import React, { useState } from 'react';
import { Button, Popup, Confirm, Icon } from 'semantic-ui-react';

import Meslis from 'components/Meslis';
import { useTranslate } from 'customHooks';

import './DeleteSubscription.scss';
import { noop } from 'utils';
import classNames from 'classnames';

const DeleteSubscription = ({ data, message, disabled, onRefetchAll = noop, ...props }) => {
	const [isConfirm, setIsConfirm] = useState(false);
	const translate = useTranslate();

	props = {
		className: classNames('DeleteSubscription', {
			disabled,
		}), // only classname no real disabeling in order to get popup to work correctly
		'data-test': 'DeleteSubscription',
		...props,
	};

	return (
		<Meslis.DeleteSubscription id={data.id} onCompleted={() => onRefetchAll()}>
			{(deleteSubscription, { loading, data, error }) =>
				loading ? (
					<Button loading icon="spinner" {...props} />
				) : error ? (
					<Button color="red" {...props}>
						{translate('queryTableView.deleteButton.error')}
					</Button>
				) : data ? (
					<Button disabled {...props}>
						{data.deleteSavedQuery
							? translate('queryTableView.deleteButton.deleted')
							: translate('queryTableView.deleteButton.failed')}
					</Button>
				) : (
					<>
						<Popup
							trigger={
								<Button
									color="red"
									onClick={
										!disabled
											? () => {
													setIsConfirm(true);
											  }
											: null
									}
									icon
									{...props}>
									<Icon name="trash" />
								</Button>
							}
							popperModifiers={[{ name: 'preventOverflow', options: { boundry: 'window' } }]}
							content={message}
							disabled={!message}
							position="top right"
							mouseEnterDelay={700}
						/>
						<Confirm
							open={isConfirm}
							closeOnDimmerClick={false}
							content={translate('queryTableView.deleteButton.deleteQuery')}
							onCancel={() => setIsConfirm(false)}
							onConfirm={() => {
								setIsConfirm(false);
								deleteSubscription();
							}}
						/>
					</>
				)
			}
		</Meslis.DeleteSubscription>
	);
};

export default DeleteSubscription;
