import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { ApmRoute } from '@elastic-deecoob/apm-rum-react';

import EventsWorkflow from 'components/EventsWorkflow';
import GenericWorkflow from 'components/GenericWorkflow';
import Subscriptions from 'components/Subscriptions';
import ExportInbox from 'components/ExportInbox';
import History from 'components/History';
import CustomLeaflet from 'components/CustomLeaflet';
import UserSettings from 'components/UserSettings';
import { Error404 } from 'components/Base';

const LoggedInSwitch = () => (
	<Switch>
		<Route exact path="/" render={() => <Redirect to="/events" />} />
		<ApmRoute path="/events" component={EventsWorkflow} />
		<ApmRoute path="/locations" component={GenericWorkflow({ type: 'location' })} />
		<ApmRoute path="/organizers" component={GenericWorkflow({ type: 'organizer' })} />
		<ApmRoute path="/my-selections" component={ExportInbox} />
		<ApmRoute path="/my-searches" component={Subscriptions} />
		<ApmRoute path="/history" component={History} />
		<ApmRoute path="/custom-leaflet" component={CustomLeaflet} />
		<ApmRoute path="/login" render={() => <Redirect to="/" />} />
		<ApmRoute path="/user-settings" component={UserSettings} />
		<ApmRoute component={Error404} />
	</Switch>
);

export default LoggedInSwitch;
