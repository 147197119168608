import React from 'react';

import Action from './Action';
import filterUtils from 'components/FilterWidget/filterUtils';
import { value2Blocks } from 'utils';

const KeywordEntry = ({ data, field, onChangeValue }) => {
	const checkExists = field.name !== 'all';

	const { selectAction } = filterUtils.adoptToSelectAction(
		checkExists ? filterUtils.adoptToExistsAssociation(data, true) : data
	);

	const handleToggleAction = () => {
		let buffer = filterUtils.adoptToActionAssociation({
			selectAction: filterUtils.oppositeAction(selectAction),
		});
		buffer = filterUtils.adoptFromExistsAssociation({ ...data, ...buffer });

		onChangeValue(buffer);
	};

	const blocks = !selectAction.includes('exists') ? value2Blocks(data.keyword) : [];

	return (
		<span className="KeywordEntry" data-test="KeywordEntry">
			<Action onToggle={handleToggleAction} selectAction={selectAction} />{' '}
			{blocks.map((block, i) => (
				<span className="block" key={i}>
					{block}
					{i !== blocks.lastIndex && ' '}
				</span>
			))}
		</span>
	);
};

export default KeywordEntry;
