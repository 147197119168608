import React, { useEffect, useRef, useState } from 'react';
import { DateInput as LibDateInput, TimeInput } from 'semantic-ui-calendar-react';
import { INVALID_DATE } from 'config';
import { useTranslateContext } from 'customHooks';
import classNames from 'classnames';
import './CustomDateInput.scss';

/* This component is wrapping DateInput and TimeInput from the external package. It expects a dateTime value of the standard format of config or a date value without time. 
   The change might be in the same format (onChange). Internally the value is divided into a separate date value and a separate time value being in 
   the customized format. It is possible to only input the date. Although the used library has a DateTimeInput we decided against it because it requires
   to many clicks. If the user inputs a syntactically incorrect value or a value being beyond the limit of maxDate and minDate then the outputted value of 
   onChange will be INVALID_DATE and the incorrect value is kept internally. */
const DateInput = ({
	value,
	onChange,
	className,
	error,
	maxDate,
	minDate,
	onBlur,
	type = 'dateInput',
	'data-test': data_test,
	...props
}) => {
	const dateInput = useRef();
	const timeInput = useRef();
	const [incorrectValue, setIncorrectValue] = useState(null); // buffering incorrect values here instead of sending the string INVALID_DATE which can easily be tested
	const [isFocus, setIsFocus] = useState(false);
	const hasTimeInput = type === 'dateTimeInput' ? true : false;

	const {
		formatDateTimeTuple,
		reformatDateTimeTuple,
		resolveDateTimeTuple,
		isDateTimeTupleEmpty,
		defaultTime,
		momentFormat: { dateCode, timeCode } = {},
	} = useTranslateContext();

	const [dateValue, timeValue] =
		incorrectValue || (!value || value === INVALID_DATE ? ['', ''] : formatDateTimeTuple(value));

	[maxDate, minDate] = [maxDate, minDate].map(date =>
		date === INVALID_DATE || date === '' ? undefined : date
	);
	const ownError = !!incorrectValue && !isFocus && 'ownError'; // An error can come from other inputs. Therefore extra highlight of own error.

	const handleKeyDown = e => {
		if (e.key === 'Backspace') {
			// Without closing popup when correcting date in text input
			// the correction would be overridden by the selected field
			// in popup after hitting enter. (known issue of DateInput component)
			dateInput.current && dateInput.current.closePopup();
			timeInput.current && timeInput.current.closePopup();
			!isFocus && setIsFocus(true);
		}
	};

	const handleChange = (dateValue, timeValue) => {
		const isEmpty = isDateTimeTupleEmpty(dateValue, timeValue); // default time also considered as empty

		// from short hand and other formats into the current format
		// always performed no matter if out of range
		[dateValue, timeValue] = resolveDateTimeTuple(dateValue, timeValue);

		// standard dateTime format
		const reformatedValue = reformatDateTimeTuple(dateValue, timeValue);

		let buffer;
		if (
			isEmpty ||
			(!!reformatedValue &&
				(!maxDate || reformatedValue <= maxDate) &&
				(!minDate || reformatedValue >= minDate))
		) {
			incorrectValue !== null && setIncorrectValue(null);
			buffer = reformatedValue;
		} else {
			setIncorrectValue([dateValue, timeValue]);
			buffer = INVALID_DATE;
		}
		buffer !== value && onChange(buffer, buffer !== INVALID_DATE);
	};

	// Inputting date without time => automatically add default time
	// but deleting time => do NOT replace by default time.
	const handleChangeDate = _dateValue =>
		handleChange(_dateValue, !timeValue && !!_dateValue ? defaultTime : timeValue);
	const handleChangeTime = _timeValue => handleChange(dateValue, _timeValue);

	// redo change routine when limits change because of different computation of INVALID_DATE
	useEffect(() => {
		!!minDate && !!maxDate && handleChange(dateValue, timeValue);
		// eslint-disable-next-line
	}, [minDate, maxDate]); // other dependencies not needed

	// unset incorrect value when value changed from outside
	useEffect(() => {
		!!incorrectValue && value !== INVALID_DATE && setIncorrectValue(null);
		// eslint-disable-next-line
	}, [value]); // other dependencies not needed

	const commonProps = {
		error,
		...props,
		onKeyDown: e => handleKeyDown(e),
		onFocus: () => !isFocus && setIsFocus(true),
		onBlur: (...vals) => {
			isFocus && setIsFocus(false);
			onBlur && onBlur(...vals);
		},
	};

	return (
		<span className={classNames('CustomDateInput', { hasTimeInput })} data-test={data_test}>
			<LibDateInput
				{...commonProps} // maxDate and minDate are buggy in library and will not be used
				className={classNames('dateInput', className, ownError)}
				data-test="dateInput"
				value={dateValue}
				onChange={(_, target) => handleChangeDate(target.value)}
				dateFormat={dateCode} // Unfortunately, month names cannot be translated
				ref={dateInput}
			/>
			{hasTimeInput && (
				<TimeInput
					{...commonProps}
					className={classNames('timeInput', className, ownError)}
					data-test="timeInput"
					value={timeValue}
					onChange={(_, target) => handleChangeTime(target.value)}
					timeFormat={convertTimeFormat(timeCode)}
					disableMinute
					ref={timeInput}
				/>
			)}
		</span>
	);
};

export default DateInput;

// The definition of the time format in this library is different from the one being used in the moment library.
const convertTimeFormat = timeCode =>
	timeCode.includes('a') ? 'ampm' : timeCode.includes('A') ? 'AMPM' : '24';
