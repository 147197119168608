import React from 'react';
import { Button, Icon, Popup } from 'semantic-ui-react';
import { useQueryParams, useTranslate } from 'customHooks';

export default ({ className, genericID, genericType, isOnly: _, ...props }) => {
	const { pushURL } = useQueryParams();
	const translate = useTranslate();

	const handleClick = () => {
		pushURL({
			rerouteTo: 'events/create',
			isNoOldParams: true,
			isNewWindow: true,
			...(genericID ? { genericID } : {}),
			...(genericType ? { genericType } : {}),
		});
	};

	return (
		<Popup
			trigger={
				<Button
					data-test="createNewEventButton"
					className={className}
					icon
					onClick={handleClick}
					{...props}>
					<Icon name="tag" />
				</Button>
			}
			content={<b>{translate('actions.createNewEvent')}</b>}
			on="hover"
			mouseEnterDelay={500}
			disabled={props.disabled}
			position="bottom right"
			closeOnTriggerClick
		/>
	);
};
