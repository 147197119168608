import { useRef } from 'react';
export default delay => {
	const callback = useRef();
	const handleStartAction = startAction => {
		callback.current = setTimeout(startAction, delay);
	};
	const handleStopAction = stopAction => {
		!!callback.current && clearTimeout(callback.current);
		callback.current = null;
		!!stopAction && stopAction();
	};

	return [handleStartAction, handleStopAction];
};
