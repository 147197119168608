import React from 'react';
import './SimpleToolbar.scss';
import { Header } from 'semantic-ui-react';

const SimpleToolbar = ({ name = ' ', children }) => {
	return (
		<div className="Toolbar">
			<Header as="h3">{name}</Header>
			{children}
		</div>
	);
};

export default SimpleToolbar;
