import React, { useState, useEffect } from 'react';
import { Button, Form, Icon, Input } from 'semantic-ui-react';

import { alerts } from 'utils';

import { useQuery, useMutation } from '@apollo/client';
import { SUBSCRIPTIONS_NAMES } from 'graphql/queries';
import { SUBSCRIBE } from 'graphql/mutations';

import './SubscribeForm.scss';
import { SAVED_QUERY_MAX_LENGTH } from 'config';
import { useTranslate } from 'customHooks';

const getOriginType = type =>
	({
		event: 'EVENT',
		location: 'LOCATION',
		organizer: 'ORGANIZER',
	}[type]);

const SubscribeForm = ({ filters, sorting, quickFilter, origin = 'event', enabled = true }) => {
	let [name, setName] = useState('');
	let [invalidName, setInvalidName] = useState(false);
	// dirty is/was used because data.saveSearchQuery seems to return true every time after first saving
	let [dirty, setDirty] = useState(false);

	const refetchQueries = [{ query: SUBSCRIPTIONS_NAMES }];
	let { data: savedQueryNames } = useQuery(SUBSCRIPTIONS_NAMES);

	const subscribeConfig = {
		variables: {
			name: name,
			type: getOriginType(origin),
			searchQuery: JSON.stringify({ filters, sorting, quickFilter }),
		},
		refetchQueries,
		onCompleted: () => {
			setDirty(false);
			setName('');
		},
	};

	let [subscribe, { data, loading, error }] = useMutation(SUBSCRIBE, subscribeConfig);
	const translate = useTranslate();

	const execute = () => {
		const nameAlreadyExists = savedQueryNames.getAllSavedQueries.find(item => item.name === name);

		if (nameAlreadyExists) {
			alerts.queryNameAlreadyExists(nameAlreadyExists.name);
		} else {
			subscribe();
		}
	};

	const isNameValid = () => name !== '' && !/^\s/.test(name);
	const executeIfValid = () => (isNameValid() ? execute() : setInvalidName(true));

	const handleChangeName = e => {
		const val = e.target.value;
		setName(
			!val || val.length < SAVED_QUERY_MAX_LENGTH ? val : val.slice(0, SAVED_QUERY_MAX_LENGTH)
		);
	};

	useEffect(() => {
		setInvalidName(false);
	}, [name]);

	useEffect(() => {
		setDirty(true);
	}, [filters]);

	return (
		<Form className="SubscribeForm">
			<Form.Field>
				<Input
					data-test="FormFieldNameInput"
					className="formFieldNameInput"
					fluid
					value={name}
					error={invalidName}
					placeholder={translate('placeholders.chooseAName')}
					onChange={handleChangeName}
				/>
			</Form.Field>
			<Form.Field>
				{loading ? (
					<Button
						className="saveSearch"
						primary
						color="grey"
						fluid
						loading
						icon="spinner"
						disabled
					/>
				) : error ? (
					<Button
						className="saveSearch"
						fluid
						color="red"
						disabled={!enabled}
						onClick={executeIfValid}>
						{translate('actions.error')}
					</Button>
				) : data && data.saveSearchQuery && !dirty ? (
					<Button className="saveSearch" primary fluid color="grey" disabled>
						{translate('actions.done')}
					</Button>
				) : data && !data.saveSearchQuery ? (
					<Button
						className="saveSearch"
						fluid
						color="red"
						disabled={!enabled}
						onClick={executeIfValid}>
						{translate('actions.fail')}
					</Button>
				) : (
					<Button className="saveSearch" primary fluid disabled={!enabled} onClick={executeIfValid}>
						<Icon name="pin" />
						{translate('actions.saveSearch')}
					</Button>
				)}
			</Form.Field>
		</Form>
	);
};

export default SubscribeForm;
