import template from 'utils/stringTemplates';

export default {
	username: 'Nutzername',
	password: 'Passwort',

	login: 'Einloggen',
	logout: 'Ausloggen',

	statusPage: 'App-Status',

	documentation: 'Dokumentation',
	anyField: 'Eines der Felder',

	detailView: {
		event: 'Veranstaltung',
		ad: 'Hinweis',
		ads: 'Hinweise',
		adsSuffix: 'e',
		id: 'ID',
		ids: 'IDs',
		eventType: 'Schlagwörter',
		location: 'Veranstaltungsort',
		organizers: 'Veranstalter',
		organizer: 'Veranstalter',
		timestamp: 'Zeitstempel',
		date: 'Datum',
		from: 'vom',
		to: 'bis',
		invoiceNumber: 'Rechnungsnummer',
		invoiceNumberTip:
			'Dieses Feld muss zusammen mit dem Feld "Positionsnummer" ausgefüllt werden. Nachdem ein Anfangswert vergeben wurde, kann es individuell editiert werden. Wenn der Wert entfernt wird, wird das Feld "Positionsnummer" ebenfalls entfernt.',
		itemNumber: 'Positionsnummer',
		itemNumberTip:
			'Dieses Feld muss zusammen mit dem Feld "Rechnungsnummer" ausgefüllt werden. Nachdem ein Anfangswert vergeben wurde, kann es individuell editiert werden. Wenn der Wert entfernt wird, wird das Feld "Rechnungsnummer" ebenfalls entfernt.',
		statusName: 'Status',
		statusUsername: 'Status zugewiesen von',
		statusLastChangedAt: 'Status zuletzt geändert am',
		admission: 'Eintritt',
		artist: 'Künstler',
		artists: 'Künstler',
		lineup: 'Besetzung',
		attendees: 'Zusagen',
		setlists: 'Musikfolgen',
		name: 'Name',
		evCustomerIds: 'Kunden-IDs',
		evCustomerIdsTip:
			'Kundenspezifische Veranstaltungs-ID (Status). Die entsprechenden Suchfelder sind "Veranstaltung - Kundenstatus" und "Veranstaltung - Kunden-IDs".',
		address: 'Adresse',
		postalCode: 'PLZ',
		street: 'Straße',
		houseNumber: 'Hausnummer',
		city: 'Stadt',
		region: 'Region',
		country: 'Land',

		category: 'Kategorie',
		freeInput: 'Beliebige Eingabe',
		phone: 'Telefonnummern',
		websites: 'Webseiten',
		url: 'URL',
		mail: 'E-Mails',
		commercialId: 'Handels-ID',
		locMatchedIds: 'Kunden-IDs',
		locCommercialId: 'Handels-ID',
		setlistTable: {
			songName: 'Song-Titel',
			artist: 'Ursprünglicher Künstler',
		},
		orgMatchedIds: 'Kunden-IDs',
		orgCommercialId: 'Handels-ID',
		isCancelled: 'Abgesagt',
		isLocationSameAsOrganizer: 'Veranstalter und Ort gleich',
		navigationCounter: template`#${0} von ${1}`,
		viewPDF: 'PDF ansehen',
		uploadEvidenceImages: 'Beweismittelbilder hochladen',
		viewDescription: 'Beschreibung ansehen',
		editDescription: 'Beschreibung editieren',
		viewSetlists: 'Musikfolgen ansehen',
	},

	genDetailView: {
		evTimestamp: 'Veranstaltung - Zeitstempel',
		evDate: 'Veranstaltung - Datum',
		evInvoiceNumber: 'Veranstaltung - Rechnungsnummer',
		evItemNumber: 'Veranstaltung - Positionsnummer',
		evStatusName: 'Veranstaltung - Status',
		evStatusUsername: 'Veranstaltung - Status zugewiesen von',
		evStatusLastChangedAt: 'Veranstaltung -	Status zuletzt geändert am',
		evCustomerIds: 'Veranstaltung - Kunden-ID',
		evAdmission: 'Veranstaltung - Eintritt',
		evArtists: 'Veranstaltung - Künstler',
		evLineup: 'Veranstaltung - Besetzung',
		evAttendees: 'Veranstaltung - Zusagen',
		evTags: 'Veranstaltung - Tags',
		evUrl: 'Veranstaltung - URL',
		evCancelled: 'Veranstaltung - Abgesagt',
		evLocationEqualsOrganizer: 'Veranstaltung - Veranstalter und Ort gleich',

		locName: 'Veranstaltungsort - Name',
		locTimestamp: 'Veranstaltungsort - Zeitstempel',
		locStatusName: 'Veranstaltungsort - Status',
		locStatusUsername: 'Veranstaltungsort - Status zugewiesen von',
		locStatusLastChangedAt: 'Veranstaltungsort - Status zuletzt geändert am',
		locCustomerIds: 'Veranstaltungsort - Kunden-ID',
		locCommercialId: 'Veranstaltungsort - Handels-ID',
		locAddress: 'Veranstaltungsort - Adresse',
		locCategory: 'Veranstaltungsort - Kategorie',
		locEmails: 'Veranstaltungsort - E-Mails',
		locPhoneNumbers: 'Veranstaltungsort - Telefonnummern',
		locWebsites: 'Veranstaltungsort - Webseiten',

		orgName: 'Veranstalter - Name',
		orgTimestamp: 'Veranstalter - Zeitstempel',
		orgStatusName: 'Veranstalter - Status',
		orgStatusUsername: 'Veranstalter - Status zugewiesen von',
		orgStatusLastChangedAt: 'Veranstalter - Status zuletzt geändert am',
		orgCustomerIds: 'Veranstalter - Kunden-ID',
		orgCommercialId: 'Veranstalter - Handels-ID',
		orgAddress: 'Veranstalter - Adresse',
		orgCategory: 'Veranstalter - Kategorie',
		orgEmails: 'Veranstalter - E-Mails',
		orgPhoneNumbers: 'Veranstalter - Telefonnummern',
		orgWebsites: 'Veranstalter - Webseiten',
	},

	tableView: {
		info: 'Info', // for filter
		infoCollaboration: 'Info - Zusammenarbeit',
		infoIsLocked: 'Info - Veranstaltung gesperrt', // for csv export
		infoLockingUser: 'Info - Gesperrt von', // for csv export
		infoState: 'Info - Status',
		infoExportCount: 'Info - Anzahl Exporte',
		infoLeaflet: 'Info - Ablage',
		infoLastChangingUser: 'Info - Letzter Bearbeiter',
		infoLastChangingUserTip:
			'Nutzer, welcher die Veranstaltung zuletzt auf eine Ablage verschoben, exportiert, ausgeblendet oder als "Keine Musikveranstaltung" gekennzeichnet hat',
		infoLastAction: 'Info - Letzte Aktion',
		infoLastActionTip:
			'Zeitpunkt, zu welchem die Veranstaltung zuletzt auf eine Ablage verschoben, exportiert, ausgeblendet oder als "Keine Musikveranstaltung" gekennzeichnet worden ist',

		evId: 'Veranstaltung - ID',
		evIdTip:
			'Eine Veranstaltung kann zuvor anderen IDs zugeordnet worden sein. ' +
			'Wenn Sie nach einer dieser alten IDs suchen, wird sie dennoch gefunden.\n\n' +
			'Eine alte ID, nach der gefiltert wird, steht als grauer Text hinter der aktuellen ID.\n\n' +
			'Um eine vollständige Liste alter IDs zu sehen, fahren Sie mit der Maus über die Info-Icons in den betreffenden Zellen.',

		evOldIds: 'Diese Veranstaltung war zuvor unter folgenden IDs verfügbar:',

		locIdTip:
			'Ein Veranstaltungsort kann zuvor anderen IDs zugeordnet worden sein. ' +
			'Wenn Sie nach einer dieser alten IDs suchen, wird er dennoch gefunden.\n\n' +
			'Eine alte ID, nach der gefiltert wird, steht als grauer Text hinter der aktuellen ID.\n\n' +
			'Um eine vollständige Liste alter IDs zu sehen, fahren Sie mit der Maus über die Info-Icons in den betreffenden Zellen.',

		locOldIds: 'Dieser Veranstaltungsort war zuvor unter folgenden IDs verfügbar:',

		orgIdTip:
			'Ein Veranstalter kann zuvor anderen IDs zugeordnet worden sein. ' +
			'Wenn Sie nach einer dieser alten IDs suchen, wird er dennoch gefunden.\n\n' +
			'Eine alte ID, nach der gefiltert wird, steht als grauer Text hinter der aktuellen ID.\n\n' +
			'Um eine vollständige Liste alter IDs zu sehen, fahren Sie mit der Maus über die Info-Icons in den betreffenden Zellen.',

		orgOldIds: 'Dieser Veranstalter war zuvor unter folgenden IDs verfügbar:',

		evName: 'Veranstaltung - Name',
		evStartDate: 'Veranstaltung - Startdatum',
		evStartTime: 'Veranstaltung - Startzeit',
		evEndDate: 'Veranstaltung - Enddatum',
		evEndTime: 'Veranstaltung - Endzeit',
		evDates: 'Veranstaltung - Start- und Enddatum',
		evAdmission: 'Veranstaltung - Eintritt',
		evType: 'Veranstaltung - Schlagwörter',
		evRelevance: 'Veranstaltung - Musikbewertung',
		evSetlists: 'Veranstaltung - Musikfolgen',
		evSetlistsArtist: 'Veranstaltung - Musikfolgen - Künstler',
		evSetlistsSongTitle: 'Veranstaltung - Musikfolgen - Songtitel',
		evSetlistsOriginalArtist: 'Veranstaltung - Musikfolgen - Ursprünglicher Künstler',
		evSetlistsPerformer: 'Veranstaltung - Musikfolgen - Interpret',
		evArtist: 'Veranstaltung - Künstler',
		evArtistTip: 'In der Veranstaltungsbeschreibung genannte Künstler',
		evArtistName: 'Veranstaltung - Name des Künstlers',
		evArtistScore: 'Veranstaltung - Künstlerwertung',
		evLineup: 'Veranstaltung - Besetzung',
		evLineupTip:
			'Die Interpreten werden automatisch aus der Veranstaltungsbeschreibung bzw. den Metadaten extrahiert.',
		evAttendees: 'Veranstaltung - Zusagen',
		evAdvertisementIds: 'Veranstaltung - Hinweis-IDs',
		evDescription: 'Veranstaltung - Beschreibung',
		evURL: 'Veranstaltung - URL',
		evCustomerIds: 'Veranstaltung - Kunden-IDs',
		evCustomerIdsTip:
			'Vom Kunden verwendete Veranstaltungs-ID (Status). Die entsprechenden Suchfelder sind "Veranstaltung - Kundenstatus" und "Veranstaltung - Kunden-IDs"',
		evCustomerIdsTipFilter: 'Vom Kunden verwendete Veranstaltungs-ID',
		evCustomerIdsStatus: 'Veranstaltung - Kunden-IDs (Kundenstatus)', // used in csv export because no tooltip in csv export
		evTimestamp: 'Veranstaltung - Zeitstempel',
		evTimestampTip:
			'Datum der Erstellung bzw. der letzten Aktualisierung der Veranstaltung (in der MESLIS-Datenbank für die Zeitzone UTC+1).',
		evProcessingStatus: 'Veranstaltung - Kundenstatus',
		evIsCancelled: 'Veranstaltung - Abgesagt',
		evIsLocationSameAsOrganizer: 'Veranstaltung - Veranstalter und Ort gleich',
		evAdCount: 'Veranstaltung - Anzahl der Hinweise',
		evOrgCount: 'Veranstaltung - Anzahl der Veranstalter',
		evSelectedOrganizer: 'Veranstaltung - Ausgewählter Veranstalter',
		evStatusName: 'Veranstaltung - Status',
		evStatusUsername: 'Veranstaltung - Status zugewiesen von',
		evStatusLastChangedAt: 'Veranstaltung - Status zuletzt geändert am',
		evInvoiceNumber: 'Veranstaltung - Rechnungsnummer',
		evItemNumber: 'Veranstaltung - Positionsnummer',

		locStatusName: 'Veranstaltungsort - Status',
		locStatusUsername: 'Veranstaltungsort - Status zugewiesen von',
		locStatusLastChangedAt: 'Veranstaltungsort - Status zuletzt geändert am',
		locId: 'Veranstaltungsort - ID',
		locMatchedId: 'Veranstaltungsort - Kunden-ID',
		locMatchedIds: 'Veranstaltungsort - Kunden-IDs',
		locCommercialId: 'Veranstaltungsort - Handels-ID',
		locCustomerStatus: 'Veranstaltungsort - Kundenstatus',
		locName: 'Veranstaltungsort - Name',
		locStreet: 'Veranstaltungsort - Straße',
		locCity: 'Veranstaltungsort - Stadt',
		locRegion: 'Veranstaltungsort - Region',
		locCountry: 'Veranstaltungsort - Land',
		locZIP: 'Veranstaltungsort - PLZ',
		locPhone: 'Veranstaltungsort - Telefonnummern',
		locCategory: 'Veranstaltungsort - Kategorie',
		locURL: 'Veranstaltungsort - Webseiten',
		locTimestamp: 'Veranstaltungsort - Zeitstempel',
		locMail: 'Veranstaltungsort - E-Mails',
		locEventCountTotal: 'Veranstaltungsort - Gesamtzahl der Veranstaltungen',
		locEventCountRelevant: 'Veranstaltungsort - Anzahl gefundener Musikveranstaltungen',
		locEventCountIrrelevant: 'Veranstaltungsort -  Anzahl gefundener Nicht-Musikveranstaltungen',
		locStatisticEventCountPerYear: 'Veranstaltungsort - Veranstaltungen pro Jahr laut MESLIS',
		locCustomerEventCountPerYear: 'Veranstaltungsort - Veranstaltungen pro Jahr laut Kunde',

		orgStatusName: 'Veranstalter - Status',
		orgStatusUsername: 'Veranstalter - Status zugewiesen von',
		orgStatusLastChangedAt: 'Veranstalter - Status zuletzt geändert am',
		orgId: 'Veranstalter - ID',
		orgMatchedIds: 'Veranstalter - Kunden-IDs',
		orgCommercialId: 'Veranstalter - Handels-ID',
		orgCustomerStatus: 'Veranstalter - Kundenstatus',
		orgName: 'Veranstalter - Name',
		orgStreet: 'Veranstalter - Straße',
		orgCity: 'Veranstalter - Stadt',
		orgRegion: 'Veranstalter - Region',
		orgCountry: 'Veranstalter - Land',
		orgZIP: 'Veranstalter - PLZ',
		orgPhone: 'Veranstalter - Telefonnummern',
		orgCategory: 'Veranstalter - Kategorie',
		orgURL: 'Veranstalter - Webseiten',
		orgTimestamp: 'Veranstalter - Zeitstempel',
		orgMail: 'Veranstalter - E-Mails',
		orgEventCountTotal: 'Veranstalter - Gesamtzahl der Veranstaltungen',
		orgEventCountRelevant: 'Veranstalter - Anzahl gefundener Musikveranstaltungen',
		orgEventCountIrrelevant: 'Veranstalter -  Anzahl gefundener Nicht-Musikveranstaltungen',
		orgStatisticEventCountPerYear: 'Veranstalter - Veranstaltungen pro Jahr laut MESLIS',
		orgCustomerEventCountPerYear: 'Veranstalter - Veranstaltungen pro Jahr laut Kunde',
	},

	genTableView: {
		statusName: 'Status',
		statusUsername: 'Status zugewiesen von',
		statusLastChangedAt: 'Status zuletzt geändert am',
		collaboration: 'Zusammenarbeit',
		id: 'ID',
		customerId: 'Kunden-ID',
		customerStatus: 'Kundenstatus',
		phoneNumbers: 'Telefonnummern',
		emailAddresses: 'E-Mails',
		url: 'Webseiten',
		timestamp: 'Zeitstempel',
		region: 'Region',
		commercialId: 'Handels-ID',
		category: 'Kategorie',
		type: 'Typ',
		cusEvPerYear: 'Veranstaltungen pro Jahr laut Kunde',
		cusEvPerYearTip1:
			'Anzahl Veranstaltungen pro Jahr laut Kunde im Vergleich zur Anzahl laut MESLIS-Daten in folgendem Format:',
		cusEvPerYearTip2: 'Jahr: Anzahl laut Kunde/ Anzahl laut MESLIS (Differenz)',

		evPerYear: 'Veranstaltungen pro Jahr laut MESLIS',
		evPerYearTip1: 'Anzahl Veranstaltungen pro Jahr laut MESLIS-Daten in folgendem Format:',
		evPerYearTip2: 'Jahr: Anzahl laut MESLIS',
		address: {
			street: 'Straße',
			city: 'Stadt',
			postalCode: 'PLZ',
			country: 'Land',
		},
		statistic: {
			eventCountIrrelevant: 'Anzahl gefundener Nicht-Musikveranstaltungen',
			eventCountRelevant: 'Anzahl gefundener Musikveranstaltungen',
			eventCountTotal: 'Gesamtzahl der Veranstaltungen',
			evCountTotalTip:
				'Die ausgewiesene Zahl gibt die Gesamtsumme über alle Jahre an, diese kann von dem vertraglich vereinbarten Zeitraum abweichen',
		},
	},

	queryTableView: {
		type: 'Typ',
		id: 'ID',
		name: 'Name',
		createdAt: 'Erstellt am',
		filters: 'Filter',
		hits: 'Treffer',
		createdBy: 'Erstellt von',
		sharedWith: 'Geteilt mit',
		actions: 'Aktionen',

		new: 'neu',

		unshareRequired:
			'Um diese gespeicherte Suche zu löschen, darf sie mit keinem anderen Nutzer mehr geteilt sein.',

		delete: 'Löschen',

		searchButton: {
			event: 'Veranstaltungen suchen',
			location: 'Veranstaltungsorte suchen',
			organizer: 'Veranstalter suchen',
		},

		deleteButton: {
			deleted: 'Gelöscht',
			failed: 'Fehlgeschlagen',
			delete: 'Löschen',
			error: 'Error',
			deleteQuery: 'Sind Sie sicher, dass Sie diese Suche löschen wollen?',
		},

		showButton: {
			show: 'Zeigen',
			hide: 'Ausblenden',
			showUnhidden: 'Sichtbare Suchen zeigen',
			showHidden: 'Unsichtbare Suchen zeigen',
		},
	},

	selectionsTableView: {
		filename: 'Dateiname',
		createdAt: 'Erstellt am',
		description: 'Beschreibung',
		downloadCount: 'Downloads',
		actions: 'Aktionen',
		mySelections: 'Meine Exporte',
	},

	leaflets: {
		create: 'Neue Ablage erstellen',
		edit: 'Bearbeiten',
		update: 'Ablage aktualisieren',
		delete: 'Ablage löschen',
		view: 'Anzeigen',
		shared: 'Geteilt',
		conflictTip1:
			'Die Conflict-Ablage enthält Veranstaltungen, für die MESLIS nicht entscheiden kann, auf welcher Ablage diese gespeichert werden sollen.\n\n' +
			'Dies kommt in seltenen Fällen vor, wenn MESLIS Veranstaltungen auf unterschiedlichen Ablagen als identisch erkennt und zusammenführt.\n\n' +
			'Sie wird von allen Nutzern der Firma geteilt. Von Hand Veranstaltungen darauf zu schieben, ist nicht mögich.',
		conflictTip2:
			'Bitte schauen Sie die Veranstaltungen auf der Conflict-Ablage durch und verschieben Sie diese händisch auf die korrekte Ablage.',
		header: {
			create: 'Neue Ablage erstellen',
			edit: 'Ablage bearbeiten',
		},
		leafName: 'Name',
		leafId: 'ID',
		leafDate: 'Erstelldatum',
		leafTag: 'Schlagwort',
		leafDescription: 'Beschreibung',
		leafCreator: 'Ersteller',
		leafOwner: 'Besitzer',
		leafNameExists: 'Eine Ablage dieses Namens existiert bereits.',
		leafNameSpaces: 'Der Name der Ablage besteht ausschließlich aus Leerzeichen.',

		formEditor: 'Wählen Sie einen Mitarbeiter',
		maxHeader: 'Maximalzahl für Ablagen erreicht',
		maxLeaflets:
			'Sie haben die maximale Anzahl Ablagen erreicht, die für einen Nutzer zugelassen sind. Wenn Sie eine neue Ablage erstellen wollen, müssen Sie zunächst eine andere löschen.',
		sharedTooltip: 'Mit anderen Nutzern geteilt',
		new: 'neu',

		items: 'Einträge',

		created: 'Erstellt',
		edited: 'Bearbeitet',
		removed: 'Entfernt',
		clear: 'Leeren',
		clearMsg: template`Ablage '${0}' leeren`,
		cancel: 'Abbrechen',
		removeAllEventsMsg: template`Sind Sie sicher, dass Sie alle Veranstaltungen von der Ablage '${0}' entfernen wollen?`,
	},

	actions: {
		actions: 'Aktionen',
		moveTo: template`Auf "${0}" verschieben`,
		moveToLeaflet: 'Auf Ablage verschieben',
		leaflet: 'Ablage',
		discard: 'Ausblenden',
		scrap: 'Keine Musikveranstaltung',
		drop: 'Zurücklegen',
		keep: 'Auf dieser Ablage belassen',
		merge: 'Zusammenführen',
		displayColumns: 'Spalten ein- und ausblenden',

		eventStatusBulkEdit: 'Veranstaltungsstatus bearbeiten',

		csvExport: 'CSV-Export',
		expandAll: 'Alles ausklappen',
		collapseAll: 'Alles einklappen',
		collapse: '...weniger',
		expand: '...mehr',

		cancel: 'Abbrechen',
		reset: 'Zurücksetzen',
		ok: 'Ok',
		download: 'Download',
		stillProcessing: 'Erstellung läuft noch',
		undo: 'Rückgängig',
		detail: 'Detail',
		table: 'Tabelle',
		showAll: 'Alles anzeigen',
		hideAll: 'Alles ausblenden',
		showOnlyCoveredSongs: 'Nur gecoverte Songs anzeigen',

		error: 'Error',
		done: 'Abgeschlossen',
		fail: 'Fehlgeschlagen',
		saveSearch: 'Suche speichern',

		selectAll: 'Alles auswählen',
		deselectAll: 'Auswahl aufheben',

		deleteAll: 'Alles löschen',
		noGrouping: 'Keine Gruppierung',
		groupingByField: 'Gruppierung nach Feld',
		groupingByAction: 'Gruppierung nach Operator',

		changeToEditMode: " Zu Modus 'Veranstaltung bearbeiten' wechseln",
		leaveEditMode: " 'Modus 'Veranstaltung bearbeiten' verlassen",
		instructionsEditMode:
			"Um in den Modus 'Veranstaltung bearbeiten' zu wechseln, müssen Sie zunächst die Veranstaltung sperren.",
		warningEditMode:
			'Hinweis: Sie können von anderen Nutzern gesperrte Veranstaltungen nicht bearbeiten.',
		createNewEvent: 'Veranstaltung anlegen',
		resetCreateUpdateEvent: 'Änderungen verwerfen',
		dragMode: 'Auf eine andere Veranstaltung verschieben bzw. kopieren',

		createNewLocation: 'Veranstaltungsort anlegen',
		createNewOrganizer: 'Veranstalter anlegen',
		selectExistingLocation: 'Existierenden Veranstaltungsort auswählen',
		selectExistingOrganizer: 'Existierenden Veranstalter auswählen',
		switchTo: template`Umstellen auf "${0}"`,

		searchLocationByCustomerId: 'Veranstaltungsort anhand Kunden-Id suchen',
		searchLocationByCommercialId: 'Veranstaltungsort anhand Handels-Id suchen',
		searchOrganizerByCustomerId: 'Veranstalter anhand Kunden-Id suchen',
		searchOrganizerByCommercialId: 'Veranstalter anhand Handels-Id suchen',

		lock: {
			event: 'Veranstaltung sperren',
			organizer: 'Veranstalter sperren',
		},
		locked: {
			event: 'Veranstaltung durch anderen Nutzer gesperrt',
			organizer: 'Veranstalter durch anderen Nutzer gesperrt',
		},
		unlock: {
			event: 'Veranstaltung entsperren',
			organizer: 'Veranstalter entsperren',
		},

		createLocation: 'Veranstaltungsort erstellen',
		createOrganizer: 'Veranstalter erstellen',

		requiredFields: 'Mit * markierte Felder sind Pflichtfelder.',
		updateLocation: 'Veranstaltungsort bearbeiten',
		updateOrganizer: 'Veranstalter bearbeiten',

		redirectToLocation: 'Schritt "Auswählen" des Veranstaltungsortes in neuem Tab öffnen.',
		redirectToOrganizer: ' Schritt "Auswählen" des Veranstalters in neuem Tab öffnen.',
	},

	filters: {
		filters: 'Filter',
		add: 'Hinzufügen',
		confirm: 'Bestätigen',
		keyword: {
			contains: 'enthält',
			doesntContain: 'enthält nicht',
			containsAll: 'enthält alles von',
			containsOneOf: 'enthält eines von',
			containsOnly: 'enthält ausschließlich',
			exists: 'existiert',
			doesntContainAll: 'enthält nicht alles von',
			doesntContainOneOf: 'enthält keines von',
			doesntContainOnly: 'enthält ausschließlich nicht',
			doesntExist: 'existiert nicht',
		},
		decompose: 'Teilt Phrase in Blöcke mit je einem Wort auf.',
		operators: {
			atLeast: 'wenigstens',
			equals: 'genauso viel wie',
			atMost: 'höchstens',
			between: 'zwischen',
			between_and: 'und',
			underLicensedCustomer: 'weniger als laut MESLIS mit',
			underLicensedCustomerInfo:
				'Die jährlichen Veranstaltungen sind laut Kunde weniger als laut MESLIS' +
				' und die Differenz ist größer oder gleich dem festgelegten Toleranzwert.',
			overLicensedCustomer: 'mehr als laut MESLIS mit',
			overLicensedCustomerInfo:
				'Die jährlichen Veranstaltungen sind laut Kunde mehr als laut MESLIS' +
				' und die Differenz ist größer oder gleich dem festgelegten Toleranzwert.',
		},
		count: {
			tolerance: 'Toleranz',
			number: 'Anzahl',
		},
		tags: {
			liveMusic: 'Live-Musik',
			backgroundMusic: 'Hintergrundmusik',
			djMusic: 'DJ-Musik',
		},
		status: {
			newLocation: 'Neuer Veranstaltungsort',
			newOrganizer: 'Neuer Veranstalter',
			changedOwner: 'Besitzer gewechselt',
			known: 'Bekannt',
		},
		boolean: {
			true: 'ja',
			false: 'nein',
		},
		year: {
			inYear: 'im Jahr',
			betweenYears: 'zwischen den Jahren',
			betweenYears_and: 'und',
			sinceYear: 'ab dem Jahr',
			untilYear: 'bis zum Jahr',
			forAllYears: 'für alle Jahre',
		},
		info: {
			lastChangingUser: 'Letzter Bearbeiter',
			leaflet: 'Ablage',
			lastChange: 'letzte Änderung',
			between: 'zwischen',
			between_and: 'und',
			from: 'ab',
			to: 'bis',
		},
		dateRange: {
			startDate: 'Startdatum',
			endDate: 'Enddatum',
			start: 'Start',
			end: 'Ende',
		},
		range: {
			from: 'ab',
			to: 'bis',
			year: 'Jahr',
			fromYear: 'vom Jahr',
			toYear: 'bis zum Jahr',
		},
		collaboration: 'Zusammenarbeit',
		chooseLocking: 'Wählen Sie eine Sperrkonfiguration',
		chooseUsers: 'Wählen Sie einen oder mehrere Nutzer',
		collaborationOptions: {
			any: 'Alle',
			unlocked: 'Nicht gesperrt',
			locked: 'Gesperrt',
			lockedByMe: 'Von mir gesperrt',
			lockedByOtherUsers: 'Von anderen Nutzern gesperrt',
			lockedByFollowingUsers: 'Von folgenden Nutzern gesperrt ...',
		},
	},

	genericEventFilters: {
		filters: 'Filter',
		relevance: 'Musikbewertung',
		relevances: {
			any: 'Alle',
			relevant: 'Als Musikveranstaltung bewertet',
			irrelevant: 'Als "Keine Musikveranstaltung" bewertet',
		},
		year: 'Jahr',
		any: 'Alle',
		status: 'Status',
		states: {
			NEW: 'Neu',
			IN_PROGRESS: 'In Bearbeitung',
			EXPORTED: 'Exportiert',
			DISCARDED: 'Ausgeblendet',
			SCRAPPED: 'Als "Keine Musikveranstaltung" gekennzeichnet',
		},
	},

	genericPager: {
		location: 'Veranstaltungsort',
		organizer: 'Veranstalter',
		loading: 'Lädt...',
		newEvents: template`${0} neue Veranstaltungen`,
	},

	resetPassword: {
		modalHeader: 'Passwort-Rücksetzung anfordern',
		modalDesc: 'Um eine Passwort-Rücksetzung anzufordern, geben Sie bitte ihren Nutzernamen ein.',

		failMessage:
			'Der von Ihnen eingegebene Nutzername existiert nicht. Überprüfen Sie bitte die Schreibweise.',
		failResetMessage:
			'Die Passwort-Rücksetzung ist fehlgeschlagen. Bitte versuchen Sie es noch einmal oder fordern Sie einen neuen Link unter app.meslis an.',
		failTokenHeader:
			'Ihr Link zur Passwort-Rücksetzung ist entweder fehlgeschlagen oder abgelaufen.',
		failTokenMessage:
			'Um einen neuen Link zur Passwort-Rücksetzung anzufordern, gehen Sie zur Login-Seite der MESLIS-Web-App.',
		successMessage:
			'Sie haben die Rücksetzung des Passworts erfolgreich angefordert. Bitte überprüfen Sie ihr Postfach nach der Bestätigungs-E-Mail.',

		confirmButton: 'Rücksetzung anfordern',
		triggerButton: 'Passwort-Rücksetzung anfordern',
		reset: 'Passwort zurücksetzen',
		change: 'Passwort ändern',
		oldPassword: 'altes Passwort',
		passwordChangeHeader: 'Ändern Sie hier Ihr Passwort für den Zugang zur MESLIS Web App',
		passwordChangeMessage: template`Bitte beachten Sie, dass das Passwort für das MESLIS Help Center von dieser Änderung nicht betroffen ist. Wenn Sie auch dieses Passwort ändern möchten, gehen Sie zu den Sicherheitseinstellungen Ihres Profils im ${0}`,
		passwordResetHeader: 'Ändern Sie hier Ihr Passwort für den Zugang zur MESLIS Web App',
		passwordResetMessage: template`Bitte beachten Sie, dass das Passwort für das MESLIS Help Center von dieser Änderung nicht betroffen ist. Wenn Sie auch dieses Passwort ändern möchten, gehen Sie zu den Sicherheitseinstellungen Ihres Profils im ${0}`,
		newPassword: 'neues Passwort',
		validatePassword: 'Neues Passwort prüfen',
		setNewPassword: 'Neues Passwort setzen',
		strength:
			'Ihr Passwort muss folgende Kriterien erfüllen:\n' +
			'\t\u2022 minimale Länge von 12 Zeichen\n' +
			'\t\u2022 mindestens eine Ziffer [0-9]\n' +
			'\t\u2022 mindestens ein Großbuchstabe [A-Z]\n' +
			'\t\u2022 mindestens ein Kleinbuchstabe [a-z]\n' +
			'\t\u2022 mindestens eines der folgenden Sonderzeichen: &-_[]`~|@$%^?!:.{}',
	},

	userSettings: {
		changePassword: 'Passwort ändern',
		table: 'Tabellenansicht',
		defaultFetchSize: 'Anzahl nachgeladener Einträge beim Herunterscrollen',
		headerReset: 'Spaltenreihenfolge auf Standard zurücksetzen',
		eventsReset: "Tabelle 'Veranstaltungen suchen'",
		locationsReset: "Tabelle 'Veranstaltungsorte suchen'",
		organizersReset: "Tabelle 'Veranstalter suchen'",
		reset: 'Zurücksetzen',
	},

	drag: {
		moveEventAd: template`Hinweis ${0} verschieben`,
		moveEventAdDescription: template`Hinweis mit ID ${0} auf andere Veranstaltung verschieben.`,
		moveEvent: 'Veranstaltung verschieben',
		moveEventDescription: template`Veranstaltung mit ID ${0} auf andere Veranstaltung verschieben.`,
		copyEventAd: template`Hinweis ${0} kopieren`,
		copyEventAdDescription: template`Hinweis mit ID ${0} auf andere Veranstaltung kopieren.`,
		copyEvent: 'Veranstaltung kopieren',
		copyEventDescription: template`Veranstaltung mit ID ${0} auf andere Veranstaltung kopieren.`,
		allAdsMoved: 'Alle Hinweise dieser Veranstaltung werden auf die andere verschoben.',
		allAdsCopied: 'Alle Hinweise dieser Veranstaltung werden auf die andere kopiert.',
		dragThisBox: 'Ziehen Sie diese Fläche auf die Detailansicht in einem anderen Browser-Tab.',
		dropHere: 'Lassen Sie hier Hinweise oder Veranstaltungen fallen.',
	},

	messages: {
		tooManyRequests: template`Scheinbar haben Sie ${0} zu häufig versucht. Bitte versuchen Sie es später noch einmal.`,
		searchResults: template`${2}gesamt: ${0} / angezeigt: ${1}`,
		selectSearchResults: template`${3}gesamt: ${0} / angezeigt: ${1} / ausgewählt: ${2}`,
		createEvent: 'Veranstaltung erstellen',
		updateEvent: 'Veranstaltung bearbeiten',
		searchLocations: 'Veranstaltungsorte suchen - ',
		searchOrganizers: 'Veranstalter suchen - ',

		noLeafletsHeader: 'Keine Ablagen',
		noLeafletsText: 'In Ihrer Liste sind aktuell keine Ablagen vorhanden.',

		noDataHeader: 'Keine Daten gefunden',
		noData:
			'Für diese Filtereinstellungen gibt es keine passenden Daten. Bitte ändern Sie ihre Filter, um mehr Daten zu sehen.',

		noSubscriptionsHeader: 'Keine gespeicherten Suchen',
		noSubscriptionsText:
			'Sie können Filterlisten, die Sie bei der Suche nach Veranstaltungen, Veranstaltungsorten oder Veranstaltern erstellt haben, speichern. ' +
			'Geben Sie einfach einen Namen ein und klicken Sie auf "Suche speichern". ' +
			'Die so erstellten Suchen werden hier aufgelistet.',

		noSelections:
			'Sie haben bisher noch keine Veranstaltungen exportiert. Um einen Export zu erstellen, gehen Sie auf "Meine Ablagen". ' +
			'Sie können alle Veranstaltungen einer Ablage oder auch einzelne Veranstaltungen zum Export auswählen.',

		noHistory:
			'Die Historie ist eine tabulare Übersicht aller ausgeblendeten Veranstaltungen und Nicht-Musikveranstaltungen. ' +
			'Es werden ausschließlich Veranstaltungen gezeigt, die vom eingeloggten Nutzer bearbeitet worden sind. ' +
			'Bisher haben Sie noch keine Veranstaltungen ausgeblendet oder als "Keine Musikveranstaltung" gekennzeichnet.',

		export: {
			header: template`Exportiere ${0} Veranstaltungen`,
			description: template`Sie sind dabei, ${0} Veranstaltungen vom Leaflet "${1}" zu exportieren. 
			Sie können eine Beschreibung angeben, um diese Datei zu identifizieren und von anderen
			Exporten zu unterscheiden.`,
			descriptionPlaceholder: 'Eine Beschreibung eintippen...',

			action: template`Nach dem Export können Sie Veranstaltungen auf dieser Ablage belassen
			oder auf jede beliebige Ablage verschieben (Status "In Bearbeitung" ${0}).
			Wenn Sie hier nichts auswählen, bleibt es beim Standardexport, d.h. die Veranstaltungen werden von der
			aktuellen Ablage entfernt und und ihr Status auf "Exportiert" ${1} gesetzt.`,
			actionPlaceholder: 'Standardexport lassen oder Aktion wählen...',
			button: {
				cancel: 'Abbrechen',
				export: template`${0} exportieren`,
				selected: 'Ausgewählte', // capital letter because at the beginning and not end as in english
				all: 'Alle',
				exportAnd: template` und ${0}`,
			},
			pageNotFound: 'Diese Seite konnte nicht gefunden werden',

			exportSuccessful: 'Export erfolgreich',
			messageExportBeingProcessed:
				'Die Erstellung läuft noch. Es kann eine Weile dauern, bis Ihnen der Export zum Download zur Verfügung steht. In der Zwischenzeit können Sie einen neuen Export erstellen.',
			startNewSearch: 'Eine neue Suche starten',
			useSavedSearch: 'Eine gespeicherte Suche anwenden',
			checkSelections: 'Exporte überprüfen',
		},

		selectOrganizer: {
			headerUnselected: 'Zutreffenden Veranstalter auswählen',
			headerSelected: template`Veranstalter "${0}" auswählen`,
			description:
				'Diese Veranstaltung ist momentan noch mehreren Veranstaltern zugewiesen. ' +
				'Bevor Sie diese innerhalb eines Veranstalters bearbeiten können, ist eine eindeutige Zuordnung erforderlich, ' +
				'da sich das Sperren eines Veranstalters nur auf die eindeutig zugeordenten Veranstaltungen auswirkt.',
			customID: 'benutzerdefinierte ID',
			oldIdReplacedByNewId: template`Alte ID ${0} durch neue ID ${1} ersetzt.`,
		},

		undoLastEventMove: 'Letzte Verschiebung rückgängig machen',

		undoLastMoveAction: template`Sind Sie sicher, dass Sie ${0}? Hinweis: Bisher gibt es noch keine "Wiederherstellen"-Funktion.`,
		undoMoveAds: template`Ihre letzte Verschiebung rückgängig machen wollen (verschiebe ${0} Hinweis(e) von der Veranstaltung ID=${1} zurück auf die Veranstaltung ID=${2})`,
		undoMoveAdsOfEvents: template`Ihr letztes Zusammenführen einer Veranstaltung rückgängig machen wollen (verschiebe ${0} Hinweis(e) von der Veranstaltung ID=${1} zurück auf verschiedene Veranstaltungen)`,
		undoCopyAds: template`Ihr letztes Kopieren rückgängig machen wollen (schiebe ${0} Hinweis(e) von Veranstaltung ID=${1} herunter)`,
		undoCopyAdsToNewRemoveAds: template`Ihr letztes Kopieren rückgängig machen wollen (schiebe alle Hinweise von Veranstaltung ID=${0} herunter) `,
		undoEventMove: template`Ihre letzte Verschiebung rückgängig machen wollen (${0} Veranstaltungen)`,

		noSetlistsDataHeader: 'Keine Musikfolgen',
		noSetlistsData: 'Für diese Veranstaltung sind keine Musikfolgen verfügbar.',
		passwordResetSuccessfulHeader: 'Passwort-Rücksetzung erfolgreich',
		passwordResetSuccessful: template`Sie haben Ihr Passwort erfolgreich zurückgesetzt. Sie können sich nun unter ${0} einloggen.`,

		urlsElseWhere: 'Bitte geben Sie die URLs unterhalb der Beweismittelbilder an.',
	},

	alerts: {
		information: 'Ooops..',
		eventsMoveFailed: template`Aktion fehlgeschlagen: Einige Veranstaltungen konnten nicht verschoben werden${0}. Dieser Fehler kann auftreten, wenn betreffende Veranstaltungen von Ihnen oder einem anderen Nutzer verändert oder gesperrt worden sind. Laden Sie die Ansicht neu und versuchen Sie es nochmals.`,
		eventsMoveFailedOfIds: template` (IDs: ${0})`,
		queryNameAlreadyExists: template`Eine Suche mit dem Namen "${0}" existiert bereits. Geben Sie bitte einen anderen Namen ein.`,
		selectedMaxAmountOfEvents: template`Es wurden nur die ersten ${0} Veranstaltungen ausgewählt. '+
		'Um weitere Veranstaltungen auswählen zu können, arbeiten Sie bitte zunächst die aktuelle Auswahl ab oder ändern Sie diese. Die Maximalzahl für eine Auswahl ist ${0}.`,
		leafletUnshared: 'Die von Ihnen gesuchte Ablage wird nicht mehr mit Ihnen geteilt.',

		unshareColleague:
			'Sind Sie sicher, dass sie diese Suche mit diesen Nutzern nicht mehr teilen wollen?',
		leafletNotBeCleared: template`Es ist etwas schief gegangen: Die Ablage konnte nicht geleert werden! \n ${0}`,
		downloadFailed:
			'Download fehlgeschlagen: Bitte laden Sie die Seite neu und versuchen Sie es noch einmal!',
		mergeEvents: template`Sind Sie sicher, dass Sie die ${0} ausgewählten Veranstaltungen zusammenführen und auf die Veranstaltung ID=${1} schieben möchten?`,
		linkUnlink: {
			noMoveAds: template`Hinweis(e) ${0} konnten nicht von Veranstaltung(en) ${1} auf Veranstaltung ${2} verschoben werden.`,
			noMoveAdsToNew: template`Veranstaltung konnte nicht erstellt werden oder Hinweis(e) ${0} konnten nicht von Veranstaltung ${1} auf eine neue Veranstaltung verschoben werden.`,
			noCopyAds: template`Hinweis(e) ${0} konnten nicht von Veranstaltung(en) ${1} auf Veranstaltung ${2} kopiert werden.`,
			noCopyAdsToNew: template`Hinweis(e) ${0} konnten nicht von Veranstaltung ${1} eine neue Veranstaltung kopiert werden.`,
			noDeleteEvent: template`Veranstaltung(en) ${0} konnten nicht gelöscht werden.`,
			noUndo:
				'Nicht alle Aktionen konnten rückgänging gemacht werden. ' +
				'Möglicherweise sind einige der betreffenden Veranstaltungen in der Zwischenzeit verschoben worden.',
		},
		otherUserLockedEvent:
			'Die Veranstaltung konnte nicht gesperrt werden. Möglicherweise hat ein anderer Nutzer dieselbe Aktion gleichzeitig ausgelöst.',
		otherUserLockedOrganizer:
			'Der Veranstalter konnte nicht gesperrt werden. Möglicherweise hat ein anderer Nutzer dieselbe Aktion gleichzeitig ausgelöst.',
		errorAssignOrganizer: 'Der Veranstalter konnte der Veranstaltung nicht zugewiesen werden.',
		lockedSelectionIgnored: template`Einige Veranstaltungen ihrer Auswahl sind in der Zwischenzeit von einem anderen Nutzer gesperrt worden. Die Veranstaltungen mit folgenden IDs werden ignoriert: ${0}.`,

		failMessageGenerator: template`Etwas ist fehlgeschlagen, ${0} konnte nicht ${1} werden. \nKeine Sorge es ist nicht Ihre Schuld und wir arbeiten daran!`,
		item: {
			event: 'Veranstaltung',
			eventStatusBulkEdit: template`Veranstaltungsstatus bearbeiten`,
			advertisement: 'Hinweis',
			advertisements: 'Hinweise',
			location: 'Veranstaltungsort',
			organizer: 'Veranstalter',
			search: 'Suche',
			undo: 'Rückgängig',
		},
		what: {
			locked: 'gesperrt',
			unlocked: 'entsperrt',
			updated: 'aktualisiert',
			created: 'erstellt',
			applied: 'angewendet',
			copied: 'kopiert',
			moved: 'verschoben',
			deleted: 'gelöscht',
		},
	},

	tooltips: {
		discardButtonHeader: 'Blenden Sie Veranstaltungen für die Standardansicht aus',
		discardButton:
			'Bitte beachten Sie, dass diese Aktion die aktuelle Ansicht sowie künftige Veranstaltungssuchen anderer Nutzer in Ihrer Organisation betrifft. ' +
			'Wollen Sie die ausgeblendeten Veranstaltungen wieder in Ihre Suche einbeziehen, klicken Sie den Status-Button "Ausgeblendet".',
		scrapButtonHeader:
			'Kennzeichnen Sie Veranstaltungen als negative Trainingsdaten zur Verbesserung der Musikbewertung',
		scrapButton:
			'Sie werden weniger ähnliche Daten in Ihrer Suche finden. ' +
			'Bitte beachten Sie, dass diese Aktion die Veranstaltungen aus der aktuellen Ansicht entfernt und auch künftige Veranstaltungssuchen anderer Nutzer in Ihrer Organisation betroffen sind. ' +
			'Wollen Sie diese Veranstaltungen wieder in Ihre Suche einbeziehen, klicken Sie den Status-Button "Als \'Keine Musikveranstaltung\' gekennzeichnet".',
		dropButtonHeader: 'Legen Sie Veranstaltungen aus der aktuellen Ablage zurück',
		dropButton:
			'Diese werden bei der Suche wieder unter dem Status "Neu" verfügbar sein ' +
			'bzw. unter dem letzten Status vor Verschieben auf eine Ablage ("Exportiert", "Ausgeblendet", oder "Als \'Keine Musikveranstaltung\' gekennzeichnet").',
		deleteButton:
			'Um eine Ablage löschen zu können, ist es zunächst erforderlich, alle darauf liegenden Veranstaltungen zu entfernen und sicherzustellen, dass Sie diese mit keinem Mitarbeiter mehr teilen.',
		mergeButton:
			'Ausgewählte Veranstaltungen zusammenführen. Die Hinweise aller ausgewählten Veranstaltungen werden auf die oberste ausgewählte Veranstaltung verschoben.',

		dragModeButton: 'Alternativ kann die "F2"-Taste gedrückt werden.',

		mergeButtonTooFew:
			'Um den Button "Zusammenführen" zu nutzen, wählen Sie mindestens zwei Veranstaltungen aus.',
		mergeButtonTooMany: template`Um den Button "Zusammenführen" zu nutzen, wählen Sie höchstens ${0} Veranstaltungen aus. Mehr Veranstaltungen können nicht zur selben Zeit zusammengeführt werden.`,
		lockButton: {
			event: {
				lock:
					'Sperren Sie diese Veranstaltung, um sie zu verschieben oder zu bearbeiten. ' +
					'So lange Sie die Veranstaltung sperren, haben andere Nutzer nur Lesezugriff.',
				locked: 'So lange ein anderer Nutzer die Veranstaltung sperrt, haben Sie nur Lesezugriff.',
				unlock: 'Geben Sie diese Veranstaltung zur Bearbeitung für andere Nutzer frei.',
				disabled:
					'Sie haben auf die Ablage der Veranstaltung keinen Zugriff. Sie können die Veranstaltung daher nur lesen.',
			},
			organizer: {
				lock:
					'Sperren Sie diesen Veranstalter, um die zugeordneten Veranstaltungen zu verschieben oder zu bearbeiten. ' +
					'So lange Sie den Veranstalter sperren, haben andere Nutzer auf die Veranstaltungen nur Lesezugriff.\n' +
					'Hinweis: Die Sperrung betrifft Veranstaltungen nicht, welche außer diesem noch anderen Veranstaltern zugeordnet sind. ' +
					'Um solche Veranstaltungen in die Sperrung einzubeziehen, weisen Sie diese eindeutig zu.',
				locked:
					'So lange ein anderer Nutzer den Veranstalter sperrt, haben Sie auf die eindeutig zugeordneten Veranstaltungen nur Lesezugriff.',
				unlock:
					'Geben Sie alle zugeordneten Veranstaltungen dieses Veranstalters zur Bearbeitung für andere Nutzer frei.',
			},
		},
		lockIcon: {
			event: {
				locked: template`Diese Veranstaltung wird von ${0} gesperrt. Sie können nur zum Lesen darauf zugreifen.`,
				lockedByMe:
					'Diese Veranstaltung wird von Ihnen gesperrt und kann von anderen Nutzern nicht bearbeitet werden.',
				viewed: template`Diese Veranstaltung wird von ${0} Nutzer(n) angeschaut: ${1}`,
				unlocked: 'Diese Veranstaltung wird von keinem Nutzer gesperrt.',
			},
			organizer: {
				locked: template`Dieser Veranstalter wird von ${0} gesperrt. Sie können nur zum Lesen darauf zugreifen.`,
				lockedByMe:
					'Dieser Veranstalter wird von Ihnen gesperrt und kann von anderen Nutzern nicht bearbeitet werden.',
				viewed: template`Dieser Veranstalter wird von ${0} Nutzer(n) angeschaut: ${1}`,
				unlocked: 'Dieser Veranstalter wird von keinem Nutzer gesperrt.',
			},
		},
		submitButton: {
			submit: 'Abschicken',
			submitPermitted: 'Das Abschicken ist nicht möglich.',
			unchanged: 'Es sind keine Daten verändert worden.',
			missingFields: 'Fehlende Pflichtfelder: ',
			invalidFields: 'Fehlerhafte Einträge bei: ',
			fieldNames: {
				name: 'Name',
				startDate: 'Start Datum',
				endDate: 'End Datum',
				location: 'Veranstaltungsort',
				editedLocation: {
					name: 'Veranstaltungsort - Name',
					matchedLocationIds: 'Veranstaltungsort - Kunden-IDs',
					emailAddresses: 'Veranstaltungsort - E-Mails',
					phoneNumbers: 'Veranstaltungsort - Telefonnummern',
					urls: 'Veranstaltungsort - Webseiten',
					address: {
						postalCode: 'Veranstaltungsort - PLZ',
						street: 'Veranstaltungsort - Straße',
						houseNumber: 'Veranstaltungsort - Hausnummer',
						city: 'Veranstaltungsort - Stadt',
						region: 'Veranstaltungsort - Region',
						country: 'Veranstaltungsort - Land',
					},
				},
				organizer: 'Veranstalter',
				editedOrganizer: {
					name: 'Veranstalter - Name',
					matchedOrganizerIds: 'Veranstalter - Kunden-IDs',
					emailAddresses: 'Veranstalter -  E-Mails',
					phoneNumbers: 'Veranstalter - Telefonnummern',
					urls: 'Veranstalter - Webseiten',
					address: {
						postalCode: 'Veranstalter - PLZ',
						street: 'Veranstalter - Straße',
						houseNumber: 'Veranstalter - Hausnummer',
						city: 'Veranstalter - Stadt',
						region: 'Veranstalter - Region',
						country: 'Veranstalter - Land',
					},
				},
				images: 'URLs der Beweismittelbilder',
				noImage: 'Beweismittelbild',
				noImages: 'Beweismittelbilder (mindestens eines erforderlich)',
				admission: 'Eintritt',
				visitorCount: 'Zusagen',
				invoiceNumber: 'Rechnungsnummer',
				itemNumber: 'Positionsnummer',
			},
		},
		csvExport: template`Exportieren Sie für alle angezeigten ${0} die momentan sichtbaren Spalten.`,
		csvExportLocations: 'Veranstaltungsorte',
		csvExportOrganizers: 'Veranstalter',
		csvExportEvents: 'Veranstaltungen',
		expandAllButtonLocations:
			'Klappt alle Tabellenzeilen aus/ein, sodass für die Spalten "Veranstaltungen pro Jahr laut Kunde" und "Veranstaltungen pro Jahr laut MESLIS" ' +
			'die Statistiken unterschiedlicher Jahre miteinander verglichen werden können.',
		expandAllButtonOrganizers:
			'Klappt alle Tabellenzeilen aus/ein, sodass für die Spalten "Veranstaltungen pro Jahr laut Kunde" und "Veranstaltungen pro Jahr laut MESLIS" ' +
			'die Statistiken unterschiedlicher Jahre miteinander verglichen werden können.',
		expandAllButtonEvents: 'Klappt bei mehreren Veranstaltern die Tabellenzeilen aus oder ein.',
		info: {
			header: {
				state: 'Nach dem Status sortieren',
				leaflet: 'Nach der aktuellen Ablage sortieren',
				user: 'Nach dem Nutzer der letzten Änderung sortieren',
				timestamp: 'Nach der letzten Änderung sortieren',
			},
			cell: {
				infoIs: 'Der Veranstaltungsstatus ist',
				NEW: 'Neu',
				IN_PROGRESS: 'In Bearbeitung',
				EXPORTED: 'Exportiert',
				DISCARDED: 'Ausgeblendet',
				SCRAPPED: 'Als "Keine Musikveranstaltung" gekennzeichnet',

				itWas_exported: template`Die Veranstaltung wurde ${0} exportiert.`,
				once: '1 mal',
				twice: '2 mal',
				times: 'mal',

				currLeafletIs: 'Die aktuelle Ablage ist',
				noLeaflet: 'Die Veranstaltung liegt momentan nicht auf einer Ablage',
				lastChangeWas: 'Die letzte Aktion zur Änderung des Veranstaltungsstatus war am',
				timestampUnknown:
					'Der Zeitstempel für die letzte Änderung des Veranstalltungsstatus ist unbekannt',
				changingUserWas: 'Der letzte Bearbeiter war',
				userUnknown: 'Der Veranstaltungsstatus ist noch von keinem Nutzer verändert worden',
			},

			filter: {
				leafletsDisabled1:
					'Die gewählten Ablagen sind momentan gesperrt. Aktivieren Sie den Status "In Bearbeitung" ',
				leafletsDisabled2: ' oder klicken Sie auf dieses Feld.',
				leafletsEnabled1: 'Nach der Auswahl von Ablagen wird der Status "In Bearbeitung" ',
				leafletsEnabled2: ' automatisch aktiviert.',
			},
			entry: {
				leafletsDisabled1:
					'Die gewählten Ablagen sind momentan nicht aktiv. Um nach Ablagen filtern zu können, aktivieren sie den Status "In Bearbeitung" ',
				leafletsDisabled2: ' oder klicken Sie auf diesen Eintrag.',
			},
			quickfilter: {
				NEW: 'Neu',
				IN_PROGRESS: 'In Bearbeitung (d.h. auf einer Ablage)',
				EXPORTED: 'Exportiert',
				DISCARDED: 'Ausgeblendet',
				SCRAPPED: 'Als "Keine Musikveranstaltung" gekennzeichnet',
			},
		},
		anyField:
			'Unscharfe Suche auf mehreren Feldern, bei der Wörter vom Suchwort um zwei Buchstaben abweichen können.',
		listNavigatorForbiddenForward:
			'Es können so lange keine Einträge mehr nachgeladen werden, bis die vorhergehenden abgearbeitet worden sind.',

		sortLeaflets: {
			name: 'Nach dem Namen sortieren',
			creator: 'Nach dem Ersteller sortieren',
			createdAt: 'Nach dem Erstelldatum sortieren',
			updatedAt: 'Nach der letzten Bearbeitung sortieren',
			default: 'Standardsortierung',
		},

		locationCreated: template`Der Veranstaltungsort wurde erstellt. Die ID ist ${0}.`,
		locationUpdated: template`Der Veranstaltungsort wurde aktualisiert. ${0}`,
		organizerCreated: template`Der Veranstalter wurde erstellt. Die ID ist ${0}.`,
		organizerUpdated: template`Der Veranstalter wurde aktualisiert. ${0}`,
		mergedEventsUpdateSuccess: template`Veranstaltung(en) mit den folgenden IDs wurden erfolgreich aktualisiert: ${0}.`,
		mergedEventsUpdateDuplication: template`Duplizierung fehlgeschlagen für Ereignis(e) mit den folgenden IDs: ${0}.`,
		mergedEventsUpdateFailure: template`Aktualisierung für Veranstaltung(en) mit den folgenden IDs fehlgeschlagen: ${0}.`,
		mergedEventsUpdateMatching: template`Abgleich für Veranstaltung(en) mit den folgenden IDs fehlgeschlagen: ${0}.`,

		sortIcon: {
			descSort: 'Absteigend nach dieser Spalte sortiert.',
			ascSort: 'Aufsteigend nach dieser Spalte sortiert.',
			prioSort: template`Die Spalte hat die Priorität ${0}.`,
			clickToSort: 'Klicken Sie, um nach dieser Spalte zu sortieren.',
			clickToToggleSort: 'Klicken Sie, um die Sortierung umzukehren.',
			multipleSort:
				'Tip: Durch Klicken mit gedrückter "Strg"-Taste wird eine multiple Sortierung erstellt. ' +
				'So können Sie nach Priorität weitere Spalten einbeziehen, wenn in einer sortierten Spalte mehrere Werte gleich sind.',
			notSortable: 'Diese Spalte kann nicht sortiert werden.',
		},
	},

	titles: {
		logout: 'Aus dem System loggen',
	},

	placeholders: {
		leafletDescription: 'Ein paar aussagekräftige Worte zu ihrer Ablage...',
		pdfPlaceholder: 'Ein PDF-Dokument ist nicht verfügbar.',
		loading: 'Lädt',
		loadingDots: 'lädt...',
		noItemsFound: 'Keine Einträge zu finden.',
		moreDataHeader: 'Daten werden nachgeladen',
		moreData: 'Bitte warten Sie einen Augenblick...',
		noMoreDataHeader: 'Keine weiteren Daten',
		noMoreData: 'Es sind keine weiteren Daten zum Nachladen verfügbar.',
		chooseAName: 'Wählen Sie einen Namen',
		dragEventAdsOrInput:
			'Lassen Sie Veranstaltungshinweise aus einem anderen Browser-Fenster hier fallen oder nutzen Sie die Eingabefelder, um neue Hinweisdaten zu erstellen!',
		allAdsRemoved: 'Von dieser Veranstaltung sind alle Hinweise entfernt worden.',
		dragAndDrop:
			'Nutzen Sie Drag and Drop zum Einfügen von Bildern oder klicken Sie, um Dateien auszuwählen.',
		releaseToDrop: 'Lassen Sie Dateien hier fallen.',
	},

	error: {
		error: 'Error',
		login: 'Login fehlgeschlagen!',
		locked: 'Der Account ist gesperrt. Fordern Sie bitte eine Passwort-Rücksetzung an.',
		dataFetch: 'Die Daten konnten nicht nachgeladen werden',
		rulePassword:
			'Für ihr Passwort ist eine minimale Länge von 12 Zeichen erforderlich, darunter ' +
			'mindestens eine Ziffer, mindestens ein Großbuchstabe, ' +
			'mindestens ein Kleinbuchstabe und mindestens ein Sonderzeichen.',

		samePassword: 'Das neue Passwort muss sich vom alten unterscheiden.',
		notSameNewPassword:
			'Die beiden Passwörter sind nicht identisch. Bitte machen Sie erneut eine Eingabe.',
		notSamePassword: 'Die Passwörter sind nicht identisch.',
		wrongPassword: 'falsches Passwort',
		passwordNotValid: 'Ursprüngliches Passwort falsch.',
	},
	success: { passwordChange: 'Sie erhalten eine Bestätigungs-E-Mail.' },

	navigation: {
		home: 'Start',
		events: 'Veranstaltungen suchen',
		locations: 'Veranstaltungsorte suchen',
		organizers: 'Veranstalter suchen',
		subscriptions: 'Meine Suchen',
		subscriptionShared: 'Es ist vor Kurzem eine Suche mit Ihnen geteilt worden.',
		subscriptionsShared: template`Es sind vor Kurzem ${0} Suchen mit Ihnen geteilt worden.`,
		leaflets: 'Meine Ablagen',
		exports: 'Meine Exporte',
		history: 'Meine Historie',
		customLeaflet: 'Ablagen verwalten',
		leafletShared: 'Es ist vor Kurzem eine Ablage mit Ihnen geteilt worden.',
		leafletsShared: template`Es sind vor Kurzem ${0} Ablagen mit Ihnen geteilt worden.`,
		help: 'Hilfecenter',
		ticketSystem: 'Ticketsystem',
		close: 'Schließen',
		userSettings: 'Nutzereinstellungen',
		search: 'Suchen',
		select: 'Auswählen',
		process: 'Verarbeiten',
	},

	local: {
		localSettings: 'Sprache und Format',
		language: 'Sprache',
		dateAndTime: 'Datum und Zeit',
	},

	countries: {
		australia: 'Australien',
		austria: 'Österreich',
		belgium: 'Belgien',
		denmark: 'Dänemark',
		germany: 'Deutschland',
		finland: 'Finnland',
		greatBritain: 'Großbritannien',
		ireland: 'Irland',
		israel: 'Israel',
		italy: 'Italien',
		netherlands: 'Niederlande',
		portugal: 'Portugal',
		sweden: 'Schweden',
		switzerland: 'Schweiz',
		usa: 'USA',
	},
};
