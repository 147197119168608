import React, { useState, createContext, useRef, useEffect } from 'react';
import storage from 'storage';
import { eventState } from 'formTypes';
import _ from 'lodash';
import useFilterConfig from 'components/FilterWidget/useFilterConfig';
import { useSorting } from 'customHooks';
import { order } from 'enums';

const FilterWidgetContext = createContext({});

const FilterWidgetProvider = ({ children }) => {
	const [eventsFilters, setEventsFilters] = useState(storage.getLastFilters('EVENT') || []);

	const eventSortingProps = useSorting('rating', order.DESC, true);
	const locationSortingProps = useSorting('name', order.ASC, true);
	const organizerSortingProps = useSorting('name', order.ASC, true);

	const QUICK_DEFAULT = {
		fieldName: 'state',
		value: eventState.defaultValue,
	};
	const [quickFilter, setQuickFilter] = useState(
		storage.getEventQuickFilter() || QUICK_DEFAULT
	);
	const [locationsFilters, setLocationsFilters] = useState(
		storage.getLastFilters('LOCATION') || []
	);
	const [organizersFilters, setOrganizersFilters] = useState(
		storage.getLastFilters('ORGANIZER') || []
	);

	const { groupings } = useFilterConfig();
	const [grouping, setGrouping] = useState(groupings.NONE);
	const [editItem, setEditItem] = useState(-1);

	const quickFilterProgressChangedRef = useRef();
	const setQuickFilterProgressChanged = x => (quickFilterProgressChangedRef.current = x);

	const adoptEventFilters2QuickFilter = quickFilter => {
		if (eventsFilters.some(f => f.fieldName === 'eventStateInfo')) {
			!quickFilter.value['IN_PROGRESS']
				? eventsFilters
						.filter(f => f.fieldName === 'eventStateInfo')
						.forEach(f => {
							if (!!_.get(f, 'value.leaflets.length')) {
								f.value.disabledLeaflets = f.value.leaflets;
								f.value.leaflets = [];
							}
						})
				: eventsFilters
						.filter(f => f.fieldName === 'eventStateInfo')
						.forEach(f => {
							if (!!_.get(f, 'value.disabledLeaflets.length')) {
								f.value.leaflets = f.value.disabledLeaflets;
								f.value.disabledLeaflets = [];
							}
						});
			setEventsFilters(eventsFilters);
		}
	};

	const removeFilters = () => {
		setEventsFilters([]);
		setQuickFilter(QUICK_DEFAULT);
		storage.removeLastFilters();
	};

	const handleSetQuickEventsFilters = filter => {
		adoptEventFilters2QuickFilter(filter);
		_.get(filter, 'value.IN_PROGRESS') !== _.get(quickFilter, 'value.IN_PROGRESS') &&
			quickFilterProgressChangedRef.current &&
			quickFilterProgressChangedRef.current(_.get(filter, 'value.IN_PROGRESS'));
		setQuickFilter(filter);
	};

	const hasEventStateInfoLeaflets = eventsFilters =>
		eventsFilters.some(
			f => f.fieldName === 'eventStateInfo' && !!_.get(f, 'value.leaflets.length')
		);

	const setQuickFilterProgress = isProgress =>
		handleSetQuickEventsFilters({
			...quickFilter,
			value: { ...quickFilter.value, IN_PROGRESS: true },
		});

	const adoptQuickFilter2EventFilters = eventFilters => {
		hasEventStateInfoLeaflets(eventFilters) && setQuickFilterProgress(true);
	};

	const handleSetEventsFilters = filters => {
		adoptQuickFilter2EventFilters(filters);
		setEventsFilters(filters);
	};

	useEffect(() => {
		storage.setLastFilters('EVENT', eventsFilters);
	}, [eventsFilters]);

	useEffect(() => {
		storage.setEventQuickFilter(quickFilter);
	}, [quickFilter]);

	useEffect(() => {
		storage.setLastFilters('LOCATION', locationsFilters);
	}, [locationsFilters]);

	useEffect(() => {
		storage.setLastFilters('ORGANIZER', organizersFilters);
	}, [organizersFilters]);

	const store = {
		event: {
			filters: eventsFilters,
			setFilters: handleSetEventsFilters,
			quickFilter,
			setQuickFilter: handleSetQuickEventsFilters,
			setQuickFilterProgress,
			setQuickFilterProgressChanged,
			...eventSortingProps /* sorting, shownSorting, setBothSortings, sortByField, onCtrlUp */,
		},
		location: {
			filters: locationsFilters,
			setFilters: setLocationsFilters,
			...locationSortingProps /* same props as eventSortingProps */,
		},
		organizer: {
			filters: organizersFilters,
			setFilters: setOrganizersFilters,
			...organizerSortingProps /* same props as eventSortingProps */,
		},
		filterList: {
			grouping,
			setGrouping,
			editItem,
			setEditItem,
		},
		removeFilters,
	};

	return <FilterWidgetContext.Provider value={store}>{children}</FilterWidgetContext.Provider>;
};

export { FilterWidgetContext, FilterWidgetProvider };
