import React from 'react';
import { Query } from '@apollo/client/react/components';

import { ORGANIZERS } from 'graphql/queries';
import { buildGenericQueryFromFilters } from 'utils';
import { useUpdateOrganizersAfterLoading } from 'customHooks';

const Organizers = ({ filters, max = 100, offset = 0, sorting: sortings, children, ...props }) => {
	const variables = {
		max,
		offset,
		searchQuery: buildGenericQueryFromFilters(filters, 'organizer'),
		sortings,
	};
	const updatingChildren = useUpdateOrganizersAfterLoading(children);
	return (
		<Query {...{ variables }} query={ORGANIZERS} fetchPolicy="network-only" {...props}>
			{updatingChildren}
		</Query>
	);
};

export default Organizers;
