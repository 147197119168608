import { STANDARD_MOMENT_FORMAT as FORMAT } from 'config';
import moment from 'moment';

const validateDate = (
	dateString // This is only a validation for the technical format not for user inputs.
) =>
	moment(dateString, FORMAT.dateCode, true).isValid() ||
	moment(dateString, FORMAT.timestampCode, true).isValid()||
	moment(dateString, FORMAT.timestampSecondsCode, true).isValid()
		? dateString
		: null;

export default validateDate;
