import Swal from 'sweetalert2';
import getTranslatorOfCurrentLanguage from './getTranslatorOfCurrentLanguage';

import './alerts.scss';

const translate = getTranslatorOfCurrentLanguage();

const animationFalse = {
	showClass: {
		popup: 'fadeUp',
		icon: '',
	},
	hideClass: {
		popup: '',
	},
};

const eventsMoveFailed = ids =>
	Swal.fire({
		title: translate('alerts.information'),
		text: translate('alerts.eventsMoveFailed', [
			!!ids?.length ? translate('alerts.eventsMoveFailedOfIds', [ids.join(', ')]) : '',
		]),
		reverseButtons: true,
		showConfirmButton: true,
		...animationFalse,
	});

const queryNameAlreadyExists = name => {
	Swal.fire({
		title: translate('alerts.information'),
		text: translate('alerts.queryNameAlreadyExists', [name]),
		showConfirmButton: true,
		...animationFalse,
	});
};

const selectedMaxAmountOfEvents = max_selectable =>
	Swal.fire({
		title: translate('alerts.information'),
		text: translate('alerts.selectedMaxAmountOfEvents', [max_selectable]),
		reverseButtons: true,
		showConfirmButton: true,
		...animationFalse,
	});

const leafletUnshared = () =>
	Swal.fire({
		title: translate('alerts.information'),
		text: translate('alerts.leafletUnshared'),
		reverseButtons: true,
		showConfirmButton: true,
		...animationFalse,
	});

const otherUserLockedEvent = () =>
	Swal.fire({
		title: translate('alerts.information'),
		text: translate('alerts.otherUserLockedEvent'),
		showConfirmButton: true,
		...animationFalse,
	});

const otherUserLockedOrganizer = () =>
	Swal.fire({
		title: translate('alerts.information'),
		text: translate('alerts.otherUserLockedOrganizer'),
		showConfirmButton: true,
		...animationFalse,
	});

const errorOperation = text =>
	Swal.fire({
		title: translate('alerts.information'),
		text,
		showConfirmButton: true,
		...animationFalse,
	});

const errorAssignOrganizer = () =>
	Swal.fire({
		title: translate('alerts.information'),
		text: translate('alerts.errorAssignOrganizer'),
		showConfirmButton: true,
		...animationFalse,
	});

const lockedSelectionIgnored = ids =>
	Swal.fire({
		title: translate('alerts.information'),
		text: translate('alerts.lockedSelectionIgnored', ids ? [ids.join(', ')] : []),
		showConfirmButton: true,
		showCancelButton: true,
		cancelButtonText: translate('actions.cancel'),
		...animationFalse,
	});

const _items = {
	event: translate('alerts.item.event'),
	advertisement: translate('alerts.item.advertisement'),
	advertisements: translate('alerts.item.advertisements'),
	location: translate('alerts.item.location'),
	organizer: translate('alerts.item.organizer'),
	search: translate('alerts.item.search'),
	undo: translate('alerts.item.undo'),
};

const _whats = {
	locked: translate('alerts.what.locked'),
	unlocked: translate('alerts.what.unlocked'),
	updated: translate('alerts.what.updated'),
	created: translate('alerts.what.created'),
	applied: translate('alerts.what.applied'),
	copied: translate('alerts.what.copied'),
	moved: translate('alerts.what.moved'),
	deleted: translate('alerts.what.deleted'),
};

const eventStatusBulkEdit = ids =>
	translate('alerts.item.eventStatusBulkEdit', ids ? [ids.join(',')] : []);

const failMessageGenerator = (item, what, ids = '') => {
	if (item === 'eventStatusBulkEdit') {
		item = eventStatusBulkEdit(ids);
	} else {
		item = _items[item];
	}

	what = _whats[what];

	return _failMessageGenerator(item, what);
};

const _failMessageGenerator = (item, what) => {
	return Swal.fire({
		title: `<img  alt="Musice note icon" src=${
			process.env.PUBLIC_URL
		}/music-note.png /> <p>${translate('alerts.information')} </p>`,
		text: translate('alerts.failMessageGenerator', [item, what]),
		showConfirmButton: true,
		...animationFalse,
	});
};

export default {
	eventsMoveFailed,
	selectedMaxAmountOfEvents,
	queryNameAlreadyExists,
	leafletUnshared,
	otherUserLockedEvent,
	otherUserLockedOrganizer,
	errorOperation,
	errorAssignOrganizer,
	lockedSelectionIgnored,
	failMessageGenerator,
};
