import React from 'react';
import { Form, Select } from 'semantic-ui-react';

import ActionAssociationSelect from './ActionAssociationSelect';

import filterUtils from 'components/FilterWidget/filterUtils';

const GenericSelect = ({
	value,
	error,
	onChange,
	options = [],
	allowedAssociations,
	allowMultiple = false,
}) => {
	const handleChangeOfAttribute = (attr, attrVal) =>
		onChange(filterUtils.adoptFromExistsAssociation({ ...value, ...{ [attr]: attrVal } }));

	const handleChange = attr => (_, target) => handleChangeOfAttribute(attr, target.value);

	const handleChangeAssociation = association =>
		onChange(filterUtils.adoptFromExistsAssociation({ ...value, association }));

	value = filterUtils.adoptToExistsAssociation(
		value,
		allowMultiple // set defaultValue for Select
			? []
			: null
	);

	return (
		<>
			<ActionAssociationSelect
				action={value.action}
				onChangeAction={act => handleChangeOfAttribute('action', act)}
				association={value.association}
				onChangeAssociation={handleChangeAssociation}
				{...{ allowedAssociations }}
			/>

			<Form.Field>
				<Select
					className="GenericSelect"
					value={value.keyword}
					search
					options={options}
					onChange={handleChange('keyword')}
					fluid
					style={{ visibility: value.association === 'exists' ? 'hidden' : 'visible' }}
					multiple={allowMultiple}
					error={error}
				/>
			</Form.Field>
		</>
	);
};

export default GenericSelect;
