import React from 'react';
import { Icon } from 'semantic-ui-react';

import { useTranslate } from 'customHooks';
import LoginBox from './LoginBox';

import './index.scss';



const imgSrcArray = [1, 2, 3, 4, 5];

// random order - Durstenfeld shuffle
const shuffleArray = array => {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[array[i], array[j]] = [array[j], array[i]];
	}
	return array;
};

const imgOrder = shuffleArray(imgSrcArray);

const Login = () => {
	const translate = useTranslate();

	const countries = [
		{ whiteText: 0, name: translate('countries.australia') },
		{ whiteText: 0, name: translate('countries.austria') },
		{ whiteText: 0, name: translate('countries.belgium') },
		{ whiteText: 0, name: translate('countries.denmark') },
		{ whiteText: 1, name: translate('countries.germany') },
		{ whiteText: 1, name: translate('countries.finland') },
		{ whiteText: 1, name: translate('countries.greatBritain') },
		{ whiteText: 1, name: translate('countries.ireland') },
		{ whiteText: 0, name: translate('countries.israel') },
		{ whiteText: 1, name: translate('countries.italy') },
		{ whiteText: 1, name: translate('countries.netherlands') },
		{ whiteText: 0, name: translate('countries.portugal') },
		{ whiteText: 1, name: translate('countries.sweden') },
		{ whiteText: 1, name: translate('countries.switzerland') },
		{ whiteText: 0, name: translate('countries.usa') },
	].sort((a, b) => {
		if (a.name < b.name) return -1;
		if (a.name > b.name) return 1;
		return 0;
	});

	return (
		<div className="Login">
			<div className="logo">
				<img alt="logo" src={`${process.env.PUBLIC_URL}/MESLIS_logo_white.png`} />
			</div>
			<LoginBox />

			<div className="statusPage">
				<a
					href={process.env.REACT_APP_STATUS_URL}
					className="status"
					target="_blank"
					rel="noopener noreferrer">
					<Icon name="heartbeat" />
					{translate('statusPage')}
				</a>
			</div>
			<div className="countries">
				{countries.map((country, index) => (
					<div key={index} className={country.whiteText ? 'white' : 'grey'}>
						{country.name}
					</div>
				))}
			</div>
			<div className="links">
				<a href="https://www.deecoob.com/" target="_blank" rel="noopener noreferrer">
					www.deecoob.com
				</a>{' '}
				|{' '}
				<a href="http://www.meslis.com/" target="_blank" rel="noopener noreferrer">
					www.meslis.com
				</a>
			</div>
			<div className="background-crossfade">
				{imgOrder.map((img, index) => (
					<img
						alt={'background image ' + index}
						key={index}
						src={`${process.env.PUBLIC_URL}/background${img}.jpg`}
					/>
				))}
				<div className="blue-hue-hack" />
			</div>
		</div>
	);
};

export default Login;
