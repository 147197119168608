import React from 'react';
import { noop } from '@babel/types';
import { Icon, Popup } from 'semantic-ui-react';
import './SelectionBox.scss';

export default ({
	tooltip,
	checked,
	intermediate,
	selectable,
	onClick = noop,
	disabled,
	onMouseEnter,
	onMouseLeave,
	...props
}) => {
	const checkbox = (
		<Icon
			name={
				checked
					? intermediate
						? 'minus square outline'
						: 'check square outline'
					: 'square outline'
			}
			data-checked={checked}
			data-selectable={selectable}
			{...props}
		/>
	);
	return (
		<span
			className="SelectionBox"
			data-test="SelectionBox"
			onClick={e => !disabled && selectable && onClick(e)}
			{...{ onMouseEnter, onMouseLeave }}>
			{!!tooltip ? (
				<Popup
					trigger={checkbox}
					content={<b>{tooltip}</b>}
					disabled={disabled}
					position="bottom left"
					mouseEnterDelay={300}
				/>
			) : (
				checkbox
			)}
		</span>
	);
};
