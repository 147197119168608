import React, { useLayoutEffect, useRef, useState } from 'react';
import { useSubscription } from '@apollo/client';
import { subscribeToOrganizerLocking } from 'graphql/subscriptions';
import { cloneDeep } from 'lodash';

// updates organizers by subscription without sending a query again

export default children => props => {
	return <UpdateOrganizersAfterLoading {...props}>{children}</UpdateOrganizersAfterLoading>;
};

const UpdateOrganizersAfterLoading = ({ children, data: defaultData, ...props }) => {
	const dataRef = useRef(); // keep dataRef without reloading component
	const [modifiedData, setModifiedData] = useState(); // setting modifiedData by subscription will reload component

	useLayoutEffect(() => {
		defaultData && modifiedData && setModifiedData(null);
		// eslint-disable-next-line
	}, [defaultData]);

	const getOrganizers = data =>
		(data && data.searchOrganizers && data.searchOrganizers.results) || [];

	const changeOrganizers = (organizerIds, userLock) => {
		const changedOrganizers = getOrganizers(dataRef.current).filter(
			organizer =>
				organizerIds.includes(organizer.id) &&
				!(
					organizer?.userLock?.isLocked === userLock?.isLocked &&
					organizer?.userLock?.user.username === userLock?.user.username
				)
		);

		if (changedOrganizers.length > 0) {
			changedOrganizers.forEach(organizer => {
				organizer.userLock = userLock;
				organizer.version++;
			});
			setModifiedData({ ...dataRef.current });
		}
	};

	useSubscription(subscribeToOrganizerLocking, {
		onSubscriptionData: ({
			subscriptionData: {
				data: { organizersChangedByLocking },
			},
		}) => {
			const { organizerIds = [], userLock } = organizersChangedByLocking;
			changeOrganizers(organizerIds, userLock);
		},
	});

	const data = modifiedData || cloneDeep(defaultData);
	dataRef.current = data;
	return children({ data, ...props });
};
