import React, { useState, useEffect, createContext, useRef, useCallback } from 'react';

import { USER_SETTINGS } from 'graphql/queries';
import { UPDATE_USER_SETTINGS } from 'graphql/mutations';
import { useQuery, useMutation } from '@apollo/client';
import { omit, isEqual } from 'lodash';

import storage from 'storage';
import browserStorage from 'browserStorage';
import { usePathname } from 'customHooks';

const AppContext = createContext({});

const AppProvider = ({ children }) => {
	//--// hiddenFields //--//
	const [hiddenFields, setHiddenFields] = useState(browserStorage.getHiddenFields());

	const getHiddenFieldsFor = key => hiddenFields[key];
	const setHiddenFieldsFor = useCallback(
		(key, value) => setHiddenFields({ ...hiddenFields, ...{ [key]: value } }),
		[hiddenFields]
	);

	useEffect(() => {
		!isEqual(browserStorage.getHiddenFields(), hiddenFields) &&
			browserStorage.setHiddenFields(hiddenFields);
	}, [hiddenFields]);

	useEffect(() => {
		const handleStorageUpdate = params => {
			if (params?.key !== 'hidden-fields' || !params?.newValue) return;

			const newHiddenFields = JSON.parse(params.newValue);
			setHiddenFields(newHiddenFields);
		};
		window.addEventListener('storage', handleStorageUpdate);
		return () => window.removeEventListener('storage', handleStorageUpdate);
		// eslint-disable-next-line
	}, []);

	//--// fieldsOrder //--//
	const [fieldsOrder, setFieldsOrder] = useState(browserStorage.getFieldsOrder());

	const getFieldsOrderFor = key => fieldsOrder[key];
	const setFieldsOrderFor = (key, value) => setFieldsOrder({ ...fieldsOrder, ...{ [key]: value } });

	useEffect(() => {
		browserStorage.setFieldsOrder(fieldsOrder);
	}, [fieldsOrder]);

	//--// genericID && genericType //--//
	// Those are used as props into the createNewEvent button in order to provide information in regards to existing location/organizer when rendering create new event component from location/organizer table and detail view.

	const [genericIDContext, setGenericIDContext] = useState();
	const [genericTypeContext, setGenericTypeContext] = useState();

	//--// genericContext //--//
	// Used to sync refetch data between pager and modal for update location/organizer done in the step select

	const [genericContext, setGenericContext] = useState();

	//--// undo event mutations //--//
	// undoList already contains the mutation that is needed to undo that action, not the actual source action
	// which is important for moving ads between events.
	const [undoList, _setUndoList] = useState(storage.getUndoList());
	const setUndoList = val => {
		storage.setUndoList(val);
		_setUndoList(val);
	};

	const undoMaxLength = 5;
	// mutating State doesnt trigger update => mutate var and then setState

	const pushUndoList = newItem => {
		// newItem should look something like {ids: [{id:..., version:..., leafletId:...},...], leafletId: ...}
		let newUndoList = [...undoList];
		// adhere to max elements in undoList
		if (newUndoList.length >= undoMaxLength) {
			newUndoList = newUndoList.slice(-(undoMaxLength - 1));
		}

		if (newItem.type === 'updateEvents') {
			newUndoList.push(newItem);
		} else {
			// leafletId with target needs to be exchanged with source
			// different source leaflets = multiple (one per leafletId) mutations to undo required
			const sourceLeafletIds = [];
			newItem.ids.map(
				item => !sourceLeafletIds.includes(item.leafletId) && sourceLeafletIds.push(item.leafletId)
			);
			// 1 mutation per sourceLeaflet required
			const mutationArray = sourceLeafletIds.map(sourceLeaflet => {
				const events = newItem.ids
					.filter(item => item.leafletId === sourceLeaflet)
					.map(item => ({
						id: item.id,
						version: item.version + 1,
						leafletId: newItem.leafletId,
					}));
				return { ids: events, leafletId: sourceLeaflet };
			});

			// push leafletIds that need to be refetched by Undo after undo
			const leafletsToRefetch = [newItem.leafletId].concat(sourceLeafletIds);

			newUndoList.push({ type: 'moveLeaflet', mutations: mutationArray, leafletsToRefetch });
		}
		setUndoList(newUndoList);
	};

	const popUndoList = () => {
		let newUndoList = [...undoList];
		const mutationForUndo = newUndoList.pop();

		if (!mutationForUndo) {
			return null;
		} else if (mutationForUndo.type === 'updateEvents') {
			// nothing
		} else {
			// cleanup - override leafletIds and versions in undo to match new expected values
			newUndoList = newUndoList.map(undoStep => {
				if (undoStep.type !== 'moveLeaflet') return undoStep;

				const newMutations = undoStep.mutations.map(muta => ({
					leafletId: muta.leafletId,
					ids: muta.ids.map(item => {
						// if item.id matches an id from mutationForUndo => override with new version, leafletId
						// else return item
						const returnVal = mutationForUndo.mutations.flatMap(mutation =>
							mutation.ids.flatMap(undoItem => {
								if (item.id !== undoItem.id) {
									return [];
								}
								return [
									{
										id: undoItem.id,
										version: undoItem.version + 1,
										leafletId: mutation.leafletId,
									},
								];
							})
						);
						// returnVal is either [] if different id or [{...}] if values need to be overridden
						return returnVal[0] ? returnVal[0] : item;
					}),
				}));
				return { mutations: newMutations, leafletsToRefetch: undoStep.leafletsToRefetch };
			});
		}
		setUndoList(newUndoList);
		// return value so button can execute the actual undo
		return mutationForUndo;
	};

	const clearUndoList = () => setUndoList([]);

	const removeFailedEventsFromUndo = failedEventsIds => {
		const newUndoList = [].concat(undoList);
		const undoStep = newUndoList.pop();
		const cleanedMutations = undoStep.mutations.map(muta => ({
			leafletId: muta.leafletId,
			ids: muta.ids.filter(item => !failedEventsIds.includes(item.id)),
		}));

		newUndoList.push({
			mutations: cleanedMutations,
			leafletsToRefetch: undoStep.leafletsToRefetch,
		});

		setUndoList(newUndoList);
	};

	const getUndoList = () => undoList;

	// Table Memo ////
	const _scrollPosesRef = useRef({});
	const setScrollPos = (storageMemoKey, scrollPos) => {
		_scrollPosesRef.current = { ..._scrollPosesRef.current, [storageMemoKey]: scrollPos };
	};
	const getScrollPos = storageMemoKey => {
		_scrollPosesRef.current[storageMemoKey] == null && setScrollPos(storageMemoKey, 0);
		return _scrollPosesRef.current[storageMemoKey];
	};

	const _scrollHorizontalPosesRef = useRef({});
	const setHorizontalScrollPos = (storageMemoKey, scrollPos) => {
		_scrollHorizontalPosesRef.current = {
			..._scrollHorizontalPosesRef.current,
			[storageMemoKey]: scrollPos,
		};
	};
	const getHorizontalScrollPos = storageMemoKey => {
		_scrollHorizontalPosesRef.current[storageMemoKey] == null &&
			setHorizontalScrollPos(storageMemoKey, 0);
		return _scrollHorizontalPosesRef.current[storageMemoKey];
	};

	const _scrollUpFlagRef = useRef(false);
	const shallScrollUp = () => {
		const ret = _scrollUpFlagRef.current;
		_scrollUpFlagRef.current = false;
		return ret;
	};

	const setScrollUpFlag = () => {
		_scrollUpFlagRef.current = true;
	};

	const userSettingValues = useUserSettings();
	const { userSettings, loading: loadingUserSettings } = userSettingValues;
	const _fetchSizesRef = useRef({});

	const _getDefaultFetchSize = () =>
		loadingUserSettings
			? null
			: !!userSettings && !!userSettings.defaultFetchSize
			? userSettings.defaultFetchSize
			: 100;

	const getFetchSize = storageMemoKey => {
		if (loadingUserSettings) return null;
		!_fetchSizesRef.current[storageMemoKey] &&
			(_fetchSizesRef.current[storageMemoKey] = _getDefaultFetchSize());

		return _fetchSizesRef.current[storageMemoKey];
	};

	const fetchMore = storageMemoKey => {
		const defaultFetchSize = _getDefaultFetchSize();
		if (defaultFetchSize != null) {
			const fetchSize = getFetchSize(storageMemoKey) + defaultFetchSize;
			_fetchSizesRef.current[storageMemoKey] = fetchSize;
			return fetchSize;
		} else return null;
	};

	const [focusedItem, _setFocusedItem] = useState({});

	const setFocusedItem = (storageMemoKey, value) =>
		_setFocusedItem({ ...focusedItem, [storageMemoKey]: value });

	const resetAll = () => {
		_fetchSizesRef.current = {};
		_scrollPosesRef.current = {};
		_scrollHorizontalPosesRef.current = {};
		setFocusedItem({});
	};

	// is true when refetches for table/detail havent completed after a moveEventsAction yet
	const [isLoadingData, setIsLoadingData] = useState(false);
	const [hasPendingMoveAction, setHasPendingMoveAction] = useState(false);

	// display leaflets config (sort and collapse)
	const [sortConfig, _setSortConfig] = useState(
		storage.getSortLeaflet() || {
			sortField: 'default',
		}
	);
	const { sortField, isDescending } = sortConfig || {};
	const setSortConfig = conf => {
		storage.setSortLeaflet(conf);
		_setSortConfig(conf);
	};

	const [collapsedLeaflets, _setCollapsedLeaflets] = useState(
		browserStorage.getCollapsedLeaflets() || []
	);
	const setCollapsedLeaflets = val => {
		browserStorage.setCollapsedLeaflets(val);
		_setCollapsedLeaflets(val);
	};

	const toggleSort = _sortField => {
		if (sortField === _sortField)
			setSortConfig({
				sortField,
				isDescending: !isDescending,
			});
		else
			setSortConfig({
				sortField: _sortField,
				isDescending: true,
			});
	};

	const allLeafletIdsRef = useRef([]);

	const handleOpenCollapse = id =>
		setCollapsedLeaflets(
			collapsedLeaflets.includes(id)
				? collapsedLeaflets.filter(_id => _id !== id)
				: [...collapsedLeaflets, id]
		);

	const handleOpenCollapseAll = () =>
		setCollapsedLeaflets(collapsedLeaflets.length !== 0 ? [] : allLeafletIdsRef.current);

	const store = {
		getHiddenFieldsFor,
		setHiddenFieldsFor,
		getFieldsOrderFor,
		setFieldsOrderFor,
		genericIDContext,
		genericTypeContext,
		setGenericIDContext,
		setGenericTypeContext,
		genericContext,
		setGenericContext,
		getUndoList,
		pushUndoList,
		popUndoList,
		clearUndoList,
		removeFailedEventsFromUndo,
		isLoadingData,
		setIsLoadingData,
		hasPendingMoveAction,
		setHasPendingMoveAction,
		tableMemo: {
			setScrollPos,
			getScrollPos,
			setHorizontalScrollPos,
			getHorizontalScrollPos,
			shallScrollUp,
			setScrollUpFlag,
			getFetchSize,
			fetchMore,
			resetAll,
			loading: loadingUserSettings,
			focusedItem,
			setFocusedItem,
		},
		displayLeafletsConfig: {
			sortConfig,
			collapsedLeaflets,
			toggleSort,
			allLeafletIdsRef,
			handleOpenCollapse,
			handleOpenCollapseAll,
		},
		userSettingValues,
	};

	return <AppContext.Provider value={store}>{children}</AppContext.Provider>;
};

// This hook should only be used in this context in order to assure that there are always current data.
const useUserSettings = () => {
	const local = browserStorage.getUserSettings();
	const isLocalOutput = !!local;

	const path = usePathname();
	const isLoggedOut =
		path.includes('login') ||
		path.includes('verify-password-reset') ||
		path.includes('reset-successful');

	const { data: requested, loading, error, refetch } = useQuery(USER_SETTINGS, {
		onCompleted: () => {
			if (!isLocalOutput && !!requested && !!requested.getUserSettings) {
				browserStorage.setUserSettings(requested.getUserSettings);
			}
		},
		skip: isLocalOutput || isLoggedOut,
	});

	const userSettings = isLocalOutput
		? local
		: !!requested && !!requested.getUserSettings
		? requested.getUserSettings
		: null;

	const [changeSettings] = useMutation(UPDATE_USER_SETTINGS);
	const setUserSettings = newSettings => {
		const _userSettings = { ...userSettings, ...newSettings };
		changeSettings({
			variables: {
				userSettings: omit(_userSettings, ['__typename']), // do not use this value it would otherwise lead to a bad request when sending it in UPDATE_USER_SETTINGS
			},
		});
		browserStorage.setUserSettings(_userSettings);
	};

	return { userSettings, loading, error, setUserSettings, refetch };
};

export default useUserSettings;

export { AppContext, AppProvider };
