import React, { useState } from 'react';

import ManageLeaflets from 'components/CustomLeaflet/ManageLeaflets';
import { LoadingContent, Msg } from 'components/Base';
import { RightSidebarLayout } from 'components/Layout';
import Meslis from 'components/Meslis';
import browserStorage from 'browserStorage';
import FullLayout from '../Layout/FullLayout';
import ManagementSidebar from './ManagementSidebar';
import { useTranslate } from 'customHooks';
import { Loader } from 'semantic-ui-react';
import { MAX_LEAFLET_COUNT } from './config';

const CustomLeaflet = () => {
	const [editableLeaflet, setEditableLeaflet] = useState(null);

	const handleActionCompleted = (leaflet, count) => {
		const newLeaflet = { leaflet: leaflet, totalCount: count };
		!!leaflet ? setEditableLeaflet(newLeaflet) : setEditableLeaflet(null);
	};

	const translate = useTranslate();

	return (
		<div className="CustomLeaflet" data-test="Manage Leaflets">
			<Meslis.CustomLeaflets>
				{({ loading, error, refetch: refetchCustomLeaflets, data = {} }) => {
					const leafletCount =
						!!data && !!data.getAllLeaflets
							? data.getAllLeaflets.filter(
									leaflet =>
										!!leaflet.creator && leaflet.creator.username === browserStorage.getUser()
							  ).length
							: 0;

					if (error) {
						// console.error(error);
					}

					const LeafletChangedSubscription = () => (
						<Meslis.SubscribeToLeafletChange
							fetchPolicy={'network-only'}
							componentId={1}
							onSubscriptionData={() => refetchCustomLeaflets(/* no params */)}
						/>
					);

					return (
						<RightSidebarLayout
							sidebar={
								loading ? (
									<Loader size="large" active />
								) : (
									<ManagementSidebar
										onActionCompleted={handleActionCompleted}
										leafletLimitReached={leafletCount > MAX_LEAFLET_COUNT}
										leaflets={data.getAllLeaflets}
										{...{ editableLeaflet }}
									/>
								)
							}>
							<FullLayout>
								{loading ? (
									<LoadingContent />
								) : error ? (
									<div>Error</div>
								) : !!data.getAllLeaflets && data.getAllLeaflets.length > 2 ? (
									<>
										<ManageLeaflets
											{...{ data, editableLeaflet, refetchCustomLeaflets }}
											onActionCompleted={handleActionCompleted}
										/>
										<LeafletChangedSubscription />
									</>
								) : (
									<Msg icon="frown outline" heading={translate('messages.noLeafletsHeader')}>
										{translate('messages.noLeafletsText')}
									</Msg>
								)}
							</FullLayout>
						</RightSidebarLayout>
					);
				}}
			</Meslis.CustomLeaflets>
		</div>
	);
};

export default CustomLeaflet;
