import React, { useState } from 'react';
import { Button, Icon, Popup } from 'semantic-ui-react';

import { downloadWithToken, urlForZip } from 'utils';
import { useTranslate } from 'customHooks';

const DownloadSelection = ({ data, refetchExports }) => {
	const [loading, setLoading] = useState(false);
	const translate = useTranslate();

	return data.isAvailable ? (
		<Button
			className="DownloadSelection"
			icon
			primary
			labelPosition="right"
			loading={loading}
			onClick={() => {
				setLoading(true);
				downloadWithToken(urlForZip(data.id), `${data.filename}.zip`)
					.then(() => refetchExports())
					.then(() => setLoading(false));
			}}>
			<Icon name="download" />
			{translate('actions.download')}
		</Button>
	) : (
		<Popup
			trigger={
				<Button className="DownloadSelection" icon color="orange" labelPosition="right">
					<Icon name="clock" />
					{translate('actions.stillProcessing')}
				</Button>
			}
			on="click"
			position="bottom center"
			inverted
			popperModifiers={[{ name: 'preventOverflow', options: { boundry: 'window' } }]}
			content="This file is still being created."
		/>
	);
};

export default DownloadSelection;
