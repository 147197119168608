import React, { useRef } from 'react';
import { DateRangeInput as BaseDateTimeRangeInput } from 'components/Base';

const DateTimeRangeInput = ({ onChange, value, ...props }) => {
	const valueRef = useRef(value);

	const handleChangeFrom = (_value, valid) => {
		const newVal = { ...valueRef.current, from: _value };
		if (valueRef.current.from !== newVal.from) {
			valueRef.current = newVal;

			onChange(newVal, valid);
		}
	};
	const handleChangeTo = (_value, valid) => {
		const newVal = { ...valueRef.current, to: _value };
		if (valueRef.current.to !== newVal.to) {
			valueRef.current = newVal;

			onChange(newVal, valid);
		}
	};

	return (
		<BaseDateTimeRangeInput
			{...{ ...props, value }}
			onChangeFrom={handleChangeFrom}
			onChangeTo={handleChangeTo}
		/>
	);
};

export default DateTimeRangeInput;
