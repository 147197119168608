import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import { useTranslate, useQueryParams } from 'customHooks';

const RedirectToStepSelect = ({
	children,
	id,
	type,
	view,
	popupPosition = 'right center',
	isNewWindow = true,
	isNoOldParams = true,
}) => {
	const translate = useTranslate();
	const { pushURL, location: urlLocation } = useQueryParams();
	// Should construct the url that will pass infomation such as redirectID(location/organizer ID) in order to open new tab ("location select/organizer select").
	const handleClick = () => {
		pushURL({
			isNewWindow,
			rerouteTo: `${type}s`,
			isNoOldParams,
			view: 'table',
			step: 'select',
			redirectID: id,
		});
	};
	// It will render the component based on the view prop
	const RedirectIcon = () => (
		<Popup
			content={translate(
				`actions.${{ organizer: 'redirectToOrganizer', location: 'redirectToLocation' }[type]}`
			)}
			trigger={<Icon className="redirect-icon" name="mail forward" onClick={handleClick} />}
			position={popupPosition}
			mouseEnterDelay={400}
		/>
	);

	return view === 'table' ? (
		<span className="redirect-to-select">
			{children}
			{/* Check the current url to assert if it is in the location table view or if the type prop on the component is location, in which case it will not render the reroute icon */}
			{urlLocation.pathname !== '/locations' || type !== 'location' ? <RedirectIcon /> : null}
		</span>
	) : (
		<RedirectIcon />
	);
};

export default RedirectToStepSelect;
