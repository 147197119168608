import React from 'react';
import { Query } from '@apollo/client/react/components';

import { USERS_OF_COMPANY } from 'graphql/queries';

const Users = ({ children, ...props }) => (
	<Query query={USERS_OF_COMPANY} {...props}>
		{children}
	</Query>
);

export default Users;
