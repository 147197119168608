import React, { useState, useLayoutEffect, useRef } from 'react';
import { Popup, Icon, Input } from 'semantic-ui-react';
import {
	ParsedText,
	Rating,
	getMergedItem,
	getMergedItems,
	AuthIFrame,
	ItemArrayList,
	ColoredIdSwitcher,
	GenericDropdownInput,
	BlocksInput,
	LocationInput,
	OrganizerInput,
	PositiveIntegerInput,
	ImageUploader,
	InfoPopup,
	RedirectToStepSelect,
	RegionInput,
	CountryInput,
	CategoryInput,
} from 'components/Base';
import Button from 'components/Button';
import browserStorage from 'browserStorage';
import {
	urlForPdf,
	analyseMergedEvent,
	isSelectableAndUnlocked,
	correctURL,
	inputValue,
	buildStreetHouseNumberFromAddress,
} from 'utils';
import { useDelay, useTranslateContext, useUserContext, useQueryParams } from 'customHooks';
import { NEW_EVENT_ID, MAX_RATING, NO_REGIONS } from 'config';
import { setHighlightItems } from 'components/Base/MergedItem';
import DateRangeInput from 'components/Base/DateRangeInput'; // DateRangeInput in Base same as in Forms
import classNames from 'classnames';

import useMergeEventDetail from './useMergeEventDetail';
import useDragDropEventDetail from './useDragDropEventDetail';
import EventDetailContent from './EventDetailContent';
import SetListTab from './SetListTab';
import DraggingDimmer from './DraggingDimmer';
import SelectOrganizerModal from './SelectOrganizerModal';
import useCreateUpdateEventDetail from './useCreateUpdateEventDetail';

import './index.scss';
import './indexCreateUpdate.scss';

export default ({
	data,
	onRefresh,
	onChangeValue,
	genericID,
	genericType,
	isUpdateMode,
	setIsUpdateMode,
	hiddenFields = [],
	...props
}) => {
	const { translate, formatTimeStamp } = useTranslateContext();
	const { user, getAvailableTime } = useUserContext();
	const [changedData, setChangedData] = useState();
	const { location: urlLocation } = useQueryParams();

	data = changedData || data || newDataElement();
	(!data.advertisements || !data.advertisements.results) &&
		(data.advertisements = { results: [], totalCount: 0 });

	const [isWaitingForUpdate, _setIsWaitingForUpdate] = useState(false);
	const setIsWaitingForUpdate = isWaiting => {
		const _isMergedMode = !isWaiting && wasMergedModeRef.current;
		wasMergedModeRef.current = isMergedMode;
		setShownAdStates({ isMergedMode: _isMergedMode, shownAdIdx, adOffset });
		_setIsWaitingForUpdate(isWaiting);
	};

	// functionality for merged data //////////////////////////////////////////
	const {
		adsWrapper,
		shownAdIdx,
		shownAdWrapper,
		isMergedMode,
		mergingData,
		adOffset,
		isInconsistent,
		setShownAdIdx,
		setShownAdStates,
		forwardAdInfoBlock,
		forwardAdIdSwitcher,
		backwardAdInfoBlock,
		backwardAdIdSwitcher,
		toggleMergedModeOfAd,
		toggleMergedMode,
	} = useMergeEventDetail({ data, isWaitingForUpdate });

	const { event: shownData } = mergingData;

	// drag and drop of advertisements //////////////////////////////////////////////
	const {
		moveModeRef,
		showDimmer,
		onDragStart,
		onDragStartAll,
		onDragEnd,
		onDragEndAll,
		onDrop,
		onDragEnter,
		onEscape,
	} = useDragDropEventDetail({
		data,
		setChangedData,
		isWaitingForUpdate,
		setIsWaitingForUpdate,
		onRefresh,
		...props,
	});

	const wasMergedModeRef = useRef();

	// functionality for edit event fields //////////////////////////////////////////////
	const {
		allowDropAds,
		changedValue,
		onChangeValue: _onChangeValue,
		isUpdatedField,
		isCreateNewLocation,
		setIsCreateNewLocation,
		isCreateNewOrganizer,
		setIsCreateNewOrganizer,
	} = useCreateUpdateEventDetail({
		isUpdateMode,
		setIsUpdateMode,
		genericID,
		genericType,
		onChangeValue,
		initialData: data,
		onRefresh,
	});

	const isShowInput = isUpdateMode || shownData.id === NEW_EVENT_ID;

	const isRenderField = field => !hiddenFields.includes(field);

	// logic to conditionally render components such as: StatusName
	const isDetailView = !!urlLocation?.search?.includes('view=detail');

	// shown organizers /////////////////////////////////////////////////////////
	const DEFAULT_ORGANIZER_IDS = analyseMergedEvent.getOrganizerIds(data).slice(0, 1);
	const [shownOrganizers, setShownOrganizers] = useState(DEFAULT_ORGANIZER_IDS);
	const toggleOrganizer = id =>
		setShownOrganizers(
			shownOrganizers.includes(id)
				? shownOrganizers.filter(_id => _id !== id)
				: shownOrganizers.concat(id)
		);

	useLayoutEffect(
		() => setShownOrganizers(DEFAULT_ORGANIZER_IDS),

		// eslint-disable-next-line
		[data.id]
		// only update if data has changed, no other dependencies
	);

	// ad labels ///////////////////////////////////////////////////////////////
	const [onMouseIn, onMouseOut] = useDelay(200);
	const handleMouseInOut = (isIn, id) => () =>
		(isIn ? onMouseIn : onMouseOut)(() => setHighlightItems(isIn, id));

	const adIndexLabels =
		!isInconsistent &&
		adsWrapper.length > 1 /* if just one ad admit that mergedEvent data and ad data are the same */
			? adsWrapper.map(({ id, shownIdx, ...props }, idx) => (
					<span
						onClick={() => {
							window.getSelection().type !== 'Range' &&
								toggleMergedModeOfAd({ id, shownIdx, ...props });
							handleMouseInOut(false, id)();
						}}
						onMouseEnter={handleMouseInOut(true, id)}
						onMouseLeave={handleMouseInOut(false, id)}
						className={`colored-idx id-${id}`}
						data-test="colored-idx"
						style={
							mergingData.idColors
								? {
										color:
											isMergedMode || id === shownAdWrapper.id ? mergingData.idColors[id] : 'gray',
								  }
								: {}
						}
						key={idx}>
						{shownIdx}
					</span>
			  ))
			: null;

	// "isInconsistent" can happen when data in EventDetail/index has changed but shownAdWrapper
	// has not yet been updated in useLayoutEffect.
	// Because of useLayoutEffect <></> is rendered but never shown.
	if (isInconsistent) return <></>;
	return (
		<DraggingDimmer
			{...{
				show: showDimmer,
				moveMode: moveModeRef.current,
				eventData: shownData,
				adData: shownAdWrapper,
				onDragStartMoveAd: e => onDragStart({ e, ads: [shownAdWrapper.ad], effectAllowed: 'move' }),
				onDragStartCopyAd: e => onDragStart({ e, ads: [shownAdWrapper.ad], effectAllowed: 'copy' }),
				onDragEndAd: e => onDragEnd({ e, ids: [shownAdWrapper.id] }),
				onDragStartMoveEvent: e => onDragStartAll({ e, effectAllowed: 'move' }),
				onDragStartCopyEvent: e => onDragStartAll({ e, effectAllowed: 'copy' }),
				onDragEndEvent: e => onDragEndAll({ e }),
				onDrop,
				onEscape,
			}}>
			<EventDetailContent
				event={
					isShowInput
						? getEventInputs({
								value: changedValue,
								onChangeValue: _onChangeValue,
								shownData,
								formatTimeStamp,
								translate,
								availableTime: getAvailableTime(),
								isUpdatedField,
								isUpdateMode,
						  })
						: getEventLabels({
								mergingData,
								data,
								isMergedMode,
								isShowInput,
								formatTimeStamp,
								translate,
						  })
				}
				location={
					isShowInput
						? getLocationInputs({
								value: changedValue,
								onChangeValue: _onChangeValue,
								isCreateNewLocation,
								setIsCreateNewLocation,
								isUpdatedField,
								translate,
								formatTimeStamp,
								isUpdateMode,
								isDetailView,
						  })
						: getLocationLabels({ mergingData, formatTimeStamp })
				}
				organizers={
					isShowInput
						? [
								getOrganizerInputs({
									value: changedValue,
									onChangeValue: _onChangeValue,
									isCreateNewOrganizer,
									setIsCreateNewOrganizer,
									isUpdatedField,
									translate,
									formatTimeStamp,
									isUpdateMode,
									isDetailView,
								}),
						  ]
						: getOrganizersLabels({
								mergingData,
								data,
								toggleOrganizer,
								shownOrganizers,
								formatTimeStamp,
						  })
				}
				selectOrganizerModal={
					isSelectableAndUnlocked(data, user?.username) && (
						<SelectOrganizerModal
							selectedOrganizerID={data.selectedOrganizer && data.selectedOrganizer.id}
							organizers={data.organizers || []}
							eventId={data.id}
							version={data.version}
							onRefresh={onRefresh}
						/>
					)
				}
				panes={
					!!isShowInput
						? getTabPaneInputs({
								value: changedValue,
								onChangeValue: _onChangeValue,
								isUpdateMode,
								isUpdatedField,
								translate,
						  })
						: getTabPanes({ shownData, translate })
				}
				placeholdertext={
					allowDropAds && isShowInput ? (
						<div className="draggingPlaceholder">
							<Icon size="massive" name="folder open outline" />
							<h1>{translate('placeholders.dragEventAdsOrInput')}</h1>
						</div>
					) : !data?.advertisements?.results?.length && !isShowInput ? (
						<div className="draggingPlaceholder">
							<Icon size="massive" name="boxes" />
							<h1>{translate('placeholders.allAdsRemoved')}</h1>
						</div>
					) : undefined
				}
				tabSubHeader={
					!isShowInput && (
						<div className="tab-sub-header" data-test="tab-sub-header">
							<ColoredIdSwitcher
								{...{
									adsWrapper,
									adOffset,
									forwardAd: forwardAdIdSwitcher,
									backwardAd: backwardAdIdSwitcher,
									shownAdWrapper,
									onClickIdx: setShownAdIdx,
									idColors: mergingData.idColors,
								}}
								style={{ visibility: adsWrapper.length > 1 ? 'visible' : 'hidden' }}
							/>
							{adsWrapper.length > 0 && (
								<div className="source-meta">
									{translate('detailView.ad')}{' '}
									{adsWrapper.length > 1 ? `${shownAdWrapper.shownIdx} ` : ' '} [
									{translate('detailView.id')}: {shownAdWrapper.id},{' '}
									{formatTimeStamp(shownData.adTimestamp, true)}]{' '}
								</div>
							)}
						</div>
					)
				}
				mergedDataProps={
					!isShowInput
						? {
								adsColor: isMergedMode ? 'inherit' : mergingData.idColors[shownAdWrapper.id],
								toggleMergedMode: adsWrapper.length > 1 ? toggleMergedMode : undefined,
								isWaitingForUpdate,
								isMergedMode,
								showForwardBackward: adsWrapper.length > 2,
								adIndexLabels,
								adOffset,
								forwardAdInfoBlock,
								backwardAdInfoBlock,
								adCount: adsWrapper.length,
						  }
						: { adCount: adsWrapper.length }
				}
				handleDragEnter={allowDropAds ? onDragEnter : undefined}
				{...{ isShowInput, isRenderField }}
			/>
		</DraggingDimmer>
	);
};

export const setHighlightColoredIdx = (isIn, adIds) => {
	if (!!adIds) {
		document
			.querySelectorAll(
				'.colored-idx' + ['', ...adIds.map(id => `:not(.id-${id})`)].reduce((txt, id) => txt + id)
			)
			.forEach(el => {
				if (isIn) {
					el.style.colorMemory = el.style.color;
					el.style.color = '#dddddd';
				} else if (!!el.style.colorMemory) {
					el.style.color = el.style.colorMemory;
					el.style.colorMemory = null;
				}
			});
	}
};

// configurations //////////////////////////////////////////////////////////////////////////////////////////

const newDataElement = () => ({
	id: NEW_EVENT_ID,
	rating: MAX_RATING,
	advertisements: { totalCount: 0, results: [] },
});

// rendered items for EventDetailContent //////////////////////

const getEventLabels = ({
	mergingData,
	data,
	isMergedMode,
	isShowInput,
	formatTimeStamp,
	translate,
}) => {
	const { event: shownData } = mergingData;
	return {
		id: !isShowInput ? shownData.id : null,
		timestamp: isMergedMode
			? getMergedItem(mergingData, 'timestamp', {
					map: t => formatTimeStamp(t, true),
			  })
			: formatTimeStamp(shownData.timestamp, true),
		rating: <Rating value={shownData.rating} size={'huge'} />,
		name: (
			<Popup
				trigger={
					<h1 className="break">
						<a
							target="_blank"
							rel="noopener noreferrer"
							// shown link
							href={correctURL(shownData.titleURL)}>
							{getMergedItem(mergingData, 'name')}
						</a>
					</h1>
				}
				position="bottom center"
				content={shownData.name}
			/>
		),
		date: (
			<>
				{getMergedItem(mergingData, 'startDate', {
					map: val => val && `${translate('detailView.from')} ${formatTimeStamp(val)}`,
				})}{' '}
				{getMergedItem(mergingData, 'endDate', {
					map: val => val && `${translate('detailView.to')} ${formatTimeStamp(val)}`,
				})}
			</>
		),
		statusName: data.eventStatus?.name,
		statusUsername: data.eventStatus?.user?.username,
		statusLastChangedAt: formatTimeStamp(data.eventStatus?.lastChangedAt, true),
		invoiceNumber: data.invoice?.invoiceNumber,
		itemNumber: data.invoice?.itemNumber,
		customIds:
			!!data.customer && !!data.customer.events ? (
				<ItemArrayList
					data={data.customer.events.map(
						ev =>
							(!!ev.id ? ev.id : '') + (!!ev.processingStatus ? ` (${ev.processingStatus})` : '')
					)}
				/>
			) : (
				<></>
			),
		admission: getMergedItems(mergingData, 'admission', { map: x => x.price, comma: true }),
		artists: data.artists && (
			<ItemArrayList
				data={getMergedItems(mergingData, 'artists', {
					map: artist => (artist.score ? `${artist.name} (${artist.score})` : artist.name),
				})}
			/>
		),
		lineup: <ItemArrayList data={getMergedItems(mergingData, 'lineup')} />,
		visitorCount: getMergedItem(mergingData, 'visitorCount'),
		types: data.types && data.types.join(', '),
		url: (
			<ItemArrayList
				data={getMergedItems(mergingData, 'url', {
					map: url => (
						<a href={correctURL(url)} target="_blank" rel="noopener noreferrer">
							{url}
						</a>
					),
				})}
			/>
		),
		isCancelled: getMergedItem(mergingData, 'isCancelled', {
			map: isCancelled =>
				isCancelled
					? translate('filters.boolean.true')
					: isCancelled === false
					? translate('filters.boolean.false')
					: '',
		}),
		isLocationSameAsOrganizer: getMergedItem(mergingData, 'isLocationSameAsOrganizer', {
			map: isLocationSameAsOrganizer =>
				isLocationSameAsOrganizer
					? translate('filters.boolean.true')
					: isLocationSameAsOrganizer === false
					? translate('filters.boolean.false')
					: '',
		}),
	};
};
const getLocationLabels = ({ mergingData, formatTimeStamp }) => {
	const { event: shownData } = mergingData;
	const {
		timestamp,
		address,
		urls,
		matchedLocationIds,
		matchedLocationId,
		emailAddresses,
		phoneNumbers,
		...location
	} = shownData.location || {};

	return {
		// render as fragment to not be falsy (render condition in OrganizerData.js)
		statusName: <>{shownData.location?.locationStatus?.name}</>,
		statusUsername: <>{shownData.location?.locationStatus?.user?.username}</>,
		statusLastChangedAt: (
			<>{formatTimeStamp(shownData.location?.locationStatus?.lastChangedAt, true)}</>
		),
		matchedLocationIds: !!matchedLocationId
			? matchedLocationId
			: matchedLocationIds && <ItemArrayList data={matchedLocationIds} />,
		emailAddresses: emailAddresses && <ItemArrayList data={emailAddresses} />,
		phoneNumbers: phoneNumbers && <ItemArrayList data={phoneNumbers} />,
		functionButton: (
			<>
				<RedirectToStepSelect
					type="location"
					id={shownData.location?.id}
					view="detail"
					popupPosition="top center"
				/>
				{shownData.location && (
					<Button.CreateUpdateGeneric
						type="location"
						isUpdateMode
						initialData={shownData.location}
						triggerType="icon"
						popupPosition="top center"
					/>
				)}
			</>
		),
		timestamp: formatTimeStamp(timestamp, true),
		address: {
			streetHouseNumber: buildStreetHouseNumberFromAddress(shownData.location?.address),
			...address,
		},
		urls: (
			<ItemArrayList
				data={urls?.map((url, idx) => (
					<a href={correctURL(url)} target="_blank" rel="noopener noreferrer" key={idx}>
						{correctURL(url) && url}
					</a>
				))}
			/>
		),
		...location,
	};
};

/**
 * Function to get labels for organizers.
 *
 * @param {Object} params - Parameters containing merging data, data, toggle function, shown organizers, and timestamp formatter.
 * @param {Object} params.mergingData - Data that includes event information and merging details.
 * @param {Object} params.data - Data that includes selected organizer.
 * @param {Function} params.toggleOrganizer - Function to toggle the visibility of an organizer.
 * @param {Array} params.shownOrganizers - List of organizer IDs that are currently shown.
 * @param {Function} params.formatTimeStamp - Function to format timestamps.
 * @returns {Array} List of organizers with enhanced properties and UI components.
 */
const getOrganizersLabels = ({
	mergingData,
	data,
	toggleOrganizer,
	shownOrganizers,
	formatTimeStamp,
}) => {
	// Destructure event data from mergingData
	const { event: shownData } = mergingData;

	// Function to create a span element for the organizer ID with toggle functionality
	const createOrganizerSpan = organizer => (
		<span
			onClick={() => toggleOrganizer(organizer.id)}
			className={
				shownData.organizers?.length > 1
					? classNames('clickable-id', {
							shown: shownOrganizers.includes(organizer.id),
					  })
					: ''
			}>
			{organizer.id}
		</span>
	);

	// Get merged organizers with the span element added for each organizer
	const mergedOrganizers = getMergedItems(mergingData, 'organizers', {
		map: createOrganizerSpan,
	});

	// Function to create a function button for an organizer
	const createFunctionButton = organizer => (
		<>
			<RedirectToStepSelect
				type="organizer"
				id={organizer?.id}
				view="detail"
				popupPosition="top center"
			/>
			{organizer && (
				<Button.CreateUpdateGeneric
					type="organizer"
					isUpdateMode
					initialData={organizer}
					triggerType="icon"
					popupPosition="top center"
				/>
			)}
		</>
	);

	// Function to create URLs as anchor elements
	const createUrls = urls =>
		urls?.map((url, idx) => (
			<a href={correctURL(url)} target="_blank" rel="noopener noreferrer" key={idx}>
				{url}
			</a>
		));

	// Map organizers to the desired format with additional UI elements and properties
	const mapOrganizers = (organizerId, index) => {
		const organizer = shownData.organizers[index];

		return {
			...organizer,
			id: organizerId,
			functionButton: createFunctionButton(organizer),
			_id: organizer?.id,
			timestamp: formatTimeStamp(organizer?.timestamp, true),
			statusName: <>{organizer?.organizerStatus?.name}</>,
			statusUsername: <>{organizer?.organizerStatus?.user?.username}</>,
			statusLastChangedAt: <>{formatTimeStamp(organizer?.organizerStatus?.lastChangedAt, true)}</>,
			address: {
				streetHouseNumber: buildStreetHouseNumberFromAddress(organizer?.address),
				...(organizer?.address || {}),
			},
			urls: createUrls(organizer?.urls),
			shown: shownOrganizers.includes(organizer?.id),
		};
	};

	// Filter function to include only the selected organizer if applicable
	const filterSelectedOrganizer = organizer =>
		!data.selectedOrganizer?.id || (organizer._id && organizer._id === data.selectedOrganizer?.id);

	// Enhance organizer with additional properties for UI display
	const enhanceOrganizer = ({
		matchedOrganizerId,
		matchedOrganizerIds,
		emailAddresses,
		phoneNumbers,
		urls,
		...organizer
	}) => {
		const enhancedOrganizer = {
			matchedOrganizerId:
				matchedOrganizerId || (matchedOrganizerIds && <ItemArrayList data={matchedOrganizerIds} />),
			emailAddresses: emailAddresses && <ItemArrayList data={emailAddresses} />,
			phoneNumbers: phoneNumbers && <ItemArrayList data={phoneNumbers} />,
			urls: urls && <ItemArrayList data={urls} />,
			...organizer,
		};

		return enhancedOrganizer;
	};

	// Iterate over merged organizers and shown organizers to map, filter, and enhance them
	return mergedOrganizers
		.map((organizer, index) => mapOrganizers(organizer, index))
		.filter(filterSelectedOrganizer)
		.map(enhanceOrganizer);
};

const getTabPanes = ({ shownData, translate }) => [
	{
		menuItem: translate('detailView.viewPDF'),
		tag: (
			<AuthIFrame
				className="pdfViewer"
				key={`${shownData.id} ${shownData.pdfId}`}
				src={urlForPdf(shownData.pdfId)}
				token={browserStorage.getToken()}
				type="application/pdf"
			/>
		),
	},
	{
		menuItem: translate('detailView.viewDescription'),
		tag: (
			<div className="descriptions-frame">
				<ParsedText>{shownData.description}</ParsedText>
			</div>
		),
	},
	{
		menuItem: translate('detailView.viewSetlists'),
		tag: <SetListTab data={!!shownData.setlists ? shownData.setlists.results : []} />,
		disabled: !shownData?.setlists?.results?.length,
	},
];

// inputs for EventDetailContent (createNewEvent, updateEvent) //////////////////////
const getEventInputs = ({
	value,
	onChangeValue,
	shownData: { id, timestamp, rating },
	formatTimeStamp,
	translate,
	availableTime,
	isUpdatedField,
	isUpdateMode,
}) => {
	return {
		id: id !== NEW_EVENT_ID ? id : undefined,
		timestamp: timestamp ? formatTimeStamp(timestamp, true) : false,
		rating: <Rating value={isUpdateMode ? rating : MAX_RATING} size={'huge'} />, // It assures that all created events are relevant as music events.
		name: (
			<h1 className="break">
				<Input
					className={classNames('name', isUpdatedField('name') && 'isUpdated', 'mandatory')}
					data-test="eventNameInput"
					value={value.name || ''}
					onChange={(_, { value }) => onChangeValue('name', value)}
					placeholder={`${translate('detailView.name')} *`}
				/>
			</h1>
		),
		date: (
			<DateRangeInput
				classNameFrom={classNames(
					isUpdatedField('startDate') && 'isUpdated',
					'mandatory',
					'dateInputFrom'
				)}
				classNameTo={classNames(
					isUpdatedField('endDate') && 'isUpdated',
					'mandatory',
					'dateInputTo'
				)}
				value={{ from: value.startDate, to: value.endDate }}
				onChangeFrom={(value, valid) => onChangeValue('startDate', value, valid)}
				onChangeTo={(value, valid) => onChangeValue('endDate', value, valid)}
				fromProps={{ placeholder: `${translate('detailView.from')} *`, iconPosition: 'right' }}
				toProps={{ placeholder: translate('detailView.to'), iconPosition: 'right' }}
				minDate={availableTime.minDate}
				maxDate={availableTime.maxDate}
			/>
		),
		admission: (
			<BlocksInput
				className={isUpdatedField('admission') && 'isUpdated'}
				inputType="Currency"
				isArray
				value={value.admission || []} /* for adInput/ eventInput it is NOT wrapped into {price} */
				onChange={(value, valid) => onChangeValue('admission', value, valid)}
			/>
		),
		artists: undefined,
		lineup: undefined,
		visitorCount: (
			<PositiveIntegerInput
				className={isUpdatedField('visitorCount') && 'isUpdated'}
				value={value.visitorCount || ''}
				onChange={(_, { value }) =>
					onChangeValue('visitorCount', value, value === '' || value >= 0)
				}
			/>
		),
		types: (
			<GenericDropdownInput
				className={isUpdatedField('types') && 'isUpdated'}
				value={value.types || []}
				onChange={value => onChangeValue('types', value)}
				allowMultiple={true}
				type="eventTypes"
			/>
		),
		statusName: isUpdateMode ? (
			<GenericDropdownInput
				className={isUpdatedField('eventStatus.name') && 'isUpdated'}
				value={value.eventStatus?.id}
				onChange={value => {
					onChangeValue('eventStatus.id', value);
					onChangeValue('eventStatus.name', value);
				}}
				type="eventStatus"
			/>
		) : undefined,
		statusUsername: undefined,
		statusLastChangedAt: undefined,
		invoiceNumber: isUpdateMode ? (
			<PositiveIntegerInput
				className={isUpdatedField('invoice.invoiceNumber') && 'isUpdated'}
				value={value.invoice?.invoiceNumber || ''}
				onChange={(_, { value }) => onChangeValue('invoice.invoiceNumber', value)}
				placeholder={'0-9'}
				maxSize="10"
			/>
		) : undefined,
		itemNumber: isUpdateMode ? (
			<PositiveIntegerInput
				className={isUpdatedField('invoice.itemNumber') && 'isUpdated'}
				value={value.invoice?.itemNumber || ''}
				onChange={(_, { value }) => onChangeValue('invoice.itemNumber', value)}
				placeholder={'0-9'}
				maxSize="10"
			/>
		) : undefined,
		url: (
			<InfoPopup
				className={isUpdatedField('url') && 'isUpdated'}
				content={translate('messages.urlsElseWhere')}
			/>
		),
		isCancelled: undefined,
		isLocationSameAsOrganizer: undefined,
	};
};

const sanitizeMatchedCustomerIds = ids => {
	if (!ids) {
		return [];
	}
	if (ids.length === 1 && ids[0].includes(',')) {
		return ids[0].split(',').map(id => id.trim());
	}
	return ids;
};

export const getLocationInputs = ({
	value,
	onChangeValue,
	isCreateNewLocation,
	setIsCreateNewLocation,
	isUpdatedField,
	translate,
	formatTimeStamp,
	isUpdateMode,
	isDetailView,
}) => {
	const header = (
		<Button.ToggleIsCreate
			isCreateNew={isCreateNewLocation}
			setIsCreateNew={value => {
				setIsCreateNewLocation(value);
				onChangeValue('editedLocation.isCreateNewLocation', value);
			}}
			type="location"
		/>
	);

	// create new location or update location /////////////////////////////////////////////////////////////////
	return isCreateNewLocation
		? {
				header,
				id: '?',
				name: (
					<Input
						className={classNames(
							{ isUpdated: isUpdatedField('editedLocation.name') },
							'mandatory'
						)}
						data-test="locData-Name-Input"
						value={inputValue(value?.editedLocation?.name)}
						onChange={(_, { value }) => onChangeValue('editedLocation.name', value)}
						placeholder={`${translate('detailView.name')} *`}
					/>
				),
				timestamp: false,
				matchedLocationIds: (
					<BlocksInput
						inputType="String"
						data-test="locData-Customer-Ids"
						className={classNames({
							isUpdated: isUpdatedField('editedLocation.matchedLocationIds'),
						})}
						isArray
						value={sanitizeMatchedCustomerIds(value?.editedLocation?.matchedLocationIds)}
						onChange={(value, valid) =>
							onChangeValue('editedLocation.matchedLocationIds', value, valid)
						}
					/>
				),
				commercialId: (
					<Input
						className={classNames({ isUpdated: isUpdatedField('editedLocation.commercialId') })}
						value={inputValue(value?.editedLocation?.commercialId)}
						onChange={(_, { value }) => onChangeValue('editedLocation.commercialId', value)}
					/>
				),
				address: {
					streetHouseNumber: (
						<>
							<Input
								className={classNames(
									'address street',
									{
										isUpdated: isUpdatedField('editedLocation.address.street'),
									},
									'mandatory'
								)}
								data-test="locData-Address-Street"
								placeholder={`${translate('detailView.street')} *`}
								value={inputValue(value?.editedLocation?.address?.street)}
								onChange={(_, { value }) => onChangeValue('editedLocation.address.street', value)}
							/>
							<Input
								className={classNames(
									'address houseNumber',
									{
										isUpdated: isUpdatedField('editedLocation.address.houseNumber'),
									},
									!isUpdateMode && 'mandatory'
								)}
								data-test="locData-Address-HouseNumber"
								placeholder={
									isUpdateMode
										? `${translate('detailView.houseNumber')}`
										: // create mode
										  `${translate('detailView.houseNumber')} *`
								}
								value={inputValue(value?.editedLocation?.address?.houseNumber)}
								onChange={(_, { value }) =>
									onChangeValue('editedLocation.address.houseNumber', value)
								}
							/>
						</>
					),
					postalCodeCity: (
						<>
							<Input
								className={classNames(
									'address postalCode',
									{
										isUpdated: isUpdatedField('editedLocation.address.postalCode'),
									},
									'mandatory'
								)}
								data-test="locData-Address-PostalCode"
								placeholder={`${translate('detailView.postalCode')} *`}
								value={inputValue(value?.editedLocation?.address?.postalCode)}
								onChange={(_, { value }) =>
									onChangeValue('editedLocation.address.postalCode', value)
								}
							/>
							<Input
								className={classNames(
									'address city',
									{
										isUpdated: isUpdatedField('editedLocation.address.city'),
									},
									'mandatory'
								)}
								data-test="locData-Address-City"
								placeholder={`${translate('detailView.city')} *`}
								value={inputValue(value?.editedLocation?.address?.city)}
								onChange={(_, { value }) => onChangeValue('editedLocation.address.city', value)}
							/>
						</>
					),
					region: (
						<RegionInput
							className={classNames(
								'address',
								{
									isUpdated: isUpdatedField('editedLocation.address.region'),
								},
								'mandatory'
							)}
							placeholder={`${translate('detailView.region')} *`}
							value={value?.editedLocation?.address?.region}
							onChange={value => onChangeValue('editedLocation.address.region', value)}
							countryValue={value?.editedLocation?.address?.country}
						/>
					),
					country: (
						<CountryInput
							className={classNames(
								'address',
								{
									isUpdated: isUpdatedField('editedLocation.address.country'),
								},
								'mandatory'
							)}
							placeholder={`${translate('detailView.country')} *`}
							value={value?.editedLocation?.address?.country}
							onChange={value => onChangeValue('editedLocation.address.country', value)}
						/>
					),
				},
				category: (
					<CategoryInput
						className={classNames({ isUpdated: isUpdatedField('editedLocation.category') })}
						value={value?.editedLocation?.category}
						onChange={value => onChangeValue('editedLocation.category', value)}
					/>
				),
				emailAddresses: (
					<BlocksInput
						inputType="Email"
						className={classNames({ isUpdated: isUpdatedField('editedLocation.emailAddresses') })}
						isArray
						value={value?.editedLocation?.emailAddresses || []}
						onChange={(value, valid) =>
							onChangeValue('editedLocation.emailAddresses', value, valid)
						}
					/>
				),
				phoneNumbers: (
					<BlocksInput
						inputType="Phone"
						className={classNames({ isUpdated: isUpdatedField('editedLocation.phoneNumbers') })}
						isArray
						value={value?.editedLocation?.phoneNumbers || []}
						onChange={(value, valid) => onChangeValue('editedLocation.phoneNumbers', value, valid)}
					/>
				),
				urls: (
					<BlocksInput
						inputType="URL"
						className={classNames({ isUpdated: isUpdatedField('editedLocation.urls') })}
						isArray
						value={value?.editedLocation?.urls || []}
						onChange={(value, valid) => onChangeValue('editedLocation.urls', value, valid)}
					/>
				),
				statusName:
					isUpdateMode && !isDetailView ? (
						<GenericDropdownInput
							className={classNames({
								isUpdated: isUpdatedField('editedLocation.locationStatus.name'),
							})}
							value={value?.editedLocation?.locationStatus?.id}
							onChange={value => {
								onChangeValue('editedLocation.locationStatus.id', value);
								onChangeValue('editedLocation.locationStatus.name', value);
							}}
							type="locationStatus"
						/>
					) : undefined,
		  }
		: // event detail: select existing location /////////////////////////////////////////////////////////////////
		  {
				header,
				...(value?.location || {}),
				id: (
					<>
						<LocationInput
							className={classNames({ isUpdated: isUpdatedField('location.id') }, 'mandatory')}
							data-test="LocationId"
							onChange={(value, valid) => onChangeValue('location', value, valid)}
							value={value?.location}
							fieldName={'id'}
							isGrowingInput
						/>
						{' *'}
					</>
				),
				matchedLocationIds: (
					<LocationInput
						className={classNames({ isUpdated: isUpdatedField('location.matchedLocationIds') })}
						onChange={(value, valid) => onChangeValue('location', value, valid)}
						value={value?.location}
						fieldName={'matchedLocationIds'}
						placeholder={translate('actions.searchLocationByCustomerId')}
					/>
				),
				commercialId: (
					<LocationInput
						className={classNames({ isUpdated: isUpdatedField('location.commercialId') })}
						onChange={(value, valid) => onChangeValue('location', value, valid)}
						value={value?.location}
						fieldName={'commercialId'}
						placeholder={translate('actions.searchLocationByCommercialId')}
					/>
				),
				timestamp: formatTimeStamp(value?.location?.timestamp, true),
				address: {
					streetHouseNumber: buildStreetHouseNumberFromAddress(value?.location?.address),
					...(value?.location?.address || {}),
				},
				// render as fragment to not be falsy (render condition in OrganizerData.js)
				statusName: <>{value.location?.locationStatus?.name}</>,
				statusUsername: <>{value.location?.locationStatus?.user?.username}</>,
				statusLastChangedAt: (
					<>{formatTimeStamp(value.location?.locationStatus?.lastChangedAt, true)}</>
				),
		  };
};

export const getOrganizerInputs = ({
	value,
	onChangeValue,
	isCreateNewOrganizer,
	setIsCreateNewOrganizer,
	formatTimeStamp,
	isUpdatedField,
	translate,
	isUpdateMode,
	isDetailView,
}) => {
	const header = (
		<Button.ToggleIsCreate
			isCreateNew={isCreateNewOrganizer}
			setIsCreateNew={value => {
				setIsCreateNewOrganizer(value);
				onChangeValue('editedOrganizer.isCreateNewOrganizer', value);
			}}
			type="organizer"
		/>
	);

	// create new organizer or update organizer ////
	return isCreateNewOrganizer
		? ////////////////////////////////////////////////////////
		  {
				header,
				id: '?',
				name: (
					<Input
						className={classNames(
							{ isUpdated: isUpdatedField('editedOrganizer.name') },
							'mandatory'
						)}
						data-test="orgData-Name-Input"
						value={inputValue(value?.editedOrganizer?.name)}
						onChange={(_, { value }) => onChangeValue('editedOrganizer.name', value)}
						placeholder={`${translate('detailView.name')} *`}
					/>
				),
				timestamp: false,
				matchedOrganizerIds: (
					<BlocksInput
						inputType="String"
						data-test="orgData-Customer-Ids"
						className={classNames({
							isUpdated: isUpdatedField('editedOrganizer.matchedOrganizerIds'),
						})}
						isArray
						value={sanitizeMatchedCustomerIds(value?.editedOrganizer?.matchedOrganizerIds)}
						onChange={(value, valid) =>
							onChangeValue('editedOrganizer.matchedOrganizerIds', value, valid)
						}
					/>
				),
				commercialId: (
					<Input
						className={classNames({ isUpdated: isUpdatedField('editedOrganizer.commercialId') })}
						value={inputValue(value?.editedOrganizer?.commercialId)}
						onChange={(_, { value }) => onChangeValue('editedOrganizer.commercialId', value)}
					/>
				),
				address: {
					streetHouseNumber: (
						<>
							<Input
								className={classNames(
									'address street',
									{
										isUpdated: isUpdatedField('editedOrganizer.address.street'),
									},
									'mandatory'
								)}
								data-test="orgData-Address-Street"
								placeholder={`${translate('detailView.street')} *`}
								value={inputValue(value?.editedOrganizer?.address?.street)}
								onChange={(_, { value }) => onChangeValue('editedOrganizer.address.street', value)}
							/>
							<Input
								className={classNames(
									'address houseNumber',
									{
										isUpdated: isUpdatedField('editedOrganizer.address.houseNumber'),
									},
									!isUpdateMode && 'mandatory'
								)}
								data-test="orgData-Address-HouseNumber"
								placeholder={
									isUpdateMode
										? `${translate('detailView.houseNumber')}`
										: // create mode
										  `${translate('detailView.houseNumber')} *`
								}
								value={inputValue(value?.editedOrganizer?.address?.houseNumber)}
								onChange={(_, { value }) =>
									onChangeValue('editedOrganizer.address.houseNumber', value)
								}
							/>
						</>
					),
					postalCodeCity: (
						<>
							<Input
								className={classNames(
									'address postalCode',
									{
										isUpdated: isUpdatedField('editedOrganizer.address.postalCode'),
									},
									'mandatory'
								)}
								data-test="orgData-Address-PostalCode"
								placeholder={`${translate('detailView.postalCode')} *`}
								value={inputValue(value?.editedOrganizer?.address?.postalCode)}
								onChange={(_, { value }) =>
									onChangeValue('editedOrganizer.address.postalCode', value)
								}
							/>
							<Input
								className={classNames(
									'address city',
									{
										isUpdated: isUpdatedField('editedOrganizer.address.city'),
									},
									'mandatory'
								)}
								data-test="orgData-Address-City"
								placeholder={`${translate('detailView.city')} *`}
								value={inputValue(value?.editedOrganizer?.address?.city)}
								onChange={(_, { value }) => onChangeValue('editedOrganizer.address.city', value)}
							/>
						</>
					),
					region: (
						<RegionInput
							className={classNames(
								'address',
								{
									isUpdated: isUpdatedField('editedOrganizer.address.region'),
								},
								NO_REGIONS.includes(value?.editedOrganizer?.address?.country) && 'noRegions',
								'mandatory'
							)}
							placeholder={`${translate('detailView.region')} *`}
							value={value?.editedOrganizer?.address?.region}
							onChange={value => onChangeValue('editedOrganizer.address.region', value)}
							countryValue={value?.editedOrganizer?.address?.country}
						/>
					),
					country: (
						<CountryInput
							className={classNames(
								'address',
								{
									isUpdated: isUpdatedField('editedOrganizer.address.country'),
								},
								'mandatory'
							)}
							placeholder={`${translate('detailView.country')} *`}
							value={value?.editedOrganizer?.address?.country}
							onChange={value => onChangeValue('editedOrganizer.address.country', value)}
							showAllCountries
						/>
					),
				},
				category: (
					<CategoryInput
						className={classNames({ isUpdated: isUpdatedField('editedOrganizer.category') })}
						value={value?.editedOrganizer?.category}
						onChange={value => onChangeValue('editedOrganizer.category', value)}
					/>
				),
				emailAddresses: (
					<BlocksInput
						inputType="Email"
						className={classNames({ isUpdated: isUpdatedField('editedOrganizer.emailAddresses') })}
						isArray
						value={value?.editedOrganizer?.emailAddresses || []}
						onChange={(value, valid) =>
							onChangeValue('editedOrganizer.emailAddresses', value, valid)
						}
					/>
				),
				phoneNumbers: (
					<BlocksInput
						inputType="Phone"
						className={classNames({ isUpdated: isUpdatedField('editedOrganizer.phoneNumbers') })}
						isArray
						value={value?.editedOrganizer?.phoneNumbers || []}
						onChange={(value, valid) => onChangeValue('editedOrganizer.phoneNumbers', value, valid)}
					/>
				),
				urls: (
					<BlocksInput
						inputType="URL"
						className={classNames({ isUpdated: isUpdatedField('editedOrganizer.urls') })}
						isArray
						value={value?.editedOrganizer?.urls || []}
						onChange={(value, valid) => onChangeValue('editedOrganizer.urls', value, valid)}
					/>
				),
				statusName:
					isUpdateMode && !isDetailView ? (
						<GenericDropdownInput
							className={classNames({
								isUpdated: isUpdatedField('editedOrganizer.organizerStatus.name'),
							})}
							value={value?.editedOrganizer?.organizerStatus?.id}
							onChange={value => {
								onChangeValue('editedOrganizer.organizerStatus.id', value);
								onChangeValue('editedOrganizer.organizerStatus.name', value);
							}}
							type="organizerStatus"
						/>
					) : (
						value?.organizerStatus?.name
					),
		  }
		: // event detail: select existing organizer //////////////////////////////////////////////////////////////////
		  {
				header,
				...(value?.organizer || {}),
				id: (
					<>
						<OrganizerInput
							className={classNames({ isUpdated: isUpdatedField('organizer.id') }, 'mandatory')}
							data-test="OrganizerId"
							onChange={(value, valid) => onChangeValue('organizer', value, valid)}
							value={value?.organizer}
							fieldName={'id'}
							isGrowingInput
						/>
						{' *'}
					</>
				),
				matchedOrganizerIds: (
					<OrganizerInput
						className={classNames({ isUpdated: isUpdatedField('organizer.matchedOrganizerIds') })}
						onChange={(value, valid) => onChangeValue('organizer', value, valid)}
						value={value?.organizer}
						fieldName={'matchedOrganizerIds'}
						placeholder={translate('actions.searchOrganizerByCustomerId')}
					/>
				),
				commercialId: (
					<OrganizerInput
						className={classNames({ isUpdated: isUpdatedField('organizer.commercialId') })}
						onChange={(value, valid) => onChangeValue('organizer', value, valid)}
						value={value?.organizer}
						fieldName={'commercialId'}
						placeholder={translate('actions.searchOrganizerByCommercialId')}
					/>
				),
				timestamp: formatTimeStamp(value?.organizer?.timestamp, true),
				address: {
					streetHouseNumber: buildStreetHouseNumberFromAddress(value?.organizer?.address),
					...(value?.organizer?.address || {}),
				},
				// render as fragment to not be falsy (render condition in OrganizerData.js)
				statusName: <>{value?.organizer?.organizerStatus?.name}</>,
				statusUsername: <>{value?.organizer?.organizerStatus?.user?.username}</>,
				statusLastChangedAt: (
					<>{formatTimeStamp(value?.organizer?.organizerStatus?.lastChangedAt, true)}</>
				),
		  };
};

const getTabPaneInputs = ({ value, onChangeValue, isUpdateMode, isUpdatedField, translate }) => [
	{
		menuItem: `${translate('detailView.uploadEvidenceImages')} *`,
		tag: (
			<ImageUploader
				value={value.images}
				onChange={(value, valid) => onChangeValue('images', value, valid)}
				{...{ isUpdatedField, isUpdateMode }}
			/>
		),
	},
];
