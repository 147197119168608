import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import { SortIcon } from '.';
import classNames from 'classnames';

import './SortIcons.scss';

export default ({ sortField, sorting, onSort }) => {
	return (
		<span className="multisort-group">
			{sortField.map((field, index) => {
				const isActive = sorting.field === field.name;

				return (
					<Popup
						key={index}
						trigger={
							<div
								className={classNames('multisort', { active: isActive })}
								onClick={e => {
									e.preventDefault();
									onSort(field.name, !!e.ctrlKey);
								}}>
								<SortIcon
									sortField={!field.onlyIcon && field.name}
									sorting={[sorting]}
									icon={<Icon className="custom" name={field.icon} />}
								/>
							</div>
						}
						disabled={!field.tooltip}
						content={field.tooltip || ''}
						mouseEnterDelay={300}
						offset={[-12, 0]}
					/>
				);
			})}
		</span>
	);
};
