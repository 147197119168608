import React, { useState, createContext, useEffect } from 'react';

import {
	getBrowserLanguage,
	getTranslator,
	formatDate as _formatDate,
	formatTimeStamp as _formatTimeStamp,
	formatDateTimeTuple as _formatDateTimeTuple,
	reformatDateTimeTuple as _reformatDateTimeTuple,
	resolveDateTimeTuple as _resolveDateTimeTuple,
	isDateTimeTupleEmpty as _isDateTimeTupleEmpty,
	defaultTime as _defaultTime
} from 'utils';
import { useUserSettings } from 'customHooks';
import getDictionary from 'i18n/getDictionary';
import { MOMENT_FORMATS } from 'config';

const TranslateContext = createContext();

const TranslateProvider = ({ children }) => {
	const { userSettings, loading } = useUserSettings();
	const _language = userSettings && userSettings.languageCode;
	const _momentFormatID = userSettings && userSettings.dateFormat;

	const [language, setLanguage] = useState(_language || getBrowserLanguage());
	const [momentFormatID, setMomentFormatID] = useState(_momentFormatID || MOMENT_FORMATS[0].id);

	useEffect(() => {
		if (!loading) {
			setLanguage(_language || language);
			setMomentFormatID(_momentFormatID || momentFormatID);
		}
		// eslint-disable-next-line
	}, [_language, _momentFormatID]); // other dependencies not needed as dependency

	const translate = getTranslator(getDictionary(language));

	const momentFormat =
		MOMENT_FORMATS.find(format => format.id === momentFormatID) || MOMENT_FORMATS[0];

	const formatDate = (dateTime, hasTime) => _formatDate(dateTime, momentFormat, hasTime);

	const formatTimeStamp = (dateTime, showSeconds = false) =>
		_formatTimeStamp(dateTime, showSeconds, momentFormat);

	const formatDateTimeTuple = dateTime => _formatDateTimeTuple(dateTime, momentFormat);

	const reformatDateTimeTuple = (date, time) => _reformatDateTimeTuple(date, time, momentFormat);

	const resolveDateTimeTuple = (date, time) => _resolveDateTimeTuple(date, time, momentFormat);

	const isDateTimeTupleEmpty = (date, time) => _isDateTimeTupleEmpty(date, time, momentFormat);

	const defaultTime = _defaultTime(momentFormat);

	const store = {
		language,
		setLanguage,
		translate,

		momentFormat,
		momentFormatID,
		setMomentFormatID,
		formatDate,
		formatTimeStamp,
		formatDateTimeTuple,
		reformatDateTimeTuple,
		resolveDateTimeTuple,
		isDateTimeTupleEmpty,
		defaultTime
	};

	return <TranslateContext.Provider value={store}>{children}</TranslateContext.Provider>;
};

export { TranslateContext, TranslateProvider };
