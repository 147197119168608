export const MAX_EVENTS_SELECTABLE = 1000;
export const MAX_EVENTS_MERGABLE = 10;

export const INITIAL_SELECTIONS_SHOWN = 21;
export const MORE_SELECTIONS_TO_SHOW = 10;

export const MAX_SHOWN_ADS = 10;
export const MAX_SHOWN_ID_MARKERS = 5;

export const MAX_RATING = 1000;

// relevant for setting start date in advance (edit and create event)
export const AVAILABLE_PLUS_YEARS = 10;

export const KEYCODES = {
	SHIFT: 16,
	STRG: 17,
	ALT: 18,
	Z: 90,
};

export const LANGUAGES = [
	{ code: 'en', name: 'English' },
	{ code: 'de', name: 'Deutsch' },
	{ code: 'pl', name: 'Polski' },
];

export const STANDARD_MOMENT_FORMAT = {
	// only technical, should not be seen by user
	// no timestampCode and timeCode because 'YYYY-MM-DD HH:mm' is not accepted by backend
	dateCode: 'YYYY-MM-DD', //  will be concatenated to ´${dateCode} ${timeCode}:00´ as timestampSecondsCode
	timeCode: 'HH:mm', // needed as part of timestampSecondsCode (see above)
	timestampSecondsCode: 'YYYY-MM-DD HH:mm:ss', // using this format because format 'YYYY-MM-DDTHH:mm:ss' = ISO 8601 currently not working for filters
	example: '2019-08-24 13:24:55',
};

export const MOMENT_FORMATS = [
	{
		id: 'YYYY-MM-DD, HH:mm:ss',
		dateCode: 'YYYY-MM-DD',
		timeCode: 'HH:mm',
		shortHandDateCode: 'YYMMDD',
		shortHandTimeCode: 'HHmm',
		timestampCode: 'YYYY-MM-DD, HH:mm',
		timestampSecondsCode: 'YYYY-MM-DD, HH:mm:ss',
		example: '2019-08-24, 13:24:55',
	},
	{
		id: 'MM/DD/YYYY, hh:mm:ss a',
		dateCode: 'MM/DD/YYYY',
		timeCode: 'hh:mm a',
		shortHandDateCode: 'MMDDYY',
		shortHandTimeCode: 'HHmm', // always the same no matter if 'hh' or 'HH'
		timestampCode: 'MM/DD/YYYY, hh:mm a',
		timestampSecondsCode: 'MM/DD/YYYY, hh:mm:ss a',
		example: '08/24/2019, 01:24:55 pm',
	},
	{
		id: 'DD/MM/YYYY, hh:mm:ss a',
		dateCode: 'DD/MM/YYYY',
		timeCode: 'hh:mm a',
		shortHandDateCode: 'DDMMYY',
		shortHandTimeCode: 'HHmm',
		timestampCode: 'DD/MM/YYYY, hh:mm a',
		timestampSecondsCode: 'DD/MM/YYYY, hh:mm:ss a',
		example: '24/08/2019, 01:24:55 pm',
	},
	{
		id: 'DD.MM.YYYY, HH:mm:ss',
		dateCode: 'DD.MM.YYYY',
		timeCode: 'HH:mm',
		shortHandDateCode: 'DDMMYY',
		shortHandTimeCode: 'HHmm',
		timestampCode: 'DD.MM.YYYY, HH:mm',
		timestampSecondsCode: 'DD.MM.YYYY, HH:mm:ss',
		example: '24.08.2019, 13:24:55',
	},
];
export const INVALID_DATE = 'invalidDate';
export const INVALID_URL = 'invalidUrl';
export const NO_DIFFERENCE = 'NO_DIFFERENCE';

// This is for comparison after update or create.
// Computing the difference in getChangedDataDiff for some attributes it might make
// sense to keep them even if they have not changed.
export const KEEP_ATTRIBUTES_FOR_CHANGED_DATA_DIFF = [
	'id',
	'rating',
	'type', // image.type is needed even if it does not change
	'file', // image.file has some read only attributes which which would not be kept even if new attribute
];

export const VALIDATION_REG_EXP = RegExp(
	/(?=^.{12,64}$)((?=.*[A-Z]+)(?=.*[.&\-_[\]`~|@$%^?:{}!']+)(?=.*[a-z]+)(?=(.*\d)+))^.*$/
);
export const EXCLUSION_REG_EXP = RegExp(/^[a-zA-Z0-9.&\-_[\]`~|@$%^?:{}!']+$/);

export const TABLE_ROW_HEIGHT = {
	DYNAMIC: 30,
	STATIC: 46,
	BOTTOM_ROW_HEIGHT: 83,
	LINE_HEIGHT: 18.5,
};

export const TABLE_HEADER_HEIGHT = 42;

export const SAVED_QUERY_MAX_LENGTH = 85;

export const [NEW, BASKET, SHELF, BIN, DISCARD, EXPORT, CONFLICT] = [-1, 1, 2, 3, 4, 5, 6];

export const NEW_EVENT_ID = -1; // New event without having a real id yet.
export const NO_OFFSET = -1; // No offset, id will not switch automatically to 0 (like undefined)

export const ICONS = {
	EVENT_MUSIC: 'music',
	EVENT: 'search',
	LOCATION: 'map marker alternate',
	ORGANIZER: 'address book',
	STATE_INFO: 'cog',
	COLLABORATION: 'lock',
	ANY_FIELD: 'question',
	EXPAND: 'chevron down',
	COLLAPSE: 'chevron up',
};

// Please add translations in tooltips.submitButton.fieldNames when adding mandatory fields.
export const CREATE_UPDATE_EVENT_MANDATORY_FIELDS = ['name', 'startDate', 'location', 'organizer']; // image.url is also mandatory but cannot be defined here

const ADDRESS_MANDATORY_FIELDS = [
	'address.street', // 'address.houseNumber' should not be mandatory because old data might have both in street (relevant for update)
	'address.postalCode',
	'address.city',
	'address.country',
	'address.region',
];

export const ADDRESS_MANDATORY_FIELDS_CREATE_ONLY = ['address.houseNumber'];

export const CREATE_UPDATE_LOCATION_MANDATORY_FIELDS = ['name', ...ADDRESS_MANDATORY_FIELDS];
export const CREATE_UPDATE_ORGANIZER_MANDATORY_FIELDS = ['name', ...ADDRESS_MANDATORY_FIELDS];

export const MAX_DETAIL_VIEW_UPLOAD_FILES = 10;
export const MAX_DETAIL_VIEW_UPLOAD_SIZE = 2 * 1024 * 1024; // max upload size in bytes = 2MB
export const ACCEPT_DETAIL_VIEW_UPLOAD = 'image/jpeg, image/jpg';

export const LOCATION_ORGANIZER_CATEGORIES = [
	'Arts/Entertainment/Nightlife',
	'Bar',
	'Book',
	'Cafe',
	'Club',
	'Community Organization',
	'Dance & Night Club',
	'Event planning/Event services',
	'Health',
	'Local business',
	'Lodging',
	'Media/News/Publishing',
	'Movie Theater',
	'Musician/Band',
	'Organization',
	'Performance & Event Venue',
	'Restaurant',
	'Spas/Beauty/Personal care',
	'Store',
	'Supermarket',
];

export const NO_REGIONS = [];
export const EMPTY_REGION = 'No regions available';

export const EntityEnum = {
	EVENT: 'EVENT',
	LOCATION: 'LOCATION',
	ORGANIZER: 'ORGANIZER',
};
