import React, { useState, useEffect } from 'react';
import { Message } from 'semantic-ui-react';
import { useTranslate, useQueryParams } from 'customHooks';
import { urlForPasswordResetRequestValid } from 'utils';
import ResetForm from './ResetForm';

import './index.scss';
import { Icon } from 'semantic-ui-react';

const PasswordReset = () => {
	const [error, setError] = useState(false);
	const [querying, setQuerying] = useState(true);
	const translate = useTranslate();

	const { id, token } = useQueryParams();

	useEffect(() => {
		async function fetchData() {
			await fetch(urlForPasswordResetRequestValid(id, token))
				.then(response => {
					if (!response.ok) {
						response.status === 400 && setError(translate('resetPassword.failTokenMessage'));
						response.status === 429 &&
							setError(
								translate('messages.tooManyRequests', [
									translate('resetPassword.triggerButton').toLowerCase(),
								])
							);
					}
					setQuerying(false);
				})
				.catch(e => {
					setError(e.toString());
					setQuerying(false);
				});
		}
		setError(false);
		setQuerying(true);
		fetchData();
	}, [id, token, translate]);

	return (
		<div className="PasswordReset">
			<div className="logo">
				<img alt="logo" src={`${process.env.PUBLIC_URL}/MESLIS_logo_white.png`} />
			</div>

			{!error && !querying ? (
				<div className="whitebox">
					<div className="header">
						<Icon size="huge" className="avatar" name="lock" />
						<h2>{translate('resetPassword.reset')}</h2>{' '}
					</div>
					<ResetForm {...{ id, token }} />
				</div>
			) : (
				<div className="centralMessage">
					{error && (
						<Message warning>
							<Message.Header>{translate('resetPassword.failTokenHeader')}</Message.Header>
							<p>{error}</p>
						</Message>
					)}
				</div>
			)}
		</div>
	);
};

export default PasswordReset;
