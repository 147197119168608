import React, { useState } from 'react';
import { Button, Confirm, Icon, Popup } from 'semantic-ui-react';

import { useTranslate, useUpdateEvents, useUserContext } from 'customHooks';

import { MAX_EVENTS_MERGABLE } from 'config';
import classNames from 'classnames';
import { alerts, isSelectableAndUnlocked } from 'utils';
import './Merge.scss';

const Merge = ({className, events, onRefresh, ...props }) => {
	const translate = useTranslate();

	const { user } = useUserContext();
	const lockedEvents = events?.filter(
		event => !isSelectableAndUnlocked(event, user?.username)
	);
	events = events?.filter(event => isSelectableAndUnlocked(event, user?.username));

	const { moveAdsOfEvents } = useUpdateEvents({ onRefresh });

	const isTooFew = !events || events.length <= 1;
	const isTooMany = events.length > MAX_EVENTS_MERGABLE;
	const disabled = isTooFew || isTooMany;

	const handleMergeEvents = () => {
		if (disabled) return;

		const _handleMergeEvents = () => {
			const [targetEvent, ...sourceEvents] = events;

			const eventIdsWithAdIds = sourceEvents.map(event => ({
				eventId: event.id,
				adIds: !!event.advertisements && event.advertisements.results.map(ad => ad.id),
			}));

			const targetEventId = targetEvent.id;
			moveAdsOfEvents(eventIdsWithAdIds, targetEventId);
		};

		if (!!lockedEvents?.length) {
			alerts
				.lockedSelectionIgnored(lockedEvents.map(ev => ev.id))
				.then(({ dismiss }) => !dismiss && _handleMergeEvents());
		} else _handleMergeEvents();
	};

	const [confirmOpen, setConfirmOpen] = useState();

	return (
		<>
			<Popup
				trigger={
					<Button
						className={classNames('Merge', className, {
							disabled,
						})} /* only classname no real disabeling in order to get popup working when disabled */
						icon
						onClick={() => !disabled && setConfirmOpen(true)}
						{...props}>
						<Icon name="object ungroup" />
					</Button>
				}
				content={
					isTooFew ? (
						<i>{translate('tooltips.mergeButtonTooFew')}</i>
					) : isTooMany ? (
						<i>{translate('tooltips.mergeButtonTooMany', [MAX_EVENTS_MERGABLE])}</i>
					) : (
						<>
							<b>{translate('actions.merge')}</b>
							<br />
							{translate('tooltips.mergeButton')}
						</>
					)
				}
				on="hover"
				mouseEnterDelay={500}
				position="bottom right"
				closeOnTriggerClick
			/>
			<Confirm
				open={confirmOpen}
				closeOnDimmerClick={false}
				content={translate('alerts.mergeEvents', [`${events.length}`, events[0] && events[0].id])}
				onCancel={() => setConfirmOpen(false)}
				onConfirm={() => {
					setConfirmOpen(false);
					handleMergeEvents();
				}}
			/>
		</>
	);
};

export default Merge;
