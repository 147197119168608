import React from 'react';
import { useTranslate } from 'customHooks';
import { Collaboration } from 'components/Base';

const CollaborationEntry = ({ data }) => {
	const translate = useTranslate();

	return (
		<>
			<br />
			<Collaboration entity="event" locking={data.locking} />
			<b>{translate(`filters.collaborationOptions.${data.locking}`)}</b>

			{data.locking === 'lockedByFollowingUsers' && (
				<>
					<br />
					<i>{data.users?.map(user => `${user.firstName} ${user.lastName}`).join(', ')}</i>
				</>
			)}
		</>
	);
};

export default CollaborationEntry;
