const protocol = process.env.REACT_APP_GRAPHQL_PROTOCOL;
const host = process.env.REACT_APP_GRAPHQL_HOST;
const port = process.env.REACT_APP_GRAPHQL_PORT;
const path = '/settings/getPublicKey';

const getPubKey = async () => {
	const response = await fetch(`${protocol}://${host}:${port}${path}`);
	const json = await response.json();
	return json;
};

export default getPubKey;
