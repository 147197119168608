import React from 'react';

import GenericEntry from './GenericEntry';
import { useTranslate } from 'customHooks';
import { USERS_OF_COMPANY } from 'graphql/queries';
import { get } from 'lodash';
import { useQuery } from '@apollo/client';

const UserEntry = ({ data, onChangeValue }) => {
	const translate = useTranslate();

	const usersOfCompany = get(
		useQuery(USERS_OF_COMPANY, { fetchPolicy: 'cache-first' }),
		'data.getAllUsersForCompany'
	);

	// TODO: usersOfCompany should be moved into Context
	// when loading of the App (me() query) is completed, the usersOfCompany should already be available instead of being executed synchronously after initial loading completes
	// currently usersOfCompany is null on initial render of UserEntry when reloading the page with a set UserEntry
	// on the first re-render (happens automatically) the entry is then rendered correctly
	if (!usersOfCompany) {
		return <></>;
	}

	const usersOptions =
		usersOfCompany.map(u => ({
			key: u.id,
			value: u.username,
			text: [u.firstName, u.lastName].join(' '),
		})) ?? [];

	// added exists here, since it shouldn't appear in the Select (this would happen if added it in useFilterConfig)
	const users = usersOptions.concat([{ value: '*', text: translate('filters.keyword.exists') }]);

	return <GenericEntry {...{ data, onChangeValue }} type="User" options={users} />;
};

export default UserEntry;
