import React, { useMemo } from 'react';
import { Button, Dropdown, Label } from 'semantic-ui-react';
import { useUserContext } from 'customHooks';
import useTranslate from 'customHooks/useTranslate';

import './StatusBulkEditDropdown.scss';

const StatusBulkEditDropdown = ({ disabled, onSelected }) => {
	const translate = useTranslate();
	const { eventStatuses } = useUserContext();

	const eventStatusesNotSet = !eventStatuses || eventStatuses?.length === 0;

	// different instances of eventStatuses would otherwise cause update of useMemo - even if they have the same values
	const eventStatusesAsString = JSON.stringify(eventStatuses);
	const orderedEventStatuses = useMemo(() => {
		if (eventStatusesNotSet) {
			return [];
		}

		// Create an empty array to hold the new objects
		const evStatusByName = [];

		for (let i = 0; i < eventStatuses.length; i++) {
			const event = eventStatuses[i];
			const statusTypeName = event.statusTypeName;

			// Check if the statusTypeName already exists in the evStatusByName
			const existingObjIndex = evStatusByName.findIndex(
				obj => obj.statusTypeName === statusTypeName
			);

			// If the statusTypeName has not been encountered before, create a new object with that key
			if (existingObjIndex === -1) {
				evStatusByName.push({ statusTypeName, events: [event] });
			}
			// If the statusTypeName already exists, add the current object to the array of events associated with that key
			else {
				evStatusByName[existingObjIndex].events.push(event);
			}
		}

		return evStatusByName;
		// eslint-disable-next-line
	}, [eventStatusesAsString]);

	if (eventStatusesNotSet) {
		return <></>;
	}

	return (
		<Dropdown
			className="StatusBulkEditDropdown"
			text={translate('actions.eventStatusBulkEdit')}
			icon="dropdown"
			direction="left"
			button
			disabled={disabled}>
			{orderedEventStatuses.length > 0 ? (
				<Dropdown.Menu>
					{orderedEventStatuses.map(obj => {
						const statusTypeName = obj.statusTypeName;
						const events = obj.events;
						return (
							<Dropdown.Item key={statusTypeName}>
								<Label style={{ fontWeight: 'bold' }} color="blue">
									{statusTypeName}
								</Label>
								<div style={{ display: 'Flex', flexDirection: 'column' }}>
									{events.map(event => (
										<Button key={event.statusId} onClick={() => onSelected(event.statusId)}>
											{event.statusName}
										</Button>
									))}
								</div>
							</Dropdown.Item>
						);
					})}
				</Dropdown.Menu>
			) : (
				<></>
			)}
		</Dropdown>
	);
};
export default StatusBulkEditDropdown;
