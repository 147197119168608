import downloadjs from 'downloadjs';
import fetchWithToken from './fetchWithToken';
import streamsaver from 'streamsaver';

const downloadWithToken = async (url, name, retry = 0) => {
	// try, catch + retry because of MESLIS-5885
	try {
		if (typeof WritableStream === 'function') {
			const response = await fetchWithToken(url);

			// We need to serve our own mitm with serviceWorker script on a secure page, then we can set streamsaver mitm to this url and downloads are served from our domain.
			// Disable mitm, as long as it's not verified that it' sworking
			//streamsaver.mitm = process.env.PUBLIC_URL + '/mitm/mitm.html'; // This apporach did not work reliable on the test server
			//streamsaver.mitm = 'streamsaver/mitm.html?version=2.0.0'; // preferred solution, but seems not to work when on the same domain as the app (see also MESLIS-4306)
			//streamsaver.mitm = 'https://app.meslis.com/streamsaver/mitm.html?version=2.0.0'; // does not work either
			const writer = streamsaver.createWriteStream(name).getWriter();

			const reader = response.body.getReader();
			const pump = () =>
				reader
					.read()
					.then(({ value, done }) => (done ? writer.close() : writer.write(value).then(pump)));
			pump();
		} else {
			fetchWithToken(url)
				.then(response => response.blob())
				.then(response => downloadjs(response, name));
		}
	} catch (error) {
		if (retry < 3) {
			retry++;
			setTimeout(() => downloadWithToken(url, name, retry), 500);
		} else if (retry === 3) {
			alert('Download failed - please reload and try again');
		}
		console.error(error);
	}
};

export default downloadWithToken;
