import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { Button, Icon } from 'semantic-ui-react';

import './Refresh.scss';

const Refresh = ({ className, disabled = true, onRefresh, ...props }) => {
	const [loading, setLoading] = useState(false);
	const handleRefresh = () => {
		setLoading(true);
		onRefresh();
	};

	useEffect(() => {
		disabled && setLoading(false);
	}, [disabled]);

	return (
		<Button
			className={classNames('refreshButton', className)}
			icon
			onClick={handleRefresh}
			disabled={disabled || loading}
			loading={loading}
			{...props}>
			<Icon name="sync alternate" className={!disabled && !loading ? 'rotate' : null} />
		</Button>
	);
};

export default Refresh;
