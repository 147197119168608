import React from 'react';

import './Price.scss';

const Price = ({ amount = 0, currency }) => {
	const label = currency ? `${amount} ${currency}` : `${amount}`;
	return <span className="Price">{label}</span>;
};

export default Price;
