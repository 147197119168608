import React from 'react';
import { Query } from '@apollo/client/react/components';

import { EXPORTS } from 'graphql/queries';

export default ({ children, ...props }) => (
	<Query fetchPolicy="network-only" query={EXPORTS} {...props}>
		{children}
	</Query>
);
