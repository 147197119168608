import React, { useState } from 'react';
import { Icon } from 'semantic-ui-react';

import { noop } from 'utils';
import storage from 'storage';
import classNames from 'classnames';

import './GraySidebarLayout.scss';

const GraySidebarLayout = ({ sidebar, onCollapsed = noop, children }) => {
	const [collapsed, setCollapsed] = useState(storage.isSecondarySidebarCollapsed());

	const handleClick = () => {
		// since Location search-step has a different structure it needs to keep its own collapsed state
		onCollapsed(!collapsed);
		setCollapsed(!collapsed);
		storage.setSecondarySidebarCollapsed(!collapsed);
	};

	const handleMouseOver = () => {
		collapsed &&
			document.querySelector('.GraySidebarLayout > .sidebar').offsetWidth > 20 &&
			document.querySelector('.GraySidebarLayout > .sidebar').classList.add('keep');
	};

	const handleMouseOut = () => {
		// explicitly runs on every mouseOut to also clear class after clicking Button, while sidebar was collapsed and hovered
		document.querySelector('.GraySidebarLayout > .sidebar').classList.remove('keep');
	};

	return (
		<div className={classNames('GraySidebarLayout', { collapsed })}>
			<div className="sidebar">
				<div className="reducer">{sidebar}</div>
			</div>

			<Icon
				className="collapser"
				onClick={handleClick}
				name={collapsed ? 'angle right' : 'angle left'}
				onMouseOver={handleMouseOver}
				onMouseOut={handleMouseOut}
			/>

			<div className="main">
				{children.props && children.props.children
					? React.cloneElement(children, {
							children: React.cloneElement(children.props.children, { collapsed: collapsed }),
					  })
					: children}
			</div>
		</div>
	);
};

export default GraySidebarLayout;
