import React from 'react';
import { Query } from '@apollo/client/react/components';

import { SUBSCRIPTIONS } from 'graphql/queries';
import Users from './Users';
import { convertOldFilterFormats } from 'utils';
import { cloneDeep } from '@apollo/client/utilities';

const Subscriptions = ({ children, ...props }) => (
	<Query query={SUBSCRIPTIONS} {...props}>
		{children}
	</Query>
);

export default Subscriptions;

// add missing data for sharedUsers
// add field fullName
export const SubscriptionsFullData = ({ children, ...props }) => (
	<Subscriptions {...props}>
		{({ loading: loadingSub, error: errorSub, data: dataSub = {}, ..._props }) => (
			<Users fetchPolicy="cache-first">
				{({ loading: loadingUsers, error: errorUsers, data: _dataUsers = {} }) => {
					const data = cloneDeep(dataSub.getAllSavedQueries || []);
					const dataUsers = cloneDeep(_dataUsers.getAllUsersForCompany || []);

					if (!loadingSub && !loadingUsers) {
						dataUsers.forEach(user => {
							user.fullName = `${user.firstName} ${user.lastName}`;
						});

						data.forEach(savedQuery => {
							// sharedUsers is null if the query is not shared with anyone
							const sharedUserIds = (savedQuery.sharedUsers || []).map(user => user.id);

							// enrich sharedUsers with User data-objects
							// could theoretically directly get this data from query (should read this part from cache then though, since the data for User(s) doesnt change very often)
							savedQuery.sharedUsers = sharedUserIds
								.map(id => dataUsers.find(user => user.id === id))
								// remove invalid entries like removed users
								.filter(user => user !== undefined);

							// handle user not being part of company / not existing anymore by setting empty object
							savedQuery.user = dataUsers.find(user => user.id === savedQuery.user.id) || {};

							savedQuery.sharedUsersLength = savedQuery.sharedUsers.length;

							savedQuery.resolvedSearchQuery = convertOldFilterFormats(
								JSON.parse(savedQuery.searchQueryAsText)
							);
							savedQuery.searchQueryLength = savedQuery.resolvedSearchQuery.filters.length;
						});
					}
					return children({
						loading: loadingSub || loadingUsers,
						error: errorSub || errorUsers,
						data,
						dataUsers,
						..._props,
					});
				}}
			</Users>
		)}
	</Subscriptions>
);
