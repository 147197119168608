import React from 'react';
import { get } from 'lodash';
import { Form, Select } from 'semantic-ui-react';
import { useTranslate, useUserContext } from 'customHooks';
import { Collaboration } from 'components/Base';
import { useQuery } from '@apollo/client';
import { USERS_OF_COMPANY } from 'graphql/queries';

const CollaborationInput = ({ value, error, onChange, isQuickfilter }) => {
	const translate = useTranslate();
	const { user } = useUserContext();
	const allUsers = get(
		useQuery(USERS_OF_COMPANY, { fetchPolicy: 'cache-first' }),
		'data.getAllUsersForCompany'
	);

	const handleChange = (_, { value: locking }) =>
		onChange({
			...value,
			locking,
			ownUserName: user?.username,
		});

	const handleChangeUser = (_, { value: usernames }) =>
		onChange({
			...value,
			users: usernames?.map(username => allUsers.find(user => user.username === username)),
			ownUserName: user?.username,
		});

	const lockingOptions = [
		...(isQuickfilter ? ['any'] : []),
		'unlocked',
		'locked',
		'lockedByMe',
		'lockedByOtherUsers',
		...(!isQuickfilter ? ['lockedByFollowingUsers'] : []),
	].map(locking => ({
		key: locking,
		value: locking,
		text: translate(`filters.collaborationOptions.${locking}`),
		content: (
			<>
				<Collaboration {...{ locking }} />
				{translate(`filters.collaborationOptions.${locking}`)}
			</>
		),
	}));

	const usersOptions = !!allUsers
		? allUsers.map(user => ({
				key: user.username,
				value: user.username /* cannot be object, would throw a warning */,
				text: `${user.firstName} ${user.lastName}`,
		  }))
		: [];

	return (
		<>
			<Form.Field>
				<Select
					data-test="LockingSelect"
					value={value?.locking}
					placeholder={translate('filters.chooseLocking')}
					error={error}
					onChange={handleChange}
					fluid
					options={lockingOptions}
				/>
			</Form.Field>
			{value?.locking === 'lockedByFollowingUsers' && (
				<Form.Field>
					<Form.Select
						data-test="LockingUsersSelect"
						value={value?.users?.map(user => user.username) || []}
						placeholder={translate('filters.chooseUsers')}
						error={error}
						onChange={handleChangeUser}
						multiple
						search
						scrolling
						clearable
						openOnFocus
						closeOnChange
						deburr
						options={usersOptions}
					/>
				</Form.Field>
			)}
		</>
	);
};

export default CollaborationInput;
