import React from 'react';
import { Mutation } from '@apollo/client/react/components';

import { UPDATE_CUSTOM_LEAFLET } from 'graphql/mutations';
import { CUSTOMLEAFLETS } from 'graphql/queries';

const UpdateCustomLeaflet = ({ children, leaflet, ...props }) => {
	return (
		<Mutation mutation={UPDATE_CUSTOM_LEAFLET} variables={{ leaflet: leaflet }} {...props}>
			{(f, data) =>
				children(
					() =>
						f({
							refetchQueries: [
								{
									query: CUSTOMLEAFLETS,
								},
							],
						}),
					data
				)
			}
		</Mutation>
	);
};

export default UpdateCustomLeaflet;
