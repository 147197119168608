import React from 'react';
import { Table } from 'semantic-ui-react';
import { useTranslate } from 'customHooks';

import './LocationOrganizerData.scss';

const LocationData = ({
	name,
	matchedLocationIds,
	commercialId,
	address,
	category,
	emailAddresses,
	phoneNumbers,
	urls,
	timestamp,
	statusName,
	statusUsername,
	statusLastChangedAt,
	isRenderField = () => true,
}) => {
	const translate = useTranslate();
	return (
		<Table className="LocationData" basic="very" celled>
			<Table.Body>
				{isRenderField('locName') && <TRow header={translate('detailView.name')}>{name}</TRow>}
				{isRenderField('locTimestamp') && !!timestamp && (
					<TRow header={translate('detailView.timestamp')}>{timestamp}</TRow>
				)}
				{isRenderField('locStatusName') && !!statusName && (
					<TRow header={translate('detailView.statusName')} dataTest="locData-StatusName">
						{statusName}
					</TRow>
				)}
				{isRenderField('locStatusUsername') && !!statusUsername && (
					<TRow header={translate('detailView.statusUsername')} dataTest="locData-StatusUsername">
						{statusUsername}
					</TRow>
				)}
				{isRenderField('locStatusLastChangedAt') && !!statusLastChangedAt && (
					<TRow
						header={translate('detailView.statusLastChangedAt')}
						dataTest="locData-StatusLastChangedAt">
						{statusLastChangedAt}
					</TRow>
				)}
				{isRenderField('locCustomerIds') && (
					<TRow header={translate('detailView.locMatchedIds')}>{matchedLocationIds}</TRow>
				)}
				{isRenderField('locCommercialId') && (
					<TRow header={translate('detailView.locCommercialId')}>{commercialId}</TRow>
				)}
				{isRenderField('locAddress') && (
					<TRow header={translate('detailView.address')}>
						{address && (
							<>
								{address.streetHouseNumber}
								<br />
								{
									address.postalCode || address.city //data fields separated by space
										? `${address.postalCode} ${address.city}`
										: address.postalCodeCity /* for inputs both fields combined (update/ create)*/
								}
								<br />
								{address.region}
								<br />
								{address.country}
							</>
						)}
					</TRow>
				)}
				{isRenderField('locCategory') && (
					<TRow header={translate('detailView.category')}>{category}</TRow>
				)}
				{isRenderField('locEmails') && (
					<TRow header={translate('detailView.mail')}>{emailAddresses}</TRow>
				)}
				{isRenderField('locPhoneNumbers') && (
					<TRow header={translate('detailView.phone')}>{phoneNumbers}</TRow>
				)}
				{isRenderField('locWebsites') && (
					<TRow header={translate('detailView.websites')}>{urls}</TRow>
				)}
			</Table.Body>
		</Table>
	);
};

const TRow = ({ header, children }) => (
	<Table.Row>
		<Table.Cell className="first_col">{header}</Table.Cell>
		<Table.Cell data-test={`locData-${header}`}>{children}</Table.Cell>
	</Table.Row>
);

export default LocationData;
