import React from 'react';
import classNames from 'classnames';
import { useTranslate } from 'customHooks';
import { useDropzone } from 'react-dropzone';
import { CustomURLInput as URLInput, DeleteIcon } from '.';
import { pullAt } from 'lodash';
import './ImageUploader.scss';
import {
	MAX_DETAIL_VIEW_UPLOAD_FILES as MAX_FILES,
	MAX_DETAIL_VIEW_UPLOAD_SIZE as MAX_SIZE,
	ACCEPT_DETAIL_VIEW_UPLOAD as ACCEPT,
} from 'config';
import browserStorage from 'browserStorage';
import { urlForPdf } from 'utils';

import { AuthIFrame, UpdateImage } from 'components/Base';
const NEW_IMAGE = { type: 'newImage', valid: true };

const ImageUploader = ({
	className,
	value: images,
	onChange: onChangeImages,
	isUpdatedField,
	isUpdateMode,
	isSingle,
}) => {
	images = [...(images || []), { ...NEW_IMAGE }]; // adding an empty image at the end rendered as input for dropping but do not use same instance

	const handleChangeImages = images => {
		images = images.filter(image => !!image.id); // remove empty images
		onChangeImages(
			images,
			images.every(image => !!image.valid)
		);
	};

	const moveToIndex = (fromIdx, toIdx) => {
		if ([fromIdx, toIdx].includes(images.length - 1)) return;

		fromIdx = Number.parseInt(fromIdx);
		if (Number.isInteger(fromIdx)) {
			let _images = [...images];
			const [image] = pullAt(_images, [fromIdx]);
			_images.splice(toIdx, 0, image);
			handleChangeImages(_images);
		}
	};

	const handleFilesSelected = (selectedFiles, idx) => {
		const [firstFile, ...additionalFiles] = selectedFiles;
		let _images = [...images];

		_images[idx].id = _images[idx].id || firstFile?.name; // id is needed to compute diff between initial value and changed value
		_images[idx].type = 'newImage';
		_images[idx].file = firstFile;
		_images[idx].pdfId = null;

		!isSingle &&
			_images.splice(
				idx + 1,
				0,
				...additionalFiles.map(file => ({
					...NEW_IMAGE,
					file,
					id: file?.name, // id is needed to compute diff between initial value and changed value
				}))
			);

		_images.forEach((image, idx) => (image.position = idx));
		handleChangeImages(_images);
	};

	const handleChangeURL = (url, idx, valid) => {
		const _images = [...images];
		_images[idx] = { ...images[idx], url: `${url}`, valid }; // needs new instances in order to work correctly with update state
		handleChangeImages(_images);
	};

	const handleDelete = idx => {
		let _images = [...images];
		_images.splice(idx, 1);
		handleChangeImages(_images);
	};

	return (
		<div className={classNames('ImageUploader', className)}>
			{images.map((image, idx) => (
				<ImageUploaderContent
					key={idx}
					image={image}
					onFilesSelected={selectedFiles => handleFilesSelected(selectedFiles, idx)}
					onChangeURL={(url, valid) => handleChangeURL(url, idx, valid)}
					onDelete={() => handleDelete(idx)}
					{...{ idx, moveToIndex, isUpdatedField, isUpdateMode, isSingle }}
				/>
			))}
		</div>
	);
};

const ImageUploaderContent = ({
	idx,
	image: {
		id,
		file, // file is for new images refering to a local file
		pdfId, // pdfId is for images on server refering to a pdf file on server
		url,
	},
	isSingle,
	onFilesSelected,
	onChangeURL,
	moveToIndex,
	onDelete,
	isUpdatedField,
	isUpdateMode,
	...props
}) => {
	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop: images => images.length && onFilesSelected(images),
		accept: ACCEPT,
		multiple: !isSingle,
		maxSize: MAX_SIZE,
		maxFiles: !isSingle ? MAX_FILES : undefined,
	});

	const translate = useTranslate();
	return (
		<div
			{...props}
			onDrop={e => {
				if (!file) return;
				e.preventDefault();
				moveToIndex(e.dataTransfer.getData('draggedIdx'), idx);
			}}>
			<div
				draggable="true"
				onDragStart={e => {
					e.dataTransfer.effectAllowed = 'move';
					e.dataTransfer.setData('draggedIdx', idx);
				}}
				className={classNames(
					'picture-uploader',
					!file && 'vertical-text',
					file?.name && isUpdateMode && 'isUpdated',
					'mandatory'
				)}
				{...getRootProps()}
				data-test="pictureUploader">
				<input className="dropzone-input" {...getInputProps()} />
				{!!file || !!pdfId ? (
					<>
						{!!file ? (
							// for new images
							<img src={URL.createObjectURL(file)} alt={file?.name || ''} draggable={false} />
						) : !!pdfId ? (
							<AuthIFrame // for images on server
								className="pdfViewer"
								src={urlForPdf(pdfId)}
								token={browserStorage.getToken()}
								type="application/pdf"
							/>
						) : null}
						<div className="footer">
							<div className="file-name">{file?.name}</div>
							{pdfId && <UpdateImage expand={isDragActive} />}
							{file && ( // only for new images, in the first approach images on server cannot be deleted
								<DeleteIcon
									className="DeleteIcon"
									onClick={e => {
										e.stopPropagation();
										onDelete();
									}}
								/>
							)}
						</div>
					</>
				) : isDragActive ? (
					<div className="dropzone-content">{translate('placeholders.releaseToDrop')}</div>
				) : (
					<div className="dropzone-content">{translate('placeholders.dragAndDrop')}</div>
				)}
			</div>

			{/* first input image (file) and then url */}
			{(file || pdfId) && (
				<URLInput
					className={classNames(
						'url-input',
						isUpdatedField({ arrayPath: 'images', id, path: 'url' }) && isUpdateMode && 'isUpdated',
						'mandatory'
					)}
					value={url || ''}
					onChange={onChangeURL}
					placeholder={`${translate('detailView.url')} *`}
				/>
			)}
		</div>
	);
};

export default ImageUploader;
