import React, { useState } from 'react';
import { Input } from 'semantic-ui-react';
import classNames from 'classnames';
import { validateURL } from 'utils';

export default ({ value: _value, onChange, className, error, onBlur, ...props }) => {
	const [incorrectValue, setIncorrectValue] = useState(null); // buffering incorrect values here instead of sending the string INVALID_DATE which can easily be tested

	const handleChange = value => {
		if (value === '' || validateURL(value)) {
			setIncorrectValue(null);
			onChange(value, true);
		} else {
			setIncorrectValue(value);
			onChange(value, false);
		}
	};

	const value = incorrectValue || _value;

	return (
		<Input
			value={value}
			error={!!incorrectValue}
			className={classNames(className, 'customURLInput')}
			onChange={(_, { value }) => handleChange(value)}
			{...props}
		/>
	);
};
