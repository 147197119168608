import React, { useContext, useEffect } from 'react';
import VTTable from './VTTable';
import _ from 'lodash';

import { AppContext } from 'contexts/AppContext';
import { noop } from 'utils';

import { Toolbar } from 'components/Base';

import './index.scss';

const Generic = ({
	data = [],
	fields = [],
	defaultHiddenFields,
	tableKey = 'unknown', // for tables showing same kind of date (e.g. events or locations)
	// storageMemoKey for tables having same scroll position
	// The storageMemoKey is used when saving scroll position and fetch size
	// of different tables.
	// This is relevant when coming from another workflow step.
	// Tables with same storageMemoKey have the same scroll position.
	storageMemoKey,
	name,
	buttons,
	renderedButtons,
	selection,
	onReachBottom = noop,
	onChangeToDetailView = noop,
	onSelectItem = noop,
	onPreSelectItem = noop,
	onResetPreSelection = noop,
	extended,
	toggleExpandAll,

	keepToolbar,
	NoData,
	vtTableRef,

	...props
}) => {
	const {
		getHiddenFieldsFor,
		setHiddenFieldsFor,
		getFieldsOrderFor,
		setFieldsOrderFor,
		setGenericIDContext,
		setGenericTypeContext,
	} = useContext(AppContext);

	useEffect(() => {
		setGenericIDContext(buttons?.createNewEvent?.genericID);
		setGenericTypeContext(buttons?.createNewEvent?.genericType);
		// eslint-disable-next-line
	}, []);

	const hiddenFields = getHiddenFieldsFor(tableKey) || defaultHiddenFields;
	const fieldsOrder = getFieldsOrderFor(tableKey) || fields.map(field => field.name);

	// adapt order of fields according to fieldsorder from browser storage => orderedFields
	const getIndex = field =>
		[
			fieldsOrder.findIndex(name => name === field.name),
			// fall back, after new release fields might have a new field unknown to fieldsOrder from browser storage
			fields.findIndex(_field => _field.name === field.name),
		].find(index => index !== -1);
	const orderedFields = [...fields].sort((field1, field2) => getIndex(field1) - getIndex(field2));

	// remove hidden fields according to hiddenFields from browser storage => renderedFields
	const renderedFields = !hiddenFields
		? orderedFields
		: orderedFields.filter(field => !hiddenFields.includes(field.name));

	// manual change of column order
	const handleMoveColumn = (movedColumn, targetColumn) => {
		let newOrder = fieldsOrder.filter(name => name !== movedColumn);

		const insertIdx = fieldsOrder.findIndex(name => name === targetColumn);
		newOrder.splice(insertIdx, 0, movedColumn);

		setFieldsOrderFor(tableKey, [...newOrder]);
	};

	// declaration of handlers for the drag and drop functionality on the table headers and on fieldSwitcher
	const handleDragStart = (e, name) => {
		e.dataTransfer.effectAllowed = 'move';
		e.dataTransfer.setData('movedcolumn', name);
	};
	const handleDragOver = (e, movable) => {
		e.dataTransfer.types.includes('movedcolumn') && movable && e.preventDefault();
	};
	const handleDrop = (e, name) => {
		const movedColumn = e.dataTransfer.getData('movedcolumn');
		handleMoveColumn(movedColumn, name);
	};

	const toolbar = (
		<Toolbar
			header={name}
			extendAll={
				['organizer-search', 'location-search', 'events'].some(ele => ele === tableKey) && {
					extended: !!extended.length,
					onClick: toggleExpandAll,
					tableKey,
				}
			}
			csvExport={
				!selection &&
				['organizer-search', 'location-search', 'events'].some(ele => ele === tableKey) && {
					headers: renderedFields,
					data: data,
					tableKey: tableKey,
				}
			}
			fieldSwitcher={
				!!hiddenFields && {
					hiddenFields,
					fields: orderedFields.filter(f => f.hideable),
					onChange: _hiddenFields => setHiddenFieldsFor(tableKey, _hiddenFields),
					type: 'tableView',
					disabled: data.length === 0,
					onDragStart: handleDragStart,
					onDragOver: handleDragOver,
					onDrop: handleDrop,
				}
			}
			{...buttons}
			renderedButtons={renderedButtons}
		/>
	);

	return (
		<div className="Generic">
			{toolbar}
			{keepToolbar && !data.length ? (
				<NoData />
			) : (
				<VTTable
					{...{
						data,
						selection,
						onReachBottom,
						onChangeToDetailView,
						onSelectItem,
						onPreSelectItem,
						onResetPreSelection,
						onDragStart: handleDragStart,
						onDragOver: handleDragOver,
						onDrop: handleDrop,
						tableKey,
						storageMemoKey,
						renderedFields,
						extended,
						...props,
					}}
					ref={vtTableRef}
				/>
			)}
		</div>
	);
};

export default Generic;

const getFromObject = field => data => {
	let val = _.get(data, field);
	return typeof val === 'string'
		? val.trim()
		: val !== null
		? Array.isArray(val)
			? val.join(', ')
			: val
		: '';
};

const configField = ({
	name,
	label,
	icon,
	render,
	sortable = false,
	hideable = true,
	sortField,
	arrayPath,
	listIcon,
	multipleSort = true,
	movable = true,
}) => {
	return {
		name,
		label,
		icon,
		render: render || getFromObject(name),
		sortable,
		hideable,
		sortField: sortField || name,
		arrayPath,
		listIcon,
		multipleSort,
		movable,
	};
};

export { configField };
