import React from 'react';
import { Button, Icon, Card, Loader } from 'semantic-ui-react';

import { ItemArrayList } from 'components/Base';
import { formatGenericStatisticPerYears, buildStreetHouseNumberFromAddress } from 'utils';

import './GenericPager.scss';
import { useTranslate } from 'customHooks';
import { ICONS } from 'config';

const GenericPager = ({ data, type, max, index, loading, onChangeIndex, newEventCount }) => {
	const realMax = max >= 10000 ? 10000 : max;
	const translate = useTranslate();
	return (
		<Card className="GenericPager" fluid>
			{loading ? (
				<Card.Content>
					<Loader size="large" active inline="centered" />
				</Card.Content>
			) : (
				<>
					<Card.Content>
						<Card.Header>
							<Icon name={ICONS[{ location: 'LOCATION', organizer: 'ORGANIZER' }[type]]} />
							{translate(`genericPager.${type}`)} <small>[ID: {data.id}]</small>
							<small className="new-events">{newEventCount}</small>
						</Card.Header>
					</Card.Content>

					<Card.Content className="oppositeAlignedFlex">
						<div data-test="nameAddress">
							<strong>{data.name}</strong>

							{data.address && (
								<>
									<div>{buildStreetHouseNumberFromAddress(data.address)}</div>
									<div>
										{data.address.postalCode}, {data.address.city}
									</div>
									<div>{data.address.region}</div>
									<div>{data.address.country}</div>
								</>
							)}
							{data.phoneNumbers && <div>{data.phoneNumbers[0]}</div>}
						</div>
						{(data.customerLocations || data.customerOrganizers) && (
							<ItemArrayList data={formatGenericStatisticPerYears(data, type)} itemLimit={5} />
						)}
					</Card.Content>
					<Card.Content style={{ textAlign: 'center' }}>
						<Button
							data-test="GenButtonFirst"
							primary
							icon
							disabled={index <= 0}
							onClick={() => onChangeIndex(0)}>
							<Icon name="angle double left" />
						</Button>
						<Button
							data-test="GenButtonPrev"
							primary
							icon
							disabled={index <= 0}
							onClick={() => onChangeIndex(index ? index - 1 : 0)}>
							<Icon name="angle left" />
						</Button>
						<Button primary disabled data-test="GenButtonPosition">
							#{(index + 1).toLocaleString()} of {realMax.toLocaleString()}
						</Button>
						<Button
							data-test="GenButtonNext"
							primary
							icon
							disabled={index + 1 >= realMax}
							onClick={() => onChangeIndex(index + 1 >= realMax ? realMax - 1 : index + 1)}>
							<Icon name="angle right" />
						</Button>
						<Button
							data-test="GenButtonLast"
							primary
							icon
							disabled={index + 1 >= realMax}
							onClick={() => onChangeIndex(realMax - 1)}>
							<Icon name="angle double right" />
						</Button>
					</Card.Content>
				</>
			)}
		</Card>
	);
};
export default GenericPager;
