import classNames from 'classnames';
import { useTranslate } from 'customHooks';
import React, { useState, useRef, useEffect } from 'react';
import { Dropdown, Icon, Input, Popup } from 'semantic-ui-react';

import './FieldSwitcher.scss';

const Hook = shown => <Icon data-test="checkmarkIcon" name={shown ? 'checkmark' : ''} />;

const FieldSwitcher = ({
	className,
	fields,
	hiddenFields,
	onChange,
	onDragStart,
	onDragOver,
	onDrop,
	type,
	disabled = false,
}) => {
	const translate = useTranslate();
	const [open, setOpen] = useState(false);
	const [searchTerm, setSearchTerm] = useState(null);
	let [focusIndex, setFocusIndex] = useState(-1);

	const searchRef = useRef();
	//needed to prevent closing when focusing search field
	const preventClosing = useRef(false);

	const focusSearchTerm = () => {
		preventClosing.current = true;
		searchRef.current && searchRef.current.focus();
		preventClosing.current = false;
	};

	const handleChangeVisibility = (fieldName, i) => {
		onChange(
			hiddenFields.includes(fieldName)
				? hiddenFields.filter(x => x !== fieldName)
				: [...hiddenFields, fieldName]
		);
		i != null && setFocusIndex(i);
	};

	const handleChangeSearchTerm = (_, target) => {
		focusIndex !== -1 && setFocusIndex(-1);
		document.querySelector('.FieldSwitcher .scrollMenu').scrollTop = 0;
		setSearchTerm(target.value);
	};

	const adoptScrollPosition = (focusIndex, key) => {
		const scrollMenu = document.querySelector('.FieldSwitcher .scrollMenu');
		const scrollItem = document.querySelector('.FieldSwitcher .scrollMenu .item');
		if (!scrollMenu || !scrollItem) return;
		key === 'ArrowDown' &&
			scrollItem.clientHeight * (focusIndex + 3) - scrollMenu.scrollTop > scrollMenu.offsetHeight &&
			(scrollMenu.scrollTop += scrollItem.clientHeight);
		key === 'ArrowUp' &&
			scrollItem.clientHeight * (focusIndex - 1) - scrollMenu.scrollTop < 0 &&
			(scrollMenu.scrollTop -= scrollItem.clientHeight);

		scrollItem.clientHeight * (focusIndex + 1) - scrollMenu.scrollTop < 0 &&
			(scrollMenu.scrollTop = scrollItem.clientHeight * (focusIndex - 1));

		scrollItem.clientHeight * (focusIndex + 1) - scrollMenu.scrollTop > scrollMenu.offsetHeight &&
			(scrollMenu.scrollTop = scrollMenu.offsetHeight + scrollItem.clientHeight * (focusIndex - 3));
	};

	const handleKeyDown = e => {
		if (['ArrowUp', 'ArrowDown'].some(k => k === e.key)) {
			focusIndex >= 0 && (fields[focusIndex].headerSelected = undefined);

			if (
				(e.key === 'ArrowDown' && focusIndex + 1 < fields.length) ||
				(e.key === 'ArrowUp' && focusIndex - 1 >= 0)
			) {
				e.key === 'ArrowDown' && focusIndex++;
				e.key === 'ArrowUp' && focusIndex--;

				adoptScrollPosition(focusIndex, e.key);
			}

			setFocusIndex(focusIndex);
		} else if (e.key === ' ' /* Space */ && focusIndex >= 0) {
			handleChangeVisibility(fields[focusIndex].name);
		}
	};

	useEffect(() => {
		if (open) {
			focusSearchTerm();
			searchRef.current.select();
		}
	}, [open]);

	searchTerm &&
		(fields = fields.filter(field => {
			const label = typeof field.label === 'string' ? field.label : field.label.props.header;
			const filterAdoption = term => term.replace('-', '').replace(/\s/g, '').toLowerCase();
			return label && filterAdoption(label).includes(filterAdoption(searchTerm));
		}));

	return (
		<Popup
			trigger={
				<Dropdown
					className={classNames(className, 'icon FieldSwitcher')}
					data-test-type={`fieldSwitcher ${type}`}
					icon="bars"
					button
					open={open}
					direction="left"
					onBlur={() => !preventClosing.current && setOpen(false)}
					onClick={() => setOpen(!open)}
					onKeyDown={handleKeyDown}
					disabled={disabled}>
					<Dropdown.Menu data-test="fieldSwitcher dropdownMenu">
						<Input
							className="search"
							data-test-type={`search ${type}`}
							icon="search"
							iconPosition="left"
							onChange={handleChangeSearchTerm}
							onClick={e => e && e.stopPropagation()}
							ref={searchRef}
						/>
						<Dropdown.Menu scrolling className="scrollMenu">
							{fields.map((field, i) => (
								<Dropdown.Item
									className={i === focusIndex ? 'selected' : ''}
									data-test-type={`dropdownItem ${type}`}
									data-test-key={i}
									key={field.name}
									onClick={e => {
										e && e.stopPropagation();
										handleChangeVisibility(field.name, i);
									}}
									draggable={field.movable}
									onDragStart={e => onDragStart(e, field.name)}
									onDragOver={e => onDragOver(e, field.movable)}
									onDrop={e => onDrop(e, field.name)}>
									{Hook(!hiddenFields.includes(field.name))}
									<Icon name={field.listIcon} /> {field.label}
								</Dropdown.Item>
							))}
						</Dropdown.Menu>
					</Dropdown.Menu>
				</Dropdown>
			}
			on="hover"
			content={<b>{translate('actions.displayColumns')}</b>}
			position="bottom right"
			closeOnTriggerClick
		/>
	);
};

export default FieldSwitcher;
