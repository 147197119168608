import { useState } from 'react';

import { cloneDeep } from 'lodash';

import { order, defaultOrder, switchOrder } from 'enums';

const useSorting = (field, orderBy = defaultOrder, isMultiple = false) => {
	const defaultVal = (val => (isMultiple ? [val] : val))({ field, orderBy });
	const [{ _sorting, _shownSorting }, setValue] = useState({
		_sorting: defaultVal,
		_shownSorting: defaultVal,
	});

	const _setSorting = val => setValue({ _sorting: val, _shownSorting });
	const _setShownSorting = val => setValue({ _sorting, _shownSorting: val });
	const _setBoth = val => setValue({ _sorting: val, _shownSorting: val });

	const handleCtrlUp = () => _setSorting(_shownSorting);

	const sortByField = (field, isHoldingCtrl) => {
		if (isMultiple) {
			// sorting as array
			const newSorting = cloneDeep(_shownSorting);
			const sortingItem = newSorting.find(item => item.field === field);

			if (!isHoldingCtrl) {
				// single sort
				if (!sortingItem || !sortingItem.orderBy || sortingItem.orderBy === order.DESC) {
					_setBoth([{ field, orderBy: order.ASC }]);
				} else {
					_setBoth([{ field, orderBy: order.DESC }]);
				}
			} else {
				//  multi sort
				if (!sortingItem) {
					newSorting.push({ field, orderBy: order.ASC });
				} else if (!sortingItem.orderBy) {
					// unsorted, but in list
					sortingItem.orderBy = order.ASC;
				} else if (sortingItem.orderBy === order.ASC) {
					sortingItem.orderBy = order.DESC;
				} else if (sortingItem.orderBy === order.DESC) {
					sortingItem.orderBy = newSorting.some(item => !!item.orderBy && item.field !== field) // always at least one orderBy !==null
						? null // not sorted, but keep item in list in order to keep position in sort array
						: order.ASC;
				}

				_setShownSorting(newSorting);
			}
		} else {
			// sorting as object (not as array)
			_setBoth({
				field,
				orderBy: field === _shownSorting.field ? switchOrder(_shownSorting.orderBy) : defaultOrder,
			});
		}
	};

	const [sorting, shownSorting] = [_sorting, _shownSorting].map(item =>
		isMultiple ? item.filter(sortingItem => !!sortingItem.orderBy) : item
	);

	return {
		sorting,
		shownSorting,
		setBothSortings: _setBoth,
		sortByField,
		onCtrlUp: handleCtrlUp,
	};
};

export default useSorting;
