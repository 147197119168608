import React from 'react';
import { useTranslateContext } from 'customHooks';
import { Select } from 'semantic-ui-react';
import { MOMENT_FORMATS, LANGUAGES } from 'config';

const LocalSettings = ({ onChangeData, ...props }) => {
	const countryOptions = LANGUAGES.map(({ code, name }) => ({
		key: code,
		value: code,
		text: name,
	}));

	const timeOptions = MOMENT_FORMATS.map(({ id, example }) => ({
		key: id,
		value: id,
		text: example,
	}));

	const {
		translate,
		language,
		setLanguage,
		momentFormatID,
		setMomentFormatID,
	} = useTranslateContext();
	return (
		<div {...props}>
			<h4>{translate('local.language')}</h4>
			<Select
				value={language}
				onChange={(_, { value }) => {
					setLanguage(value);
					onChangeData('languageCode', value, false);
				}}
				options={countryOptions}
				data-test="SelectLanguage"
			/>

			<h4>{translate('local.dateAndTime')}</h4>
			<Select
				value={momentFormatID}
				onChange={(_, { value }) => {
					setMomentFormatID(value);
					onChangeData('dateFormat', value, false);
				}}
				options={timeOptions}
			/>
		</div>
	);
};

export default LocalSettings;
