import React, { useState, useRef } from 'react';

import { Select, Confirm } from 'semantic-ui-react';
import { noop } from 'utils';
import { useTranslate } from 'customHooks';

const SelectColleague = ({
	colleagues = [],
	companyUsers = [],
	onChangeColleagues = noop,
	creatorId,
	disabled,
} = {}) => {
	const confirmRef = useRef();
	const [confirmOpen, setConfirmOpen] = useState();
	const translate = useTranslate();

	const shareableUsers = companyUsers.filter(user => user.id !== creatorId);
	const options = shareableUsers.map(user => ({
		value: user.id,
		text: user.fullName,
		key: user.id,
	}));

	const handleChange = (_, target) => {
		const targetVal = target.value || [];

		confirmRef.current = () => {
			onChangeColleagues(targetVal.map(id => companyUsers.find(user => user.id === id)));
			confirmRef.current = null;
		};

		if (targetVal.length < colleagues.length) {
			setConfirmOpen(true);
		} else {
			confirmRef.current();
		}
	};

	return (
		<>
			<Select
				className="SelectColleague"
				data-test="SelectColleague"
				value={colleagues}
				onChange={handleChange}
				multiple
				clearable
				closeOnChange
				deburr
				search
				scrolling
				{...{ options, disabled }}
			/>
			<Confirm
				data-test="SelectColleagueConfirm"
				open={confirmOpen}
				closeOnDimmerClick={false}
				content={translate('alerts.unshareColleague')}
				onCancel={() => setConfirmOpen(false)}
				onConfirm={() => {
					setConfirmOpen(false);
					confirmRef.current && confirmRef.current();
				}}
			/>
		</>
	);
};

export default SelectColleague;
