import _ from 'lodash';
import { formatGenericStatisticPerYears } from './formatGenericStatistic';
import buildStreetHouseNumberFromAddress from './buildStreetHouseNumberFromAddress';
import getTranslatorOfCurrentLanguage from './getTranslatorOfCurrentLanguage';
import getCurrentFormator from './getCurrentFormator';

const translate = getTranslatorOfCurrentLanguage();

// Solves nested arrays by cloning items. This enables to apply all functions in Excel or Office Calc.
// Replaces [{id:42, myArrayPath: [{d:'bla1', e:'blub1'}, {d:'bla2', e:'blub2'}]}]
// by [{id:42, myArrayPath: {d:'bla1', e:'blub1'}}, {id:42, myArrayPath: {d:'bla2', e:'blub2'}}]
const solveNestedArrays = ({ arrayPath, data, headers }) => {
	const includingArrayPaths = headers
		.filter(header => header.key.startsWith(`${arrayPath}.`))
		.map(header => header.key);
	const fieldPaths = includingArrayPaths.map(path => path.replace(`${arrayPath}.`, ''));

	for (let i = 0; i < data.length; ) {
		const dataRow = data[i];
		const arrayItems = _.get(dataRow, arrayPath);
		if (
			arrayItems?.length > 0 &&
			includingArrayPaths.length > 0 /* do not clone if array column not defined */
		) {
			_.unset(dataRow, arrayPath);
			const addedRows = [];
			for (let j = 0; j < arrayItems.length - 1; j++) addedRows.push(_.clone(dataRow));

			addedRows.forEach((row, j) => data.splice(i + j + 1, 0, row)); // insert into data
			_.zip([dataRow, ...addedRows], arrayItems).forEach(([cloneRow, arrayItem]) => {
				_.zip(includingArrayPaths, fieldPaths).forEach(([fullPath, fieldPath]) => {
					_.set(cloneRow, fullPath, _.get(arrayItem, fieldPath));
				});
			});
			i += arrayItems.length;
		} else {
			i++;
		}
	}
};

// format array of objects to array(lists) - equivalent to how csv from export is displayed
const handleArrayOfObjectsEvents = (data, headers, allUsers) => {
	const format = getCurrentFormator();

	const predHeader = header => header.key === 'customer.events';
	if (headers.some(predHeader)) {
		const newHeader = { key: 'customerEvents', label: translate('tableView.evCustomerIdsStatus') };
		data.forEach(dataRow =>
			_.set(
				dataRow,
				newHeader.key,
				dataRow.customer?.events &&
					dataRow.customer.events
						.map(
							({ id, processingStatus }) =>
								id + (!!processingStatus ? ` (${processingStatus})` : '')
						)
						.join(', ')
			)
		);
		headers[headers.findIndex(predHeader)] = newHeader;
	}

	if (headers.some(header => header.key === 'state')) {
		data.forEach(dataRow =>
			_.update(dataRow, 'state', value => value && translate(`genericEventFilters.states.${value}`))
		);
	}

	if (headers.some(header => header.key === 'admission')) {
		data.forEach(dataRow =>
			_.update(dataRow, 'admission', value =>
				value ? value.map(admission => admission.price).join(', ') : null
			)
		);
	}
	if (headers.some(header => header.key === 'setlists')) {
		data.forEach(dataRow =>
			_.update(dataRow, 'setlists', value => (value ? value.totalCount > 0 && 'Available' : null))
		);
	}
	if (headers.some(header => header.key === 'artists')) {
		data.forEach(dataRow =>
			_.update(dataRow, 'artists', value =>
				value ? value.map(artist => artist.name).join(', ') : null
			)
		);
	}

	if (headers.some(header => header.key === 'isCancelled')) {
		data.forEach(dataRow =>
			_.update(dataRow, 'isCancelled', value =>
				value
					? translate('filters.boolean.true')
					: value === false
					? translate('filters.boolean.false')
					: ''
			)
		);
	}
	if (headers.some(header => header.key === 'isLocationSameAsOrganizer')) {
		data.forEach(dataRow =>
			_.update(dataRow, 'isLocationSameAsOrganizer', value =>
				value
					? translate('filters.boolean.true')
					: value === false
					? translate('filters.boolean.false')
					: ''
			)
		);
	}

	if (headers.some(header => header.key === 'url')) {
		data.forEach(
			dataRow =>
				(dataRow.url =
					dataRow.advertisements && dataRow.advertisements.results.map(ad => ad.url).join(', '))
		);
	}

	if (headers.some(header => header.key === 'advertisements.id')) {
		data.forEach(dataRow =>
			_.update(dataRow, 'advertisements', value => {
				return {
					id: !!value && !!value.results ? value.results.map(ad => ad.id).join(', ') : null,
					...value,
				};
			})
		);
	}

	if (headers.some(header => header.key === 'startTime')) {
		data.forEach(dataRow => (dataRow.startTime = format(dataRow.startDate, 'timeCode')));
	}

	if (headers.some(header => header.key === 'startDate')) {
		data.forEach(dataRow => _.update(dataRow, 'startDate', value => format(value)));
	}

	if (headers.some(header => header.key === 'endTime')) {
		data.forEach(dataRow => (dataRow.endTime = format(dataRow.endDate, 'timeCode')));
	}

	if (headers.some(header => header.key === 'endDate')) {
		data.forEach(dataRow => _.update(dataRow, 'endDate', value => format(value)));
	}

	if (headers.some(header => header.key === 'timestamp')) {
		data.forEach(dataRow =>
			_.update(dataRow, 'timestamp', value => format(value, 'timestampSecondsCode'))
		);
	}

	if (headers.some(header => header.key === 'customLeaflet.timestamp')) {
		data.forEach(dataRow =>
			_.update(dataRow, 'customLeaflet.timestamp', value => format(value, 'timestampSecondsCode'))
		);
	}

	if (headers.some(header => header.key === 'customLeaflet.username')) {
		data.forEach(dataRow =>
			_.update(dataRow, 'customLeaflet.username', username => {
				const user = allUsers && allUsers.find(user => user.username === username);
				return user && `${user.firstName} ${user.lastName}`;
			})
		);
	}

	if (headers.some(header => header.key === 'isLocked')) {
		data.forEach(dataRow => {
			dataRow.isLocked = dataRow.userLock?.isLocked
				? translate('filters.boolean.true')
				: translate('filters.boolean.false');
		});
	}

	if (headers.some(header => header.key === 'lockingUser')) {
		data.forEach(dataRow => {
			if (!dataRow.userLock) return null;
			const user =
				allUsers && allUsers.find(user => user.username === dataRow.userLock.user.username);
			dataRow.lockingUser = user && `${user.firstName} ${user.lastName}`;
		});
	}

	solveNestedArrays({ arrayPath: 'organizers', data, headers });

	// unset nested objects who's children have been replaced before in this function
	data.forEach(dataRow => {
		_.unset(dataRow, 'customer');
	});
	_.remove(headers, header => header.key === 'customer.events');
};

// insert additional headers for columns which show multiple data in table
const handleHeaderArrayOfObjectsEvents = headers => {
	let index;

	index = headers.findIndex(header => header.key === 'collaboration');
	if (index !== -1) {
		headers.splice(
			index,
			1,
			{ key: 'isLocked', label: translate('tableView.infoIsLocked') },
			{ key: 'lockingUser', label: translate('tableView.infoLockingUser') }
		);
	}

	index = headers.findIndex(header => header.key === 'stateInfo');
	if (index !== -1) {
		headers.splice(
			index,
			1,
			{ key: 'state', label: translate('tableView.infoState') },
			{ key: 'countOfExported', label: translate('tableView.infoExportCount') }
		);
	}
	const countHeader = headers.find(header => header.key === 'organizers.length');
	countHeader && _.update(countHeader, 'key', () => 'organizersCount');

	index = headers.findIndex(header => header.key === 'startDate');
	if (index !== -1) {
		headers.splice(index + 1, 0, { key: 'startTime', label: translate('tableView.evStartTime') });
	}
	index = headers.findIndex(header => header.key === 'endDate');
	if (index !== -1) {
		headers.splice(index + 1, 0, { key: 'endTime', label: translate('tableView.evEndTime') });
	}

	return headers;
};

// format array of objects to array(lists) - equivalent to how csv from export is displayed
const handleArrayOfObjectsGeneric = (data, headers, type) => {
	const customerField = { location: 'customerLocations', organizer: 'customerOrganizers' }[type];
	let predefinedHeader = header => header.key === customerField;

	if (headers.some(predefinedHeader)) {
		const newHeader = {
			key: 'customerEventsPerYear',
			label: translate('genTableView.cusEvPerYear'),
		};
		data.forEach(entity =>
			_.set(
				entity,
				newHeader.key,
				entity[customerField] && formatGenericStatisticPerYears(entity, type).join(', ')
			)
		);
		headers[headers.findIndex(predefinedHeader)] = newHeader;
	}

	predefinedHeader = header => header.key === 'statistic.eventCountPerYear';
	if (headers.some(predefinedHeader)) {
		const newHeader = { key: 'meslisEventsPerYear', label: translate('genTableView.evPerYear') };
		data.forEach(entity =>
			_.set(
				entity,
				newHeader.key,
				entity.statistic?.eventCountPerYear &&
					entity.statistic.eventCountPerYear.map(obj => `${obj.year}: ${obj.count}`).join(', ')
			)
		);
		headers[headers.findIndex(predefinedHeader)] = newHeader;
	}

	// unset nested objects who's children have been replaced before in this function
	data.forEach(dataRow => {
		_.unset(dataRow, 'statistic.eventCountPerYear');
		_.unset(dataRow, 'customerLocations');
		_.unset(dataRow, 'customerOrganizers');
	});

	_.remove(
		headers,
		header =>
			header.key === 'statistic.eventCountPerYear' ||
			header.key === 'customerLocations' ||
			header.key === 'customerOrganizers'
	);
};

/**
 * @description escapes quotes in supplied objects
 * @param {event|location|organizer} object
 */
const escapeQuotes = object => {
	for (const field in object) {
		if (!object.hasOwnProperty(field)) {
			continue;
		}

		if (typeof object[field] === 'string') {
			object[field] = `${object[field]}`.replace(/"/g, '""');
		}

		if (typeof object[field] === 'object') {
			escapeQuotes(object[field]);
		}
	}
};

/**
 * @typedef {Object} header
 * @property {String} label
 * @property {String} name
 */
/**
 * @typedef {Object} headerParsed
 * @property {String} label
 * @property {String} key
 */
/**
 * @typedef {Object} csvResult
 * @property {event[]|location[]|organizer[]} csvData
 * @property {headerParsed[]} csvHeaders
 */
/**
 * Maps the supplied data and headers to a format understood by the csv library
 * @param {Object} param
 * @param {String} param.tableKey for which table to map, used to detect input entity
 * @param {header[]} param.headers headers to keep for result csvData
 * @param {event[]|location[]|organizer[]} param.data data that should be mapped to csv
 * @param {Object[]} param.allUsers
 * @returns {csvResult}
 */
const dataToCSV = ({ tableKey, headers, data, allUsers }) => {
	// only take label and name from header and "rename" name to key
	let csvHeaders = headers.flatMap(header => {
		if (header.name && header.name.includes('streetHouseNumber')) {
			const addressKey = header.name.replace(/\.streetHouseNumber/, '');
			// use street as key for streetHouseNumber, so that we dont need to manage 3 different keys to set StreetHouseNumber
			const streetHouseNumberKey = addressKey + '.street';
			const houseNumberKey = addressKey + '.houseNumber';

			const streetHeader = { label: header.label, key: streetHouseNumberKey };
			const houseNumberHeader = { label: header.label, key: houseNumberKey };
			return [streetHeader, houseNumberHeader];
		}

		return [{ label: header.label, key: header.name }];
	});

	// don't change data which is displayed in app
	let csvData = _.cloneDeep(data);

	// format arrays of objects to display the same way from backend export
	switch (tableKey) {
		case 'events':
			csvHeaders = handleHeaderArrayOfObjectsEvents(csvHeaders);
			handleArrayOfObjectsEvents(csvData, csvHeaders, allUsers);
			break;
		case 'location-search':
			handleArrayOfObjectsGeneric(csvData, csvHeaders, 'location');
			break;
		case 'organizer-search':
			handleArrayOfObjectsGeneric(csvData, csvHeaders, 'organizer');
			break;
		default:
			console.error('invalid tableKey supplied');
	}

	// filter data to only include displayed keys (headers)
	csvData = csvData.map(entity => {
		const streetHeaders = csvHeaders.filter(x => x.key.includes('street'));

		if (streetHeaders.length) {
			correctStreetHouseNumberMapping(entity, streetHeaders, csvHeaders);
		}

		return _.pick(
			entity,
			csvHeaders.map(header => header.key)
		);
	});

	// update label for InfoPopup
	csvHeaders.forEach(header => {
		const label = _.get(header, 'label.props.header');
		label && _.update(header, 'label', () => label);
	});

	csvData.forEach(event => escapeQuotes(event));

	return { csvData, csvHeaders };
};

/**
 * correct the virtual streetHouseNumber field that doesnt actually exist in soruce data and is usually mapped in Table component
 * @important needs to work for all views:
 * * location: address.streetHouseNumber
 * * organizer: address.streetHouseNumber
 * * event: [location.address.streetHouseNumber, organizers.address.streetHouseNumber])
 * @param {headerParsed[]} headers
 * @param {location|event|organizer} entity
 * @param {headerParsed[]} csvHeaders
 */
const correctStreetHouseNumberMapping = (entity, headers, csvHeaders) => {
	headers.forEach(({ key }) => {
		const addressKey = key.replace(/\.street/, '');
		const address = _.get(entity, addressKey);

		_.set(entity, key, buildStreetHouseNumberFromAddress(address));

		/// remove houseNumber header that is only required to allow mapping the streetHouseNumber and should not be included in the csv later on
		const houseNumberKey = addressKey + '.houseNumber';
		_.remove(csvHeaders, header => header.key === houseNumberKey);
	});
};

export default dataToCSV;
