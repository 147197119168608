import React from 'react';
import { useTranslate } from 'customHooks';
import { Icon } from 'semantic-ui-react';

import './Collapse.scss';

const Collapse = ({ onChange, collapsed }) => {
	const translate = useTranslate();
	return (
		<div className="Collapse" onClick={onChange}>
			{collapsed ? <Icon name="chevron circle right" /> : <Icon name="chevron circle left" />}
			{collapsed ? '' : translate('navigation.close')}
		</div>
	);
};

export default Collapse;
