import React, { useState, useEffect } from 'react';
import ActionAssociationSelect from './ActionAssociationSelect';

import { BlocksInput } from 'components/Base';

import filterUtils from '../filterUtils';
import { Form, Input } from 'semantic-ui-react';

const KeywordInput = ({ value, error, onChange, allowedAssociations, isBlocksInput = true }) => {
	const [state, setState] = useState({
		memoryKeyword: null,
		existsMode: false,
		isTextStar: false,
	});
	const { memoryKeyword, existsMode, isTextStar } = state;

	const isUseExists = allowedAssociations.includes('exists') && !isTextStar;

	useEffect(() => {
		value.keyword !== '*' && isTextStar && setState({ ...state, isTextStar: false });

		// eslint-disable-next-line
	}, [value.keyword]); // isTextStar and setState not needed for dependency array

	const handleChanges = changes => {
		let _value = { ...value, ...changes };
		isUseExists && (_value = filterUtils.adoptFromExistsAssociation(_value));
		onChange(_value);
	};

	const handleChangeKeyword = keyword => {
		keyword === '*' && !isTextStar && setState({ ...state, isTextStar: true });

		handleChanges({ keyword });
	};

	const handleChangeAction = action => handleChanges({ action });

	const handleChangeAssociation = association => {
		if (association === 'exists') {
			setState({ memoryKeyword: value.keyword, existsMode: true, isTextStar: false });
			handleChanges({ association });
		} else {
			if (existsMode) {
				const keyword = memoryKeyword !== '*' ? memoryKeyword : '';
				handleChanges({ keyword, association });
			} else {
				handleChanges({ association });
			}
		}
	};

	isUseExists && (value = filterUtils.adoptToExistsAssociation(value));

	value.association !== 'exists' &&
		existsMode &&
		setState({ ...state, memoryKeyword: null, existsMode: false });

	return (
		<>
			<ActionAssociationSelect
				action={value.action}
				association={value.association}
				onChangeAction={handleChangeAction}
				onChangeAssociation={handleChangeAssociation}
				{...{ allowedAssociations }}
			/>

			{isBlocksInput ? (
				<BlocksInput
					value={value.keyword}
					onChange={handleChangeKeyword}
					error={error}
					style={{ visibility: value.association === 'exists' ? 'hidden' : 'visible' }}
				/>
			) : (
				<Form.Field style={{ visibility: value.association === 'exists' ? 'hidden' : 'visible' }}>
					<Input
						fluid
						value={value.keyword}
						error={error}
						onChange={(_, { value }) => handleChangeKeyword(value)}
						data-test="keywordInputText"
					/>
				</Form.Field>
			)}
		</>
	);
};

const GenericKeywordInput = ({ allowedAssociations, isBlocksInput }) => {
	return ({ value, error, onChange }) => (
		<KeywordInput {...{ value, error, onChange, allowedAssociations, isBlocksInput }} />
	);
};

export default GenericKeywordInput;
