import React from 'react';

import { FullLayout } from 'components/Layout';
import WorkflowLayer from 'components/WorkflowLayer';
import { useSelectable, useTranslate } from 'customHooks';
import { configHistory } from 'components/Table/Events';

import { MAX_EVENTS_SELECTABLE } from 'config';
import { alerts } from 'utils';

// setup similar to Process in EventsWorkflow
const Content = ({ ...props }) => {
	const translate = useTranslate();
	const [
		selection,
		setSelection,
		swapSelected,
		removeSelected,
		addBulkSelected,
		removeBulkSelected,
		isSelected,
	] = useSelectable({
		max: MAX_EVENTS_SELECTABLE,
		onMaxReached: () => alerts.selectedMaxAmountOfEvents(MAX_EVENTS_SELECTABLE),
	});

	const noDataMsg = translate('messages.noHistory');

	const handleDeselectAll = () => setSelection([]);

	// does this work as replacement?
	const handleActionCompleted = item => {
		!!item ? removeSelected(item) : setSelection([]);
	};

	// scrapped, discarded
	const sourceLeaflets = [3, 4];

	return (
		<FullLayout>
			<WorkflowLayer
				{...{
					selection,
					isSelected,
					setSelection,
					sourceLeaflets,
					noDataMsg,
					...props,
				}}
				onSelectItem={swapSelected}
				onSelectAll={setSelection}
				onDeselectAll={handleDeselectAll}
				onAddBulk={addBulkSelected}
				onRemoveBulk={removeBulkSelected}
				onActionCompleted={handleActionCompleted}
				config={configHistory}
			/>
		</FullLayout>
	);
};

export default Content;
