import React from 'react';
import { Mutation } from '@apollo/client/react/components';

import { TO_CUSTOM_LEAFLET } from 'graphql/mutations';

const ToLeafletById = ({ children, onCompleted = data => {}, ...props }) => {
	const handleCompleted = data => {
		onCompleted(data.toLeafletById);
	};

	return (
		<Mutation mutation={TO_CUSTOM_LEAFLET} onCompleted={handleCompleted} {...props}>
			{children}
		</Mutation>
	);
};

export default ToLeafletById;
