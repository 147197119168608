import React from 'react';
import Generic, { configField as _configField} from './Generic';
import { DownloadSelection, ParsedText } from 'components/Base';

import './Selections.scss';
import { useTranslateContext } from 'customHooks';

const configField = props => _configField({ ...props, multipleSort: false });

const Selections = ({
	data,
	refetchExports,
	moreDataAvailable,
	onReachBottom,
	onSort,
	sorting,
}) => {
	const { translate, formatDate } = useTranslateContext();
	const fields = [
		configField({
			name: 'filename',
			label: translate('selectionsTableView.filename'),
			sortable: true,
		}),
		configField({
			name: 'createdAt',
			label: translate('selectionsTableView.createdAt'),
			sortable: true,
			render: data => formatDate(data.createdAt),
		}),
		configField({
			name: 'description',
			label: translate('selectionsTableView.description'),
			sortable: true,
			render: data => <ParsedText>{data.description}</ParsedText>,
		}),
		configField({
			name: 'downloadCount',
			label: translate('selectionsTableView.downloadCount'),
			sortable: true,
		}),
		configField({
			name: 'actions',
			label: translate('selectionsTableView.actions'),
			sortable: true,
			movable: false,
			sortField: 'isAvailable',
			render: data => <DownloadSelection {...{ refetchExports, data }} />,
		}),
	];
	return (
		<Generic
			name={translate('selectionsTableView.mySelections')}
			tableKey="selection"
			storageMemoKey="selection"
			{...{ data, fields, moreDataAvailable, onReachBottom, onSort, sorting }}
		/>
	);
};

export default Selections;
