import React, { useState, useEffect, useContext } from 'react';

import Search from './Search';
import Select from './Select';
import Process from '../EventsWorkflow/Process';
import { AppContext } from 'contexts/AppContext';
import { FilterWidgetContext } from 'contexts/FilterWidgetContext';
import { useSorting, useQueryParams } from 'customHooks';
import { order } from 'enums';
import classNames from 'classnames';
import './index.scss';

const GenericWorkflow = ({ type }) => {
	// type is location or organizer but not event
	const { location, organizer } = useContext(FilterWidgetContext);
	const {
		filters,
		setFilters,
		sorting: genericSorting,
		shownSorting: shownGenericSorting,
		sortByField: sortGenericByField,
		onCtrlUp: onCtrlGenericUp,
	} = { location, organizer }[type];

	const {
		sorting: eventSorting,
		shownSorting: shownEventSorting,
		sortByField: sortEventByField,
		onCtrlUp: onCtrlEventUp,
	} = useSorting('rating', order.DESC, true);

	const { step } = useQueryParams({ step: 'search' });
	const { tableMemo } = useContext(AppContext);
	const [lastSelections, setLastSelections] = useState([]);
	const [forwardSelections, setForwardSelections] = useState([]);

	const handleSortEventByField = (...obj) => {
		setLastSelections(lastSelections);
		setForwardSelections(forwardSelections);
		sortEventByField(...obj);
	};

	useEffect(() => {
		setLastSelections([]);
		setForwardSelections([]);
	}, [step]);

	const handleGenericFiltersUpdate = filters => {
		setFilters(filters);
		tableMemo.setScrollUpFlag();
	};

	return (
		<div className={classNames('GenericWorkflow', type)}>
			{step === 'search' ? (
				<Search
					filters={filters}
					sorting={genericSorting}
					shownSorting={shownGenericSorting}
					onSort={sortGenericByField}
					onCtrlUp={onCtrlGenericUp}
					onChangeFilters={handleGenericFiltersUpdate}
					{...{ type }}
				/>
			) : step === 'select' ? (
				<Select
					genericFilters={filters.filter(x => x.enabled)}
					{...{
						genericSorting,
						eventSorting,
						shownEventSorting,
						onCtrlEventUp,
						lastSelections,
						forwardSelections,
					}}
					onSort={handleSortEventByField}
					{...{ type }}
				/>
			) : (
				<Process
					sorting={eventSorting}
					shownSorting={shownEventSorting}
					onCtrlUp={onCtrlEventUp}
					onSort={handleSortEventByField}
					{...{ lastSelections, forwardSelections }}
				/>
			)}
		</div>
	);
};
export default ({ type }) => () => GenericWorkflow({ type });
