import React, { useState } from 'react';
import { Button, Modal } from 'semantic-ui-react';

import { ClearLeaflet } from 'components/Base';
import { useTranslate } from 'customHooks';

const ClearModal = ({ disabled, leafletData, sorting, offset }) => {
	const [open, setOpen] = useState(false);
	const [clearing, setClearing] = useState(false);
	const translate = useTranslate();

	const ClearButton = () => (
		<Button data-test="ClearModalTriggerButton" onClick={() => setOpen(true)} {...{ disabled }}>
			{translate('leaflets.clear')}
		</Button>
	);

	const handleClose = () => {
		setOpen(false);
		setClearing(false);
	};

	return (
		<Modal
			size="small"
			onClose={handleClose}
			trigger={<ClearButton />}
			{...{ open }}
			className="fadeUp"
			data-test="ClearModal">
			<Modal.Header>{translate('leaflets.clearMsg', [leafletData.name])}</Modal.Header>
			<Modal.Content>
				<Modal.Description>
					<p>{translate('leaflets.removeAllEventsMsg', [leafletData.name])}</p>
				</Modal.Description>
			</Modal.Content>
			<Modal.Actions>
				<Button loading={clearing} disabled={clearing} onClick={handleClose}>
					{translate('leaflets.cancel')}
				</Button>

				<ClearLeaflet
					leafletId={leafletData.id}
					onCleared={handleClose}
					{...{ sorting, offset, clearing, setClearing }}
				/>
			</Modal.Actions>
		</Modal>
	);
};

export default ClearModal;
