import React from 'react';
import _ from 'lodash';
import { Placeholder } from 'semantic-ui-react';

const LoadingContent = () => (
	<Placeholder data-test="LoadingContent" fluid style={{ margin: 20 }}>
		<Placeholder.Header>
			<Placeholder.Line />
		</Placeholder.Header>
		{[4, 2, 6, 3, 5].map((line, index) => (
			<Placeholder.Paragraph key={index}>
				{_.times(line, index => (
					<Placeholder.Line key={index} />
				))}
			</Placeholder.Paragraph>
		))}
	</Placeholder>
);

export default LoadingContent;
