import classNames from 'classnames';
import React from 'react';
import { Input } from 'semantic-ui-react';
// Semantic-ui-react Input type number component, accepts negative numbers and the 'e' to express exponents. In order to prevent this unwated input occurances this component was created. Having more strict requirements, it will only accept up to six numbers, excluding letters (captlized or not) and special characters.
export default ({ value, onChange, maxSize = 6, className, ...props }) => {
	const handleChange = (e, { value, ...target }) => {
		// by using maxSize, we can set the maximum number of digits that can be entered when using this component
		let inputValidator = new RegExp('^[0-9]{0,' + maxSize + '}$');

		if (!!value && !inputValidator.test(value)) return;
		onChange(e, { value, ...target });
	};
	return (
		<Input
			className={classNames('PositiveIntegerInput', className)}
			value={value}
			onChange={handleChange}
			{...props}
		/>
	);
};
