import gql from 'graphql-tag';

export const subscribeForDownload = gql`
	subscription subscribeForDownload($id: Int!) {
		subscribeForDownload(id: $id) {
			isCompleted
		}
	}
`;

export const subscribeToLeaflet = gql`
	subscription eventChangedByLeafletIds($ids: [Int!], $componentId: Int) {
		eventChangedByLeafletIds(ids: $ids, componentId: $componentId) {
			isSuccessful
			sender
		}
	}
`;

export const subscribeToQueryChange = gql`
	subscription savedQueryChanged($componentId: Int) {
		savedQueryChanged(componentId: $componentId) {
			isShared
			savedQueryId
		}
	}
`;

export const subscribeToLeafletChange = gql`
	subscription leafletChanged($componentId: Int) {
		leafletChanged(componentId: $componentId) {
			isShared
			leafletId
			isUpdated
		}
	}
`;

export const eventsHaveChangedBySameUser = gql`
	subscription eventsHaveChangedBySameUser($instanceId: Int) {
		eventsHaveChangedBySameUser(instanceId: $instanceId) {
			eventIds
			isSuccessful
			sender
		}
	}
`;

export const subscribeToEventLocking = gql`
	subscription eventsChangedByLocking {
		eventsChangedByLocking {
			eventIds
			userLock {
				isLocked
				user {
					username
				}
			}
		}
	}
`;

export const subscribeToOrganizerLocking = gql`
	subscription organizersChangedByLocking {
		organizersChangedByLocking {
			organizerIds
			eventIds
			userLock {
				isLocked
				user {
					username
				}
			}
		}
	}
`;
