import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { ApolloConsumer } from '@apollo/client';

import { AVAILABLE_PLUS_YEARS, EntityEnum } from 'config';
import { getPubKey, rsaEncrypt } from 'utils';
import { LOGIN } from 'graphql/queries';
import { LOGOUT } from 'graphql/queries';
import { ME } from 'graphql/queries';
import browserStorage from 'browserStorage';

import { AppContext } from './AppContext';
import { FilterWidgetContext } from './FilterWidgetContext';
import _ from 'lodash';

class MeslisClient {
	constructor(client) {
		this.client = client;
	}

	me = () => this.client.query({ query: ME });

	login = (user, pass) =>
		rsaEncrypt(pass, getPubKey).then(encrypted => {
			return this.client.query({
				query: LOGIN,
				variables: {
					username: user,
					password: encrypted,
				},
			});
		});

	logout = () => this.client.query({ query: LOGOUT }).then(() => this.client.clearStore());
}

const withMeslis = WrappedComponent => props => (
	<ApolloConsumer>
		{client => <WrappedComponent client={new MeslisClient(client)} {...props} />}
	</ApolloConsumer>
);

const UserContext = createContext({
	user: '',
});

const UserProvider = withMeslis(({ client, children }) => {
	const [user, setUser] = useState(undefined);
	const [loading, setLoading] = useState(true);
	const [eventTags, setEventTags] = useState([]);
	const [eventStatuses, setEventStatuses] = useState([]);
	const [locationStatuses, setLocationStatuses] = useState([]);
	const [organizerStatuses, setOrganizerStatuses] = useState([]);

	const { removeFilters } = useContext(FilterWidgetContext);
	const { tableMemo, clearUndoList } = useContext(AppContext);

	const signOut = () => {
		browserStorage.resetUserSettings();
		tableMemo.resetAll();
		client.logout().then(() => setUser(null));
		clearUndoList();
	};

	const signIn = (user, pass) =>
		client.login(user, pass).then(response => setUser(response.data.login));

	const _setStatuses = statuses => {
		setEventStatuses(
			statuses.filter(x => x.entityType === EntityEnum.EVENT).sort(x => x.statusTypeName)
		);
		setLocationStatuses(
			statuses.filter(x => x.entityType === EntityEnum.LOCATION).sort(x => x.statusTypeName)
		);
		setOrganizerStatuses(
			statuses.filter(x => x.entityType === EntityEnum.ORGANIZER).sort(x => x.statusTypeName)
		);
	};

	useEffect(() => {
		browserStorage.getToken()
			? client
					.me()
					.then(response => setUser(response.data.me))
					.catch(() => setUser(null))
			: setUser(false);
	}, [client]);

	useEffect(
		() => {
			if (user === undefined) {
				return;
			} else if (!user) {
				browserStorage.removeUser();
				!!removeFilters && removeFilters();
			} else {
				const { eventTags: _eventTags, companyStatuses } = user?.company || {};
				if (_eventTags?.length > 0) {
					setEventTags(_eventTags);
				}
				if (companyStatuses?.length > 0) {
					_setStatuses(companyStatuses);
				}
				browserStorage.setUser(user);
			}
			setLoading(false);
		},
		// eslint-disable-next-line
		[user] // removeFilters not needed for dependency array
	);

	const defaultMinusYears = user?.company?.availableHistory;
	const defaultPlusYears = AVAILABLE_PLUS_YEARS;

	const getAvailableTime = useCallback(
		({ minusYears = defaultMinusYears, plusYears = defaultPlusYears } = {}) => {
			if (!minusYears || !plusYears) {
				return { years: [] };
			}

			const currentYear = new Date().getFullYear();
			const minYear = currentYear - minusYears;
			const maxYear = currentYear + plusYears;
			const years = _.range(minYear, maxYear + 1); // maxYear + 1 because exclusive definition
			const minDate = `${minYear}-01-01 00:00:00`;
			const maxDate = `${maxYear}-12-31 23:59:59`;

			return { minYear, maxYear, years, minDate, maxDate };
		},
		[defaultMinusYears, defaultPlusYears]
	);

	const store = {
		user,
		loading,
		signIn,
		signOut,
		getAvailableTime,
		eventTags,
		eventStatuses,
		locationStatuses,
		organizerStatuses,
	};

	return <UserContext.Provider value={store}>{children}</UserContext.Provider>;
});

export { UserContext, UserProvider };
