import React from 'react';
import { Popup, Icon, Label } from 'semantic-ui-react';
import { useTranslate } from 'customHooks';

import './Header.scss';
import { CONFLICT } from 'config';
import { InfoPopup, LabeledIcon } from 'components/Base';
import classNames from 'classnames';

const Header = ({ leafletData, totalCount, leafletId, isChanged, isNew, isCollapsed }) => {
	const translate = useTranslate();
	const info =
		leafletData.id === CONFLICT ? (
			<>
				{translate('leaflets.conflictTip1')}
				<br />
				<br />
				<i>{translate('leaflets.conflictTip2')}</i>
			</>
		) : (
			leafletId && leafletData.description
		);
	const isShare = leafletData.editors || leafletData.id === CONFLICT;
	const isRenderFirstLine =
		!isCollapsed && (!!leafletData.creator || !!info || isShare || isChanged);
	return (
		<div className={'Header'}>
			{isRenderFirstLine && (
				<div className={'line small'}>
					{leafletData.creator && (
						<div className={'creator'}>
							{leafletData.creator.firstName + ' ' + leafletData.creator.lastName}
						</div>
					)}
					<div className={'icons'}>
						<div>{!!info && <InfoPopup content={info} position="bottom center" />}</div>
						<div>
							{isShare && (
								<Popup
									trigger={
										<LabeledIcon
											name="share alternate"
											label={isNew ? translate('leaflets.new') : null}
										/>
									}
									content={translate('leaflets.sharedTooltip')}
									position="bottom right"
								/>
							)}
						</div>
						<div> {isChanged && <Icon name="sync alternate" />}</div>
					</div>
				</div>
			)}

			<div className={classNames('line', { small: isRenderFirstLine })}>
				<div className={'items'}>
					{totalCount.toLocaleString()} {translate('leaflets.items')}
				</div>
				{leafletData.eventTag && <Label className={'description'}>{leafletData.eventTag}</Label>}
			</div>
		</div>
	);
};

export default Header;
