import React from 'react';
import { useTranslate } from 'customHooks';
import { Icon, Message } from 'semantic-ui-react';

const MoreData = () => {
	const translate = useTranslate();
	return (
		<Message icon>
			<Icon name="circle notched" loading />
			<Message.Content>
				<Message.Header>{translate('placeholders.moreDataHeader')}</Message.Header>
				<p>{translate('placeholders.moreData')}</p>
			</Message.Content>
		</Message>
	);
};

export default MoreData;
