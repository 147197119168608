import { get } from 'lodash';

export const defaultSortFunction = field => (a, b) => {
	// get since sort.field for ownder ('user.username') isnt resolved correctly otherwise
	// toLowerCase for strings, since upper case < lower case => compare at same level
	const _a = typeof get(a, field) === 'string' ? get(a, field).toLowerCase() : get(a, field);
	const _b = typeof get(b, field) === 'string' ? get(b, field).toLowerCase() : get(b, field);

	if (_a < _b) return -1;
	if (_a > _b) return 1;
	return 0;
};
