import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { ApmRoute } from '@elastic-deecoob/apm-rum-react';

import PasswordReset from 'components/PasswordReset';
import Success from 'components/PasswordReset/Success';
import Login from 'components/Login';

const LoggedOutSwitch = () => {
	return (
		<Switch>
			<ApmRoute exact path="/login" component={Login} />
			<ApmRoute path="/verify-password-reset" component={PasswordReset} />
			<ApmRoute path="/reset-successful" component={Success} />
			{<Route render={() => <Redirect to="/login" />} />}
		</Switch>
	);
};

export default LoggedOutSwitch;
