import React from 'react';
import { useTranslate } from 'customHooks';
import DateRangeEntry from './DateRangeEntry';

export default ({ data: { start_from, start_to, end_from, end_to } = {} }) => {
	const translate = useTranslate();
	return (
		<div data-test="RangesEntry">
			{(start_from || start_to) && (
				<>
					{translate('filters.dateRange.start')}:{' '}
					<DateRangeEntry data={{ from: start_from, to: start_to }} />
				</>
			)}
			{(start_from || start_to) && (end_from || end_to) && <br />}
			{(end_from || end_to) && (
				<>
					{translate('filters.dateRange.end')}: <DateRangeEntry data={{ from: end_from, to: end_to }} />
				</>
			)}
		</div>
	);
};
