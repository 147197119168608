import { isEqual, uniqWith } from 'lodash';

const correctAction = (action, _isNegative) =>
	action == null
		? null
		: _isNegative
		? action.replace('include', 'exclude')
		: action.replace('exclude', 'include');

const oppositeAction = action =>
	action.includes('include') ? correctAction(action, true) : correctAction(action, false);

const adoptToActionAssociation = ({ selectAction, ...value }) => {
	const [action, association] = selectAction.split('_');
	return { action, association, ...value };
};

const adoptToSelectAction = (
	{ action, association, ...value },
	undefinedAssociation2Or = false
) => ({
	selectAction: action + (!!association ? '_' + association : undefinedAssociation2Or ? '_or' : ''),
	...value,
});

const adoptToExistsAssociation = ({ association, keyword, ...values }, defaultKeyword = '') =>
	keyword === '*'
		? { ...values, association: 'exists', keyword: defaultKeyword }
		: { association, keyword, ...values };
const adoptFromExistsAssociation = ({ association, keyword, ...values }) =>
	association === 'exists'
		? { ...values, association: null, keyword: '*' }
		: { association, keyword, ...values };

const removeDuplicates = filters =>
	uniqWith(
		filters,
		({ enabled: _, ...attribsA }, { enabled: __, ...attribsB }) =>
			isEqual({ ...attribsA }, { ...attribsB }) // it is a dublicate even if enabled-property differs
	);

const filterUtils = {
	oppositeAction,
	adoptToActionAssociation,
	adoptToSelectAction,
	adoptToExistsAssociation,
	adoptFromExistsAssociation,
	removeDuplicates,
};

export default filterUtils;
