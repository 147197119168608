import _ from 'lodash';

const getTranslator = getDictionary => (path, parameters) => {
	if (!_.get(getDictionary, path)) {
		throw new Error(`The path ${path} does not exist in the dictionary`);
	}

	return parameters ? _.get(getDictionary, path)(...parameters) : _.get(getDictionary, path);
};

export default getTranslator;
