import React, { useState, useContext } from 'react';
import { Button, Form, Icon, Message } from 'semantic-ui-react';

import { useTranslate } from 'customHooks';
import { UserContext } from 'contexts/UserContext';

import ResetPasswordModal from './ResetPasswordModal';

import './LoginBox.scss';

const LoginBox = () => {
	const [user, setUserInput] = useState('');
	const [pass, setPassInput] = useState('');
	const [error, setError] = useState(false);
	const [resetRequested, setResetRequested] = useState(false);

	const translate = useTranslate();

	const { signIn } = useContext(UserContext);

	const authFailed = () => setError('error.login');
	const userLocked = () => setError('error.locked');

	const handleSignIn = e => {
		e.preventDefault();
		signIn(user, pass).catch(e => {
			console.error('catch error', e.message, e.URL, e.lineNo, e.errorObj, ' has failed');
			e.message === 'GraphQL error: Account is locked.' ? userLocked() : authFailed();
		});
		setResetRequested(false);
	};

	const handleChangeUser = (e, data) => setUserInput(data.value);

	const handleChangePass = (e, data) => setPassInput(data.value);

	const handleResetSuccess = () => {
		setResetRequested(true);
		setError(false);
	};

	return (
		<div className="whitebox">
			<Icon size="massive" className="avatar" name="user circle" />
			<Form onSubmit={handleSignIn}>
				<Form.Input
					data-test="LoginUsernameInput"
					type="text"
					onChange={handleChangeUser}
					id="username"
					autoComplete="username"
					placeholder={translate('username')}
				/>
				<Form.Input
					data-test="LoginPasswordInput"
					type="password"
					onChange={handleChangePass}
					autoComplete="current-password"
					placeholder={translate('password')}
				/>
				{error && (
					<Message negative data-test={error}>
						{translate(error)}
					</Message>
				)}

				{resetRequested && (
					<Message positive> {translate('resetPassword.successMessage')} </Message>
				)}

				<ResetPasswordModal
					onSuccess={handleResetSuccess}
					hideSuccess={() => setResetRequested(false)}
				/>

				<Button
					floated="right"
					data-test="LoginSubmitButton"
					type="submit"
					primary
					content={translate('login')}
				/>
			</Form>
			<div className="version">v{process.env.REACT_APP_VERSION}</div>
		</div>
	);
};

export default LoginBox;
