import {
	NumberRangeInput,
	GenericKeywordInput,
	RatingRange,
	DateTimeRangeInput,
	DateRangesInput,
	StatusListSelect,
	TagListSelect,
	GenericEventsPerYearInput,
	EventStateInfoInput,
	BooleanSelect,
	CollaborationInput,
	EntityStatusListSelect,
	UserSelect,
} from 'components/FilterWidget/Forms';
import {
	KeywordEntry,
	NumberRangeEntry,
	DateRangeEntry,
	RangesEntry,
	RatingEntry,
	StatusEntry,
	TagEntry,
	EventStateInfoEntry,
	EventsPerYearEntry,
	BooleanEntry,
	CollaborationEntry,
	EntityStatusEntry,
	UserEntry,
} from 'components/FilterWidget/FilterList/Entries';
import { INVALID_DATE } from 'config';
import EventStateQuickFilter from 'components/FilterWidget/EventStateQuickFilter';
import { organizerFields } from 'components/FilterWidget/OrganizersFilter';
import { locationFields } from 'components/FilterWidget/LocationsFilter';
import { eventFields } from 'components/FilterWidget/EventsFilter';

const _validateKeyword = ({ keyword } = {}) => !!keyword && keyword !== '';
const _isNotArrayOrArrayNotEmpty = ({ keyword } = {}) =>
	!Array.isArray(keyword) || keyword.length > 0;

const numberRange = {
	name: 'numberRange',
	Component: NumberRangeInput,
	EntryComponent: NumberRangeEntry,
	defaultValue: { from: '', to: '' },
	validate: ({ from, to } = {}) => {
		const bothFieldsAreSet = !!from && !!to;
		if (bothFieldsAreSet) {
			return parseInt(from) <= parseInt(to);
		}

		const atLeastOneFieldIsSet = !!from || !!to;
		return atLeastOneFieldIsSet;
	},
};

const _eventsPerYearProps = {
	EntryComponent: EventsPerYearEntry,
	validate: ({ count, min, max } = {}) => !!count || (!!min && !!max),
};

const meslisEvents = {
	name: 'meslisEvents',
	Component: GenericEventsPerYearInput({ isMeslisEvents: true }),
	defaultValue: {
		operator: 'greaterThanEqual',
		count: '1',
		min: null,
		max: null,
		from_year: null,
		to_year: null,
	},
	..._eventsPerYearProps,
};

const customerEvents = {
	name: 'customerEvents',
	Component: GenericEventsPerYearInput({ isMeslisEvents: false }),
	defaultValue: {
		operator: 'underLicensed',
		count: '1',
		from_year: null,
		to_year: null,
	},
	..._eventsPerYearProps,
};

//should be renamed to dateTimeRange to be more clear
const dateRange = {
	name: 'dateRange',
	Component: DateTimeRangeInput,
	EntryComponent: DateRangeEntry,
	defaultValue: { from: '', to: '' },
	validate: ({ from, to } = {}) => {
		const atLeastOneDateIsSet = !!from || !!to;
		const hasNoInvalidDates = [from, to].every(date => date !== INVALID_DATE);

		return atLeastOneDateIsSet && hasNoInvalidDates;
	},
};

const datesRange = {
	name: 'datesRange',
	Component: DateRangesInput,
	EntryComponent: RangesEntry,
	defaultValue: { start_from: '', start_to: '', end_from: '', end_to: '' },
	validate: ({ start_from, start_to, end_from, end_to } = {}) => {
		const atLeastOneDateIsSet = !!start_from || !!start_to || !!end_from || !!end_to;
		const hasNoInvalidDates = [start_from, start_to, end_from, end_to].every(
			date => date !== INVALID_DATE
		);

		return atLeastOneDateIsSet && hasNoInvalidDates;
	},
};

const ratingRange = {
	name: 'ratingRange',
	Component: RatingRange,
	EntryComponent: RatingEntry,
	defaultValue: { operator: 'equal', rating: 3 },
	validate: ({ rating, operator } = {}) => !!rating?.toString() && !!operator,
};

const tagList = {
	name: 'tagList',
	Component: TagListSelect,
	EntryComponent: TagEntry,
	defaultValue: {
		action: 'include',
		keyword: [],
		association: 'or',
	},
	validate: _isNotArrayOrArrayNotEmpty,
};

const entityStatusSelect = {
	name: 'entityStatusSelect',
	Component: EntityStatusListSelect,
	EntryComponent: EntityStatusEntry,
	defaultValue: {
		action: 'include',
		keyword: [],
		association: 'or',
	},
	validate: _isNotArrayOrArrayNotEmpty,
};

const userSelect = {
	name: 'userSelect',
	Component: UserSelect,
	EntryComponent: UserEntry,
	defaultValue: {
		action: 'include',
		keyword: [],
		association: 'or',
	},
	validate: _isNotArrayOrArrayNotEmpty,
};

const booleanSelect = {
	name: 'booleanSelect',
	Component: BooleanSelect,
	EntryComponent: BooleanEntry,
	defaultValue: { action: 'include', keyword: 'true' },
	validate: value => !!value,
};

const statusList = {
	name: 'statusList',
	Component: StatusListSelect,
	EntryComponent: StatusEntry,
	defaultValue: { action: 'include', association: 'or', keyword: [] },
	validate: _isNotArrayOrArrayNotEmpty,
};

const keywordOr = {
	name: 'keywordOr',
	Component: GenericKeywordInput({ allowedAssociations: ['or'] }),
	EntryComponent: KeywordEntry,
	defaultValue: { action: 'include', association: 'or', keyword: '' },
	validate: _validateKeyword,
};
const keywordOrExists = {
	name: 'keywordOrExists',
	Component: GenericKeywordInput({ allowedAssociations: ['or', 'exists'] }),
	EntryComponent: KeywordEntry,
	defaultValue: { action: 'include', association: 'or', keyword: '' },
	validate: _validateKeyword,
};

const keywordOrAndExists = {
	name: 'keywordOrAndExists',
	Component: GenericKeywordInput({ allowedAssociations: ['or', 'and', 'exists'] }),
	EntryComponent: KeywordEntry,
	defaultValue: { action: 'include', association: 'and', keyword: '' },
	validate: _validateKeyword,
};

const keywordOrAndStrictExists = {
	name: 'keywordOrAndStrictExists',
	Component: GenericKeywordInput({ allowedAssociations: ['or', 'and', 'strict', 'exists'] }),
	EntryComponent: KeywordEntry,
	defaultValue: { action: 'include', association: 'and', keyword: '' },
	validate: _validateKeyword,
};

const keywordNoBlocks = {
	name: 'keywordNoBlocks',
	Component: GenericKeywordInput({ allowedAssociations: ['or'], isBlocksInput: false }),
	EntryComponent: KeywordEntry,
	defaultValue: { action: 'include', association: 'or', keyword: '' },
	validate: _validateKeyword,
};

const eventState = {
	name: 'eventState',
	Component: EventStateQuickFilter,
	EntryComponent: null,
	defaultValue: {
		NEW: true,
		IN_PROGRESS: false,
		EXPORTED: false,
		DISCARDED: false,
		SCRAPPED: false,
	},
	validate: () => true,
};

const collaboration = {
	name: 'collaboration',
	Component: CollaborationInput,
	EntryComponent: CollaborationEntry,
	defaultValue: {},
	validate: ({ locking, users } = {}) => {
		if (!locking) {
			return false;
		}

		if (locking === 'lockedByFollowingUsers') {
			return !!users?.length;
		}

		return locking !== 'any';
	},
};

const eventStateInfo = {
	name: 'eventStateInfo',
	Component: EventStateInfoInput,
	EntryComponent: EventStateInfoEntry,
	defaultValue: {
		leaflets: [],
		timestamp: {
			from: null,
			to: null,
		},
		users: [],
	},
	validate: ({ leaflets, users, timestamp } = {}) => {
		const atLeastOneFieldIsSet =
			leaflets?.length > 0 || users?.length > 0 || timestamp?.from || timestamp?.to;
		const dateIsNotSet = !timestamp;
		const hasNoInvalidDates = [timestamp?.from, timestamp?.to].every(date => date !== INVALID_DATE);

		return atLeastOneFieldIsSet && (dateIsNotSet || hasNoInvalidDates);
	},
};

// noop for translate - since translation isnt needed to get formtype
const noop = () => '';
const fields = {
	event: eventFields(noop).concat([
		{ name: 'state', label: '', formType: eventState, defaultValue: eventState.defaultValue },
	]),
	location: locationFields(noop),
	organizer: organizerFields(noop),
};

const getFormTypeForFieldName = (name, type = 'event') => {
	const field = fields[type].find(x => x.name === name);
	return field?.formType ?? '';
};

export {
	getFormTypeForFieldName,
	numberRange,
	meslisEvents,
	customerEvents,
	dateRange,
	datesRange,
	ratingRange,
	tagList,
	entityStatusSelect,
	userSelect,
	booleanSelect,
	statusList,
	keywordOr,
	keywordOrExists,
	keywordOrAndExists,
	keywordOrAndStrictExists,
	keywordNoBlocks,
	eventState,
	collaboration,
	eventStateInfo,
};
