import React, { useState, useEffect, useContext } from 'react';
import { Switch, Route } from 'react-router-dom';

import Search from './Search';
import Select from './Select';
import Process from './Process';
import Success from './Success';
import { useQueryParams } from 'customHooks';
import { AppContext, FilterWidgetContext } from 'contexts';
import CreateEvent from 'components/CreateEvent';

const EventsWorkflow = () => {
	const {
		event: {
			filters,
			setFilters,
			quickFilter,
			setQuickFilter,
			sorting,
			shownSorting,
			sortByField,
			onCtrlUp,
		},
	} = useContext(FilterWidgetContext);

	const { step } = useQueryParams({ step: 'search' });
	const { tableMemo } = useContext(AppContext);
	const [lastSelections, setLastSelections] = useState([]);
	const [forwardSelections, setForwardSelections] = useState([]);

	const handleSortByField = (...obj) => {
		setLastSelections(lastSelections);
		setForwardSelections(forwardSelections);
		sortByField(...obj);
	};

	useEffect(() => {
		setLastSelections([]);
		setForwardSelections([]);
	}, [step]);

	const handleFiltersUpdate = filters => {
		setFilters(filters);
		tableMemo.setScrollUpFlag();
	};
	const handleQuickFilterUpdate = filter => {
		setQuickFilter(filter);
		tableMemo.setScrollUpFlag();
	};

	return (
		<div data-test="EventsWorkflow">
			<Switch>
				<Route exact path="/events/success" component={Success} />
				<Route exact path="/events/create" component={CreateEvent} />

				<Route>
					{step === 'process' ? (
						<Process
							{...{ sorting, shownSorting, onCtrlUp, lastSelections, forwardSelections }}
							onSort={handleSortByField}
						/>
					) : step === 'select' ? (
						<Select
							{...{
								sorting,
								shownSorting,
								onCtrlUp,
								quickFilter,
								lastSelections,
								forwardSelections,
							}}
							filters={filters.filter(item => item.enabled)}
							onSort={handleSortByField}
						/>
					) : (
						<Search
							{...{ sorting, shownSorting, onCtrlUp, filters, quickFilter }}
							onSort={sortByField}
							onFiltersUpdate={handleFiltersUpdate}
							onQuickFilterUpdate={handleQuickFilterUpdate}
						/>
					)}
				</Route>
			</Switch>
		</div>
	);
};

export default EventsWorkflow;
