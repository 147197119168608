import React from 'react';
import { useQuery } from '@apollo/client';
import { buildEventsQueryFromFilters, buildGenericQueryFromFilters } from 'utils';
import { EVENTS_COUNT, LOCATIONS_COUNT, ORGANIZERS_COUNT } from 'graphql/queries';
import { useTranslate } from 'customHooks';

export default ({ filters, quickFilter, type }) => {
	filters = filters || [];
	const variables = {
		max: 0,
		searchQuery:
			type === 'EVENT'
				? buildEventsQueryFromFilters(!!quickFilter ? [...filters, quickFilter] : filters)
				: buildGenericQueryFromFilters(filters),
	};

	const query = {
		EVENT: EVENTS_COUNT,
		LOCATION: LOCATIONS_COUNT,
		ORGANIZER: ORGANIZERS_COUNT,
	}[type];

	const queryName = {
		EVENT: 'searchEventsBySearchParams',
		LOCATION: 'searchLocations',
		ORGANIZER: 'searchOrganizers',
	}[type];

	const { loading, data } = useQuery(query, {
		variables,
		fetchPolicy: 'cache-first',
	});

	const translate = useTranslate();

	let number = loading
		? translate('placeholders.loadingDots')
		: data && data[queryName] && data[queryName].totalCount.toLocaleString();

	return <>{number}</>;
};
