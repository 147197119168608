import gql from 'graphql-tag';

export const EXPORT = gql`
	mutation toExported($ids: [EventIdInput]!, $description: String, $targetLeafletId: Int) {
		toExported(ids: $ids, description: $description, targetLeafletId: $targetLeafletId)
	}
`;

// Mutation for leaflet management
export const TO_CUSTOM_LEAFLET = gql`
	mutation moveToLeaflet($leafletId: Int!, $ids: [EventIdInput]!) {
		toLeafletById(leafletId: $leafletId, ids: $ids) {
			id
			success
		}
	}
`;

export const EMPTY_CUSTOM_LEAFLET = gql`
	mutation clearLeaflet($leafletId: Int!) {
		emptyLeafletById(leafletId: $leafletId)
	}
`;

export const CREATE_CUSTOM_LEAFLET = gql`
	mutation createLeaflet($leaflet: LeafletInput!) {
		createLeaflet(leaflet: $leaflet) {
			id
		}
	}
`;

export const UPDATE_CUSTOM_LEAFLET = gql`
	mutation updateLeaflet($leaflet: LeafletUpdateType!) {
		updateLeaflet(leaflet: $leaflet) {
			id
		}
	}
`;

export const DELETE_CUSTOM_LEAFLET = gql`
	mutation deleteLeaflet($id: Int!, $leaflet: LeafletUpdateType!) {
		updateLeaflet(leaflet: $leaflet) {
			id
		}
		deleteLeaflet(id: $id)
	}
`;

// Mutations for query (filters) management
export const UPDATE_SAVED_QUERY = gql`
	mutation updateSavedQuery($savedQuery: SavedQueryUpdateType!) {
		updateSavedQuery(savedQuery: $savedQuery) {
			id
		}
	}
`;

export const HIDE_SAVED_QUERY = gql`
	mutation hideSavedQuery($id: Int!, $hide: Boolean) {
		hideSavedQuery(id: $id, hide: $hide)
	}
`;

export const SUBSCRIBE = gql`
	mutation saveSearchQuery($name: String, $type: QueryType!, $searchQuery: String!) {
		saveSearchQuery(name: $name, type: $type, searchQuery: $searchQuery)
	}
`;

export const DELETE_SUBSCRIPTION = gql`
	mutation deleteSavedSearchQuery($id: Int!) {
		deleteSavedQuery(id: $id)
	}
`;

// Mutations for user management

export const logClientExport = gql`
	mutation logClientCSVExport($ids: [String]!, $type: ExportType!) {
		saveClientExport(ids: $ids, type: $type)
	}
`;

export const UPDATE_USER_SETTINGS = gql`
	mutation updateUserSettings($userSettings: UserSettingsUpdateType!) {
		updateUserSettings(userSettings: $userSettings) {
			dateFormat
			defaultFetchSize
			languageCode
		}
	}
`;

export const CHANGE_PASSWORD = gql`
	mutation changePassword($newPassword: String!, $oldPassword: String!) {
		updatePasswordForUser(newPassword: $newPassword, oldPassword: $oldPassword) {
			id
		}
	}
`;

// Mutations for event management

export const MOVE_EVENT_ADS = gql`
	mutation moveEventAds($moveEventAdsInput: MoveEventAdsInputType!) {
		moveEventAds(moveEventAdsInput: $moveEventAdsInput)
	}
`;

export const COPY_EVENT_ADS = gql`
	mutation copyEventAds($copyEventAdsInput: CopyEventAdsInputType!) {
		copyEventAds(copyEventAdsInput: $copyEventAdsInput) {
			success
			adIds
		}
	}
`;

export const CREATE_EVENT = gql`
	mutation createEvent($adIdsListInput: AdIdsListInputType) {
		createEvent(adIdsListInput: $adIdsListInput) {
			eventId
			adIds
			success
		}
	}
`;

export const DELETE_EVENT = gql`
	mutation deleteEvent($id: ID!) {
		deleteEvent(id: $id)
	}
`;

export const ASSIGN_ORGANIZER_FOR_EVENTS = gql`
	mutation assignOrganizerForEvents($organizerWithEventIds: OrganizerWithEventIdsInputType!) {
		assignOrganizerForEvents(organizerWithEventIds: $organizerWithEventIds)
	}
`;

export const SET_EVENT_STATUS = gql`
	mutation setEventStatus($eventStatusUpdate: EventStatusUpdateType!) {
		setEventStatus(eventStatusUpdate: $eventStatusUpdate) {
			id
			success
		}
	}
`;

export const CREATE_NEW_EVENT = gql`
	mutation createNewEvent($createNewEvent: EventInputType!) {
		createNewEvent(createNewEvent: $createNewEvent) {
			id
		}
	}
`;

export const UPDATE_EVENT = gql`
	mutation updateEvent($updateEvent: EventUpdateType!) {
		updateEvent(updateEvent: $updateEvent) {
			id
		}
	}
`;

export const LOCK_EVENTS = gql`
	mutation lockEvents($idsWithVersion: [IdWithVersionInputType]!) {
		lockEvents(idsWithVersion: $idsWithVersion)
	}
`;

export const UNLOCK_EVENTS = gql`
	mutation unlockEvents($idsWithVersion: [IdWithVersionInputType]!) {
		unlockEvents(idsWithVersion: $idsWithVersion)
	}
`;

// Mutations for location management

export const CREATE_LOCATION = gql`
	mutation createLocation($createLocation: LocationInputType!) {
		createLocation(createLocation: $createLocation) {
			id
		}
	}
`;

export const UPDATE_LOCATION = gql`
	mutation updateLocation($updateLocation: LocationUpdateType!) {
		updateLocationWithEventUpdateResponse(updateLocation: $updateLocation) {
			location {
				id
			}
			eventUpdateResponses {
				failureType
				id
				updateStatus
			}
		}
	}
`;

// Mutations for organizer management

export const CREATE_ORGANIZER = gql`
	mutation createOrganizer($createOrganizer: OrganizerInputType!) {
		createOrganizer(createOrganizer: $createOrganizer) {
			id
		}
	}
`;

export const UPDATE_ORGANIZER = gql`
	mutation updateOrganizer($updateOrganizer: OrganizerUpdateType!) {
		updateOrganizerWithEventUpdateResponse(updateOrganizer: $updateOrganizer) {
			organizer {
				id
			}
			eventUpdateResponses {
				failureType
				id
				updateStatus
			}
		}
	}
`;

export const LOCK_ORGANIZERS = gql`
	mutation lockOrganizers($idsWithVersion: [IdWithVersionInputType]!) {
		lockOrganizers(idsWithVersion: $idsWithVersion)
	}
`;

export const UNLOCK_ORGANIZERS = gql`
	mutation unlockOrganizers($idsWithVersion: [IdWithVersionInputType]!) {
		unlockOrganizers(idsWithVersion: $idsWithVersion)
	}
`;

// Mutations for ad management
export const UPDATE_AD = gql`
	mutation updateAd($updateAd: AdUpdateType!) {
		updateAd(updateAd: $updateAd) {
			id
		}
	}
`;

export const CREATE_NEW_AD = gql`
	mutation createAd($createAd: CreateAdInputType!) {
		createAd(createAd: $createAd) {
			id
		}
	}
`;
