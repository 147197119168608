import React, { useState } from 'react';
import { Button, Form, Loader, Modal, Icon } from 'semantic-ui-react';
import { ActionsDropdown } from 'components/Base';

import Meslis from 'components/Meslis';
import './ExportModal.scss';
import { useTranslate } from 'customHooks';
import stateIcons from 'configurations/stateIcons';

const ExportModal = ({ selection, leafletData, disabled, onDone, exportSelected }) => {
	const [open, setOpen] = useState(false);
	const [description, setDescription] = useState('');
	const [exporting, setExporting] = useState(false);
	const [stagedAction, setStagedAction] = useState();
	const translate = useTranslate();
	const pfx = 'messages.export.';

	const selectionCountName = exportSelected
		? translate(pfx + 'button.selected')
		: translate(pfx + 'button.all');

	const ExportButton = (
		<Button positive onClick={() => setOpen(true)} {...{ disabled }}>
			{translate(pfx + 'button.export', [selectionCountName])}
		</Button>
	);

	const handleClose = () => {
		if (!exporting) {
			setOpen(false);
			setDescription('');
			setExporting(false);
		}
	};

	return (
		<div className="ExportModal">
			<Modal
				size="small"
				onClose={handleClose}
				trigger={ExportButton}
				{...{ open }}
				className="fadeUp ExportModal">
				<Meslis.Leaflet {...{ leafletId: !!leafletData && leafletData.id, max: 9999 }}>
					{({ data = {}, loading, error }) => {
						if (error) {
							// console.error(error);
							return <div>Error</div>;
						}
						if (loading || !data.searchEventsBySearchParams)
							return <Loader size="large" active inline="centered" />;
						const ids =
							selection.length > 0
								? selection.map(item => {
										return {
											id: item.id,
											version: item.version,
											leafletId: item && item.customLeaflet.id,
										};
								  })
								: data.searchEventsBySearchParams.results.map(item => {
										return {
											id: item.id,
											version: item.version,
											leafletId: item.customLeaflet.id,
										};
								  });

						return (
							!!data.searchEventsBySearchParams &&
							!!data.searchEventsBySearchParams.results && (
								<>
									<Modal.Header>{translate(pfx + 'header', [selectionCountName.toLowerCase()])}</Modal.Header>

									<Modal.Content>
										<Modal.Description>
											<p>{translate(pfx + 'description', [ids.length, leafletData.name])}</p>
											<Form>
												<Form.TextArea
													placeholder={translate(pfx + 'descriptionPlaceholder')}
													value={description}
													onChange={e => setDescription(e.target.value)}
												/>
											</Form>
											<br />
											<>
												{translate(pfx + 'action', [
													<Icon name={stateIcons.IN_PROGRESS} />,
													<Icon name={stateIcons.EXPORTED} />,
												])}
											</>
											<br />
											<br />
											<ActionsDropdown
												events={data.searchEventsBySearchParams.results}
												onSelected={setStagedAction}
												shownElements={{
													keepInLeaflet: leafletData.id,
													leaflets: true,
													cancelSelection: true,
												}}
												noOptionId={leafletData.id}
												isModalLayout={true}
												placeholder={translate(pfx + 'actionPlaceholder')}
											/>
										</Modal.Description>
									</Modal.Content>
									<Modal.Actions>
										<Button loading={exporting} disabled={exporting} onClick={() => setOpen(false)}>
											{translate(pfx + 'button.cancel')}
										</Button>

										<Meslis.Export
											ids={ids}
											description={description}
											targetLeafletId={stagedAction && stagedAction.targetLeafletId}>
											{execute => (
												<Button
													loading={exporting}
													disabled={exporting}
													positive
													onClick={() => {
														setExporting(true);
														execute()
															.then(handleClose)
															.then(() => onDone());
													}}>
													{translate(pfx + 'button.export', [selectionCountName])}
													{!!stagedAction
														? translate(pfx + 'button.exportAnd', [stagedAction.actionName.toLowerCase()])
														: ''}
												</Button>
											)}
										</Meslis.Export>
									</Modal.Actions>
								</>
							)
						);
					}}
				</Meslis.Leaflet>
			</Modal>
		</div>
	);
};

export default ExportModal;
