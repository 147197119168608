import { CONFLICT } from 'config';
import { useTranslate } from 'customHooks';
import React from 'react';
import { Table as SemanticTable } from 'semantic-ui-react';

import Row from './Row';

const Table = ({ data, onDelete, leafletData, filters, sorting }) => {
	const translate = useTranslate();
	return data.totalCount ? (
		<SemanticTable size="small">
			<SemanticTable.Body>
				{data.results.slice(0, 5).map(item => (
					<Row
						key={item.id}
						data={item}
						isDelete={!leafletData || leafletData.id !== CONFLICT}
						{...{ leafletData, filters, sorting, onDelete }}
					/>
				))}
			</SemanticTable.Body>
		</SemanticTable>
	) : (
		<p>{translate('placeholders.noItemsFound')}</p>
	);
};
export default Table;
