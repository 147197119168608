import React from 'react';
import { Divider } from 'semantic-ui-react';
import './InfoBox.scss';
import { useTranslate } from 'customHooks';

const InfoBox = ({ leafletData }) => {
	const translate = useTranslate();
	return (
		leafletData && (
			<div className="InfoBox">
				{leafletData.description && (
					<>
						<strong>{translate('leaflets.leafDescription')}</strong>
						<Divider />

						<div className="description">{leafletData.description}</div>
					</>
				)}
			</div>
		)
	);
};

export default InfoBox;
