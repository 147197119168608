import React from 'react';

import FilterEntry from './FilterEntry';
import FilterDetailForm from './FilterDetailForm';
import classNames from 'classnames';

import './FilterDetail.scss';

const FilterDetail = ({
	fields,
	data,
	editableDeletable,
	editMode,
	type,
	onEdit,
	onCancel,
	onChange,
	onDelete,
}) => {
	if ((!!fields && !fields.find(item => item.name === data.fieldName)) || !data.fieldName)
		return <></>;

	const handleSubmit = data => onChange(data);
	const enabledClass = data.enabled ? 'enabled' : 'disabled';

	const editClass = editMode ? 'FilterDetailEdit' : '';

	return (
		<div className={classNames('FilterDetail', enabledClass, editClass)}>
			{editMode ? (
				<FilterDetailForm {...{ fields, data, onCancel, type }} onSubmit={handleSubmit} />
			) : (
				<FilterEntry {...{ fields, data, editableDeletable, onChange, onDelete, onEdit, type }} />
			)}
		</div>
	);
};

export default FilterDetail;
