import { RatingInput } from 'components/Base';
import React from 'react';
import { Form, Select } from 'semantic-ui-react';

import useFilterConfig from '../useFilterConfig';

const defaultValue = { operator: 'equal', rating: 3 };

const RatingRange = ({ value, onChange }) => {
	const handleChange = (attr, _value) => onChange({ ...value, ...{ [attr]: _value } });
	const { operators } = useFilterConfig();

	return (
		<>
			<Form.Field>
				<Select
					data-test="FilterInputRelevanceOperator"
					value={value.operator || defaultValue.operator}
					options={operators}
					onChange={(_, { value }) => handleChange('operator', value)}
					fluid
				/>
			</Form.Field>

			<Form.Field>
				<RatingInput
					className="filterInputRelevance"
					data-test="FilterInputRelevance"
					value={value.rating}
					onChange={value => handleChange('rating', value)}
					fluid
				/>
			</Form.Field>
		</>
	);
};

export default RatingRange;