import React, { useState } from 'react';
import { Checkbox, Button } from 'semantic-ui-react';
import { useTranslate } from 'customHooks';

import FilterForm from '../FilterForm';

import './FilterDetailForm.scss';

const FilterDetailForm = ({ fields, data: propsData, onSubmit, onCancel, type }) => {
	const [data, setData] = useState(propsData);
	const translate = useTranslate();

	const handleToggleEnabled = (e, { checked }) => setData({ ...data, ...{ enabled: checked } });

	const handleSubmit = value => onSubmit({ ...value, ...{ enabled: data.enabled } });

	return (
		<div className="FilterDetailForm">
			<Checkbox checked={data.enabled} onChange={handleToggleEnabled} label="&nbsp;" />

			<FilterForm
				{...{ fields, type }}
				initialData={data}
				onAddFilter={handleSubmit}
				confirmButtonText={translate('filters.confirm')}
			/>
			<Button fluid onClick={onCancel}>
				{translate('actions.cancel')}
			</Button>
		</div>
	);
};
export default FilterDetailForm;
