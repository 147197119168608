import React, { useState, useContext } from 'react';
import { Form, Icon, Select, Button, Segment, Input } from 'semantic-ui-react';

import Meslis from 'components/Meslis';
import ShareLeafletFormPart from './ShareLeafletFormPart';
import { useTranslate } from 'customHooks';
import { UserContext } from 'contexts/UserContext';

import './CreateLeafletForm.scss';

const CreateLeafletForm = ({
	name,
	description,
	tag,
	users,
	onChangeTag,
	onSubmit,
	setName,
	setDescription,
	leafletLimitReached = false,
	errorMessage,
}) => {
	const translate = useTranslate();
	const { eventTags: fields = [] } = useContext(UserContext);
	const [shared, setShared] = useState(false);
	const [editors, setEditors] = useState([]);

	const handleSharing = () => {
		setShared(!shared);
	};
	const handleChangeEditors = (e, target) => {
		setEditors(target.value);
	};

	const handleSubmit = () => {
		setShared(false);
		setEditors([]);
		onSubmit();
	};

	return (
		<div className="CreateLeafletForm">
			<header>
				<h3>{translate('leaflets.header.create')}</h3>
			</header>

			{!!errorMessage && <div className="warning">{errorMessage}</div>}

			<Form onSubmit={handleSubmit} disabled={leafletLimitReached}>
				<Form.Field>
					<label>{translate('leaflets.leafName')}</label>
					<Input
						data-test="createLeafletName"
						placeholder="Name"
						value={name}
						onChange={e => setName(e.target.value)}
						maxLength="34"
						error={!!errorMessage}
					/>
				</Form.Field>
				<Form.Field>
					<label>{translate('leaflets.leafTag')}</label>
					<Select
						data-test="createLeafletTag"
						value={tag}
						clearable
						onChange={onChangeTag}
						placeholder={translate('leaflets.leafTag')}
						options={fields.map(item => ({
							value: item,
							text: item,
							key: item,
						}))}
					/>
				</Form.Field>
				<Form.Field>
					<Form.TextArea
						data-test="createLeafletDescription"
						label={translate('leaflets.leafDescription')}
						placeholder={translate('placeholders.leafletDescription')}
						value={description}
						onChange={e => setDescription(e.target.value)}
					/>
				</Form.Field>
				<ShareLeafletFormPart
					{...{ editors, shared, users }}
					onSharing={handleSharing}
					onChangeEditors={handleChangeEditors}
				/>

				<Meslis.CreateCustomLeaflet
					leaflet={{
						name: name,
						...(!!description && { description: description }),
						...(!!tag && { eventTag: tag }),
						...(editors.length > 0 && { editorIds: editors }),
					}}>
					{f => (
						<Button
							data-test="createLeafletButton"
							fluid
							disabled={!name || !!errorMessage}
							primary
							onClick={f}>
							<Icon name="plus" />
							{translate('leaflets.create')}
						</Button>
					)}
				</Meslis.CreateCustomLeaflet>
			</Form>
			{leafletLimitReached && (
				<div className="overlay">
					<Segment className="MaximumReached">
						<h3>{translate('leaflets.maxHeader')}</h3>
						<p>{translate('leaflets.maxLeaflets')}</p>
					</Segment>
				</div>
			)}
		</div>
	);
};

export default CreateLeafletForm;
