import React from 'react';

const stringTemplates = (fragments, ...keys) => (...values) => {
	const result = [fragments[0]];
	keys.forEach((key, i) => result.push(values[key], fragments[i + 1]));
	if (values != null && values.every(v => typeof v === 'string' || typeof v === 'number'))
		return result.join('');
	else
		return (
			<>
				{result.map((r, i) => (
					<span key={i}>{r}</span>
				))}
			</>
		);
};

export default stringTemplates;
