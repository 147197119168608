import React from 'react';
import { Button, Icon, Popup } from 'semantic-ui-react';
import { useTranslate } from 'customHooks';
import classNames from 'classnames';

import './ListNavigator.css';

const ListNavigator = ({className, offset, total = 1, overflow = 100, onChangeIndex }) => {
	const translate = useTranslate();
	const isTotalZero = total === 0; // can happen when clearing leaflet when showing detailview

	return (
		<div className={classNames('ListNavigator', className)}>
			{isTotalZero ? (
				<></>
			) : (
				<>
					<Button
						primary
						icon
						className="first"
						data-test="listButtonFirst"
						disabled={offset <= 0}
						onClick={() => onChangeIndex(0)}>
						<Icon name="double angle left" />
					</Button>
					<Button
						primary
						icon
						className="previous"
						data-test="listButtonPrevious"
						disabled={offset <= 0}
						onClick={() => onChangeIndex(offset - 1)}>
						<Icon name="angle left" />
					</Button>
				</>
			)}
			{!isTotalZero && (
				<span className="counter">
					{translate('detailView.navigationCounter', [
						(offset + 1).toLocaleString(),
						total.toLocaleString(),
					])}
				</span>
			)}

			{isTotalZero ? (
				<></>
			) : offset >= total - 1 || offset >= overflow - 1 ? (
				<Popup
					className="forbiddenForward"
					position="bottom center"
					inverted
					trigger={
						<span>
							<Button icon="angle right" color="grey" />
							<Button icon="angle double right" color="grey" />
						</span>
					}
					content={translate('tooltips.listNavigatorForbiddenForward')}
				/>
			) : (
				<>
					<Button
						primary
						icon
						className="next"
						data-test="listButtonNext"
						onClick={() => onChangeIndex(offset + 1)}>
						<Icon name="angle right" />
					</Button>
					<Button
						primary
						icon
						className="last"
						data-test="listButtonLast"
						onClick={() => onChangeIndex(Math.min(total, overflow) - 1)}>
						<Icon name="double angle right" />
					</Button>
				</>
			)}
		</div>
	);
};

export default ListNavigator;
