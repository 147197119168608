import React from 'react';

import useFilterConfig from 'components/FilterWidget/useFilterConfig';
import GenericEntry from './GenericEntry';
import { useTranslate } from 'customHooks';

const EntityStatusEntry = ({ data, onChangeValue, type }) => {
	const translate = useTranslate();
	const { eventStatuses, locationStatuses, organizerStatuses } = useFilterConfig();

	// added exists here, since it shouldn't appear in the Select (this would happen if added it in useFilterConfig)
	const status = { event: eventStatuses, location: locationStatuses, organizer: organizerStatuses }[
		type
	].concat([{ value: '*', text: translate('filters.keyword.exists') }]);

	return <GenericEntry {...{ data, onChangeValue }} type="EntityStatus" options={status} />;
};

export default EntityStatusEntry;
