import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';

import { getClient } from './apollo/client';
import App from 'components/App';
import {
	UserProvider,
	TranslateProvider,
	AppProvider,
	FilterWidgetProvider,
	MessagesProvider,
} from 'contexts';

// apm needs to be at least imported in order to work
import apm from './elasticRum';
if (process.env.REACT_APP_APM_SERVER) {
	apm.setInitialPageLoadName(window.location.pathname);
}

let client = getClient();
render(
	<Router>
		<ApolloProvider client={client}>
			<AppProvider>
				<TranslateProvider>
					<FilterWidgetProvider>
						<UserProvider>
							<MessagesProvider>
								<App />
							</MessagesProvider>
						</UserProvider>
					</FilterWidgetProvider>
				</TranslateProvider>
			</AppProvider>
		</ApolloProvider>
	</Router>,
	document.getElementById('root')
);
