import React from 'react';

import Meslis from 'components/Meslis';
import LeafletBox from '../LeafletBox';
import { useAppContext, useQueryParams } from 'customHooks';
import { alerts } from 'utils';

import './LeafletList.scss';

const LeafletList = ({
	viewLabel,
	onTableActionCompleted,
	showList,
	showExport,
	showClear,
	offset,
	sorting,
	selection,
	leafletId,
	filters,
}) => {
	const {
		displayLeafletsConfig: { sortConfig, collapsedLeaflets, handleOpenCollapse, allLeafletIdsRef },
	} = useAppContext();
	const { sortField, isDescending } = sortConfig || {};
	const { step, pushURL } = useQueryParams();

	const compareItems = (a, b) => {
		const [_a, _b] =
			sortField === 'creator'
				? [a, b].map(x => x.creator && `${x.creator.firstName} ${x.creator.lastName}`)
				: sortField === 'updatedAt' || sortField === 'createdAt'
				? [a, b].map(x => x[sortField] && new Date(x[sortField]).valueOf())
				: [a[sortField], b[sortField]];

		if (_a == null) return 1;
		else if (_b == null) return -1;
		else return (_a < _b ? -1 : 1) * (isDescending ? 1 : -1);
	};

	return (
		<Meslis.CustomLeaflets>
			{({ loading, data = {}, error, refetch }) => {
				if (error) {
					// console.error(error);
					return <div>Error</div>;
				}

				if (!!data && !!data.getAllLeaflets)
					allLeafletIdsRef.current = data.getAllLeaflets.map(leaflet => leaflet.id);

				const LeafletChangedSubscription = () => (
					<Meslis.SubscribeToLeafletChange
						fetchPolicy={'network-only'}
						componentId={2}
						onSubscriptionData={() => {
							refetch().then(data => {
								// only relevant if in process step
								if (step !== 'process') return;

								// list of leaflets visible/shared to user
								const validIds = data.data.getAllLeaflets.map(leaflet => leaflet.id);
								const wasCurrentLeafletUnshared = !validIds.some(id => id === leafletId);
								if (wasCurrentLeafletUnshared) {
									alerts.leafletUnshared();
									pushURL({ leafletId: 1 });
								}
							});
						}}
					/>
				);

				return (
					!loading && (
						<div className="LeafletList">
							<SortedList
								itemWrappers={data.getAllLeaflets.map((leaflet, index) => ({
									data: leaflet,
									item: (
										<LeafletBox
											key={index}
											selection={leafletId === leaflet.id || !leafletId ? selection : []}
											leafletData={leaflet}
											editLeaflet
											isLeafletItem
											isCollapsed={collapsedLeaflets.find(id => id === leaflet.id)}
											onOpenCollapse={() => handleOpenCollapse(leaflet.id)}
											{...{
												showList,
												showExport,
												showClear,
												viewLabel,
												offset,
												sorting,
												leafletId,
												onTableActionCompleted,
												filters,
												index,
											}}
										/>
									),
								}))}
								{...{ sortConfig, compareItems }}
							/>
							<LeafletChangedSubscription />
						</div>
					)
				);
			}}
		</Meslis.CustomLeaflets>
	);
};

// Using this SortedList with prerendered components avoids
// a rerender when sorting which would lead to unnecessary data refetches.
const SortedList = ({ itemWrappers, sortConfig, compareItems }) => {
	const sortedItems = (!!sortConfig && !!itemWrappers
		? itemWrappers.sort((wrapperA, wrapperB) => compareItems(wrapperA.data, wrapperB.data))
		: itemWrappers
	).map(wrapper => wrapper.item);

	return <>{sortedItems}</>;
};

export default LeafletList;
