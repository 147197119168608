import React, { useState } from 'react';

import Table from 'components/Table';
import Meslis from 'components/Meslis';
import { FullLayout } from 'components/Layout';
import { LoadingContent, Msg } from 'components/Base';
import { INITIAL_SELECTIONS_SHOWN, MORE_SELECTIONS_TO_SHOW } from 'config';
import { useTranslate, useSorting } from 'customHooks';
import { defaultSortFunction } from 'utils';
import { order } from 'enums';

import './index.scss';

export default () => {
	const [currentlyShown, setCurrentlyShown] = useState(INITIAL_SELECTIONS_SHOWN);
	const translate = useTranslate();
	const { sorting, sortByField } = useSorting('createdAt', order.DESC);

	return (
		<div className="ExportInbox" data-test="My Selections">
			<FullLayout>
				<Meslis.Exports>
					{({ loading, error, data = {}, refetch }) => {
						if (loading) return <LoadingContent />;

						if (error) {
							// console.error(error);
							return <p>Error</p>;
						}

						if (data.getAllExports.length === 0) {
							return <Msg icon="frown outline">{translate('messages.noSelections')}</Msg>;
						}

						const sortedData = () => {
							const _data = [...data.getAllExports].sort(defaultSortFunction(sorting.field));
							if (sorting.orderBy === order.DESC) {
								_data.reverse();
							}
							return _data;
						};

						const _data = sortedData();

						const moreDataAvailable = currentlyShown < _data.length;
						const handleReachBottom = () =>
							moreDataAvailable && setCurrentlyShown(currentlyShown + MORE_SELECTIONS_TO_SHOW);

						const handleActionChanged = () => {
							refetch();
						};

						return (
							<>
								<Table.Selections
									data={_data.slice(0, currentlyShown)}
									onReachBottom={handleReachBottom}
									refetchExports={handleActionChanged}
									onSort={sortByField}
									{...{ moreDataAvailable, sorting }}
								/>
								{data.getAllExports &&
									data.getAllExports
										.filter(data => data.isAvailable !== true)
										.map((data, index) => (
											<Meslis.SubscribeForDownload
												key={index}
												id={data.id}
												onSubscriptionData={handleActionChanged}
											/>
										))}
							</>
						);
					}}
				</Meslis.Exports>
			</FullLayout>
		</div>
	);
};
