import React, { useState } from 'react';

import { Button, Input, Modal, Message } from 'semantic-ui-react';
import { useTranslate } from 'customHooks';
import { urlForRequestPasswordResetToken } from 'utils';

const ResetPasswordModal = ({ onSuccess, hideSuccess }) => {
	const [open, setOpen] = useState(false);
	const [querying, setQuerying] = useState(false);
	const [error, setError] = useState(false);
	const [username, setUsername] = useState('');

	const translate = useTranslate();

	const RequestResetHandler = (
		<div
			onClick={() => {
				setOpen(true);
				hideSuccess();
			}}
			className="RequestResetHandler">
			{translate('resetPassword.triggerButton')}
		</div>
	);

	const handleClose = () => {
		if (!querying) {
			setOpen(false);
			setUsername('');
			setError(false);
		}
	};

	const handleRequestReset = async () => {
		setError(false);
		setQuerying(true);

		await fetch(urlForRequestPasswordResetToken(username))
			.then(response => {
				if (response.ok && response.status === 200) {
					onSuccess();
					handleClose();
				} else if (!response.ok) {
					response.status === 400 && setError(translate('resetPassword.failMessage'));
					response.status === 429 &&
						setError(
							translate('messages.tooManyRequests', [
								translate('resetPassword.triggerButton').toLowerCase(),
							])
						);
				}
				setQuerying(false);
			})
			.catch(e => {
				setError(e.toString());
				setQuerying(false);
			});
	};

	return (
		<Modal
			size="small"
			trigger={RequestResetHandler}
			onClose={handleClose}
			{...{ open }}
			className="fadeUp">
			<>
				<Modal.Header> {translate('resetPassword.modalHeader')}</Modal.Header>

				<Modal.Content>
					<Modal.Description>
						<p> {translate('resetPassword.modalDesc')} </p>

						<Input
							fluid
							placeholder="Username"
							value={username}
							onChange={e => setUsername(e.target.value)}
						/>

						{error && <Message negative> {error} </Message>}
					</Modal.Description>
				</Modal.Content>
				<Modal.Actions>
					<Button loading={querying} disabled={querying} onClick={handleClose}>
						{translate('actions.cancel')}
					</Button>

					<Button
						loading={querying}
						disabled={querying}
						primary
						positive
						onClick={handleRequestReset}>
						{translate('resetPassword.confirmButton')}
					</Button>
				</Modal.Actions>
			</>
		</Modal>
	);
};

export default ResetPasswordModal;
