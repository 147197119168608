import { useRef, useMemo } from 'react';
import { cloneDeep } from 'lodash';

import {
	DEFAULT_WIDTH,
	DEFAULT_MIN_WIDTH,
	DEFAULT_WIDTHS,
	FULL_WIDTH_TABLES,
} from 'components/Table/tableWidthsConfig';
import browserStorage from 'browserStorage';
import { fieldNameEscapes } from 'utils';

const getDefaultWidths = (tableKey, filterPredicate = () => true) => {
	const buffer = {};
	DEFAULT_WIDTHS.filter(
		definition => definition.tableKeys.includes(tableKey) && filterPredicate(definition)
	).forEach(definition => {
		definition.fields.forEach(fieldName => (buffer[fieldName] = cloneDeep(definition)));
	});
	return buffer;
};

const getDefaultWidth = (tableKey, field) =>
	DEFAULT_WIDTHS.find(
		definition => definition.tableKeys.includes(tableKey) && definition.fields.includes(field)
	) || { width: null };

const useResizeColumns = ({ fields, tableKey }) => {
	//create global stylesheet for all fields

	const columnWidths = useMemo(() => {
		const storageWidth = browserStorage.getTableWidths(tableKey);
		return !!storageWidth
			? {
					...storageWidth,
					...getDefaultWidths(tableKey, ({ fixWidth }) => fixWidth),
			  }
			: getDefaultWidths(tableKey);
	}, [tableKey]);

	const fieldsRef = useRef();
	fieldsRef.current = fields || [];

	const isFullWidth = FULL_WIDTH_TABLES.includes(tableKey); //These tables have full width even if width definitions of columns sum up to less width than appropriate.

	const updateQueryTag = () => {
		let sumWidth = 0;
		let css = fieldsRef.current
			.map(field => field.name)
			.reduce((prevVal, fieldName) => {
				columnWidths[fieldName] = columnWidths[fieldName] || {};
				columnWidths[fieldName].width = columnWidths[fieldName].width || DEFAULT_WIDTH;
				const width = `${columnWidths[fieldName].width}px`;
				let max = columnWidths[fieldName].max;
				max = max && `${max}px`;

				const rowSelector = `.VTCell.${fieldNameEscapes(fieldName)}`;
				const headSelector = `.VTHeaderCell.${fieldNameEscapes(fieldName)}`;

				const cssSnippet = `${rowSelector}, ${headSelector} {
							width: ${width};
							min-width: ${width};
							max-width: ${isFullWidth ? (!!max ? max : 'none') : `${width}`};
						}\n`;
				// min-width and max-width in css must be width, semantic minWidth and maxWidth is assured in handleResizeColumn.
				// An exception is max-width for FULL_WIDTH_TABLES. In order to meet full page width real width will automatically be set to
				// something between width and max-width or to something greater than width if max-width = 'none'.

				sumWidth += columnWidths[fieldName].width;
				return prevVal + cssSnippet;
			}, '');

		if (!isFullWidth) {
			css += `.VTContainer, .VTRow {
			width: ${sumWidth}px !important;
		}`;
		}

		const styleEl = !!document.querySelector(`#styleColumnWidths`)
			? document.querySelector(`#styleColumnWidths`)
			: document.createElement('style');

		if (!document.querySelector(`#styleColumnWidths`)) {
			styleEl.setAttribute('type', 'text/css');
			styleEl.setAttribute('id', `styleColumnWidths`);
			const head = document.querySelector('head');
			head.appendChild(styleEl);
		}

		styleEl.innerHTML = css;
	};
	updateQueryTag();

	const handleResizeColumn = (value, fieldName, modus = 'absolut') => {
		columnWidths[fieldName] = columnWidths[fieldName] || {};

		if (columnWidths[fieldName].fixWidth) return;
		if (modus === 'default') {
			columnWidths[fieldName].width = getDefaultWidth(tableKey, fieldName).width;
		} else {
			const width =
				modus === 'relative'
					? /* For FULL_WIDTH_TABLES it can happen that real offsetWidth of column differs to specified width in css #styleColumnWidths */
					  document.querySelector(`.VTHeader .VTHeaderCell.${fieldNameEscapes(fieldName)}`)
							.offsetWidth + value
					: /* modus === 'absolut' */ value;

			columnWidths[fieldName].width = Math.max(
				width,
				columnWidths[fieldName].min || DEFAULT_MIN_WIDTH
			);

			columnWidths[fieldName].width = Math.min(
				columnWidths[fieldName].width,
				columnWidths[fieldName].max || columnWidths[fieldName].width
			);
		}

		browserStorage.setTableWidths(tableKey, columnWidths);
		updateQueryTag();
	};

	return handleResizeColumn;
};

export default useResizeColumns;
