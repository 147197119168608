import browserStorage from 'browserStorage';

const fetchWithToken = (url, options = {}) => {
	const token = browserStorage.getToken();
	options.headers
		? options.headers.append('authorization', `Bearer ${token}`)
		: (options.headers = new Headers({ authorization: `Bearer ${token}` }));

	return fetch(url, options);
};

export default fetchWithToken;
