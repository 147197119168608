import React from 'react';

import useFilterConfig from 'components/FilterWidget/useFilterConfig';
import GenericSelect from './GenericSelect';

const StatusListSelect = ({ value, error, onChange, type }) => {
	const { statusLocation, statusOrganizer } = useFilterConfig();
	const status = { location: statusLocation, organizer: statusOrganizer }[type];

	return (
		<GenericSelect
			options={status}
			allowedAssociations={['or', 'exists']}
			allowMultiple={true}
			{...{ value, error, onChange }}
		/>
	);
};

export default StatusListSelect;
