import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { Divider, Icon, Dropdown } from 'semantic-ui-react';
import classNames from 'classnames';

import { useTranslate } from 'customHooks';
import { UserContext } from 'contexts/UserContext';

import SignOutButton from 'components/SignOutButton';

import './UserDropdown.scss';
const ticketSystemUrl = process.env.REACT_APP_TICKET_SYSTEM_URL;
const helpCenterUrl = process.env.REACT_APP_HELP_CENTER_URL;

export const UserDropdown = ({ collapsed }) => {
	const translate = useTranslate();
	const { user } = useContext(UserContext);

	return (
		<div className={classNames('UserDropdown', { collapsed })} data-test="UserDropdown">
			<Dropdown
				// icon={null} is actually needed to NOT show an Icon for the Dropdown (caret)
				icon={null}
				trigger={
					<div className="trigger">
						<div className="white-text full">
							<Icon name="user" />
							<span className="label">{user.username}</span>
						</div>
						<div className="white-text small">
							<Icon className="Icon" name="user" />
						</div>
					</div>
				}
				floating
				direction="right">
				<Dropdown.Menu className="Dropdown-Menu">
					<Dropdown.Item as={Link} to="/my-selections">
						<Icon name="download" />
						{translate('navigation.exports')}
					</Dropdown.Item>
					<Dropdown.Item as={Link} to="/user-settings" data-test="UserSettings">
						<Icon className="avatar" name="user circle" />
						{translate('navigation.userSettings')}
					</Dropdown.Item>

					<Divider />

					<Dropdown.Item
						id="status-dropdown"
						icon="heartbeat"
						as="a"
						href={process.env.REACT_APP_STATUS_URL}
						target="_blank"
						rel="noopener noreferrer"
						text={translate('statusPage')}
					/>

					<Dropdown.Item
						id="help-dropdown"
						icon="help"
						href={helpCenterUrl}
						as="a"
						target="_blank"
						rel="noopener noreferrer"
						text={translate('navigation.help')}
					/>

					<Dropdown.Item
						id="ticket-system-dropdown"
						icon="ticket"
						href={ticketSystemUrl}
						as="a"
						target="_blank"
						rel="noopener noreferrer"
						text={translate('navigation.ticketSystem')}
					/>

					<Divider />

					<SignOutButton />
				</Dropdown.Menu>
			</Dropdown>
		</div>
	);
};

export default UserDropdown;
