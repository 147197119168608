// Queries
import CustomLeaflets from './CustomLeaflets';
import Events from './Events';
import EventsDetail from './EventsDetail';
import Event from './Event';
import Locations from './Locations';
import Organizers from './Organizers';
import Users from './Users';
import Leaflet from './Leaflet';
import Exports from './Exports';
import Subscriptions, { SubscriptionsFullData } from './Subscriptions';
import Me from './Me';
import EventsCount from './EventsCount';

// Mutations
import Export from './Export';
import ToLeafletById from './ToLeafletById';
import DeleteSubscription from './DeleteSubscription';
import EmptyCustomLeaflet from './EmptyCustomLeaflet';
import CreateCustomLeaflet from './CreateCustomLeaflet';
import DeleteCustomLeaflet from './DeleteCustomLeaflet';
import UpdateCustomLeaflet from './UpdateCustomLeaflet';

// Subscriptions
import SubscribeForDownload from './SubscribeForDownload';
import SubscribeToLeaflet from './SubscribeToLeaflet';
import SubscribeToLeafletChange from './SubscribeToLeafletChange';

const Meslis = {
	// Queries
	CustomLeaflets,
	Events,
	EventsDetail,
	Event,
	Locations,
	Organizers,
	Users,
	Leaflet,
	Exports,
	Subscriptions,
	SubscriptionsFullData,
	Me,
	EventsCount,

	// Mutations
	Export,
	ToLeafletById,
	DeleteSubscription,
	EmptyCustomLeaflet,
	CreateCustomLeaflet,
	DeleteCustomLeaflet,
	UpdateCustomLeaflet,

	// Subscriptions
	SubscribeForDownload,
	SubscribeToLeaflet,
	SubscribeToLeafletChange,
};

export default Meslis;
