import { cloneDeep } from 'lodash';
import { useUpdateEvents, useEventListener, useEventsHaveChangedBySameUser } from 'customHooks';
import { useRef, useState } from 'react';
import storage from 'storage';

const useDragDropEventDetail = ({
	data,
	setChangedData,
	isWaitingForUpdate,
	setIsWaitingForUpdate,
	isLockedByOtherUser,
	onRefresh,
}) => {
	const { moveAds, copyAds } = useUpdateEvents();

	const [showDimmer, setShowDimmer] = useState(false);
	const moveModeRef = useRef();

	const handleKeyDown = e => {
		if (e.key === 'F2' && !e.ctrlKey && !e.shiftKey && !e.altKey && !isLockedByOtherUser) {
			if (!moveModeRef.current && data.advertisements.totalCount > 0 && !isWaitingForUpdate) {
				moveModeRef.current = 'dragStart';
				setShowDimmer(true);
			} else {
				moveModeRef.current = null;
				setShowDimmer(false);
			}
		}
	};

	useEventListener('keyup', handleKeyDown);

	const handleAdMovedOut = ids => {
		const modifiedData = cloneDeep(data);
		modifiedData.advertisements.results = (modifiedData.advertisements.results || []).filter(
			ad => !ids.includes(ad.id)
		);
		const totalCount = modifiedData.advertisements.results.length;
		modifiedData.advertisements.totalCount = totalCount;

		setIsWaitingForUpdate(true);
		setChangedData(modifiedData);

		totalCount === 0 && storage.setEmptiedEventId(data.id);
	};

	const handleAdsMovedIn = (ads, oldEventID, isCopy) => {
		const modifiedData = cloneDeep(data);
		const newEventId = data.id;
		const targetWasEmpty = modifiedData.advertisements.totalCount === 0;
		modifiedData.advertisements.results.push(...ads);
		modifiedData.advertisements.totalCount += ads.length;

		(isCopy ? copyAds : moveAds)(
			ads.map(ad => ad.id),
			oldEventID,
			newEventId,
			targetWasEmpty
		);

		setIsWaitingForUpdate(true);
		setChangedData(modifiedData);

		modifiedData.advertisements.totalCount !== 0 &&
			storage.getEmptiedEventId() === data.id &&
			storage.setEmptiedEventId(null);
	};

	const handleDragStart = ({ ads, e, effectAllowed }) => {
		e.dataTransfer.effectAllowed = effectAllowed;
		e.dataTransfer.setData('oldEventID', `${data.id}`);
		e.dataTransfer.setData('ads', JSON.stringify(ads));
	};
	const handleDragStartAll = ({ e, effectAllowed }) => {
		e.dataTransfer.effectAllowed = effectAllowed;
		e.dataTransfer.setData('oldEventID', `${data.id}`);
		e.dataTransfer.setData('ads', JSON.stringify(data.advertisements.results));
	};

	const handleDragEnd = ({ ids, e }) => {
		e.dataTransfer.dropEffect === 'move' && handleAdMovedOut(ids);
		moveModeRef.current = null;
		!!showDimmer && setShowDimmer(false);
	};

	const handleDragEndAll = ({ e }) => {
		!!e &&
			e.dataTransfer &&
			e.dataTransfer.dropEffect === 'move' &&
			handleAdMovedOut(data.advertisements.results.map(ad => ad.id));
		moveModeRef.current = null;
		!!showDimmer && setShowDimmer(false);
	};

	const getAddedAds = ads => {
		const currIds = data.advertisements.results.map(_ad => _ad.id);
		return ads.filter(ad => !currIds.includes(ad.id));
	};

	const handleDrop = e => {
		const ads = JSON.parse(e.dataTransfer.getData('ads'));
		const oldEventID = e.dataTransfer.getData('oldEventID');
		if (!Array.isArray(ads) || !oldEventID) return;

		const addedAds = getAddedAds(ads);
		if (addedAds.length !== 0) {
			e.preventDefault();
			handleAdsMovedIn(addedAds, oldEventID, e.dataTransfer.effectAllowed === 'copy');
		}
		moveModeRef.current = null;
		!!showDimmer && setShowDimmer(false);
	};

	const handleDragEnter = e => {
		if (!['ads', 'oldeventid'].every(type => e?.dataTransfer?.types.includes(type))) return;
		e.preventDefault();
		if (!moveModeRef.current && !isLockedByOtherUser) {
			moveModeRef.current = 'dragOver';
			setShowDimmer(true);
		}
	};

	const handleDragExit = e => {
		e.preventDefault();
		if (moveModeRef.current === 'dragOver') {
			moveModeRef.current = null;
			setShowDimmer(false);
		}
	};

	useEventListener(
		'dragleave',
		e =>
			e.screenX === 0 && // e.screenX === 0 && e.screenY === 0 means is out of window
			e.screenY === 0 &&
			e.target.className.includes('DraggingDimmer') &&
			handleDragExit(e)
	);

	const handleRefreshAfterEventsChanged = () => {
		!!onRefresh &&
			onRefresh().then(() => {
				setIsWaitingForUpdate(false);
				setChangedData(null);
			});
	};

	// Use subscription to refresh in order to update both target instance and source instance.
	useEventsHaveChangedBySameUser({
		subscribeId: data.id,
		onRefresh: handleRefreshAfterEventsChanged,
	});

	useEventListener('updateEventsError', () => {
		setIsWaitingForUpdate(false);
		setChangedData(null);
	});

	return {
		showDimmer,
		moveModeRef,
		onDragStart: handleDragStart,
		onDragStartAll: handleDragStartAll,
		onDragEnd: handleDragEnd,
		onDragEndAll: handleDragEndAll,
		onDrop: handleDrop,
		onDragEnter: handleDragEnter,
		onEscape: () => {
			moveModeRef.current = null;
			setShowDimmer(false);
		},
	};
};

export default useDragDropEventDetail;
