import { useAppContext } from 'customHooks';

export default storageMemoKey => {
	const {
		tableMemo: { getHorizontalScrollPos, setHorizontalScrollPos },
	} = useAppContext();
	return () => {
		const vtheader = document.querySelector('.VTHeader');
		const vtbody = document.querySelector('.VTList');
		if (vtheader && vtbody) {
			vtheader.onscroll = () => setHorizontalScrollPos(storageMemoKey, vtheader.scrollLeft);
			const left = getHorizontalScrollPos(storageMemoKey);
			const top = vtbody.scrollTop || 0;
			vtheader.scrollTo(left, 0);
			vtbody.scrollTo(left, top);
		}
	};
};
