import React from 'react';
import { Query } from '@apollo/client/react/components';

import { ME } from 'graphql/queries';

export default ({ children, ...props }) => (
	<Query query={ME} {...props}>
		{children}
	</Query>
);
