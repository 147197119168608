import React from 'react';

import { useTranslate } from 'customHooks';
import useFilterConfig from 'components/FilterWidget/useFilterConfig';

const EventsPerYearEntry = ({ data: { operator, count, min, max, from_year, to_year } }) => {
	const translate = useTranslate();
	const { customerEventsOperators } = useFilterConfig();
	return (
		<span>
			<>
				{operator !== 'between' ? (
					<>
						<strong>{customerEventsOperators.find(op => op.value === operator).text}</strong>{' '}
						{count}
					</>
				) : (
					<>
						<strong>{translate('filters.operators.between')}</strong> {min}{' '}
						<strong>{translate('filters.operators.between_and')}</strong> {max}
					</>
				)}
			</>
			{', '}
			{from_year === to_year && !!from_year ? (
				<>
					<strong>{translate('filters.year.inYear')}</strong> {from_year}
				</>
			) : !!from_year && !!to_year ? (
				<>
					<strong>{translate('filters.year.betweenYears')}</strong> {from_year}{' '}
					<strong>{translate('filters.year.betweenYears_and')}</strong> {to_year}
				</>
			) : !!from_year ? (
				<>
					<strong>{translate('filters.year.sinceYear')}</strong> {from_year}
				</>
			) : !!to_year ? (
				<>
					<strong>{translate('filters.year.untilYear')}</strong> {to_year}
				</>
			) : (
				<>
					<strong>{translate('filters.year.forAllYears')}</strong>
				</>
			)}
		</span>
	);
};

export default EventsPerYearEntry;
