import { useState } from 'react';

import { fieldNameEscapes } from 'utils';
import {
	MAX_ADJUST_WIDTH as MAX_WIDTH,
	ADJUST_WIDTH_OFFSET as OFFSET,
	ADJUST_WIDTH_HEADER_OFFSET as HEADER_OFFSET,
	ADJUST_WIDTH_ITEM_ARRAY_OFFSET as ITEM_ARRAY_OFFSET,
} from 'components/Table/tableWidthsConfig';

export default handleResizeColumn => {
	const [smartAdjustColumns, setSmartAdjustColumns] = useState([]);

	const getElements = selector => Array.prototype.slice.call(document.querySelectorAll(selector));

	// measuredItems might be nested only take the deepest ones.
	const hasNoNestedMeasure = item => item.querySelectorAll('.measured-item').length === 0;

	const computeAdjustWidth = fieldName => {
		fieldName = fieldNameEscapes(fieldName);

		const measuredItems = [
			// measureItems header
			...getElements(`.VTHeaderCell.${fieldName} .measured-item`).map(item => ({
				item,
				offset: HEADER_OFFSET,
			})),

			// measureItems cell - no item array
			...getElements(`.VTCell.${fieldName} .measured-item`)
				.filter(hasNoNestedMeasure)
				.map(item => ({ item, offset: OFFSET })),

			// measureItems cell - of item array
			...getElements(`.VTCell.${fieldName} .ItemArrayList .measured-item`)
				.filter(hasNoNestedMeasure)
				.map(item => ({ item, offset: ITEM_ARRAY_OFFSET })),
		];

		const itemWidths = measuredItems.map(({ item, offset }) => item.offsetWidth + offset);

		return Math.min(Math.max(...itemWidths), MAX_WIDTH);
	};

	const onSmartAdjust = fieldName => {
		if (!smartAdjustColumns.includes(fieldName)) {
			const newWidth = computeAdjustWidth(fieldName);
			handleResizeColumn(newWidth, fieldName);
			setSmartAdjustColumns([fieldName, ...smartAdjustColumns]);
		} else {
			handleResizeColumn(null, fieldName, 'default');
			setSmartAdjustColumns(smartAdjustColumns.filter(_fieldName => _fieldName !== fieldName));
		}
	};

	const handleScroll = () => {
		smartAdjustColumns.length !== 0 && setSmartAdjustColumns([]);
	};

	const handleResize = fieldName =>
		setSmartAdjustColumns(smartAdjustColumns.filter(_fieldName => _fieldName !== fieldName));

	return { onSmartAdjust, onScroll: handleScroll, onResize: handleResize, smartAdjustColumns };
};
