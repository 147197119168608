import React from 'react';

import Navi from './Navi';
import LeafletList from 'components/CustomLeaflet/LeafletList';
import SortExpandMenu from 'components/CustomLeaflet/SortExpandMenu';
import { FullLayout, GraySidebarLayout } from 'components/Layout';
import { useSelectable, useTranslate } from 'customHooks';
import { MAX_EVENTS_SELECTABLE } from 'config';
import { alerts } from 'utils';
import { StickySidebarElement } from 'components/Base';
import { configEvents } from 'components/Table/Events';
import WorkflowLayer from 'components/WorkflowLayer';

const Select = ({ filters: _filters, quickFilter, sorting, ...props }) => {
	const [
		selection,
		setSelection,
		swapSelected,
		removeSelected,
		addBulkSelected,
		removeBulkSelected,
		isSelected,
	] = useSelectable({
		max: MAX_EVENTS_SELECTABLE,
		onMaxReached: () => alerts.selectedMaxAmountOfEvents(MAX_EVENTS_SELECTABLE),
	});

	const translate = useTranslate();

	const handleDeselectAll = () => setSelection([]);

	const handleActionCompleted = item => (!!item ? removeSelected(item) : setSelection([]));

	const filters = !!quickFilter ? [..._filters, quickFilter] : _filters;

	return (
		<GraySidebarLayout
			sidebar={
				<>
					<StickySidebarElement>
						<Navi />
						<SortExpandMenu />
					</StickySidebarElement>
					<LeafletList
						showList
						viewLabel={translate('navigation.process')}
						leafletId={-1}
						{...{ filters, sorting }}
					/>
				</>
			}>
			<FullLayout>
				<WorkflowLayer
					onSelectItem={swapSelected}
					onSelectAll={setSelection}
					onDeselectAll={handleDeselectAll}
					onActionCompleted={handleActionCompleted}
					onAddBulk={addBulkSelected}
					onRemoveBulk={removeBulkSelected}
					config={configEvents}
					storageMemoKey="events"
					{...{
						filters,
						quickFilter,
						sorting,
						selection,
						isSelected,
						setSelection,
						...props,
					}}
				/>
			</FullLayout>
		</GraySidebarLayout>
	);
};

export default Select;
