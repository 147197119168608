// Due to inconsistencies in the database street can contain street and houseNumber.
// This function arranges a valid string in any case.
const buildStreetHouseNumberFromAddress = address => {
	if (!address) {
		return '';
	}

	return `${address?.street || ''} ${address?.houseNumber || ''}`.trim();
};
export default buildStreetHouseNumberFromAddress;
