import React from 'react';
import { Mutation } from '@apollo/client/react/components';

import { DELETE_SUBSCRIPTION } from 'graphql/mutations';

const DeleteSubscription = ({ children, id, ...props }) => (
	<Mutation mutation={DELETE_SUBSCRIPTION} variables={{ id: id }} {...props}>
		{(f, data) => children(f, data)}
	</Mutation>
);

export default DeleteSubscription;
