import { useRef, useState } from 'react';
import { zip } from 'lodash';

import { useMutation } from '@apollo/client';
import {
	CREATE_NEW_EVENT,
	CREATE_NEW_AD,
	CREATE_LOCATION,
	CREATE_ORGANIZER,
	UPDATE_EVENT,
	UPDATE_AD,
} from 'graphql/mutations';
import useUploadImages from './useUploadImages';
import { alerts } from 'utils';

export default ({ isUpdate = false } = {}) => {
	const initialDataRef = useRef();
	const changedValueRef = useRef(); // changed value with old data and new or changed data
	const changedValueDiffRef = useRef(); // only new or changed data
	const onChangeSubmittedRef = useRef();
	const onResetRef = useRef();
	const [submitIssues, setSubmitIssues] = useState({});

	const [createEventMutation, { loading: loadingEvent }] = useMutation(CREATE_NEW_EVENT, {
		onError: () => alerts.failMessageGenerator('event', 'created'),
	});
	const [createAdMutation, { loading: loadingAd }] = useMutation(CREATE_NEW_AD, {
		onError: () => alerts.failMessageGenerator('advertisement', 'created'),
	});
	const [createLocationMutation, { loading: loadingLocation }] = useMutation(CREATE_LOCATION, {
		onError: () => alerts.failMessageGenerator('location', 'created'),
	});
	const [createOrganizerMutation, { loading: loadingOrganizer }] = useMutation(CREATE_ORGANIZER, {
		onError: () => alerts.failMessageGenerator('organizer', 'created'),
	});

	const [updateEventMutation, { loading: loadingUpdateEvent }] = useMutation(UPDATE_EVENT, {
		onError: () => alerts.failMessageGenerator('event', 'updated'),
	});
	const [updateAdMutation, { loading: loadingUpdateAd }] = useMutation(UPDATE_AD, {
		onError: () => alerts.failMessageGenerator('advertisement', 'updated'),
	});
	const { uploadImages, loading: loadingImages } = useUploadImages();

	const loading =
		loadingEvent ||
		loadingAd ||
		loadingLocation ||
		loadingOrganizer ||
		loadingUpdateEvent ||
		loadingUpdateAd ||
		loadingImages;

	const handleSubmit = async () => {
		let {
			id,
			name,
			rating,
			startDate,
			endDate,
			types,
			images,
			eventStatus,
			invoice,
			location,
			editedLocation,
			organizer,
			editedOrganizer,
			visitorCount,
			admission,
		} = transformInputData(changedValueDiffRef.current || {});

		const eventId = changedValueRef.current.id;

		// create images
		const createdImages = images
			? await createImages(
					images.newItems.filter(image => image.type === 'newImage'),
					uploadImages
			  )
			: [];

		// In update events mode images can be added, deleted or updated. Each image is represented by an advertisement.
		// But only the image, and the url of that advertisement are considered.
		let createdAdIds;
		let updatedAdId;
		if (isUpdate) {
			// create ads (only update event mode)
			// create ads for new created event images and add to event

			createdAdIds =
				!!createdImages &&
				(await Promise.all(
					createdImages.map(image => createAdByImage({ image, eventId, createAdMutation }))
				));

			// update ads (only update event mode)
			// ads are updated by the changed image items
			const changedImages = images?.changedItems;

			updatedAdId =
				!!changedImages?.length &&
				(await Promise.all(
					changedImages.map(image =>
						updateAd({ image, eventId, uploadImages, updateAdMutation })
					) || []
				));

			// delete ads (only update event mode)
			// TODO images.deletedItems
		}

		// create new location if a new one is defined for the event
		const { isCreateNewLocation, ...locationProps } = editedLocation || {};
		location = isCreateNewLocation
			? await createLocation({ createLocation: locationProps, createLocationMutation })
			: location;

		// create new organizer if a new one is defined for the event
		const { isCreateNewOrganizer, ...organizerProps } = editedOrganizer || {};
		organizer = isCreateNewOrganizer
			? await createOrganizer({ createOrganizer: organizerProps, createOrganizerMutation })
			: organizer;

		const data = isUpdate
			? (
					await updateEvent(
						{
							id,
							name,
							startDate,
							endDate,
							types,
							eventStatusId: eventStatus?.id,
							invoice,
							locationId: location?.id,
							organizerIds: organizer?.id && [organizer.id],
							admission,
							visitorCount,
						},
						updateEventMutation,
						initialDataRef
					)
			  )?.data
			: (
					await createEventMutation({
						variables: {
							createNewEvent: {
								rating,
								name,
								startDate,
								endDate,
								types,
								images: createdImages.map(({ createdId, position, url }) => ({
									id: createdId,
									source: { url },
									position,
								})),
								locationId: location?.id,
								organizerId: organizer?.id,
								admission,
								visitorCount,
							},
						},
					})
			  )?.data;
		if (!!data?.createNewEvent || !!data?.updateEvent) {
			!!onChangeSubmittedRef.current &&
				onChangeSubmittedRef.current(data.createNewEvent || data.updateEvent);
		} else if (!!updatedAdId || !!createdAdIds?.length) {
			!!onChangeSubmittedRef.current && onChangeSubmittedRef.current({ id });
		}
	};

	const handleChangeValue = ({
		initialData,
		changedValue,
		changedValueDiff,
		submitIssues: _submitIssues,
		onChangeSubmitted,
		onReset,
	}) => {
		console.log('>>> on value change', initialData, changedValue);

		initialDataRef.current = initialData;
		changedValueRef.current = changedValue;
		changedValueDiffRef.current = changedValueDiff;
		onChangeSubmittedRef.current = onChangeSubmitted;
		onResetRef.current = onReset;
		if (!!_submitIssues || !!submitIssues) setSubmitIssues(_submitIssues);
	};

	return {
		onSubmit: handleSubmit,
		onChangeValue: handleChangeValue,
		onReset: () => {
			!!onResetRef.current && onResetRef.current();
		},
		submitIssues,
		loading,
	};
};

/////////////////////////////////////////////////////////////////////////////////////////////////////

// transform event input data ///////////////////////////////////////////////////////////////////////
const transformInputData = ({ admission, visitorCount, invoice, ...vals }) => {
	admission = admission
		?.map(_admission => parseFloat(_admission))
		.filter(_admission => isFinite(_admission));
	if (!admission?.length) admission = undefined;

	visitorCount = parseInt(visitorCount);
	if (!isFinite(visitorCount)) visitorCount = undefined;

	const emptyInvoiceNumber = invoice?.invoiceNumber === null;
	const emptyItemNumber = invoice?.itemNumber === null;

	if (emptyInvoiceNumber || emptyItemNumber) {
		invoice = null;
	}

	return { admission, visitorCount, invoice, ...vals };
};

// functions for creating and updating image ad, location and organizer. ////////////////////////////
const createImages = (images, uploadImages) =>
	new Promise(async resolve => {
		if (!images) {
			resolve([]);
			return;
		}
		const createdFileIds = await uploadImages(images);
		resolve(zip(createdFileIds, images).map(([createdId, image]) => ({ createdId, ...image })));
	});

const createAdByImage = ({ image, eventId, createAdMutation }) =>
	new Promise(async resolve => {
		const response = await createAdMutation({
			variables: {
				createAd: {
					eventId,
					createAd: {
						image: {
							id: image.createdId,
							source: { url: image.url },
							position: image.position,
						},
					},
				},
			},
		});
		resolve({ id: response?.data?.createAd?.id, position: image.position });
	});

const updateEvent = ({ id, ...updatedVals }, updateEventMutation, initialData) => {
	// especial handling for invoice deletion
	const isDeleteInvoice =
		initialData?.current?.invoice && updatedVals?.invoice == null ? true : false;

	return Object.values(updatedVals).some(value => value != null) || isDeleteInvoice // check if some values have changed
		? updateEventMutation({
				variables: {
					updateEvent: {
						id,
						...updatedVals,
					},
				},
		  })
		: new Promise(resolve => resolve(null));
};

const updateAd = ({ image, eventId, uploadImages, updateAdMutation }) =>
	new Promise(async resolve => {
		const createdImage =
			image.type === 'newImage' && (await createImages([image], uploadImages))[0];
		if (createdImage || image.url) {
			const response = await updateAdMutation({
				variables: {
					updateAd: {
						id: image.id,
						eventId,
						image: {
							...(!!image.createdId ? { id: image.createdId } : {}),
							...(!!image.url ? { source: { url: image.url } } : {}),
							...(!!image.position ? { position: image.position } : {}),
						},
					},
				},
			});
			resolve({ id: response?.data?.updateAd?.id });
		}
	});

const createLocation = ({ createLocation, createLocationMutation }) =>
	new Promise(async resolve => {
		const response = await createLocationMutation({
			variables: {
				createLocation,
			},
		});
		const id = response?.data?.createLocation?.id;
		resolve(id != null ? { id } : null);
	});

const createOrganizer = ({ createOrganizer, createOrganizerMutation }) =>
	new Promise(async resolve => {
		const response = await createOrganizerMutation({
			variables: {
				createOrganizer,
			},
		});
		const id = response?.data?.createOrganizer?.id;
		resolve(id != null ? { id } : null);
	});
