import React, { useState, useLayoutEffect, useCallback } from 'react';
import { Message } from 'semantic-ui-react';

import SetListTable from './SetListTable';
import SetListToolArea from './SetListToolArea';
import storage from 'storage';
import { useTranslate } from 'customHooks';

import './SetListTab.scss';

const SetListTab = ({ data = [] }) => {
	const dataLength = useCallback(data.length, [data]);
	const fillIsExpanded = useCallback(
		(arr = []) => {
			// array with value in every entry (with length = amount of setlists)
			const initialLength = arr.length;
			arr.length = dataLength;
			arr.fill(storage.isSetListExpanded(), initialLength);
			return arr;
		},
		[dataLength]
	);
	const [isExpanded, setIsExpanded] = useState(fillIsExpanded());
	const [onlyCovered, setOnlyCovered] = useState(false);
	const translate = useTranslate();

	useLayoutEffect(() => {
		// extend array for more data if needed
		if (dataLength > isExpanded.length) {
			setIsExpanded(fillIsExpanded([].concat(isExpanded)));
		}
		// eslint-disable-next-line
	}, [dataLength]);

	// update the value on index with bool (clone array instead of mutating, so setState updates component)
	const updateIsExpanded = ({ index, bool }) => {
		const _isExpanded = [].concat(isExpanded);
		_isExpanded[index] = bool;
		storage.setSetListExpanded(bool);
		setIsExpanded(_isExpanded);
	};

	return data.length ? (
		<div className="SetListTab">
			<SetListToolArea {...{ isExpanded, setIsExpanded, setOnlyCovered, dataLength }} />
			<div className="SetListContainer">
				{!!data &&
					data.map((item, index) => (
						<SetListTable
							key={index}
							{...{ onlyCovered }}
							updateIsExpanded={bool => updateIsExpanded({ ...{ index, bool } })}
							isExpanded={isExpanded[index]}
							data={item}
						/>
					))}
			</div>
		</div>
	) : (
		<Message info>
			<Message.Header>{translate('messages.noSetlistsDataHeader')}</Message.Header>
			<p>{translate('messages.noSetlistsData')}</p>
		</Message>
	);
};

export default SetListTab;
