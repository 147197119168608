import React from 'react';
import Button from 'components/Button';
import { Header, Button as UIButton } from 'semantic-ui-react';
import { ListNavigator, Actions, StatusBulkEdit } from 'components/Base';
import classNames from 'classnames';

import './Toolbar.scss';

export default ({ header, ...props }) => {
	const {
		listNavigator,
		actions,
		merge,
		dragMode,
		updateEvent,
		createNewEvent,
		submit,
		lock,
		createUpdateGeneric,
		undo,
		refresh,
		extendAll,
		table,
		csvExport,
		fieldSwitcher,
		renderedButtons,
		reset,
		isUpdateMode,
		bulkEdit,
	} = applyIsOnly(props);

	return (
		<div className={classNames('Toolbar', isUpdateMode && 'isShowInput')}>
			<Header as="h3">{header != null ? header : ''}</Header>
			{!!listNavigator && (
				<div className={classNames('paging', listNavigator.className)}>
					<ListNavigator {...listNavigator} overflow={10000} />
				</div>
			)}
			<div className="actionBar">
				<div className="right-side">
					{/* action button in detail view is always shown even if action button is not shown for corresponding table */}
					<UIButton.Group>{!!actions && <Actions {...actions} />}</UIButton.Group>
					{/* uses actions.events and actions.onCompleted */}
					<UIButton.Group>{!!bulkEdit && <StatusBulkEdit {...actions} />}</UIButton.Group>
					{!!lock && <UIButton.Group>{!!lock && <Button.Lock {...lock} />}</UIButton.Group>}
					{(!!merge || !!createNewEvent || !!updateEvent || !!createUpdateGeneric || !!undo) && (
						<UIButton.Group>
							{!!merge && <Button.Merge {...merge} />}
							{!!dragMode && <Button.DragMode {...dragMode} />}
							{!isUpdateMode && !!updateEvent && <Button.UpdateEvent {...updateEvent} />}
							{!!createNewEvent && <Button.CreateNewEvent {...createNewEvent} />}
							{!!createUpdateGeneric && <Button.CreateUpdateGeneric {...createUpdateGeneric} />}
							{!!undo && <Button.Undo {...undo} />}
						</UIButton.Group>
					)}
					{(!!refresh ||
						!!extendAll ||
						!!csvExport ||
						!!updateEvent ||
						!!fieldSwitcher ||
						!!table) && (
						<UIButton.Group>
							{!!refresh && <Button.Refresh {...refresh} />}
							{!!extendAll && <Button.ExpandAll {...extendAll} />}
							{!!csvExport && <Button.CSVExport {...csvExport} />}
							{isUpdateMode && !!updateEvent && <Button.UpdateEvent {...updateEvent} />}
							{!!fieldSwitcher && <Button.FieldSwitcher {...fieldSwitcher} />}
							{!!table && <Button.Table {...table} />}
						</UIButton.Group>
					)}
					{(!!reset || !!submit) && (
						<UIButton.Group>
							{!!reset && <Button.Reset {...reset} />}
							{!!submit && <Button.Submit {...submit} />}
						</UIButton.Group>
					)}
					{/* rendered buttons as tags */}
					{renderedButtons}
				</div>
			</div>
		</div>
	);
};

const applyIsOnly = props => {
	if (!Object.keys(props).some(key => props[key]?.isOnly)) return props;
	Object.keys(props).forEach(key => {
		if (!props[key]) return;
		!props[key].isOnly &&
			!!props[key] &&
			typeof props[key] !== 'boolean' &&
			(props[key].className = classNames(props[key].className, 'invisible'));
	});
	return props;
};
