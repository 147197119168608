import Meslis from 'components/Meslis';
import React, { useEffect } from 'react';
import { Loader } from 'semantic-ui-react';
import Table from './Table';
import './PreviewList.scss';

const PreviewList = ({
	data,
	loading,
	refetch,
	leafletData,
	refetchCount,
	filters,
	sorting,
	isChanged = false,
}) => {
	useEffect(() => {
		!!isChanged && refetch();
	}, [isChanged, refetch]);

	if (loading || !data.searchEventsBySearchParams) {
		return (
			<div className="LoaderContainer">
				<Loader size="large" active inline="centered" />
			</div>
		);
	}

	return (
		<div className="PreviewList">
			<Table
				data={data.searchEventsBySearchParams}
				onDelete={() => {
					refetch();
					refetchCount();
				}}
				{...{ leafletData, filters, sorting }}
			/>
		</div>
	);
};

export default ({ leafletData, ...props }) => (
	<Meslis.Leaflet leafletId={leafletData && leafletData.id} max={5}>
		{({ data, loading, refetch }) => (
			<PreviewList {...{ data, loading, refetch, leafletData, ...props }} />
		)}
	</Meslis.Leaflet>
);
