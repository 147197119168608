import React from 'react';

import Table from 'components/Table';
import Meslis from 'components/Meslis';
import { FullLayout } from 'components/Layout';
import { LoadingContent, Msg } from 'components/Base';
import { useTranslate, useSorting } from 'customHooks';
import { defaultSortFunction } from 'utils';

import { order } from 'enums';

import './index.scss';

const Subscriptions = () => {
	const translate = useTranslate();
	const { sorting, sortByField } = useSorting('createdAt', order.DESC);

	return (
		<div className="Subscriptions" data-test="My Searches">
			<FullLayout>
				<Meslis.SubscriptionsFullData
					fetchPolicy="no-cache" // no-cache because other users might have changed something
				>
					{({ loading, error, data, dataUsers, refetch }) => {
						if (loading) return <LoadingContent />;

						if (error) {
							// console.error(error);
							return <p>Error</p>;
						}

						if (data.length === 0) {
							return (
								<Msg icon="frown outline" heading={translate('messages.noSubscriptionsHeader')}>
									{translate('messages.noSubscriptionsText')}
								</Msg>
							);
						}

						const sortedData = () => {
							const _data = data.sort(defaultSortFunction(sorting.field));
							if (sorting.orderBy === order.DESC) {
								_data.reverse();
							}
							return _data;
						};

						const _data = sortedData();

						const initialColleaguesOfQueries = () => {
							const obj = {};
							_data.forEach(query => (obj[query.id] = query.sharedUsers));
							return obj;
						};

						const initialIsHiddenOfQueries = () =>
							_data.reduce(
								(prev, current) => ({
									...prev,
									[current.id]: !!current.isHiddenForMe,
								}),
								{}
							);

						return (
							<Table.Subscriptions
								data={_data}
								onRefetchAll={() => refetch()}
								initialColleaguesOfQueries={initialColleaguesOfQueries()}
								initialIsHiddenOfQueries={initialIsHiddenOfQueries()}
								onSort={sortByField}
								{...{ sorting, dataUsers }}
							/>
						);
					}}
				</Meslis.SubscriptionsFullData>
			</FullLayout>
		</div>
	);
};

export default Subscriptions;
