import React from 'react';
import _ from 'lodash';
import { DownloadSelection, Rating, PriceList } from 'components/Base';

const renderPrices = data => <PriceList data={data.admission} />;

const renderRating = data => <Rating value={data.rating} />;

const getFromObject = field => data => {
	let val = _.get(data, field);
	val = typeof val === 'string' ? val.trim() : val;
	return val !== null ? val : '';
};

const events = name =>
	name === 'rating'
		? renderRating
		: name === 'admission.price'
		? renderPrices
		: getFromObject(name);

const selections = name =>
	name === 'actions' ? data => <DownloadSelection data={data} /> : getFromObject(name);

export default { events, selections };
