import React from 'react';

import Price from './Price';

const PriceList = ({ data }) => (
	<div className="PriceList">
		{data &&
			data.map((item, index) => <Price key={index} amount={item.price} currency={item.currency} />)}
	</div>
);

export default PriceList;
