import React, { useState } from 'react';
import { Form, Button, Message } from 'semantic-ui-react';
import { useTranslate } from 'customHooks';
import { Redirect } from 'react-router-dom';
import { VALIDATION_REG_EXP, EXCLUSION_REG_EXP } from 'config';
import { urlForPasswordResetConfirmation, getPubKey, rsaEncrypt } from 'utils';
import './ResetForm.scss';

const helpCenterUrl = process.env.REACT_APP_HELP_CENTER_URL;
const ResetForm = ({ id, token }) => {
	const [newPassword, setNewPassword] = useState('');
	const [validationPassword, setValidationPassword] = useState('');
	const [error, setError] = useState(false);
	const [ruleError, setRuleError] = useState(false);
	const [redirect, setRedirect] = useState(false);

	const translate = useTranslate();

	const handleSubmit = () => {
		validatePassword();
	};

	const confirmPasswordReset = async encryptedPT => {
		setError(false);
		await fetch(urlForPasswordResetConfirmation(id, token, encodeURIComponent(encryptedPT)))
			.then(response => {
				if (response.ok) {
					setRedirect(true);
				} else if (!response.ok) {
					response.status === 400 && setError(translate('resetPassword.failResetMessage'));
					response.status === 429 &&
						setError(
							translate('messages.tooManyRequests', [
								translate('resetPassword.triggerButton').toLowerCase(),
							])
						);
				}
			})
			.catch(e => {
				setError(e.toString());
			});
	};

	const validatePassword = () => {
		!(VALIDATION_REG_EXP.test(newPassword) && EXCLUSION_REG_EXP.test(newPassword)) &&
			setRuleError(true);
		validationPassword !== newPassword && setError(translate('error.notSameNewPassword'));

		let valError =
			!(VALIDATION_REG_EXP.test(newPassword) && EXCLUSION_REG_EXP.test(newPassword)) ||
			validationPassword !== newPassword
				? true
				: false;

		if (valError === false) {
			setValidationPassword('');
			setNewPassword('');
			rsaEncrypt(newPassword, getPubKey).then(encrypted => confirmPasswordReset(encrypted));
		}
	};

	return (
		<div className="ResetForm">
			{redirect && <Redirect to="/reset-successful" />}
			<Form onSubmit={handleSubmit} error={!!error} success={redirect}>
				<Message error header="Validation failed" content={error} />
				<Message
					info
					className="password-reset-message"
					header={translate('resetPassword.passwordResetHeader')}
					content={translate('resetPassword.passwordResetMessage', [
						<a href={helpCenterUrl} target="_blank" rel="noopener noreferrer">
							<b>Help Center</b>
						</a>,
					])}
				/>
				<Form.Input
					required
					error={ruleError && translate('error.rulePassword')}
					type="password"
					placeholder={translate('resetPassword.newPassword')}
					onChange={e => {
						setError(false);
						setRuleError(false);
						setNewPassword(e.target.value);
					}}
					value={newPassword}
					autoComplete="off"
				/>
				<Form.Input
					error={error === translate('error.notSamePassword') && error}
					required
					type="password"
					placeholder={translate('resetPassword.validatePassword')}
					onChange={e => {
						setError(false);
						setRuleError(false);
						setValidationPassword(e.target.value);
					}}
					value={validationPassword}
					autoComplete="off"
				/>
				<div className="tip">{translate('resetPassword.strength')}</div>
				<Button
					fluid
					color="blue"
					disabled={!validationPassword || !newPassword || error}
					type="submit">
					{translate('resetPassword.setNewPassword')}
				</Button>
			</Form>
		</div>
	);
};

export default ResetForm;
