import React, { useEffect } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { useUserContext } from 'customHooks';
import { getCountriesOfCompany, ALL_COUNTRIES } from 'countriesConfig';

const CountryInput = ({ value, onChange, showAllCountries, placeholder, ...props }) => {
	const { user } = useUserContext();

	const countries = showAllCountries ? ALL_COUNTRIES : getCountriesOfCompany(user?.company?.id);

	useEffect(() => {
		// Most companies are only working on events in a single country.
		// This useEffect assures that it will be set automatically on init.
		!value && onChange(countries[0]);
		// eslint-disable-next-line
	}, []);

	if (!!value && !countries.includes(value)) {
		// fall back for updating if spelling in data base differs from spelling in predefined list
		placeholder = value;
	}

	return (
		<Dropdown
			data-test="CountryInput"
			options={countries.map(country => ({ text: country, value: country }))}
			selection
			clearable
			search
			value={value || ''} // Map undefined to ''. This is necessary for resetting.
			onChange={(_, { value }) => onChange(value)}
			{...{ placeholder, ...props }}
		/>
	);
};

export default CountryInput;
