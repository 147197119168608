import { get, has } from 'lodash';
import generateIdColors from './generateIdColors';

const getAdsWrapper = mergedEvent =>
	(get(mergedEvent, 'advertisements.results') || []).map((ad, idx) => ({
		id: ad.id,
		idx,
		shownIdx: idx + 1,
		ad,
	}));
const getOrganizerIds = mergedEvent => (mergedEvent.organizers || []).map(o => o.id);
const getMergingData = ({
	mergedEvent,
	comparedFields,
	comparedArrays,
	notMergedAdFields,
	shownAdWrapper: { id: shownAdId } = {},
	isMergedMode,
	titleURLSameAs,
}) => {
	let event = {};
	const dataSource = {};
	let idColors = {};

	// This can happen when data in EventDetail/index has changed but shownAdId
	// has not yet been updated in useLayoutEffect.
	const isInconsistent =
		!!shownAdId &&
		!getAdsWrapper(mergedEvent)
			.map(x => x.id)
			.includes(shownAdId);

	if (!!mergedEvent && !isInconsistent) {
		if (mergedEvent.advertisements.results.length <= 1) {
			event = {
				...mergedEvent,
				advertisements: undefined,
			};

			comparedArrays
				.filter(({ isOnlyInAd }) => isOnlyInAd)
				.forEach(({ name }) => {
					event[name] = [];
					mergedEvent.advertisements.results.forEach(ad => {
						ad[name] != null && event[name].push(ad[name]);
					});
				});
		} else if (isMergedMode) {
			/* merged mode */
			event = {
				...mergedEvent,
				advertisements: undefined,
			};

			comparedFields.forEach(
				({ name, noSourceValue /* don't show id markers if having this standard value */ }) => {
					dataSource[name] = [];
					mergedEvent.advertisements.results.forEach(ad => {
						if (ad[name] != null && ad[name] !== noSourceValue && ad[name] === mergedEvent[name])
							dataSource[name].push(ad.id);
					});
				}
			);

			comparedArrays.forEach(({ name, mapComparedValue: map, isOnlyInAd }) => {
				dataSource[name] = [];
				map = map || (x => x);

				if (isOnlyInAd) {
					event[name] = [];
					mergedEvent.advertisements.results.forEach(ad => {
						if (ad[name] != null) {
							event[name].push(ad[name]);
							dataSource[name].push([ad.id]);
						}
					});
				} else {
					const mergedArray = (mergedEvent[name] || []).map(map);
					mergedArray.forEach((item, i) => {
						dataSource[name][i] = [];
						mergedEvent.advertisements.results.forEach(ad => {
							const adArray = (ad[name] || []).map(map);
							if (adArray.includes(item)) dataSource[name][i].push(ad.id);
						});
					});
				}
			});
		} else {
			/* single mode */
			event = {
				...mergedEvent, //keep all fields wich do not exist on ad layer
				advertisements: undefined,
				...mergedEvent.advertisements.results.find(ad => ad.id === shownAdId),
			};

			comparedArrays.forEach(({ name, mapComparedValue: map, isOnlyInAd }) => {
				map = map || (x => x);
				if (isOnlyInAd) {
					event[name] = [event[name]];
				} else {
					event[name] =
						event[name] != null &&
						event[name]
							.map(map)
							.map(compared => (mergedEvent[name] || []).find(item => map(item) === compared));
				}
			});
		}

		shownAdId &&
			notMergedAdFields &&
			notMergedAdFields.forEach(name => {
				event[name] = mergedEvent.advertisements.results.find(ad => ad.id === shownAdId)[name];
			});

		event.id = mergedEvent.id;
		event.timestamp = mergedEvent.timestamp;
		event.adTimestamp =
			has(mergedEvent, 'advertisements.results') &&
			shownAdId &&
			mergedEvent.advertisements.results.find(ad => ad.id === shownAdId).timestamp;

		event.titleURL = get(
			mergedEvent.advertisements.results.find(
				ad =>
					ad.id ===
					(isMergedMode && mergedEvent.advertisements.results.length > 1
						? get(dataSource, `${titleURLSameAs}[0]`)
						: shownAdId)
			),
			'url'
		);

		idColors = generateIdColors(getAdsWrapper(mergedEvent).map(x => x.id));
	}
	return { mergingData: { event, dataSource, idColors }, isInconsistent };
};

export default { getAdsWrapper, getOrganizerIds, getMergingData };
