import React, { useRef, useState } from 'react';

import storage from 'storage';
import Navi from './Navi';

import { FullLayout, GraySidebarLayout } from 'components/Layout';
import { LoadingContent, DimmableWrapper, Msg } from 'components/Base';
import LocationsFilter from 'components/FilterWidget/LocationsFilter';
import OrganizersFilter from 'components/FilterWidget/OrganizersFilter';
import Table from 'components/Table';

import useQueryParams from 'customHooks/useQueryParams';
import Meslis from 'components/Meslis';
import { useFetchSize, useTranslate } from 'customHooks';

export const Search = ({ filters, sorting, type, ...props }) => {
	const storageMemoKey = `${type}-search`;
	const [MeslisGeneric, dataField] =
		type === 'location'
			? [Meslis.Locations, 'searchLocations']
			: // type === 'organizer'
			  [Meslis.Organizers, 'searchOrganizers'];

	const { loading: loadingMax, fetchSize: max, fetchMore } = useFetchSize(storageMemoKey);

	// assures that data not null when reloading
	// => dimmer is applied instead of LoadingContent showing table with old data
	// this is important to not lose vertical and horizontal scrolling
	const shownData = useRef();

	return (
		<MeslisGeneric filters={filters.filter(x => x.enabled)} {...{ sorting, max }}>
			{({ data, loading, error }) => {
				shownData.current = (data && data[dataField]) || shownData.current;
				return (
					<SearchContent
						data={shownData.current}
						loading={loading || loadingMax}
						{...{ error, max, fetchMore, storageMemoKey, filters, sorting, type, ...props }}
					/>
				);
			}}
		</MeslisGeneric>
	);
};
const SearchContent = ({
	data,
	loading,
	error,
	max,
	fetchMore,
	storageMemoKey,

	filters,
	sorting,
	shownSorting,
	onChangeFilters,
	onCtrlUp,
	onSort,
	type,
}) => {
	const translate = useTranslate();
	const [collapsed, setCollapsed] = useState(storage.isSecondarySidebarCollapsed());
	const { pushURL } = useQueryParams({});

	const results = !!data?.results?.length ? data.results : [];
	const totalCount = data ? data.totalCount : 0;

	const handleReachBottom = () => {
		if (!loading && max < parseInt(totalCount)) {
			fetchMore(storageMemoKey);
		}
	};

	const handleDoubleClickRow = genericOffset => {
		pushURL({ step: 'select', genericOffset });
	};

	const handleCollapsed = collapsed => setCollapsed(collapsed);

	const name = translate('messages.searchResults', [
		totalCount.toLocaleString(),
		results.length.toLocaleString(),
		!collapsed
			? ''
			: type === 'location'
			? translate('messages.searchLocations')
			: translate('messages.searchOrganizers'),
	]);

	const [TableGeneric, Filter] =
		type === 'location'
			? [Table.Locations, LocationsFilter]
			: // type === 'organizer'
			  [Table.Organizers, OrganizersFilter];

	return (
		<GraySidebarLayout
			onCollapsed={handleCollapsed}
			sidebar={
				<>
					<Navi />
					<Filter {...{ filters, sorting, onChangeFilters }} />
				</>
			}>
			<FullLayout>
				{!!error ? (
					<div>Error</div>
				) : loading && !data ? (
					<LoadingContent />
				) : totalCount === 0 ? (
					<Msg>{translate('messages.noData')}</Msg>
				) : (
					<DimmableWrapper dimmed={loading && !!data}>
						<TableGeneric
							{...{
								moreDataAvailable: parseInt(totalCount) > results.length,
								data: results,
								onChangeToDetailView: handleDoubleClickRow,
								onReachBottom: handleReachBottom,
								filters: filters.filter(x => x.enabled),
								name,
								onSort,
								sorting: shownSorting,
								onCtrlUp,
								storageMemoKey,
							}}
						/>
					</DimmableWrapper>
				)}
			</FullLayout>
		</GraySidebarLayout>
	);
};

export default Search;
