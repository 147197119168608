import React from 'react';
import { Icon } from 'semantic-ui-react';
import { useTranslate } from 'customHooks';

export default ({ expand }) => {
	const translate = useTranslate();
	return (
		<div className="update-image">
			<Icon name="image outline" size="big" />
			<span className="release-text">
				{expand ? translate('placeholders.releaseToDrop') : translate('placeholders.dragAndDrop')}
			</span>
		</div>
	);
};
