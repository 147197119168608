import React from 'react';
import { Icon } from 'semantic-ui-react';
import { useTranslate, useUserSettings } from 'customHooks';
import browserStorage from 'browserStorage';

import PasswordChange from 'components/PasswordChange';
import TableSettings, { defaultTableData } from './TableSettings';
import LocalSettings from './LocalSettings';
import Group from './Group';

import LoadingContent from 'components/Base/LoadingContent';

import './index.scss';

const UserSettings = () => {
	const { loading, userSettings, setUserSettings } = useUserSettings();
	const translate = useTranslate();

	const handleChangeData = ({ dateFormat, defaultFetchSize, languageCode }) => {
		const userSettingsBuffer = {};
		dateFormat && (userSettingsBuffer.dateFormat = dateFormat);
		defaultFetchSize && (userSettingsBuffer.defaultFetchSize = parseInt(defaultFetchSize));
		languageCode && (userSettingsBuffer.languageCode = languageCode);

		setUserSettings(userSettingsBuffer);
	};

	if (!loading) {
		return (
			<div className="user-settings-page">
				<div className="user-header">
					<Icon size="huge" className="avatar" name="user circle" />
					<div className="name">{browserStorage.getUser()}</div>
				</div>

				<Group
					header={translate('userSettings.changePassword')}
					isExpandable={true}
					AlwaysShown={<Icon className="passwordIcon" name="lock" />}>
					<PasswordChange />
				</Group>

				<Group
					header={translate('userSettings.table')}
					SettingsInput={TableSettings}
					data={userSettings}
					defaultData={defaultTableData}
					onSubmitData={handleChangeData}
				/>

				<Group
					header={translate('local.localSettings')}
					SettingsInput={LocalSettings}
					onSubmitData={handleChangeData}
					/* data is not provided here because it comes from the TranslateContext */
				/>
			</div>
		);
	} else {
		return <LoadingContent />;
	}
};

export default UserSettings;
