import React from 'react';
import { Table } from 'semantic-ui-react';
import { useTranslate } from 'customHooks';

const OrganizerData = ({
	name,
	matchedOrganizerIds,
	commercialId,
	address,
	category,
	emailAddresses,
	phoneNumbers,
	urls,
	timestamp,
	statusName,
	statusUsername,
	statusLastChangedAt,
	isRenderField = () => true,
}) => {
	const translate = useTranslate();

	return (
		<Table className="OrganizerData" basic="very" celled>
			<Table.Body>
				{isRenderField('orgName') && <TRow header={translate('detailView.name')}>{name}</TRow>}
				{isRenderField('orgTimestamp') && !!timestamp && (
					<TRow header={translate('detailView.timestamp')}>{timestamp}</TRow>
				)}
				{isRenderField('orgStatusName') && !!statusName && (
					<TRow header={translate('detailView.statusName')}> {statusName}</TRow>
				)}
				{isRenderField('orgStatusUsername') && !!statusUsername && (
					<TRow header={translate('detailView.statusUsername')}>{statusUsername}</TRow>
				)}
				{isRenderField('orgStatusLastChangedAt') && !!statusLastChangedAt && (
					<TRow header={translate('detailView.statusLastChangedAt')}>{statusLastChangedAt}</TRow>
				)}
				{isRenderField('orgCustomerIds') && (
					<TRow header={translate('detailView.orgMatchedIds')}>{matchedOrganizerIds}</TRow>
				)}
				{isRenderField('orgCommercialId') && (
					<TRow header={translate('detailView.orgCommercialId')}>{commercialId}</TRow>
				)}
				{isRenderField('orgAddress') && (
					<TRow header={translate('detailView.address')}>
						{address && (
							<>
								{address.streetHouseNumber /* for inputs both fields combined (update/create) */}
								<br />
								{
									address.postalCode || address.city //data fields separated by space
										? `${address.postalCode} ${address.city}`
										: address.postalCodeCity /* for inputs both fields combined (update/create) */
								}
								<br />
								{address.region}
								<br />
								{address.country}
							</>
						)}
					</TRow>
				)}
				{isRenderField('orgCategory') && (
					<TRow header={translate('detailView.category')}>{category}</TRow>
				)}
				{isRenderField('orgEmails') && (
					<TRow header={translate('detailView.mail')}>{emailAddresses}</TRow>
				)}
				{isRenderField('orgPhoneNumbers') && (
					<TRow header={translate('detailView.phone')}>{phoneNumbers}</TRow>
				)}
				{isRenderField('orgWebsites') && (
					<TRow header={translate('detailView.websites')}>{urls}</TRow>
				)}
			</Table.Body>
		</Table>
	);
};

const TRow = ({ header, children }) => (
	<Table.Row>
		<Table.Cell className="first_col">{header}</Table.Cell>
		<Table.Cell data-test={`orgData-${header}`}>{children}</Table.Cell>
	</Table.Row>
);

export default OrganizerData;
