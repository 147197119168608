import React from 'react';

import './RightSidebarLayout.scss';

const RightSidebarLayout = ({ sidebar, children }) => {
	return (
		<div className="RightSidebarLayout">
			<div className="main">{children}</div>
			<div className="sidebar">{sidebar}</div>
		</div>
	);
};
export default RightSidebarLayout;
