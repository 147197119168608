import React from 'react';

import UserDropdown from './UserDropdown';
import Navigation from './Navigation';
import Collapse from './Collapse';

import './index.scss';

export default ({ onCollapse, collapsed }) => (
	<div className="Sidebar">
		<div className="logo big" onClick={onCollapse}>
			<img
				className="meslis-logo"
				alt="MESLIS logo"
				src={`${process.env.PUBLIC_URL}/logo_white.png`}
			/>
			<img
				className="meslis-font"
				alt="MESLIS"
				src={`${process.env.PUBLIC_URL}/meslis_white.png`}
			/>
		</div>
		<div className="logo small" onClick={onCollapse}>
			<img
				className="meslis-logo"
				alt="MESLIS logo"
				src={`${process.env.PUBLIC_URL}/logo_white.png`}
			/>
		</div>
		<Navigation collapsed={collapsed} />
		<Collapse collapsed={collapsed} onChange={onCollapse} />
		<UserDropdown collapsed={collapsed} />
	</div>
);
