import React from 'react';
import SimpleToolbar from '../Base/SimpleToolbar';
import LeafletGrid from './LeafletGrid';
import useTranslate from '../../customHooks/useTranslate';

import './ManageLeaflets.scss';
import { useMessagesContext } from 'customHooks';

const ManageLeaflets = ({
	data,
	onActionCompleted,
	editableLeaflet = null,
	refetchCustomLeaflets,
}) => {
	const translate = useTranslate();

	const { setOnUpdateLeaflets } = useMessagesContext();
	!!setOnUpdateLeaflets && setOnUpdateLeaflets(refetchCustomLeaflets);

	return (
		<div className="ManageLeaflets">
			<SimpleToolbar name={translate('navigation.customLeaflet')} />
			<LeafletGrid
				onActionCompleted={onActionCompleted}
				data={data.getAllLeaflets}
				editableLeaflet={editableLeaflet}
			/>
		</div>
	);
};

export default ManageLeaflets;
