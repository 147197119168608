import React from 'react';
import useReactRouter from 'use-react-router';

import NavigationButton from './NavigationButton';
import { useMessagesContext, useQueryParams, useTranslate } from 'customHooks';
import { ICONS } from 'config';
import classNames from 'classnames';

import './Navigation.scss';

export default ({ collapsed }) => {
	const translate = useTranslate();
	const { location } = useReactRouter();
	const { step } = useQueryParams({ offset: 0 });

	const { newQueryIds, newLeafletIds, handleNavigationClick } = useMessagesContext();

	const data = [
		{
			url: '/events',
			icon: ICONS.EVENT,
			text: translate('navigation.events'),
			'data-test': 'searchEventsButton',
			isActive: () => location.pathname === '/events' && step !== 'process',
		},
		{
			url: '/locations',
			icon: ICONS.LOCATION,
			text: translate('navigation.locations'),
			isActive: () => location.pathname === '/locations' && step !== 'process',
		},
		{
			url: '/organizers',
			icon: ICONS.ORGANIZER,
			text: translate('navigation.organizers'),
			isActive: () => location.pathname === '/organizers' && step !== 'process',
		},
		{
			url: '/events?step=process',
			icon: 'clipboard',
			text: translate('navigation.leaflets'),
			isActive: () =>
				(location.pathname === '/events' ||
					location.pathname === '/locations' ||
					location.pathname === '/organizers') &&
				step === 'process',
		},
		{
			url: '/my-searches',
			icon: 'searchengin',
			text: translate('navigation.subscriptions'),
			number: newQueryIds.length,
			tooltip:
				newQueryIds.length === 1
					? translate('navigation.subscriptionShared')
					: newQueryIds.length > 1
					? translate('navigation.subscriptionsShared', [newQueryIds.length])
					: null,
		},
		{ url: '/my-selections', icon: 'download', text: translate('navigation.exports') },
		{ url: '/history', icon: 'history', text: translate('navigation.history') },
		{
			url: '/custom-leaflet',
			icon: 'paste',
			text: translate('navigation.customLeaflet'),
			number: newLeafletIds.length,
			tooltip:
				newLeafletIds.length === 1
					? translate('navigation.leafletShared')
					: newLeafletIds.length > 1
					? translate('navigation.leafletsShared', [newLeafletIds.length])
					: null,
		},
	];

	return (
		<div className={classNames('Navigation', { collapsed })}>
			{data.map((item, index) => (
				<NavigationButton key={index} onClick={() => handleNavigationClick(item.url)} {...item} />
			))}
		</div>
	);
};
