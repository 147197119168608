import React from 'react';
import { useTranslate } from 'customHooks';
import { Icon } from 'semantic-ui-react';

import './Msg.scss';

const Msg = ({ heading, children, icon = 'search' }) => {
	const translate = useTranslate();
	heading = heading || translate('messages.noDataHeader');
	return (
		<div className="Msg">
			<Icon size="massive" name={icon} />
			<h1>{heading}</h1>
			<p>{children}</p>
		</div>
	);
};

export default Msg;
