import React from 'react';
import { useTranslate } from 'customHooks';
import { Dimmer, Loader } from 'semantic-ui-react';

import './DimmableWrapper.scss';

export default ({ children, dimmed }) => {
	const translate = useTranslate();
	return (
		<Dimmer.Dimmable data-test="DimmableWrapper" {...{ dimmed }}>
			<Dimmer active={dimmed} inverted>
				<Loader> {translate('placeholders.loading')}</Loader>
			</Dimmer>
			{children}
		</Dimmer.Dimmable>
	);
};
