import { useAppContext } from 'customHooks';
import { useEffect, useState } from 'react';
export default storageMemoKey => {
	const { tableMemo } = useAppContext();

	const loading = tableMemo.loading;
	const [fetchSize, setFetchSize] = useState(tableMemo.getFetchSize(storageMemoKey));

	useEffect(() => {
		if (!loading) {
			setFetchSize(tableMemo.getFetchSize(storageMemoKey));
		}
		// eslint-disable-next-line
	}, [loading]); // no more dependencies needed

	const fetchMore = () => setFetchSize(tableMemo.fetchMore(storageMemoKey));

	return { loading, fetchSize, fetchMore };
};
