import React, { useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';

import { ToggleIcon } from 'components/Base';
import './InfoBlock.scss';
import { noop } from 'utils';
import { useTranslate } from 'customHooks';
import { MAX_SHOWN_ADS } from 'config';
import classNames from 'classnames';

const InfoBlock = ({
	id,
	ids,
	onClickHeader = noop,
	icon,
	isIconRotating,
	functionButton,
	name,
	adHeader,
	ads,
	adOffset,
	forwardAd,
	backwardAd,
	showForwardBackward,
	adsColor,
	isHeadingGray,
	children,
	isShowInput,
}) => {
	const [open, setOpen] = useState(true);
	const translate = useTranslate();
	const handleToggleDropdown = () => setOpen(!open);

	if (!!ids && ids.length === 1) [id, ids] = [ids[0], undefined];
	else if (!!ids && ids.length === 0) ids = undefined;

	return (
		<div className="InfoBlock" data-test="InfoBlock">
			<h3>
				<span
					onClick={() => window.getSelection().type !== 'Range' && onClickHeader(id)}
					className={classNames({
						clickable: onClickHeader !== noop,
						'heading-gray': isHeadingGray,
					})}>
					<Icon name={icon} className={isIconRotating ? 'rotating' : null} /> {name}{' '}
					{id && (
						<small className="id" data-test={name + 'Meta'}>
							[
							{id && (
								<span data-test={`${name}Id`}>
									{translate('detailView.id')}: {id}
								</span>
							)}
							]
						</small>
					)}
				</span>
				{ids && (
					<small data-test={name + 'Meta'}>
						{' '}
						[{translate('detailView.ids')}:{' '}
						{ids.map((id, index) => (
							<span key={index}>
								{
									<>
										{id}
										{index < ids.length - 1 ? ', ' : ''}
									</>
								}
							</span>
						))}
						]
					</small>
				)}
				{
					// adding sub-heading for required fields for create/edit event
					name === 'Event' && isShowInput && (
						<div className={'sub-heading'}>{translate('actions.requiredFields')}</div>
					)
				}
				{functionButton}
				{
					// only render ads for Event-Infoblock,
					//if event not merged from different ads (ads===null) render
					//in order to keep height and avoid jumping of content below
					ads !== undefined && (
						<span style={{ visibility: ads !== null ? 'visible' : 'hidden' }}>
							<br />
							<span
								className={onClickHeader !== noop ? 'clickable' : ''}
								onClick={() => window.getSelection().type !== 'Range' && onClickHeader(id)}
								style={{ color: adsColor }}>
								{adHeader}
							</span>{' '}
							{ads !== null && (
								<small className="idxs">
									<span className="backward">
										{showForwardBackward ? (
											<Button icon onClick={backwardAd} disabled={!backwardAd}>
												<Icon name="angle left" size="small" />
											</Button>
										) : (
											<>[</>
										)}
									</span>

									<span data-test="ads" className={ads.length > MAX_SHOWN_ADS ? 'streched' : ''}>
										{adOffset !== 0 && (
											<>
												<span className="idx">{ads[0]}</span>
												<span className="idx">...</span>
											</>
										)}
										{ads.slice(adOffset, adOffset + MAX_SHOWN_ADS).map((ad, index, arr) => {
											return (
												<span key={index} className="idx">
													{
														<>
															{ad}
															{index < arr.length - 1 ? ', ' : ''}
														</>
													}
												</span>
											);
										})}
										{ads.length > adOffset + MAX_SHOWN_ADS && (
											<>
												<span className="idx"> ... </span>
												<span className="idx">{ads[ads.length - 1]}</span>
											</>
										)}
									</span>
									<span className="forward">
										{showForwardBackward ? (
											<Button icon onClick={forwardAd} disabled={!forwardAd}>
												<Icon name="angle right" size="small" />
											</Button>
										) : (
											<>]</>
										)}
									</span>
								</small>
							)}
						</span>
					)
				}
			</h3>
			<ToggleIcon open={open} onToggle={handleToggleDropdown} />
			<hr />
			{open && children}
		</div>
	);
};

export default InfoBlock;
