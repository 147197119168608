export const getCountriesOfCompany = companyID =>
	COUNTRIES_OF_COMPANIES[companyID] || ALL_COUNTRIES;
export const getRegionsOfCountry = country => REGIONS_OF_COUNTRIES[country] || ['?'];


// definitions REGIONS_OF_COUNTRIES, ALL_COUNTRIES and COUNTRIES_OF_COMPANIES. Please take note that all regions should be alphabetically sorted and have the first letter capitalized

const REGIONS_OF_COUNTRIES = {
	Armenia: [
		'Aragatsotn',
		'Ararat',
		'Armavir',
		'Gegharkunik',
		'Kotayk',
		'Lori',
		'Shirak',
		'Syunik',
		'Tavush',
		'Vayots Dzor',
		'Yerevan',
	],
	Albania: [
		'Berat',
		'Dibër',
		'Durrës',
		'Elbasan',
		'Fier',
		'Gjirokastër',
		'Korçë',
		'Kukës',
		'Lezhë',
		'Shkodër',
		'Tirana',
		'Vlorë',
	],
	Andorra: [
		'Andorra la Vella',
		'Escaldes-Engordany',
		'Encamp',
		'Sant Julià de Lòria',
		'La Massana',
		'Ordino',
		'Canillo',
	],
	Australia: [
		'New South Wales',
		'Queensland',
		'South Australia',
		'Tasmania',
		'Victoria',
		'Western Australia',
	],
	Austria: [
		'Burgenland',
		'Kärnten',
		'Niederösterreich',
		'Oberösterreich',
		'Salzburg',
		'Steiermark',
		'Tirol',
		'Vorarlberg',
		'Wien',
	],
	Belarus: ['Brest', 'Gomel', 'Grodno', 'Mogilev', 'Minsk', 'Vitebsk'],
	Belgium: [
		'Antwerpen',
		'Vlaams Gewest',
		'Limburg',
		'Hasselt',
		'Oost-Vlaanderen',
		'Gent',
		'Vlaams-Brabant',
		'Leuven',
		'West-Vlaanderen',
		'Brugge',
	],
	'Bosnia and Herzegovina': ['Federation of Bosnia and Herzegovina', 'Republika Srpska'],
	Bulgaria: [
		'Blagoevgrad',
		'Burgas',
		'Dobrich',
		'Gabrovo',
		'Haskovo',
		'Kardzhali',
		'Kyustendil',
		'Lovech',
		'Montana',
		'Pazardzhik',
		'Pernik',
		'Pleven',
		'Plovdiv',
		'Razgrad',
		'Ruse',
		'Shumen',
		'Silistra',
		'Sliven',
		'Smolyan',
		'Sofia-City',
		'Sofia',
		'Stara Zagora',
		'Targovishte',
		'Varna',
		'Veliko Tarnovo',
		'Vidin',
		'Vratsa',
		'Yambol',
	],
	Croatia: [
		'Bjelovar-Bilogora',
		'Brod-Posavina',
		'Dubrovnik-Neretva',
		'Istria',
		'Karlovac',
		'Koprivnica-Križevci',
		'Krapina-Zagorje',
		'Lika-Senj',
		'Međimurje',
		'Osijek-Baranja',
		'Požega-Slavonia',
		'Primorje-Gorski Kotar',
		'Sisak-Moslavina',
		'Split-Dalmatia',
		'Šibenik-Knin',
		'Varaždin',
		'Virovitica-Podravina',
		'Vukovar-Syrmia',
		'Zadar',
		'Zagreb',
	],
	Cyprus: ['Famagusta', 'Kyrenia', 'Larnaca', 'Limassol', 'Nicosia', 'Paphos'],
	'Czech Republic': [
		'Prague',
		'Central Bohemian',
		'South Bohemian',
		'Plzeň',
		'Karlovy Vary',
		'Ústí nad Labem',
		'Liberec',
		'Hradec Králové',
		'Pardubice',
		'Vysočina',
		'South Moravian',
		'Olomouc',
		'Zlín',
		'Moravian-Silesian',
	],
	Denmark: ['Hovedstaden', 'Midtjylland', 'Syddanmark', 'Nordjylland', 'Sjælland'],
	Estonia: [
		'Harju',
		'Hiiu',
		'Ida-Viru',
		'Jõgeva',
		'Järva',
		'Lääne',
		'Lääne-Viru',
		'Põlva',
		'Pärnu',
		'Rapla',
		'Saare',
		'Tartu',
		'Valga',
		'Viljandi',
		'Võru',
	],
	Finland: [
		'Ahvenanmaa',
		'Etelä-Karjala',
		'Etelä-Pohjanmaa',
		'Etelä-Savo',
		'Kainuu',
		'Kanta-Häme',
		'Keski-Pohjanmaa',
		'Keski-Suomi',
		'Kymenlaakso',
		'Lappi',
		'Pirkanmaa',
		'Pohjanmaa',
		'Pohjois-Karjala',
		'Pohjois-Pohjanmaa',
		'Pohjois-Savo',
		'Päijät-Häme',
		'Satakunta',
		'Uusimaa',
		'Varsinais-Suomi',
	],
	France: [
		'Auvergne-Rhône-Alpes',
		'Bourgogne-Franche-Comté',
		'Brittany',
		'Centre-Val de Loire',
		'Corsica',
		'Grand Est',
		'Hauts-de-France',
		'Île-de-France',
		'Normandy',
		'Nouvelle-Aquitaine',
		'Occitanie',
		'Pays de la Loire',
		"Provence-Alpes-Côte d'Azur",
	],
	Germany: [
		'Baden-Württemberg',
		'Bayern',
		'Berlin',
		'Brandenburg',
		'Bremen',
		'Hamburg',
		'Hessen',
		'Mecklenburg-Vorpommern',
		'Niedersachsen',
		'Nordrhein-Westfalen',
		'Rheinland-Pfalz',
		'Saarland',
		'Sachsen',
		'Sachsen-Anhalt',
		'Schleswig-Holstein',
		'Thüringen',
	],
	Georgia: [
		'Tbilisi',
		'Imereti',
		'Ajaria',
		'Kvemo Kartli',
		'Samegrelo and zemo Kartli',
		'Kakheti',
		'Shida Kartli',
		'Abkhazia',
		'Samtskhe-Javakheti',
		'Guria',
		'Mtskheta-Mtianeti',
		'Racha-Lechkhumi and Kvemo Svaneti',
	],
	Greece: [
		'Attica',
		'Central Greece',
		'Central Macedonia',
		'Crete',
		'Eastern Macedonia and Thrace',
		'Epirus',
		'Ionian Islands',
		'Mount Athos',
		'North Aegan',
		'Peloponnese',
		'South Aegean',
		'Thessaly',
		'West Greece',
		'West macedonia',
	],
	Hungary: [
		'Baranya',
		'Bács-Kiskun',
		'Békés',
		'Borsod-Abaúj-Zemplén',
		'Budapest',
		'Csongrád-Csanád',
		'Fejér',
		'Győr-Moson-Sopron',
		'Hajdú-Bihar',
		'Hajdú-Bihar',
		'Heves',
		'Jász-Nagykun-Szolnok',
		'Komárom-Esztergom',
		'Nógrád',
		'Pest',
		'Somogy',
		'Szabolcs-Szatmár-Bereg',
		'Tolna',
		'Vas',
		'Veszprém',
		'Zala',
		'Baja',
		'Dunaújváros',
		'Esztergom',
		'Érd',
		'Hódmezővásárhely',
		'Nagykanizsa',
		'Sopron ',
	],
	Iceland: [
		'Reykjavík',
		'Kópavogur',
		'Seltjarnarnes',
		'Garðabær',
		'Hafnarfjörður',
		'Mosfellsbær',
		'Kjósarhreppur',
		'Reykjanesbær',
		'Grindavíkurbær',
		'Suðurnesjabær',
		'Vogar',
		'Akranes',
		'Skorradalshreppur',
		'Hvalfjarðarsveit',
		'Borgarbyggð',
		'Grundarfjarðarbær',
		'Helgafellssveit',
		'Stykkishólmur',
		'Eyja- og Miklaholtshreppur',
		'Snæfellsbær',
		'Dalabyggð',
		'Bolungarvík',
		'Ísafjarðarbær',
		'Reykhólahreppur',
		'Tálknafjarðarhreppur',
		'Vesturbyggð',
		'Súðavík',
		'Árneshreppur',
		'Kaldrananeshreppur',
		'Strandabyggð',
		'Skagafjörður',
		'Húnabyggð',
		'Húnaþing vestra',
		'Akrahreppur',
		'Akureyri',
		'Norðurþing',
		'Fjallabyggð',
		'Dalvíkurbyggð',
		'Eyjafjarðarsveit',
		'Hörgársveit',
		'Svalbarðsstrandarhreppur',
		'Grýtubakkahreppur',
		'Skútustaðahreppur',
		'Tjörneshreppur',
		'Þingeyjarsveit',
		'Svalbarðshreppur',
		'Langanesbyggð',
		'Fjarðabyggð',
		'Múlaþing',
		'Vopnafjarðarhreppur',
		'Fljótsdalshreppur',
		'Hornafjörður',
		'Vestmannaeyjar',
		'Árborg',
		'Mýrdalshreppur',
		'Skaftárhreppur',
		'Ásahreppur',
		'Rangárþing eystra',
		'Rangárþing ytra',
		'Hrunamannahreppur',
		'Hveragerði',
		'Ölfus',
		'Grímsnes- og Grafningshreppur',
		'Skeiða- og Gnúpverjahreppur',
		'Bláskógabyggð',
		'Flóahreppur',
	],
	Ireland: ['Connacht', 'Leinster', 'Munster', 'Ulster'],
	Israel: ['Jerusalem', 'North', 'Haifa', 'Center', 'Tel Aviv', 'South', 'Judea and Samaria Area'],
	Italy: [
		'Lombardia',
		'Lazio',
		'Campania',
		'Veneto',
		'Sicilia',
		'Emilia-Romagna',
		'Piemonte',
		'Puglia',
		'Toscana',
		'Calabria',
		'Sardegna',
		'Liguria',
		'Marche',
		'Abruzzo',
		'Friuli-Venezia Giulia',
		'Trentino-Alto Adige',
		'Umbria',
		'Basilicata',
		'Molise',
		"Valle d'Aosta",
	],
	Kosovo: ['Peja', 'Mitrovica', 'Pristhina', 'Gjilan', 'Gjakova', 'Prizren', 'Ferizaj'],
	Latvia: [
		'Ādaži',
		'Aizkraukle',
		'Aluksne',
		'Augšdaugava',
		'Balvi',
		'Bauska',
		'Cēsis',
		'Dobele',
		'Gulbene',
		'Jelgava',
		'Jēkabpils',
		'Ķekava',
		'Krāslava',
		'Kuldīga',
		'Limbaži',
		'Līvāni',
		'Ludza',
		'Madona',
		'Mārupe',
		'Ogre',
		'Olaine',
		'Preiļi',
		'Rēzekne',
		'Ropaži',
		'Salaspils',
		'Saldus',
		'Saulkrasti',
		'Sigulda',
		'Smiltene',
		'South Kurzeme',
		'Talsi',
		'Tukums',
		'Valka',
		'Valmiera',
		'Varakļāni',
		'Ventspils',
	],
	Liechtenstein: ['Liechtenstein'],
	Lithuania: [
		'Alytus',
		'Kaunas',
		'Klaipėda',
		'Marijampolė',
		'Panevėžys',
		'Šiauliai',
		'Tauragė',
		'Telšiai',
		'Utena',
		'Vilnius',
	],
	Luxembourg: [
		'Clervaux',
		'Wiltz',
		'Vianden',
		'Redange',
		'Diekirch',
		'Mersch',
		'Echternach',
		'Capellen',
		'Luxembourg',
		'Grevenmacher',
		'Esch-sur-Alzette',
		'Remich',
	],
	Malta: [
		'Central Region',
		'Gozo Region',
		'Northern Region',
		'South Eastern Region',
		'Southern Region',
	],
	Moldova: [
		'Bălți',
		'Briceni',
		'Dondușeni',
		'Drochia',
		'Edineț',
		'Fălești',
		'Florești',
		'Glodeni',
		'Ocnița',
		'Rîșcani',
		'Sîngerei',
		'Soroca',
		'Chișinău',
		'Anenii Noi',
		'Călărași',
		'Criuleni',
		'Dubăsari',
		'Hîncești',
		'Ialoveni',
		'Nisporeni',
		'Orhei',
		'Rezina',
		'Șoldănești',
		'Strășeni',
		'Tighina',
		'Telenești',
		'Ungheni',
		'Basarabeasca',
		'Cahul',
		'Cantemir',
		'Căușeni',
		'Cimișlia',
		'Leova',
		'Ștefan Vodă',
		'Taraclia',
	],
	Monaco: ['Monaco'],
	Montenegro: [
		'Andrijevica',
		'Bar',
		'Berane',
		'Bijelo Polje',
		'Budva',
		'Cetinje',
		'Danilovgrad',
		'Gusinje',
		'Herceg Novi',
		'Kolašin',
		'Kotor',
		'Mojkovac',
		'Nikšić',
		'Petnjica',
		'Plav',
		'Pljevlja',
		'Plužine',
		'Podgorica',
		'Rožaje',
		'Šavnik',
		'Tivat',
		'Tuzi',
		'Ulcinj',
		'Žabljak',
	],
	Netherlands: [
		'Groningen',
		'Friesland',
		'Drenthe',
		'Overijssel',
		'Flevoland',
		'Gelderland',
		'Utrecht',
		'Noord-Holland',
		'Zuid-Holland',
		'Zeeland',
		'Noord-Brabant',
		'Limburg',
	],
	'North Macedonia': [
		'Berovo ',
		'Češinovo-Obleševo',
		'Delčevo',
		'Karbinci ',
		'Kočani',
		'Makedonska Kamenica',
		'Pehčevo ',
		'Probištip',
		'Štip',
		'Zrnovci',
		'Kratovo',
		'Kriva Palanka',
		'Kumanovo',
		'Lipkovo',
		'Rankovce',
		'Staro Nagoričane',
		'Bitola',
		'Demir Hisar',
		'Dolneni',
		'Krivogaštani',
		'Kruševo',
		'Mogila',
		'Novaci',
		'Prilep',
		'Resen',
		'Bogovinje',
		'Brvenica',
		'Gostivar',
		'Jegunovce',
		'Mavrovo and Rostuša',
		'Tearce',
		'Tetovo',
		'Vrapčište',
		'Želino',
		'Aerodrom',
		'Aračinovo',
		'Butel',
		'Čair',
		'Centar',
		'Čučer-Sandevo',
		'Gazi Baba',
		'Gjorče Petrov',
		'Ilinden',
		'Karpoš',
		'Kisela Voda',
		'Petrovec',
		'Saraj',
		'Sopište',
		'Studeničani',
		'Šuto Orizari',
		'Zelenikovo',
		'Bogdanci',
		'Bosilovo',
		'Gevgelija',
		'Dojran',
		'Konče',
		'Novo Selo',
		'Radoviš',
		'Strumica',
		'Valandovo',
		'Vasilevo',
		'Centar Župa',
		'Debar',
		'Debarca',
		'Kičevo',
		'Makedonski Brod',
		'Ohrid',
		'Plasnica',
		'Struga',
		'Vevčani',
		'Čaška',
		'Demir Kapija',
		'Gradsko',
		'Kavadarci',
		'Rosoman',
		'Sveti Nikole',
		'Lozovo',
		'Veles',
	],
	Norway: [
		'Agder',
		'Innlandet',
		'Møre og Romsdal',
		'Nordland',
		'Oslo',
		'Rogaland',
		'Vestfold og Telemark',
		'Troms og Finnmark',
		'Trøndelag',
		'Vestland',
		'Viken',
	],
	Poland: [
		'Dolnośląskie',
		'Kujawsko-pomorskie',
		'Lubelskie',
		'Lubuskie',
		'Łódzkie',
		'Małopolskie',
		'Mazowieckie',
		'Opolskie',
		'Podkarpackie',
		'Podlaskie',
		'Pomorskie',
		'Śląskie',
		'Świętokrzyskie',
		'Warmińsko-mazurskie',
		'Wielkopolskie',
		'Zachodniopomorskie',
	],
	Portugal: [
		'Lisbon',
		'Leiria',
		'Santarém',
		'Setúbal',
		'Beja',
		'Faro',
		'Évora',
		'Portalegre',
		'Castelo Branco',
		'Guarda',
		'Coimbra',
		'Aveiro',
		'Viseu',
		'Bragança',
		'Vila Real',
		'Porto',
		'Braga',
		'Viana do Castelo',
	],
	Romania: [
		'Alba',
		'Arad',
		'Argeș',
		'Bacău',
		'Bihor',
		'Bistrița-Năsăud',
		'Botoșani',
		'Brașov',
		'Brăila',
		'Bucharest',
		'Buzău',
		'Caraș-Severin',
		'Călărași',
		'Cluj',
		'Constanța',
		'Covasna',
		'Dâmbovița',
		'Dolj',
		'Galați',
		'Giurgiu',
		'Gorj',
		'Harghita',
		'Hunedoara',
		'Ialomița',
		'Iași',
		'Ilfov',
		'Maramureș',
		'Mehedinți',
		'Mureș',
		'Neamț',
		'Olt',
		'Prahova',
		'Satu Mare',
		'Sălaj',
		'Sibiu',
		'Suceava',
		'Teleorman',
		'Timiș',
		'Tulcea',
		'Vaslui',
		'Vâlcea',
		'Vrancea',
	],
	Russia: [
		'Adygea',
		'Bashkorstostan',
		'Buryatia',
		'Altai Republic',
		'Dagestan',
		'Ingushetia',
		'Kabardino-Balkaria',
		'Kalmykia',
		'Karachay-Cherkessia',
		'Karelia',
		'Komi Republic',
		'Mari El',
		'Mordovia',
		'Sakha',
		'North Ossetia-Alania',
		'Tatarstan',
		'Tuva',
		'Udmurtia',
		'Khakassia',
		'Chechnya',
		'Chuvashia',
		'Altai',
		'Krasnodar',
		'Krasnoyarsk',
		'Primorsky',
		'Stavropol',
		'Khabarovsk',
		'Amur',
		'Arkhangelsk',
		'Astrakhan',
		'Belgorod',
		'Bryasnk',
		'Vladmir',
		'Volgograd',
		'Vologda',
		'Voronezh',
		'Ivanovo',
		'Irkutsk',
		'Kaliningrad',
		'Kaluga',
		'Kamchatka',
		'Kemerovo',
		'Kirov',
		'Kostroma',
		'Kurgan',
		'Kursk',
		'Leningrad',
		'Lipetsk',
		'Magadan',
		'Moscow',
		'Murmansk',
		'Nizhny Novogorod',
		'Novgorod',
		'Novosibirsk',
		'Omsk',
		'Orenburg',
		'Oryol',
		'Penza',
		'Perm',
		'Pskov',
		'Rostov',
		'Ryazan',
		'Samara',
		'Saratov',
		'Sakhalin',
		'Sverdlovsk',
		'Smolensk',
		'Tambov',
		'Tver',
		'Tomsk',
		'Tula',
		'Tyumen',
		'Ulyanovsk',
		'Chelyabinsk',
		'Zabaykalsky',
		'Yaroslavi',
		'Moscow',
		'Saint Petersburg',
		'Jewish Autonomous Oblast',
		'Nenets-Mansi Autonomous Okrug-Yugra',
		'Yamalo-Nenets Autonomous Okrug',
	],
	'San Marino': [
		'Acquaviva',
		'Borgo Maggiore',
		'Chiesanuova',
		'City of San Marino',
		'Domagnano',
		'Faetano',
		'Fiorentino',
		'Montegiardino',
		'Serravalle',
	],
	Serbia: [
		'Kolubara',
		'Mačva',
		'Moravica',
		'Pomoravlje',
		'Rasina',
		'Raška',
		'Šumadija',
		'Zlatibor',
		'Bor',
		'Braničevo',
		'Jablanica',
		'Nišava',
		'Pčinja',
		'Pirot',
		'Podunavlje',
		'Toplica',
		'Zaječar',
		'Central Banat',
		'North Bačka',
		'North Banat',
		'South Bačka',
		'South Banat',
		'Srem',
		'West Bačka',
		'Kosovo',
		'Kosovo-Pomoravlje',
		'Kosovska Mitrovica',
		'Peć',
		'Prizren',
	],
	Slovakia: [
		'Bratislava',
		'Trnava',
		'Trenčín',
		'Nitra',
		'Žilina',
		'Banská Bystrica',
		'Prešov',
		'Košice',
	],
	Slovenia: [
		'Ajdovščina',
		'Ankaran',
		'Apače',
		'Beltinci',
		'Benedikt',
		'Bistrica ob Sotli',
		'Bled',
		'Bloke',
		'Bohinj',
		'Borovnica',
		'Bovec',
		'Braslovče',
		'Brda',
		'Brezovica',
		'Brežice',
		'Cankova',
		'Celje',
		'Cerklje na Gorenjskem',
		'Cerknica',
		'Cerkno',
		'Cerkvenjak',
		'Cirkulane',
		'Črenšovci',
		'Črna na Koroškem',
		'Črnomelj',
		'Destrnik',
		'Divača',
		'Dobje',
		'Dobrepolje',
		'Dobrna',
		'Dobrova–Polhov Gradec',
		'Dobrovnik',
		'Dol pri Ljubljani',
		'Dolenjske Toplice',
		'Domžale',
		'Dornava',
		'Dravograd',
		'Duplek',
		'Gorenja Vas–Poljane',
		'Gorišnica',
		'Gorje',
		'Gornja Radgona',
		'Gornji Grad',
		'Gornji Petrovci',
		'Grad',
		'Grosuplje',
		'Hajdina',
		'Hoče-Slivnica',
		'Hodoš',
		'Horjul',
		'Hrastnik',
		'Hrpelje-Kozina',
		'Idrija',
		'Ig',
		'Ilirska Bistrica',
		'Ivančna Gorica',
		'Izola',
		'Jesenice',
		'Jezersko',
		'Juršinci',
		'Kamnik',
		'Kanal ob Soči',
		'Kidričevo',
		'Kobarid',
		'Kobilje',
		'Kočevje',
		'Komen',
		'Komenda',
		'Koper',
		'Kostanjevica na Krki',
		'Kostel',
		'Kozje',
		'Kranj',
		'Kranjska Gora',
		'Križevci',
		'Krško',
		'Kungota',
		'Kuzma',
		'Laško',
		'Lenart',
		'Lendava',
		'Litija',
		'Ljubljana',
		'Ljubno',
		'Ljutomer',
		'Log-Dragomer',
		'Logatec',
		'Loška Dolina',
		'Loški Potok',
		'Lovrenc na Pohorju',
		'Luče',
		'Lukovica',
		'Majšperk',
		'Makole',
		'Maribor',
		'Markovci',
		'Medvode',
		'Mengeš',
		'Metlika',
		'Mežica',
		'Miklavž na Dravskem Polju',
		'Miren-Kostanjevica',
		'Mirna',
		'Mirna Peč',
		'Mislinja',
		'Mokronog-Trebelno',
		'Moravče',
		'Moravske Toplice',
		'Mozirje',
		'Murska Sobota',
		'Muta',
		'Naklo',
		'Nazarje',
		'Nova Gorica',
		'Novo Mesto',
		'Odranci',
		'Oplotnica',
		'Ormož',
		'Osilnica',
		'Pesnica',
		'Piran',
		'Pivka',
		'Podčetrtek',
		'Podlehnik',
		'Podvelka',
		'Poljčane',
		'Polzela',
		'Postojna',
		'Prebold',
		'Preddvor',
		'Prevalje',
		'Ptuj',
		'Puconci',
		'Rače-Fram',
		'Radeče',
		'Radenci',
		'Radlje ob Dravi',
		'Radovljica',
		'Ravne na Koroškem',
		'Razkrižje',
		'Rečica ob Savinji',
		'Renče–Vogrsko',
		'Ribnica',
		'Ribnica na Pohorju',
		'Rogaška Slatina',
		'Rogašovci',
		'Rogatec',
		'Ruše',
		'Šalovci',
		'Selnica ob Dravi',
		'Semič',
		'Šempeter-Vrtojba',
		'Šenčur',
		'Šentilj',
		'Šentjernej',
		'Šentjur',
		'Šentrupert',
		'Sevnica',
		'Sežana',
		'Škocjan',
		'Škofja Loka',
		'Škofljica',
		'Slovenj Gradec',
		'Slovenska Bistrica',
		'Slovenske Konjice',
		'Šmarje pri Jelšah',
		'Šmarješke Toplice',
		'Šmartno ob Paki',
		'Šmartno pri Litiji',
		'Sodražica',
		'Solčava',
		'Šoštanj',
		'Središče ob Dravi',
		'Starše',
		'Štore',
		'Straža',
		'Sveta Ana',
		'Sveta Trojica v Slovenskih Goricah',
		'Sveti Andraž v Slovenskih Goricah',
		'Sveti Jurij ob Ščavnici',
		'Sveti Jurij v Slovenskih Goricah',
		'Sveti Tomaž',
		'Tabor',
		'Tišina',
		'Tolmin',
		'Trbovlje',
		'Trebnje',
		'Trnovska Vas',
		'Trzin',
		'Tržič',
		'Turnišče',
		'Velenje',
		'Velika Polana',
		'Velike Lašče',
		'Veržej',
		'Videm',
		'Vipava',
		'Vitanje',
		'Vodice',
		'Vojnik',
		'Vransko',
		'Vrhnika',
		'Vuzenica',
		'Zagorje ob Savi',
		'Žalec',
		'Zavrč',
		'Železniki',
		'Žetale',
		'Žiri',
		'Žirovnica',
		'Zreče',
		'Žužemberk',
	],
	Spain: [
		'Andalusia',
		'Aragon',
		'Asturias',
		'Belearic Islands',
		'Basque Country',
		'Canary Islands',
		'Cantabria',
		'Castilla-La Mancha',
		'Castile and León',
		'Catalonia',
		'Extremadura',
		'Galicia',
		'La Rioja',
		'Madrid',
		'Murcia',
		'Navarra',
		'Valencia',
	],
	Sweden: [
		'Blekinge',
		'Dalarna',
		'Gotland',
		'Gävleborg',
		'Halland',
		'Jämtland',
		'Jönköping',
		'Kalmar',
		'Kronoberg',
		'Norrbotten',
		'Skåne',
		'Stockholm',
		'Södermanland',
		'Uppsala',
		'Värmland',
		'Västerbotten',
		'Västernorrland',
		'Västmanland',
		'Västra Götaland',
		'Örebro',
		'Östergötland',
	],
	Switzerland: [
		'Zürich',
		'Bern',
		'Luzern',
		'Uri',
		'Schwyz',
		'Obwalden',
		'Nidwalden',
		'Glarus',
		'Zug',
		'Freiburg',
		'Solothurn',
		'Basel-Stadt',
		'Basel-Landschaft',
		'Schaffhausen',
		'Appenzell Ausserrhoden',
		'Appenzell Innerrhoden',
		'Sankt Gallen',
		'Graubünden',
		'Aargau',
		'Thurgau',
		'Tessin',
		'Waadt',
		'Wallis',
		'Neuenburg',
		'Genf',
		'Jura',
	],
	Turkey: [
		'Adana',
		'Adıyaman',
		'Afyonkarahisar',
		'Ağrı',
		'Amasya',
		'Ankara',
		'Antalya',
		'Artvin',
		'Aydın',
		'Balıkesir',
		'Bilecik',
		'Bingöl',
		'Bitlis',
		'Bolu',
		'Burdur',
		'Bursa',
		'Çanakkale',
		'Çankırı',
		'Çorum',
		'Denizli',
		'Diyarbakır',
		'Edirne',
		'Elazığ',
		'Erzincan',
		'Erzurum',
		'Eskişehir',
		'Gaziantep',
		'Giresun',
		'Gümüşhane',
		'Hakkâri',
		'Hatay',
		'Isparta',
		'Mersin',
		'Istanbul',
		'İzmir',
		'Kars',
		'Kastamonu',
		'Kayseri',
		'Kırklareli',
		'Kırşehir',
		'Kocaeli',
		'Konya',
		'Kütahya',
		'Malatya',
		'Manisa',
		'Kahramanmaraş',
		'Mardin',
		'Muğla',
		'Muş',
		'Nevşehir',
		'Niğde',
		'Ordu',
		'Rize',
		'Sakarya',
		'Samsun',
		'Siirt',
		'Sinop',
		'Sivas',
		'Tekirdağ',
		'Tokat',
		'Trabzon',
		'Tunceli',
		'Şanlıurfa',
		'Uşak',
		'Van',
		'Yozgat',
		'Zonguldak',
		'Aksaray',
		'Bayburt',
		'Karaman',
		'Kırıkkale',
		'Batman',
		'Şırnak',
		'Bartın',
		'Ardahan',
		'Iğdır',
		'Yalova',
		'Karabük',
		'Kilis',
		'Osmaniye',
		'Düzce',
	],
	Ukraine: [
		'Autonomous Republic of Crimea',
		'Vinnytsia',
		'Volyn',
		'Dnipropetrovsk',
		'Donetsk',
		'Zhytomyr',
		'Zakarpattia',
		'Zaporizhia',
		'Ivano-Frankivsk',
		'Kyiv',
		'Kirovohrad',
		'Luhansk',
		'Lviv',
		'Mykolaiv',
		'Odesa',
		'Poltava',
		'Rivne',
		'Sumy',
		'Ternopil',
		'Kharkiv',
		'Kherson',
		'Khmelnytskyi',
		'Cherkasy',
		'Chernivtsi',
		'Chernihiv',
		'Sevastopol',
	],
	'United Kingdom': ['England', 'Scotland', 'Wales', 'Northern Ireland'],
	'United States': [
		'Alabama',
		'Alaska',
		'Arizona',
		'Arkansas',
		'California',
		'Colorado',
		'Connecticut',
		'Delaware',
		'Florida',
		'Georgia',
		'Hawaii',
		'Idaho',
		'Illinois',
		'Indiana',
		'Iowa',
		'Kansas',
		'Kentucky',
		'Louisiana',
		'Maine',
		'Maryland',
		'Massachusetts',
		'Michigan',
		'Minnesota',
		'Mississippi',
		'Missouri',
		'Montana',
		'Nebraska',
		'Nevada',
		'New Hampshire',
		'New Jersey',
		'New Mexico',
		'New York',
		'North Carolina',
		'North Dakota',
		'Ohio',
		'Oklahoma',
		'Oregon',
		'Pennsylvania',
		'Rhode Island',
		'South Carolina',
		'South Dakota',
		'Tennessee',
		'Texas',
		'Utah',
		'Vermont',
		'Virginia',
		'Washington',
		'West Virginia',
		'Wisconsin',
		'Wyoming',
	],
	'Vatican City': ['Vatican City'],
};
export const ALL_COUNTRIES = Object.keys(REGIONS_OF_COUNTRIES);
const COUNTRIES_OF_COMPANIES = {
	1: ALL_COUNTRIES, // company-name: "deecoob Technology GmbH",
	2: ['Germany'], // company-name: "deecoob Service GmbH",
	3: ['Germany'], // company-name: "MPLC Deutschland GmbH",
	4: ['Austria'], // company-name: "AKM",
	5: ['Sweden'], // company-name: "STIM",
	6: ['Australia'], // company-name: "APRA",
	10: ['Netherlands'], // company-name: "BSSS",
	14: ALL_COUNTRIES, // company-name: "deecoob Technology GmbH Admin",
	18: ['United States'], // company-name: "BMI",
	22: ['United States'], // company-name: "ASCAP",
	26: ['United States'], // company-name: "SESAC",
	30: ['Portugal'], // company-name: "SPAUTORES",
	34: ['Italy'], // company-name: "SCF",
	38: ['Belgium'], // company-name: "SABAM",
	42: ['Denmark'], // company-name: "KODA",
	47: ['Israel'], // company-name: "ACUM",
	54: ALL_COUNTRIES, // company-name: "deecoob Monitoring",
	58: ['Germany'], // company-name: "GEMA",
	62: ['Finland'], // company-name: "TEOSTO",
	65: ['Ireland'], // company-name: "IMRO",
	// There are different companies Ids for the same company-name: "SUISA". On dev "SUISA" is 66, on prod "SUISA" is 70.
	70: ['Switzerland', 'Liechtenstein'], // company-name: "SUISA",
	77: ['Germany'], // company-name: "DB",
	81: ALL_COUNTRIES, // company-name: "MYR",
	88: ['Germany'], // company-name: "GEMA CIO",
	93: ['Italy'], // company-name: "MPLC Italia Srl",
	97: ['Switzerland'], // company-name: "MPLC Switzerland GmbH",
	99: ['Germany', 'Sweden'], // company-name: "SPOTIFY",
	104: ALL_COUNTRIES, // company-name: "GEMA-AT",
	109: ['United Kingdom'], // company-name: "PPL-PRS",
};
