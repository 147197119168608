import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { Popup, Icon } from 'semantic-ui-react';
import _ from 'lodash';

import { useTranslateContext } from 'customHooks';
import { LEAFLETS_FOR_COMPANY, USERS_OF_COMPANY } from 'graphql/queries';

import './EventStateInfoEntry.scss';
import { FilterWidgetContext } from 'contexts/FilterWidgetContext';
import stateIcons from 'configurations/stateIcons';

export default ({ data: { users = [], leaflets = [], disabledLeaflets = [], timestamp } }) => {
	/////////// fill incomplete data ////////////////////////////////////////
	let incomplete = leaflets.some(l => !_.has(l, 'name'));
	const allLeaflets = _.get(
		useQuery(LEAFLETS_FOR_COMPANY, { skip: !incomplete }),
		'data.getAllLeafletsForCompany'
	);
	incomplete &&
		allLeaflets &&
		leaflets
			.filter(l => !_.has(l, 'name'))
			.forEach(l => {
				_.assignIn(l, { ...allLeaflets.find(_l => _l.id === l.id) });
			});

	incomplete = users.some(u => !_.has(u, 'firstname'));
	const allUsers = _.get(
		useQuery(USERS_OF_COMPANY, { skip: !incomplete }),
		'data.getAllUsersForCompany'
	);
	incomplete &&
		allUsers &&
		users
			.filter(u => !_.has(u, 'firstname'))
			.forEach(u => {
				_.assignIn(u, { ...allUsers.find(_u => _u.username === u.username) });
			});
	////////////////////////////////////////////////////////////////////////////////////

	const { translate, formatDate } = useTranslateContext();

	const userStr =
		users && users.length !== 0
			? users.map(u => [u.firstName, u.lastName].join(' ')).join(' or ')
			: null;
	const leafletsStr =
		leaflets && leaflets.length !== 0 ? leaflets.map(l => l.name).join(' or ') : null;
	const disabledLeafletsStr =
		disabledLeaflets && disabledLeaflets.length !== 0
			? disabledLeaflets.map(l => l.name).join(' or ')
			: null;

	const {
		event: { setQuickFilterProgress },
	} = useContext(FilterWidgetContext);

	return (
		<span className="EventStateInfoEntry">
			<>
				{leafletsStr && (
					<>
						<br />
						<strong>{translate('filters.info.leaflet')}</strong>: {leafletsStr}
					</>
				)}
				{disabledLeafletsStr && (
					<>
						<br />
						<Popup
							trigger={
								<s className="disabledLeaflets" onClick={() => setQuickFilterProgress(true)}>
									<strong>{translate('filters.info.leaflet')}</strong>: {disabledLeafletsStr}
								</s>
							}
							content={
								<>
									{translate('tooltips.info.entry.leafletsDisabled1')}{' '}
									<Icon name={stateIcons.IN_PROGRESS} />
									{translate('tooltips.info.entry.leafletsDisabled2')}
								</>
							}
							mouseEnterDelay={700}
							position="bottom center"
						/>
					</>
				)}
				{userStr && (
					<>
						<br />
						<strong>{translate('filters.info.lastChangingUser')}</strong>: {userStr}
					</>
				)}
				{timestamp && (timestamp.from || timestamp.to) && (
					<>
						<br />
						<strong>{translate('filters.info.lastChange')}</strong>{' '}
						{!!timestamp.from && !!timestamp.to ? (
							<>
								<strong>{translate('filters.info.between')}</strong> {formatDate(timestamp.from)}{' '}
								<strong>{translate('filters.info.between_and')}</strong> {formatDate(timestamp.to)}
							</>
						) : !!timestamp.from ? (
							<>
								<strong>{translate('filters.info.from')}</strong> {formatDate(timestamp.from)}
							</>
						) : !!timestamp.to ? (
							<>
								<strong>{translate('filters.info.to')}</strong> {formatDate(timestamp.to)}
							</>
						) : (
							<></>
						)}
					</>
				)}
			</>
		</span>
	);
};
