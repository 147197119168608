import React, { useContext } from 'react';
import { Form, Icon, Label, Popup } from 'semantic-ui-react';
import { useQuery } from '@apollo/client';
import _ from 'lodash';

import DateInput from 'components/Base/CustomDateInput'; // closing input when overriding
import { useTranslate } from 'customHooks';
import { LEAFLETS_FOR_COMPANY, USERS_OF_COMPANY } from 'graphql/queries';
import { FilterWidgetContext } from 'contexts/FilterWidgetContext';
import stateIcons from 'configurations/stateIcons';

import './EventStateInfoInput.scss';

const EventStateInfoInput = ({ value, error, onChange }) => {
	const translate = useTranslate();

	const allUsers = _.get(useQuery(USERS_OF_COMPANY), 'data.getAllUsersForCompany');
	const allUsersOptions = !!allUsers
		? allUsers.map(u => ({
				key: u.id,
				value: u.id /* cannot be object, would throw a warning */,
				text: [u.firstName, u.lastName].join(' '),
		  }))
		: [];

	let allLeaflets = _.get(
		useQuery(LEAFLETS_FOR_COMPANY, {
			fetchPolicy: 'network-only',
		}),
		'data.getAllLeafletsForCompany'
	);
	allLeaflets =
		allLeaflets && allLeaflets.filter((l, i) => allLeaflets.findIndex(_l => _l.id === l.id) === i); //remove duplicates
	const allLeafletsOptions = !!allLeaflets
		? allLeaflets.map(leaflet => ({
				key: leaflet.id,
				value: leaflet.id,
				text: leaflet.creator
					? `${leaflet.name} (${leaflet.creator.firstName} ${leaflet.creator.lastName})`
					: leaflet.name,
		  }))
		: [];

	const {
		event: { setQuickFilterProgress, setQuickFilterProgressChanged },
	} = useContext(FilterWidgetContext);

	/// fill in incomplete data /////////////////////
	allLeaflets &&
		!!value.leaflets &&
		value.leaflets
			.filter(l => !_.has(l, 'name'))
			.forEach(l => {
				_.assignIn(l, { ...allLeaflets.find(_l => _l.id === l.id) });
			});
	allUsers &&
		!!value.users &&
		value.users
			.filter(u => !_.has(u, 'firstname'))
			.forEach(u => {
				_.assignIn(u, { ...allUsers.find(_u => _u.username === u.username) });
			});

	const handleChangeUser = userIds =>
		onChange({
			...value,
			users: allUsers.filter(u => userIds.includes(u.id)),
		});
	const handleChangeTimestampFrom = from =>
		onChange({
			...value,
			timestamp: { ...value.timestamp, from },
		});
	const handleChangeTimestampTo = to =>
		onChange({
			...value,
			timestamp: { ...value.timestamp, to },
		});
	const handleChangeLeaflet = leafletIds => {
		onChange({
			...value,
			leaflets: allLeaflets.filter(l => leafletIds.includes(l.id)),
		});
		!!_.get(leafletIds, 'length') && setQuickFilterProgress(true);
	};

	setQuickFilterProgressChanged(isProgress => {
		if (!!_.get(value.leaflets, 'length') && !isProgress) {
			onChange({
				...value,
				disabledLeaflets: value.leaflets,
				leaflets: [],
			});
		} else if (!!_.get(value.disabledLeaflets, 'length') && isProgress) {
			onChange({
				...value,
				leaflets: value.disabledLeaflets,
				disabledLeaflets: [],
			});
		}
	});

	const isEnabledLeaflets = !_.get(value, 'disabledLeaflets.length');

	const _selectProps = {
		multiple: true,
		error,
		search: true,
		scrolling: true,
		clearable: true,
		openOnFocus: true,
		closeOnChange: true,
		deburr: true,
	};

	const _dateProps = {
		fluid: true,
		error,
		closable: true,
		clearable: true,
		clearIcon: <Icon name="remove" color="red" />,
		iconPosition: 'left',
	};

	return (
		<div className="EventStateInfoInput">
			<Form.Group widths="equal">
				<Popup
					trigger={
						<Label className="userIcon">
							<Icon name="clipboard" />
						</Label>
					}
					content={
						isEnabledLeaflets ? (
							<>
								{translate('tooltips.info.filter.leafletsEnabled1')}
								<Icon name={stateIcons.IN_PROGRESS} />
								{translate('tooltips.info.filter.leafletsEnabled2')}
							</>
						) : (
							<>
								{translate('tooltips.info.filter.leafletsDisabled1')}
								<Icon name={stateIcons.IN_PROGRESS} />
								{translate('tooltips.info.filter.leafletsDisabled2')}
							</>
						)
					}
					mouseEnterDelay={700}
					position="bottom center"
				/>
				{isEnabledLeaflets ? (
					<Form.Select
						className="leafletSelect"
						value={value.leaflets.map(l => l.id)}
						placeholder={translate('filters.info.leaflet')}
						options={allLeafletsOptions}
						onChange={(e, target) => handleChangeLeaflet(target.value)}
						data-test="stateInfo-leafletSelect"
						{..._selectProps}
					/>
				) : (
					<Form.Field>
						<Popup
							trigger={
								<div className="disabledLeaflets" onClick={() => setQuickFilterProgress(true)}>
									{value.disabledLeaflets.map(l => l.name).join(', ')}
								</div>
							}
							content={
								<>
									{translate('tooltips.info.filter.leafletsDisabled1')}
									<Icon name={stateIcons.IN_PROGRESS} />
									{translate('tooltips.info.filter.leafletsDisabled2')}
								</>
							}
							mouseEnterDelay={700}
							position="bottom center"
						/>
					</Form.Field>
				)}
			</Form.Group>
			<Form.Group widths="equal">
				<Label className="userIcon">
					<Icon name="user" />
				</Label>

				<Form.Select
					value={value.users.map(u => u.id)}
					placeholder={translate('filters.info.lastChangingUser')}
					options={allUsersOptions}
					onChange={(e, target) => handleChangeUser(target.value)}
					data-test="stateInfo-lastChangingUser"
					{..._selectProps}
				/>
			</Form.Group>

			<Form.Group widths="equal" className="timestamp">
				<Form.Field>
					<DateInput
						value={value.timestamp.from}
						maxDate={value.timestamp.to}
						placeholder={translate('filters.info.from')}
						onChange={_value => handleChangeTimestampFrom(_value)}
						data-test="stateInfo-from"
						{..._dateProps}
					/>
				</Form.Field>
				<Form.Field>
					<DateInput
						value={value.timestamp.to}
						minDate={value.timestamp.from}
						placeholder={translate('filters.info.to')}
						onChange={_value => handleChangeTimestampTo(_value)}
						data-test="stateInfo-to"
						{..._dateProps}
					/>
				</Form.Field>
			</Form.Group>
		</div>
	);
};

export default EventStateInfoInput;
