import React, { useRef } from 'react';
import { useEventListener, useTranslate, useUserContext } from 'customHooks';
import { SelectionBox } from 'components/Base';
import { isSelectableAndUnlocked } from 'utils';

export default ({ selection, onSelectItem, onPreSelectItem, onResetPreSelection, selectAll }) => {
	const hoveredSelector = useRef();
	const translate = useTranslate();
	const { user } = useUserContext();

	useEventListener(
		'keydown',
		({ key }) =>
			key === 'Shift' &&
			hoveredSelector.current &&
			onPreSelectItem(hoveredSelector.current.data, hoveredSelector.current.index, 'bulk')
	);
	useEventListener(
		'keyup',
		({ key }) =>
			key === 'Shift' &&
			hoveredSelector.current &&
			onPreSelectItem(hoveredSelector.current.data, hoveredSelector.current.index, 'item')
	);

	if (!selection) return null;
	return {
		name: 'selectionColumn',
		label: (
			<>
				{selection && (
					<SelectionBox
						className="SelectAllButton"
						data-test="SelectAllButton"
						checked={selectAll.someItemsAreSelected}
						intermediate={selectAll.someItemsAreUnselected}
						selectable="true"
						onClick={() => selectAll.onClick()}
						disabled={selectAll.disabled}
						tooltip={
							!selectAll.someItemsAreSelected
								? translate('actions.selectAll')
								: translate('actions.deselectAll')
						}
					/>
				)}
			</>
		),
		sortable: false,
		hideable: false,
		render: (data, index) => {
			const checked = selection && selection.some(item => data.id === item.id);
			return (
				<>
					{selection && (
						<SelectionBox
							className="SelectButton"
							data-test="SelectButton"
							checked={checked}
							selectable={isSelectableAndUnlocked(data, user?.username) || checked} // unselecting always possible
							onClick={e => onSelectItem(data, index, e.shiftKey ? 'bulk' : 'item')}
							onMouseEnter={e => {
								onPreSelectItem(data, index, e.shiftKey ? 'bulk' : 'item');
								hoveredSelector.current = { data, index };
							}}
							onMouseLeave={() => {
								onResetPreSelection();
								hoveredSelector.current = null;
							}}
						/>
					)}
				</>
			);
		},
		isSticky: true,
	};
};
