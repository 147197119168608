import { useTranslate } from 'customHooks';
import React from 'react';
import { Card, Dimmer, Icon } from 'semantic-ui-react';

import './DraggingDimmer.scss';

export default ({
	children,
	eventData,
	adData,
	onDragStartMoveAd,
	onDragStartCopyAd,
	onDragEndAd,
	onDragStartMoveEvent,
	onDragStartCopyEvent,
	onDragEndEvent,
	moveMode,
	show,
	onDrop,
	onEscape,
}) => {
	const translate = useTranslate();

	const DragCard = ({ header, description1, description2, icon, ...props }) => (
		<Card className="dragCard" {...props}>
			<Card.Content>
				<Card.Header>{header}</Card.Header>
				<Card.Description textAlign="center">
					{description1}
					{description2 && (
						<>
							<br />
							<br />
							{description2}
						</>
					)}
					<br />
					<br />
					<Icon size="massive" name={icon} />
					<br />
					<br />
					<i>{translate('drag.dragThisBox')}</i>
				</Card.Description>
			</Card.Content>
		</Card>
	);

	return (
		<>
			<Dimmer
				className="DraggingDimmer"
				active={show}
				inverted
				page
				onClick={e => e.target.className.includes('DraggingDimmer') && onEscape && onEscape()}>
				{moveMode === 'dragStart' ? (
					<Card.Group className="dragCards">
						<DragCard
							header={translate('drag.moveEventAd', [adData && adData.shownIdx])}
							description1={translate('drag.moveEventAdDescription', [
								<i>{adData && adData.id}</i>,
							])}
							draggable="true"
							icon={'file outline'}
							data-test="moveEventAd"
							{...{ onDragStart: onDragStartMoveAd, onDragEnd: onDragEndAd }}
						/>
						<DragCard
							header={translate('drag.moveEvent')}
							description1={translate('drag.moveEventDescription', [
								<i>{eventData && eventData.id}</i>,
							])}
							description2={translate('drag.allAdsMoved')}
							draggable="true"
							icon={'folder outline'}
							data-test="moveEvent"
							{...{ onDragStart: onDragStartMoveEvent, onDragEnd: onDragEndEvent }}
						/>

						<DragCard
							header={translate('drag.copyEventAd', [adData && adData.shownIdx])}
							description1={translate('drag.copyEventAdDescription', [
								<i>{adData && adData.id}</i>,
							])}
							draggable="true"
							icon={'file'}
							data-test="copyEventAd"
							{...{ onDragStart: onDragStartCopyAd, onDragEnd: onDragEndAd }}
						/>

						<DragCard
							header={translate('drag.copyEvent')}
							description1={translate('drag.copyEventDescription', [
								<i>{eventData && eventData.id}</i>,
							])}
							description2={translate('drag.allAdsCopied')}
							draggable="true"
							icon={'folder'}
							data-test="copyEvent"
							{...{ onDragStart: onDragStartCopyEvent, onDragEnd: onDragEndEvent }}
						/>
					</Card.Group>
				) : (
					<Card.Group onDragOver={e => e.preventDefault()} {...{ onDrop }}>
						<Card className="dropCard" data-test="dropCard">
							<Card.Content>
								<Card.Header>{translate('drag.dropHere')}</Card.Header>
								<Card.Description textAlign="center">
									<br />
									<br />
									<Icon size="massive" name="folder open" />
								</Card.Description>
							</Card.Content>
						</Card>
					</Card.Group>
				)}
			</Dimmer>
			{children}
		</>
	);
};
