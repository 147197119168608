import React from 'react';
import { Button, Popup, Icon } from 'semantic-ui-react';
import { ICONS } from 'config';

import { useTranslate } from 'customHooks';
import classNames from 'classnames';

const ExpandAll = ({ extended, tableKey, ...props }) => {
	const translate = useTranslate();

	return (
		<Popup
			trigger={
				<Button {...props} className={classNames('ExpandAllButton', props?.className)} icon>
					<Icon name={extended ? ICONS.COLLAPSE : ICONS.EXPAND} />
				</Button>
			}
			content={
				<>
					<b>{extended ? translate('actions.collapseAll') : translate('actions.expandAll')}</b>
					<br />
					{tableKey === 'events'
						? translate('tooltips.expandAllButtonEvents')
						: tableKey === 'location-search'
						? translate('tooltips.expandAllButtonLocations')
						: /* tableKey === 'organizer-search' */
						  translate('tooltips.expandAllButtonOrganizers')}
				</>
			}
			position="bottom right"
		/>
	);
};

export default ExpandAll;
