import React, { useState } from 'react';
import { Form, Button, Message } from 'semantic-ui-react';
import { getPubKey, rsaEncrypt } from 'utils';
import { useTranslate } from 'customHooks';
import { useMutation } from '@apollo/client';
import { CHANGE_PASSWORD } from 'graphql/mutations';
import { VALIDATION_REG_EXP, EXCLUSION_REG_EXP } from 'config';
import './ChangePasswordForm.scss';
const helpCenterUrl = process.env.REACT_APP_HELP_CENTER_URL;

const ChangePasswordForm = () => {
	const [newPassword, setNewPassword] = useState('');
	const [oldPassword, setOldPassword] = useState('');
	const [validationPassword, setValidationPassword] = useState('');
	const [baseError, setBaseError] = useState(false);
	const [authError, setAuthError] = useState(false);
	const [ruleError, setRuleError] = useState(false);
	const [changeError, setChangeError] = useState(false);

	const translate = useTranslate();

	const [changePassword, { error: mutationError, data: mutationData }] = useMutation(
		CHANGE_PASSWORD,
		{
			onError(...error) {
				setAuthError(true);
			},
		}
	);

	const handleSubmit = () => {
		validatePassword();
	};
	const validatePassword = () => {
		if (newPassword === oldPassword) setChangeError(true);
		else if (!(VALIDATION_REG_EXP.test(newPassword) && EXCLUSION_REG_EXP.test(newPassword)))
			setRuleError(true);
		validationPassword !== newPassword && setBaseError(true);

		let valError =
			!(VALIDATION_REG_EXP.test(newPassword) && EXCLUSION_REG_EXP.test(newPassword)) ||
			newPassword === oldPassword ||
			validationPassword !== newPassword
				? true
				: false;

		if (valError === false) {
			rsaEncrypt(newPassword, getPubKey)
				.then(async encryptedNew => {
					let encryptOld = await rsaEncrypt(oldPassword, getPubKey);
					return [encryptedNew, encryptOld];
				})
				.then(encrypted =>
					changePassword({
						variables: {
							newPassword: encrypted[0],
							oldPassword: encrypted[1],
						},
					})
				);
			setValidationPassword('');
			setNewPassword('');
			setOldPassword('');
		}
	};

	const resetErrors = () => {
		setBaseError(false);
		setAuthError(false);
		setRuleError(false);
		setChangeError(false);
	};

	const inputLength = [newPassword, validationPassword].every(password => password.length > 11);

	return (
		<div className="ChangePasswordForm group-content">
			<Form onSubmit={handleSubmit} success={!!mutationData} error={!!baseError || !!mutationError}>
				<Message
					info
					className="password-change-message"
					header={translate('resetPassword.passwordChangeHeader')}
					content={translate('resetPassword.passwordChangeMessage', [
						<a href={helpCenterUrl} target="_blank" rel="noopener noreferrer">
							<b>Help Center</b>
						</a>,
					])}
				/>
				<Message
					success
					header="Password Change Successful"
					content={translate('success.passwordChange')}
				/>
				<Message
					error
					header="Validation failed"
					content={
						baseError
							? translate('error.notSameNewPassword')
							: mutationError
							? translate('error.passwordNotValid')
							: null
					}
				/>
				<Form.Input
					required
					error={authError && translate('error.wrongPassword')}
					type="password"
					placeholder={translate('resetPassword.oldPassword')}
					onChange={e => {
						resetErrors();
						setOldPassword(e.target.value);
					}}
					value={oldPassword}
					autoComplete="off"
					maxLength="64"
				/>
				<Form.Input
					required
					error={
						ruleError
							? translate('error.rulePassword')
							: changeError && translate('error.samePassword')
					}
					type="password"
					placeholder={translate('resetPassword.newPassword')}
					onChange={e => {
						resetErrors();
						setNewPassword(e.target.value);
					}}
					value={newPassword}
					autoComplete="off"
					maxLength="64"
				/>

				<Form.Input
					error={baseError && 'passwords are not the same'}
					required
					type="password"
					placeholder={translate('resetPassword.validatePassword')}
					onChange={e => {
						resetErrors();
						setValidationPassword(e.target.value);
					}}
					value={validationPassword}
					autoComplete="off"
					maxLength="64"
				/>

				<div className="tip">{translate('resetPassword.strength')}</div>

				<Button
					fluid
					color="blue"
					disabled={
						!oldPassword || !validationPassword || !newPassword || !inputLength || baseError
					}
					type="submit">
					{translate('resetPassword.setNewPassword')}
				</Button>
			</Form>
		</div>
	);
};

export default ChangePasswordForm;
