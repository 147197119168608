const typePolicies = {
	Event: {
		fields: {
			organizers: {
				// The selected organizer should be always in the first place.
				read: (organizers, { readField }) => {
					const selectedOrganizer = readField('selectedOrganizer');
					return !!selectedOrganizer?.id
						? organizers.sort((a, b) => ({ [a.id]: -1, [b.id]: 1 }[selectedOrganizer.id] || 0))
						: organizers;
				},
			},
			locations: {
				keyFields: false,
			},
			// eslint-disable-next-line
			organizers: {
				keyFields: false,
			},
			customLeaflet: {
				// There are queries requesting customLeaflet with less or more arguments.
				// Replacement with less arguments of same instance in cache would cause unintended updating of some queries.
				// This merge method ensures that the same instance is always looking similar in cache.
				merge: (existing, incoming) => ({
					...(existing || {}),
					...(incoming || {}),
				}),
			},
		},
	},
	Location: {
		keyFields: false,
	},
	Organizer: {
		keyFields: false,
	},
};

export default typePolicies;
