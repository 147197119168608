import React, { useLayoutEffect, useRef, useState } from 'react';
import { useSubscription } from '@apollo/client';
import { subscribeToEventLocking, subscribeToOrganizerLocking } from 'graphql/subscriptions';
import { cloneDeep } from 'lodash';

// updates events by subscription without sending a query again

export default children => props => {
	return <UpdateEventsAfterLoading {...props}>{children}</UpdateEventsAfterLoading>;
};

const UpdateEventsAfterLoading = ({ children, data: defaultData, ...props }) => {
	const dataRef = useRef(); // keep dataRef without reloading component
	const [modifiedData, setModifiedData] = useState(); // setting modifiedData by subscription will reload component

	useLayoutEffect(() => {
		defaultData && modifiedData && setModifiedData(null);
		// eslint-disable-next-line
	}, [defaultData]);

	const getEvents = data =>
		data?.getEvent
			? [data.getEvent]
			: data?.searchEventsBySearchParams
			? data.searchEventsBySearchParams.results
			: [];

	const changeEvents = (eventIds, userLock) => {
		const changedEvents = getEvents(dataRef.current).filter(
			event =>
				eventIds.includes(event.id) &&
				!(
					event?.userLock?.isLocked === userLock?.isLocked &&
					event?.userLock?.user.username === userLock?.user.username
				)
		);

		if (changedEvents.length > 0) {
			changedEvents.forEach(event => {
				event.userLock = userLock;
				event.version++;
			});

			setModifiedData({ ...dataRef.current });
		}
	};

	useSubscription(subscribeToEventLocking, {
		onSubscriptionData: ({
			subscriptionData: {
				data: { eventsChangedByLocking },
			},
		}) => {
			const { eventIds = [], userLock } = eventsChangedByLocking;
			changeEvents(eventIds, userLock);
		},
	});

	useSubscription(subscribeToOrganizerLocking, {
		onSubscriptionData: ({
			subscriptionData: {
				data: { organizersChangedByLocking },
			},
		}) => {
			const { eventIds = [], userLock } = organizersChangedByLocking;
			changeEvents(eventIds, userLock);
		},
	});

	const data = modifiedData || cloneDeep(defaultData);
	dataRef.current = data;
	return children({ data, ...props });
};
