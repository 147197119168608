import React from 'react';

import './FullCenterLayout.scss';

const FullCenterLayout = ({ children }) => (
	<div className="FullCenterLayout">
		<main>{children}</main>
	</div>
);

export default FullCenterLayout;
