import React, {
	useRef,
	useState,
	useLayoutEffect,
	useContext,
	useImperativeHandle,
	forwardRef,
	useCallback,
} from 'react';
import { Table, Column, Row } from 'react-vt-table-deecoob-private';
import AutoSizer from 'react-virtualized-auto-sizer';
import { get } from 'lodash';

import MoreData from './MoreData';
import NoMoreData from './NoMoreData';
import { MeasuredItem, SortIcon } from 'components/Base';
import { noop, isSelectableAndUnlocked } from 'utils';
import {
	useResizeColumns,
	usePrevious,
	useSlowRepeat,
	useSaveHorizontalScroll,
	useSmartAdjust,
	useSelectionColumn,
	useUserContext,
} from 'customHooks';
import { AppContext } from 'contexts/AppContext';

import { TABLE_ROW_HEIGHT, TABLE_HEADER_HEIGHT } from 'config';
import classNames from 'classnames';

import './Vt.scss';

const VTTable = forwardRef(
	(
		{
			data,
			sorting,
			moreDataAvailable,
			selection,
			onReachBottom = noop,
			onChangeToDetailView = noop,
			onSelectItem = noop,
			onPreSelectItem = noop,
			onResetPreSelection = noop,
			onSort,
			onCtrlUp,
			onDragStart,
			onDragOver,
			onDrop,

			// currently set as class because some cells for locations / events might overflow
			tableKey,
			// to set / remember scroll position for different tables
			storageMemoKey,

			renderedFields,
			getRowClassName,
			getRowHeight,

			extended = [],
			selectAll,
		},
		ref
	) => {
		const table = useRef(null);
		const [newScrollOffset, setNewScrollOffset] = useState(0);
		const tableContentHeight = useRef(0);
		const previousExtended = usePrevious(extended) || [];
		const previousDataLength = (!!data && usePrevious(data.length)) || 0;
		const changedHeightIndexRef = useRef(0);

		const selectionColumn = useSelectionColumn({
			selection,
			onSelectItem,
			onPreSelectItem,
			onResetPreSelection,
			selectAll,
		});
		const { user } = useUserContext();

		if (selectionColumn) renderedFields = [selectionColumn, ...renderedFields];

		const _handleResizeColumn = useResizeColumns({ fields: renderedFields, tableKey });

		const { tableMemo } = useContext(AppContext);

		const focusedItem = tableMemo.focusedItem[storageMemoKey];
		const setFocusedItem = value => tableMemo.setFocusedItem(storageMemoKey, value);

		sorting = Array.isArray(sorting) ? sorting : sorting === null ? [] : [sorting];

		const defaultRowHeight = ['location-search', 'events', 'organizer-search'].some(
			e => tableKey && tableKey.includes(e)
		)
			? TABLE_ROW_HEIGHT.DYNAMIC
			: TABLE_ROW_HEIGHT.STATIC;
		const rowHeights = useRef([]);

		const lineHeight = TABLE_ROW_HEIGHT.LINE_HEIGHT; // for expandable cells
		const bottomRowHeight = TABLE_ROW_HEIGHT.BOTTOM_ROW_HEIGHT;

		// if scroll offset is changed more often than every 50ms table is not reloaded.
		const slowKeyDownRepeat = useSlowRepeat(50);
		const handleKeyUp = ({ key }) => {
			if (!!onCtrlUp && key === 'Control') {
				onCtrlUp();
				const header = document.getElementsByClassName('VTHeader')[0];
				header.className = header.className.replace('ctrlDown', ' ').trim();
			}
		};

		const handleKeyDown = ({ key, shiftKey, altKey, ctrlKey }) => {
			if (key === 'Control') {
				const header = document.getElementsByClassName('VTHeader')[0];
				if (!header.className.includes('ctrlDown')) {
					header.className += ' ctrlDown';
				}
			}

			const listInner = parseInt(table.current.listInner.style.height.replace('px', ''));
			const listOuter = parseInt(table.current.listOuter.style.height.replace('px', ''));
			const scrollOffset = table.current.list.state.scrollOffset;

			if (
				(listInner <= listOuter && key !== 'ArrowLeft' && key !== 'ArrowRight') ||
				shiftKey ||
				altKey ||
				ctrlKey
			)
				return;

			const [minHeight, maxHeight] = [0.1, tableContentHeight.current + bottomRowHeight];
			const entryDelta = table.current.getRowHeight();
			const pageDelta = 0.9 * listOuter;
			const horizontalDelta = 20;

			const verticalOffsets = {
				ArrowUp: Math.max(scrollOffset - entryDelta, minHeight),
				ArrowDown: Math.min(scrollOffset + entryDelta, maxHeight),
				PageUp: Math.max(scrollOffset - pageDelta, minHeight),
				PageDown: Math.min(scrollOffset + pageDelta, maxHeight),
				End: maxHeight,
				Home: minHeight,
			};
			const horizontalDeltas = {
				ArrowLeft: -horizontalDelta,
				ArrowRight: horizontalDelta,
			};

			if (verticalOffsets[key]) slowKeyDownRepeat(() => setNewScrollOffset(verticalOffsets[key]));
			else if (horizontalDeltas[key]) {
				table.current.listOuter.scrollLeft += horizontalDeltas[key];
			}
		};

		tableMemo.shallScrollUp() && table.current && table.current.scrollTo(0);

		useLayoutEffect(() => {
			newScrollOffset > 0 && table.current && table.current.scrollTo(newScrollOffset);
		}, [newScrollOffset]);

		useLayoutEffect(() => {
			if (table.current) {
				// have table recalculate height (changed height of rows doesnt trigger recalc automatically)
				table.current.resetAfterIndex(changedHeightIndexRef.current || 0);
			}
			// explicitly extended.length to not cause rerender loops
			// eslint-disable-next-line
		}, [extended.length, changedHeightIndexRef.current, sorting]); // changedHeightIndexRef is needed as dependency although throwing a warning

		// required to restore scroll position for each table, when coming back from another workflow step
		const updateVerticalScroll = table =>
			storageMemoKey && table && table.scrollTo(tableMemo.getScrollPos(storageMemoKey));

		const updateHorizontalScroll = useSaveHorizontalScroll(storageMemoKey);

		const tableCallback = useCallback(_table => {
			table.current = _table;
			updateVerticalScroll(_table);
			updateHorizontalScroll();
			// eslint-disable-next-line
		}, []); // no dependencies needed as triggered by table component itself

		const {
			onSmartAdjust,
			onScroll: onScrollAdjust,
			onResize: onResizeAdjust,
			smartAdjustColumns,
		} = useSmartAdjust(_handleResizeColumn);

		const handleScroll = e => {
			storageMemoKey && tableMemo.setScrollPos(storageMemoKey, e.scrollOffset);
			e.scrollOffset >= tableContentHeight.current && onReachBottom();
			onScrollAdjust();
		};

		const handleResizeColumn = (diff, fieldName, ...vals) => {
			_handleResizeColumn(diff, fieldName, ...vals);
			onResizeAdjust(fieldName);
		};

		const handleSort = (e, sortField) => {
			const isHoldingCtrl = !!e.ctrlKey;
			const field = renderedFields.find(e => e.sortField === sortField);

			if (field.name === 'selectionColumn' || !onSort) return;

			if (field.sortable) {
				tableMemo.setScrollUpFlag();
				onSort(field.sortField, isHoldingCtrl);
			}
		};

		useImperativeHandle(ref, () => {
			return {
				changePreSelect: ({ from, to, type }) => {
					const rows = document.getElementsByClassName('VTRow');

					for (let shownI = 0; shownI < rows.length; shownI++) {
						const row = rows[shownI];
						row.className = row.className
							.replace('preselected', '')
							.replace('predeselected', '')
							.replace('  ', ' ');

						if (type === 'empty') continue;
						if (type === 'add' || type === 'remove') {
							const globalI = row.dataset.rowIndex;
							from <= globalI &&
								to > globalI &&
								(row.className += type === 'add' ? ' preselected' : ' predeselected');
						}
					}
				},
			};
		});

		const computeRowHeights = () => {
			if (!data) return;
			const computeRowHeight = index => {
				if (!!getRowHeight) {
					return getRowHeight(index, data[index]);
				} else if (!extended || !extended.includes(index)) {
					return defaultRowHeight;
				}
				const maxLines = !!renderedFields
					? Math.max(
							1,
							...renderedFields
								.map(f => get(data, `[${index}].${f.arrayPath}.length`))
								.filter(f => Number.isInteger(f))
					  )
					: 1;

				return maxLines * lineHeight + defaultRowHeight - lineHeight;
			};
			let _rowHeights = [];
			let _changedHeightIndex = null;
			for (let i = 0; i < data.length; i++) {
				_rowHeights[i] = computeRowHeight(i);
				if (
					_changedHeightIndex === null &&
					(!rowHeights.current || rowHeights.current[i] !== _rowHeights[i])
				) {
					_changedHeightIndex = i;
				}
			}
			changedHeightIndexRef.current = _changedHeightIndex || 0;
			rowHeights.current = _rowHeights;
		};

		const _getRowHeight = i => (rowHeights.current && rowHeights.current[i]) || defaultRowHeight; // i can be > than in data because of loading content.

		// recompute row heights only if data or extended have changed but before rendering (therefore no useEffect)
		((!!data && data.length !== previousDataLength) ||
			(!!extended && extended.length !== previousExtended.length) ||
			!!getRowHeight) &&
			computeRowHeights();

		const rowRenderer = props => {
			const { index, style, data } = props;

			return index === data.dataList.length - 1 ? (
				<div
					className="onReachBottomRow"
					{...{
						style: {
							...style,
							height: bottomRowHeight + 'px',
							lineHeight: style.height + 'px',
						},
					}}>
					{onReachBottom && moreDataAvailable ? <MoreData /> : <NoMoreData />}
				</div>
			) : (
				// override styling here if required -> like alignment of text
				<Row
					{...props}
					{...{
						style:
							extended.includes(index) || getRowHeight
								? { ...style, height: _getRowHeight(index) + 'px' }
								: style,
					}}
				/>
			);
		};

		return (
			<div onKeyDown={handleKeyDown} onKeyUp={handleKeyUp} tabIndex="0" className="tableWrapper">
				<AutoSizer>
					{({ height, width }) => {
						tableContentHeight.current =
							TABLE_HEADER_HEIGHT +
							rowHeights.current.reduce((prev, curr) => prev + curr, 0) -
							height;
						return (
							<Table
								className={classNames('ui table blue', tableKey)}
								ref={tableCallback}
								width={width}
								height={height}
								data={[...data, {}]}
								rowRenderer={rowRenderer}
								rowHeight={index => _getRowHeight(index)}
								headerHeight={TABLE_HEADER_HEIGHT}
								onScroll={handleScroll}
								overflowWidth={10}
								onResizeColumn={e => handleResizeColumn(e.resizeDiff, e.dataKey, 'relative')}
								onHeaderDoubleClick={(_, { dataKey }) => {
									const field = renderedFields.find(
										renderedField => renderedField.name === dataKey
									);
									onSmartAdjust(field.name);
								}}
								onRowClick={({ shiftKey }, { rowData }) =>
									!shiftKey && focusedItem?.id !== rowData.id && setFocusedItem(rowData)
								}
								headerCellClassName={i => {
									const sorted =
										!!onSort &&
										!!sorting &&
										sorting.some(item => item.field === renderedFields[i]['sortField'])
											? ' sorted'
											: '';

									const smartAdjusted = smartAdjustColumns.some(
										field => field === renderedFields[i]['name']
									)
										? ' smartAdjusted'
										: '';

									return renderedFields[i].name + sorted + smartAdjusted;
								}}
								rowClassName={i =>
									classNames(
										{
											selected: selection?.some(item => data[i].id === item.id),
											focused: focusedItem?.id === data[i].id,
											unselectable: isSelectableAndUnlocked(data[i], user?.username) === false,
											// needed for test as data-test cannot be applied on VTRow
											lockedByMe:
												data[i].userLock?.isLocked &&
												data[i].userLock?.user.username === user?.username,
										},
										getRowClassName && getRowClassName(i, data[i])
									)
								}
								onRowDoubleClick={(e, { rowIndex, rowData }) => {
									const isNewWindow = e.ctrlKey;
									onChangeToDetailView(rowIndex, rowData.id, isNewWindow);
								}}>
								{renderedFields.map(
									({ label, name, icon, sortField, sortable, multipleSort, render, movable }) => {
										return (
											<Column
												label={label}
												dataKey={name}
												key={name}
												columnHeaderCellRenderer={() => {
													return (
														<div
															className={classNames('VTCellContent', {
																true:
																	onSort &&
																	!!sorting &&
																	sorting.some(item => item.field === sortField),
															})}>
															{!icon && (
																<span
																	className="header-label"
																	draggable={movable}
																	onDragStart={e => onDragStart(e, name)}
																	onDragOver={e => onDragOver(e, movable)}
																	onDrop={e => onDrop(e, name)}>
																	<MeasuredItem label={label} />
																</span>
															)}
															<SortIcon
																{...{
																	sortField,
																	sortable,
																	sorting,
																	onSort: handleSort,
																	multipleSort,
																	isHidden: name === 'selectionColumn',
																	icon,
																}}
															/>
														</div>
													);
												}}
												cellRenderer={({ rowData, rowIndex }) => {
													return (
														<div className={'VTCellContent'}>
															<MeasuredItem label={render(rowData, rowIndex)} />
														</div>
													);
												}}
											/>
										);
									}
								)}
							</Table>
						);
					}}
				</AutoSizer>
			</div>
		);
	}
);

export default VTTable;
