import React from 'react';
import { Button, Icon, Popup } from 'semantic-ui-react';

import { useTranslate } from 'customHooks';

const Scrap = ({ ...props }) => {
	const translate = useTranslate();

	return (
		<Popup
			trigger={
				<Button icon labelPosition="right" color="red" {...props}>
					{translate('actions.scrap')}
					<Icon name="trash" />
				</Button>
			}
			content={
				<>
					<b>{translate('tooltips.scrapButtonHeader')}</b>
					<br />
					{translate('tooltips.scrapButton')}
				</>
			}
			on="hover"
			mouseEnterDelay={500}
			disabled={props.disabled}
			position="bottom right"
			closeOnTriggerClick
		/>
	);
};

export default Scrap;
