import { useMutation } from '@apollo/client';
import classNames from 'classnames';
import { useAppContext, useTranslate, useUpdateEvents } from 'customHooks';
import { TO_CUSTOM_LEAFLET } from 'graphql/mutations';
import { EVENTS_COUNT, LEAFLET } from 'graphql/queries';
import React, { useState } from 'react';
import { Button, Modal, Popup } from 'semantic-ui-react';
import { alerts, buildEventsQueryFromFilters } from 'utils';
import './Undo.scss';

const Undo = ({ className, onRefresh = () => {}, disabled }) => {
	const { popUndoList, getUndoList = () => [] } = useAppContext();
	const [open, setOpen] = useState(false);
	const [undoEventsLength, setUndoEventsLength] = useState(null);
	const translate = useTranslate();

	const onCompleted = ({ toLeafletById }) => {
		onRefresh();
		toLeafletById.some(resp => !resp.success && alerts.eventsMoveFailed());
	};

	const onError = () => {
		alerts.failMessageGenerator('undo', 'applied');
	};

	const [toLeafletById, { loading }] = useMutation(TO_CUSTOM_LEAFLET, { onCompleted, onError });

	const { performUndo } = useUpdateEvents({ onRefresh });

	const undoMutation = mutationArray => {
		if (!mutationArray) return;
		if (mutationArray.type === 'updateEvents') {
			performUndo(mutationArray);
		} else {
			const leafletsToRefetch = mutationArray.leafletsToRefetch;

			mutationArray.mutations.map(muta =>
				toLeafletById({
					variables: muta,
					refetchQueries: leafletsToRefetch.flatMap(id => [
						{
							query: LEAFLET,
							variables: {
								searchQuery: buildEventsQueryFromFilters([], { leafletIds: [id] }),
								max: 5,
							},
						},
						{
							query: EVENTS_COUNT,
							variables: {
								max: 0,
								searchQuery: buildEventsQueryFromFilters([], { leafletIds: [id] }),
							},
						},
					]),
				})
			);
		}
	};

	const handleClick = () => {
		undoMutation(popUndoList());
		setOpen(false);
	};

	const numberOfEventsInNextUndo =
		getUndoList().length &&
		[]
			.concat(getUndoList())
			.pop()
			.mutations.map(muta => muta.ids && muta.ids.length)
			.reduce((acc, curr) => acc + curr);

	disabled = disabled || getUndoList().length === 0;

	const UndoButton = () => (
		<Popup
			trigger={
				<Button
					className={classNames('UndoButton', className)}
					data-test="UndoButton"
					icon="undo"
					onClick={() => {
						setOpen(true);
						setUndoEventsLength(numberOfEventsInNextUndo);
					}}
					disabled={disabled}
				/>
			}
			on="hover"
			content={<b>{translate('actions.undo')}</b>}
			position="bottom right"
			closeOnTriggerClick
		/>
	);

	return (
		<Modal
			data-test="UndoModal"
			trigger={<UndoButton />}
			{...{ open }}
			onClose={() => setOpen(false)}>
			<Modal.Header>{translate('messages.undoLastEventMove')}</Modal.Header>

			<Modal.Content>
				<Modal.Description>
					{getUndoAlert({ getUndoList, undoEventsLength, translate })}
				</Modal.Description>
			</Modal.Content>
			<Modal.Actions>
				<Button loading={loading} disabled={loading} onClick={() => setOpen(false)}>
					{translate('actions.cancel')}
				</Button>

				<Button loading={loading} disabled={loading} positive onClick={handleClick}>
					{translate('actions.undo')}
				</Button>
			</Modal.Actions>
		</Modal>
	);
};

const getUndoAlert = ({ getUndoList, undoEventsLength, translate }) => {
	let undoObject = getUndoList();
	undoObject = !!undoObject && !!undoObject.length ? undoObject[undoObject.length - 1] : null;

	if (!undoObject) return '';
	let undoMsg;
	if (undoObject.type === 'updateEvents') {
		switch (undoObject.alertKey) {
			case 'moveAds':
			case 'moveAdsToNew':
				const adsNum1 = undoObject.mutations[0].eventIdsWithAdIds[0].adIds.length;
				const targetId = undoObject.mutations[0].targetEventId;
				const sourceId1 = undoObject.mutations[0].eventIdsWithAdIds[0].eventId;
				undoMsg = translate('messages.undoMoveAds', [adsNum1, sourceId1, targetId]);
				break;
			case 'moveAdsOfEvents':
				const adsNum2 = undoObject.mutations.reduce(
					(sum, mut) => sum + mut.eventIdsWithAdIds[0].adIds.length,
					0
				);
				const fromAd = undoObject.mutations[0].eventIdsWithAdIds[0].eventId;
				undoMsg = translate('messages.undoMoveAdsOfEvents', [adsNum2, fromAd]);
				break;
			case 'copyAds':
				const adsNum3 = undoObject.mutations[0].eventIdsWithAdIds[0].adIds.length;
				const sourceId2 = undoObject.mutations[0].eventIdsWithAdIds[0].eventId;
				undoMsg = translate('messages.undoCopyAds', [adsNum3, sourceId2]);
				break;
			case 'copyAdsToNew':
				const deletedId = undoObject.mutations[1].id;
				undoMsg = translate('messages.undoCopyAdsToNewRemoveAds', [deletedId]);
				break;
			default:
				break;
		}
	} else {
		undoMsg = translate('messages.undoEventMove', [undoEventsLength]);
	}
	return translate('messages.undoLastMoveAction', [undoMsg]);
};

export default Undo;
