import React from 'react';
import { Table } from 'semantic-ui-react';
import { useTranslate, useQueryParams } from 'customHooks';
import { InfoPopup } from 'components/Base';
import classNames from 'classnames';

const EventData = ({
	date,
	customIds,
	admission,
	artists,
	lineup,
	visitorCount,
	types,
	url,
	isCancelled,
	isLocationSameAsOrganizer,
	isShowInput,
	timestamp,
	statusName,
	statusUsername,
	statusLastChangedAt,
	invoiceNumber,
	itemNumber,
	isRenderField = () => true,
}) => {
	const translate = useTranslate();
	const { location: urlLocation } = useQueryParams();

	// input only shown for updateEvent not createEvent
	// for create/update{Entity} contrary to LocationData/OrganizerData:
	// - EventData shows the fields/columns that cant be changed and just grays out the label (isShowInput)
	// - LocationData/OrganizerData simply don't show the fields/columns that cant be changed at all
	const onlyShowAsDisabledForCreate = urlLocation?.pathname?.includes('create')
		? isShowInput
		: false;

	return (
		<Table basic="very" celled>
			<Table.Body>
				{isRenderField('evTimestamp') && !!timestamp && (
					<TRow header={translate('detailView.timestamp')}>{timestamp}</TRow>
				)}

				{isRenderField('evDates') && (
					<TRow header={translate('detailView.date')} dataTest="evData-Date">
						{date}
					</TRow>
				)}
				{isRenderField('evInvoiceNumber') && (
					<TRow
						header={
							isShowInput ? (
								<InfoPopup
									header={translate('detailView.invoiceNumber')}
									content={translate('detailView.invoiceNumberTip')}
									position="bottom center"
								/>
							) : (
								translate('detailView.invoiceNumber')
							)
						}
						dataTest="evData-InvoiceNumber"
						isShowInput={onlyShowAsDisabledForCreate}>
						{invoiceNumber}
					</TRow>
				)}
				{isRenderField('evItemNumber') && (
					<TRow
						header={
							isShowInput ? (
								<InfoPopup
									header={translate('detailView.itemNumber')}
									content={translate('detailView.itemNumberTip')}
									position="bottom center"
								/>
							) : (
								translate('detailView.itemNumber')
							)
						}
						dataTest="evData-ItemNumber"
						isShowInput={onlyShowAsDisabledForCreate}>
						{itemNumber}
					</TRow>
				)}
				{isRenderField('evStatusName') && (
					<TRow
						header={translate('detailView.statusName')}
						dataTest="evData-StatusName"
						isShowInput={onlyShowAsDisabledForCreate}>
						{statusName}
					</TRow>
				)}
				{isRenderField('evStatusUsername') && (
					<TRow
						header={translate('detailView.statusUsername')}
						dataTest="evData-StatusUsername"
						{...{ isShowInput }}>
						{statusUsername}
					</TRow>
				)}
				{isRenderField('evStatusLastChangedAt') && (
					<TRow
						header={translate('detailView.statusLastChangedAt')}
						dataTest="evData-StatusLastChangedAt"
						{...{ isShowInput }}>
						{statusLastChangedAt}
					</TRow>
				)}
				{isRenderField('evCustomerIds') && (
					<TRow
						header={
							<InfoPopup
								header={translate('detailView.evCustomerIds')}
								content={translate('detailView.evCustomerIdsTip')}
								position="bottom center"
							/>
						}
						{...{ isShowInput }}>
						{customIds}
					</TRow>
				)}

				{isRenderField('evAdmission') && (
					<TRow header={translate('detailView.admission')} dataTest="evData-Admission">
						{admission}
					</TRow>
				)}

				{isRenderField('evArtists') && (
					<TRow
						header={translate('detailView.artists')}
						dataTest="evData-Artists"
						{...{ isShowInput }}>
						{artists}
					</TRow>
				)}

				{isRenderField('evLineup') && (
					<TRow
						header={translate('detailView.lineup')}
						dataTest="evData-Lineup"
						{...{ isShowInput }}>
						{lineup}
					</TRow>
				)}

				{isRenderField('evAttendees') && (
					<TRow header={translate('detailView.attendees')} dataTest="evData-Attendees">
						{visitorCount}
					</TRow>
				)}

				{isRenderField('evTags') && (
					<TRow header={translate('detailView.eventType')} dataTest="evData-Tags">
						{types}
					</TRow>
				)}

				{isRenderField('evUrl') && <TRow header={translate('detailView.url')}>{url}</TRow>}

				{isRenderField('evCancelled') && (
					<TRow header={translate('detailView.isCancelled')} {...{ isShowInput }}>
						{isCancelled}
					</TRow>
				)}

				{isRenderField('evLocationEqualsOrganizer') && (
					<TRow header={translate('detailView.isLocationSameAsOrganizer')} {...{ isShowInput }}>
						{isLocationSameAsOrganizer}
					</TRow>
				)}
			</Table.Body>
		</Table>
	);
};

const TRow = ({ header, children, isShowInput, dataTest }) => (
	<Table.Row>
		<Table.Cell className={classNames('first_col', isShowInput && 'NO_INPUT')}>{header}</Table.Cell>
		<Table.Cell data-test={dataTest}>{children}</Table.Cell>
	</Table.Row>
);

export default EventData;
