import React from 'react';
import { Button, Icon } from 'semantic-ui-react';

import { useTranslate } from 'customHooks';

const Keep = ({ ...props }) => {
	const translate = useTranslate();

	return (
		<Button icon labelPosition="right" color="blue" basic {...props}>
			{translate('actions.keep')}
			<Icon name="arrow left" />
		</Button>
	);
};

export default Keep;
