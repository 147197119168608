import React, { useState, useEffect, useRef } from 'react';

import Meslis from 'components/Meslis';
import Table from 'components/Table/Events';
import { LoadingContent, Msg, DimmableWrapper } from 'components/Base';
import EventCarousel from 'components/EventCarousel';
import {
	useTranslate,
	useQueryParams,
	useUndoSelection,
	useAppContext,
	useFetchSize,
} from 'customHooks';
import browserStorage from 'browserStorage';
import { noop, compareObjectsWithProperty } from 'utils';
import classNames from 'classnames';

import './index.scss';
import { NEW } from 'config';

const WorkflowLayer = ({ sourceLeaflets = [], sorting, filters, storageMemoKey, ...props }) => {
	const { loading: loadingMax, fetchSize: max, fetchMore } = useFetchSize(storageMemoKey);

	// assures that dataEvents not null when reloading
	// => dimmer is applied instead of LoadingContent showing table with old data
	// this is important to not lose vertical and horizontal scrolling
	const shownEvents = useRef();

	return (
		<Meslis.Events leafletIds={sourceLeaflets} {...{ sorting, max, filters, skip: loadingMax }}>
			{({
				data: dataEvents = {},
				error: errorEvents,
				refetch: refetchEvents,
				loading: loadingEvents,
			}) => (
				<Meslis.EventsCount leafletIds={sourceLeaflets} {...{ filters }}>
					{({
						data: dataCount = {},
						error: errorCount,
						refetch: refetchCount,
						loading: loadingCount,
					}) => {
						shownEvents.current =
							dataEvents?.searchEventsBySearchParams?.results || shownEvents.current || [];
						return (
							<WorkflowLayerContent
								dataEvents={shownEvents.current}
								totalCount={dataCount?.searchEventsBySearchParams?.totalCount}
								displayedCount={dataEvents?.searchEventsBySearchParams?.totalCount}
								loading={loadingEvents || loadingCount || loadingMax}
								error={errorEvents || errorCount}
								{...{
									refetchEvents,
									refetchCount,
									fetchMore,
									max,
									sorting,
									filters,
									storageMemoKey,
									sourceLeaflets,
									...props,
								}}
							/>
						);
					}}
				</Meslis.EventsCount>
			)}
		</Meslis.Events>
	);
};

const WorkflowLayerContent = ({
	dataEvents,
	totalCount,
	displayedCount,
	loading,
	error,
	refetchEvents,
	refetchCount,
	fetchMore,
	max,

	sourceLeaflets = [],
	sorting,
	shownSorting,
	filters,
	selection,
	onSelectItem,
	onSelectAll,
	onAddBulk,
	onRemoveBulk,
	isSelected,
	onActionCompleted = noop,
	collapsed,
	storageMemoKey,

	setSelection = () => {},
	lastSelections = [],
	forwardSelections = [],

	// to show toolbar in process step even if leaflet is empty
	keepToolbar,
	// to show different noDataMsg for history
	noDataMsg,
	...props
}) => {
	const translate = useTranslate();
	const vtTableRef = useRef();
	const _noDataMsg = noDataMsg ? noDataMsg : translate('messages.noData');

	const [refreshRequired, setRefreshRequired] = useState(false);
	const { hasPendingMoveAction, tableMemo } = useAppContext();

	const focusedItem = tableMemo.focusedItem[storageMemoKey];
	const setFocusedItem = value => tableMemo.setFocusedItem(storageMemoKey, value);

	const { view, pushURL, step } = useQueryParams({
		...(sourceLeaflets.length === 1 &&
			sourceLeaflets[0] !== -1 && { leafletId: sourceLeaflets[0] }),
		view: 'table',
	});

	const [onDetailClickedVersion, setOnDetailClickedVersion] = useState(null);

	useUndoSelection({
		forwardSelections,
		lastSelections,
		setSelection,
		selection,
		sourceLeaflets,
	});

	const joinedSourceLeaflets = sourceLeaflets.join();
	useEffect(() => {
		sourceLeaflets.length && setRefreshRequired(false);
		// eslint-disable-next-line
	}, [joinedSourceLeaflets, sourceLeaflets.length]);

	const handleRequired = () => {
		!refreshRequired && setRefreshRequired(true);
	};

	const handleChangeToTableView = () => pushURL({ currentId: 0, view: 'table' });

	const handleSubscriptionData = ({
		subscriptionData: {
			data: { eventChangedByLeafletIds },
		},
	}) => {
		if (!!eventChangedByLeafletIds && eventChangedByLeafletIds.isSuccessful) {
			eventChangedByLeafletIds.sender !== browserStorage.getUser() && handleRequired();
			refetchCount();
		}
	};

	const TotalCountSubscription = () => {
		// subscribe to changes of big data pool or leaflets
		const _sourceLeaflets = sourceLeaflets.length === 0 ? [NEW] : sourceLeaflets;
		return (
			_sourceLeaflets.some(leaflet => leaflet > 4 || leaflet === -1) && (
				<Meslis.SubscribeToLeaflet
					ids={_sourceLeaflets}
					componentId={1}
					onSubscriptionData={handleSubscriptionData}
				/>
			)
		);
	};

	const NoData = () => (
		<>
			<Msg>{_noDataMsg}</Msg>
			<TotalCountSubscription />
		</>
	);
	if (totalCount === 0 && !loading && displayedCount === 0 && !keepToolbar) return <NoData />;

	const onBulkSelect = (item, index) => {
		focusedItem == null && setFocusedItem(item);

		const currentIndex =
			focusedItem && dataEvents.indexOf(dataEvents.find(item => item.id === focusedItem.id));
		const currentArray =
			currentIndex < index + 1
				? dataEvents.slice(currentIndex, index + 1)
				: dataEvents.slice(index, currentIndex + 1);
		isSelected(item) === false ? onAddBulk(currentArray) : onRemoveBulk(currentArray);
	};

	const handlePreSelectItem = (item, index, modus) => {
		let preSelection;
		if (modus === 'item') {
			preSelection = { from: index, to: index + 1 };
		} else {
			// modus === 'bulk'
			if (focusedItem == null) return;

			const currentIndex =
				focusedItem && dataEvents.indexOf(dataEvents.find(item => item.id === focusedItem.id));
			preSelection =
				currentIndex < index + 1
					? { from: currentIndex, to: index + 1 }
					: { from: index, to: currentIndex + 1 };
		}
		preSelection.type = !isSelected(item) ? 'add' : 'remove';
		const t = vtTableRef.current;
		t && t.changePreSelect(preSelection);
	};

	const handleResetPreSelection = () => {
		const t = vtTableRef.current;
		t && t.changePreSelect({ type: 'empty' });
	};

	const handleSelectItem = (item, index, modus) => {
		lastSelections.length > 20 && lastSelections.shift();
		lastSelections.push(selection);
		forwardSelections = [];

		modus === 'bulk'
			? onBulkSelect(item, index)
			: //modus === 'item'
			  onSelectItem(item);
	};

	const handleRefresh =
		storageMemoKey !== 'history' // no undo and refresh for history
			? () =>
					new Promise(resolve => {
						refetchEvents().then(
							({
								data: {
									searchEventsBySearchParams: { results: data },
								},
							}) => {
								// keep/re-apply selection to events that are still shown
								selection &&
									onSelectAll(
										data.filter(item => selection.some(x => compareObjectsWithProperty(item, x)))
									);
								onActionCompleted();
								resolve();
							}
						);
						setRefreshRequired(false);
					})
			: noop;

	const handleChangeToDetailView = (offset, id, isNewWindow) => {
		const detailOffset = offset > totalCount - 1 ? totalCount - 1 : offset;

		// makes sure the version isnt updated when going into detail view - see MESLIS-5001
		setOnDetailClickedVersion(dataEvents[offset].version);

		pushURL({
			view: 'detail',
			offset: detailOffset,
			currentId: id,
			isNewWindow,
		});
	};

	const handleActionCompleted = item => {
		refetchEvents().then(() => {
			setRefreshRequired(false);
			item ? onActionCompleted(item) : onActionCompleted();
		});
		refetchCount();
	};

	const moreDataAvailable = parseInt(displayedCount) > dataEvents.length;

	const handleReachBottom = () => {
		if (!loading && max < displayedCount) {
			fetchMore();
		}
	};

	const name =
		storageMemoKey !== 'history'
			? step === 'search' || !step
				? // search
				  translate('messages.searchResults', [
						totalCount && totalCount.toLocaleString(),
						dataEvents.length.toLocaleString(),
						!!collapsed ? translate('navigation.events') + ' - ' : '',
				  ])
				: // select / process
				  translate('messages.selectSearchResults', [
						totalCount && totalCount.toLocaleString(),
						dataEvents.length.toLocaleString(),
						selection.length.toLocaleString(),
						!!collapsed && dataEvents[0] && step === 'process'
							? dataEvents[0].customLeaflet.name + ' - '
							: translate('navigation.select') + ' - ',
				  ])
			: // history
			  translate('messages.selectSearchResults', [
					totalCount && totalCount.toLocaleString(),
					dataEvents.length.toLocaleString(),
					selection.length.toLocaleString(),
					translate('navigation.history') + ' - ',
			  ]);

	return !!error ? (
		<div>Error</div>
	) : // show LoadingContent but for dataEvents.length > 0 the dimmer is shown in another component keeping the content, same goes for Detail View
	loading && !dataEvents.length && view !== 'detail' ? (
		<LoadingContent />
	) : (
		<div className="WorkflowLayer">
			<DimmableWrapper dimmed={(loading && view !== 'detail') || hasPendingMoveAction}>
				<div
					className={classNames('Container', {
						focusSet: selection !== undefined && focusedItem != null,
					})}>
					<Table
						{...{
							name,
							moreDataAvailable,
							selection,
							onSelectAll,
							refreshRequired,
							sourceLeaflets,
							storageMemoKey,
							keepToolbar,
							NoData,
							vtTableRef,
							sorting: shownSorting,
							...props,
						}}
						onSelectItem={handleSelectItem}
						onPreSelectItem={handlePreSelectItem}
						onResetPreSelection={handleResetPreSelection}
						onRefresh={handleRefresh}
						showActions
						data={dataEvents}
						onReachBottom={handleReachBottom}
						onActionCompleted={handleActionCompleted}
						onChangeToDetailView={handleChangeToDetailView}
					/>
					{view === 'detail' && (
						<EventCarousel
							{...{
								sourceLeaflets,
								filters,
								sorting,
								refreshRequired,
								onDetailClickedVersion,
								storageMemoKey,
							}}
							totalCount={totalCount}
							onRefresh={handleRefresh}
							onChangeToTableView={handleChangeToTableView}
							onActionCompleted={handleActionCompleted}
						/>
					)}
				</div>
			</DimmableWrapper>
			<TotalCountSubscription />
		</div>
	);
};

export default WorkflowLayer;
