import React, { useState } from 'react';
import { Button, Icon, Popup } from 'semantic-ui-react';
import { CSVLink } from 'react-csv';
import { useMutation, useQuery } from '@apollo/client';

import { useTranslate } from 'customHooks';
import { logClientExport } from 'graphql/mutations';
import { dataToCSV } from 'utils';
import { USERS_OF_COMPANY } from 'graphql/queries';
import { get } from 'lodash';

const CSVExport = ({ data, headers, tableKey, ...props }) => {
	const [CSVData, setCSVData] = useState([]);
	const [CSVHeaders, setCSVHeaders] = useState([]);

	const translate = useTranslate();

	const [logExport] = useMutation(logClientExport);

	// with currently implementation disabled isnt shown because <Msg /> for noData replaces whole view
	// => pre-caution for future changes or bugs
	const disabled = !data || data.length < 1;

	const informBackend = () => {
		const ids = data.map(dataRow => dataRow.id.toString());
		const type = tableKey === 'events' ? 'EVENT' : 'LOCATION';

		logExport({ variables: { type, ids } });
	};

	const allUsers = get(
		useQuery(USERS_OF_COMPANY, { skip: disabled, fetchPolicy: 'cache-first' }),
		'data.getAllUsersForCompany'
	);

	// only format data for CSVLink when clicked, instead of on every rerender
	const handleSetCSVData = async () => {
		const { csvData, csvHeaders } = dataToCSV({ data, headers, tableKey, allUsers });
		setCSVData(csvData);
		setCSVHeaders(csvHeaders);

		informBackend();
	};

	const filename = tableKey.includes('event')
		? 'events.csv'
		: tableKey.includes('location')
		? 'locations.csv'
		: 'organizers.csv';

	return (
		<CSVLink
			data={CSVData}
			headers={CSVHeaders}
			filename={filename}
			onClick={handleSetCSVData}
			separator={','}
			// skip CSVLink when using tab (CSVLink renders as <a> and has different default styling than <Button>) - onClick still normally bubbles up
			tabIndex="-1">
			<Popup
				trigger={
					<Button data-test="csv-Export" icon {...{ disabled, ...props }}>
						<Icon name="download" />
					</Button>
				}
				on="hover"
				content={
					<>
						<b>{translate('actions.csvExport')}</b>
						<br />
						{translate('tooltips.csvExport', [
							tableKey.includes('event')
								? translate('tooltips.csvExportEvents')
								: tableKey.includes('location')
								? translate('tooltips.csvExportLocations')
								: translate('tooltips.csvExportOrganizers'),
						])}
					</>
				}
				position="bottom right"
				closeOnTriggerClick
			/>
		</CSVLink>
	);
};

export default CSVExport;
