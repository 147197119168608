import React from 'react';
import { Message } from 'semantic-ui-react';

const ErrorMessage = props => (
	<Message negative>
		<Message.Header>{props.header || 'Error'}</Message.Header>
		{props.message}
	</Message>
);

export default ErrorMessage;
