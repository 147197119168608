import React, { useState } from 'react';
import { Button, Segment, Popup, Label, Icon } from 'semantic-ui-react';

import Meslis from 'components/Meslis';
import useQueryParams from 'customHooks/useQueryParams';
import { ToggleIcon } from 'components/Base';

import Footer from './Footer';
import Header from './Header';
import PreviewList from './PreviewList';
import InfoBox from './InfoBox';
import ClearModal from './ClearModal';

import { useMessagesContext, useTranslateContext } from 'customHooks';
import { CONFLICT, BASKET, SHELF } from 'config';
import classNames from 'classnames';

import './index.scss';

const LeafletBox = ({
	showInfo,
	showList,
	showClear,
	showEdit,
	showExport,
	isLeafletItem,
	isCollapsed,

	leafletData,
	selection,
	leafletId,
	editableLeaflet,

	viewLabel,
	rerouteViewButtonTo,

	sorting,
	filters,
	offset,
	onActionCompleted = (a, b) => {},
	onTableActionCompleted = () => {},
	onOpenCollapse,

	index,
}) => {
	const { pushURL } = useQueryParams();
	const [open, setOpen] = useState(false);
	const [isChanged, setIsChanged] = useState(false);

	const { translate, formatDate } = useTranslateContext();

	// toggle open first 5 elements
	const handleToggleDropdown = () => {
		setOpen(!open);
	};

	const handleViewLeaflet = () => {
		pushURL({
			view: 'table',
			step: 'process',
			leafletId: leafletData ? leafletData.id : [],
			...(rerouteViewButtonTo && { rerouteTo: rerouteViewButtonTo }),
		});
		onTableActionCompleted();
	};

	const handleEditLeaflet = (item, count) => {
		onActionCompleted(item, count);
	};

	let isCurrent =
		(leafletId && leafletId === leafletData.id) ||
		(!!editableLeaflet && editableLeaflet.leaflet.id === leafletData.id);

	const isConflict = leafletData.id === CONFLICT;
	showClear = showClear && !isConflict;

	const handleChange = count => {
		!isChanged && setIsChanged(true);
		isCurrent && onActionCompleted(leafletData, count.data.searchEventsBySearchParams.totalCount);
		setTimeout(() => {
			setIsChanged(false);
		}, 3000);
	};

	const handleLeafletBoxClick = e =>
		!!onOpenCollapse &&
		window.getSelection().type !== 'Range' &&
		!['button', 'icon'].some(noClass => e.target.className.includes(noClass)) &&
		!e.target.closest('.modal') &&
		onOpenCollapse();

	const { newLeafletIds } = useMessagesContext();

	const isNew = leafletData && newLeafletIds && newLeafletIds.includes(leafletData.id);

	viewLabel = viewLabel || translate('leaflets.view');

	return (
		<div
			className="LeafletBox"
			data-test={typeof index === 'number' ? `LeafletBox${index}` : null}
			onClick={handleLeafletBoxClick}>
			<Segment
				className={classNames({ current: isCurrent, conflict: isConflict })}
				color={isCurrent ? 'blue' : 'grey'}>
				<Meslis.EventsCount leafletIds={[leafletData.id]}>
					{({
						data: dataCount = {},
						error: errorCount,
						refetch: refetchCount,
						loading: loadingCount,
					}) => {
						if (errorCount) {
							// console.error(errorCount);
							return <div>Error</div>;
						}

						const handleSubscriptionData = ({
							subscriptionData: {
								data: { eventChangedByLeafletIds },
							},
						}) => {
							if (!!eventChangedByLeafletIds && eventChangedByLeafletIds.isSuccessful) {
								refetchCount().then(data => handleChange(data));
							}
						};

						const TotalCountSubscription = () =>
							![BASKET, SHELF].includes(leafletData.id) ? (
								// Subscribe to changes from me and other users.
								// Don't subscribe to basket or shelf because having same id for every user.
								// See components/Base/Actions.js for update of basket and shelf.
								<Meslis.SubscribeToLeaflet
									ids={[leafletData.id]}
									componentId={0}
									onSubscriptionData={handleSubscriptionData}
								/>
							) : null;

						const totalCount =
							(dataCount &&
								dataCount.searchEventsBySearchParams &&
								dataCount.searchEventsBySearchParams.totalCount) ||
							0;

						return (
							<span className={classNames({ filledLeaflet: totalCount > 0 })}>
								{loadingCount ? (
									<span>{translate('placeholders.loadingDots')}</span>
								) : (
									<Header
										{...{ leafletData, leafletId, isChanged, isNew, totalCount, isCollapsed }}
									/>
								)}

								{leafletData.name.length > 24 ? (
									<Popup
										trigger={<h3 className="leafletDescription">{leafletData.name}</h3>}
										content={leafletData.name}
										position="top left"
										basic
									/>
								) : (
									<h3 className="leafletDescription">{leafletData.name}</h3>
								)}
								{!isCollapsed && (
									<>
										{showList && totalCount !== 0 && (
											<>
												<ToggleIcon open={open} onToggle={handleToggleDropdown} />
												{!open && <hr />}
												{open && (
													<PreviewList
														{...{ leafletData, refetchCount, isChanged, filters, sorting }}
													/>
												)}
											</>
										)}
										{showInfo && <InfoBox {...{ leafletData }} />}
										<div className="footer">
											<Button
												onClick={handleViewLeaflet}
												disabled={!totalCount}
												data-test="viewLeafletButton">
												{viewLabel}
											</Button>
											{showClear && (
												<ClearModal
													{...{ sorting, offset, leafletData }}
													disabled={totalCount === 0}
												/>
											)}
											<Footer
												toEditLeaflet={handleEditLeaflet}
												data={totalCount}
												{...{ leafletData, showExport, showEdit, selection }}
											/>
										</div>

										{(!!leafletData.createdAt || !!leafletData.updatedAt) &&
											// don't show dates for legacy leaflets
											![BASKET, SHELF, CONFLICT].some(id => id === leafletData.id) && (
												<div className={classNames('createdAt', { noBox: isLeafletItem })}>
													{leafletData.createdAt && (
														<Label className={'created'} size="tiny" basic>
															<Icon name="file" />
															{translate('leaflets.created')} {formatDate(leafletData.createdAt)}
														</Label>
													)}
													{leafletData.updatedAt && (
														<Label className={'updated'} size="tiny" basic>
															<Icon name="edit" />
															{translate('leaflets.edited')} {formatDate(leafletData.updatedAt)}
														</Label>
													)}
												</div>
											)}
									</>
								)}
								{!loadingCount && <TotalCountSubscription />}
							</span>
						);
					}}
				</Meslis.EventsCount>
			</Segment>
		</div>
	);
};

export default LeafletBox;
