import React from 'react';

import useFilterConfig from 'components/FilterWidget/useFilterConfig';
import GenericEntry from './GenericEntry';

const BooleanEntry = ({ data, onChangeValue }) => {
	const { boolean } = useFilterConfig();

	return <GenericEntry {...{ data, onChangeValue }} type="Boolean" options={boolean} />;
};

export default BooleanEntry;
