import { Query } from '@apollo/client/react/components';
import { EVENTS_DETAIL } from 'graphql/queries';
import React from 'react';
import { buildEventsQueryFromFilters } from 'utils';
import { useUpdateEventsAfterLoading } from 'customHooks';

const EventsDetail = ({
	children,
	max = 100,
	offset = 0,
	filters = [],
	sorting: sortings = [],
	leafletIds = [],
	...props
}) => {
	const variables = {
		max,
		sortings,
		offset,
		searchQuery: buildEventsQueryFromFilters(filters, { leafletIds }),
	};
	const updatingChildren = useUpdateEventsAfterLoading(children);
	return (
		<Query query={EVENTS_DETAIL} fetchPolicy="network-only" variables={variables} {...props}>
			{updatingChildren}
		</Query>
	);
};
export default EventsDetail;
