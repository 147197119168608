import React from 'react';
import { useTranslate } from 'customHooks';

const conditionalDisplay = (item, descriptor) =>
	!!item ? (
		<>
			<strong>{descriptor} </strong>
			<span>{item} </span>
		</>
	) : null;

const NumberRangeEntry = ({ data }) => {
	const translate = useTranslate();
	return (
		<span data-test="RangeEntry">
			{conditionalDisplay(data.from, translate('filters.range.from'))}
			{conditionalDisplay(data.to, translate('filters.range.to'))}
		</span>
	);
};

export default NumberRangeEntry;
