import React from 'react';

import useFilterConfig from 'components/FilterWidget/useFilterConfig';
import GenericSelect from './GenericSelect';
import { Message } from 'semantic-ui-react';

const EntityStatusListSelect = ({ value, error, onChange, type }) => {
	const { eventStatuses, locationStatuses, organizerStatuses } = useFilterConfig();

	const statuses = {
		event: eventStatuses,
		location: locationStatuses,
		organizer: organizerStatuses,
	}[type];

	if (!statuses || !statuses?.length) {
		return (
			<Message
				color="yellow"
				header="Not supported"
				content="There are no statuses available for your company"
			/>
		);
	}

	return (
		<GenericSelect
			options={statuses}
			allowedAssociations={['or', 'exists']}
			allowMultiple={true}
			{...{ value, error, onChange }}
		/>
	);
};

export default EntityStatusListSelect;
