import React from 'react';
import { Icon } from 'semantic-ui-react';
import { InfoUser } from '.';

import './CollaborationIcons.scss';

export default ({ userLock, viewingUsers }) =>
	(userLock && userLock.isLocked) || !!viewingUsers.length ? (
		<>
			<Icon name="lock" className="viewingStatus" />{' '}
			{<InfoUser username={userLock.user.username} />}
			{viewingUsers &&
				viewingUsers.map((viewingUser, idx) => (
					<span key={idx}>
						<Icon name="eye" className="viewingStatus" />{' '}
						<InfoUser username={viewingUser.username} />
					</span>
				))}
		</>
	) : (
		<Icon name="lock open" className="viewingStatus" />
	);
