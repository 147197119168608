import React, { useEffect } from 'react';
import { get, uniq } from 'lodash';

import {
	PriceList,
	Rating,
	InfoPopup,
	Collaboration,
	InfoUser,
	StateInfo,
	RedirectToStepSelect,
} from 'components/Base';
import Generic, { configField } from './Generic';
import { useItemArray, useTranslateContext, useUserContext } from 'customHooks';
import {
	renderIdAndOldIds,
	isSelectableAndUnlocked,
	buildStreetHouseNumberFromAddress,
} from 'utils';
import { ICONS } from 'config';
const { EVENT_MUSIC, LOCATION, ORGANIZER, STATE_INFO, COLLABORATION } = ICONS;

const Events = ({
	config = configEvents,
	data = [],
	genericID,
	genericType,
	onChangeToDetailView,
	onActionCompleted,
	sourceLeaflets = [],
	selection,
	onSelectAll,
	onDeselectAll,

	onRefresh,
	refreshRequired,
	lockingConfig,
	storageMemoKey,
	buttons = {},
	...props
}) => {
	const { translate, formatTimeStamp } = useTranslateContext();
	const { user } = useUserContext();

	const { renderItemArray, extended, toggleExpandAll, closeAll } = useItemArray(data);

	// close all extendable events on rerender with new data to avoid overlapping rows
	useEffect(() => {
		/* avoid unnecessary rerender */
		if (extended.length !== 0) closeAll();
		// eslint-disable-next-line
	}, [data]);

	const fields = returnFields({
		translate,
		formatTimeStamp,
		renderItemArray,
	});

	const disableButtons = data.length === 0;
	const someItemsAreSelected = selection && selection.length >= 1;
	const someItemsAreUnselected =
		selection &&
		data &&
		data.filter(item => isSelectableAndUnlocked(item, user?.username)).length !== selection.length;
	const toggleSelectAll = () => (someItemsAreSelected ? onDeselectAll() : onSelectAll(data));

	// renew event instances of selection in order to have correct userLock
	selection = !!data
		? selection?.map(_ev => data.find(ev => _ev.id === ev.id)).filter(ev => !!ev)
		: selection;

	buttons = {
		...(selection && {
			actions: {
				sourceLeaflets,
				events: selection,
				onCompleted: onActionCompleted,
			},
			undo: {
				disabled: storageMemoKey === 'history',
				onRefresh,
			},
			refresh: { disabled: !refreshRequired || storageMemoKey === 'history', onRefresh },
			merge: { events: selection, onRefresh },
			lock: !!lockingConfig ? { ...lockingConfig, isOnly: data?.length === 0 } : undefined, // showing lock button for organizer even if having no event data
			bulkEdit: true,
		}),
		createNewEvent: { genericID, genericType, isOnly: data.length === 0 }, // can always create a new event no matter how many events currently in organizer
		...buttons,
	};

	return (
		<Generic
			{...{
				data,
				buttons,
				selection,
				onChangeToDetailView,
				toggleExpandAll,
				extended,
				selectAll: {
					onClick: toggleSelectAll,
					disabled: disableButtons,
					someItemsAreSelected,
					someItemsAreUnselected,
				},
				storageMemoKey,
				...props,
			}}
			// only pass fields that are defined in the relevant config array
			fields={fields.filter(x => config.listedFields.includes(x.name))}
			defaultHiddenFields={config.defaultHiddenFields}
			tableKey="events"
		/>
	);
};

const organizerParams = { selectedIdPath: 'selectedOrganizer.id', idPath: 'id' };

// outside of component to reduce scrolling needed when changing props/logic of actual component
const returnFields = ({ translate, formatTimeStamp, renderItemArray }) => [
	configField({
		listIcon: COLLABORATION,
		name: 'collaboration',
		label: translate('tableView.infoCollaboration'),
		icon: <Collaboration entity="event" heading={true} />,
		sortable: false,
		movable: false,
		render: data => <Collaboration entity="event" userLock={data.userLock} />,
	}),
	configField({
		listIcon: STATE_INFO,
		name: 'stateInfo',
		label: translate('tableView.infoState'),
		icon: <StateInfo heading={true} />,
		sortable: true,
		sortField: 'state',
		movable: false,
		render: data => (
			<StateInfo
				state={data.state}
				leafletName={get(data, 'customLeaflet.name')}
				changingUser={get(data, 'customLeaflet.username')}
				timestamp={get(data, 'customLeaflet.timestamp')}
				countOfExported={data.countOfExported}
			/>
		),
	}),
	configField({
		listIcon: STATE_INFO,
		name: 'customLeaflet.name',
		label: translate('tableView.infoLeaflet'),
		sortable: true,
	}),
	configField({
		listIcon: STATE_INFO,
		name: 'customLeaflet.username',
		label: (
			<InfoPopup
				header={translate('tableView.infoLastChangingUser')}
				content={translate('tableView.infoLastChangingUserTip')}
			/>
		),
		sortable: true,
		render: ({ customLeaflet } = {}) => (
			<InfoUser username={customLeaflet && customLeaflet.username} />
		),
	}),
	configField({
		listIcon: STATE_INFO,
		name: 'customLeaflet.timestamp',
		label: (
			<InfoPopup
				header={translate('tableView.infoLastAction')}
				content={translate('tableView.infoLastActionTip')}
			/>
		),
		sortable: true,
		render: ({ customLeaflet } = {}) =>
			customLeaflet?.timestamp && formatTimeStamp(customLeaflet.timestamp, true),
	}),
	configField({
		listIcon: EVENT_MUSIC,
		name: 'id',
		label: (
			<InfoPopup header={translate('tableView.evId')} content={translate('tableView.evIdTip')} />
		),
		sortable: true,
		render: data =>
			renderIdAndOldIds({
				data,
				idField: 'id',
				filterField: 'id',
				oldIdsField: 'oldIds',
				message: translate('tableView.evOldIds'),
			}),
	}),
	configField({
		listIcon: EVENT_MUSIC,
		name: 'advertisements.id',
		label: translate('tableView.evAdvertisementIds'),
		render: data => {
			return data.advertisements && data.advertisements.results.map(ad => ad.id).join(', ');
		},
	}),
	configField({
		listIcon: EVENT_MUSIC,
		name: 'customer.events',
		label: (
			<InfoPopup
				header={translate('tableView.evCustomerIds')}
				content={translate('tableView.evCustomerIdsTip')}
			/>
		),
		render: data =>
			!!data?.customer?.events &&
			data.customer.events
				.map(ev => ev.id + (!!ev.processingStatus ? ` (${ev.processingStatus})` : ''))
				.join(', '),
	}),

	configField({
		listIcon: EVENT_MUSIC,
		name: 'name',
		label: translate('tableView.evName'),
		sortable: true,
	}),
	configField({
		listIcon: EVENT_MUSIC,
		name: 'startDate',
		label: translate('tableView.evStartDate'),
		sortable: true,
		render: data => formatTimeStamp(data.startDate),
	}),
	configField({
		listIcon: EVENT_MUSIC,
		name: 'endDate',
		label: translate('tableView.evEndDate'),
		sortable: true,
		render: data => formatTimeStamp(data.endDate),
	}),
	configField({
		listIcon: EVENT_MUSIC,
		name: 'admission',
		label: translate('tableView.evAdmission'),
		sortable: true,
		sortField: 'admission.price',
		render: data => <PriceList data={data.admission} />,
	}),
	configField({
		listIcon: EVENT_MUSIC,
		name: 'types',
		label: translate('tableView.evType'),
		sortable: true,
	}),
	configField({
		listIcon: EVENT_MUSIC,
		name: 'rating',
		label: translate('tableView.evRelevance'),
		sortable: true,
		render: data => <Rating value={data.rating} />,
	}),
	configField({
		listIcon: EVENT_MUSIC,
		name: 'setlists',
		label: translate('tableView.evSetlists'),
		render: data => data.setlists && data.setlists.totalCount > 0 && 'Available',
	}),
	configField({
		listIcon: EVENT_MUSIC,
		name: 'artists',
		label: (
			<InfoPopup
				header={translate('tableView.evArtist')}
				content={translate('tableView.evArtistTip')}
			/>
		),
		sortable: true,
		sortField: 'artists.score',
		render: data =>
			data.artists &&
			data.artists
				.map(artist => (artist.score ? `${artist.name} (${artist.score})` : artist.name))
				.join(', '),
	}),
	configField({
		listIcon: EVENT_MUSIC,
		name: 'lineup',
		label: (
			<InfoPopup
				header={translate('tableView.evLineup')}
				content={translate('tableView.evLineupTip')}
			/>
		),
		sortable: true,
	}),
	configField({
		listIcon: EVENT_MUSIC,
		name: 'visitorCount',
		label: translate('tableView.evAttendees'),
		sortable: true,
	}),
	configField({
		listIcon: EVENT_MUSIC,
		name: 'url',
		label: translate('tableView.evURL'),
		render: data => data.advertisements && data.advertisements.results.map(ad => ad.url).join(', '),
	}),
	configField({
		listIcon: EVENT_MUSIC,
		name: 'timestamp',
		label: (
			<InfoPopup
				header={translate('tableView.evTimestamp')}
				content={translate('tableView.evTimestampTip')}
			/>
		),
		sortable: true,
		render: data => formatTimeStamp(data.timestamp, true),
	}),
	configField({
		listIcon: EVENT_MUSIC,
		name: 'isCancelled',
		label: translate('tableView.evIsCancelled'),
		sortable: true,
		render: data =>
			data.isCancelled
				? translate('filters.boolean.true')
				: data.isCancelled === false && translate('filters.boolean.false'),
	}),
	configField({
		listIcon: EVENT_MUSIC,
		name: 'isLocationSameAsOrganizer',
		label: translate('tableView.evIsLocationSameAsOrganizer'),
		sortable: true,
		render: data =>
			data.isLocationSameAsOrganizer
				? translate('filters.boolean.true')
				: data.isLocationSameAsOrganizer === false && translate('filters.boolean.false'),
	}),
	configField({
		listIcon: EVENT_MUSIC,
		name: 'advertisements.totalCount',
		label: translate('tableView.evAdCount'),
		sortable: true,
	}),
	configField({
		listIcon: EVENT_MUSIC,
		name: 'organizersCount',
		label: translate('tableView.evOrgCount'),
		sortable: true,
	}),
	configField({
		listIcon: EVENT_MUSIC,
		name: 'selectedOrganizer.id',
		label: translate('tableView.evSelectedOrganizer'),
		sortable: true,
	}),
	configField({
		listIcon: EVENT_MUSIC,
		name: 'eventStatus.name',
		sortField: 'status.name',
		label: translate('tableView.evStatusName'),
		sortable: true,
	}),
	configField({
		listIcon: EVENT_MUSIC,
		name: 'eventStatus.user.username',
		sortField: 'status.username',
		label: translate('tableView.evStatusUsername'),
		sortable: true,
		render: ({ eventStatus }) => <InfoUser username={eventStatus?.user?.username} />,
	}),
	configField({
		listIcon: EVENT_MUSIC,
		name: 'eventStatus.lastChangedAt',
		sortField: 'status.lastChangedAt',
		label: translate('tableView.evStatusLastChangedAt'),
		sortable: true,
		render: ({ eventStatus }) => formatTimeStamp(eventStatus?.lastChangedAt, true),
	}),
	configField({
		listIcon: EVENT_MUSIC,
		name: 'invoice.invoiceNumber',
		label: translate('tableView.evInvoiceNumber'),
		sortable: true,
	}),
	configField({
		listIcon: EVENT_MUSIC,
		name: 'invoice.itemNumber',
		label: translate('tableView.evItemNumber'),
		sortable: true,
	}),
	configField({
		listIcon: LOCATION,
		name: 'location.id',
		label: (
			<InfoPopup header={translate('tableView.locId')} content={translate('tableView.locIdTip')} />
		),
		render: data => (
			<RedirectToStepSelect id={data.location?.id} type="location" view="table">
				{renderIdAndOldIds({
					data,
					idField: 'location.id',
					filterField: 'location.id',
					oldIdsField: 'location.oldIds',
					message: translate('tableView.locOldIds'),
				})}
			</RedirectToStepSelect>
		),
		sortable: true,
	}),
	configField({
		listIcon: LOCATION,
		name: 'location.commercialId',
		label: translate('tableView.locCommercialId'),
		sortable: true,
	}),
	configField({
		listIcon: LOCATION,
		name: 'location.matchedLocationIds',
		label: translate('tableView.locMatchedIds'),
		render: data =>
			data.location &&
			data.location.matchedLocationIds &&
			data.location.matchedLocationIds.join(', '),
	}),
	configField({
		listIcon: LOCATION,
		name: 'location.name',
		label: translate('tableView.locName'),
		sortable: true,
		render: data => (
			<RedirectToStepSelect id={data.location?.id} type="location" view="table">
				{data.location?.name}
			</RedirectToStepSelect>
		),
	}),
	configField({
		listIcon: LOCATION,
		name: 'location.address.streetHouseNumber',
		label: translate('tableView.locStreet'),
		render: ({ location }) => buildStreetHouseNumberFromAddress(location?.address),
		sortField: 'location.address.street', // cannot sort both
		sortable: true,
	}),
	configField({
		listIcon: LOCATION,
		name: 'location.address.postalCode',
		label: translate('tableView.locZIP'),
		sortable: true,
	}),
	configField({
		listIcon: LOCATION,
		name: 'location.address.city',
		label: translate('tableView.locCity'),
		sortable: true,
	}),
	configField({
		listIcon: LOCATION,
		name: 'location.address.region',
		label: translate('tableView.locRegion'),
		sortable: true,
	}),
	configField({
		listIcon: LOCATION,
		name: 'location.address.country',
		label: translate('tableView.locCountry'),
		sortable: true,
	}),
	configField({
		listIcon: LOCATION,
		name: 'location.category',
		label: translate('tableView.locCategory'),
		sortable: true,
	}),
	configField({
		listIcon: LOCATION,
		name: 'location.emailAddresses',
		label: translate('tableView.locMail'),
		sortable: true,
	}),
	configField({
		listIcon: LOCATION,
		name: 'location.phoneNumbers',
		label: translate('tableView.locPhone'),
		sortable: true,
	}),
	configField({
		listIcon: LOCATION,
		name: 'location.urls',
		label: translate('tableView.locURL'),
		sortable: true,
	}),
	configField({
		listIcon: LOCATION,
		name: 'location.timestamp',
		label: translate('tableView.locTimestamp'),
		sortable: true,
		render: ({ location }) => formatTimeStamp(location?.timestamp),
	}),
	configField({
		listIcon: ORGANIZER,
		name: 'organizers.id',
		label: (
			<InfoPopup header={translate('tableView.orgId')} content={translate('tableView.orgIdTip')} />
		),
		sortable: true,
		sortField: 'organizer.id',
		render: renderItemArray('organizers', '.', {
			render: organizer => (
				<RedirectToStepSelect id={organizer?.id} type="organizer" view="table">
					{renderIdAndOldIds({
						data: organizer,
						idField: 'id',
						filterField: 'organizer.id',
						oldIdsField: 'oldIds',
						message: translate('tableView.orgOldIds'),
					})}
				</RedirectToStepSelect>
			),
			...organizerParams,
		}),
		arrayPath: 'organizers',
	}),
	configField({
		listIcon: ORGANIZER,
		name: 'organizers.commercialId',
		label: translate('tableView.orgCommercialId'),
		sortable: true,
		sortField: 'organizer.commercialId',
		render: renderItemArray('organizers', 'commercialId', organizerParams),
		arrayPath: 'organizers',
	}),
	configField({
		listIcon: ORGANIZER,
		name: 'organizers.matchedOrganizerIds',
		label: translate('tableView.orgMatchedIds'),
		render: renderItemArray('organizers', 'matchedOrganizerIds', {
			render: val => val && val.join(', '),
			...organizerParams,
		}),
		arrayPath: 'organizers',
	}),
	configField({
		listIcon: ORGANIZER,
		name: 'organizers.name',
		label: translate('tableView.orgName'),
		sortable: true,
		sortField: 'organizer.name',
		render: renderItemArray('organizers', '.', {
			render: organizer => (
				<RedirectToStepSelect id={organizer?.id} type="organizer" view="table">
					{organizer?.name}
				</RedirectToStepSelect>
			),
			...organizerParams,
		}),
		arrayPath: 'organizers',
	}),
	configField({
		listIcon: ORGANIZER,
		name: 'organizers.address.streetHouseNumber',
		label: translate('tableView.orgStreet'),
		sortable: true,
		sortField: 'organizer.address.street', // cannot sort both
		render: renderItemArray('organizers', 'address', {
			render: address => buildStreetHouseNumberFromAddress(address),
			...organizerParams,
		}),
		arrayPath: 'organizers',
	}),
	configField({
		listIcon: ORGANIZER,
		name: 'organizers.address.postalCode',
		label: translate('tableView.orgZIP'),
		sortable: true,
		sortField: 'organizer.address.postalCode',
		render: renderItemArray('organizers', 'address.postalCode', organizerParams),
		arrayPath: 'organizers',
	}),
	configField({
		listIcon: ORGANIZER,
		name: 'organizers.address.city',
		label: translate('tableView.orgCity'),
		sortable: true,
		sortField: 'organizer.address.city',
		render: renderItemArray('organizers', 'address.city', organizerParams),
		arrayPath: 'organizers',
	}),
	configField({
		listIcon: ORGANIZER,
		name: 'organizers.address.region',
		label: translate('tableView.orgRegion'),
		sortable: true,
		sortField: 'organizer.address.region',
		render: renderItemArray('organizers', 'address.region', organizerParams),
		arrayPath: 'organizers',
	}),
	configField({
		listIcon: ORGANIZER,
		name: 'organizers.address.country',
		label: translate('tableView.orgCountry'),
		sortable: true,
		sortField: 'organizer.address.country',
		render: renderItemArray('organizers', 'address.country', organizerParams),
		arrayPath: 'organizers',
	}),
	configField({
		listIcon: ORGANIZER,
		name: 'organizers.category',
		label: translate('tableView.orgCategory'),
		sortable: true,
		sortField: 'organizer.category',
		render: renderItemArray('organizers', 'category', organizerParams),
		arrayPath: 'organizers',
	}),
	configField({
		listIcon: ORGANIZER,
		name: 'organizers.emailAddresses',
		label: translate('tableView.orgMail'),
		sortable: true,
		sortField: 'organizer.emailAddresses',
		render: renderItemArray('organizers', 'emailAddresses', {
			render: val => val && val.join(', '),
			...organizerParams,
		}),
		arrayPath: 'organizers',
	}),
	configField({
		listIcon: ORGANIZER,
		name: 'organizers.phoneNumbers',
		label: translate('tableView.orgPhone'),
		sortable: true,
		sortField: 'organizer.phoneNumbers',
		render: renderItemArray('organizers', 'phoneNumbers', {
			render: val => val && val.join(', '),
			...organizerParams,
		}),
		arrayPath: 'organizers',
	}),
	configField({
		listIcon: ORGANIZER,
		name: 'organizers.urls',
		label: translate('tableView.orgURL'),
		sortable: true,
		sortField: 'organizer.urls',
		render: renderItemArray('organizers', 'urls', {
			render: val => val && val.join(', '),
			...organizerParams,
		}),
		arrayPath: 'organizers',
	}),
	configField({
		listIcon: ORGANIZER,
		name: 'organizers.timestamp',
		label: translate('tableView.orgTimestamp'),
		sortable: true,
		render: renderItemArray('organizers', 'timestamp', {
			render: formatTimeStamp,
			...organizerParams,
		}),
		arrayPath: 'organizers',
	}),
];

// see configLocationEvents for an example how so use props to return non default values
// = {} is required to allow calling the function without any arguments
// {defaultHiddenFieldsDiff: {exclude: [...], include: [...],}, listedFieldsDiff: {exclude: [...], include: [...],}
// exclude means from excluded from default array
const defaultConfig = ({ defaultHiddenFieldsDiff, listedFieldsDiff } = {}) => {
	const config = {
		defaultHiddenFields: [
			// 'collaboration',
			// 'stateInfo',
			'customLeaflet.name',
			'customLeaflet.username',
			'customLeaflet.timestamp',
			'id',
			'advertisements.id',
			'customer.events',
			// 'name',
			// 'startDate',
			'endDate',
			'admission',
			'types',
			// 'rating',
			'setlists',
			'artists',
			// 'lineup',
			'visitorCount',
			'url',
			'timestamp',
			'isCancelled',
			'isLocationSameAsOrganizer',
			'advertisements.totalCount',
			'organizersCount',
			'selectedOrganizer.id',
			'eventStatus.name',
			'eventStatus.user.username',
			'eventStatus.lastChangedAt',
			'invoice.invoiceNumber',
			'invoice.itemNumber',
			'location.id',
			'location.commercialId',
			'location.matchedLocationIds',
			// 'location.name',
			'location.address.streetHouseNumber',
			'location.address.postalCode',
			// 'location.address.city',
			'location.address.region',
			'location.address.country',
			'location.category',
			'location.emailAddresses',
			'location.phoneNumbers',
			'location.urls',
			'location.timestamp',
			'organizers.id',
			'organizers.commercialId',
			'organizers.matchedOrganizerIds',
			// 'organizers.name',
			'organizers.address.streetHouseNumber',
			'organizers.address.postalCode',
			'organizers.address.city',
			'organizers.address.region',
			'organizers.address.country',
			'organizers.category',
			'organizers.emailAddresses',
			'organizers.phoneNumbers',
			'organizers.urls',
			'organizers.timestamp',
		],
		listedFields: [
			'collaboration',
			'stateInfo',
			'customLeaflet.name',
			'customLeaflet.username',
			'customLeaflet.timestamp',
			'id',
			'advertisements.id',
			'customer.events',
			'name',
			'startDate',
			'endDate',
			'admission',
			'types',
			'rating',
			'setlists',
			'artists',
			'lineup',
			'visitorCount',
			'url',
			'timestamp',
			'isCancelled',
			'isLocationSameAsOrganizer',
			'advertisements.totalCount',
			'organizersCount',
			'selectedOrganizer.id',
			'eventStatus.name',
			'eventStatus.user.username',
			'eventStatus.lastChangedAt',
			'invoice.invoiceNumber',
			'invoice.itemNumber',
			'location.id',
			'location.commercialId',
			'location.matchedLocationIds',
			'location.name',
			'location.address.streetHouseNumber',
			'location.address.postalCode',
			'location.address.city',
			'location.address.region',
			'location.address.country',
			'location.category',
			'location.emailAddresses',
			'location.phoneNumbers',
			'location.urls',
			'location.timestamp',
			'organizers.id',
			'organizers.commercialId',
			'organizers.matchedOrganizerIds',
			'organizers.name',
			'organizers.address.streetHouseNumber',
			'organizers.address.postalCode',
			'organizers.address.city',
			'organizers.address.region',
			'organizers.address.country',
			'organizers.category',
			'organizers.emailAddresses',
			'organizers.phoneNumbers',
			'organizers.urls',
			'organizers.timestamp',
		],
	};

	// remove array of values from an array
	const _exclude = (exclusions, key) =>
		(config[key] = config[key].filter(val => !exclusions.some(exc => val === exc)));
	// add array of values to an array & de-dupe
	const _include = (inclusions, key) => (config[key] = uniq(config[key].concat(inclusions)));

	if (defaultHiddenFieldsDiff) {
		const { exclude, include } = defaultHiddenFieldsDiff;
		exclude && _exclude(exclude, 'defaultHiddenFields');
		include && _include(include, 'defaultHiddenFields');
	}

	if (listedFieldsDiff) {
		const { exclude, include } = listedFieldsDiff;
		exclude && _exclude(exclude, 'listedFields');
		include && _include(include, 'listedFields');
	}

	return config;
};

// Config for event search and select
const configEvents = defaultConfig();

// Config for event process
const configEventsProcess = defaultConfig();

// Config used for "My History"
const configHistory = defaultConfig();

// Config for location-select
const configLocationEvents = defaultConfig({
	defaultHiddenFieldsDiff: {
		exclude: [
			'admission',
			'types',
			'artists',
			'visitorCount',
			'organizers.name',
			'organizers.address.streetHouseNumber',
			'organizers.address.postalCode',
			'organizers.address.city',
			'organizers.address.region',
			'organizers.address.country',
			'organizers.phoneNumbers',
		],
		include: ['location.name', 'location.address.city'],
	},
});

// Config for organizer-select
const configOrganizerEvents = defaultConfig({
	defaultHiddenFieldsDiff: {
		exclude: [
			'admission',
			'types',
			'artists',
			'visitorCount',
			'location.name',
			'location.address.streetHouseNumber',
			'location.address.postalCode',
			'location.address.city',
			'location.address.region',
			'location.address.country',
			'location.phoneNumbers',
			'selectedOrganizer.id',
			'organizersCount',
		],
		include: ['organizers.name', 'organizers.address.city'],
	},
});

export default Events;

export {
	configEvents,
	configEventsProcess,
	configLocationEvents,
	configOrganizerEvents,
	configHistory,
};
