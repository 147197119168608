import { useRef } from 'react';
// asserts that a task is not invoked more often
// than in a certain frequency
const useSlowRepeat = frequency => {
	const timeOut = useRef(null);

	const slowRepeat = task => {
		if (!timeOut.current) {
			timeOut.current = setTimeout(() => {
				timeOut.current = null;
			}, frequency);
			task();
		}
	};

	return slowRepeat;
};

export default useSlowRepeat;
