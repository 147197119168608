// It might be that the filter format has changed but old filter data
// persist in data base. In this case the data will be adopted in this function.
export default savedQuery => {
	const { filters, sorting, quickFilter } = Array.isArray(savedQuery) // in the old format filters were saved directly as array without sorting and quickFilter
		? { filters: savedQuery, sorting: null, quickFilter: null }
		: savedQuery;

	const convertedFilters = [];
	const startDateFilters = [];
	const endDateFilters = [];
	filters.forEach(filter =>
		filter.fieldName === 'startDate'
			? startDateFilters.push(filter)
			: filter.fieldName === 'endDate'
			? endDateFilters.push(filter)
			: convertedFilters.push(filter)
	);

	// convert old date filters to dates range
	convertedFilters.push(
		...startDateFilters.map(filter => ({
			fieldName: 'dates',
			enabled: filter.enabled,
			value: {
				start_from: filter.value.from,
				start_to: filter.value.to,
			},
		})),
		...endDateFilters.map(filter => ({
			fieldName: 'dates',
			enabled: filter.enabled,
			value: {
				end_from: filter.value.from,
				end_to: filter.value.to,
			},
		}))
	);

	return { filters: convertedFilters, sorting, quickFilter };
};
