import React from 'react';
import { Subscription } from '@apollo/client/react/components';

import { subscribeForDownload } from 'graphql/subscriptions';

export default ({ children, id, ...props }) => (
	<Subscription subscription={subscribeForDownload} variables={{ id: id }} {...props}>
		{children}
	</Subscription>
);
