import React from 'react';
import { Popup } from 'semantic-ui-react';

import { useTranslate } from 'customHooks';

import './ToggleIsCreate.scss';

const ToggleIsCreate = ({ isCreateNew, setIsCreateNew, type }) => {
	const translate = useTranslate();

	const createNew =
		type === 'location'
			? translate('actions.createNewLocation')
			: translate('actions.createNewOrganizer');
	const selectExisting =
		type === 'location'
			? translate('actions.selectExistingLocation')
			: translate('actions.selectExistingOrganizer');
	const switchTo = mode => translate('actions.switchTo', [mode]);

	return (
		<Popup
			trigger={
				<span className="ToggleIsCreate" onClick={() => setIsCreateNew(!isCreateNew)}>
					{isCreateNew ? createNew : selectExisting}
				</span>
			}
			content={isCreateNew ? switchTo(selectExisting) : switchTo(createNew)}
			on="hover"
			mouseEnterDelay={500}
			position="bottom right"
			closeOnTriggerClick
		/>
	);
};

export default ToggleIsCreate;
