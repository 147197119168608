import React from 'react';

import useFilterConfig from 'components/FilterWidget/useFilterConfig';
import GenericSelect from './GenericSelect';

const TagListSelect = ({ value, error, onChange }) => {
	const { tags } = useFilterConfig();
	return (
		<GenericSelect
			options={tags}
			allowedAssociations={['or', 'and', 'exists']}
			allowMultiple={true}
			{...{ value, error, onChange }}
		/>
	);
};

export default TagListSelect;
