import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { LOCATIONS, ORGANIZERS } from 'graphql/queries';
import { buildGenericQueryFromFilters } from 'utils';
import { get } from 'lodash';

const useOrganizerLocationQueryById = ({ onResponse, skip, type, fieldName = 'id' }) => {
	const [QUERY, queryName] =
		type === 'location'
			? [LOCATIONS, 'searchLocations']
			: //  type === 'organizer'
			  [ORGANIZERS, 'searchOrganizers'];

	const [variables, setVariables] = useState();
	const { data, loading } = useQuery(QUERY, {
		skip: skip || !variables,
		fetchPolicy: 'network-only',
		variables,
	});

	const invalid = !!variables && get(data, `${queryName}.totalCount`) !== 1;
	const item = !!variables && !invalid ? get(data, `${queryName}.results[0]`) : null;
	const empty = !variables;

	useEffect(() => {
		onResponse && variables && onResponse(item, !invalid);
		// eslint-disable-next-line
	}, [item, invalid]); // only item and invalid as dependency

	const requestItem = fieldValue => {
		setVariables(
			!!fieldValue
				? {
						max: 1,
						offset: 0,
						sortings: [],
						searchQuery: buildGenericQueryFromFilters([
							{
								fieldName,
								type: 'keywordOrExists',
								value: {
									action: 'include',
									association: 'or',
									keyword: fieldValue,
								},
							},
						]),
				  }
				: null
		);
	};

	return [{ item, invalid, loading, empty }, requestItem];
};

export default useOrganizerLocationQueryById;

export const useLocationQueryById = props => {
	const [{ item, ...vals }, request] = useOrganizerLocationQueryById({
		...props,
		type: 'location',
	});
	return [{ location: item, ...vals }, request];
};
export const useOrganizerQueryById = props => {
	const [{ item, ...vals }, request] = useOrganizerLocationQueryById({
		...props,
		type: 'organizer',
	});
	return [{ organizer: item, ...vals }, request];
};
