export const isSelectableAndUnlocked = (item, currentUsername) =>
	item?.isSelectable && !isLockedByOtherUser(item, currentUsername);

// item might be event or organizer
export const isLockedByOtherUser = (item, currentUsername) =>
	item?.userLock?.isLocked && item?.userLock?.user.username !== currentUsername;

export const isLockedByMe = (item, currentUsername) =>
	item?.userLock?.isLocked && item?.userLock?.user.username === currentUsername;

export const isSelectableAndLockedByMe = (item, currentUsername) =>
	item?.isSelectable && isLockedByMe(item, currentUsername);
