import { useSubscription } from '@apollo/client';
import { eventsHaveChangedBySameUser } from 'graphql/subscriptions';

export default ({ subscribeId, onRefresh }) => {
	useSubscription(eventsHaveChangedBySameUser, {
		onSubscriptionData: ({
			subscriptionData: {
				data: { eventsHaveChangedBySameUser: { eventIds, isSuccessful } = {} } = {},
			},
		}) => {
			isSuccessful && eventIds.includes(subscribeId) && onRefresh();
		},
		variables: { instanceId: new Date().getTime() },
	});
};
