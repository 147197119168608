import React from 'react';

import Navi from './Navi';
import { FullLayout, GraySidebarLayout } from 'components/Layout';
import WorkflowLayer from 'components/WorkflowLayer/index';
import EventsFilter from 'components/FilterWidget/EventsFilter';
import { configEvents } from 'components/Table/Events';

const Search = ({
	filters,
	sorting,
	quickFilter,
	onFiltersUpdate,
	onQuickFilterUpdate,
	...props
}) => {
	const enabledFilters = filters.filter(item => item.enabled);

	return (
		<GraySidebarLayout
			sidebar={
				<>
					<Navi />
					<EventsFilter
						{...{ filters, sorting, quickFilter, onFiltersUpdate, onQuickFilterUpdate }}
					/>
				</>
			}>
			<FullLayout>
				<WorkflowLayer
					{...{ sorting, ...props }}
					filters={!!quickFilter ? [...enabledFilters, quickFilter] : enabledFilters}
					baseURL={'/events/filter'}
					config={configEvents}
					storageMemoKey="events"
				/>
			</FullLayout>
		</GraySidebarLayout>
	);
};

export default Search;
