import React, { useCallback } from 'react';
import { Divider, Table } from 'semantic-ui-react';

import { ToggleIcon } from 'components/Base';
import { useTranslate } from 'customHooks';

import './SetListTable.scss';

const SetListTable = ({ data = [], isExpanded, onlyCovered, updateIsExpanded }) => {
	const translate = useTranslate();

	const handleToggle = () => updateIsExpanded(!isExpanded);

	const coveredSongs = useCallback(
		data.songs.filter(song => !!song.originalArtist),
		[data.songs]
	);

	const displayedSongs = !!onlyCovered ? coveredSongs : data.songs;

	const renderBodyRow = (data, index) =>
		isExpanded
			? {
					key: index,
					cells: [
						data.title,
						{ key: 'originalArtist', content: data.originalArtist, 'data-test': 'originalArtist' },
					],
			  }
			: { key: index, cells: [] };

	const headerRow = isExpanded
		? [translate('detailView.setlistTable.songName'), translate('detailView.setlistTable.artist')]
		: [
				'',
				{
					key: 'songsCount',
					'data-test': 'songsCount',
					content: `covered: ${coveredSongs.length} / total: ${data.songs.length}`,
				},
		  ];

	return (
		<div className="SetListTable">
			<div className="SetListHeader">
				<div className="Artist">
					<a href={data.url} target="_blank" rel="noopener noreferrer">
						{data.artist}
					</a>
				</div>
				{!!displayedSongs.length && <ToggleIcon open={isExpanded} onToggle={handleToggle} />}
				<Divider />
			</div>

			<div className="TableContainer">
				<Table
					basic="very"
					headerRow={headerRow}
					renderBodyRow={renderBodyRow}
					tableData={displayedSongs}
				/>
			</div>
		</div>
	);
};

export default SetListTable;
