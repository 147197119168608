import moment from 'moment';
import browserStorage from 'browserStorage';
import { MOMENT_FORMATS } from 'config';

export default () => (value, type = 'dateCode') => {
	// Please, use hook useTranslateContext where ever possible because this is not instantly updating when format changes.
	//  This should only be used outside of react components where hooks cannot be used, e.g. for dataToCSV.

	const { dateFormat } = browserStorage.getUserSettings() || {};

	const momentFormat = MOMENT_FORMATS.find(format => format.id === dateFormat) || MOMENT_FORMATS[0];

	return value && moment.utc(value).format(momentFormat[type]); // use .utc in order to show in originally recorded time without time zone offset
};
