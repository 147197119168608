import React from 'react';
import { Subscription } from '@apollo/client/react/components';

import { subscribeToLeafletChange } from 'graphql/subscriptions';

export default ({ children, componentId = 0, ...props }) => {
	return (
		<Subscription
			subscription={subscribeToLeafletChange}
			variables={{ componentId }} // parameter ids irrelevant for App-Service logic but has to have a value
			{...props}>
			{children}
		</Subscription>
	);
};
