import { useContext } from 'react';
import { TranslateContext } from 'contexts/TranslateContext';

const useTranslate = () => {
	const { translate } = useContext(TranslateContext);

	return translate;
};

export default useTranslate;
