import React from 'react';
import { Grid } from 'semantic-ui-react';
import { BASKET, SHELF, BIN, DISCARD, EXPORT, CONFLICT } from 'config';
import LeafletItem from './LeafletItem';
import './LeafletGrid.scss';

const LeafletGrid = ({ data, onActionCompleted, editableLeaflet }) => {
	return (
		<div className="LeafletGrid">
			<Grid container columns="equal">
				{data &&
					data
						.filter(item => ![BASKET, SHELF, BIN, DISCARD, EXPORT, CONFLICT].includes(item.id))
						.map(item => (
							<Grid.Column width={5} key={item.id}>
								<LeafletItem
									onActionCompleted={onActionCompleted}
									leafletData={item}
									editableLeaflet={editableLeaflet}
								/>
							</Grid.Column>
						))}
			</Grid>
		</div>
	);
};

export default LeafletGrid;
