import React from 'react';
import { Query } from '@apollo/client/react/components';

import { EVENT } from 'graphql/queries';
import { useUpdateEventsAfterLoading } from 'customHooks';

const Event = ({ id, children, ...props }) => {
	const updatingChildren = useUpdateEventsAfterLoading(children);
	return (
		<Query query={EVENT} fetchPolicy="network-only" variables={{ id: `${id}` }} {...props}>
			{updatingChildren}
		</Query>
	);
};

export default Event;