import DeleteLeaflet from './DeleteLeaflet';
import Discard from './Discard';
import Drop from './Drop';
import Keep from './Keep';
import Scrap from './Scrap';
import Merge from './Merge';
import Table from './Table';
import ToCustomLeaflet from './ToCustomLeaflet';
import Refresh from './Refresh';
import CSVExport from './CSVExport';
import ExpandAll from './ExpandAll';
import UpdateEvent from './UpdateEvent';
import CreateNewEvent from './CreateNewEvent';
import Submit from './Submit';
import Undo from './Undo';
import FieldSwitcher from './FieldSwitcher';
import DragMode from './DragMode';
import Lock from './Lock';
import ToggleIsCreate from './ToggleIsCreate';
import CreateUpdateGeneric from './CreateUpdateGeneric';
import Reset from './Reset';

const Button = {
	DeleteLeaflet,
	Discard,
	Drop,
	Keep,
	Scrap,
	Merge,
	Table,
	ToCustomLeaflet,
	Refresh,
	CSVExport,
	ExpandAll,
	UpdateEvent,
	CreateNewEvent,
	Submit,
	Undo,
	FieldSwitcher,
	DragMode,
	Lock,
	ToggleIsCreate,
	CreateUpdateGeneric,
	Reset,
};

export default Button;
