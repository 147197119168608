import React from 'react';
import { Icon } from 'semantic-ui-react';
import classNames from 'classnames';

export default ({ 'data-test': dataTest, className, ...props }) => (
	<Icon
		className={classNames('remove', 'red', className)}
		link
		tabIndex="undefined"
		data-test={dataTest}
		{...props}
	/>
);
