import React, { useContext } from 'react';
import { Icon, Dropdown } from 'semantic-ui-react';

import { useTranslate } from 'customHooks';
import { UserContext } from 'contexts/UserContext';

const SignOutButton = () => {
	const translate = useTranslate();
	const { signOut } = useContext(UserContext);
	return (
		<Dropdown.Item data-test="logout-dropdown" title={translate('titles.logout')} onClick={signOut}>
			<Icon name="sign out" />
			{translate('logout')}
		</Dropdown.Item>
	);
};

export default SignOutButton;
