import React from 'react';

import SubscribeForm from './SubscribeForm';
import FilterForm from './FilterForm';
import FilterList from './FilterList';
import filterUtils from './filterUtils';

import {
	keywordOr,
	keywordOrExists,
	keywordOrAndExists,
	keywordOrAndStrictExists,
	numberRange,
	statusList,
	meslisEvents,
	customerEvents,
	dateRange,
	userSelect,
	entityStatusSelect,
} from 'formTypes';
import { useTranslate } from 'customHooks';

import './LocationsFilter.scss';

const LocationsFilter = ({ filters, sorting, onChangeFilters }) => {
	const translate = useTranslate();
	const fields = locationFields(translate);

	const updateFilters = filters => onChangeFilters(filterUtils.removeDuplicates(filters));

	const handleAddFilter = filter => {
		updateFilters([...filters, { ...filter, ...{ enabled: true } }]);
	};

	return (
		<div className="LocationsFilter">
			<FilterForm
				{...{ fields }}
				confirmButtonText={translate('filters.add')}
				type="location"
				onAddFilter={handleAddFilter}
			/>
			<hr />
			<SubscribeForm enabled={filters.length} origin={'location'} {...{ filters, sorting }} />
			{!!filters.length ? (
				<FilterList {...{ fields, filters }} type="location" onChangeFilters={updateFilters} />
			) : null}
		</div>
	);
};

const setFilterField = (name, label, formType) => ({
	name,
	label,
	formType,
	defaultValue: formType.defaultValue,
});

export const locationFields = translate => [
	setFilterField('id', translate('tableView.locId'), keywordOr),
	setFilterField('matchedLocationIds', translate('tableView.locMatchedId'), keywordOrExists),
	setFilterField('commercialId', translate('tableView.locCommercialId'), keywordOrAndExists),
	setFilterField('name', translate('tableView.locName'), keywordOrAndStrictExists),
	setFilterField('address.street', translate('tableView.locStreet'), keywordOrAndStrictExists), // will search for street and house number
	setFilterField('address.postalCode', translate('tableView.locZIP'), keywordOrExists),
	setFilterField('address.city', translate('tableView.locCity'), keywordOrAndStrictExists),
	setFilterField('address.region', translate('tableView.locRegion'), keywordOrAndStrictExists),
	setFilterField('address.country', translate('tableView.locCountry'), keywordOrExists),
	setFilterField('category', translate('tableView.locCategory'), keywordOrAndExists),
	setFilterField('emailAddresses', translate('tableView.locMail'), keywordOrAndExists),
	setFilterField('phoneNumbers', translate('tableView.locPhone'), keywordOrAndExists),
	setFilterField('urls', translate('tableView.locURL'), keywordOrAndExists),
	setFilterField('timestamp', translate('tableView.locTimestamp'), dateRange),
	setFilterField('customerStatus', translate('tableView.locCustomerStatus'), statusList),
	setFilterField(
		'customerLocations.eventCountPerYear',
		translate('tableView.locCustomerEventCountPerYear'),
		customerEvents
	),
	setFilterField(
		'statistic.eventCountPerYear',
		translate('tableView.locStatisticEventCountPerYear'),
		meslisEvents
	),
	setFilterField(
		'statistic.eventCountTotal',
		translate('tableView.locEventCountTotal'),
		numberRange
	),
	setFilterField(
		'statistic.eventCountRelevant',
		translate('tableView.locEventCountRelevant'),
		numberRange
	),
	setFilterField(
		'statistic.eventCountIrrelevant',
		translate('tableView.locEventCountIrrelevant'),
		numberRange
	),
	setFilterField('status.name', translate('tableView.locStatusName'), entityStatusSelect),
	setFilterField('status.username', translate('tableView.locStatusUsername'), userSelect),
	setFilterField('status.lastChangedAt', translate('tableView.locStatusLastChangedAt'), dateRange),
];

export default LocationsFilter;
