import React, { useState, useContext } from 'react';
import { Card, Form } from 'semantic-ui-react';

import { UserContext } from 'contexts/UserContext';
import { ToggleIcon } from 'components/Base';

import './GenericEventFilters.scss';
import { useTranslate } from 'customHooks';
import EventStateQuickFilter from 'components/FilterWidget/EventStateQuickFilter';
import { CollaborationInput } from 'components/FilterWidget/Forms';

const GenericEventFilters = ({
	type,
	year,
	collaboration,
	onChangeYear,
	relevance,
	onChangeRelevance,
	stateValue,
	onChangeStateValue,
	onChangeCollaboration,
}) => {
	const [open, setOpen] = useState(true);
	const { getAvailableTime } = useContext(UserContext);
	const { years: availableYears } = getAvailableTime({ plusYears: 1 });
	const translate = useTranslate();

	const handleToggleDropdown = () => {
		setOpen(!open);
	};

	return (
		<div className="GenericEventFilters">
			<Card fluid>
				<Card.Content>
					<Card.Header textAlign="center">{translate('genericEventFilters.filters')}</Card.Header>
					<ToggleIcon open={open} onToggle={handleToggleDropdown} />
				</Card.Content>
				<Card.Content>
					{open && (
						<Form>
							<strong>{translate('genericEventFilters.relevance')}</strong>
							<Form.Select
								data-test="eventFiltersRelevance"
								value={relevance}
								options={[
									{
										key: 'any',
										text: translate('genericEventFilters.relevances.any'),
										value: 'any',
									},
									{
										key: 'relevant',
										text: translate('genericEventFilters.relevances.relevant'),
										value: 'relevant',
									},
									{
										key: 'irrelevant',
										text: translate('genericEventFilters.relevances.irrelevant'),
										value: 'irrelevant',
									},
								]}
								fluid
								onChange={onChangeRelevance}
							/>
							<strong>{translate('genericEventFilters.year')}</strong>
							<Form.Select
								data-test="GenericEventFiltersYear"
								value={year}
								options={[
									{ key: 'any', text: translate('genericEventFilters.any'), value: 'any' },
								].concat(availableYears.map(year => ({ key: year, text: year, value: year })))}
								fluid
								onChange={onChangeYear}
							/>
							{type === 'organizer' && (
								<>
									<strong>{translate('filters.collaboration')}</strong>
									<CollaborationInput
										value={collaboration}
										onChange={onChangeCollaboration}
										isQuickfilter
									/>
								</>
							)}
							<strong>{translate('genericEventFilters.status')}</strong>
							<EventStateQuickFilter
								{...{
									value: stateValue,
									onChangeValue: onChangeStateValue,
								}}
							/>
						</Form>
					)}
				</Card.Content>
			</Card>
		</div>
	);
};

export default GenericEventFilters;
