import { getFormTypeForFieldName } from 'formTypes';
import validateDate from './validateDate';
import validateFieldName from './validateFieldName';
import { value2Blocks } from './value2BlocksAndBlocks2Value';
import storage from 'storage';
import browserStorage from 'browserStorage';
import { BASKET, SHELF, BIN, DISCARD } from 'config';

// maxDate requires adding time to match properly
const maxDate = date => (validateDate(date) ? date.replace('00:00:00', '23:59:59') : null);

const formatDateRange = data => ({
	fieldName: data.fieldName,
	min: validateDate(data.value.from),
	max: maxDate(data.value.to),
});

const formatNumberRange = data => ({
	fieldName: data.fieldName,
	min: data.value.from,
	max: data.value.to,
});

const formatRating = data => {
	const min = data.value.operator !== 'lessThanEqual' ? (data.value.rating * 200).toString() : null;
	const max =
		data.value.operator !== 'greaterThanEqual' ? (data.value.rating * 200 + 199).toString() : null;

	return { fieldName: data.fieldName, min, max };
};

const mapFieldRange = f =>
	f.formType.name === 'dateRange'
		? formatDateRange(f)
		: f.formType.name === 'numberRange'
		? formatNumberRange(f)
		: formatRating(f);

const arrays2Array = (prev, curr) => [...(prev || []), ...(curr || [])];

const sourceLeaflets = [BASKET, SHELF, BIN, DISCARD];

// -- // functions shared by Events and Generic // -- //

// type is used to apply correct filter definitions
// possible values are: event, location or organizer
const appendFormType = (filters, type) =>
	filters
		.map(f => {
			// remove filter if fieldName doesnt exist
			if (!validateFieldName(f.fieldName, type)) return null;

			return {
				formType: getFormTypeForFieldName(f.fieldName, type),
				...f,
			};
		})
		.filter(f => !!f && f);

const getKeywords = filters =>
	filters.filter(
		f =>
			f.formType.name.includes('keyword') ||
			f.formType.name === 'tagList' ||
			f.formType.name === 'statusList' ||
			f.formType.name === 'booleanSelect' ||
			f.formType.name === 'entityStatusSelect' ||
			f.formType.name === 'userSelect'
	);

const getFieldRanges = filters =>
	filters
		.filter(
			f =>
				f.formType.name === 'numberRange' ||
				f.formType.name === 'dateRange' ||
				f.formType.name === 'ratingRange'
		)
		.map(mapFieldRange);

const getDatesRange = filters =>
	filters
		.filter(f => f.formType.name === 'datesRange')
		.map(date =>
			[
				{
					fieldName: 'startDate',
					min: validateDate(date.value.start_from),
					max: maxDate(date.value.start_to),
				},
				{
					fieldName: 'endDate',
					min: validateDate(date.value.end_from),
					max: maxDate(date.value.end_to),
				},
			].filter(dateFilter => !!dateFilter.min || !!dateFilter.max)
		)
		.reduce((prev, curr) => [...prev, ...curr], []);

export const getFieldFilters = (filters, type) =>
	getKeywords(filters)
		.filter(f => f.fieldName !== 'all')
		.map(f => {
			let values = f.value.keyword;
			if (
				f.value.association === 'strict' &&
				getFormTypeForFieldName(f.fieldName, type).name?.includes('keyword')
			) {
				values = value2Blocks(values);
			}
			// values for multi select is an array, but needs to be string that can be evaluated "strict"
			// => all double quotes (") need to be escaped
			// see test for an example of the result
			if (
				['tagList', 'statusList', 'entityStatusSelect', 'userSelect'].some(
					x => f.formType.name === x
				) &&
				Array.isArray(values)
			) {
				let valueString = '';
				const lastIndex = values.length - 1;
				values.forEach((value, index) => {
					index === lastIndex ? (valueString += `"${value}"`) : (valueString += `"${value}" `);
				});
				values = valueString;
			}
			return {
				fieldName: f.fieldName,
				operation: f.value.action === 'include' ? 'INCLUDE' : 'EXCLUDE',
				association: !!f.value.association
					? { and: 'AND', or: 'OR', strict: 'STRICT' }[f.value.association]
					: undefined,
				values,
			};
		});

const getCollaborationFilters = (filters, type) =>
	filters
		.filter(({ fieldName }) => fieldName === 'collaboration')
		.map(({ value }) => [
			{
				fieldName: 'userLock.isLocked',
				formType: { name: 'booleanSelect' },
				value: {
					action: value.locking !== 'unlocked' ? 'include' : 'exclude',
					association: null,
					keyword: `true`,
				},
			},
			...(['lockedByMe', 'lockedByOtherUsers', 'lockedByFollowingUsers'].includes(value.locking)
				? [
						{
							fieldName: 'userLock.username',
							formType: { name: 'keywordOr' },
							value: {
								action: ['lockedByMe', 'lockedByFollowingUsers'].includes(value.locking)
									? 'include'
									: 'exclude',
								association: 'or',
								keyword:
									value.locking === 'lockedByFollowingUsers'
										? value?.users?.map(user => `"${user.username}"`).join(' ')
										: `"${value.ownUserName}"`,
							},
						},
				  ]
				: []),
		])
		.map(filters => getFieldFilters(filters, type))
		.reduce((prev, filters) => [...prev, ...filters], []);

const getStateFilters = (filters, { explicitLeafletIds }) => {
	const mapStateInfo2LeafletIds = ({ value: { leaflets } }) =>
		!!leaflets ? leaflets.map(l => l.id) : [];

	const mapStateInfo2EventFieldFilters = ({ value: { users } }) => [
		...(!!users && users.length !== 0
			? [
					{
						fieldName: 'customLeaflet.username',
						association: 'STRICT',
						operation: 'INCLUDE',
						values: users.map(u => u.username),
					},
			  ]
			: []),
	];

	const mapStateInfo2EventFieldRanges = ({ value: { timestamp } }) => [
		...(!!timestamp && (timestamp.from || timestamp.to)
			? [
					{
						fieldName: 'customLeaflet.timestamp',
						min: timestamp.from,
						max: timestamp.to,
					},
			  ]
			: []),
	];

	const mapState2EventFieldFilters = leafletsDefined => ({ value: states }) => [
		...(!!states
			? [
					{
						fieldName: 'state',
						operation: 'INCLUDE',
						values: Object.keys(states).filter(
							// If leaflets are defined, the filter should not find any other state
							// than 'IN_PROGRESS' because the state info filter (with leaflets defined)
							// and the quick filter are AND-concated as all filters.
							stateKey =>
								leafletsDefined ? stateKey === 'IN_PROGRESS' && states[stateKey] : states[stateKey]
						),
					},
			  ]
			: []),
	];

	const eventStateFilters = filters.filter(f => f.formType.name === 'eventState');
	let eventStateInfoFilters = filters.filter(f => f.formType.name === 'eventStateInfo');

	let leafletIds = [
		...(!!explicitLeafletIds ? explicitLeafletIds : []),
		...eventStateInfoFilters.map(mapStateInfo2LeafletIds).reduce(arrays2Array, []),
	].map(id => parseInt(id));

	let eventFieldFilter = [
		...eventStateFilters
			.map(mapState2EventFieldFilters(!!leafletIds.length))
			.reduce(arrays2Array, []),
		...eventStateInfoFilters.map(mapStateInfo2EventFieldFilters).reduce(arrays2Array, []),
	];

	const eventFieldRange = eventStateInfoFilters
		.map(mapStateInfo2EventFieldRanges)
		.reduce(arrays2Array, []);

	if (
		// send own username in case basket or shelf is selected in selection processing step
		// (leaflet ids explicit and not via filters)
		!!explicitLeafletIds &&
		explicitLeafletIds.some(id => sourceLeaflets.includes(id)) &&
		!eventFieldFilter.some(f => f.fieldName === 'customLeaflet.username')
	) {
		const me = browserStorage.getUser();
		eventFieldFilter = [
			...eventFieldFilter,
			...mapStateInfo2EventFieldFilters({ value: { users: [{ username: me }] } }),
		];
	}
	return { eventFieldFilter, eventFieldRange, leafletIds };
};

const filterIdsToStorage = (fieldName, filters) => {
	const idsInFilters = filters
		.filter(f => f.fieldName === fieldName && f.operation === 'INCLUDE')
		.reduce((prev, curr) => [...prev, ...curr.values.split(' ')], [])
		.filter(id => id !== '');
	storage.setIdsInFilters(fieldName, idsInFilters); // needed for showing old ids in gray in table if explicitely searched for them
};

export const buildEventsQueryFromFilters = (filters, { leafletIds: explicitLeafletIds } = {}) => {
	const type = 'event';
	filters = appendFormType(filters, type);

	// group by type (of value(s))
	let eventFieldRange = getFieldRanges(filters);
	let eventFieldFilter = getFieldFilters(filters, type);
	let datesRange = getDatesRange(filters);
	let leafletIds;

	// handle any (*) keyword on types field (usually association is STRICT, but for "any" it needs to be "OR")
	// changing value here instead of in the Select component mean we dont need any special handling when the filter is eddited from "X" to "any" or the other way around
	// handled here since this only concerns Events
	const typesAnyValue = eventFieldFilter.find(
		item => item.fieldName === 'types' && item.values === '*'
	);
	if (typesAnyValue) {
		typesAnyValue.association = 'OR';
	}

	const {
		eventFieldRange: stateRange,
		eventFieldFilter: stateKeyword,
		leafletIds: stateLeafletIds,
	} = getStateFilters(filters, { explicitLeafletIds });

	const collaborationFilters = getCollaborationFilters(filters, type);

	[eventFieldRange, eventFieldFilter, leafletIds] = [
		[...eventFieldRange, ...stateRange, ...datesRange],
		[...eventFieldFilter, ...stateKeyword, ...collaborationFilters],
		stateLeafletIds,
	];

	['id', 'location.id', 'organizer.id'].forEach(fieldName =>
		filterIdsToStorage(fieldName, eventFieldFilter)
	);

	const include = getKeywords(filters)
		.filter(f => f.fieldName === 'all' && f.value.action === 'include')
		.map(f => f.value.keyword);

	const exclude = getKeywords(filters)
		.filter(f => f.fieldName === 'all' && f.value.action === 'exclude')
		.map(f => f.value.keyword);

	return {
		include,
		exclude,
		eventFieldFilter,
		eventFieldRange,
		leafletIds,
	};
};

// default type to location for tests
export const buildGenericQueryFromFilters = (filters, type = 'location') => {
	// add formType to filters
	filters = appendFormType(filters, type);

	// group by type (of value(s))
	const eventFieldRange = getFieldRanges(filters);
	const eventFieldFilter = getFieldFilters(filters, type);

	eventFieldFilter.push(...getCollaborationFilters(filters, type));

	const mapEventsPerYear = f => {
		const {
			value: { operator },
			fieldName,
		} = f;

		const [count, min, max, from_year, to_year] = [
			f.value.count && parseInt(f.value.count),
			f.value.min && parseInt(f.value.min),
			f.value.max && parseInt(f.value.max),
			f.value.from_year && parseInt(f.value.from_year),
			f.value.to_year && parseInt(f.value.to_year),
		];

		const minMax =
			operator === 'greaterThanEqual'
				? { min: count, max: null }
				: operator === 'equal'
				? { min: count, max: count }
				: operator === 'lessThanEqual'
				? { min: null, max: count }
				: operator === 'between'
				? { min, max }
				: operator === 'underLicensed'
				? { value: count }
				: operator === 'overLicensed'
				? { value: -count }
				: {};

		const minMaxDiff =
			operator === 'underLicensed' || operator === 'overLicensed'
				? { difference: { fieldName: fieldName + '.differenceMeslisToCustomer', ...minMax } }
				: { eventCount: { fieldName: fieldName + '.count', ...minMax } };

		return {
			...minMaxDiff,
			year: {
				fieldName: fieldName + '.year',
				min: from_year,
				max: to_year,
			},
		};
	};

	const eventsPerYear = filters
		.filter(f => f.formType.name === 'meslisEvents' || f.formType.name === 'customerEvents')
		.map(mapEventsPerYear);

	const fieldRangeWithPeriod = eventsPerYear.filter(f => !('difference' in f));
	const fieldPeriodWithDifference = eventsPerYear.filter(f => 'difference' in f);

	filterIdsToStorage('id', eventFieldFilter);

	return {
		eventFieldFilter,
		eventFieldRange,
		fieldRangeWithPeriod,
		fieldPeriodWithDifference,
	};
};
