import React from 'react';
import { alerts, isSelectableAndUnlocked } from 'utils';
import { useUserContext } from 'customHooks';

import { useMutation } from '@apollo/client';
import { SET_EVENT_STATUS } from 'graphql/mutations';

import StatusBulkEditDropdown from './StatusBulkEditDropdown';

export default ({ events, onCompleted = () => {} }) => {
	// Logic for rendering the bulk edit dropdown

	// Fetching the user and checking if the selected events are selectable and unlocked
	const { user } = useUserContext();
	const selectableEvents = events?.filter(event => isSelectableAndUnlocked(event, user?.username));

	// Mutation for updating the status of the selected events
	const [updateBulkEventStatus, { loading }] = useMutation(SET_EVENT_STATUS, {
		onError: () => {
			alerts.failMessageGenerator('eventStatusBulkEdit', 'updated');
		},
		onCompleted: ({ setEventStatus }) => {
			const failedEvents = setEventStatus?.filter(x => !x.success);
			const successEvents = setEventStatus?.filter(x => x.success);
			if (failedEvents.length) {
				alerts.failMessageGenerator(
					'eventStatusBulkEdit',
					'updated',
					failedEvents.map(x => x.id)
				);
			}

			if (successEvents) {
				onCompleted();
			}
		},
	});

	const handleSelected = statusId => {
		const eventsToUpdate = selectableEvents.map(({ id, version }) => ({ id, version }));

		updateBulkEventStatus({
			variables: {
				eventStatusUpdate: {
					statusId,
					eventIds: eventsToUpdate,
				},
			},
		});
	};

	return (
		<StatusBulkEditDropdown disabled={!events?.length || loading} onSelected={handleSelected} />
	);
};
