import React from 'react';

import Action from './Action';
import filterUtils from 'components/FilterWidget/filterUtils';

const GenericEntry = ({ data, onChangeValue, type, options }) => {
	const handleToggleAction = () => {
		onChangeValue({ ...data, action: filterUtils.oppositeAction(data.action) });
	};

	const { selectAction } = filterUtils.adoptToSelectAction(
		filterUtils.adoptToExistsAssociation(data, true)
	);

	const getTextForOption = (options, keyword) => {
		const option = options.find(opt => opt.value === keyword);
		if (!option) {
			return null;
		}

		return option.text ?? option.value;
	};

	const text = () => {
		if (selectAction.includes('exists')) {
			return '';
		}

		if (Array.isArray(data.keyword)) {
			return (
				<>
					{data.keyword.map((keyword, i) => (
						<span className="block" key={i}>
							{getTextForOption(options, keyword)}{' '}
						</span>
					))}
				</>
			);
		}

		return getTextForOption(options, data.keyword);
	};

	return (
		<span data-test={type + 'Entry'}>
			<Action onToggle={handleToggleAction} selectAction={selectAction} /> {text()}
		</span>
	);
};

export default GenericEntry;
