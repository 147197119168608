import React from 'react';
import { Icon } from 'semantic-ui-react';

import moment from 'moment';

import { useTranslate } from 'customHooks';
import DateInput from 'components/Base/CustomDateInput'; // closing input when overriding
import { noop } from 'utils';

const DateRangeInput = ({
	value,
	error,
	onChangeFrom = noop,
	onChangeTo = noop,
	fromProps,
	toProps,
	minDate,
	maxDate,
	classNameFrom,
	classNameTo,
}) => {
	const translate = useTranslate();

	const handleChangeFrom = (_value, valid) => {
		onChangeFrom(_value, valid);

		// set toDate one minute after fromDate (= "synchronization")
		const fromDateOneMinuteLater = moment(_value).add(1, 'mins');
		onChangeTo(fromDateOneMinuteLater.format(), true);

		// delete toDate value again
		// --> now, the DateRange picker of toDate will show the
		// correct month when opening it again
		setTimeout(() => onChangeTo('', true), 0);
	};

	const handleChangeTo = (_value, valid) => {
		onChangeTo(_value, valid);
	};

	const _props = {
		error,
		closable: true,
		clearable: true,
		clearIcon: <Icon name="remove" color="red" />,
		iconPosition: 'left',
	};

	return (
		<>
			<DateInput
				className={classNameFrom}
				placeholder={translate('filters.range.from')}
				value={value.from}
				minDate={minDate}
				maxDate={value.to || maxDate}
				onChange={handleChangeFrom}
				{..._props}
				{...fromProps}
				data-test="dateInputFrom"
				type="dateTimeInput"
			/>
			<DateInput
				className={classNameTo}
				placeholder={translate('filters.range.to')}
				value={value.to}
				minDate={value.from || minDate}
				maxDate={maxDate}
				onChange={handleChangeTo}
				startMode="day"
				initialDate={value.from}
				{..._props}
				{...toProps}
				data-test="dateInputTo"
				type="dateTimeInput"
			/>
		</>
	);
};

export default DateRangeInput;
