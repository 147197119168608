import React from 'react';
import { useTranslate } from 'customHooks';
import { Icon, Message } from 'semantic-ui-react';

const NoMoreData = () => {
	const translate = useTranslate();
	return (
		<Message info icon>
			<Icon name="boxes" />
			<Message.Content>
				<Message.Header>{translate('placeholders.noMoreDataHeader')}</Message.Header>
				<p>{translate('placeholders.noMoreData')}</p>
			</Message.Content>
		</Message>
	);
};

export default NoMoreData;
