import React from 'react';
import { Mutation } from '@apollo/client/react/components';

import { EXPORT } from 'graphql/mutations';

const Export = ({ children, ids, description, targetLeafletId, ...props }) => (
	<Mutation mutation={EXPORT} {...props}>
		{(f, data) =>
			children(
				() =>
					f({
						variables: { ids, description, targetLeafletId },
					}),
				data
			)
		}
	</Mutation>
);

export default Export;
