import React from 'react';
import _ from 'lodash';
export default ({ filters, children }) => {
	// checks filters for no-data-condition and if yes returns pseudo-results without backend-request
	const states =
		filters &&
		_.get(
			filters.find(f => f.fieldName === 'state'),
			'value'
		);
	return states && Object.keys(states).every(state => !states[state]) ? ( //no state selected
		<>
			{children({
				data: { searchEventsBySearchParams: { results: [], totalCount: 0 } },
				error: false,
				refetch: () => {},
				loading: false,
			})}
		</>
	) : null;
};
