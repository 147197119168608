import React from 'react';
import { ICONS } from 'config';
import { LabeledIcon } from '.';
import { useMessagesContext, useTranslate } from 'customHooks';

const SavedSearchIcon = ({ value, id }) => {
	const { newQueryIds } = useMessagesContext();

	const translate = useTranslate();

	return (
		<LabeledIcon
			name={ICONS[value]}
			label={newQueryIds.includes(id) ? translate('queryTableView.new') : null}
		/>
	);
};
export default SavedSearchIcon;
