import { LANGUAGES } from 'config';

const getBrowserLanguage = () => {
	const code = LANGUAGES.map(language => language.code).find(code =>
		navigator.language.includes(code) // navigator.language might be en-US, en-UK or de-DE ...
	);
	// only return code en or de
	return code || LANGUAGES[0].code;
};

export default getBrowserLanguage;
