import React from 'react';
import { Subscription } from '@apollo/client/react/components';

import { subscribeToLeaflet } from 'graphql/subscriptions';

export default ({ children, ids, componentId = 0, ...props }) => (
	<Subscription
		subscription={subscribeToLeaflet}
		variables={{ ids: ids, componentId: componentId }}
		{...props}>
		{children}
	</Subscription>
);
