import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import { FullLayout } from 'components/Layout';
import { useTranslate } from 'customHooks';

import './Success.css';

const Success = () => {
	const translate = useTranslate();
	return (
		<FullLayout>
			<div className="Success">
				<div className="icon-hero">
					<Icon size="massive" name="thumbs up" />
				</div>
				<h1>{translate('messages.export.exportSuccessful')}</h1>
				<p className="body">{translate('messages.export.messageExportBeingProcessed')}</p>
				<p className="actions">
					<Button
						size="large"
						fluid
						icon
						primary
						labelPosition="left"
						as={Link}
						to="/events/filter">
						<Icon name="search" />
						{translate('messages.export.startNewSearch')}
					</Button>
					<Button size="large" fluid icon primary labelPosition="left" as={Link} to="/my-searches">
						<Icon name="searchengin" />
						{translate('messages.export.useSavedSearch')}
					</Button>
					<Button
						size="large"
						fluid
						icon
						primary
						labelPosition="left"
						as={Link}
						to="/my-selections">
						<Icon name="download" />
						{translate('messages.export.checkSelections')}
					</Button>
				</p>
			</div>
		</FullLayout>
	);
};
export default Success;
