import moment from 'moment';
import { STANDARD_MOMENT_FORMAT, MOMENT_FORMATS } from 'config';

const formatDate = (dateTime, momentFormat, hasTime) =>
	dateTime &&
	moment.utc(ignoreZone(dateTime)).format(momentFormat[hasTime ? 'timestampCode' : 'dateCode']); // use .utc in order to show in originally recorded time without time zone offset

/**
 * Formats a timestamp string into a specified format.
 * @param {string} dateTime - The timestamp string to format.
 * @param {boolean} showSeconds - Whether to include seconds in the formatted timestamp.
 * @param {object} momentFormat - The moment.js format object to use for formatting.
 * @returns {string} The formatted timestamp string.
 */
const formatTimeStamp = (dateTime, showSeconds, momentFormat) => {
	// Ignore the time zone offset in the input timestamp string.
	dateTime = ignoreZone(dateTime);

	// Determine which moment.js format code to use based on whether seconds should be included.
	const code = showSeconds ? 'timestampSecondsCode' : 'timestampCode';

	// Format the timestamp string using the specified moment.js format.
	return dateTime && moment.utc(dateTime).format(momentFormat[code]);
};

// dateTime might be in format "YYYY-MM-DD HH:mm" or "YYYY-MM-DD"

const formatDateTimeTuple = (dateTime, momentFormat) => {
	dateTime = ignoreZone(dateTime);
	return [
		dateTime ? moment.utc(dateTime).format(momentFormat.dateCode) : '', // empty string because input fields do not accept null or undefined
		dateTime ? moment.utc(dateTime).format(momentFormat.timeCode) : '',
	];
};

const validate = (dateTime, code) => !!dateTime && moment(dateTime, code, true).isValid();

// from short hand and other formats into the current custom format
const resolveDateTimeTuple = (writtenDate, writtenTime, momentFormat) => {
	// find a fitting format for date and time (relevant for shorthand or other format than current)
	const dateCode = [
		momentFormat.shortHandDateCode,
		momentFormat.dateCode,
		...MOMENT_FORMATS.map(format => format.dateCode),
	].find(code => validate(writtenDate, code));

	const timeCode = [
		momentFormat.shortHandTimeCode,
		momentFormat.timeCode,
		...MOMENT_FORMATS.map(format => format.timeCode),
	].find(code => validate(writtenTime, code));

	// transfer written date and time into standard format
	const standardDate =
		dateCode && moment(writtenDate, dateCode, true).format(STANDARD_MOMENT_FORMAT.dateCode);
	const standardTime =
		timeCode && moment(writtenTime, timeCode, true).format(STANDARD_MOMENT_FORMAT.timeCode);

	// transfer standard format back into custom format or keept the written time/date
	const customDate = !!standardDate
		? moment(standardDate, STANDARD_MOMENT_FORMAT.dateCode, true).format(momentFormat.dateCode)
		: writtenDate;

	const customTime = !!standardTime
		? moment(standardTime, STANDARD_MOMENT_FORMAT.timeCode, true).format(momentFormat.timeCode)
		: writtenTime;

	return [customDate, customTime];
};

/**
 * Reformat a date and time tuple into a standardized format.
 * @param {string} date - The date string to reformat.
 * @param {string} time - The time string to reformat.
 * @param {object} momentFormat - The moment.js format object to use for formatting.
 * @returns {string|null} The reformatted date and time tuple in the format "YYYY-MM-DD HH:mm:ss", or null if the input is invalid.
 */
const reformatDateTimeTuple = (date, time, momentFormat) => {
	// Validate the input date and time strings.
	const isDateValid = validate(date, momentFormat.dateCode);
	const isTimeValid = validate(time, momentFormat.timeCode);

	// If either the date or time is invalid, return null.
	if (!isDateValid || !isTimeValid) {
		return null;
	}

	// Reformat the date and time strings into the standardized format.
	const formattedDate = moment(date, momentFormat.dateCode, true).format(
		STANDARD_MOMENT_FORMAT.dateCode
	);
	const formattedTime = moment(time, momentFormat.timeCode, true).format(
		STANDARD_MOMENT_FORMAT.timeCode
	);
	const formattedDateTime = `${formattedDate} ${formattedTime}:00`;

	return formattedDateTime;
};

// The default time '00:00:00' is considered as empty because inputting date
// without time the default time will automatically be set. Relevant for deleting.
const isDateTimeTupleEmpty = (date, time, momentFormat) =>
	!date &&
	(!time ||
		moment(time, momentFormat.timeCode, true).format(STANDARD_MOMENT_FORMAT.timeCode) === '00:00');

const defaultTime = momentFormat =>
	moment('00:00', STANDARD_MOMENT_FORMAT.timeCode, true).format(momentFormat.timeCode);

// help functions /////////////////////////////////////////////////////////////////////////

// Some dates in data base might have an information of the time zone. This will be ignored.
// It is assumed that always the local time without time zone correction must be displayed.
const ignoreZone = dateTime => {
	if (!dateTime) return dateTime;
	const index = dateTime.indexOf('+');
	index !== -1 && (dateTime = dateTime.substring(0, index));
	return dateTime;
};

export {
	formatDate,
	formatTimeStamp,
	formatDateTimeTuple,
	reformatDateTimeTuple,
	resolveDateTimeTuple,
	isDateTimeTupleEmpty,
	defaultTime,
};
