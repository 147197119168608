import React from 'react';
import { Query } from '@apollo/client/react/components';

import { LOCATIONS } from 'graphql/queries';
import { buildGenericQueryFromFilters } from 'utils';

const Locations = ({ filters, max = 100, offset = 0, sorting: sortings, children, ...props }) => {
	const variables = {
		max,
		offset,
		searchQuery: buildGenericQueryFromFilters(filters, 'location'),
		sortings,
	};

	return (
		<Query {...{ variables }} query={LOCATIONS} fetchPolicy="network-only" {...props}>
			{children}
		</Query>
	);
};

export default Locations;
