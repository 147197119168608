import stateIcons from 'configurations/stateIcons';
import { useTranslate } from 'customHooks';
import React from 'react';
import { Icon, Popup, Button } from 'semantic-ui-react';
import './EventStateQuickFilter.scss';

export default ({ value, onChangeValue }) => {
	const translate = useTranslate();

	const attributes = ['NEW', 'IN_PROGRESS', 'EXPORTED', 'DISCARDED', 'SCRAPPED'].map(key => ({
		key,
		icon: stateIcons[key],
		tooltip: translate('tooltips.info.quickfilter.' + key),
	}));

	const handleClick = key => {
		onChangeValue({ ...value, [key]: !value[key] });
	};

	return (
		<div className="EventStateQuickFilter">
			{attributes.map(({ key, icon, tooltip }) => (
				<Popup
					key={key}
					trigger={
						<Button
							className={value && value[key] ? 'enabled' : 'notenabled'}
							onClick={() => handleClick(key)}
							data-test={`quickfilterButton-${key}`}>
							<Icon name={icon} />
						</Button>
					}
					content={tooltip}
					position="bottom center"
					mouseEnterDelay={300}
					closeOnTriggerClick
				/>
			))}
		</div>
	);
};
