import { useState } from 'react';
import _ from 'lodash';

const useFilters = () => {
	const [filters, setFilters] = useState([]);

	const updateFilters = filters => setFilters(_.uniqWith(filters, _.isEqual));

	const addFilter = filter => setFilters([...filters, { ...filter, ...{ enabled: true } }]);

	return {
		filters,
		updateFilters,
		addFilter,
	};
};
export default useFilters;
