import React, { useState, useContext } from 'react';
import { Form, Input, Button, Icon } from 'semantic-ui-react';

import { InfoPopup, DeleteIcon } from 'components/Base';
import useFilterConfig from '../useFilterConfig';
import { UserContext } from 'contexts/UserContext';
import { noClickDefault, nullToEmptyStr, emptyStrToNull } from 'utils';
import { useTranslate } from 'customHooks';
import classNames from 'classnames';

import './GenericEventsPerYearInput.scss';

const EventsPerYearInput = ({ value, error, onChange, isMeslisEvents }) => {
	value = nullToEmptyStr(value, 'count', 'min', 'max', 'to_year', 'from_year');
	const translate = useTranslate();
	const { meslisEventsOperators, customerEventsOperators } = useFilterConfig();
	const [isRange, setRange] = useState(value.from_year !== value.to_year);

	const { getAvailableTime } = useContext(UserContext);
	const { minYear, maxYear } = getAvailableTime({ plusYears: 1 });

	const handleChange = changes => {
		let _value = { ...value, ...changes };
		_value = emptyStrToNull(_value, 'count', 'min', 'max', 'to_year', 'from_year');
		return onChange(_value);
	};
	const handleChangeAttr = attr => (_, target) => {
		handleChange({ [attr]: target.value });
	};

	const isDefined = v => !Number.isNaN(Number.parseInt(v));
	const handleChangeFrom = from => {
		const changes = {
			from_year: from,
			...(isRange &&
			isDefined(value.to_year) &&
			isDefined(from) &&
			from >= minYear &&
			from <= maxYear
				? { to_year: Math.max(from, value.to_year).toString() }
				: !isRange
				? { to_year: from }
				: {}),
		};
		return handleChange(changes);
	};
	const handleChangeTo = to => {
		const changes = {
			to_year: to,
			...(isRange && isDefined(value.from_year) && isDefined(to) && to >= minYear && to <= maxYear
				? { from_year: Math.min(value.from_year, to).toString() }
				: {}),
		};

		return handleChange(changes);
	};
	const handleToggleTo = () => {
		setRange(!isRange);
		handleChange({ to_year: value.from_year });
	};

	let operatorOptions = isMeslisEvents ? meslisEventsOperators : customerEventsOperators;
	operatorOptions = operatorOptions.map(({ text, info, ...props }) =>
		!!info
			? {
					...props,
					text: (
						<div>
							<span className="text">{text}</span>
							<InfoPopup content={info} position="bottom center" />
						</div>
					),
			  }
			: { text, ...props }
	);

	const _props = { fluid: true, type: 'number' };
	const _yearProps = { ..._props, min: minYear, max: maxYear };
	const _countProps = {
		..._props,
		...(!!value && value.operator.includes('Licensed')
			? { placeholder: translate('filters.count.tolerance'), min: 1 }
			: { placeholder: translate('filters.count.number'), min: 0 }),
	};

	return (
		<>
			<Form.Select
				className="operatorInput"
				data-test="operatorInput"
				value={value.operator}
				tooltip={'test'}
				options={operatorOptions}
				onChange={handleChangeAttr('operator')}
				fluid
			/>

			{value.operator !== 'between' ? (
				<Form.Input
					data-test="countInput"
					value={value.count}
					onChange={handleChangeAttr('count')}
					error={error}
					{..._countProps}
				/>
			) : (
				<Form.Group widths="equal">
					<Form.Input
						data-test="minInput"
						value={value.min}
						onChange={handleChangeAttr('min')}
						error={error}
						{..._countProps}
					/>
					<Form.Input
						data-test="maxInput"
						value={value.max}
						onChange={handleChangeAttr('max')}
						error={error}
						{..._countProps}
					/>
				</Form.Group>
			)}

			<Form.Group widths="equal">
				<Form.Field>
					<Input
						data-test="fromYearInput"
						value={value.from_year}
						onChange={(_, target) => handleChangeFrom(target.value)}
						placeholder={
							isRange ? translate('filters.range.fromYear') : translate('filters.range.year')
						}
						icon={DeleteIcon({
							onClick: () => handleChangeFrom(''),
							'data-test': 'fromYearDelete',
						})}
						{..._yearProps}></Input>
				</Form.Field>

				<Form.Field>
					<Input className={classNames('toInput', { range: isRange })} as="div">
						<Button
							type="button"
							className="yearExpandButton"
							data-test="yearExpandButton"
							icon
							onClick={noClickDefault(handleToggleTo)}
							tabIndex="0">
							<Icon className={classNames('caret', isRange ? 'right' : 'down')} />
						</Button>
						<Input
							data-test="toYearInput"
							value={value.to_year}
							style={{ visibility: isRange ? 'visible' : 'collapse' }}
							onChange={(_, target) => handleChangeTo(target.value)}
							placeholder={translate('filters.range.toYear')}
							icon={DeleteIcon({ onClick: () => handleChangeTo(''), 'data-test': 'toYearDelete' })}
							{..._yearProps}
						/>
					</Input>
				</Form.Field>
			</Form.Group>
		</>
	);
};

const GenericEventsPerYearInput = ({ isMeslisEvents }) => {
	return ({ value, error, onChange }) => (
		<EventsPerYearInput {...{ value, error, onChange, isMeslisEvents }} />
	);
};

export default GenericEventsPerYearInput;
