import React from 'react';
import { Button } from 'semantic-ui-react';

import Meslis from 'components/Meslis';
import { useFetchSize, useTranslate } from 'customHooks';

const ClearLeaflet = ({
	leafletId,
	offset,
	sorting,
	clearing,
	setClearing,
	onCleared = () => {},
}) => {
	const { loading: loadingMax, fetchSize: max } = useFetchSize(`process-${leafletId}`);
	const translate = useTranslate();

	const onError = error => {
		alert(translate('alerts.leafletNotBeCleared', [error]));
		onCleared();
	};

	return (
		<Meslis.EmptyCustomLeaflet
			{...{ offset, sorting, max, leafletId, onError, skip: loadingMax }}
			onCompleted={onCleared}>
			{clearLeaflet => (
				<Button
					negative
					loading={clearing}
					disabled={clearing}
					onClick={() => {
						setClearing(true);
						clearLeaflet();
					}}>
					{translate('leaflets.clear')}
				</Button>
			)}
		</Meslis.EmptyCustomLeaflet>
	);
};

export default ClearLeaflet;
