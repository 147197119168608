import React, { useState } from 'react';

import Content from './Content';
import { useSorting } from 'customHooks';

import './index.scss';
import { order } from 'enums';

// setup similar to EventsWorkflow
const History = () => {
	const { sorting, shownSorting, sortByField, onCtrlUp } = useSorting(
		'customLeaflet.timestamp',
		order.DESC,
		true
	);
	const [lastSelections, setLastSelections] = useState([]);
	const [forwardSelections, setForwardSelections] = useState([]);

	const handleSortByField = (...obj) => {
		setLastSelections(lastSelections);
		setForwardSelections(forwardSelections);
		sortByField(...obj);
	};

	return (
		<div className="History" data-test="My History">
			<Content
				storageMemoKey="history"
				onSort={handleSortByField}
				{...{ sorting, shownSorting, onCtrlUp, lastSelections, forwardSelections }}
			/>
		</div>
	);
};

export default History;
