export const [DEFAULT_WIDTH, DEFAULT_MIN_WIDTH] = [300, 100];
export const MAX_ADJUST_WIDTH = 1000; // only for smartAdjust, there is no limit for manually resizing
export const ADJUST_WIDTH_OFFSET = 20;
export const ADJUST_WIDTH_HEADER_OFFSET = 40;
export const ADJUST_WIDTH_ITEM_ARRAY_OFFSET = 50;

// tableKeys
export const [EVENT, LOCATION, ORGANIZER, SEARCH_QUERY, SELECTION] = [
	['events'],
	['location-search'],
	['organizer-search'],
	['search-query'],
	['selection'],
];

export const ALL_WORKFLOW_KEYS = [...EVENT, ...LOCATION, ...ORGANIZER];
export const ALL_GENERIC_KEYS = [...LOCATION, ...ORGANIZER];
export const FULL_WIDTH_TABLES = [...SEARCH_QUERY, ...SELECTION]; //These tables have full width even if width definitions of columns sum up to less width than appropriate.

export const DEFAULT_WIDTHS = [
	// hint 1 If table key not defined the definition is applied to tables of ALL_WORKFLOW_KEYS.
	// hint 2 Width can be more than the width definition if having FULL_WIDTH_TABLES and having more space than total width and max not set.
	{
		fields: ['selectionColumn'],
		width: 40, // width has to be the same as in VT.scss
		fixWidth: true,
	},
	{
		fields: ['collaboration'],
		// width has to be the same as in VT.scss
		width: 40, // width: 55 when viewing users is implemented
		fixWidth: true,
	},
	{
		fields: ['stateInfo'],
		width: 40, // width has to be the same as in VT.scss
		fixWidth: true,
	},
	{
		fields: ['customLeaflet.name'],
		tableKeys: EVENT,
		width: 160,
	},
	{
		fields: ['customLeaflet.username'],
		tableKeys: EVENT,
		width: 195,
	},
	{
		fields: ['customLeaflet.timestamp'],
		tableKeys: EVENT,
		width: 165,
	},
	{
		fields: ['countOfExported'],
		tableKeys: EVENT,
		width: 120,
		min: 30,
	},
	{
		fields: ['id', 'organizers.id'],
		width: 155,
		min: 60,
	},
	{
		fields: ['location.id'],
		width: 180,
		min: 60,
	},
	{
		fields: ['matchedLocationIds'],
		width: 280,
	},
	{
		fields: ['artists', 'lineup', 'price'],
		width: 210,
	},
	{
		fields: ['eventCountTotal', 'statistic.eventCountTotal'],
		width: 220,
		min: 40,
	},
	{
		fields: [
			'eventCountRelevant',
			'statistic.eventCountRelevant',
			'eventCountIrrelevant',
			'statistic.eventCountIrrelevant',
		],
		width: 240,
		min: 40,
	},
	{
		fields: ['admission', 'visitorCount', 'commercialId'],
		width: 175,
	},
	{
		fields: ['rating'],
		width: 175,
		min: 110,
	},
	{
		fields: ['name', 'location.name', 'organizers.name'],
		width: 400,
		min: 150,
	},
	{
		fields: ['startDate', 'endDate'],
		width: 195,
	},

	{
		fields: ['statistic.eventCountPerYear'],
		tableKeys: ALL_GENERIC_KEYS,
		width: 260,
	},
	{
		fields: ['timestamp'],
		tableKeys: EVENT,
		width: 210,
	},
	{
		fields: ['isCancelled'],
		tableKeys: EVENT,
		width: 180,
		min: 40,
	},
	{
		fields: ['isLocationSameAsOrganizer'],
		tableKeys: EVENT,
		width: 350,
		min: 40,
	},
	{
		fields: ['advertisements.totalCount'],
		tableKeys: EVENT,
		width: 250,
		min: 40,
	},
	{
		fields: ['organizersCount'],
		tableKeys: EVENT,
		width: 260,
		min: 40,
	},
	{
		fields: ['selectedOrganizer.id'],
		tableKeys: EVENT,
		width: 280,
	},
	{
		fields: ['address.streetHouseNumber', 'location.address.streetHouseNumber', 'organizers.address.streetHouseNumber'],
		width: 220,
		min: 40,
	},
	{
		fields: ['address.postalCode', 'organizers.address.postalCode'],
		width: 140,
		min: 60,
	},
	{
		fields: ['location.address.postalCode'],
		width: 170,
		min: 60,
	},
	{
		fields: [
			'address.city',
			'organizers.address.city',
			'address.region',
			'organizers.address.region',
			'address.country',
			'organizers.address.country',
		],
		width: 160,
	},
	{
		fields: ['location.address.city', 'location.address.region', 'location.address.country'],
		width: 185,
	},

	{
		fields: ['location.category'],
		width: 205,
	},
	{
		fields: ['customerOrganizers'],
		width: 220,
	},
	{
		fields: ['customerLocations'],
		width: 255,
	},

	// SEARCH_QUERY
	{
		fields: ['type'],
		tableKeys: SEARCH_QUERY,
		width: 70,
		min: 70,
		max: 70,
	},
	{
		fields: ['id'],
		tableKeys: SEARCH_QUERY,
		width: 70,
		min: 50,
		max: 120,
	},
	{
		fields: ['name'],
		tableKeys: SEARCH_QUERY,
		width: 200,
	},
	{
		fields: ['createdAt'],
		tableKeys: SEARCH_QUERY,
		width: 100,
		min: 100,
		max: 100,
	},
	{
		fields: ['filters'],
		tableKeys: SEARCH_QUERY,
		width: 70,
		min: 40,
		max: 140,
	},
	{
		fields: ['hits'],
		tableKeys: SEARCH_QUERY,
		width: 70,
		min: 40,
		max: 140,
	},
	{
		fields: ['owner'],
		tableKeys: SEARCH_QUERY,
		width: 150,
	},
	{
		fields: ['share'],
		tableKeys: SEARCH_QUERY,
		width: 600,
		min: 600,
		max: 600,
	},
	{
		fields: ['actions'],
		tableKeys: SEARCH_QUERY,
		width: 145,
		min: 145,
		max: 145,
	},

	// SELECTION
	{
		fields: ['filename'],
		tableKeys: SELECTION,
		width: 200,
	},
	{
		fields: ['createdAt'],
		tableKeys: SELECTION,
		width: 100,
		min: 80,
		max: 100,
	},
	{
		fields: ['description'],
		tableKeys: SELECTION,
		width: 600,
	},
	{
		fields: ['downloadCount'],
		tableKeys: SELECTION,
		width: 100,
		min: 40,
		max: 120,
	},
	{
		fields: ['actions'],
		tableKeys: SELECTION,
		width: 200,
		min: 200,
		max: 200,
	},
].map(definition => {
	definition.tableKeys = definition.tableKeys || ALL_WORKFLOW_KEYS;
	return definition;
});
