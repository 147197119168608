import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';
import { GrowingInput } from '.';
import { useDelay, useOrganizerLocationQueryById } from 'customHooks';
import { Input } from 'semantic-ui-react';
import ItemArrayList from './ItemArrayList';

const OrganizerLocationInput = ({ value, onChange, type, fieldName, isGrowingInput, ...props }) => {
	let fieldValue = (value && value[fieldName]) || '';
	let restValues;
	const count = (value?.count || 0) + 1;

	const [inputValue, setInputValue] = useState('');
	const isActiveRef = useRef(false);

	const [{ invalid, loading }, requestItem] = useOrganizerLocationQueryById({
		onResponse: (item, valid) => {
			setInputValue(item?.[fieldName] || '');
			onChange({ ...(item || {}), count }, valid);
		},
		type,
		fieldName,
	});

	if (Array.isArray(fieldValue) && fieldValue.length) {
		if (fieldValue.includes(inputValue)) {
			restValues = fieldValue.filter(value => value !== inputValue);
			fieldValue = inputValue;
		} else {
			[fieldValue, ...restValues] = fieldValue;
		}
	}

	const [startAction, stopAction] = useDelay(800);

	const handleChanged = value => {
		stopAction();
		startAction(() => {
			requestItem(value);
		});
	};

	// active behaviour: start query after manual update
	useEffect(() => {
		if (!isActiveRef.current) return;
		handleChanged(inputValue);
		!inputValue && onChange(null);
		// eslint-disable-next-line
	}, [inputValue]);

	// passive behaviour: update by incoming value without querying
	useLayoutEffect(() => {
		if (isActiveRef.current) {
			isActiveRef.current = false;
			return;
		}
		requestItem(null);
		setInputValue(fieldValue);

		// Needs to be invoked on every change in order to reset isActiveRef
		// even if value remains null (invalid). Therefore count as dependency.
		// eslint-disable-next-line
	}, [count]);

	const inputProps = {
		value: inputValue,
		onChange: (_, { value }) => {
			if (value !== inputValue) {
				isActiveRef.current = true;
				setInputValue(value);
			}
		},
		error: invalid,
		loading: loading,
		...props,
	};

	const InputComponent = isGrowingInput ? GrowingInput : Input;

	return (
		<>
			<InputComponent widthOffset={30} isWidthEmpty {...inputProps} />
			{restValues && <ItemArrayList data={restValues} />}
		</>
	);
};

export const LocationInput = ({ ...props }) =>
	OrganizerLocationInput({ type: 'location', ...props });
export const OrganizerInput = ({ ...props }) =>
	OrganizerLocationInput({ type: 'organizer', ...props });
