import { useState } from 'react';
import { isSelectableAndUnlocked } from 'utils';
import { useUserContext } from 'customHooks';

const useSelectable = ({ max, onMaxReached = () => {} }) => {
	let [selection, setSelection] = useState([]);
	const { user } = useUserContext();

	const setTrimmedSelection = items => {
		items = items.filter(item => isSelectableAndUnlocked(item, user?.username));
		setSelection(items.slice(0, max));
		if (items.length > max) onMaxReached();
	};
	const addSelected = e => setTrimmedSelection([...selection, e]);
	const isSelected = e => selection.some(x => e?.id === x?.id);
	const removeSelected = e => setSelection(selection.filter(x => e?.id !== x?.id));
	const swapSelected = e => (isSelected(e) ? removeSelected(e) : addSelected(e));
	const addBulkSelected = e =>
		setTrimmedSelection(
			[...selection, ...e].filter(
				(item, index) => [...selection, ...e].findIndex(e => e?.id === item?.id) === index
			)
		);

	const removeBulkSelected = e =>
		setSelection(selection.filter(x => !e.some(y => y?.id === x?.id)));

	return [
		selection,
		setTrimmedSelection,
		swapSelected,
		removeSelected,
		addBulkSelected,
		removeBulkSelected,
		isSelected,
	];
};

export default useSelectable;
