import getDictionary from 'i18n/getDictionary';
import browserStorage from 'browserStorage';
import getBrowserLanguage from './getBrowserLanguage';
import getTranslator from './getTranslator';

// Please, use hook useTranslate where ever possible because this is not instantly updating when language changes.
//  This should only be used outside of react components where hooks cannot be used, e.g. for alerts.
const getTranslatorOfCurrentLanguage = () => (path, parameters) => {
	const { languageCode } = browserStorage.getUserSettings() || {};
	const translate = getTranslator(getDictionary(languageCode || getBrowserLanguage()));
	return translate(path, parameters);
};
export default getTranslatorOfCurrentLanguage;
export { getTranslatorOfCurrentLanguage };
