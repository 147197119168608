import React from 'react';
import { Button } from 'semantic-ui-react';
import { useQueryParams, useTranslate } from 'customHooks';

import '../EventsWorkflow/Navi.scss';

const Navi = () => {
	const { step, pushURL, genericOffset } = useQueryParams({ step: 'search' });
	const translate = useTranslate();

	const buttonProps = id => ({
		key: id,
		fluid: true,
		color: 'blue',
		className: step === id ? 'activeNavButton' : '',
		onClick: () => {
			pushURL({
				step: id,
				view: 'table',
				genericOffset: typeof genericOffset === 'number' && step !== 'search' ? genericOffset : 0,
				offset: 0,
			});
		},
	});

	const buttons = [
		{ id: 'search', text: translate('navigation.search') },
		{ id: 'select', text: translate('navigation.select') },
		{ id: 'process', text: translate('navigation.process') },
	];

	return (
		<div className="Navi">
			{buttons.map(button => (
				<Button {...buttonProps(button.id)} data-test={`${button.id}StepButton`}>
					{button.text}
				</Button>
			))}
		</div>
	);
};

export default Navi;
