import React from 'react';
import { Dropdown, Label, Icon } from 'semantic-ui-react';
import './ToCustomLeaflet.scss';
import { useTranslate } from 'customHooks';

const ToCustomLeaflet = ({ onActionClick, leaflets, ...props }) => {
	const translate = useTranslate();

	// id = id of leaflet that event should be moved to
	const handleLeafletClick = (id, name) => onActionClick(id, translate('actions.moveTo', [name]));

	return (
		<div className="ToCustomLeaflet">
			<Label>
				{translate('actions.moveToLeaflet')} <Icon name="clipboard" />
			</Label>
			<Dropdown.Menu className={'leaflet-menu'} scrolling>
				{!!leaflets &&
					leaflets.length &&
					leaflets.map(item => (
						<Dropdown.Item
							className={'leafletName'}
							onClick={handleLeafletClick(item.id, item.name)}
							key={item.id}
							{...props}>
							{item.name}
						</Dropdown.Item>
					))}
			</Dropdown.Menu>
		</div>
	);
};

export default ToCustomLeaflet;
