// sessionStorage object ////////////////////////////////////////////////////////////
const getObject = key => JSON.parse(sessionStorage.getItem(key));
const setObject = (key, value) => sessionStorage.setItem(key, JSON.stringify(value));
const removeObject = key => sessionStorage.removeItem(key);

// sessionStorage boolean ////////////////////////////////////////////////////////////

const getBoolean = key => sessionStorage.getItem(key) === 'true';
const setBoolean = (key, value) => sessionStorage.setItem(key, `${value}`);

// sessionStorage number /////////////////////////////////////////////////////////////

const getNumber = key => Number.parseInt(sessionStorage.getItem(key));
const setNumber = (key, num) => sessionStorage.setItem(key, num);

// sessionStorage item /////////////////////////////////////////////////////////////

const getItem = key => sessionStorage.getItem(key);

/////////////////////////////////////////////////////////////////////////////////////

const isSidebarCollapsed = () => getBoolean('sidebar-collapsed');
const setSidebarCollapsed = collapsed => setBoolean('sidebar-collapsed', collapsed);

const isSecondarySidebarCollapsed = () => getBoolean('second-sidebar-collapsed');
const setSecondarySidebarCollapsed = collapsed => setBoolean('second-sidebar-collapsed', collapsed);

const isSetListExpanded = () =>
	getItem('setlist-expanded') == null || getBoolean('setlist-expanded');
const setSetListExpanded = expanded => setBoolean('setlist-expanded', expanded);

/////////////////////////////////////////////////////////////////////////////////////
const setUndoList = val => setObject('undoList', val);
const getUndoList = () => getObject('undoList') || [];

const getLastFilters = type => getObject(`${type}-filters`) || [];
const setLastFilters = (type, filters) => setObject(`${type}-filters`, filters);
const removeLastFilters = () => {
	removeObject('EVENT-filters');
	removeObject('LOCATION-filters');
	removeObject('ORGANIZER-filters');
	removeObject('EVENT-quickFilter');
};

const getEventQuickFilter = () => getObject('EVENT-quickFilter');
const setEventQuickFilter = val => setObject('EVENT-quickFilter', val);

const getIdsInFilters = fieldName => getObject(`idsInFilters_${fieldName}`);
const setIdsInFilters = (fieldName, val) => setObject(`idsInFilters_${fieldName}`, val);

const getDetailTab = () => getNumber('detail-tab') || 0;
const setDetailTab = tab => setNumber('detail-tab', tab);

const getSortLeaflet = () => getObject('sortLeaflet');
const setSortLeaflet = val => setObject('sortLeaflet', val);

const getEmptiedEventId = () => getObject('emptiedEventId');
const setEmptiedEventId = val => setObject('emptiedEventId', val);

export default {
	isSidebarCollapsed,
	setSidebarCollapsed,
	isSecondarySidebarCollapsed,
	setSecondarySidebarCollapsed,
	isSetListExpanded,
	setSetListExpanded,
	setUndoList,
	getUndoList,
	getLastFilters,
	setLastFilters,
	removeLastFilters,
	getEventQuickFilter,
	setEventQuickFilter,
	getIdsInFilters,
	setIdsInFilters,
	getDetailTab,
	setDetailTab,
	getSortLeaflet,
	setSortLeaflet,
	getEmptiedEventId,
	setEmptiedEventId,
};
