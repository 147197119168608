// this function is used to set the default for functions (for functions / functional components arguments)
// by using this exported function instead of "() => {}" the advantage is, that no warning for arguments that are passed in the real function are thrown
// example:
// const functionalComponent = ({doesntThrowWarning = noop, throwsWarning = () => {}, alsoNoWarning = () => {}}) => {
//   doesntThrowWarning(hasArgument); // has at least 1 argument
//   throwsWarning(hasArgument); // has at least 1 argument
//   alsoNoWarning; // has 0 arguments
// }
// the warning usually throws when there is at least 1 argument, but default doesnt have any arguments, noop "counters" this behavior

export default () => {};
