import React from 'react';
import { useTranslate } from 'customHooks';
import { Button, Icon, Popup } from 'semantic-ui-react';

const Table = props => {
	const translate = useTranslate();
	return (
		<Popup
			trigger={
				<Button data-test="tableViewButton" icon {...props}>
					<Icon name="table" />
				</Button>
			}
			on="hover"
			content={
				<>
					<b>{translate('actions.table')}</b>
					<br />
				</>
			}
			position="bottom right"
			closeOnTriggerClick
		/>
	);
};

export default Table;
