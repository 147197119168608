import { useEffect, useLayoutEffect, useState } from 'react';
import { MAX_SHOWN_ADS } from 'config';
import { analyseMergedEvent } from 'utils';
import { setHighlightColoredIdx } from '.';

const useMergeEventDetail = ({ data, isWaitingForUpdate }) => {
	const adsWrapper = analyseMergedEvent.getAdsWrapper(data);
	const [{ isMergedMode, shownAdIdx, adOffset }, setShownAdStates] = useState(DEFAULT_AD_STATES);
	const shownAdWrapper = adsWrapper[shownAdIdx] || {};
	useLayoutEffect(
		() => setShownAdStates(DEFAULT_AD_STATES),
		// eslint-disable-next-line
		[data.id] // only update if data has changed, no other dependencies
	);
	useEffect(
		() => {
			shownAdIdx >= data.advertisements.totalCount &&
				data.advertisements.totalCount !== 0 &&
				setShownAdIdx(data.advertisements.totalCount - 1);
		},
		// eslint-disable-next-line
		[data.advertisements.totalCount] // only update if totalCount has changed, no other dependencies
	);
	const { mergingData, isInconsistent } = analyseMergedEvent.getMergingData({
		mergedEvent: data,
		shownAdWrapper,
		isMergedMode,
		comparedFields: COMPARED_FIELDS,
		comparedArrays: COMPARED_ARRAYS,
		notMergedAdFields: NOT_MERGED_AD_FIELDS,
		titleURLSameAs: TITLE_URL_SAME_AS,
	});

	const recomputeOffset = idx => Math.floor(idx / MAX_SHOWN_ADS) * MAX_SHOWN_ADS;

	const forwardBackwardAd = (isForward, isSetSingleMode) => () => {
		const [_shownAdIdx, _isMergedMode] =
			isSetSingleMode && isMergedMode
				? [shownAdIdx, false] // set to single mode before incrementing/ decrementing
				: [shownAdIdx + (isForward ? 1 : -1), isMergedMode];
		let _adOffset = recomputeOffset(_shownAdIdx);
		setShownAdStates({
			shownAdIdx: _shownAdIdx,
			isMergedMode: _isMergedMode,
			adOffset: _adOffset,
		});
	};

	const forwardAdInfoBlock =
		isMergedMode || shownAdWrapper.idx !== adsWrapper.length - 1
			? forwardBackwardAd(true, true)
			: null;
	const forwardAdIdSwitcher =
		shownAdWrapper.idx !== adsWrapper.length - 1 ? forwardBackwardAd(true, false) : null;
	const backwardAdInfoBlock =
		isMergedMode || shownAdWrapper.idx !== 0 ? forwardBackwardAd(false, true) : null;
	const backwardAdIdSwitcher = shownAdWrapper.idx !== 0 ? forwardBackwardAd(false, false) : null;

	const toggleMergedModeOfAd = ({ id, idx }) =>
		setShownAdStates(
			isMergedMode
				? {
						isMergedMode: false,
						shownAdIdx: idx,
						adOffset: recomputeOffset(idx),
				  }
				: {
						isMergedMode: !isWaitingForUpdate && id === shownAdWrapper.id,
						shownAdIdx: idx,
						adOffset: recomputeOffset(idx),
				  }
		);

	const setShownAdIdx = idx =>
		setShownAdStates({
			isMergedMode,
			shownAdIdx: idx,
			adOffset: recomputeOffset(idx),
		});

	const toggleMergedMode = () => {
		if (isWaitingForUpdate) return;
		isMergedMode && setHighlightColoredIdx(false, []);
		setShownAdStates({ isMergedMode: !isMergedMode, shownAdIdx, adOffset });
	};

	return {
		adsWrapper,
		setShownAdStates,
		shownAdIdx,
		shownAdWrapper,
		isMergedMode,
		mergingData,
		adOffset,
		isInconsistent,
		setShownAdIdx,
		forwardAdInfoBlock,
		forwardAdIdSwitcher,
		backwardAdInfoBlock,
		backwardAdIdSwitcher,
		toggleMergedModeOfAd,
		toggleMergedMode,
	};
};

const DEFAULT_AD_STATES = {
	shownAdIdx: 0,
	isMergedMode: true,
	adOffset: 0,
};

const COMPARED_FIELDS = [
	{ name: 'name' },
	{ name: 'startDate' },
	{ name: 'endDate' },
	{ name: 'timestamp' },
	{ name: 'visitorCount' },
	{ name: 'isCancelled', noSourceValue: false }, // only show markers if value is true because if false it always applies for all sources
	{ name: 'isLocationSameAsOrganizer', noSourceValue: false },
];
const COMPARED_ARRAYS = [
	{ name: 'artists', mapComparedValue: artist => artist.id },
	{ name: 'admission', mapComparedValue: admission => admission.price },
	{ name: 'lineup' },
	{ name: 'url', isOnlyInAd: true },
	{ name: 'organizers', mapComparedValue: organizer => organizer.id },
];
const NOT_MERGED_AD_FIELDS = ['pdfId', 'description'];
const TITLE_URL_SAME_AS = 'name';

export default useMergeEventDetail;
