import classNames from 'classnames';
import React from 'react';
import { Rating, Select } from 'semantic-ui-react';

import './RatingInput.scss';

const RatingInput = ({ value, onChange, className, ...props }) => {
	const ratingOptions = [0, 1, 2, 3, 4, 5].map(stars => ({
		text: <Rating maxRating="5" rating={stars} icon="star" disabled />,
		value: stars,
	}));

	return (
		<Select
			className={classNames(className, 'RatingInput')}
			value={value}
			options={ratingOptions}
			onChange={(_, target) => onChange(target.value)}
			{...props}
		/>
	);
};

export default RatingInput;