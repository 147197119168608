import React from 'react';
import { Icon } from 'semantic-ui-react';

import './LabeledIcon.scss';

export default ({ label, ...props }) => (
	<span className="labeled-icon">
		<Icon {...props} />
		{label !== 0 && label != null && <div className="icon-label">{label}</div>}
	</span>
);
