import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Dropdown, Icon, Button as SUIButton } from 'semantic-ui-react';

import Button from 'components/Button';
import { useTranslate, useUserContext } from 'customHooks';
import { isSelectableAndUnlocked } from 'utils';
import { LEAFLET_NAMES } from 'graphql/queries';
import { CONFLICT } from 'config';
import classNames from 'classnames';

import './ActionsDropdown.scss';

const ActionsDropdown = ({
	className,
	events = [],
	disabled,
	onSelected,
	shownElements,
	isModalLayout,
	placeholder,
	noOptionId,
}) => {
	const { data: allLeaflets } = useQuery(LEAFLET_NAMES);
	const leaflets =
		allLeaflets &&
		allLeaflets.getAllLeaflets &&
		allLeaflets.getAllLeaflets.filter(l => {
			return l.id !== noOptionId && l.id !== CONFLICT;
		});

	const [stageAction, setStageAction] = useState();
	const translate = useTranslate();
	const { user } = useUserContext();

	// disable buttons for no selectable events and unselectable items in detailView
	disabled = !!(
		disabled ||
		!events.length ||
		events.some(ev => isSelectableAndUnlocked(ev, user?.username) === false)
	);

	const handleMoveLeaflet = (targetLeafletId, actionName) => () => {
		setStageAction({
			targetLeafletId,
			actionName,
		});
	};

	useEffect(
		() => onSelected && onSelected(stageAction),
		// eslint-disable-next-line
		[stageAction] // onSelected not needed for dependency array
	);

	return (
		<div className={classNames('Actions', className, { stagingSelectedAction: isModalLayout })}>
			{leaflets && (
				<>
					<Dropdown
						className={
							'icon actions-dropdown' + (isModalLayout && !!stageAction ? ' stageAction' : '')
						}
						data-test="actions-dropdown"
						text={
							isModalLayout
								? !!stageAction
									? stageAction.actionName
									: placeholder
								: translate('actions.actions')
						}
						icon="dropdown"
						direction="left"
						button
						disabled={disabled}>
						<Dropdown.Menu>
							{/* Option to keep in same leaflet in ExportModal */}
							{shownElements.keepInLeaflet && (
								<Dropdown.Item className={'action-item'}>
									<Button.Keep
										disabled={disabled}
										onClick={handleMoveLeaflet(
											shownElements.keepInLeaflet,
											translate('actions.keep')
										)}
									/>
								</Dropdown.Item>
							)}

							{shownElements.leaflets && (
								<Button.ToCustomLeaflet
									onActionClick={handleMoveLeaflet}
									disabled={disabled}
									{...{ leaflets }}
								/>
							)}

							{shownElements.drop && (
								<Dropdown.Item className={'action-item'}>
									<Button.Drop disabled={disabled} onClick={handleMoveLeaflet(-1)} />
								</Dropdown.Item>
							)}

							{shownElements.discard && (
								<Dropdown.Item className={'action-item'}>
									<Button.Discard disabled={disabled} onClick={handleMoveLeaflet(4)} />
								</Dropdown.Item>
							)}

							{shownElements.scrap && (
								<Dropdown.Item className={'action-item'}>
									<Button.Scrap disabled={disabled} onClick={handleMoveLeaflet(3)} />
								</Dropdown.Item>
							)}
						</Dropdown.Menu>
					</Dropdown>

					{/* Button to remove the selected leafleat in ExportModal */}
					{shownElements.cancelSelection && (
						<SUIButton
							className="clearButton"
							onClick={() => setStageAction(null)}
							disabled={!stageAction}>
							<Icon name="close" color="red" />
						</SUIButton>
					)}
				</>
			)}
		</div>
	);
};

export default ActionsDropdown;
