import React from 'react';
import 'semantic-ui-css/semantic.min.css';

import { AppLayout } from './Layout';
import { Loader } from './Base';
import LoggedInSwitch from 'components/Switchs/LoggedInSwitch';
import LoggedOutSwitch from 'components/Switchs/LoggedOutSwitch';

import { UserContext } from 'contexts/UserContext';

import './App.scss';
import './swal.scss';

const App = () => {
	const { loading, user } = React.useContext(UserContext);
	const reset = window.location.pathname === '/verify-password-reset';

	return !reset ? (
		<AppLayout>{loading ? <Loader /> : user ? <LoggedInSwitch /> : <LoggedOutSwitch />}</AppLayout>
	) : (
		<LoggedOutSwitch />
	);
};

export default App;
