import React from 'react';
import { Form, Input } from 'semantic-ui-react';
import { useTranslate } from 'customHooks';

const defaultValue = { from: '', to: '' };

const NumberRangeInput = ({ value, error, onChange }) => {
	const handleChange = attr => (e, target) => onChange({ ...value, ...{ [attr]: target.value } });
	const translate = useTranslate();

	const _props = { fluid: true, type: 'number', min: 0, error };

	return (
		<>
			<Form.Field>
				<Input
					{..._props}
					value={value.from || defaultValue.from}
					onChange={handleChange('from')}
					placeholder={translate('filters.range.from')}
					data-test="number-range-from"
				/>
			</Form.Field>

			<Form.Field>
				<Input
					{..._props}
					value={value.to || defaultValue.to}
					onChange={handleChange('to')}
					placeholder={translate('filters.range.to')}
					data-test="number-range-to"
				/>
			</Form.Field>
		</>
	);
};

export default NumberRangeInput;
