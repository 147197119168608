import { Query } from '@apollo/client/react/components';
import { EVENTS_COUNT } from 'graphql/queries';
import React from 'react';
import { buildEventsQueryFromFilters, checkFiltersEmptyResult as checkEmpty } from 'utils';

const EventsCount = ({ children, filters = [], leafletIds = [], ...props }) => {
	const variables = {
		max: 0,
		searchQuery: buildEventsQueryFromFilters(filters, { leafletIds }),
	};

	return (
		checkEmpty({ filters, children }) || (
			<Query query={EVENTS_COUNT} fetchPolicy="network-only" variables={variables} {...props}>
				{children}
			</Query>
		)
	);
};
export default EventsCount;
