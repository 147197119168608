import React from 'react';
import { Button, Icon, Popup } from 'semantic-ui-react';

import { useTranslate } from 'customHooks';

const Drop = ({ ...props }) => {
	const translate = useTranslate();

	return (
		<Popup
			trigger={
				<Button icon labelPosition="right" color="blue" basic {...props}>
					{translate('actions.drop')}
					<Icon name="arrow left" />
				</Button>
			}
			content={
				<>
					<b>{translate('tooltips.dropButtonHeader')}</b>
					<br />
					{translate('tooltips.dropButton')}
				</>
			}
			on="hover"
			mouseEnterDelay={500}
			disabled={props.disabled}
			position="bottom right"
			closeOnTriggerClick
		/>
	);
};

export default Drop;
