import React from 'react';
import LeafletBox from '../LeafletBox';
import './LeafletItem.scss';

const LeafletItem = ({ leafletData, onActionCompleted, editableLeaflet }) => {
	return (
		<div className="LeafletItem">
			<LeafletBox
				selection={[]}
				showEdit
				showInfo
				editLeaflet
				rerouteViewButtonTo="events"
				{...{ editableLeaflet, onActionCompleted, leafletData }}
			/>
		</div>
	);
};

export default LeafletItem;
