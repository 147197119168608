import React from 'react';

import useFilterConfig from 'components/FilterWidget/useFilterConfig';
import './Action.scss';

export default ({ enabled = true, onToggle, selectAction }) => {
	const { selectActions } = useFilterConfig();
	const color = selectAction.includes('include') ? 'green' : 'red';

	const handleClick = () => enabled && onToggle();

	return (
		<span className="Action">
			<span className={color} onClick={handleClick}>
				{selectActions.find(x => x.value === selectAction).text}
			</span>
		</span>
	);
};
