import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import { useTranslateContext } from 'customHooks';
import stateIcons from 'configurations/stateIcons';
import { InfoUser, LabeledIcon } from '.';

import './StateInfo.scss';

export default ({
	state = 'IN_PROGRESS',
	leafletName,
	changingUser,
	timestamp,
	countOfExported,
	heading,
}) => {
	const { translate, formatTimeStamp } = useTranslateContext();
	const iconName = stateIcons[state];
	countOfExported = countOfExported || 0;
	const timestampStr = formatTimeStamp(timestamp, true);
	changingUser = changingUser && <InfoUser username={changingUser} />;

	const pfx = 'tooltips.info.cell.';

	const tooltip = heading ? (
		<b>{translate('tableView.infoState')}</b>
	) : (
		<span>
			{translate(pfx + 'infoIs')} <Icon name={iconName} /> <b>{translate(pfx + state)}</b>. <br />
			{translate(pfx + 'itWas_exported', [
				<b>
					{countOfExported === 1
						? translate(pfx + 'once')
						: countOfExported === 2
						? translate(pfx + 'twice')
						: `${countOfExported} ` + translate(pfx + 'times')}
				</b>,
			])}
			<br />
			{!!leafletName ? (
				<>
					{translate(pfx + 'currLeafletIs')} <b>{leafletName}</b>.
				</>
			) : (
				<>{translate(pfx + 'noLeaflet')}.</>
			)}
			<br />
			{!!timestampStr ? (
				<>
					{translate(pfx + 'lastChangeWas')} <b>{timestampStr}</b>.
				</>
			) : (
				<>{translate(pfx + 'timestampUnknown')}.</>
			)}
			<br />
			{!!changingUser ? (
				<>
					{translate(pfx + 'changingUserWas')} <b>{changingUser}</b>.
				</>
			) : (
				<>{translate(pfx + 'userUnknown')}.</>
			)}
			<br />
		</span>
	);

	return (
		<Popup
			position="bottom center"
			content={tooltip}
			mouseEnterDelay={300}
			trigger={
				<LabeledIcon
					className="EventStateIcon"
					name={iconName}
					label={countOfExported}
					data-test={state}
				/>
			}
		/>
	);
};
