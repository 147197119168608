import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import classNames from 'classnames';
import { useTranslate } from 'customHooks';

import { order as orderEnum } from 'enums';

import './SortIcon.scss';

export default ({
	sortField,
	sortable = true,
	sorting,
	onSort = () => {},
	icon,
	multipleSort,
	isHidden,
}) => {
	const translate = useTranslate();

	if (isHidden) return <></>;

	const sortingItem = sorting.find(item => item.field === sortField);
	let sortingIndex = sorting.findIndex(item => item.field === sortField);
	sortingIndex = sortingIndex === -1 || sorting.length === 1 ? null : sortingIndex + 1;
	const order = sortingItem && sortingItem.orderBy;

	const popupProps = {
		mouseEnterDelay: 800,
		wide: true,
		position: 'bottom center',
	};

	const SortableIcon = () => (
		<span
			className={classNames('SortIcon', !order && 'no-value')}
			data-test="SortIcon"
			data-test-direction={
				order === orderEnum.ASC ? 'asc' : order === orderEnum.DESC ? 'desc' : null
			}
			onClick={e => onSort(e, sortField)}>
			<Popup
				trigger={<Icon name={order === orderEnum.DESC ? 'caret down' : 'caret up'} />}
				content={<TooltipContent {...{ translate, order, sortingIndex, multipleSort }} />}
				{...popupProps}
			/>
			{sortingIndex !== 0 && sortingIndex != null && (
				<div className="icon-label">{sortingIndex}</div>
			)}
		</span>
	);

	const UnsortableIcon = () => (
		<Popup
			trigger={<Icon className={classNames('SortIcon', 'unsortable', 'no-value')} name="dont" />}
			content={<b>{translate('tooltips.sortIcon.notSortable')}</b>}
			{...popupProps}
		/>
	);

	const SortIcon = sortable ? <SortableIcon /> : <UnsortableIcon />;

	return !!icon ? (
		<div className="sortIcon-wrapper">
			{icon}
			{sortable && SortIcon}
		</div>
	) : (
		SortIcon
	);
};

const TooltipContent = ({ translate, order, sortingIndex, multipleSort }) => (
	<>
		{
			// telling sort direction
			{
				[orderEnum.DESC]: (
					<b>
						{translate('tooltips.sortIcon.descSort')}
						<br />
						<br />
					</b>
				),
				[orderEnum.ASC]: (
					<b>
						{translate('tooltips.sortIcon.ascSort')}
						<br />
						<br />
					</b>
				),
			}[order] || (
				// prompt to sort if not sorted
				<i>
					<b>{translate('tooltips.sortIcon.clickToSort')}</b>
				</i>
			)
		}
		{
			// telling priority of this column
			!!sortingIndex && (
				<b>
					{translate('tooltips.sortIcon.prioSort', [sortingIndex])}
					<br />
					<br />
				</b>
			)
		}
		{
			// promt to change direction
			!!order && (
				<i>
					<b>{translate('tooltips.sortIcon.clickToToggleSort')}</b>
				</i>
			)
		}
		{
			// explanation of multiple sort
			multipleSort && (
				<i>
					<br />
					<br />
					{translate('tooltips.sortIcon.multipleSort')}
				</i>
			)
		}
	</>
);
