import { ActionsDropdown } from 'components/Base';
import Meslis from 'components/Meslis';
import { useAppContext, useUserContext } from 'customHooks';
import { EVENTS_COUNT, LEAFLET } from 'graphql/queries';
import React from 'react';
import { alerts, buildEventsQueryFromFilters, isSelectableAndUnlocked } from 'utils';
import _ from 'lodash';
import { CONFLICT, BASKET, SHELF } from 'config';

const Actions = ({ className, sourceLeaflets = [], events, onCompleted = () => {} }) => {
	const { user } = useUserContext();
	const lockedEvents = events?.filter(event => !isSelectableAndUnlocked(event, user?.username));
	events = events?.filter(event => isSelectableAndUnlocked(event, user?.username));

	const { pushUndoList, removeFailedEventsFromUndo, setHasPendingMoveAction } = useAppContext();

	const shownElements = {
		leaflets: true,
		drop:
			sourceLeaflets.some(leafletId => leafletId > 0) &&
			!sourceLeaflets.every(leafletId => leafletId === CONFLICT),
		discard: true,
		scrap: true,
		cancelSelection: false,
		keepInLeaflet: false,
	};

	const handleCompleted = data => {
		if (data.some(item => !item.success) || data.errors) {
			const failedEventsIds = data.filter(item => !item.success).map(item => item.id);
			alerts.eventsMoveFailed(failedEventsIds);
			removeFailedEventsFromUndo(failedEventsIds);
		}
		onCompleted();
		// in the future we might want to merge setHasPendingMoveAction and setIsLoadingData (AppContext), since the refetch here triggers both and therefore could lead to additional rerenders
		setHasPendingMoveAction(false);
	};

	const moveEvent = (toLeafletById, targetLeafletId) => {
		const ids = events.map(item => ({
			id: item.id,
			version: item.version,
			leafletId: item.customLeaflet ? item.customLeaflet.id : -1,
		}));
		setHasPendingMoveAction(true);
		toLeafletById({
			variables: {
				leafletId: targetLeafletId,
				ids: ids,
			},
			refetchQueries: [targetLeafletId, ...sourceLeaflets]
				.filter(item => [BASKET, SHELF].includes(item))
				.flatMap(item => [
					// Reloading Leaflet Boxes of basket and shelf.
					// Basket and shelf are user specific although having same id for every user
					// therefore not using subscriptions in LeafletBox/index.js
					{
						query: LEAFLET,
						variables: {
							searchQuery: buildEventsQueryFromFilters([], { leafletIds: [item] }),
							max: 5,
						},
					},
					{
						query: EVENTS_COUNT,
						variables: {
							max: 0,
							searchQuery: buildEventsQueryFromFilters([], { leafletIds: [item] }),
						},
					},
				]),
			awaitRefetchQueries: true,
		});
		pushUndoList({
			ids: ids,
			leafletId: targetLeafletId,
		});
	};

	const handleSelected = toLeafletById => action => {
		if (action && action.targetLeafletId) {
			if (!!lockedEvents?.length) {
				alerts
					.lockedSelectionIgnored(lockedEvents.map(ev => ev.id))
					.then(({ dismiss }) => !dismiss && moveEvent(toLeafletById, action.targetLeafletId));
			} else moveEvent(toLeafletById, action.targetLeafletId);
		}
	};

	return (
		<Meslis.ToLeafletById onCompleted={handleCompleted}>
			{(toLeafletById, { loading }) => {
				return (
					<ActionsDropdown
						{...{
							className,
							events,
							shownElements,
							noOptionId: _.get(sourceLeaflets, 'length') === 1 ? sourceLeaflets[0] : undefined,
						}}
						disabled={!events?.length || loading}
						onSelected={handleSelected(toLeafletById)}
					/>
				);
			}}
		</Meslis.ToLeafletById>
	);
};
export default Actions;
