import classNames from 'classnames';
import React from 'react';
import { Popup, Icon } from 'semantic-ui-react';

import './InfoPopup.scss';

const InfoPopup = ({ header = <></>, content, className, type, ...props }) => (
	<span className="InfoPopup">
		{header}
		<Popup
			className={classNames('InfoPopup-Message', className)}
			content={content}
			trigger={
				<Icon
					name="info"
					className={classNames('infoIcon', type === 'selectOrganizer' && className)}
				/>
			}
			wide
			popperModifiers={[{ name: 'preventOverflow', options: { boundry: 'window' } }]}
			closeOnTriggerClick
			on="hover"
			{...props}
		/>
	</span>
);

export default InfoPopup;
