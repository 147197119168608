import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslate } from 'customHooks';
import { AppContext } from 'contexts/AppContext';
import { configEvents } from 'components/Table/Events';
import { locationsListedFields } from 'components/Table/Locations';
import { organizersListedFields } from 'components/Table/Organizers';
import { Container, Header, Icon, Button, Grid } from 'semantic-ui-react';
import classNames from 'classnames';

import './Group.scss';

const Group = ({
	children,
	header,
	isExpandable = false,
	AlwaysShown,
	SettingsInput,
	data,
	defaultData,
	onSubmitData,
}) => {
	const [isExpanded, setExpanded] = useState(false);

	const [localData, setLocalData] = useState(data);
	const [wait, setWait] = useState(null);

	const translate = useTranslate();

	const [eventsDisabled, setEventsDisabled] = useState(true);
	const [locationsDisabled, setLocationsDisabled] = useState(true);
	const [organizersDisabled, setOrganizersDisabled] = useState(true);

	const { getFieldsOrderFor, setFieldsOrderFor } = useContext(AppContext);
	const eventsTableOrder = getFieldsOrderFor('events');
	const locationsTableOrder = getFieldsOrderFor('location-search');
	const organizersTableOrder = getFieldsOrderFor('organizer-search');

	useEffect(() => {
		if (!_.isEqual(eventsTableOrder, configEvents.listedFields)) {
			setEventsDisabled(false);
		}
		if (!_.isEqual(locationsTableOrder, locationsListedFields)) {
			setLocationsDisabled(false);
		}
		if (!_.isEqual(organizersTableOrder, organizersListedFields)) {
			setOrganizersDisabled(false);
		}
		// eslint-disable-next-line
	}, []);

	const isUnsubmitted = !!wait;
	const canSetToDefault = !_.isEqual(defaultData, localData);

	const handleChange = (name, value, isError) => {
		const _changeData = {
			[name]: '' + value,
		};
		setLocalData({
			...localData,
			..._changeData,
		});
		!!wait && clearTimeout(wait);
		!isError &&
			setWait(
				setTimeout(() => {
					onSubmitData(_changeData);
					setWait(null);
				}, 500)
			);
	};

	const handleSetToDefault = () => {
		setLocalData(defaultData);
		onSubmitData(defaultData);
	};

	const SettingsForm = () => (
		<div className="group-content">
			<SettingsInput data={localData} onChangeData={handleChange} />

			{defaultData && (
				<>
					<Button className="default" onClick={handleSetToDefault} disabled={!canSetToDefault}>
						{translate('userSettings.reset')}
					</Button>
					<div className="header-reset-group">
						<h4 className="header-reset-content">{translate('userSettings.headerReset')}</h4>
						<div class="events-reset content">
							<h4> {translate('userSettings.eventsReset')}</h4>
							<Button
								toggle
								disabled={eventsDisabled}
								onClick={() => {
									setEventsDisabled(true);
									setFieldsOrderFor('events', configEvents.listedFields);
								}}>
								{translate('userSettings.reset')}
							</Button>
						</div>
						<div class="locations-reset content">
							<h4>{translate('userSettings.locationsReset')}</h4>
							<Button
								toggle
								disabled={locationsDisabled}
								onClick={() => {
									setLocationsDisabled(true);
									setFieldsOrderFor('location-search', locationsListedFields);
								}}>
								{translate('userSettings.reset')}
							</Button>
						</div>
						<div class="organizers-reset content">
							<h4> {translate('userSettings.organizersReset')}</h4>
							<Button
								toggle
								disabled={organizersDisabled}
								onClick={() => {
									setOrganizersDisabled(true);
									setFieldsOrderFor('organizer-search', organizersListedFields);
								}}>
								{translate('userSettings.reset')}
							</Button>
						</div>
					</div>
				</>
			)}
		</div>
	);

	const Content = (
		<>
			{!!SettingsInput && SettingsForm()}
			{children}
		</>
	);

	return (
		<Container className="group" textAlign="left">
			<Header as="h2">{header}</Header>
			<div className={classNames('group-box', { unsubmitted: isUnsubmitted })}>
				{isExpandable ? (
					<>
						<Grid columns={2}>
							<Grid.Column width={2}>
								<Button className="expander" onClick={() => setExpanded(!isExpanded)} icon>
									<Icon className={classNames('angle', isExpanded ? 'down' : 'right')} />
								</Button>
							</Grid.Column>
							<Grid.Column className="alwaysShown" width={12}>
								{AlwaysShown}
							</Grid.Column>
						</Grid>

						{
							<div
								className={
									isExpanded ? 'expanded' : 'unexpanded'
								} /* style={{ display: !isExpanded ? 'none' : undefined }} */
							>
								{Content}
							</div>
						}
					</>
				) : (
					<>
						{!!AlwaysShown && AlwaysShown}
						{Content}
					</>
				)}
			</div>
		</Container>
	);
};
export default Group;
