import React from 'react';
import { useTranslate } from 'customHooks';
import { useState } from 'react';

import EventData from './EventData';
import LocationData from './LocationData';
import OrganizerData from './OrganizerData';
import { Icon, Tab } from 'semantic-ui-react';
import storage from 'storage';
import InfoBlock from './InfoBlock';
import { ICONS } from 'config';
import './EventDetailContent.scss';
import classNames from 'classnames';

const EventDetailContent = ({
	event: { id, rating, name, ...props },
	location,
	organizers,
	selectOrganizerModal,
	panes,
	placeholdertext,
	tabSubHeader,
	mergedDataProps: {
		adsColor,
		adCount,
		toggleMergedMode,
		isWaitingForUpdate,
		isMergedMode,
		showForwardBackward,
		adIndexLabels,
		adOffset,
		forwardAdInfoBlock,
		backwardAdInfoBlock,
	},
	handleDragEnter,
	isShowInput,
	isRenderField,
}) => {
	const translate = useTranslate();

	const lastPaneDisabled = panes[panes.length - 1]?.disabled ? 'lastPaneDisabled' : '';
	panes = panes.map((pane, index) => ({
		menuItem: pane.menuItem,
		render: () => (
			<Tab.Pane key={index} attached={false}>
				{index !== 2 && tabSubHeader} {/* no ColoredIdSwitcher for setlists */}
				{pane.tag}
			</Tab.Pane>
		),
	}));

	const [_tabIndex, _setTabIndex] = useState(storage.getDetailTab());
	const tabIndex = Math.min(_tabIndex, panes.length - 1); // In edit mode and create mode there are less input fields than in normal mode.

	const handleTabChange = (_, data) => {
		storage.setDetailTab(data.activeIndex);
		_setTabIndex(data.activeIndex);
	};

	/**
	 * Normalizes the property "matchedOrganizerIds" of an organizer object.
	 * Some organizer objects have a property "matchedOrganizerId" instead of "matchedOrganizerIds".
	 * In that case, this function renames the property "matchedOrganizerId" to "matchedOrganizerIds".
	 * @param {*} organizer The organizer object to normalize.
	 * @returns An organizer object with the property "matchedOrganizerIds" and no (more) property "matchedOrganizerIds".
	 */
	const normalizeMatchedOrganizerIds = organizer => {
		let normalizedOrganizer;
		const { matchedOrganizerId, ...rest } = organizer;

		if (matchedOrganizerId) {
			normalizedOrganizer = {
				...rest,
				matchedOrganizerIds: matchedOrganizerId.props.data,
			};
		} else {
			normalizedOrganizer = { ...organizer };
		}

		return normalizedOrganizer;
	};

	return (
		<div
			className={classNames('EventDetail', { isShowInput })}
			data-test="EventDetail"
			data-test-adcount={adCount}
			onDragEnter={handleDragEnter}>
			<div className="wrapper">
				<div className="heading">
					{rating}
					{name}
				</div>
				<div className="divided">
					{placeholdertext || (
						<Tab
							data-test="detailViewTabs"
							className="preview"
							onTabChange={handleTabChange}
							menu={{
								secondary: true,
								pointing: true,
								className: lastPaneDisabled,
							}}
							activeIndex={tabIndex}
							panes={panes}
						/>
					)}
					<div className="scroll_frame">
						<InfoBlock
							id={id}
							adHeader={
								<>
									{translate('detailView.ad')}
									<span
										style={{
											visibility: isMergedMode && adIndexLabels !== null ? 'visible' : 'hidden',
										}}>
										{translate('detailView.adsSuffix')}
									</span>
								</>
							}
							adsColor={adsColor}
							onClickHeader={toggleMergedMode}
							icon={ICONS.EVENT_MUSIC}
							isIconRotating={isWaitingForUpdate}
							name={translate('detailView.event')}
							isHeadingGray={!isMergedMode}
							showForwardBackward={showForwardBackward}
							{...{
								ads: adIndexLabels,
								adOffset,
								forwardAd: forwardAdInfoBlock,
								backwardAd: backwardAdInfoBlock,
								isShowInput,
							}}>
							<EventData {...{ isShowInput, isRenderField, ...props }} />
						</InfoBlock>
						<InfoBlock
							id={location.id}
							icon={ICONS.LOCATION}
							name={location.header || translate('detailView.location')}
							functionButton={location?.functionButton}>
							<LocationData {...{ isRenderField, ...location }} />
						</InfoBlock>
						<InfoBlock
							ids={organizers.map(organizer => (
								<>{organizer.id}</>
							))} // organizer.id can be a clickable html-tag. It may not be converted to string.
							icon={ICONS.ORGANIZER}
							name={
								organizers?.length === 1 && !!organizers[0].header
									? organizers[0].header
									: organizers?.length > 1
									? translate('detailView.organizers')
									: translate('detailView.organizer')
							}
							functionButton={
								<>
									{selectOrganizerModal}
									{organizers?.length === 1 && !!organizers[0].functionButton
										? organizers[0].functionButton
										: null}
								</>
							}>
							{organizers?.length > 1 ? (
								organizers.map((organizer, index) => {
									return (
										organizer.shown && (
											<div key={index}>
												<span className="id-header">
													<Icon name={ICONS.ORGANIZER} />[{organizer.id}]
												</span>
												{organizer.functionButton}
												<OrganizerData
													{...{ isRenderField, ...normalizeMatchedOrganizerIds(organizer) }}
												/>
											</div>
										)
									);
								})
							) : (
								<OrganizerData
									{...{
										isRenderField,
										...(organizers.length
											? { ...normalizeMatchedOrganizerIds(organizers[0]) }
											: {}),
									}}
								/>
							)}
						</InfoBlock>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EventDetailContent;
