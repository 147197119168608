import React from 'react';
import { Button, Icon, Popup } from 'semantic-ui-react';

import { useTranslate } from 'customHooks';

const DragMode = ({ ...props }) => {
	const translate = useTranslate();

	const handleClick = () => {
		const event = new Event('keyup');
		event.key = 'F2';
		document.dispatchEvent(event);
	};
	return (
		<Popup
			trigger={
				<Button icon onClick={handleClick} {...props}>
					<Icon name="images outline" />
				</Button>
			}
			content={
				<>
					<b>{translate('actions.dragMode')}</b>
					<br />
					<i>{translate('tooltips.dragModeButton')}</i>
				</>
			}
			on="hover"
			mouseEnterDelay={500}
			disabled={props.disabled}
			position="bottom right"
			closeOnTriggerClick
		/>
	);
};

export default DragMode;
