import useReactRouter from 'use-react-router';

export const getParamsObject = paramsString => {
	const paramsArray = !!paramsString ? paramsString.slice(1).split('&') : [];

	return paramsArray.reduce((accumulator, item) => {
		const [key, value] = item.split('=');
		accumulator[key] = isNaN(value) ? value : Number(value);
		return accumulator;
	}, {});
};

const getParamsString = paramsObject =>
	Object.keys(paramsObject)
		.filter(key => paramsObject[key] !== undefined)
		.sort()
		.map(key => key + '=' + paramsObject[key])
		.join('&');

const useQueryParams = (defaults = {}) => {
	const { history, location } = useReactRouter();

	const paramsObject = { ...defaults, ...getParamsObject(location.search) };

	const pushURL = params => {
		const { isNewWindow, rerouteTo, isNoOldParams, ...newQueryParams } = params;
		const queryParams = isNoOldParams ? newQueryParams : { ...paramsObject, ...newQueryParams };

		const url = rerouteTo
			? `/${rerouteTo}?${getParamsString(queryParams)}`
			: `${location.pathname}?${getParamsString(queryParams)}`;

		isNewWindow ? window.open(url, '_blank') : history.push(url);
	};

	return { ...paramsObject, location, pushURL };
};

export default useQueryParams;
