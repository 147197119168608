import React, { useEffect } from 'react';

import { InfoPopup, InfoUser } from 'components/Base';
import Generic, { configField } from 'components/Table/Generic';
import { useTranslateContext, useItemArray } from 'customHooks';
import {
	formatGenericStatisticPerYear,
	renderIdAndOldIds,
	buildStreetHouseNumberFromAddress,
} from 'utils';

const defaultHiddenFields = [
	'locationStatus.name',
	'locationStatus.user.username',
	'locationStatus.lastChangedAt',
	'id',
	'matchedLocationIds',
	'commercialId',
	// 'name',
	// 'address.streetHouseNumber',
	// 'address.postalCode',
	// 'address.city',
	'address.region',
	'address.country',
	// 'category',
	'emailAddresses',
	'phoneNumbers',
	'urls',
	'timestamp',
	'customerStatus',
	// 'customerLocations'
	// 'statistic.eventCountPerYear'
	// 'statistic.eventCountTotal',
	// 'statistic.eventCountRelevant',
	'statistic.eventCountIrrelevant',
];

const locationsListedFields = [
	'locationStatus.name',
	'locationStatus.user.username',
	'locationStatus.lastChangedAt',
	'id',
	'matchedLocationIds',
	'commercialId',
	'name',
	'address.streetHouseNumber',
	'address.postalCode',
	'address.city',
	'address.region',
	'address.country',
	'category',
	'emailAdresses',
	'phoneNumbers',
	'urls',
	'timestamp',
	'customerStatus',
	'customerLocations',
	'statistic.eventCountPerYear',
	'statistic.eventCountTotal',
	'statistic.eventCountRelevant',
	'statistic.eventCountIrrelevant',
];

const Locations = ({
	data,
	name,
	moreDataAvailable,
	onSort,
	onChangeToDetailView,
	onReachBottom,
	storageMemoKey,
	sorting,
	onCtrlUp,
	filters,
}) => {
	const { translate, formatTimeStamp } = useTranslateContext();
	const { renderItemArray, extended, toggleExpandAll, setExtended, closeAll } = useItemArray(data);

	// close all extendable events on rerender with new data to avoid overlapping rows
	useEffect(() => {
		if (extended.length !== 0 /* avoid unnecessary rerender */) closeAll();
		// eslint-disable-next-line
	}, [data]); // no closeAll in dependency array needed

	// slightly hacky way to check if values in object / array have actually changed without introducing new states
	const joinedFilters = JSON.stringify(filters);
	const stringifiedSorting = JSON.stringify(sorting);
	useEffect(() => {
		setExtended([]);
	}, [setExtended, joinedFilters, stringifiedSorting]);

	const fields = [
		configField({
			name: 'id',
			label: (
				<InfoPopup
					header={translate('genTableView.id')}
					content={translate('tableView.locIdTip')}
				/>
			),
			sortable: true,
			render: data =>
				renderIdAndOldIds({
					data,
					idField: 'id',
					filterField: 'id',
					oldIdsField: 'oldIds',
					message: translate('tableView.locOldIds'),
				}),
		}),
		configField({
			name: 'matchedLocationIds',
			label: translate('genTableView.customerId'),
			sortable: false,
		}),
		configField({
			name: 'commercialId',
			label: translate('genTableView.commercialId'),
			sortable: true,
		}),
		configField({ name: 'name', label: 'Name', sortable: true }),
		configField({
			name: 'address.streetHouseNumber',
			render: location => buildStreetHouseNumberFromAddress(location?.address),
			label: translate('genTableView.address.street'),
			sortField: 'address.street', // cannot sort both
			sortable: true,
		}),
		configField({
			name: 'address.postalCode',
			label: translate('genTableView.address.postalCode'),
			sortable: true,
		}),
		configField({
			name: 'address.city',
			label: translate('genTableView.address.city'),
			sortable: true,
		}),
		configField({
			name: 'address.region',
			label: translate('genTableView.region'),
			sortable: true,
		}),
		configField({
			name: 'address.country',
			label: translate('genTableView.address.country'),
			sortable: true,
		}),
		configField({
			name: 'category',
			label: translate('genTableView.category'),
			sortable: true,
		}),
		configField({
			name: 'emailAddresses',
			label: translate('genTableView.emailAddresses'),
			sortable: true,
		}),
		configField({
			name: 'phoneNumbers',
			label: translate('genTableView.phoneNumbers'),
			sortable: true,
		}),
		configField({
			name: 'urls',
			label: translate('genTableView.url'),
			sortable: true,
		}),
		configField({
			name: 'timestamp',
			label: translate('genTableView.timestamp'),
			sortable: true,
			render: ({ timestamp }) => formatTimeStamp(timestamp),
		}),
		configField({
			name: 'customerStatus',
			label: translate('genTableView.customerStatus'),
			sortable: true,
		}),
		configField({
			name: 'customerLocations',
			label: (
				<InfoPopup
					header={translate('genTableView.cusEvPerYear')}
					content={
						<>
							<i>{translate('genTableView.cusEvPerYearTip1')}</i>
							<br />
							<br />
							{translate('genTableView.cusEvPerYearTip2')}
						</>
					}
				/>
			),
			sortable: true,
			sortField: 'customerLocations.eventCountPerYear.differenceMeslisToCustomer',
			render: renderItemArray('customerLocations[0].statisticPerYear', '.', {
				render: formatGenericStatisticPerYear,
			}),
			arrayPath: 'customerLocations[0].statisticPerYear',
		}),
		configField({
			name: 'statistic.eventCountPerYear',
			label: (
				<InfoPopup
					header={translate('genTableView.evPerYear')}
					content={
						<>
							<i>{translate('genTableView.evPerYearTip1')}</i>
							<br />
							<br />
							{translate('genTableView.evPerYearTip2')}
						</>
					}
				/>
			),
			sortable: true,
			sortField: 'statistic.eventCountPerYear.count',
			render: renderItemArray('statistic.eventCountPerYear', '.', {
				render: val => `${val.year}: ${val.count}`,
			}),
			arrayPath: 'statistic.eventCountPerYear',
		}),
		configField({
			name: 'statistic.eventCountTotal',
			label: (
				<InfoPopup
					header={translate('genTableView.statistic.eventCountTotal')}
					content={
						<>
							<i>{translate('genTableView.statistic.evCountTotalTip')}</i>
						</>
					}
				/>
			),
			sortable: true,
		}),
		configField({
			name: 'statistic.eventCountRelevant',
			label: translate('genTableView.statistic.eventCountRelevant'),
			sortable: true,
		}),
		configField({
			name: 'statistic.eventCountIrrelevant',
			label: translate('genTableView.statistic.eventCountIrrelevant'),
			sortable: true,
		}),
		configField({
			name: 'locationStatus.name',
			sortField: 'status.name',
			label: translate('genTableView.statusName'),
			sortable: true,
		}),
		configField({
			name: 'locationStatus.user.username',
			sortField: 'status.username',
			label: translate('genTableView.statusUsername'),
			sortable: true,
			render: ({ locationStatus }) => <InfoUser username={locationStatus?.user?.username} />,
		}),
		configField({
			name: 'locationStatus.lastChangedAt',
			sortField: 'status.lastChangedAt',
			label: translate('genTableView.statusLastChangedAt'),
			sortable: true,
			render: ({ locationStatus }) => formatTimeStamp(locationStatus?.lastChangedAt, true),
		}),
	];

	return (
		<Generic
			{...{
				data,
				fields,
				sorting,
				onCtrlUp,
				name,
				moreDataAvailable,
				defaultHiddenFields,
				onSort,
				onChangeToDetailView,
				onReachBottom,
				storageMemoKey,
				extended,
				toggleExpandAll,
				buttons: {
					createUpdateGeneric: {
						type: 'location',
						allowRefetch: true,
					},
				},
			}}
			tableKey="location-search"
		/>
	);
};

export default Locations;

export { defaultHiddenFields, locationsListedFields };
