import React, { useEffect, useState } from 'react';
import { Button, Icon, Modal, Popup } from 'semantic-ui-react';
import { GrowingInput, ItemArrayList } from 'components/Base';
import { useTranslateContext, useDelay, useOrganizerQueryById, useQueryParams } from 'customHooks';
import { useMutation } from '@apollo/client';
import { ASSIGN_ORGANIZER_FOR_EVENTS } from 'graphql/mutations';

import OrganizerData from './OrganizerData';
import classNames from 'classnames';
import { alerts } from 'utils';

import './SelectOrganizerModal.scss';

const SelectOrganizerModal = ({ selectedOrganizerID, organizers, eventId, version, onRefresh }) => {
	const { translate, formatTimeStamp } = useTranslateContext();
	const { pushURL } = useQueryParams();

	const [assignOrganizer] = useMutation(ASSIGN_ORGANIZER_FOR_EVENTS, {
		onError: () => alerts.errorAssignOrganizer(),
	});

	const [open, setOpen] = useState(false);

	const [currentID, setCurrentID] = useState(selectedOrganizerID);
	const [hoveredID, setHoveredID] = useState(organizers.length && organizers[0].id);
	const [isWaiting, setIsWaiting] = useState(false);

	useEffect(() => setCurrentID(selectedOrganizerID), [selectedOrganizerID]);

	const [customID, setCustomID] = useState('');

	const [
		{ organizer: customOrganizer, invalid, loading: loadingSearchOrganizer, empty },
		requestOrganizer,
	] = useOrganizerQueryById({
		onResponse: (org, valid) => {
			if (valid) {
				setCurrentID(org.id);
				setCustomID(org.id);
				setHoveredID(org.id);
			}
		},
		skip: !open,
	});
	const allOrganizers = [...organizers, ...(!!customOrganizer ? [customOrganizer] : [])];
	const shownID =
		hoveredID || customID || currentID || (!!allOrganizers.length ? allOrganizers[0].id : null);
	const [startAction, stopAction] = useDelay(800);

	const handleChangeCustomId = id => /^\d*$/.test(id) && setCustomID(id);

	const handleCustomIdChanged = value => {
		stopAction();
		startAction(() => {
			if (!!value && customID !== customOrganizer?.id) {
				requestOrganizer(value);
			}
			setCurrentID(null);
			setHoveredID(null);
		});
	};

	const handleAssignOrganizer = async () => {
		if (currentID !== selectedOrganizerID) {
			setIsWaiting(true);
			const response = await assignOrganizer({
				variables: {
					organizerWithEventIds: {
						organizerId: { id: currentID, version },
						eventIds: [{ id: eventId, version }],
					},
				},
			});
			const newEventId = response?.data?.assignOrganizerForEvents[0];

			if (newEventId !== eventId) {
				pushURL({
					view: 'detail',
					currentId: newEventId,
				});
			}

			await onRefresh();
			setIsWaiting(false);
			handleClose();
		}
	};

	const getName = id => {
		const organizer = allOrganizers.find(organizer => organizer.id === id);
		return !!organizer ? organizer.name : '';
	};
	const getLabel = id => {
		const organizer = allOrganizers.find(organizer => organizer.id === id);
		return !!organizer ? `[ID: ${organizer.id}] ${organizer.name}` : '';
	};

	const handleClose = () => {
		setOpen(false);
		setHoveredID(null);
		setCustomID('');
	};

	const handleMouseEnter = organizer => setHoveredID(organizer && organizer.id);
	const handleMouseLeave = () => !!currentID && setHoveredID(null);

	useEffect(() => {
		handleCustomIdChanged(customID);
		// eslint-disable-next-line
	}, [customID]); // only customID as dependency

	let shownOrganizer = allOrganizers.find(organizer => organizer.id === shownID);
	if (!!shownOrganizer) {
		shownOrganizer = {
			...shownOrganizer,
			timestamp: formatTimeStamp(shownOrganizer?.timestamp, true),
			urls: (
				<ItemArrayList
					data={shownOrganizer?.urls?.map((url, idx) => (
						<a key={idx} href={url} target="_blank" rel="noopener noreferrer">
							{url}
						</a>
					))}
				/>
			),
		};
	}

	if (organizers.length < 2) return <></>;

	const Trigger = () => (
		<Popup
			trigger={<Icon className={'SelectOrganizerIcon'} name="pin" onClick={() => setOpen(true)} />}
			mouseEnterDelay={200}>
			<Popup.Header>{translate('messages.selectOrganizer.headerUnselected')}</Popup.Header>
			<Popup.Content>{translate('messages.selectOrganizer.description')}</Popup.Content>
		</Popup>
	);

	return (
		<Modal
			onClose={() => setOpen(false)}
			trigger={<Trigger />}
			{...{ open }}
			className="SelectOrganizerModal">
			<Modal.Header>
				{!currentID
					? translate('messages.selectOrganizer.headerUnselected')
					: translate('messages.selectOrganizer.headerSelected', [getName(currentID)])}
			</Modal.Header>
			<Modal.Content>
				<Modal.Description>{translate('messages.selectOrganizer.description')}</Modal.Description>
				<br />

				<div className="checkboxContainer">
					{organizers.map(organizer => (
						<Button
							key={organizer.id}
							className={classNames({
								shown: organizer.id === shownID,
								checked: organizer.id === currentID,
							})}
							onClick={() => setCurrentID(organizer.id !== currentID ? organizer.id : null)}
							onMouseEnter={() => handleMouseEnter(organizer)}
							onMouseLeave={handleMouseLeave}
							disabled={isWaiting}>
							{getLabel(organizer.id)}
						</Button>
					))}
					<span
						className={classNames('customOrganizer', {
							shown: customOrganizer && customOrganizer.id === shownID,
							checked: customOrganizer && customOrganizer.id === currentID,
							empty,
							invalid,
						})}
						onClick={() =>
							setCurrentID(
								customOrganizer && customOrganizer.id !== currentID ? customOrganizer.id : null
							)
						}
						onMouseEnter={() => handleMouseEnter(customOrganizer)}
						onMouseLeave={handleMouseLeave}
						disabled={isWaiting}>
						{'[ID:'}
						<Popup
							trigger={
								<GrowingInput
									value={customID || ''}
									widthOffset={25}
									isWidthEmpty
									onChange={(_, { value }) => handleChangeCustomId(value)}
									error={invalid}
									loading={loadingSearchOrganizer}
									disabled={isWaiting}
									infoPopup={{
										showInfo: !!customOrganizer?.oldIds?.length,
										content: translate('messages.selectOrganizer.oldIdReplacedByNewId', [
											customOrganizer?.oldIds.join(','),
											customOrganizer?.id,
										]),
										className: 'select-organizer',
										type: 'selectOrganizer',
									}}
								/>
							}
							on={['hover', 'click']}
							content={translate('messages.selectOrganizer.customID')}
						/>
						{'] '}
						<span className="customName">{getName(customOrganizer && customOrganizer.id)}</span>
					</span>
				</div>

				<div
					className={classNames('organizerContainer', {
						opaque: !!currentID && shownID !== currentID,
					})}>
					<OrganizerData {...shownOrganizer} />
				</div>
			</Modal.Content>
			<Modal.Actions>
				<Button onClick={() => handleClose()} disabled={isWaiting}>
					{translate('actions.cancel')}
				</Button>
				<Button
					className="ok"
					disabled={!currentID}
					loading={isWaiting}
					onClick={() => {
						handleAssignOrganizer();
					}}>
					{translate('actions.ok')}
				</Button>
			</Modal.Actions>
		</Modal>
	);
};

export default SelectOrganizerModal;
