import { EVENTS } from 'graphql/queries';
import _ from 'lodash';
import React from 'react';
import { Icon, Table } from 'semantic-ui-react';

import Meslis from 'components/Meslis';
import { useAppContext, useQueryParams, useTranslate, useUserContext } from 'customHooks';

import { buildEventsQueryFromFilters, isSelectableAndUnlocked } from 'utils';

const Row = ({ data, isDelete, onDelete, leafletData, filters, sorting: sortings }) => {
	const { offset } = useQueryParams();
	const { pushUndoList, tableMemo } = useAppContext();
	const { user } = useUserContext();
	const translate = useTranslate();

	return (
		<Table.Row>
			<Table.Cell>{_.truncate(data.name, { length: 30 })}</Table.Cell>
			<Table.Cell textAlign="right">
				<Meslis.ToLeafletById>
					{(toLeaflet, { loading, data: dataMutation }) =>
						!isSelectableAndUnlocked(data, user?.username) ? (
							<></>
						) : loading ? (
							<Icon loading name="spinner" />
						) : dataMutation ? (
							<div>{translate('leaflets.removed')}</div>
						) : (
							isDelete && (
								<Icon
									name="close"
									color="red"
									onClick={() => {
										const ids = [
											{
												id: data.id,
												version: data.version,
												leafletId: !!leafletData && leafletData.id,
											},
										];
										toLeaflet({
											variables: {
												leafletId: -1,
												ids,
											},
											refetchQueries: [
												{
													query: EVENTS,
													variables: {
														searchQuery: buildEventsQueryFromFilters(filters),
														max: tableMemo.getFetchSize('events') || 100,
														...{ sortings, offset },
													},
												},
											],
										}).then(onDelete);
										pushUndoList({
											ids,
											leafletId: -1,
										});
									}}
								/>
							)
						)
					}
				</Meslis.ToLeafletById>
			</Table.Cell>
		</Table.Row>
	);
};

export default Row;
