import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { AppContext } from 'contexts/AppContext';

import { LabeledIcon } from 'components/Base';
import { Popup } from 'semantic-ui-react';

const NavigationButton = ({
	url,
	icon,
	text,
	isActive,
	onClick,
	number = 0,
	tooltip,
	...props
}) => {
	const { tableMemo } = useContext(AppContext);

	const navigationButton = (
		<NavLink
			className="NavigationButton"
			to={url}
			onClick={() => {
				tableMemo.resetAll(); /* resets all scroll positions and fetch sizes */
				onClick();
			}}
			{...{ isActive, ...props }}>
			<LabeledIcon name={icon} label={number} {...{ tooltip }} />
			<span className="navigation-label">{text}</span>
		</NavLink>
	);

	return !!tooltip ? (
		<Popup
			trigger={navigationButton}
			content={tooltip}
			mouseEnterDelay={500}
			position="right center"
		/>
	) : (
		navigationButton
	);
};
export default NavigationButton;
