import React from 'react';
import { get } from 'lodash';
import { useQuery } from '@apollo/client';
import { USERS_OF_COMPANY } from 'graphql/queries';

export default ({ username }) => {
	const allUsers = get(
		useQuery(USERS_OF_COMPANY, { skip: !username, fetchPolicy: 'cache-first' }),
		'data.getAllUsersForCompany'
	);

	const user = allUsers?.find(user => user.username === username);

	if (!user) {
		return <>{username}</>;
	}

	return <>{`${user.firstName} ${user.lastName}`}</>;
};
