const correctURL = url => {
	// Added this try and catch statement in order to prevent crashes on the application whenever an invalid url is being processed. In this case it will return null and the url will not be displayed on the app.
	if (url == null) return null;
	try {
		return new URL(url.startsWith('http://') || url.startsWith('https://') ? url : 'http://' + url)
			.href;
	} catch (e) {
		console.error(`There was problem with given url ${e}`);
		return null;
	}
};

export default correctURL;
