import { Query } from '@apollo/client/react/components';
import { LEAFLET } from 'graphql/queries';
import React from 'react';
import { buildEventsQueryFromFilters } from 'utils';
import { useUpdateEventsAfterLoading } from 'customHooks';

export default ({ children, leafletId, max, ...props }) => {
	const updatingChildren = useUpdateEventsAfterLoading(children);
	return (
		<Query
			query={LEAFLET}
			variables={{
				searchQuery: buildEventsQueryFromFilters([], {
					leafletIds: !!leafletId ? [leafletId] : [],
				}),
				max: max,
			}}
			fetchPolicy="network-only"
			{...props}>
			{updatingChildren}
		</Query>
	);
};
