import React from 'react';
import { Checkbox, Form } from 'semantic-ui-react';
import { useTranslate } from 'customHooks';
import { noop } from 'utils';

const ShareLeafletFormPart = ({
	editors = [],
	users = [],
	shared = false,
	onSharing = noop,
	onChangeEditors = noop,
}) => {
	const translate = useTranslate();

	return (
		<>
			<Form.Field>
				<Checkbox
					data-test="editLeafletShareToggle"
					toggle
					disabled={editors.length > 0}
					checked={shared}
					label={translate('leaflets.shared')}
					onChange={onSharing}
				/>
			</Form.Field>
			<Form.Select
				data-test="editLeafletShareSelect"
				options={users.map(user => ({
					value: user.id,
					text: `${user.firstName} ${user.lastName}`,
					key: user.id,
				}))}
				onChange={onChangeEditors}
				multiple
				clearable
				deburr
				search
				placeholder={translate('leaflets.formEditor')}
				value={editors}
				scrolling
				className={!shared ? 'hidden' : null}
			/>
		</>
	);
};

export default ShareLeafletFormPart;
