import React, { useEffect, useRef } from 'react';
import { Dropdown } from 'semantic-ui-react';
import { getRegionsOfCountry } from 'countriesConfig';
import { EMPTY_REGION, NO_REGIONS } from 'config';

const RegionInput = ({ value, onChange, countryValue, placeholder, ...props }) => {
	const initialCountryValueRef = useRef(countryValue);
	const regions = !!countryValue ? getRegionsOfCountry(countryValue) : [];
	const emptyRegion = regions[0] === EMPTY_REGION;

	useEffect(() => {
		// Whenever the country changes, reset the region to undefined
		if (initialCountryValueRef.current !== countryValue) onChange(undefined);
		// eslint-disable-next-line
	}, [countryValue]);

	if (!!value && !regions.includes(value)) {
		// fall back for updating if spelling in data base differs from spelling in predefined list
		placeholder = value;
	}

	if (NO_REGIONS.includes(countryValue)) {
		placeholder = EMPTY_REGION;
	}

	return (
		<Dropdown
			data-test="RegionInput"
			disabled={!regions.length || emptyRegion}
			options={regions.map(region => ({ text: region, value: region }))}
			selection
			clearable
			search
			onChange={(_, { value }) => onChange(value)}
			{...{ value, placeholder, ...props }}
		/>
	);
};

export default RegionInput;
