import React from 'react';
import storage from 'storage';
import { get, has } from 'lodash';
import { InfoPopup, ItemArrayList } from 'components/Base';

export default ({ data, idField, filterField, oldIdsField, message }) => {
	if (has(data, oldIdsField) && get(data, oldIdsField + '.length') > 0) {
		// show oldIds which are obsolet after merging
		// show them in popup
		// show old ids filtered by user in gray behind
		const idsInFilters = storage.getIdsInFilters(filterField) || [];
		const matchedOldIds = get(data, oldIdsField).filter(oldId =>
			idsInFilters.some(filteredId => filteredId === oldId)
		);
		return (
			<InfoPopup
				header={
					<>
						{get(data, idField)}
						{matchedOldIds.length > 0 && (
							<span style={{ color: 'gray' }}>, {matchedOldIds.join(', ')}</span>
						)}
					</>
				}
				content={
					<>
						{message + '\n'}
						<ItemArrayList data={get(data, oldIdsField)} itemLimit={10} />
					</>
				}
				hoverable
				position="bottom center"
			/>
		);
	} else return get(data, idField);
};
