import React, { useState, useEffect, useContext } from 'react';
import { getChangedDataDiff, isLockedByOtherUser, alerts, isLockedByMe } from 'utils';
import { Button as UIButton, Icon, Modal, Popup } from 'semantic-ui-react';
import { getLocationInputs, getOrganizerInputs } from 'components/EventDetail';
import LocationData from 'components/EventDetail/LocationData';
import OrganizerData from 'components/EventDetail/OrganizerData';
import Button from '.';
import {
	UPDATE_LOCATION,
	UPDATE_ORGANIZER,
	CREATE_LOCATION,
	CREATE_ORGANIZER,
} from 'graphql/mutations';
import { useTranslate, useUserContext } from 'customHooks';
import { RedirectToStepSelect } from 'components/Base';
import { useMutation } from '@apollo/client';
import { get, set, cloneDeep } from 'lodash';

import {
	CREATE_UPDATE_LOCATION_MANDATORY_FIELDS as LOCATION_MANDATORY_FIELDS,
	CREATE_UPDATE_ORGANIZER_MANDATORY_FIELDS as ORGANIZER_MANDATORY_FIELDS,
	ADDRESS_MANDATORY_FIELDS_CREATE_ONLY as CREATE_ONLY_MANDATORY_FIELDS,
	NO_DIFFERENCE,
	EMPTY_REGION,
} from 'config';

import './CreateUpdateGeneric.scss';
import { AppContext } from 'contexts';

const CreateUpdateGeneric = ({
	type,
	isUpdateMode,
	allowRefetch = false,
	initialData = {},
	triggerType = 'button',
	popupPosition = 'bottom right',
	refetchMeslisGeneric,
}) => {
	const translate = useTranslate();
	const { user } = useUserContext();
	const [createdID, setCreatedID] = useState();
	const [updatedID, setUpdatedID] = useState();
	const [open, setOpen] = useState(false);

	const { genericContext } = useContext(AppContext);

	const { CREATE_ENTITY, UPDATE_ENTITY } = {
		location: {
			CREATE_ENTITY: CREATE_LOCATION,
			UPDATE_ENTITY: UPDATE_LOCATION,
		},
		organizer: {
			CREATE_ENTITY: CREATE_ORGANIZER,
			UPDATE_ENTITY: UPDATE_ORGANIZER,
		},
	}[type];

	const [create, { loading: loadingCreate }] = useMutation(CREATE_ENTITY, {
		onError: () => alerts.failMessageGenerator(type, 'created'),
	});
	const [update, { data, loading: loadingUpdate }] = useMutation(UPDATE_ENTITY, {
		onError: () => alerts.failMessageGenerator(type, 'updated'),
	});

	const collectUpdateGenericIds = (data = {}, type) => {
		let eventUpdateResponses;
		if (type === 'organizer') {
			var { updateOrganizerWithEventUpdateResponse } = data || {};
			eventUpdateResponses = updateOrganizerWithEventUpdateResponse?.eventUpdateResponses || [];
		}
		if (type === 'location') {
			var { updateLocationWithEventUpdateResponse } = data || {};
			eventUpdateResponses = updateLocationWithEventUpdateResponse?.eventUpdateResponses || [];
		}

		let successfulIds = [];
		let duplicationIds = [];
		let failedUpdateIds = [];
		let matchingIds = [];

		if (eventUpdateResponses.length > 0) {
			eventUpdateResponses.forEach(eventUpdateResponse => {
				if (eventUpdateResponse.updateStatus === 'SUCCESSFUL') {
					successfulIds.push(eventUpdateResponse.id);
				} else if (eventUpdateResponse.updateStatus === 'FAILED') {
					switch (eventUpdateResponse.failureType) {
						case 'DUPLICATION':
							duplicationIds.push(eventUpdateResponse.id);
							break;
						case 'UPDATE':
							failedUpdateIds.push(eventUpdateResponse.id);
							break;
						case 'MATCHING':
							matchingIds.push(eventUpdateResponse.id);
							break;
						default:
							break;
					}
				}
			});
		}

		return { successfulIds, duplicationIds, failedUpdateIds, matchingIds };
	};

	const generateUpdateMessage = data => {
		const { successfulIds, duplicationIds, failedUpdateIds, matchingIds } = collectUpdateGenericIds(
			data,
			type
		);

		const successMessage =
			successfulIds.length > 0
				? translate('tooltips.mergedEventsUpdateSuccess', [successfulIds.join(', ')])
				: '';
		const duplicationMessage =
			duplicationIds.length > 0
				? translate('tooltips.mergedEventsUpdateDuplication', [duplicationIds.join(', ')])
				: '';
		const failedUpdateMessage =
			failedUpdateIds.length > 0
				? translate('tooltips.mergedEventsUpdateFailure', [failedUpdateIds.join(', ')])
				: '';
		const matchingMessage =
			matchingIds.length > 0
				? translate('tooltips.mergedEventsUpdateMatching', [matchingIds.join(', ')])
				: '';

		return `${successMessage} ${duplicationMessage} ${failedUpdateMessage} ${matchingMessage}`;
	};

	const createdIDLink = createdID && (
		<>
			{createdID}{' '}
			<RedirectToStepSelect id={createdID} {...{ type }}>
				{createdID}
			</RedirectToStepSelect>
		</>
	);

	const updatedIDs = updatedID && <>{generateUpdateMessage(data)}</>;

	const {
		heading,
		hasBeenCreatedUpdatedMsg,
		GenericData,
		getInputs,
		createEntity,
		updateEntity,
		MANDATORY_FIELDS,
		editedType,
	} = {
		location: {
			heading: isUpdateMode
				? translate('actions.updateLocation')
				: translate('actions.createLocation'),
			hasBeenCreatedUpdatedMsg: translate(
				createdID ? 'tooltips.locationCreated' : 'tooltips.locationUpdated',
				[createdID ? createdIDLink : updatedIDs]
			),
			GenericData: LocationData,
			getInputs: getLocationInputs,
			createEntity: 'createLocation',
			updateEntity: 'updateLocation',
			MANDATORY_FIELDS: LOCATION_MANDATORY_FIELDS,
			editedType: 'editedLocation',
		},
		organizer: {
			heading: isUpdateMode
				? translate('actions.updateOrganizer')
				: translate('actions.createOrganizer'),
			hasBeenCreatedUpdatedMsg: translate(
				createdID ? 'tooltips.organizerCreated' : 'tooltips.organizerUpdated',
				[createdID ? createdIDLink : updatedIDs]
			),
			GenericData: OrganizerData,
			getInputs: getOrganizerInputs,
			createEntity: 'createOrganizer',
			updateEntity: 'updateOrganizer',
			MANDATORY_FIELDS: ORGANIZER_MANDATORY_FIELDS,
			editedType: 'editedOrganizer',
		},
	}[type];

	const transformInitialData = (initialData = {}) => ({
		[editedType]: cloneDeep(initialData),
	});

	const [
		{ changedValueDiff, changedValue, invalidFields },
		setChangedValueInvalidFields,
	] = useState({
		changedValue: transformInitialData(initialData),
		changedValueDiff: NO_DIFFERENCE,
		invalidFields: [],
	});

	useEffect(() => {
		genericContext &&
			setChangedValueInvalidFields({
				changedValue: isUpdateMode ? transformInitialData(genericContext) : {},
				changedValueDiff: NO_DIFFERENCE,
				invalidFields: [],
			});
		// eslint-disable-next-line
	}, [genericContext]);

	// Currently locking is only implemented for organizer (not for location).
	const isCheckLocking = isUpdateMode && type === 'organizer';
	const actionDisabled =
		isCheckLocking &&
		(isLockedByOtherUser(initialData, user?.username) ||
			!isLockedByMe(initialData, user?.username));

	const loading = loadingCreate || loadingUpdate;

	const createdUpdatedID = createdID || updatedID;

	const handleChangeValue = (path, value, valid = true) => {
		const _changedValue = { ...changedValue };

		set(_changedValue, path, value);

		const _invalidFields =
			valid && invalidFields.includes(path)
				? invalidFields.filter(field => field !== path)
				: !valid && !invalidFields.includes(path)
				? [...invalidFields, path]
				: invalidFields;

		const changedValueDiff = getChangedDataDiff(transformInitialData(initialData), _changedValue);

		setChangedValueInvalidFields({
			changedValueDiff,
			changedValue: _changedValue,
			invalidFields: _invalidFields,
		});
	};

	const handleSubmit = async () => {
		let id;
		if (isUpdateMode) {
			const response = await update({
				variables: {
					[updateEntity]: removedUnusedSubmitData(changedValueDiff[editedType]),
				},
			});

			switch (type) {
				case 'location':
					id = response?.data?.updateLocationWithEventUpdateResponse?.location?.id;
					break;
				case 'organizer':
					id = response?.data?.updateOrganizerWithEventUpdateResponse?.organizer?.id;
					break;
				default:
					break;
			}

			!!id && setUpdatedID(id);
			allowRefetch && refetchMeslisGeneric(type);
		} else {
			const response = await create({
				variables: {
					[createEntity]: cleanBeforeSubmit(changedValue[editedType]),
				},
			});
			id = !!response?.data && response?.data[createEntity]?.id;
			!!id && setCreatedID(id);
		}

		setChangedValueInvalidFields({
			changedValue: transformInitialData(initialData),
			changedValueDiff: NO_DIFFERENCE,
			invalidFields: [],
		});
	};

	const handleClose = () => {
		setOpen(false);
		setUpdatedID(null);
		setCreatedID(null);
	};

	const handleReset = () => {
		setChangedValueInvalidFields({
			// The difference between the initial data and the changed value
			changedValueDiff: NO_DIFFERENCE,
			// The changed value
			changedValue: transformInitialData(initialData),
			// The fields that are invalid
			invalidFields: [],
		});
	};

	const isUpdatedField = path => get(changedValueDiff, path) !== undefined;

	const submitIssues = getSubmitIssues({
		changedValueDiff,
		changedValue,
		invalidFields,
		mandatoryFields: isUpdateMode
			? MANDATORY_FIELDS
			: // create mode
			  [...MANDATORY_FIELDS, ...CREATE_ONLY_MANDATORY_FIELDS],
		editedType,
	});

	const inputs = getInputs({
		isCreateNewLocation: true,
		isCreateNewOrganizer: true,
		value: changedValue,
		onChangeValue: handleChangeValue,
		isUpdatedField,
		translate,
		isUpdateMode,
	});

	const [TriggerType, iconAttr] = { icon: [Icon, 'name'], button: [UIButton, 'icon'] }[triggerType];

	const Trigger = (
		<Popup
			trigger={
				<TriggerType
					className="CreateUpdateGenericTrigger"
					data-test="create-Update-Generic-Trigger"
					{...{ [iconAttr]: isUpdateMode ? 'edit' : 'tag' }}
					onClick={() => setOpen(true)}
					disabled={actionDisabled}
				/>
			}
			on="hover"
			content={<b>{heading}</b>}
			position={popupPosition}
			closeOnTriggerClick
		/>
	);

	return (
		<Modal
			className="CreateUpdateGeneric"
			trigger={Trigger}
			{...{ open }}
			onClose={() => setOpen(false)}>
			<Modal.Header>
				{heading}
				<div className={'sub-heading'}>{translate('actions.requiredFields')}</div>
			</Modal.Header>
			<Modal.Content>
				{!createdUpdatedID ? (
					<GenericData {...{ isUpdateMode, ...inputs }} />
				) : (
					hasBeenCreatedUpdatedMsg
				)}
			</Modal.Content>
			<Modal.Actions>
				{!createdUpdatedID ? (
					<>
						<UIButton
							data-test="create-Update-Generic-Cancel"
							onClick={handleClose}
							disabled={loading}
							loading={loading}>
							{translate('actions.cancel')}
						</UIButton>
						<UIButton
							data-test="create-Update-Generic-Reset"
							onClick={handleReset}
							disabled={loading || submitIssues?.isUnchanged}
							loading={loading}>
							{translate('actions.reset')}
						</UIButton>
						<Button.Submit
							data-test="create-Update-Generic-Submit"
							onClick={handleSubmit}
							submitIssues={submitIssues}
							disabled={actionDisabled || loading}
							loading={loading}
						/>
					</>
				) : (
					<UIButton onClick={handleClose} disabled={loading} loading={loading}>
						{translate('actions.ok')}
					</UIButton>
				)}
			</Modal.Actions>
		</Modal>
	);
};

const getSubmitIssues = ({
	changedValueDiff,
	changedValue,
	invalidFields,
	mandatoryFields,
	editedType,
}) => {
	const isMissingField = (parent, field) => {
		const value = get(parent, field);

		// If the value is an array, check that all items are empty.
		if (Array.isArray(value)) {
			return value.every(_value => !_value);
		}

		// If the value is not an array, check that it is empty.
		return !value;
	};

	const missingFields = mandatoryFields
		.filter(field => isMissingField(changedValue[editedType], field))
		.map(field => `${editedType}.${field}`);

	// If any fields are invalid, or if any mandatory fields are missing,
	// or if the value is unchanged, return an object with the relevant details.
	if (invalidFields.length || missingFields.length || changedValueDiff === NO_DIFFERENCE) {
		return {
			invalidFields,
			missingFields,
			isUnchanged: changedValueDiff === NO_DIFFERENCE,
		};
	}

	// Otherwise, return null.
	return null;
};

const removedUnusedSubmitData = ({ timestamp, userLock, version, ...submitData } = {}) => {
	const cleanedData = cleanBeforeSubmit(submitData);
	return mapEntityStatusToCorrectField(cleanedData);
};

const mapEntityStatusToCorrectField = ({
	organizerStatus,
	eventStatus,
	locationStatus,
	...data
}) => {
	if (organizerStatus) {
		data.organizerStatusId = organizerStatus.name;
	}

	if (locationStatus) {
		data.locationStatusId = locationStatus.name;
	}

	if (eventStatus) {
		data.eventStatusId = eventStatus.name;
	}

	return data;
};

// For countries with no regions available we must set region null before submitting data to the backend.
const cleanBeforeSubmit = ({ address, ...submitData } = {}) => {
	if (address?.region === EMPTY_REGION) {
		address.region = null;
	}
	submitData = { ...submitData, address };

	return submitData;
};

export default CreateUpdateGeneric;
