import React from 'react';

import useFilterConfig from 'components/FilterWidget/useFilterConfig';
import GenericEntry from './GenericEntry';
import { useTranslate } from 'customHooks';

const StatusEntry = ({ data, onChangeValue, type }) => {
	const translate = useTranslate();
	const { statusLocation, statusOrganizer } = useFilterConfig();

	// added exists here, since it shouldn't appear in the Select (this would happen if added it in useFilterConfig)
	const status = { location: statusLocation, organizer: statusOrganizer }[type].concat([
		{ value: '*', text: translate('filters.keyword.exists') },
	]);

	return <GenericEntry {...{ data, onChangeValue }} type="Status" options={status} />;
};

export default StatusEntry;
