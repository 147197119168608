import React from 'react';
import './MergedItem.scss';
import { get, zip } from 'lodash';

import { MAX_SHOWN_ID_MARKERS as MAX_LENGTH } from 'config';
import { setHighlightColoredIdx } from 'components/EventDetail';
import { useDelay } from 'customHooks';
import classNames from 'classnames';

export const MergedItem = ({ children, adIds, idColors }) => {
	//showing MAX_LENGTH + 1 or less id-markers and if more showing MAX_LENGTH id-markers and two dots
	const hasOverflow = !!adIds && adIds.length > MAX_LENGTH + 1;
	const shownAdIds = hasOverflow ? adIds.slice(0, MAX_LENGTH) : adIds;

	const [onMouseIn, onMouseOut] = useDelay(200);
	const handleMouseInOut = isIn => () => {
		(isIn ? onMouseIn : onMouseOut)(() => setHighlightColoredIdx(isIn, adIds));
	};

	return (
		<span className={classNames('merged-item', ...(adIds || []).map(id => `id-${id}`))}>
			<span
				className="content"
				// set min-width in order to have enough space for markers
				style={shownAdIds && { minWidth: `${(shownAdIds.length + hasOverflow) * 0.6}em` }}
				onMouseEnter={handleMouseInOut(true)}
				onMouseLeave={handleMouseInOut(false)}>
				{children}
			</span>
			{!!shownAdIds && !!idColors && (
				<div className="id-markers">
					{shownAdIds.map((id, idx) => (
						<svg className="id-marker" data-test={`id-marker-${id}`} key={idx}>
							<rect fill={idColors[id]}></rect>
						</svg>
					))}
					{hasOverflow && <div className="overflow">.. </div>}
				</div>
			)}
		</span>
	);
};

export const setHighlightItems = (isHighlight, id) =>
	document
		.querySelectorAll(`.merged-item.id-${id}`)
		.forEach(el => (el.style.backgroundColor = isHighlight ? '#00000023' : null));

export const getMergedItem = (
	{ event, dataSource, idColors } /* mergingData */,
	path,
	{ map } = {}
) => {
	const children = map ? map(get(event, path)) : get(event, path);
	return (
		children && (
			<MergedItem
				{...{
					adIds: get(dataSource, path),
					idColors,
				}}>
				{children}
			</MergedItem>
		)
	);
};

export const getMergedItems = (
	{ event, dataSource, idColors } /* mergingData */,
	path,
	{ map, comma = false } = {}
) => {
	const dataArray = get(event, path) || []; // get(event, path, []) does not work
	let adArray = get(dataSource, path) || [];
	dataArray.length > adArray.length && adArray.push(...Array(dataArray.length - adArray.length));

	return zip(dataArray, adArray)
		.map(([val, adIds], i, arr) => {
			const shownValue = map && val ? map(val) : val;

			return shownValue != null ? (
				<span key={i}>
					<MergedItem {...{ adIds, idColors }}>
						<>
							{shownValue}
							{comma && i !== arr.length - 1 && ','}
						</>
					</MergedItem>
					{comma ? ' ' : '' /* space after comma */}
				</span>
			) : null;
		})
		.filter(elem => elem !== null);
};
