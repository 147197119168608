import React from 'react';
import classNames from 'classnames';
import { Button, Popup } from 'semantic-ui-react';
import { useTranslate } from 'customHooks';
import './UpdateEvent.scss';

export default ({ disabled, isUpdateMode, setIsUpdateMode }) => {
	const translate = useTranslate();
	return (
		<Popup
			trigger={
				<Button
					className={classNames('UpdateEvent', { disabled })}
					data-test="UpdateEvent"
					onClick={() => !disabled && setIsUpdateMode(!isUpdateMode)}
					icon="edit"
				/>
			}
			content={
				disabled ? (
					<>
						<b>{translate('actions.instructionsEditMode')}</b>
						<br />
						<i>{translate('actions.warningEditMode')}</i>
					</>
				) : (
					<b>
						{isUpdateMode
							? translate('actions.leaveEditMode')
							: translate('actions.changeToEditMode')}
					</b>
				)
			}
			on="hover"
			mouseEnterDelay={500}
			position="bottom right"
			closeOnTriggerClick
		/>
	);
};
