import { useContext } from 'react';
import { TranslateContext } from 'contexts/TranslateContext';

const useTranslateContext = () => {
	const store = useContext(TranslateContext);

	return store;
};

export default useTranslateContext;
