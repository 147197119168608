import { useContext } from 'react';

import { UserContext } from 'contexts/UserContext';
import { useTranslate } from 'customHooks';
import { useCallback } from 'react';

const useFilterConfig = () => {
	const translate = useTranslate();
	const {
		eventTags = [],
		eventStatuses: _eventStatuses = [],
		locationStatuses: _locationStatuses = [],
		organizerStatuses: _organizerStatuses = [],
	} = useContext(UserContext);

	const simpleSelectActions = [
		{ value: 'include', text: translate('filters.keyword.contains') },
		{ value: 'exclude', text: translate('filters.keyword.doesntContain') },
	];

	const positiveSelectActions = [
		{ value: 'include_and', text: translate('filters.keyword.containsAll') },
		{ value: 'include_or', text: translate('filters.keyword.containsOneOf') },
		{ value: 'include_strict', text: translate('filters.keyword.containsOnly') },
		{ value: 'include_exists', text: translate('filters.keyword.exists') },
	];

	const negativeSelectActions = [
		{ value: 'exclude_and', text: translate('filters.keyword.doesntContainAll') },
		{ value: 'exclude_or', text: translate('filters.keyword.doesntContainOneOf') },
		{ value: 'exclude_strict', text: translate('filters.keyword.doesntContainOnly') },
		{ value: 'exclude_exists', text: translate('filters.keyword.doesntExist') },
	];

	const selectActions = useCallback(
		simpleSelectActions.concat(positiveSelectActions).concat(negativeSelectActions),
		[]
	);

	const operators = [
		{ value: 'greaterThanEqual', text: translate('filters.operators.atLeast') },
		{ value: 'equal', text: translate('filters.operators.equals') },
		{ value: 'lessThanEqual', text: translate('filters.operators.atMost') },
	];

	const _between = {
		value: 'between',
		text: translate('filters.operators.between'),
	};

	const customerEventsOperators = useCallback(
		operators.concat([
			_between,
			{
				value: 'underLicensed',
				text: translate('filters.operators.underLicensedCustomer'),
				info: translate('filters.operators.underLicensedCustomerInfo'),
			},
			{
				value: 'overLicensed',
				text: translate('filters.operators.overLicensedCustomer'),
				info: translate('filters.operators.overLicensedCustomerInfo'),
			},
		]),
		[]
	);

	const meslisEventsOperators = useCallback(operators.concat([_between]), []);

	const tags = useCallback(
		eventTags.map(tag => ({ value: tag, text: tag })),
		[eventTags]
	);

	const eventStatuses = useCallback(
		_eventStatuses.map(x => ({
			value: x.statusName,
			text: x.statusName,
			id: x.statusId,
		})),
		[_eventStatuses]
	);

	const locationStatuses = useCallback(
		_locationStatuses.map(x => ({
			value: x.statusName,
			text: x.statusName,
			id: x.statusId,
		})),
		[_locationStatuses]
	);

	const organizerStatuses = useCallback(
		_organizerStatuses.map(x => ({
			value: x.statusName,
			text: x.statusName,
			id: x.statusId,
		})),
		[_organizerStatuses]
	);

	const statusLocation = [
		{ value: 'New', text: translate('filters.status.newLocation') },
		{ value: 'Changed', text: translate('filters.status.changedOwner') },
		{ value: 'Known', text: translate('filters.status.known') },
	];

	const statusOrganizer = [
		{ value: 'New', text: translate('filters.status.newOrganizer') },
		{ value: 'Changed', text: translate('filters.status.changedOwner') },
		{ value: 'Known', text: translate('filters.status.known') },
	];

	const boolean = [
		{ value: 'true', text: translate('filters.boolean.true') },
		{ value: 'false', text: translate('filters.boolean.false') },
	];

	const groupings = { NONE: 0, FIELD: 1, ACTION: 2 };

	return {
		simpleSelectActions,
		positiveSelectActions,
		negativeSelectActions,
		selectActions,
		operators,
		customerEventsOperators,
		meslisEventsOperators,
		tags,
		statusLocation,
		statusOrganizer,
		boolean,
		groupings,
		eventStatuses,
		locationStatuses,
		organizerStatuses,
	};
};

export default useFilterConfig;
