import { NO_DIFFERENCE, KEEP_ATTRIBUTES_FOR_CHANGED_DATA_DIFF as KEEP_ATTRIBUTES } from 'config';
import { union, isEqual } from 'lodash';

/* computes the difference between two objects, needed for updating */
const getChangedDataDiff = (initialData, changedData, keepAttributes = KEEP_ATTRIBUTES) => {
	// Empty values, undefined and null are all mapped to null, e.g. if some input replaces null by empty string it will still be NO_DIFFERENCE.
	// eslint-disable-next-line
	if ([undefined, null, '', [], {}].some(likeNull => likeNull == initialData)) initialData = null; // needs to be '==' instead of '===' to work for []
	// eslint-disable-next-line
	if ([undefined, null, '', [], {}].some(likeNull => likeNull == changedData)) changedData = null;

	if (isEqual(initialData, changedData)) return NO_DIFFERENCE;

	// for simple arrays just returning changedData or NO_DIFFERENCE
	// for arrays of distinguishable items (objects having id) the return value is { changedItems, newItems, deletedItems }
	if ([initialData, changedData].some(data => data != null && Array.isArray(data))) {
		changedData = changedData || [];
		initialData = initialData || [];

		if (![...initialData, ...changedData].some(item => !!item?.id)) {
			return changedData;
		}

		const ids = union(
			initialData.map(item => item.id),
			changedData.map(item => item.id)
		).filter(id => id !== undefined);

		const ret = ids.reduce(
			({ changedItems, newItems, deletedItems }, id) => {
				const initialItem = initialData.find(item => item.id === id);
				const changedItem = changedData.find(item => item.id === id);

				if (initialItem == null) {
					newItems.push(changedItem);
				} else if (changedItem == null) {
					deletedItems.push(initialItem);
				} else {
					const diffValue = getChangedDataDiff(initialItem, changedItem);
					if (diffValue !== NO_DIFFERENCE) {
						changedItems.push(diffValue);
					}
				}

				return { changedItems, newItems, deletedItems };
			},

			{ changedItems: [], newItems: [], deletedItems: [] }
		);
		return [ret.changedItems, ret.newItems, ret.deletedItems].some(arr => !!arr.length)
			? ret
			: NO_DIFFERENCE;
	}

	if ([initialData, changedData].some(data => data != null && typeof data === 'object')) {
		changedData = changedData || {};
		initialData = initialData || {};

		const keys = union(Object.keys(initialData), Object.keys(changedData));

		const ret = keys.reduce((prev, key) => {
			const diffValue = getChangedDataDiff(initialData[key], changedData[key]);

			if (keepAttributes.includes(key)) {
				return { ...prev, [key]: changedData[key] };
			}

			if (diffValue === NO_DIFFERENCE) {
				return prev;
			}

			return { ...prev, [key]: diffValue };
		}, {});

		const retKeys = Object.keys(ret).filter(key => !keepAttributes.includes(key));
		if (retKeys.length) {
			return ret;
		}

		return NO_DIFFERENCE;
	}

	return changedData;
};

export default getChangedDataDiff;
