import React from 'react';

import { DimmableWrapper, Toolbar } from 'components/Base';
import { useAppContext, useCreateUpdateEvent, useQueryParams, useTranslate } from 'customHooks';
import EventDetail from 'components/EventDetail';
import {
	getFieldsConfig,
	createUpdateEventDefaultHiddenFields as defaultHiddenFields,
} from 'components/EventCarousel/getFieldsConfig';

import './index.scss';

const CreateEvent = () => {
	const { genericID, genericType } = useQueryParams();
	const { onSubmit, onChangeValue, submitIssues, loading } = useCreateUpdateEvent();

	const storageMemoKey = 'createUpdateEvent';
	const { getHiddenFieldsFor, setHiddenFieldsFor } = useAppContext();
	const hiddenFields = getHiddenFieldsFor(storageMemoKey) || defaultHiddenFields;

	const translate = useTranslate();

	return (
		<div className="CreateEvent">
			<DimmableWrapper dimmed={loading}>
				<Toolbar
					header={translate('messages.createEvent')}
					submit={{ onClick: onSubmit, submitIssues }}
					fieldSwitcher={{
						hiddenFields,
						fields: getFieldsConfig(translate, 'evTimestamp'),
						onChange: _hiddenFields => setHiddenFieldsFor(storageMemoKey, _hiddenFields),
						type: storageMemoKey,
						disabled: false,
					}}
				/>
				<EventDetail onChangeValue={onChangeValue} {...{ genericID, genericType, hiddenFields }} />
			</DimmableWrapper>
		</div>
	);
};

export default CreateEvent;
