import React from 'react';
import { Button, Icon, Popup } from 'semantic-ui-react';
import { useTranslate } from 'customHooks';

import './Reset.scss';
export default ({ isDisabled, onClick }) => {
	const translate = useTranslate();
	const handleClick = () => onClick();
	return (
		<Popup
			trigger={
				<Button
					className="ResetButton"
					data-test="ResetButton"
					icon
					onClick={handleClick}
					disabled={isDisabled}>
					<Icon name="exchange" />
				</Button>
			}
			content={<b>{translate('actions.resetCreateUpdateEvent')}</b>}
			on="hover"
			mouseEnterDelay={500}
			position="bottom right"
			closeOnTriggerClick
		/>
	);
};
