import React, { useState, useContext, useEffect } from 'react';
import { Route } from 'react-router-dom';
import { Button, Icon, Popup } from 'semantic-ui-react';
import { useMutation } from '@apollo/client';
import { HIDE_SAVED_QUERY, UPDATE_SAVED_QUERY } from 'graphql/mutations';

import Generic, { configField as _configField } from './Generic';
import { DeleteSubscription, SavedSearchIcon, SearchQueryCount } from 'components/Base';
import { FilterWidgetContext } from 'contexts';
import { useMessagesContext, useTranslateContext, useUserContext } from 'customHooks';
import SelectColleague from './Generic/SelectColleague';
import classNames from 'classnames';
import { alerts } from 'utils';

import './Subscriptions.scss';

const configField = props => _configField({ ...props, multipleSort: false });

const Subscriptions = ({
	data,
	dataUsers: companyUsers,
	initialColleaguesOfQueries,
	initialIsHiddenOfQueries,
	onRefetchAll,
	sorting,
	onSort,
}) => {
	const { translate, formatDate } = useTranslateContext();
	const {
		event,
		location,
		organizer,
		filterList: { setEditItem },
	} = useContext(FilterWidgetContext);
	const { user } = useUserContext();
	const { setOnUpdateQueries } = useMessagesContext();

	setOnUpdateQueries(() => onRefetchAll());
	const { id: myUserId } = user || {};

	const [updateSavedQuery, { loading: loadingUpdate }] = useMutation(UPDATE_SAVED_QUERY, {
		onError: () => alerts.failMessageGenerator('search', 'updated'),
	});
	const [hideSavedQuery, { loading: loadingHide }] = useMutation(HIDE_SAVED_QUERY, {
		onError: () => alerts.failMessageGenerator('search', 'updated'),
	});

	const loading = loadingUpdate || loadingHide;

	// colleagues, that the query is shared with
	const [colleaguesOfQueries, setColleaguesOfQueries] = useState(initialColleaguesOfQueries);
	// needs to update every time additional queries are loaded or queries are shared with the user

	const [isHiddenOfQueries, setIsHiddenOfQueries] = useState(initialIsHiddenOfQueries);

	const setHiddenOfQuery = (id, hidden) => {
		isHiddenOfQueries[id] = hidden;
		setIsHiddenOfQueries({ ...isHiddenOfQueries });
	};

	const [{ isShowHidden, isShowUnhidden }, setShowHiddenUnhidden] = useState({
		isShowHidden: false,
		isShowUnhidden: true,
	});

	const toggleShowUnhidden = () =>
		setShowHiddenUnhidden({
			isShowHidden: !isShowHidden,
			isShowUnhidden: isShowHidden ? true : isShowUnhidden, // both states cannot be false at the same time
		});
	const toggleShowHidden = () =>
		setShowHiddenUnhidden({
			isShowUnhidden: !isShowUnhidden,
			isShowHidden: isShowUnhidden ? true : isShowHidden,
		});

	useEffect(() => {
		setColleaguesOfQueries(initialColleaguesOfQueries);
	}, [initialColleaguesOfQueries]);

	const getRowHeight = (_, _data) => {
		// Math.PI * Thumb
		// Estimate row height by number of chosen colleagues
		// which is not exact because names might be of different length.
		return (
			(_data && colleaguesOfQueries[_data.id]
				? Math.floor(colleaguesOfQueries[_data.id].length / 4) + 1
				: 1) *
				30 +
			15
		);
	};

	const fields = getFields({
		data,
		translate,
		formatDate,
		isHiddenOfQueries,
		myUserId,
		updateSavedQuery,
		loading,
		companyUsers,
		colleaguesOfQueries,
		event,
		location,
		organizer,
		setEditItem,
		hideSavedQuery,
		setHiddenOfQuery,
		onRefetchAll,
		setColleaguesOfQueries,
	});

	const shownData = data.filter(
		dataItem =>
			(isShowHidden && isHiddenOfQueries[dataItem.id]) ||
			(isShowUnhidden && !isHiddenOfQueries[dataItem.id])
	);

	const renderedButtons = (
		<Button.Group basic>
			<Popup
				trigger={
					<Button
						className={classNames('displayQueries', isShowUnhidden ? 'enabled' : 'notenabled')}
						onClick={() => toggleShowHidden()}
						icon={true}>
						<Icon name={'eye'} />
					</Button>
				}
				content={translate('queryTableView.showButton.showUnhidden')}
			/>

			<Popup
				trigger={
					<Button
						className={classNames(
							'displayQueries',
							'hidden',
							isShowUnhidden ? 'enabled' : 'notenabled'
						)}
						onClick={() => toggleShowUnhidden()}
						icon={true}>
						<Icon name={'eye slash'} />
					</Button>
				}
				content={translate('queryTableView.showButton.showHidden')}
			/>
		</Button.Group>
	);

	const getRowClassName = (_, dataItem) => {
		return isHiddenOfQueries[dataItem.id] ? 'hidden' : null;
	};

	return (
		<>
			<Generic
				name={translate('navigation.subscriptions')}
				{...{
					data: shownData,
					getRowClassName,
					renderedButtons,
					getRowHeight,
					fields,
					sorting,
					onSort,
				}}
				tableKey="search-query"
				storageMemoKey="search-query"
			/>
		</>
	);
};

const getFields = ({
	data,
	translate,
	formatDate,
	isHiddenOfQueries,
	myUserId,
	updateSavedQuery,
	loading,
	companyUsers,
	colleaguesOfQueries,
	event,
	location,
	organizer,
	setEditItem,
	hideSavedQuery,
	setHiddenOfQuery,
	onRefetchAll,
	setColleaguesOfQueries,
}) => [
	configField({
		name: 'type',
		label: translate('queryTableView.type'),
		sortable: true,
		movable: false,
		render: data => <SavedSearchIcon value={data.type} id={data.id} />,
	}),
	configField({
		name: 'id',
		label: translate('queryTableView.id'),
		sortable: true,
		movable: false,
	}),
	configField({ name: 'name', label: translate('queryTableView.name'), sortable: true }),
	configField({
		name: 'filters',
		label: translate('queryTableView.filters'),
		sortable: true,
		sortField: 'searchQueryLength',
		render: data => data.searchQueryLength,
	}),
	configField({
		name: 'hits',
		label: translate('queryTableView.hits'),
		render: data => (
			<SearchQueryCount
				filters={data && data.resolvedSearchQuery && data.resolvedSearchQuery.filters}
				quickFilter={data && data.resolvedSearchQuery && data.resolvedSearchQuery.quickFilter}
				type={data.type}
			/>
		),
	}),
	configField({
		name: 'createdAt',
		label: translate('queryTableView.createdAt'),
		sortable: true,
		render: data => formatDate(data.createdAt),
	}),
	configField({
		name: 'owner',
		label: translate('queryTableView.createdBy'),
		sortable: true,
		sortField: 'user.fullName',
		render: data => data.user && data.user.fullName,
	}),
	configField({
		name: 'share',
		label: translate('queryTableView.sharedWith'),
		sortable: true,
		sortField: 'sharedUsersLength',
		render: _data => {
			// if creator === me
			const isMine = _data.user && _data.user.id === myUserId;
			const isHidden = isHiddenOfQueries[_data.id];
			return (
				<SelectColleague
					// the || case is required since otherwise the app erros when additional queries load or are shared with the user
					// the useEffect for initialColleaguesOfQueries updates colleaguesOfQueries, but during that update the App errors since colleaguesOfQueries[_data.id] can be undefined for 1 cycle (not even visible for user usually)
					colleagues={(colleaguesOfQueries[_data.id] || _data.sharedUsers).map(user => user.id)}
					onChangeColleagues={val => {
						// optimistically update colleaguesOfQueries even before result of mutation is known
						setColleaguesOfQueries({
							...colleaguesOfQueries,
							[_data.id]: val,
						});

						updateSavedQuery({
							variables: {
								savedQuery: { id: _data.id, sharedUserIds: val.map(user => user.id) /* name */ },
							},
						}).then(response => {
							if (!response) {
								// if no response (query errors), change colleaguesOfQueries back to previous value
								// value seems to be snapshot when function is executed, so "colleaguesOfQueries" here is the same one that is destructured above to set the new value
								setColleaguesOfQueries(colleaguesOfQueries);
							} else {
								// if query resolves mutate sharedUsers for that query to match (done, so that sorting respects new length, etc & for concurrency)
								const dataToChange = data.find(query => query.id === _data.id);
								dataToChange.sharedUsers = val;
								dataToChange.sharedUsersLength = val.length;
							}
						});
					}}
					creatorId={_data.user && _data.user.id}
					disabled={!isMine || isHidden || loading}
					{...{ companyUsers }}
				/>
			);
		},
	}),
	configField({
		name: 'actions',
		label: translate('queryTableView.actions'),
		sortable: true,
		sortField: 'type',
		movable: false,
		render: _data => {
			const isHidden = isHiddenOfQueries[_data.id];
			const handleClick = history => () => {
				const { setFilters, setQuickFilter, setBothSortings } = {
					EVENT: event,
					LOCATION: location,
					ORGANIZER: organizer,
				}[_data.type];

				setFilters(_data.resolvedSearchQuery.filters);
				_data.resolvedSearchQuery.quickFilter &&
					setQuickFilter &&
					setQuickFilter(_data.resolvedSearchQuery.quickFilter);
				_data.resolvedSearchQuery.sorting && setBothSortings(_data.resolvedSearchQuery.sorting);
				setEditItem(-1);

				history.push(
					{
						EVENT: '/events',
						LOCATION: '/locations',
						ORGANIZER: '/organizers',
					}[_data.type]
				);
			};

			const buttonIcon = {
				EVENT: 'search icon',
				LOCATION: 'map marker alternate',
				ORGANIZER: 'address book',
			}[_data.type];

			const buttonName = {
				EVENT: translate('queryTableView.searchButton.event'),
				LOCATION: translate('queryTableView.searchButton.location'),
				ORGANIZER: translate('queryTableView.searchButton.organizer'),
			}[_data.type];

			const handleShowHide = () => {
				const hidden = !isHidden;
				hideSavedQuery({
					variables: {
						id: _data.id,
						hide: hidden,
					},
				});

				setHiddenOfQuery(_data.id, hidden);
			};

			return (
				<>
					<Route
						render={({ history }) => (
							<Popup
								trigger={
									<Button
										className="applySavedSearchButton"
										onClick={handleClick(history)}
										disabled={isHidden}
										icon>
										<Icon className={buttonIcon} />
									</Button>
								}
								content={buttonName}
								position="top right"
								mouseEnterDelay={700}
							/>
						)}
					/>
					<Popup
						trigger={
							<Button
								className={classNames('showHide', { hidden: isHidden })}
								onClick={handleShowHide}
								icon>
								{isHidden ? <Icon name={'eye'} /> : <Icon name={'eye slash'} />}
							</Button>
						}
						content={
							isHidden
								? translate('queryTableView.showButton.show')
								: translate('queryTableView.showButton.hide')
						}
						position="top right"
						mouseEnterDelay={700}
					/>
					{_data.user && _data.user.id === myUserId && (
						<DeleteSubscription
							{...{ data: _data, onRefetchAll }}
							disabled={(colleaguesOfQueries[_data.id] || _data.sharedUsers).length !== 0}
							message={
								_data.sharedUsers.length !== 0
									? translate('queryTableView.unshareRequired')
									: translate('queryTableView.delete')
							}
						/>
					)}
				</>
			);
		},
	}),
];

export default Subscriptions;
