import forge from 'node-forge';

export const xmlParseToObject = xml => {
	let parsedXMLObject = {};

	const parser = new DOMParser();
	const xmlDoc = parser.parseFromString(xml, 'text/xml');

	parsedXMLObject.Modulus = xmlDoc.getElementsByTagName('Modulus')[0].childNodes[0].nodeValue;
	parsedXMLObject.Exponent = xmlDoc.getElementsByTagName('Exponent')[0].childNodes[0].nodeValue;

	return parsedXMLObject;
};

const rsaEncrypt = async (plaintext, pk) => {
	return await pk()
		.then(response => {
			return encryptAlg(plaintext, response);
		})
		.catch(err => console.error(err));
};

const encryptAlg = (plaintext, pubKey) => {
	const pt = forge.util.encodeUtf8(plaintext);

	/*suggested by pedrofb @
      https://stackoverflow.com/questions/45687270/how-can-i-convert-rsa-plblickey-xml-to-pem-format
      based on
      https://github.com/digitalbazaar/forge/issues/169
      */
	//parse xml to object

	const rsaKeyValue = xmlParseToObject(pubKey);

	var BigInteger = forge.jsbn.BigInteger;
	const parseBigInteger = b64 => {
		return new BigInteger(forge.util.createBuffer(forge.util.decode64(b64)).toHex(), 16);
	};
	//Create a Forge public key from modulus and exponent
	const publicKey = forge.pki.setRsaPublicKey(
		parseBigInteger(rsaKeyValue.Modulus), // n
		parseBigInteger(rsaKeyValue.Exponent)
	);

	const encryptedPT = publicKey.encrypt(pt, 'RSAES-PKCS1-V1_5');
	return forge.util.encode64(encryptedPT);
};

export default rsaEncrypt;
