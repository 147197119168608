import React from 'react';

import SubscribeForm from './SubscribeForm';
import FilterForm from './FilterForm';
import FilterList from './FilterList';
import filterUtils from './filterUtils';

import {
	keywordOr,
	keywordOrExists,
	keywordOrAndExists,
	keywordOrAndStrictExists,
	numberRange,
	statusList,
	meslisEvents,
	customerEvents,
	dateRange,
	collaboration,
	userSelect,
	entityStatusSelect,
} from 'formTypes';
import { useTranslate } from 'customHooks';

import './OrganizersFilter.scss';

const OrganizersFilter = ({ filters, sorting, onChangeFilters }) => {
	const translate = useTranslate();
	const fields = organizerFields(translate);

	const updateFilters = filters => onChangeFilters(filterUtils.removeDuplicates(filters));

	const handleAddFilter = filter => {
		updateFilters([...filters, { ...filter, ...{ enabled: true } }]);
	};

	return (
		<div className="OrganizersFilter">
			<FilterForm
				{...{ fields }}
				confirmButtonText={translate('filters.add')}
				type="organizer"
				onAddFilter={handleAddFilter}
			/>
			<hr />
			<SubscribeForm enabled={filters.length} origin={'organizer'} {...{ filters, sorting }} />
			{!!filters.length ? (
				<FilterList {...{ fields, filters }} type="organizer" onChangeFilters={updateFilters} />
			) : null}
		</div>
	);
};

const setFilterField = (name, label, formType) => ({
	name,
	label,
	formType,
	defaultValue: formType.defaultValue,
});

export const organizerFields = translate => [
	setFilterField('collaboration', translate('tableView.infoCollaboration'), collaboration),
	setFilterField('id', translate('tableView.orgId'), keywordOr),
	setFilterField('matchedOrganizerIds', translate('tableView.orgMatchedIds'), keywordOrExists),
	setFilterField('commercialId', translate('tableView.orgCommercialId'), keywordOrAndExists),
	setFilterField('name', translate('tableView.orgName'), keywordOrAndStrictExists),
	setFilterField('address.street', translate('tableView.orgStreet'), keywordOrAndStrictExists), // will search for street and house number
	setFilterField('address.postalCode', translate('tableView.orgZIP'), keywordOrExists),
	setFilterField('address.city', translate('tableView.orgCity'), keywordOrAndStrictExists),
	setFilterField('address.region', translate('tableView.orgRegion'), keywordOrAndStrictExists),
	setFilterField('address.country', translate('tableView.orgCountry'), keywordOrExists),
	setFilterField('category', translate('tableView.orgCategory'), keywordOrAndStrictExists),
	setFilterField('emailAddresses', translate('tableView.orgMail'), keywordOrAndExists),
	setFilterField('phoneNumbers', translate('tableView.orgPhone'), keywordOrAndExists),
	setFilterField('urls', translate('tableView.orgURL'), keywordOrAndExists),
	setFilterField('timestamp', translate('tableView.orgTimestamp'), dateRange),
	setFilterField('customerStatus', translate('tableView.orgCustomerStatus'), statusList),
	setFilterField(
		'customerOrganizers.eventCountPerYear',
		translate('tableView.orgCustomerEventCountPerYear'),
		customerEvents
	),
	setFilterField(
		'statistic.eventCountPerYear',
		translate('tableView.orgStatisticEventCountPerYear'),
		meslisEvents
	),
	setFilterField(
		'statistic.eventCountTotal',
		translate('tableView.orgEventCountTotal'),
		numberRange
	),
	setFilterField(
		'statistic.eventCountRelevant',
		translate('tableView.orgEventCountRelevant'),
		numberRange
	),
	setFilterField(
		'statistic.eventCountIrrelevant',
		translate('tableView.orgEventCountIrrelevant'),
		numberRange
	),
	setFilterField('status.name', translate('tableView.orgStatusName'), entityStatusSelect),
	setFilterField('status.username', translate('tableView.orgStatusUsername'), userSelect),
	setFilterField('status.lastChangedAt', translate('tableView.orgStatusLastChangedAt'), dateRange),
];

export default OrganizersFilter;
