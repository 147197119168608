import React, { useState, useEffect } from 'react';
import storage from 'storage';

import Sidebar from 'components/Sidebar';
import classNames from 'classnames';
import './SidebarLayout.scss';

const SidebarLayout = ({ children }) => {
	const [collapsed, setCollapsed] = useState(storage.isSidebarCollapsed());

	useEffect(() => storage.setSidebarCollapsed(!!collapsed), [collapsed]);

	return (
		<div className={classNames('SidebarLayout', { collapsed })}>
			<Sidebar collapsed={collapsed} onCollapse={() => setCollapsed(!collapsed)} />
			<main>{children}</main>
		</div>
	);
};

export default SidebarLayout;
