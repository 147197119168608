import React from 'react';
import { Form, Button, Select, Icon, Label } from 'semantic-ui-react';
import useFilterConfig from '../useFilterConfig';
import filterUtils from '../filterUtils';
import classNames from 'classnames';
import './ActionAssociationSelect.scss';

const ActionAssociationSelect = ({
	action,
	association,
	allowedAssociations,
	onChangeAction,
	onChangeAssociation,
}) => {
	let { selectAction } = filterUtils.adoptToSelectAction(
		{ action, association },
		!!allowedAssociations
	);
	const { negativeSelectActions, positiveSelectActions, selectActions } = useFilterConfig();

	const handleNegate = () => {
		selectAction = filterUtils.oppositeAction(selectAction);
		const { action } = filterUtils.adoptToActionAssociation({ selectAction });
		onChangeAction(action);
	};

	const handleChangeVal = (e, target) => {
		selectAction = target.value;
		const { association } = filterUtils.adoptToActionAssociation({ selectAction });
		onChangeAssociation(association);
	};

	const isNegative = selectAction.includes('exclude');
	const hasManyOptions = !!allowedAssociations && allowedAssociations.length > 1;
	return (
		<Form.Field width={16}>
			<Button
				className={'action-negate-association-container'}
				fluid
				as="div"
				labelPosition="right"
				tabIndex={'undefined'}>
				<Button
					className={classNames('action-negate-button', isNegative ? 'negative' : 'positive')}
					icon
					type={'button'}
					onClick={handleNegate}
					tabIndex="0">
					<Icon
						name={'adjust'}
						className={classNames('action-icon', { 'horizontally flipped': isNegative })}
					/>
				</Button>
				<Label
					className="action-label"
					basic
					pointing="left"
					tabIndex={hasManyOptions ? '1' : undefined}>
					{hasManyOptions ? (
						<Select
							className="action-select"
							value={selectAction}
							options={(isNegative ? negativeSelectActions : positiveSelectActions).filter(opt =>
								allowedAssociations.find(ass => opt.value.includes(ass))
							)}
							onChange={handleChangeVal}
						/>
					) : (
						<span onClick={handleNegate}>
							{selectActions.find(a => a.value === selectAction).text}
						</span>
					)}
				</Label>
			</Button>
		</Form.Field>
	);
};

export default ActionAssociationSelect;
