import React from 'react';
import { Input } from 'semantic-ui-react';
import { useTranslateContext } from 'customHooks';

export default ({ value, onChange, ...props }) => {
	const { language } = useTranslateContext();
	const separator = { en: '.', de: ',' }[language] || ',';

	if (!!value) {
		value = value.replace('.', separator);
	}

	const handleChange = (e, { value, ...target }) => {
		if (!!value && !/^[0-9]{0,5}([.,][0-9]{0,2})?$/.test(value)) return;

		if (value.startsWith(separator)) {
			value = value.replace(separator, `${'00' + separator}`);
		}

		value = value.replace(',', '.');

		onChange(e, { value, ...target });
	};

	return <Input value={value} onChange={handleChange} {...props} />;
};
