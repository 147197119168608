import React from 'react';
import { useTranslate } from 'customHooks';
import { Card, Divider, Dropdown, Icon } from 'semantic-ui-react';
import useFilterConfig from '../useFilterConfig';

const Header = ({ grouping, onSetGrouping, onDeleteAllFilters }) => {
	const { groupings } = useFilterConfig();
	const translate = useTranslate();

	return (
		<Card.Content>
			<Dropdown
				position="left"
				className="list-settings"
				trigger={<Icon name="ellipsis vertical" />}
				icon={null}>
				<Dropdown.Menu className="left">
					<Dropdown.Item onClick={onDeleteAllFilters}>
						<Icon name="delete" color="red" /> {translate('actions.deleteAll')}
					</Dropdown.Item>
					<Divider />
					<Dropdown.Item
						active={grouping === groupings.NONE}
						onClick={() => onSetGrouping(groupings.NONE)}
						icon="list"
						text={translate('actions.noGrouping')}
					/>
					<Dropdown.Item
						active={grouping === groupings.FIELD}
						onClick={() => onSetGrouping(groupings.FIELD)}
						icon="list"
						text={translate('actions.groupingByField')}
					/>
					<Dropdown.Item
						active={grouping === groupings.ACTION}
						onClick={() => onSetGrouping(groupings.ACTION)}
						icon="list"
						text={translate('actions.groupingByAction')}
					/>
				</Dropdown.Menu>
			</Dropdown>
			<Card.Header textAlign="center">{translate('filters.filters')}</Card.Header>
		</Card.Content>
	);
};

export default Header;
