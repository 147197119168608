import React from 'react';
import { LOCATION_ORGANIZER_CATEGORIES as CATEGORIES } from 'config';
import { Dropdown } from 'semantic-ui-react';
import classNames from 'classnames';

const CategoryInput = ({ value, onChange, className, placeholder, ...inputProps }) => {

	if (!!value && !CATEGORIES.includes(value)) {
		// fall back for updating if spelling in data base differs from spelling in predefined list
		placeholder = value;
	}
	return (
		<Dropdown
			className={classNames('CategoryInput', className)}
			options={CATEGORIES.map(category => ({ text: category, value: category }))}
			selection
			clearable
			value={value}
			onChange={(_, { value }) => onChange(value)}
			{...{ placeholder, ...inputProps }}
		/>
	);
};

export default CategoryInput;
