import React from 'react';
import { Button } from 'semantic-ui-react';
import { useTranslate } from 'customHooks';

import Meslis from 'components/Meslis';

const DeleteLeaflet = ({ id, leaflet, events, onActionCompleted, ...props }) => {
	const translate = useTranslate();

	return (
		<Meslis.DeleteCustomLeaflet id={id} leaflet={leaflet}>
			{f => (
				<Button
					data-test="editLeafletDeleteButton"
					disabled={!!events && !Object.entries(events).length === 0}
					color="grey"
					onClick={() => {
						f();
						onActionCompleted();
					}}
					{...props}>
					{translate('leaflets.delete')}
				</Button>
			)}
		</Meslis.DeleteCustomLeaflet>
	);
};

export default DeleteLeaflet;
