import { useState, useEffect } from 'react';
import { useOrganizerLocationQueryById, useQueryParams } from 'customHooks';
import { checkRecursiveObjectNotEmpty, formatTimeStamp, getChangedDataDiff } from 'utils';
import {
	CREATE_UPDATE_EVENT_MANDATORY_FIELDS as EVENT_MANDATORY_FIELDS,
	CREATE_UPDATE_LOCATION_MANDATORY_FIELDS as LOCATION_MANDATORY_FIELDS,
	CREATE_UPDATE_ORGANIZER_MANDATORY_FIELDS as ORGANIZER_MANDATORY_FIELDS,
	STANDARD_MOMENT_FORMAT,
	NO_DIFFERENCE,
	NEW_EVENT_ID,
} from 'config';
import { get, set, cloneDeep } from 'lodash';

const useCreateUpdateEventDetail = ({
	genericID,
	genericType,
	onChangeValue,
	initialData,
	isUpdateMode, // if true for updating existing event
	setIsUpdateMode,
	onRefresh,
}) => {
	const isCreateMode = initialData?.id === NEW_EVENT_ID; // if true for creating new event
	const isNormalMode = !isCreateMode && !isUpdateMode; // if true then no input fields are shown in detail view

	const [
		{ changedValueDiff, changedValue, invalidFields },
		setChangedValueInvalidFields,
	] = useState({
		changedValue: transformInitialData(initialData),
		invalidFields: [],
		changedValueDiff: NO_DIFFERENCE,
	});

	const valueEmpty = !checkRecursiveObjectNotEmpty(changedValue);

	// If allowDropAds is set then the dropping of ads from another browser tab of another event is allowed.
	// It is allowed to drop on an existing event in normal mode or to drop on a new event without filled input fields.
	const allowDropAds = isNormalMode || (isCreateMode && valueEmpty);

	const [isCreateNewLocation, setIsCreateNewLocation] = useState(); // true = create new location with custom data, false = set existing location
	const [isCreateNewOrganizer, setIsCreateNewOrganizer] = useState(); // true = create new organizer with custom data, false = set existing organizer

	const { pushURL } = useQueryParams();

	useEffect(() => {
		!!initialData && handleReset(); // will reset with new initial data
		initialData?.id === NEW_EVENT_ID &&
			!!genericType &&
			!!genericID &&
			requestInitOrganizerLocation(`${genericID}`); // predefined organizer/location when invoking from organizer select or location select
		// eslint-disable-next-line
	}, [initialData?.id, isUpdateMode]);

	const handleChangeSubmitted = ({ id } = {}) => {
		if (id === changedValue?.id) {
			setIsUpdateMode(false);
			onRefresh();
		}
		pushURL({ rerouteTo: 'events', view: 'detail', currentId: id });
	};

	const handleChange = (_changedValue, _invalidFields) => {
		const changedValueDiff = getChangedDataDiff(transformInitialData(initialData), _changedValue);

		const submitIssues = getSubmitIssues(_changedValue, changedValueDiff, _invalidFields);

		if (!!submitIssues) {
			onChangeValue({ submitIssues, onReset: handleReset });
		} else {
			onChangeValue({
				initialData,
				changedValue: _changedValue,
				changedValueDiff,
				onChangeSubmitted: handleChangeSubmitted,
				onReset: handleReset,
			});
		}

		setChangedValueInvalidFields({
			changedValue: _changedValue,
			changedValueDiff,
			invalidFields: _invalidFields,
		});
	};

	const handleChangeValue = (path, value, valid = true) => {
		set(changedValue, path, value);

		const _invalidFields = invalidFields.filter(_path => path !== _path);
		!valid && _invalidFields.push(path);

		handleChange(changedValue, _invalidFields);
	};

	// Handler function for the reset button on update event mode.
	const handleReset = () => handleChange(transformInitialData(initialData), []);
	const isMissingField = (parent, field) => {
		const value = get(parent, field);
		return Array.isArray(value) ? value.every(_value => !_value) : !value;
	};

	const isUpdatedField = path => {
		if (typeof path === 'object') {
			const arrayPath = get(path, 'arrayPath');
			const id = get(path, 'id');
			const nestedPath = get(path, 'path');

			const ret = get(changedValueDiff, arrayPath);
			const items = ret && [
				...(ret.changedItems || []),
				...(ret.newItems || []),
				...(ret.deletedItems || []),
			];

			const updatedItem = items && items.find(item => item.id === id);

			return updatedItem !== undefined ? get(updatedItem, nestedPath) !== undefined : false;
		} else {
			return isUpdateMode ? get(changedValueDiff, path) !== undefined : false;
		}
	};

	const getSubmitIssues = (changedValue, changedValueDiff, invalidFields) => {
		let missingFields = EVENT_MANDATORY_FIELDS.filter(field => isMissingField(changedValue, field));
		changedValue?.images?.some(image => !image.url) && missingFields.push('images');

		// reporting fields handling
		const invoiceInitialValue = initialData?.invoice;
		const invoiceNumber = changedValue?.invoice?.invoiceNumber;
		const itemNumber = changedValue?.invoice?.itemNumber;

		if (!changedValue?.images?.some(image => !!image.pdfId || !!image.file))
			missingFields.push('noImages');

		if (!!changedValue?.editedLocation?.isCreateNewLocation) {
			missingFields = missingFields.filter(field => field !== 'location');
			missingFields.push(
				...LOCATION_MANDATORY_FIELDS.filter(field =>
					isMissingField(changedValue?.editedLocation, field)
				).map(attr => `editedLocation.${attr}`)
			);
		}

		if (!!changedValue?.editedOrganizer?.isCreateNewOrganizer) {
			missingFields = missingFields.filter(field => field !== 'organizer');
			missingFields.push(
				...ORGANIZER_MANDATORY_FIELDS.filter(field =>
					isMissingField(changedValue?.editedOrganizer, field)
				).map(attr => `editedOrganizer.${attr}`)
			);
		}

		if (!invoiceInitialValue && (invoiceNumber || itemNumber)) {
			!invoiceNumber && missingFields.push('invoiceNumber');
			!itemNumber && missingFields.push('itemNumber');
		}

		const isUnchanged = changedValueDiff === NO_DIFFERENCE;

		if (!missingFields.length && !invalidFields.length && !isUnchanged) return null;

		return { missingFields, invalidFields, isUnchanged };
	};
	// eslint-disable-next-line
	const [_, requestInitOrganizerLocation] = useOrganizerLocationQueryById({
		// _ not needed
		onResponse: item => item && handleChangeValue(genericType, { ...(item || {}), count: 1 }),
		type: genericType,
		skip: !genericType,
		fieldName: 'id',
	});

	return {
		allowDropAds,
		changedValue,
		onChangeValue: handleChangeValue,
		handleReset,
		isUpdatedField,
		isCreateNewLocation,
		setIsCreateNewLocation,
		isCreateNewOrganizer,
		setIsCreateNewOrganizer,
	};
};

const transformInitialData = (data = {}) => {
	if (data.id === NEW_EVENT_ID) return { rating: data.rating };
	data = cloneDeep(data);
	data.admission = data.admission?.map(_admission => `${_admission.price}`);

	// unifying date to STANDARD_MOMENT_FORMAT and ignoring time zone
	// second parameter 'showSeconds' must be true because timestampCode not provided for STANDARD_MOMENT_FORMAT
	data.startDate = data.startDate && formatTimeStamp(data.startDate, true, STANDARD_MOMENT_FORMAT);
	data.endDate = data.endDate && formatTimeStamp(data.endDate, true, STANDARD_MOMENT_FORMAT);

	data.organizer =
		data?.organizers?.find(
			organizer => organizer?.id && organizer?.id === data?.selectedOrganizer?.id
		) ||
		(data?.organizers?.length && data.organizers[0]);

	// ads will be shown as image input fields but instead of a local image
	// file the pdfId is used to show the evidence image
	data.images =
		data.advertisements?.results.map((ad, idx) => ({
			type: 'adImage',
			id: ad.id,
			url: ad.url,
			pdfId: ad.pdfId,
			valid: true,
			position: idx,
		})) || [];

	return data;
};

export default useCreateUpdateEventDetail;
