import React, { useLayoutEffect, useRef, useState } from 'react';
import { isEqual } from 'lodash';

import { getFieldsConfig, createUpdateEventDefaultHiddenFields } from './getFieldsConfig';

import { Toolbar, LoadingContent, Msg, DimmableWrapper } from 'components/Base';
import EventDetail from 'components/EventDetail';
import Meslis from 'components/Meslis';
import {
	useQueryParams,
	usePrevious,
	useUpdateEvents,
	useUserContext,
	useTranslate,
	useAppContext,
	useCreateUpdateEvent,
} from 'customHooks';
import { NO_OFFSET } from 'config';
import { noop, isSelectableAndUnlocked, isSelectableAndLockedByMe } from 'utils';
import storage from 'storage';

import './index.scss';

const EventCarousel = ({ filters, sorting, sourceLeaflets, ...props }) => {
	const { offset, currentId, pushURL } = useQueryParams({ offset: 0 });

	const handleCompleted = data => {
		const { totalCount, results } = data.searchEventsBySearchParams;

		// if offset is greater than totalCount set offset to last valid value / 0
		if (offset > totalCount - 1 || results.length === 0) {
			pushURL({
				offset: totalCount - 1 >= 0 ? totalCount - 1 : 0,
			});
		}
	};

	// assures that event not null when reloading
	// => dimmer is applied instead of LoadingContent showing view with old data
	const shownEvent = useRef();

	return (
		<div className="EventCarousel" data-test="EventCarousel">
			{currentId == null || currentId === 0 ? (
				<Meslis.EventsDetail
					leafletIds={sourceLeaflets}
					{...{ filters, sorting, offset }}
					max={1}
					onCompleted={handleCompleted}>
					{({ data = {}, error, loading, refetch }) => {
						shownEvent.current = !!data?.searchEventsBySearchParams?.results?.length
							? data.searchEventsBySearchParams.results[0]
							: shownEvent.current;
						return (
							<EventDetailContainer
								type="EventsDetail"
								event={shownEvent.current}
								{...{ error, loading, refetch, currentId, filters, sourceLeaflets, ...props }}
							/>
						);
					}}
				</Meslis.EventsDetail>
			) : (
				<Meslis.Event id={currentId}>
					{({ data = {}, error, loading, refetch }) => {
						shownEvent.current = data.getEvent || shownEvent.current;
						return (
							<EventDetailContainer
								type="Event"
								event={shownEvent.current}
								{...{
									error,
									loading,
									refetch,
									currentId,
									filters,
									sourceLeaflets,
									...props,
								}}
							/>
						);
					}}
				</Meslis.Event>
			)}
		</div>
	);
};

const EventDetailContainer = ({
	type,
	event,
	error,
	loading,
	refetch,
	currentId,
	filters,
	sourceLeaflets,
	onChangeToTableView,
	totalCount,
	onActionCompleted = noop,
	onRefresh,
	refreshRequired,
	storageMemoKey: storageTableMemoKey,
	...props
}) => {
	const { offset, pushURL } = useQueryParams({ offset: 0 });
	const previousFilters = usePrevious(filters);
	const { deleteEvent } = useUpdateEvents();
	const { user } = useUserContext();
	const translate = useTranslate();

	const [expectedVersion, setExpectedVersion] = useState(-1);

	const _isSelectableAndUnlocked = isSelectableAndUnlocked(event, user?.username);
	const _isSelectableAndLockedByMe = isSelectableAndLockedByMe(event, user?.username);

	// Configuring hiddenFields for the detail view

	// this handles switching from Meslis.getEvent to Meslis.EventsDetail (especially when filters are only editted)
	const joinedFilters = JSON.stringify(filters);
	useLayoutEffect(() => {
		if (currentId !== 0 && previousFilters && !isEqual(filters, previousFilters)) {
			handleChangeIndex(offset);
		}
		// eslint-disable-next-line
	}, [joinedFilters]);

	const [isUpdateMode, setIsUpdateMode] = useState(false);

	useLayoutEffect(() => {
		!!isUpdateMode && setIsUpdateMode(false);
		expectedVersion !== -1 && setExpectedVersion(-1);
		// eslint-disable-next-line
	}, [event?.id]);

	const {
		onSubmit,
		onChangeValue,
		submitIssues,
		loading: loadingCreate,
		onReset,
	} = useCreateUpdateEvent({
		isUpdate: true,
	});
	loading |= loadingCreate;

	const storageDetailMemoKey = isUpdateMode ? 'createUpdateEvent' : 'detailView';
	const defaultHiddenFields = isUpdateMode ? createUpdateEventDefaultHiddenFields : [];
	const {
		getHiddenFieldsFor,
		setHiddenFieldsFor,
		genericIDContext,
		genericTypeContext,
	} = useAppContext();
	const hiddenFields = getHiddenFieldsFor(storageDetailMemoKey) || defaultHiddenFields;

	const handleChangeIndex = newOffset => {
		pushURL({ offset: Number(offset > totalCount - 1 ? totalCount - 1 : newOffset), currentId: 0 });
	};

	deleteEmptyEvent({ data: event, loading, deleteEvent });

	const handleActionCompleted = () => {
		if (offset !== NO_OFFSET) {
			offset >= totalCount - 1
				? handleChangeIndex(offset - 1)
				: type === 'EventsDetail'
				? refetch()
				: handleChangeIndex(offset);
		}
		onActionCompleted(event);
	};

	const handleRefresh = () => {
		if (offset !== NO_OFFSET) {
			offset >= totalCount - 1
				? handleChangeIndex(totalCount - 1)
				: type === 'EventsDetail'
				? refetch()
				: handleChangeIndex(offset);
		}
		onRefresh();
	};

	const handleRefreshSameId = () =>
		type === 'EventsDetail'
			? new Promise(() => {
					pushURL({ currentId: event?.id, view: 'detail' });
			  }) // No resolve needed because pushURL is reloading anyway
			: refetch();
	return loading && !event ? (
		<LoadingContent />
	) : !!error ? (
		<div>Error</div>
	) : (
		<DimmableWrapper dimmed={(loading && !!event) || (event?.version || 0) < expectedVersion}>
			<Toolbar
				{...{
					header: isUpdateMode && translate('messages.updateEvent'),
					listNavigator: {
						offset,
						total: totalCount,
						onChangeIndex: handleChangeIndex,
					},
					actions: {
						onCompleted: handleActionCompleted,
						sourceLeaflets,
						events: event && [event],
					},
					dragMode: {
						disabled:
							!event ||
							!_isSelectableAndUnlocked ||
							!event.advertisements ||
							!event.advertisements.totalCount,
					},
					updateEvent: {
						isUpdateMode,
						setIsUpdateMode,
						disabled: !event || !_isSelectableAndLockedByMe,
						isOnly: isUpdateMode,
					},
					createNewEvent: { genericID: genericIDContext, genericType: genericTypeContext },
					submit: isUpdateMode && { onClick: onSubmit, submitIssues, isOnly: true },
					reset: isUpdateMode && {
						isDisabled: submitIssues?.isUnchanged,
						onClick: onReset,
					},
					table: { onClick: onChangeToTableView },
					lock: {
						id: event?.id,
						version: event?.version,
						lockedType: 'event',
						userLock: event?.userLock,
						disabled: !_isSelectableAndUnlocked,
						setExpectedVersion,
					},
					// no undo and refresh in myHistory
					undo: {
						disabled: !event || !_isSelectableAndUnlocked || storageTableMemoKey === 'history',
						onRefresh: handleRefresh,
					},
					refresh: {
						disabled: !refreshRequired || storageTableMemoKey === 'history',
						onRefresh: handleRefresh,
					},
					fieldSwitcher: {
						hiddenFields,
						fields: getFieldsConfig(translate, isUpdateMode && 'evTimestamp'),
						onChange: _hiddenFields => setHiddenFieldsFor(storageDetailMemoKey, _hiddenFields),
						type: storageDetailMemoKey,
						disabled: false,
						isOnly: isUpdateMode,
					},
					isUpdateMode,
				}}
			/>
			{!event ? (
				<Msg />
			) : (
				<EventDetail
					data={event}
					onRefresh={handleRefreshSameId}
					{...{
						isLockedByOtherUser: !_isSelectableAndUnlocked,
						isUpdateMode,
						setIsUpdateMode,
						onChangeValue,
						hiddenFields,
						...props,
					}}
				/>
			)}
		</DimmableWrapper>
	);
};

const deleteEmptyEvent = ({ data, loading, deleteEvent }) => {
	if (
		!loading &&
		!!data &&
		!!data.id &&
		(!data.advertisements || data.advertisements.totalCount === 0) &&
		storage.getEmptiedEventId() === data.id
	) {
		deleteEvent(storage.getEmptiedEventId());
		storage.setEmptiedEventId(null);
	}
};

export default EventCarousel;
