import React from 'react';
import { useTranslate, useUserContext } from 'customHooks';
import { Button, Popup } from 'semantic-ui-react';
import { CollaborationIcons } from 'components/Base';
import { LOCK_EVENTS, UNLOCK_EVENTS, LOCK_ORGANIZERS, UNLOCK_ORGANIZERS } from 'graphql/mutations';
import { useMutation } from '@apollo/client';
import { alerts } from 'utils';
import classNames from 'classnames';

import './Lock.scss';

const Lock = ({
	className,
	id,
	version,
	lockedType,
	userLock,
	disabled /* might be disabled by lockedByColleage but also if moved to leaflet of other user */,
	setExpectedVersion,
}) => {
	const translate = useTranslate();

	const {
		user: { username: currentUsername },
	} = useUserContext();

	const isLocked = userLock?.isLocked;
	const currentUserLocking = userLock?.user.username === currentUsername;

	const [lockEvents] = useMutation(LOCK_EVENTS, {
		onError: () => {
			setExpectedVersion(-1);
			alerts.otherUserLockedEvent();
		},
	});

	const [unlockEvents] = useMutation(UNLOCK_EVENTS, {
		onError: () => {
			setExpectedVersion(-1);
			alerts.failMessageGenerator('event', 'unlocked');
		},
	});

	const [lockOrganizers] = useMutation(LOCK_ORGANIZERS, {
		onError: () => {
			setExpectedVersion(-1);
			alerts.otherUserLockedOrganizer();
		},
	});

	const [unlockOrganizers] = useMutation(UNLOCK_ORGANIZERS, {
		onError: () => {
			setExpectedVersion(-1);
			alerts.failMessageGenerator('organizer', 'unlocked');
		},
	});

	const [lock, unlock] = {
		event: [lockEvents, unlockEvents],
		organizer: [lockOrganizers, unlockOrganizers],
	}[lockedType];

	const handleClick = () => {
		const variables = { idsWithVersion: [{ id, version }] };
		const operation = isLocked ? (currentUserLocking ? unlock : null) : lock;
		if (!!operation) {
			operation({ variables });
			setExpectedVersion(version + 1);
		}
	};

	return (
		<Popup
			trigger={
				<Button
					className={classNames(
						'LockButton',
						className,
						isLocked && (currentUserLocking ? 'lockedByMe' : 'lockedByOtherUsers'),
						{ disabled } // only classname no real disabeling in order to get popup to work correctly
					)}
					data-test="LockButton"
					icon={isLocked ? 'lock' : 'lock open'}
					onClick={!disabled ? handleClick : null}
				/>
			}
			content={
				isLocked ? (
					<>
						<b>
							{currentUserLocking
								? translate(`actions.unlock.${lockedType}`)
								: translate(`actions.locked.${lockedType}`)}
						</b>
						<br />
						<i>{<CollaborationIcons userLock={userLock} />}</i>
						<br />
						<i>
							{currentUserLocking
								? translate(`tooltips.lockButton.${lockedType}.unlock`)
								: translate(`tooltips.lockButton.${lockedType}.locked`)}
						</i>
					</>
				) : disabled && lockedType === 'event' ? (
					<i>{translate(`tooltips.lockButton.event.disabled`)}</i>
				) : (
					<>
						<b>{translate(`actions.lock.${lockedType}`)}</b>
						<br />
						<i>{translate(`tooltips.lockButton.${lockedType}.lock`)}</i>
						<br />
					</>
				)
			}
			position="bottom right"
			mouseEnterDelay={700}
			closeOnTriggerClick
		/>
	);
};

export default Lock;
