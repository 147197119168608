import React from 'react';
import InfoPopup from './InfoPopup';
import { useMeasureContext } from 'customHooks';
import { Input } from 'semantic-ui-react';
import { CurrencyInput } from '.';

// an input field that grows according to its content
export default ({
	value,
	type,
	font = {},
	widthOffset = 0,
	maxWidth = Number.POSITIVE_INFINITY,
	isWidthEmpty,
	infoPopup = {},
	...props
}) => {
	font = { ...DEFAULT_FONT, ...font };
	const { showInfo, content: message, className: styling, type: parentComponent } = infoPopup;

	const measureContext = useMeasureContext(font);

	let width = Math.ceil(measureContext.measureText(value || '').width);
	width = width > 0 || isWidthEmpty ? width + widthOffset : undefined;

	if (width) {
		width = Math.min(width, maxWidth);
		width = `${width}px`;
	}

	return type === 'currency' ? (
		<CurrencyInput value={value} style={{ width, ...font }} {...props} />
	) : (
		<>
			<Input value={value} type={type} style={{ width, ...font }} {...props} />
			{showInfo && <InfoPopup content={message} className={styling} type={parentComponent} />}
		</>
	);
};

// cannot be defined in scss because it is needed for computing
const DEFAULT_FONT = {
	fontStyle: 'normal',
	fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
	fontSize: '13px',
};
