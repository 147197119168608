import React from 'react';
import { Query } from '@apollo/client/react/components';

import { CUSTOMLEAFLETS } from 'graphql/queries';

const CustomLeaflets = ({ children, ...props }) => (
	<Query query={CUSTOMLEAFLETS} fetchPolicy="network-only" {...props}>
		{children}
	</Query>
);

export default CustomLeaflets;
