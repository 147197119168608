import React from 'react';
import useFilterConfig from 'components/FilterWidget/useFilterConfig';
import { Select } from 'semantic-ui-react';
import classNames from 'classnames';

const GenericDropdownInput = ({
	value,
	onChange,
	className,
	type,
	allowMultiple = false,
	...props
}) => {
	const {
		tags,
		eventStatuses: _eventStatuses,
		locationStatuses: _locationStatuses,
		organizerStatuses: _organizerStatuses,
	} = useFilterConfig();

	const eventStatuses = _eventStatuses.map(x => ({ value: x.id, text: x.text }));
	const locationStatuses = _locationStatuses.map(x => ({ value: x.id, text: x.text }));
	const organizerStatuses = _organizerStatuses.map(x => ({ value: x.id, text: x.text }));

	const setOptions = type => {
		switch (type) {
			case 'eventTypes':
				return tags;
			case 'eventStatus':
				return eventStatuses;
			case 'locationStatus':
				return locationStatuses;
			case 'organizerStatus':
				return organizerStatuses;
			default:
				return [];
		}
	};

	const options = setOptions(type);

	if (!options || !options?.length) {
		return <></>;
	}

	return (
		<Select
			className={classNames('GenericDropdownInput', className)}
			value={value}
			search
			options={options}
			onChange={(_, { value }) => onChange(value)}
			multiple={allowMultiple}
			clearable={type !== 'eventTypes'}
			{...props}
		/>
	);
};

export default GenericDropdownInput;
