import React, { useState } from 'react';
import { useTranslate } from 'customHooks';
import { Input } from 'semantic-ui-react';

import config from './config';
import classNames from 'classnames';

import './index.scss';

const TableSettings = ({ data, onChangeData, ...props }) => {
	const translate = useTranslate();
	const [isError, setError] = useState(false);

	const checkError = (name, value) => {
		let _isError =
			name === 'defaultFetchSize'
				? value < config.selectionMin || value > config.selectionMax
				: false;
		isError !== _isError && setError(_isError);
		return _isError;
	};

	const handleInput = (name, value) => {
		// without this, it is possible to input '9999999' using the keyboard
		// but inputting 999 starting with 9 should be possible
		const _isError = checkError(name, value);
		onChangeData(name, value, _isError);
	};

	checkError('defaultFetchSize', !!data && data.defaultFetchSize);

	return (
		<div {...props} className={classNames('TableSettings', props.className)}>
			<h4>{translate('userSettings.defaultFetchSize')}</h4>
			<Input
				type="number"
				className={classNames({ error: isError })}
				value={!!data && data.defaultFetchSize}
				min={config.selectionMin}
				max={config.selectionMax}
				step={config.selectionStep}
				onChange={(_, target) => handleInput('defaultFetchSize', target.value)}
			/>
		</div>
	);
};

export default TableSettings;

export const defaultTableData = config.defaultTableData;
