import React from 'react';
import { useTranslate } from 'customHooks';
import { Message } from 'semantic-ui-react';

import './index.scss';

const Success = () => {
	const translate = useTranslate();
	return (
		<div className="PasswordSuccess">
			<div className="logo">
				<img alt="logo" src={`${process.env.PUBLIC_URL}/MESLIS_logo_white.png`} />
			</div>
			<div className="centralMessage">
				<Message success>
					<Message.Header>{translate('messages.passwordResetSuccessfulHeader')}</Message.Header>
					{translate('messages.passwordResetSuccessful', [
						<a href={window.location.origin}> MESLIS WebApp</a>,
					])}
				</Message>
			</div>
		</div>
	);
};

export default Success;
