import { ICONS } from 'config';

const getFieldsConfig = (translate, omit = '') => {
	const fieldsConfig = [
		{
			name: 'evTimestamp',
			label: translate('genDetailView.evTimestamp'),
			listIcon: ICONS.EVENT_MUSIC,
		},
		{
			name: 'evDates',
			label: translate('genDetailView.evDate'),
			listIcon: ICONS.EVENT_MUSIC,
		},
		{
			name: 'evInvoiceNumber',
			label: translate('genDetailView.evInvoiceNumber'),
			listIcon: ICONS.EVENT_MUSIC,
		},
		{
			name: 'evItemNumber',
			label: translate('genDetailView.evItemNumber'),
			listIcon: ICONS.EVENT_MUSIC,
		},
		{
			name: 'evStatusName',
			label: translate('genDetailView.evStatusName'),
			listIcon: ICONS.EVENT_MUSIC,
		},
		{
			name: 'evStatusUsername',
			label: translate('genDetailView.evStatusUsername'),
			listIcon: ICONS.EVENT_MUSIC,
		},
		{
			name: 'evStatusLastChangedAt',
			label: translate('genDetailView.evStatusLastChangedAt'),
			listIcon: ICONS.EVENT_MUSIC,
		},
		{
			name: 'evCustomerIds',
			label: translate('genDetailView.evCustomerIds'),
			listIcon: ICONS.EVENT_MUSIC,
		},
		{
			name: 'evAdmission',
			label: translate('genDetailView.evAdmission'),
			listIcon: ICONS.EVENT_MUSIC,
		},
		{
			name: 'evArtists',
			label: translate('genDetailView.evArtists'),
			listIcon: ICONS.EVENT_MUSIC,
		},
		{
			name: 'evLineup',
			label: translate('genDetailView.evLineup'),
			listIcon: ICONS.EVENT_MUSIC,
		},
		{
			name: 'evAttendees',
			label: translate('genDetailView.evAttendees'),
			listIcon: ICONS.EVENT_MUSIC,
		},
		{
			name: 'evTags',
			label: translate('genDetailView.evTags'),
			listIcon: ICONS.EVENT_MUSIC,
		},
		{
			name: 'evUrl',
			label: translate('genDetailView.evUrl'),
			listIcon: ICONS.EVENT_MUSIC,
		},
		{
			name: 'evCancelled',
			label: translate('genDetailView.evCancelled'),
			listIcon: ICONS.EVENT_MUSIC,
		},
		{
			name: 'evLocationEqualsOrganizer',
			label: translate('genDetailView.evLocationEqualsOrganizer'),
			listIcon: ICONS.EVENT_MUSIC,
		},
		{
			name: 'locName',
			label: translate('genDetailView.locName'),
			listIcon: ICONS.LOCATION,
		},
		{
			name: 'locTimestamp',
			label: translate('genDetailView.locTimestamp'),
			listIcon: ICONS.LOCATION,
		},
		{
			name: 'locStatusName',
			label: translate('genDetailView.locStatusName'),
			listIcon: ICONS.LOCATION,
		},
		{
			name: 'locStatusUsername',
			label: translate('genDetailView.locStatusUsername'),
			listIcon: ICONS.LOCATION,
		},
		{
			name: 'locStatusLastChangedAt',
			label: translate('genDetailView.locStatusLastChangedAt'),
			listIcon: ICONS.LOCATION,
		},
		{
			name: 'locCustomerIds',
			label: translate('genDetailView.locCustomerIds'),
			listIcon: ICONS.LOCATION,
		},
		{
			name: 'locCommercialId',
			label: translate('genDetailView.locCommercialId'),
			listIcon: ICONS.LOCATION,
		},
		{
			name: 'locAddress',
			label: translate('genDetailView.locAddress'),
			listIcon: ICONS.LOCATION,
		},
		{
			name: 'locCategory',
			label: translate('genDetailView.locCategory'),
			listIcon: ICONS.LOCATION,
		},
		{
			name: 'locEmails',
			label: translate('genDetailView.locEmails'),
			listIcon: ICONS.LOCATION,
		},
		{
			name: 'locPhoneNumbers',
			label: translate('genDetailView.locPhoneNumbers'),
			listIcon: ICONS.LOCATION,
		},
		{
			name: 'locWebsites',
			label: translate('genDetailView.locWebsites'),
			listIcon: ICONS.LOCATION,
		},
		{
			name: 'orgName',
			label: translate('genDetailView.orgName'),
			listIcon: ICONS.ORGANIZER,
		},
		{
			name: 'orgTimestamp',
			label: translate('genDetailView.orgTimestamp'),
			listIcon: ICONS.ORGANIZER,
		},
		{
			name: 'orgStatusName',
			label: translate('genDetailView.orgStatusName'),
			listIcon: ICONS.ORGANIZER,
		},
		{
			name: 'orgStatusUsername',
			label: translate('genDetailView.orgStatusUsername'),
			listIcon: ICONS.ORGANIZER,
		},
		{
			name: 'orgStatusLastChangedAt',
			label: translate('genDetailView.orgStatusLastChangedAt'),
			listIcon: ICONS.ORGANIZER,
		},
		{
			name: 'orgCustomerIds',
			label: translate('genDetailView.orgCustomerIds'),
			listIcon: ICONS.ORGANIZER,
		},
		{
			name: 'orgCommercialId',
			label: translate('genDetailView.orgCommercialId'),
			listIcon: ICONS.ORGANIZER,
		},
		{
			name: 'orgAddress',
			label: translate('genDetailView.orgAddress'),
			listIcon: ICONS.ORGANIZER,
		},
		{
			name: 'orgCategory',
			label: translate('genDetailView.orgCategory'),
			listIcon: ICONS.ORGANIZER,
		},
		{
			name: 'orgEmails',
			label: translate('genDetailView.orgEmails'),
			listIcon: ICONS.ORGANIZER,
		},
		{
			name: 'orgPhoneNumbers',
			label: translate('genDetailView.orgPhoneNumbers'),
			listIcon: ICONS.ORGANIZER,
		},
		{
			name: 'orgWebsites',
			label: translate('genDetailView.orgWebsites'),
			listIcon: ICONS.ORGANIZER,
		},
	];

	return fieldsConfig.filter(obj => obj.name !== omit);
};

const createUpdateEventDefaultHiddenFields = [
	'evCustomerIds',
	'evInvoiceNumber',
	'evItemNumber',
	'evStatusName',
	'evStatusUsername',
	'evStatusLastChangedAt',
	'evArtists',
	'evLineup',
	'evTags',
	'evUrl',
	'evCancelled',
	'evLocationEqualsOrganizer',
	'locTimestamp',
	'locStatusName',
	'locStatusUsername',
	'locStatusLastChangedAt',
	'locCategory',
	'locEmails',
	'locPhoneNumbers',
	'locWebsites',
	'orgTimestamp',
	'orgStatusName',
	'orgStatusUsername',
	'orgStatusLastChangedAt',
	'orgCategory',
	'orgEmails',
	'orgPhoneNumbers',
	'orgWebsites',
];

export { getFieldsConfig, createUpdateEventDefaultHiddenFields };
