import { init } from '@elastic-deecoob/apm-rum';

import browserStorage from 'browserStorage';
import { getParamsObject } from 'customHooks/useQueryParams';

const graphQlServer =
	process.env.REACT_APP_GRAPHQL_PROTOCOL +
	'://' +
	process.env.REACT_APP_GRAPHQL_HOST +
	':' +
	process.env.REACT_APP_GRAPHQL_PORT;
const name = () => {
	const { step } = getParamsObject(window.location.search);
	return ['/events', '/locations', '/organizers'].some(path =>
		window.location.pathname.includes(path)
	)
		? window.location.pathname + '?' + (step ? step : 'search')
		: window.location.pathname;
};

let apm = {};
if (process.env.REACT_APP_APM_SERVER) {
	apm = init({
		// Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
		serviceName: 'MeslisWebApp',

		// Set custom APM Server URL (default: http://localhost:8200)
		serverUrl: process.env.REACT_APP_APM_SERVER,

		// Set service version (required for sourcemap feature)
		serviceVersion: process.env.REACT_APP_VERSION,

		//--// optional settings
		// logLevel: 'debug',
		pageLoadTransactionName: name(),
		breakdownMetrics: true,
		// needs to be handled by backend agents too
		distributedTracingOrigins: [graphQlServer],
		environment: String(process.env.REACT_APP_GRAPHQL_HOST),
		ignoreTransactions: [/POST http:\/\/\w*:\d*\/sockjs-node.*/],
	});

	apm.setUserContext({ username: browserStorage.getUser() });
	apm.observe('transaction:end', transaction => {
		if (transaction.type === 'http-request') {
			if (transaction.name === `POST ${graphQlServer}/graphql`) {
				// get operationNames from spans
				const queryNames = transaction.spans
					.filter(span => span.context.tags && span.context.tags.operationName)
					.map(span => span.context.tags.operationName);
				transaction.name = `GraphQl ${queryNames.join(', ')}`;
			} else if (transaction.name.includes(process.env.REACT_APP_GRAPHQL_PDF_PATH)) {
				transaction.name = 'REST getPdf/*';
			}
		}
	});
}

export default apm;
