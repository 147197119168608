import { useRef, useCallback, useState, useEffect } from 'react';
import { KEYCODES } from 'config';

const useUndoSelection = ({
	forwardSelections,
	lastSelections,
	setSelection,
	selection,
	sourceLeaflets,
}) => {
	const [step, setStep] = useState(selection);
	useEffect(() => {
		selection === undefined ? setStep(selection) : setStep(1);
	}, [selection]);

	const handleStrgZ = useCallback(() => {
		forwardSelections.push(selection);
		lastSelections.length > 0 && setSelection(lastSelections.pop());
	}, [selection, forwardSelections, lastSelections, setSelection]);

	const handleStrgShiftZ = useCallback(() => {
		lastSelections.push(selection);
		forwardSelections.length > 0 && setSelection(forwardSelections.pop());
	}, [selection, forwardSelections, lastSelections, setSelection]);

	const latestHandleStrgZ = useRef(handleStrgZ);
	const latestHandleStrgShiftZ = useRef(handleStrgShiftZ);

	useEffect(() => {
		latestHandleStrgShiftZ.current = handleStrgShiftZ;
	}, [handleStrgShiftZ]);

	useEffect(() => {
		latestHandleStrgZ.current = handleStrgZ;
	}, [handleStrgZ]);

	useEffect(() => {
		let container = document.querySelector('.Container');
		const handleKeyDown = e => {
			if (!container) {
				container = document.querySelector('.Container');
			} else {
				e.keyCode === KEYCODES.Z &&
					e.ctrlKey === true &&
					e.shiftKey === false &&
					latestHandleStrgZ.current();
				e.keyCode === KEYCODES.Z &&
					e.ctrlKey === true &&
					e.shiftKey === true &&
					latestHandleStrgShiftZ.current();

				if (step !== undefined && !container.classList.contains('keydown')) {
					e.shiftKey && container.classList.add('keyblock');
					(e.ctrlKey || e.shiftKey) && container.classList.add('keydown');
				}
			}
		};

		const handleKeyUp = e => {
			!container
				? (container = document.querySelector('.Container'))
				: !e.ctrlKey && !e.shiftKey && container.classList.remove('keydown', 'keyblock');
		};

		window.addEventListener('keydown', handleKeyDown);
		window.addEventListener('keyup', handleKeyUp);
		// Remove event listeners on cleanup
		return () => {
			window.removeEventListener('keydown', handleKeyDown);
			window.removeEventListener('keyup', handleKeyUp);
		};
	}, [step, sourceLeaflets]);
};

export default useUndoSelection;
