import React from 'react';
import { useTranslateContext } from 'customHooks';

const conditionalDisplay = (item, descriptor) =>
	!!item ? (
		<>
			<strong>{descriptor} </strong>
			<span>{item} </span>
		</>
	) : null;

const DateRangeEntry = ({ data }) => {
	const { translate, formatDate } = useTranslateContext();
	return (
		<span data-test="RangeEntry">
			{conditionalDisplay(formatDate(data.from, true), translate('filters.range.from'))}
			{conditionalDisplay(formatDate(data.to, true), translate('filters.range.to'))}
		</span>
	);
};

export default DateRangeEntry;
