import { useMutation } from '@apollo/client';
import { get } from 'lodash';
import { COPY_EVENT_ADS, MOVE_EVENT_ADS, CREATE_EVENT, DELETE_EVENT } from 'graphql/mutations';
import { NEW_EVENT_ID } from 'config';

import { noop, alerts } from 'utils';
import useAppContext from './useAppContext';
import useQueryParams from './useQueryParams';
import useTranslateContext from './useTranslateContext';

export default ({ onRefresh = noop } = {}) => {
	const { pushUndoList, setHasPendingMoveAction } = useAppContext();
	const { pushURL } = useQueryParams({ offset: 0 });
	const { translate } = useTranslateContext();

	// mutations ///////////////////////////////////////////////

	const [moveEventAdsMutation] = useMutation(MOVE_EVENT_ADS, {
		onError: () => alerts.failMessageGenerator('advertisements', 'moved'),
	});

	const [copyEventAdsMutation] = useMutation(COPY_EVENT_ADS, {
		onError: () => alerts.failMessageGenerator('advertisements', 'copied'),
	});

	const [createEventMutation] = useMutation(CREATE_EVENT, {
		onError: () => alerts.failMessageGenerator('event', 'created'),
	});

	const [deleteEventMutation] = useMutation(DELETE_EVENT, {
		onError: () => alerts.failMessageGenerator('event', 'deleted'),
	});

	// move ///////////////////////////////////////////////////////

	const _moveAds = (eventIdsWithAdIds, targetEventId) =>
		new Promise(resolve => {
			moveEventAdsMutation({
				variables: {
					moveEventAdsInput: {
						eventIdsWithAdIds,
						targetEventId,
					},
				},
			}).then(val => resolve({ success: get(val, 'data.moveEventAds') }));
		});

	const _moveAdsPushUndo = (adIds, sourceEventId, targetEventId, targetWasEmpty) =>
		pushUndoList(
			getUndoObject(
				[
					getMoveAdsUndo([getEventIdWithAdIds(adIds, targetEventId)], sourceEventId),
					...(targetWasEmpty ? [getDeleteEventUndo(targetEventId)] : []),
				],
				'moveAds'
			)
		);

	const _moveAdsError = (adIds = [], sourceEventId, targetEventId) =>
		alerts.errorOperation(
			translate('alerts.linkUnlink.noMoveAds', [adIds.join(', '), sourceEventId, targetEventId])
		);

	const moveAdsOfEventsPushUndo = (eventIdsWithAdIds, targetEventId) =>
		pushUndoList(
			getUndoObject(
				eventIdsWithAdIds.map(({ eventId, adIds }) =>
					getMoveAdsUndo([getEventIdWithAdIds(adIds, targetEventId)], eventId)
				),
				'moveAdsOfEvents',
				true,
				true
			)
		);

	const moveAdsOfEventsError = (eventIdsWithAdIds, targetEventId) =>
		alerts.errorOperation(getNoMoveAdsTranslation(eventIdsWithAdIds, targetEventId));

	const _moveAdsOfEventsUndoError = (promise, errorList, { eventIdsWithAdIds, targetEventId }) =>
		new Promise(resolve =>
			promise.then(({ success }) => {
				!success && errorList.push(getNoMoveAdsTranslation(eventIdsWithAdIds, targetEventId));
				resolve();
			})
		);

	// move new ///////////////////////////////////////////////////////

	const _moveAdsToNew = eventIdsWithAdIds =>
		new Promise(resolve =>
			_copyAdsToNew(null).then(({ eventId, success }) => {
				success && _moveAds(eventIdsWithAdIds, eventId);
				resolve({ eventId, success });
			})
		);

	const _moveAdsToNewPushUndo = (adIds, sourceEventId, targetId) =>
		pushUndoList(
			getUndoObject(
				[
					getMoveAdsUndo([getEventIdWithAdIds(adIds, targetId)], sourceEventId),
					getDeleteEventUndo(targetId),
				],
				'moveAdsToNew'
			)
		);

	const _moveAdsToNewError = (adIds = [], sourceEventId) =>
		alerts.errorOperation(
			translate('alerts.linkUnlink.noMoveAdsToNew', [adIds.join(', '), sourceEventId])
		);

	// copy //////////////////////////////////////////////////////////////

	const _copyAds = (eventIdWithAdIds, targetEventId) =>
		new Promise(resolve => {
			copyEventAdsMutation({
				variables: {
					copyEventAdsInput: {
						eventIdWithAdIds,
						targetEventId,
					},
				},
			}).then(val => {
				const adIds = get(val, 'data.copyEventAds.adIds');
				const success = get(val, 'data.copyEventAds.success');
				resolve({ adIds, success });
			});
		});

	const _copyAdsPushUndo = (adIds, targetEventId, targetWasEmpty) =>
		pushUndoList(
			getUndoObject(
				[
					getMoveAdsUndo([getEventIdWithAdIds(adIds, targetEventId)], null),
					...(targetWasEmpty ? [getDeleteEventUndo(targetEventId)] : []),
				],
				'copyAds'
			)
		);

	const _copyAdsPushError = (adIds = [], sourceEventId, targetEventId) =>
		alerts.errorOperation(
			translate('alerts.linkUnlink.noCopyAds', [adIds.join(', '), sourceEventId, targetEventId])
		);

	// copy new //////////////////////////////////////////////////////////////

	const _copyAdsToNew = adIds =>
		new Promise(resolve => {
			createEventMutation({
				variables: {
					adIdsListInput: adIds && { adIds },
				},
			}).then(val => {
				const eventId = get(val, 'data.createEvent.eventId');
				const adIds = get(val, 'data.createEvent.adIds');
				const success = get(val, 'data.createEvent.success');
				resolve({ eventId, adIds, success });
			});
		});

	const _copyAdsToNewPushUndo = (adIds, newId) =>
		pushUndoList(
			getUndoObject(
				[getMoveAdsUndo([getEventIdWithAdIds(adIds, newId)], null), getDeleteEventUndo(newId)],
				'copyAdsToNew'
			)
		);

	const _copyAdsToNewError = (adIds = [], sourceEventId) =>
		alerts.errorOperation(
			translate('alerts.linkUnlink.noCopyAdsToNew', [adIds.join(', '), sourceEventId])
		);

	// delete /////////////////////////////////////////////////////////////////////////

	const _deleteEvent = id =>
		new Promise(resolve => {
			deleteEventMutation({
				variables: {
					id,
				},
			}).then(val => resolve({ success: get(val, 'data.deleteEvent') }));
		});

	const _deleteEventUndoError = (promise, errorList, muta) =>
		new Promise(resolve =>
			promise.then(({ success }) => {
				!success && errorList.push(translate('alerts.linkUnlink.noDeleteEvent', [muta.id || '']));
				resolve();
			})
		);

	// Hint: There are no undos for delete because delete is not directly triggered by user action.

	// returns /////////////////////////////////////////////////////////////////////////

	const moveAds = (adIds, sourceEventId, targetEventId, targetWasEmpty = false) => {
		if (sourceEventId === NEW_EVENT_ID) return;
		// no loading dimmer
		if (targetEventId !== NEW_EVENT_ID) {
			_moveAds([getEventIdWithAdIds(adIds, sourceEventId)], targetEventId).then(({ success }) => {
				if (success) {
					_moveAdsPushUndo(adIds, sourceEventId, targetEventId, targetWasEmpty);
					onRefresh();
				} else {
					_moveAdsError(adIds, sourceEventId, targetEventId);
					dispatchError();
				}
			});
		} else {
			_moveAdsToNew([getEventIdWithAdIds(adIds, sourceEventId)]).then(({ eventId, success }) => {
				if (success) {
					_moveAdsToNewPushUndo(adIds, sourceEventId, eventId);
					pushURL({
						rerouteTo: 'events',
						currentId: eventId,
						view: 'detail',
						isNoOldParams: true,
					});
				} else {
					_moveAdsToNewError(adIds, sourceEventId);
					dispatchError();
				}
			});
		}
	};

	const moveAdsOfEvents = (eventIdsWithAdIds, targetEventId) => {
		setHasPendingMoveAction(true); // loading dimmer
		_moveAds(eventIdsWithAdIds, targetEventId).then(async ({ success }) => {
			if (success) {
				const deletedEvents = (eventIdsWithAdIds || []).map(
					eventIdWithAdIds => eventIdWithAdIds.eventId
				);
				await Promise.all(deletedEvents.map(id => _deleteEvent(id)));
				moveAdsOfEventsPushUndo(eventIdsWithAdIds, targetEventId);
			} else moveAdsOfEventsError(eventIdsWithAdIds, targetEventId);
			setHasPendingMoveAction(false);
			onRefresh();
		});
	};

	const copyAds = (adIds, sourceEventId, targetEventId, targetWasEmpty = false) => {
		if (sourceEventId === NEW_EVENT_ID) return;
		// no loading dimmer
		if (targetEventId !== NEW_EVENT_ID) {
			_copyAds(getEventIdWithAdIds(adIds, sourceEventId), targetEventId).then(
				({ success, adIds: adIdsNew }) => {
					if (success) {
						_copyAdsPushUndo(adIdsNew, targetEventId, targetWasEmpty);
						onRefresh();
					} else {
						_copyAdsPushError(adIds, sourceEventId, targetEventId);
						dispatchError();
					}
				}
			);
		} else {
			_copyAdsToNew(adIds).then(({ eventId, adIds: adIdsNew, success }) => {
				if (success) {
					_copyAdsToNewPushUndo(adIdsNew, eventId);
					pushURL({
						rerouteTo: 'events',
						currentId: eventId,
						view: 'detail',
						isNoOldParams: true,
					});
				} else {
					_copyAdsToNewError(adIds, sourceEventId);
					dispatchError();
				}
			});
		}
	};

	const performUndo = async undoObject => {
		setHasPendingMoveAction(true); // loading dimmer
		const errorList = [];
		const mutations = undoObject.mutations.map(muta => {
			if (muta.type === 'moveAds') {
				const { eventIdsWithAdIds, targetEventId } = muta;
				return () =>
					_moveAdsOfEventsUndoError(_moveAds(eventIdsWithAdIds, targetEventId), errorList, muta);
			} else {
				/*  if (muta.type === 'deleteEvent') */
				const { id } = muta;
				return () => _deleteEventUndoError(_deleteEvent(id), errorList, muta);
			}
		});
		if (undoObject.isParallel) {
			await Promise.all(mutations.map(muta => muta()));
		} else {
			for (let idx = 0; idx < mutations.length; idx++) {
				const muta = mutations[idx];
				await muta();
			}
		}
		setHasPendingMoveAction(false);
		if (!!errorList.length) {
			alerts.errorOperation([translate('alerts.linkUnlink.noUndo'), ...errorList].join('\n'));
			dispatchError();
		} else {
			undoObject.isReload && onRefresh();
		}
	};

	const getUndoObject = (mutations, alertKey, isParallel = false, isReload = false) => ({
		type: 'updateEvents',
		mutations,
		alertKey,
		isParallel,
		isReload,
	});

	const getMoveAdsUndo = (eventIdsWithAdIds, targetEventId) => ({
		type: 'moveAds',
		eventIdsWithAdIds,
		targetEventId,
	});

	const getDeleteEventUndo = id => ({
		type: 'deleteEvent',
		id,
	});

	const getEventIdWithAdIds = (adIds, eventId) => ({
		eventId,
		adIds,
	});

	const getNoMoveAdsTranslation = (eventIdsWithAdIds = [], targetEventId) =>
		translate('alerts.linkUnlink.noMoveAds', [
			eventIdsWithAdIds.reduce((pre, { adIds = [] }) => [...pre, ...adIds], []).join(', '),
			eventIdsWithAdIds.map(({ eventId }) => eventId).join(', '),
			`${targetEventId}`,
		]);

	const dispatchError = () => document.dispatchEvent(new Event('updateEventsError'));

	return { moveAds, moveAdsOfEvents, copyAds, deleteEvent: _deleteEvent, performUndo };
};
