import de from './de.js';
import en from './en.js';
import pl from './pl.js';

const dictionary = {
	de: de,
	en: en,
	pl: pl,
};

const getDictionary = (locale = 'en') => dictionary[locale];

export default getDictionary;
