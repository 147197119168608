import React, { useContext } from 'react';
import { Card } from 'semantic-ui-react';

import FilterDetail from './FilterDetail';
import Header from './Header';

import './index.scss';
import { FilterWidgetContext } from 'contexts/FilterWidgetContext';
import useFilterConfig from '../useFilterConfig';

const FilterList = ({ fields, filters, onChangeFilters, type }) => {
	const { groupings } = useFilterConfig();
	const {
		filterList: { grouping, setGrouping, editItem, setEditItem },
	} = useContext(FilterWidgetContext);

	// Sort methods
	const sortByField = (a, b) => (a.fieldName < b.fieldName ? -1 : 0);

	const sortByAction = (a, b) =>
		a.value.action + a.value.association < b.value.action + b.value.association ? -1 : 0;

	const noSort = () => 0;

	const sortFunction = grouping =>
		grouping === groupings.NONE
			? noSort
			: grouping === groupings.FIELD
			? sortByField
			: sortByAction;

	const sortedFilters = [...filters].sort(sortFunction(grouping));

	// filters is unsorted, index comes from sortedFilters => need to use findIndex to find the index in the filters array matching that value - MESLIS-5887
	const originalIndex = index => filters.findIndex(e => e === sortedFilters[index]);

	const handleChange = index => filter => {
		setEditItem(-1);
		onChangeFilters(
			filters.map((_filter, _index) => (_index === originalIndex(index) ? filter : _filter))
		);
	};

	const handleDeleteFilter = index => () =>
		onChangeFilters(filters.filter((_, _index) => _index !== originalIndex(index)));

	const handleDeleteAllFilters = () => {
		setEditItem(-1);
		onChangeFilters([]);
	};

	const handleSetGrouping = grouping => {
		setEditItem(-1);
		setGrouping(grouping);
	};

	const handleEditFilter = index => () => setEditItem(index);

	return (
		<div className="FilterList">
			<Card fluid className="filterListHeader">
				<Header
					{...{ grouping }}
					onDeleteAllFilters={handleDeleteAllFilters}
					onSetGrouping={handleSetGrouping}
				/>
				<Card.Content className="filterListContent">
					{sortedFilters.map((item, index) => (
						<FilterDetail
							{...{ fields, type }}
							key={index}
							data={item}
							editableDeletable={editItem < 0}
							editMode={index === editItem}
							onEdit={handleEditFilter(index)}
							onCancel={handleEditFilter(-1)}
							onDelete={handleDeleteFilter(index)}
							onChange={handleChange(index)}
						/>
					))}
				</Card.Content>
			</Card>
		</div>
	);
};

export default FilterList;
