import React, { useState, useContext, useEffect } from 'react';

import Navi from './Navi';
import GenericPager from './GenericPager';
import GenericEventFilters from './GenericEventFilters';
import SortExpandMenu from 'components/CustomLeaflet/SortExpandMenu';

import { Msg, LoadingContent, StickySidebarElement, DimmableWrapper } from 'components/Base';
import LeafletList from 'components/CustomLeaflet/LeafletList';
import WorkflowLayer from 'components/WorkflowLayer/index';
import { configLocationEvents, configOrganizerEvents } from 'components/Table/Events';
import { FullLayout, GraySidebarLayout } from 'components/Layout';
import Meslis from 'components/Meslis';

import { useQueryParams, useSelectable, useTranslate, useUserContext } from 'customHooks';
import { collaboration } from 'formTypes';
import { MAX_EVENTS_SELECTABLE } from 'config';
import { alerts } from 'utils';
import { AppContext, FilterWidgetContext } from 'contexts';

const Select = ({ genericFilters, genericSorting, type, ...props }) => {
	const { genericOffset, redirectID } = useQueryParams({ view: 'table', genericOffset: 0 });
	const { location, organizer } = useContext(FilterWidgetContext);
	const { genericContext, setGenericContext } = useContext(AppContext);
	const { setFilters } = { location, organizer }[type];

	// Used to set filters when redirecting the user to new tab from event table view or event detail view
	useEffect(() => {
		if (redirectID)
			setFilters([
				{
					enabled: true,
					fieldName: 'id',
					value: {
						action: 'include',
						association: 'or',
						keyword: `${redirectID}`,
					},
				},
			]);
		// eslint-disable-next-line
	}, [redirectID]);

	const [MeslisGeneric, dataField] =
		type === 'location'
			? [Meslis.Locations, 'searchLocations']
			: // type === 'organizer'
			  [Meslis.Organizers, 'searchOrganizers'];

	return (
		<MeslisGeneric filters={genericFilters} sorting={genericSorting} offset={genericOffset} max={1}>
			{({ data, loading, error, refetch }) => {
				return (
					<Meslis.EventsCount
						filters={data && getNewCountFilters(data[dataField], type)}
						skip={loading}>
						{({
							data: dataNewCount,
							loading: loadingNewCount,
							error: errorNewCount,
							refetch: refetchNewCount,
						}) => (
							<SelectContent
								data={data && data[dataField]}
								{...{
									data: data && data[dataField],
									dataNewCount: dataNewCount?.searchEventsBySearchParams,
									loading,
									loadingNewCount,
									error: !!error || !!errorNewCount,
									refetchNewCount,
									refetch,
									genericContext,
									setGenericContext,

									type,
									...props,
								}}
							/>
						)}
					</Meslis.EventsCount>
				);
			}}
		</MeslisGeneric>
	);
};

const SelectContent = ({
	data,
	dataNewCount,
	loading,
	loadingNewCount,
	error,
	refetchNewCount,
	refetch,
	genericContext,
	setGenericContext,

	eventSorting,
	shownEventSorting,
	onCtrlEventUp,
	type,
	...props
}) => {
	const results = !!data?.results?.length ? data.results : [];
	const totalCount = data ? data.totalCount : null;

	useEffect(() => {
		setGenericContext(null);
		// eslint-disable-next-line
	}, []);

	const translate = useTranslate();

	const newEventCount =
		loadingNewCount && !dataNewCount
			? translate('genericPager.loading')
			: !!error
			? ''
			: translate('genericPager.newEvents', [dataNewCount?.totalCount]);

	const [expectedVersion, setExpectedVersion] = useState(-1);
	const [relevance, setRelevance] = useState('any');
	const [yearFilter, setYearFilter] = useState('any');
	const [stateValue, setStateValue] = useState({
		NEW: true,
		IN_PROGRESS: false,
		EXPORTED: false,
		DISCARDED: false,
		SCRAPPED: false,
	});
	const [collaborationFilter, setCollaborationFilter] = useState({ locking: 'any' });

	const [
		selection,
		setSelection,
		swapSelected,
		removeSelected,
		addBulkSelected,
		removeBulkSelected,
		isSelected,
	] = useSelectable({
		max: MAX_EVENTS_SELECTABLE,
		onMaxReached: () => alerts.selectedMaxAmountOfEvents(MAX_EVENTS_SELECTABLE),
	});
	const { pushURL, genericOffset } = useQueryParams();
	const { user } = useUserContext();

	const handleChangeIndex = genericOffset => {
		setSelection([]);
		setExpectedVersion(-1);
		pushURL({ genericOffset, offset: 0, currentId: 0 });
	};

	const handleDeselectAll = () => setSelection([]);

	const handleActionCompleted = item => (!!item ? removeSelected(item) : setSelection([]));

	const handleChangeRelevance = (_, { value }) => setRelevance(value);

	const handleChangeYear = (_, { value }) => setYearFilter(value);

	const handleChangeCollaboration = value => setCollaborationFilter(value);

	//  Handler function for refetching generic (organizer/location) after it has been updated
	const refetchMeslisGeneric = async type => {
		new Promise(async resolve => {
			const ret = await refetch();
			resolve(
				type === 'location'
					? setGenericContext(ret?.data?.searchLocations?.results?.[0])
					: type === 'organizer'
					? setGenericContext(ret?.data?.searchOrganizers?.results?.[0])
					: setGenericContext(null)
			);
		});
	};

	return error ? (
		<div>Error</div>
	) : !loading && !totalCount ? (
		<Msg>{translate('messages.noData')}</Msg>
	) : (
		<DimmableWrapper dimmed={(results[0]?.version || Number.POSITIVE_INFINITY) < expectedVersion}>
			<GraySidebarLayout
				sidebar={
					<>
						<StickySidebarElement>
							<Navi />
							<GenericPager
								loading={!data}
								data={results ? results[0] : null}
								max={totalCount}
								index={genericOffset}
								onChangeIndex={handleChangeIndex}
								{...{ type, newEventCount }}
							/>
							<GenericEventFilters
								{...{ relevance, stateValue, type }}
								year={yearFilter}
								collaboration={collaborationFilter}
								onChangeYear={handleChangeYear}
								onChangeRelevance={handleChangeRelevance}
								onChangeStateValue={setStateValue}
								onChangeCollaboration={handleChangeCollaboration}
							/>
							<SortExpandMenu />
						</StickySidebarElement>
						<LeafletList viewLabel={translate('navigation.process')} leafletId={-1} />
					</>
				}>
				<FullLayout>
					{loading ? (
						<LoadingContent />
					) : (
						<WorkflowLayer
							{...{
								selection,
								isSelected,
								setSelection,
								...props,
							}}
							genericID={results ? results[0].id : null}
							genericType={type}
							keepToolbar
							sorting={eventSorting}
							shownSorting={shownEventSorting}
							onCtrlUp={onCtrlEventUp}
							filters={composeEventsFilter({
								id: results ? results[0].id : null,
								type,
								stateValue,
								relevance,
								collaborationFilter,
								yearFilter,
								user,
							})}
							onSelectItem={swapSelected}
							onSelectAll={setSelection}
							onDeselectAll={handleDeselectAll}
							onAddBulk={addBulkSelected}
							onRemoveBulk={removeBulkSelected}
							onActionCompleted={() => {
								handleActionCompleted();
								refetchNewCount();
							}}
							config={type === 'location' ? configLocationEvents : configOrganizerEvents}
							storageMemoKey={`${type}-select`}
							lockingConfig={
								type === 'organizer'
									? {
											id: results ? results[0].id : null,
											lockedType: 'organizer',
											userLock: results ? results[0].userLock : null,
											version: results ? results[0].version : null,
											setExpectedVersion,
									  }
									: undefined
							}
							buttons={{
								createUpdateGeneric: {
									type,
									isUpdateMode: true,
									initialData: results && results[0],
									refetchMeslisGeneric,
									allowRefetch: true,
								},
							}}
						/>
					)}
				</FullLayout>
			</GraySidebarLayout>
		</DimmableWrapper>
	);
};

// Quick Event Filters for Location/ Organizer Select //////////////////////////////////////////////////////////////

const relevantFilter = {
	enabled: true,
	fieldName: 'rating',
	value: {
		operator: 'greaterThanEqual',
		rating: 3,
	},
};

const irrelevantFilter = {
	enabled: true,
	fieldName: 'rating',
	value: {
		operator: 'lessThanEqual',
		rating: 2,
	},
};

const getNewCountFilters = (data, type) =>
	!!data?.results?.length
		? [
				{
					fieldName: 'state',
					value: {
						NEW: true,
					},
					enabled: true,
				},
				{
					fieldName: `${type}.id`,
					value: {
						action: 'include',
						keyword: data.results[0].id,
					},
					enabled: true,
				},
		  ]
		: [];

const getYearFilter = year => ({
	enabled: true,
	fieldName: 'dates',
	value: {
		start_from: `${year}-01-01`,
		start_to: `${year}-12-31`,
	},
});

const composeEventsFilter = ({
	id,
	type,
	stateValue,
	relevance,
	collaborationFilter,
	yearFilter,
	user,
}) => {
	let filters = [
		{
			enabled: true,
			fieldName: `${type}.id`,
			value: {
				action: 'include',
				keyword: id,
			},
		},
		{
			fieldName: 'state',
			value: stateValue,
			enabled: true,
		},
	];
	if (relevance === 'relevant') filters.push(relevantFilter);
	if (relevance === 'irrelevant') filters.push(irrelevantFilter);
	if (yearFilter !== 'any') filters.push(getYearFilter(yearFilter));

	collaboration.validate(collaborationFilter) &&
		filters.push({
			fieldName: 'collaboration',
			enabled: true,
			value: collaborationFilter,
		});

	return filters;
};

export default Select;
