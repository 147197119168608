import React from 'react';
import { Rating as SemanticRating } from 'semantic-ui-react';
import { MAX_RATING } from 'config';

// "value=400  max=1000 units=5" would give us a "defaultRating=2"
// "value=400  max=1000 units=10" would give us a "defaultRating=4"

const Rating = ({ value, max = MAX_RATING, units = 5, size }) => (
	<SemanticRating
		icon="star"
		size={size}
		rating={value / (max / units)}
		maxRating={units}
		disabled
	/>
);

export default Rating;
