// localStorage object ////////////////////////////////////////////////////////////
const getObject = key => JSON.parse(localStorage.getItem(key));
const setObject = (key, value) => localStorage.setItem(key, JSON.stringify(value));
const removeObject = key => localStorage.removeItem(key);

/////////////////////////////////////////////////////////////////////////////////////

const getToken = () => localStorage.getItem('token');

const setUser = user => {
	if (!user) return;
	localStorage.setItem('username', user.username);
	localStorage.setItem('token', user.jwtToken.token);
	localStorage.setItem('expires', user.jwtToken.expires);
};

const removeUser = () => {
	localStorage.removeItem('username');
	localStorage.removeItem('token');
	localStorage.removeItem('expires');
};

const getUser = () => localStorage.getItem('username');

const setUserSettings = val => setObject('userSettings', val);
const resetUserSettings = () => setUserSettings(null);
const getUserSettings = () => getObject('userSettings');

const getTableWidths = tableKey => getObject(`${tableKey}-table-widths`);
const setTableWidths = (tableKey, columnWidths) =>
	setObject(`${tableKey}-table-widths`, columnWidths);
const resetTableWidths = tableKey => removeObject(`${tableKey}-table-widths`);
const getCollapsedLeaflets = () => getObject('collapsedLeaflets');
const setCollapsedLeaflets = val => setObject('collapsedLeaflets', val);

const getHiddenFields = () => getObject('hidden-fields') || {};
const setHiddenFields = val => setObject('hidden-fields', val);

const getFieldsOrder = () => getObject('fields-order') || {};
const setFieldsOrder = val => setObject('fields-order', val);
export default {
	getToken,
	setUser,
	getUser,
	removeUser,
	setUserSettings,
	resetUserSettings,
	getUserSettings,
	getTableWidths,
	setTableWidths,
	resetTableWidths,
	getCollapsedLeaflets,
	setCollapsedLeaflets,
	getHiddenFields,
	setHiddenFields,
	getFieldsOrder,
	setFieldsOrder,
};
