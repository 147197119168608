import React from 'react';

import GenericSelect from './GenericSelect';
import { USERS_OF_COMPANY } from 'graphql/queries';
import { get } from 'lodash';
import { useQuery } from '@apollo/client';

const UserSelect = ({ value, error, onChange }) => {
	const usersOfCompany = get(
		useQuery(USERS_OF_COMPANY, { fetchPolicy: 'cache-first' }),
		'data.getAllUsersForCompany'
	);

	const usersOptions =
		usersOfCompany?.map(u => ({
			key: u.id,
			value: u.username,
			text: [u.firstName, u.lastName].join(' '),
		})) ?? [];

	return (
		<GenericSelect
			options={usersOptions}
			allowedAssociations={['or', 'exists']}
			allowMultiple={true}
			{...{ value, error, onChange }}
		/>
	);
};

export default UserSelect;
