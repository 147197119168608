import React from 'react';
import { Rating } from 'semantic-ui-react';

import useFilterConfig from 'components/FilterWidget/useFilterConfig';

const RatingEntry = ({ data }) => {
	const { operators } = useFilterConfig();
	return (
		<span data-test="RatingEntry">
			<strong>{operators.find(op => op.value === data.operator).text}</strong>{' '}
			<Rating rating={data.rating} maxRating="5" icon="star" disabled />
		</span>
	);
};

export default RatingEntry;
