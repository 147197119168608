import React, { useContext } from 'react';

import SidebarLayout from './SidebarLayout';
import FullCenterLayout from './FullCenterLayout';
import { UserContext } from 'contexts/UserContext';

const AppLayout = ({ children }) => {
	const { user } = useContext(UserContext);
	const Component = user ? SidebarLayout : FullCenterLayout;
	return <Component>{children}</Component>;
};

export default AppLayout;
