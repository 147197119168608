import React from 'react';
import { useTranslate, useUserContext } from 'customHooks';
import { Popup } from 'semantic-ui-react';
import { InfoUser, LabeledIcon } from '.';
import classNames from 'classnames';

import './Collaboration.scss';

export default ({ entity, userLock, locking, viewingUsers = [], heading }) => {
	const translate = useTranslate();

	const {
		user: { username: currentUsername },
	} = useUserContext();

	const buffer = [];

	locking &&
		buffer.push({
			labeledIcon: locking !== 'any' && (
				<LabeledIcon
					name={locking === 'unlocked' ? 'lock open' : 'lock'}
					className={classNames('viewingStatus', locking)}
				/>
			),
		});

	userLock?.isLocked &&
		buffer.push({
			labeledIcon: (
				<LabeledIcon
					name="lock"
					className={classNames(
						'viewingStatus',
						userLock.user.username === currentUsername ? 'lockedByMe' : ' lockedByOtherUsers'
					)}
				/>
			),
			tooltip:
				userLock.user.username === currentUsername
					? translate(`tooltips.lockIcon.${entity}.lockedByMe`)
					: translate(`tooltips.lockIcon.${entity}.locked`, [
							<b>
								<InfoUser username={userLock.user.username} />
							</b>,
					  ]),
		});

	!!viewingUsers.length &&
		buffer.push({
			labeledIcon: <LabeledIcon name="eye" label={viewingUsers.length} />,
			tooltip: translate(`tooltips.lockIcon.${entity}.viewed`, [
				<b>{viewingUsers.length}</b>,
				<b>
					{viewingUsers.map((viewingUser, idx, arr) => (
						<>
							<InfoUser username={viewingUser.username} />
							{idx !== arr.length - 1 && <>{', '}</>}
						</>
					))}
				</b>,
			]),
		});

	!!heading &&
		buffer.push({
			labeledIcon: <LabeledIcon name="lock" />,
			tooltip: (
				<b>
					{translate(
						{
							event: 'tableView.infoCollaboration',
							organizer: 'genTableView.collaboration',
						}[entity]
					)}
				</b>
			),
		});

	!buffer.length &&
		buffer.push({
			labeledIcon: <LabeledIcon name="lock open" />,
			tooltip: translate(`tooltips.lockIcon.${entity}.unlocked`),
		});

	return buffer.map(({ labeledIcon, tooltip }, idx) =>
		!!tooltip ? (
			<Popup
				trigger={labeledIcon}
				content={tooltip}
				mouseEnterDelay={500}
				position="bottom center"
				key={idx}
			/>
		) : (
			<span key={idx}>{labeledIcon}</span>
		)
	);
};
