import React, { useState } from 'react';
import { Form, Icon, Select } from 'semantic-ui-react';
import './FilterForm.scss';

const FilterForm = ({ fields, initialData, confirmButtonText, onAddFilter, type }) => {
	const getField = name => fields.find(x => x.name === name);

	const [data, setData] = useState(
		initialData
			? {
					...initialData,
					error: false,
			  }
			: {
					fieldName: fields[0].name,
					value: fields[0].defaultValue,
					error: false,
			  }
	);

	const formType = getField(data.fieldName).formType;

	const isValueValid = () => formType.validate(data.value);
	const correctData = () => formType.correct && (data.value = formType.correct(data.value));

	const submitFilter = () => {
		onAddFilter({ fieldName: data.fieldName, value: data.value });
		setData({ ...data, ...{ value: formType.defaultValue, error: false } });
	};

	const setError = (error = true) => setData({ ...data, ...{ error } });

	const handleOnChangeField = (e, target) => {
		const newField = getField(target.value);
		const sameFormType = newField.formType.name === formType.name;

		setData({
			fieldName: target.value,
			value: sameFormType ? data.value : newField.defaultValue,
			error: false,
		});
	};

	const handleSubmit = () => {
		correctData();
		isValueValid() ? submitFilter() : setError();
	};

	const handleChangeValue = value => {
		data.error
			? setData({ ...data, ...{ value, error: formType.validate(value.keyword) } })
			: setData({ ...data, ...{ value } });
	};
	return (
		<div className="FilterForm">
			<Form onSubmit={handleSubmit}>
				<Form.Field data-test="FilterFormFieldSelect">
					<Select
						value={data.fieldName}
						search
						onChange={handleOnChangeField}
						options={fields.map(item => ({
							value: item.name,
							text: item.label,
							content: item.content,
							icon: item.listIcon,
						}))}
					/>
				</Form.Field>

				<formType.Component
					{...{
						error: data.error,
						onChange: handleChangeValue,
						value: data.value,
						type,
					}}
				/>

				<Form.Field>
					<Form.Button className="confirm" fluid primary>
						<Icon name="plus" />
						{confirmButtonText}
					</Form.Button>
				</Form.Field>
			</Form>
		</div>
	);
};

export default FilterForm;
