import React from 'react';

import useFilterConfig from 'components/FilterWidget/useFilterConfig';
import GenericSelect from './GenericSelect';

const BooleanSelect = ({ value, error, onChange }) => {
	const { boolean } = useFilterConfig();
	return <GenericSelect options={boolean} {...{ value, error, onChange }} />;
};

export default BooleanSelect;
