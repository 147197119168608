import {
	ApolloClient,
	InMemoryCache,
	HttpLink,
	defaultDataIdFromObject,
	split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import browserStorage from 'browserStorage';
import typePolicies from './typePolicies';

const handleError = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors) {
		for (let error of graphQLErrors) {
			switch (!!error.extensions && error.extensions.code) {
				case 'authorization':
					// this should fix multi/loop redirects when token expires
					const login = window.location.origin + '/login';
					return !window.location.href.includes('/login') && (window.location.href = login);
				default:
					console.error(error);
			}
		}
	}
});

const getAuthorization = () => {
	const token = browserStorage.getToken();
	return token ? `Bearer ${token}` : '';
};

const getURI = protocol => {
	const host = process.env.REACT_APP_GRAPHQL_HOST;
	const port = process.env.REACT_APP_GRAPHQL_PORT;
	const path = process.env.REACT_APP_GRAPHQL_PATH;
	return `${protocol}://${host}:${port}${path}`;
};

const getDataId = object => {
	if (object.__typename === 'Location' && !object.id) {
		return undefined;
	}
	if (object.__typename === 'CustomLeaflet') {
		return undefined;
	}
	if (object.__typename === 'StatusType') {
		return undefined;
	}
	if (object.__typename === 'PostalAddress') {
		return undefined;
	}

	return defaultDataIdFromObject(object);
};

const getClient = () => {
	const uri = getURI(process.env.REACT_APP_GRAPHQL_PROTOCOL);
	const authLink = setContext((_, { headers }) => ({
		headers: { ...headers, Authorization: getAuthorization() },
	}));

	const httpLink = new HttpLink({ uri });
	const wsLink = new WebSocketLink(
		new SubscriptionClient(getURI(process.env.REACT_APP_GRAPHQL_PROTOCOL_WS), {
			reconnect: true,
			lazy: true,
			connectionParams: () => ({ Authorization: getAuthorization() }),
		})
	);

	const link = split(
		({ query }) => {
			const { kind, operation } = getMainDefinition(query);
			return kind === 'OperationDefinition' && operation === 'subscription';
		},
		handleError.concat(wsLink),
		handleError.concat(authLink.concat(httpLink))
	);

	const cache = new InMemoryCache({ dataIdFromObject: getDataId, typePolicies });

	return new ApolloClient({ link, cache });
};

export { getAuthorization, getURI, getClient, getDataId };
