import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';

import { USERS_OF_COMPANY } from 'graphql/queries';
import EditLeafletForm from './Forms/EditLeafletForm';
import CreateLeafletForm from './Forms/CreateLeafletForm';
import browserStorage from 'browserStorage';

import './ManagementSidebar.scss';
import { useTranslate } from 'customHooks';

const ManagementSidebar = ({
	onActionCompleted,
	editableLeaflet,
	leafletLimitReached = false,
	leaflets = [],
}) => {
	const [leafletData, setLeafletData] = useState(editableLeaflet && editableLeaflet.leaflet);
	const [meslisEvents, setMeslisEvents] = useState(
		editableLeaflet ? editableLeaflet.totalCount : 0
	);
	const [description, setDescription] = useState(!!leafletData ? leafletData.description : '');
	const [tag, setTag] = useState(!!leafletData ? leafletData.eventTag : '');
	const [name, setName] = useState(!!leafletData ? leafletData.name : '');
	const [errorMessage, setErrorMessage] = useState();

	const translate = useTranslate();

	const { data: companyUsers } = useQuery(USERS_OF_COMPANY);
	const currentUser = browserStorage.getUser();

	const users = !!companyUsers?.getAllUsersForCompany
		? companyUsers.getAllUsersForCompany.filter(user => user.username !== currentUser)
		: [];

	useEffect(() => {
		editableLeaflet ? setLeafletData(editableLeaflet.leaflet) : setLeafletData(editableLeaflet);
		editableLeaflet && setMeslisEvents(editableLeaflet.totalCount);
	}, [editableLeaflet]);

	const usedLeafletNames = leaflets
		.filter(leaflet => leaflet.creator && leaflet.creator.username === currentUser)
		.map(leaflet => leaflet.name)
		.concat(['Basket', 'Shelf']);
	const findErrorLeafletName = name => {
		if (
			name !== (leafletData && leafletData.name) &&
			usedLeafletNames.some(usedName => usedName.trim() === name.trim())
		)
			return translate('leaflets.leafNameExists');
		else if (!!name && name !== '' && name.trim() === '')
			return translate('leaflets.leafNameSpaces');
	};

	useEffect(() => {
		setErrorMessage(findErrorLeafletName(name));
		// eslint-disable-next-line
	}, [name]); // no dependency needed for findErrorLeafletName

	const handleChangeTag = (e, target) => {
		e.type === 'blur' ? setTag('') : setTag(target.value);
	};

	const handleSubmit = () => {
		setName('');
		setTag('');
		setDescription('');
	};

	const handleActionCompleted = () => {
		setName('');
		setTag('');
		setDescription('');
		onActionCompleted();
	};

	useEffect(() => {
		if (!!leafletData) {
			setTag(leafletData.eventTag || '');
			setName(leafletData.name || '');
			setDescription(leafletData.description || '');
		}
	}, [leafletData]);

	return !leafletData || !leafletData.name ? (
		<div className="ManagementSidebar">
			<CreateLeafletForm
				{...{
					description,
					name,
					tag,
					setDescription,
					setName,
					leafletLimitReached,
					users,
					errorMessage,
				}}
				onSubmit={handleSubmit}
				onChangeTag={handleChangeTag}
			/>
		</div>
	) : (
		<div className="ManagementSidebar">
			<EditLeafletForm
				{...{
					name,
					setName,
					tag,
					errorMessage,
					setDescription,
					description,
					leafletData,
					meslisEvents,
					users,
				}}
				onActionCompleted={handleActionCompleted}
				onChangeTag={handleChangeTag}
			/>
		</div>
	);
};

export default ManagementSidebar;
