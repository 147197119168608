import React from 'react';

import Navi from './Navi';
import { StickySidebarElement } from 'components/Base';
import { FullLayout, GraySidebarLayout } from 'components/Layout';
import WorkflowLayer from 'components/WorkflowLayer/index';
import SortExpandMenu from 'components/CustomLeaflet/SortExpandMenu';
import LeafletList from 'components/CustomLeaflet/LeafletList';
import { useQueryParams, useSelectable } from 'customHooks';
import { configEventsProcess } from 'components/Table/Events';

import { MAX_EVENTS_SELECTABLE } from 'config';
import { alerts } from 'utils';

const Process = ({ sorting, ...props }) => {
	const { leafletId } = useQueryParams({ leafletId: 1, view: 'table' });
	const storageMemoKey = 'process' + (leafletId != null ? '-' + leafletId : '');

	const [
		selection,
		setSelection,
		swapSelected,
		removeSelected,
		addBulkSelected,
		removeBulkSelected,
		isSelected,
	] = useSelectable({
		max: MAX_EVENTS_SELECTABLE,
		onMaxReached: () => alerts.selectedMaxAmountOfEvents(MAX_EVENTS_SELECTABLE),
	});

	const handleDeselectAll = () => setSelection([]);

	const handleActionCompleted = item => {
		!!item ? removeSelected(item) : setSelection([]);
	};

	return (
		<GraySidebarLayout
			sidebar={
				<>
					<StickySidebarElement>
						<Navi />
						<SortExpandMenu />
					</StickySidebarElement>
					<LeafletList
						showClear
						showExport
						offset={0}
						onTableActionCompleted={handleActionCompleted}
						{...{ sorting, leafletId, selection }}
					/>
				</>
			}>
			<FullLayout>
				<WorkflowLayer
					{...{
						sorting,
						selection,
						isSelected,
						setSelection,
						storageMemoKey,
						...props,
					}}
					sourceLeaflets={[leafletId]}
					onSelectItem={swapSelected}
					onSelectAll={setSelection}
					onDeselectAll={handleDeselectAll}
					onAddBulk={addBulkSelected}
					onRemoveBulk={removeBulkSelected}
					onActionCompleted={handleActionCompleted}
					config={configEventsProcess}
					keepToolbar={true}
				/>
			</FullLayout>
		</GraySidebarLayout>
	);
};
export default Process;
