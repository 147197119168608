import { isEqual } from 'lodash';

// comapring by single prop - id is far faster than deep comparision
// requirement is that id is the primary key (if id is same rest is always same too)

const compareObjectsWithProperty = (object, otherObject, property = 'id') => {
	let returnValue;
	try {
		returnValue = object[property] === otherObject[property];
	} catch {
		returnValue = isEqual(object, otherObject);
	}
	return returnValue;
};

export default compareObjectsWithProperty;
