import React from 'react';
import ChangePasswordForm from './ChangePasswordForm';

const PasswordChange = () => {
	return (
		<div className="PasswordChange">
			<ChangePasswordForm />
		</div>
	);
};

export default PasswordChange;
