import _ from 'lodash';

const customerGenerics = { location: 'customerLocations', organizer: 'customerOrganizers' };

const formatGenericStatisticPerYear = (val = {}) => {
	const { year, count, differenceToMeslisEventCount } = val;
	const meslisCount = count + differenceToMeslisEventCount;

	return `${year}: ${count}/${meslisCount} (${
		differenceToMeslisEventCount >= 0 ? '+' : ''
	}${differenceToMeslisEventCount})`;
};

const formatGenericStatisticPerYears = (dataRow, type) => {
	const field = `${customerGenerics[type]}[0].statisticPerYear`;
	const statisticFieldExists = _.has(dataRow, field);

	if (!statisticFieldExists) {
		return [];
	}

	const fieldvalue = _.get(dataRow, field);

	if (!fieldvalue) {
		return [];
	}

	return fieldvalue.map(formatGenericStatisticPerYear);
};

export { formatGenericStatisticPerYear, formatGenericStatisticPerYears };
