// https://www.freecodecamp.org/news/check-if-a-javascript-string-is-a-url#how-to-check-if-a-string-is-a-valid-url-using-an-input-element

export const validateURL = string => {
	let baseUrlString = string.trim();

	// if base url string contains space characters or single quotes, it is invalid
	const urlBaseChecker = /.*\..*/;
	const invalidCharacters = /[\s\n\t\r']/g;

	if (!urlBaseChecker.test(string) || invalidCharacters.test(string)) {
		return false;
	}

	if (!string.startsWith('http') || !string.startsWith('https')) {
		baseUrlString = 'http://' + string;
	}

	try {
		const url = new URL(baseUrlString);
		return !!url;
	} catch (_) {
		console.log(_.message, 'validateURL Error');
		return false;
	}
};

export default validateURL;
