import React from 'react';
import { Route } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import ExportModal from './ExportModal';
import Meslis from 'components/Meslis';

import './Footer.scss';

import { useTranslate } from 'customHooks';

const Footer = ({ leafletData, toEditLeaflet, showExport, showEdit, data, selection }) => {
	const translate = useTranslate();
	return (
		<div className="Footer">
			<Meslis.Me>
				{({ data: { me } = {} }) => {
					const hasRights =
						leafletData.id === 1 ||
						(!!leafletData.creator && !!me && me.username === leafletData.creator.username);
					return (
						hasRights && (
							<>
								{showEdit && leafletData.creator && (
									<Button
										data-test="EditLeafletButton"
										onClick={() => {
											toEditLeaflet(leafletData, data);
										}}>
										{translate('leaflets.edit')}
									</Button>
								)}
								{showExport && leafletData.id !== 2 && (
									<Route
										render={({ history }) => (
											<ExportModal
												disabled={data === 0}
												leafletData={leafletData}
												selection={selection}
												onDone={() => history.push('/events/success')}
												exportSelected={selection.length}
											/>
										)}
									/>
								)}
							</>
						)
					);
				}}
			</Meslis.Me>
		</div>
	);
};

export default Footer;
