export default ids => {
	let h = 0;
	const delta = 360 / ids.length;
	const idColors = {};
	ids.forEach(id => {
		idColors[id] = `hsl(${h}, 100%, 35%)`;
		h += delta;
	});
	return idColors;
};
