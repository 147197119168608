import classNames from 'classnames';
import { useTranslate } from 'customHooks';
import React from 'react';
import { Button, Icon, Popup } from 'semantic-ui-react';
import './Submit.scss';

export default ({ className, onClick, submitIssues, isOnly: _, ...props }) => {
	const translate = useTranslate();
	const invalidFields = submitIssues?.invalidFields?.map(
		field => translate(`tooltips.submitButton.fieldNames.${field}`) || field
	);
	const missingFields = submitIssues?.missingFields?.map(
		field => translate(`tooltips.submitButton.fieldNames.${field}`) || field
	);

	return (
		<Popup
			trigger={
				<Button
					className={classNames('Submit', className, !!submitIssues && 'disabled')}
					data-test="Submit"
					icon
					onClick={(...args) => !submitIssues && onClick(...args)}
					{...props}>
					<Icon name="save" />
					<span>{translate('tooltips.submitButton.submit')}</span>
				</Button>
			}
			content={
				<div>
					{!submitIssues ? (
						<b>{translate('tooltips.submitButton.submit')}</b>
					) : (
						<>
							<b>{translate('tooltips.submitButton.submitPermitted')}</b>
							{submitIssues.isUnchanged && (
								<span data-test="unchanged">
									<br />
									{translate('tooltips.submitButton.unchanged')}
								</span>
							)}
							{!!missingFields?.length && (
								<>
									<br />
									{translate('tooltips.submitButton.missingFields')}
									<i data-test="missingFields">{missingFields.join(', ')}</i>
								</>
							)}
							{!!invalidFields?.length && (
								<>
									<br />
									{translate('tooltips.submitButton.invalidFields')}
									<i data-test="invalidFields">{invalidFields.join(', ')}</i>
								</>
							)}
						</>
					)}
				</div>
			}
			data-test="submitButtonTooltip"
			on="hover"
			mouseEnterDelay={500}
			position="bottom right"
			closeOnTriggerClick
		/>
	);
};
