import { MAX_SHOWN_ADS } from 'config';
import { useTranslate } from 'customHooks';
import React from 'react';
import { Button, Icon, Popup } from 'semantic-ui-react';
import './ColoredIdSwitcher.scss';

export default ({
	adsWrapper,
	adOffset,
	forwardAd,
	backwardAd,
	shownAdWrapper: { id: shownAdId },
	onClickIdx,
	idColors,
	...props
}) => {
	const translate = useTranslate();
	const ads = adsWrapper.map(({ id, idx, shownIdx, ad }) => (
		<Popup
			trigger={
				<svg key={id} onClick={() => onClickIdx(idx)}>
					{id === shownAdId ? (
						<rect fill={idColors[id]}></rect>
					) : (
						<circle fill={idColors[id]}></circle>
					)}
				</svg>
			}
			mouseEnterDelay={500}
			position="bottom center"
			key={id}>
			{translate('detailView.ad')} {shownIdx}
		</Popup>
	));
	return (
		<div className="ColoredIdSwitcher" {...props}>
			{ads.length > 2 && (
				<Button className="backward-button" icon onClick={backwardAd} disabled={!backwardAd}>
					<Icon name="angle left" size="small" />
				</Button>
			)}
			<span className={ads.length > MAX_SHOWN_ADS ? 'streched' : ''}>
				{adOffset !== 0 && (
					<>
						<span className="idx">{ads[0]}</span>
						<span className="idx">...</span>
					</>
				)}
				{ads.slice(adOffset, adOffset + MAX_SHOWN_ADS).map((ad, index) => {
					return (
						<span key={index} className="idx">
							{ad}
						</span>
					);
				})}
				{ads.length > adOffset + MAX_SHOWN_ADS && (
					<>
						<span className="idx"> ... </span>
						<span className="idx">{ads[ads.length - 1]}</span>
					</>
				)}
			</span>
			{ads.length > 2 && (
				<Button className="forward-button" icon onClick={forwardAd} disabled={!forwardAd}>
					<Icon name="angle right" size="small" />
				</Button>
			)}
		</div>
	);
};