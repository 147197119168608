import React, { useState } from 'react';
import { Form, Icon } from 'semantic-ui-react';

import classNames from 'classnames';

import { useTranslate } from 'customHooks';
import DateInput from 'components/Base/CustomDateInput'; // closing input when overriding
import './DateRangesInput.scss';

const DateRangesInput = ({ value, error, onChange }) => {
	const { start_from, start_to, end_from, end_to } = value || {};

	const handleChange = attr => _value => {
		switch (attr) {
			case 'start_from':
				if (_value) {
					onChange({
						...value,
						...{ start_from: _value, start_to: _value },
					});
					setTimeout(() => onChange({ ...value, ...{ start_from: _value, start_to: '' } }), 0);
				}
				break;
			case 'end_from':
				if (_value) {
					onChange({
						...value,
						...{ end_from: _value, end_to: _value },
					});
					setTimeout(() => onChange({ ...value, ...{ end_from: _value, end_to: '' } }), 0);
				}
				break;
			case 'start_to':
			case 'end_to':
				onChange({ ...value, ...{ [attr]: _value } });
				break;
			default:
				break;
		}
	};

	const [isExpanded, setIsExpanded] = useState(end_from || end_to);
	const translate = useTranslate();

	const _props = {
		error,
		closable: true,
		clearable: true,
		clearIcon: <Icon name="remove" color="red" />,
		iconPosition: 'left',
	};

	return (
		<div className="DateRangesInput">
			<Form.Button
				type="button"
				className={classNames('expandButton', { expanded: isExpanded })}
				onClick={() => setIsExpanded(!isExpanded)}
				tabIndex="0"
				disabled={isExpanded && (!!end_from || !!end_to)}
				icon>
				<Icon className={classNames('caret', isExpanded ? 'down' : 'right')} />
			</Form.Button>
			<div className="inputsGroup">
				<span>{translate('filters.dateRange.startDate')}</span>
				<Form.Group widths="equal">
					<Form.Field width={1} fluid>
						<DateInput
							onChange={handleChange('start_from')}
							value={start_from}
							maxDate={start_to || end_from || end_to}
							placeholder={translate('filters.info.from')}
							{..._props}
						/>
					</Form.Field>
					<Form.Field width={1} fluid>
						<DateInput
							onChange={handleChange('start_to')}
							value={start_to}
							minDate={start_from}
							maxDate={end_to}
							placeholder={translate('filters.info.to')}
							{..._props}
						/>
					</Form.Field>
				</Form.Group>
				<div style={{ display: isExpanded ? undefined : 'none' }}>
					<span>{translate('filters.dateRange.endDate')}</span>
					<Form.Group widths="equal">
						<Form.Field width={1} fluid>
							<DateInput
								onChange={handleChange('end_from')}
								value={end_from}
								minDate={start_from}
								maxDate={end_to}
								placeholder={translate('filters.info.from')}
								{..._props}
							/>
						</Form.Field>
						<Form.Field width={1} fluid>
							<DateInput
								onChange={handleChange('end_to')}
								value={end_to}
								minDate={end_from || start_to || start_from}
								placeholder={translate('filters.info.to')}
								{..._props}
							/>
						</Form.Field>
					</Form.Group>
				</div>
			</div>
		</div>
	);
};

export default DateRangesInput;
