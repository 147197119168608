import React from 'react';
import { Icon, Checkbox } from 'semantic-ui-react';

import './FilterEntry.scss';

const FilterEntry = ({ fields, data, editableDeletable, type, onChange, onEdit, onDelete }) => {
	const field = fields.find(x => x.name === data.fieldName);

	const formType = field.formType;

	const handleToggleEnabled = () => onChange({ ...data, ...{ enabled: !data.enabled } });

	const handleChangeValue = value => onChange({ ...data, ...{ value } });

	return (
		<div className="FilterEntry">
			<div className="line">
				<Checkbox className="enabler" checked={data.enabled} onChange={handleToggleEnabled} />
				<span className="field">{field.label}</span>{' '}
				<formType.EntryComponent
					{...{ type, field }}
					data={data.value}
					onChangeValue={handleChangeValue}
				/>
			</div>
			{editableDeletable && (
				<>
					<Icon data-test="FilterDeleteIcon" className="delete red" name="close" onClick={onDelete} />
					<Icon data-test="FilterEditIcon" className="edit green" name="edit" onClick={onEdit} />
				</>
			)}
		</div>
	);
};

export default FilterEntry;
