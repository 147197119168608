// https://www.geeksforgeeks.org/file-uploading-in-react-js/
// https://surajsharma.net/blog/react-upload-file-using-axios

import { useState } from 'react';
import { urlForImageUpload } from 'utils';
import axios from 'axios';
import FormData from 'form-data';
import { getAuthorization } from 'apollo/client';

const useUploadImages = () => {
	const [loading, setLoading] = useState();

	const _uploadImage = ({ file } = {}) =>
		new Promise(async resolve => {
			if (!file) {
				// if no local image do not create
				resolve(null);
				return;
			}
			const form = new FormData();
			form.append('image', file, file.name);
			const response = await axios.post(urlForImageUpload(), form, {
				headers: {
					Authorization: getAuthorization(),
				},
			});
			resolve(response?.data);
		});

	const uploadImage = image =>
		new Promise(async resolve => {
			setLoading(true);
			const ret = await _uploadImage(image);
			setLoading(false);
			resolve(ret);
		});

	const uploadImages = images => {
		if (!images?.length) return new Promise(resolve => resolve([]));

		return new Promise(async resolve => {
			setLoading(true);
			const ret = await Promise.all(images.map(_uploadImage));
			setLoading(false);
			resolve(ret);
		});
	};

	return { uploadImage, uploadImages, loading };
};

export default useUploadImages;
