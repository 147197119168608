import React, { useState } from 'react';
import { ItemArrayList } from 'components/Base';
import _ from 'lodash';
const useItemArray = data => {
	const [extended, setExtended] = useState([]);
	const toggleExtended = index => {
		if (extended.includes(index)) {
			setExtended(extended.filter(val => val !== index));
			return;
		}
		setExtended([index].concat(extended).sort());
	};

	const getShowAll = index => extended.includes(index);

	const closeAll = () => setExtended([]);
	const toggleExpandAll = () => {
		if (extended.length === 0) {
			let allIndexes = [];
			for (let i = 0; i < data.length; i++) {
				allIndexes.push(i);
			}
			setExtended(allIndexes);
		} else {
			closeAll();
		}
	};

	const renderItemArray = (arrPath, fieldPath, { render, selectedIdPath, idPath } = {}) => (
		data,
		index
	) => {
		const selectedId = selectedIdPath && _.get(data, `${selectedIdPath}`);
		let selectedIdx;
		data = _.has(data, `${arrPath}.length`)
			? _.get(data, `${arrPath}`).map((item, idx) => {
					let val = fieldPath !== '.' ? _.get(item, `${fieldPath}`) : item;
					const id = idPath && _.get(item, `${idPath}`);
					if (id && id === selectedId) selectedIdx = idx;
					if (render) val = render(val);
					return val || '';
			  })
			: null;

		return !!data ? (
			<ItemArrayList
				itemLimit={1}
				onClickHook={() => toggleExtended(index)}
				defaultShowAll={getShowAll(index)}
				isHighlightingSiblings
				{...{ data, index, extended, selectedIdx }}
			/>
		) : (
			<></>
		);
	};

	return {
		renderItemArray,
		extended,
		toggleExpandAll,
		closeAll,
		setExtended,
	};
};

export default useItemArray;
