import { useTranslate } from 'customHooks';
import React, { useEffect, useState } from 'react';
import { Icon, Loader } from 'semantic-ui-react';
import classNames from 'classnames';
import './AuthFrame.scss';

export default ({ token, src, type, ...props }) => {
	const [blobObject, setBlobObject] = useState(undefined);

	useEffect(() => {
		if (!src) {
			setBlobObject(null);
			return;
		}
		const options = {
			method: 'GET',
			headers: new Headers({ authorization: `Bearer ${token}`, type }),
		};
		fetch(src, options)
			.then(response => response.blob())
			.then(response => {
				var blob = new Blob([response], { type });
				setBlobObject(response.size !== 0 ? URL.createObjectURL(blob) : null);
			})
			.catch(e => {
				console.error('Error', e);
				setBlobObject(null);
			});
	}, [token, src, type]);

	return blobObject ? (
		<iframe title={'authIFrame'} src={blobObject} {...{ type, ...props }} />
	) : (
		<PDFPlaceholder {...{ blobObject, ...props }} />
	);
};

const PDFPlaceholder = ({ className, blobObject, ...props }) => {
	const translate = useTranslate();
	return (
		<div className={classNames(className, 'pdfPlaceholder')} {...props}>
			{
				// null means "broken link" -> placeholder text
				blobObject === null ? (
					<div>
						<Icon size="massive" name="search" />
						<h1>{translate('placeholders.pdfPlaceholder')}</h1>
					</div>
				) : (
					// blobObject === undefined
					// undefined means "not yet loaded" -> no placeholder text
					<Loader size="large" active inline="centered">
						{translate('placeholders.loadingDots')}
					</Loader>
				)
			}
		</div>
	);
};
