import React from 'react';
import { SortIcons } from 'components/Base';
import { useAppContext, useTranslate } from 'customHooks';
import { order as orderEnum } from 'enums';
import { ICONS } from 'config';

import { Icon, Popup } from 'semantic-ui-react';

import './SortExpandMenu.scss';

export default () => {
	const {
		displayLeafletsConfig: { sortConfig, collapsedLeaflets, toggleSort, handleOpenCollapseAll },
	} = useAppContext();
	const { sortField, isDescending } = sortConfig || {};

	const translate = useTranslate();

	return (
		<div className="SortExpandMenu" data-test="SortExpandMenu">
			<SortIcons
				sortField={[
					{
						name: 'default',
						icon: 'bullseye',
						tooltip: translate('tooltips.sortLeaflets.default'),
						onlyIcon: true,
					},
					{
						name: 'name',
						icon: 'sort alphabet down',
						tooltip: translate('tooltips.sortLeaflets.name'),
					},
					{
						name: 'creator',
						icon: 'user',
						tooltip: translate('tooltips.sortLeaflets.creator'),
					},
					{
						name: 'createdAt',
						icon: 'file',
						tooltip: translate('tooltips.sortLeaflets.createdAt'),
					},
					{
						name: 'updatedAt',
						icon: 'edit',
						tooltip: translate('tooltips.sortLeaflets.updatedAt'),
					},
				]}
				sorting={{
					orderBy: isDescending == null ? null : isDescending ? orderEnum.DESC : orderEnum.ASC,
					field: sortField,
				}}
				onSort={toggleSort}
			/>

			<Popup
				content={
					collapsedLeaflets.length !== 0
						? translate('actions.expandAll')
						: translate('actions.collapseAll')
				}
				trigger={
					<Icon
						className="expandIcon"
						data-test="expandIcon"
						name={collapsedLeaflets.length !== 0 ? ICONS.EXPAND : ICONS.COLLAPSE}
						onClick={handleOpenCollapseAll}
					/>
				}
				mouseEnterDelay={300}
				position="top right"
			/>
		</div>
	);
};
