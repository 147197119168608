export const value2Blocks = value => {
	let currValue = value;
	const blocks = [];
	while (currValue.length > 0) {
		currValue = currValue.trim();
		let [begin, end] =
			currValue[0] === '"'
				? [1, currValue.indexOf('"', 1)]
				: [
						0,
						currValue.includes('"')
							? Math.min(currValue.indexOf(' '), currValue.indexOf('"'))
							: currValue.indexOf(' '),
				  ];
		let begin2;
		if (end !== -1) begin2 = currValue[0] === '"' ? end + 1 : end;
		else end = begin2 = currValue.length;
		blocks.push(currValue.slice(begin, end));
		currValue = currValue.slice(begin2, currValue.length);
	}

	return blocks;
};

export const blocks2Value = blocks =>
	blocks
		.map(block => {
			block = block || '';
			block = block.replace(/"/g, '').trim();
			return block.includes(' ') ? `"${block}"` : block;
		})
		.join(' ')
		.trim();
