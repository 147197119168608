import React, { useState } from 'react';
import { Icon, List, Popup } from 'semantic-ui-react';
import { ICONS } from 'config';
import { useTranslate } from 'customHooks';
import classNames from 'classnames';

import { noop } from 'utils';
import './ItemArrayList.scss';
import MeasuredItem from './MeasuredItem';

const ItemArrayList = ({
	data,
	itemLimit = 3,
	onClickHook = noop,
	isHighlightingSiblings = false,
	defaultShowAll = false,
	selectedIdx,
	index,
}) => {
	const [showAll, setShowAll] = useState(defaultShowAll);
	const translate = useTranslate();

	if (!data || data.length === 0 || data.join('') === '') return <></>;

	const filteredData = data.filter(item => item !== null && item !== undefined && item !== '');

	const toggleShowAll = e => {
		e.stopPropagation();
		setShowAll(!showAll);
		onClickHook();
	};

	const handleMouseEnter = innerIndex => {
		const nodelist = document.querySelectorAll(
			`.ItemArrayList[data-outer-index='${index}'] [data-inner-index='${innerIndex}']`
		);
		for (let item of nodelist) {
			if (!item.className.includes('highlight'))
				item.className = (item.className + ' highlight').trim();
		}
	};
	const handleMouseLeave = innerIndex => {
		const nodelist = document.querySelectorAll(
			`.ItemArrayList[data-outer-index='${index}'] [data-inner-index='${innerIndex}']`
		);
		for (let item of nodelist) {
			item.className = item.className.replace('highlight', '').trim();
		}
	};

	return (
		<div className="ItemArrayList" data-outer-index={index}>
			{filteredData.length <= itemLimit ? (
				<List>
					{filteredData.map((item, innerIndex) => (
						<List.Item key={innerIndex}>{<MeasuredItem label={item} />}</List.Item>
					))}
				</List>
			) : (
				<>
					<List>
						{showAll
							? filteredData.map((item, innerIndex) => (
									<List.Item
										className={classNames({
											alignWithSiblings: isHighlightingSiblings,
											notSelected: selectedIdx != null && innerIndex !== selectedIdx,
										})}
										data-inner-index={innerIndex}
										onMouseEnter={
											isHighlightingSiblings ? () => handleMouseEnter(innerIndex) : noop
										}
										onMouseLeave={
											isHighlightingSiblings ? () => handleMouseLeave(innerIndex) : noop
										}
										key={innerIndex}>
										{<MeasuredItem label={item} />}
									</List.Item>
							  ))
							: filteredData
									.slice(0, itemLimit)
									.map((item, innerIndex) => (
										<List.Item key={innerIndex}>{<MeasuredItem label={item} />}</List.Item>
									))}
					</List>
					<Popup
						trigger={
							<Icon
								className="expand-icon"
								name={showAll ? ICONS.COLLAPSE : ICONS.EXPAND}
								onClick={toggleShowAll}
								data-test={showAll ? 'less' : 'more'}
							/>
						}
						content={translate(showAll ? 'actions.collapse' : 'actions.expand')}
						position="right center"
						mouseEnterDelay={400}
					/>
				</>
			)}
		</div>
	);
};
export default ItemArrayList;
