import React from 'react';
import { Mutation } from '@apollo/client/react/components';

import { EMPTY_CUSTOM_LEAFLET } from 'graphql/mutations';
import { EVENTS, EVENTS_COUNT, LEAFLET } from 'graphql/queries';
import { buildEventsQueryFromFilters } from 'utils';

const EmptyCustomLeaflet = ({
	children,
	leafletId,
	offset,
	sorting: sortings,
	filters = [],
	max,
	...props
}) => {
	return (
		<Mutation mutation={EMPTY_CUSTOM_LEAFLET} {...props}>
			{(f, data) => {
				const refetchQueries = [
					{
						query: LEAFLET,
						variables: {
							searchQuery: { leafletIds: leafletId },
						},
					},
					{
						query: EVENTS_COUNT,
						variables: {
							max: 0,
							searchQuery: buildEventsQueryFromFilters(filters, { leafletIds: [leafletId] }),
						},
					},
					{
						query: EVENTS,
						variables: {
							searchQuery: buildEventsQueryFromFilters(filters, { leafletIds: [leafletId] }),
							max,
							offset: offset,
							sortings,
						},
					},
				];
				return children(
					() =>
						f({
							variables: { leafletId },
							refetchQueries,
							awaitRefetchQueries: true,
						}),
					data
				);
			}}
		</Mutation>
	);
};

export default EmptyCustomLeaflet;
