import React from 'react';
import { Query } from '@apollo/client/react/components';

import { EVENTS } from 'graphql/queries';
import { buildEventsQueryFromFilters, checkFiltersEmptyResult as checkEmpty } from 'utils';
import { useUpdateEventsAfterLoading } from 'customHooks';

const Events = ({
	max = 100,
	offset = 0,
	filters = [],
	sorting: sortings = [],
	leafletIds = [],
	children,
	...props
}) => {
	const variables = {
		max,
		sortings,
		offset,
		searchQuery: buildEventsQueryFromFilters(filters, { leafletIds }),
		includeEventAds: false,
	};
	const updatingChildren = useUpdateEventsAfterLoading(children);
	return (
		checkEmpty({ filters, children }) || (
			<Query
				query={EVENTS}
				fetchPolicy="network-only"
				notifyOnNetworkStatusChange={true}
				variables={variables}
				{...props}>
				{updatingChildren}
			</Query>
		)
	);
};

export default Events;