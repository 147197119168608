import { useState } from 'react';

const useInputField = (type, value, validation = () => true) => {
	const [inputField, setInputField] = useState({ value, error: false, type, validation });

	const updateType = (type, validation) =>
		setInputField({ type, validation, value: null, error: false });

	const validate = () => {
		const error = inputField.validation(inputField.value);
		if (error !== inputField.error) setInputField({ ...inputField, ...{ error } });
	};

	return { inputField, validate, updateType };
};

export default useInputField;
