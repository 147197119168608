import React, { useState, useRef, createContext } from 'react';
import { subscribeToQueryChange, subscribeToLeafletChange } from 'graphql/subscriptions';
import { useSubscription } from '@apollo/client';

const MessagesContext = createContext();

const MessagesProvider = ({ children }) => {
	const visitedRef = useRef();

	// newly shared with user
	const [newQueryIds, setNewQueryIds] = useState([]);
	const [newLeafletIds, setNewLeafletIds] = useState([]);

	const onUpdateQueriesRef = useRef();
	const setOnUpdateQueries = val => (onUpdateQueriesRef.current = val);

	const onUpdateLeafletsRef = useRef();
	const setOnUpdateLeaflets = val => (onUpdateLeafletsRef.current = val);

	useSubscription(subscribeToQueryChange, {
		onSubscriptionData: ({
			subscriptionData: {
				data: { savedQueryChanged },
			},
		}) => {
			const { savedQueryId, isShared } = savedQueryChanged || {};

			if (isShared === true && !newQueryIds.includes(savedQueryId)) {
				setNewQueryIds([...newQueryIds, savedQueryId]);
				!!onUpdateQueriesRef.current && onUpdateQueriesRef.current();
			}
			if (isShared === false && newQueryIds.includes(savedQueryId)) {
				setNewQueryIds(newQueryIds.filter(id => id !== savedQueryId));
				!!onUpdateQueriesRef.current && onUpdateQueriesRef.current();
			}
		},
		variables: { componentId: 1 },
	});

	useSubscription(subscribeToLeafletChange, {
		onSubscriptionData: ({
			subscriptionData: {
				data: { leafletChanged },
			},
		}) => {
			const { leafletId, isShared } = leafletChanged || {};

			handleLeafletSharedOrUnshared(isShared, leafletId);
		},
		variables: { componentId: 3 },
	});

	const handleLeafletSharedOrUnshared = (isShared, leafletId) => {
		// is a notification for this elaflet id currently byeing shown
		const notificationForLeafletBeingShown = newLeafletIds.includes(leafletId);

		// newly shared with user
		if (isShared && !notificationForLeafletBeingShown) {
			setNewLeafletIds([...newLeafletIds, leafletId]);
			!!onUpdateLeafletsRef.current && onUpdateLeafletsRef.current();
		}

		// was unshared with user
		if (!isShared && notificationForLeafletBeingShown) {
			setNewLeafletIds(newLeafletIds.filter(id => id !== leafletId));
			!!onUpdateLeafletsRef.current && onUpdateLeafletsRef.current();
		}
	};

	const handleNavigationClick = url => {
		if (visitedRef.current === '/my-searches' && url !== '/my-searches') {
			setNewQueryIds([]);
		}
		if (visitedRef.current === '/custom-leaflet' && url !== '/custom-leaflet') {
			setNewLeafletIds([]);
		}
		visitedRef.current = url;
	};

	const store = {
		newQueryIds,
		newLeafletIds,
		handleNavigationClick,
		setOnUpdateQueries,
		setOnUpdateLeaflets,
	};

	return <MessagesContext.Provider value={store}>{children}</MessagesContext.Provider>;
};

export { MessagesContext, MessagesProvider };
