import template from 'utils/stringTemplates';

export default {
	username: 'Nazwa użytkownika',
	password: 'Hasło',

	login: 'Logowanie',
	logout: 'Wylogowanie',

	statusPage: 'Status aplikacji',

	documentation: 'Dokumentacja',
	anyField: 'Dowolne pole',

	detailView: {
		event: 'Wydarzenie',
		ad: 'Reklama',
		ads: 'Reklamy',
		adsSuffix: ' ',
		id: 'Identyfikator',
		ids: 'Identyfikatory',
		eventType: 'Tagi',
		location: 'Lokalizacja',
		organizers: 'Organizatorzy',
		organizer: 'Organizator',
		timestamp: 'Znacznik czasu',
		date: 'Data',
		from: 'od',
		to: 'do',
		invoiceNumber: 'Numer faktury',
		invoiceNumberTip:
			'To pole wypełnij wraz z polem "Numer pozycji". Po przypisaniu mu wartości, możesz je indywidualnie edytować. Jeśli usuniesz wartość, pole "Numer pozycji" zostanie usunięte wraz z nią.',
		itemNumber: 'Numer pozycji',
		itemNumberTip:
			'To pole wypełnij wraz z polem "Numer faktury". Po przypisaniu mu wartości, możesz je indywidualnie edytować. Jeśli usuniesz wartość, pole "Numer faktury" zostanie usunięte wraz z nią.',
		statusName: 'Status',
		statusUsername: 'Kto ustawił status',
		statusLastChangedAt: 'Ostatnia zmiana statusu',
		admission: 'Wejście',
		artist: 'Artysta',
		artists: 'Artyści',
		lineup: 'Lista artystów',
		attendees: 'Osoby uczestniczące',
		setlists: 'Listy utworów',
		name: 'Nazwa',
		evCustomerIds: 'Identyfikatory klientów',
		evCustomerIdsTip:
			'Identyfikator wydarzenia (status) używany przez klienta; powiązane pola to "Wydarzenie - Status klienta" oraz "Wydarzenie - Identyfikatory klientów"',
		address: 'Adres',
		postalCode: 'Kod pocztowy',
		street: 'Ulica',
		houseNumber: 'Numer domu',
		city: 'Miasto',
		region: 'Region',
		country: 'Kraj',

		category: 'Kategoria',
		freeInput: 'Dowolne dane wejściowe',
		phone: 'Numery telefonów',
		websites: 'Strony internetowe',
		url: 'URL',
		mail: 'E-maile',
		commercialId: 'Identyfikator handlowy',
		locMatchedIds: 'Identyfikatory klientów',
		locCommercialId: 'Identyfikator handlowy',
		setlistTable: {
			songName: 'Tytuł piosenki',
			artist: 'Artysta oryginalny',
		},
		orgMatchedIds: 'Identyfikatory klientów',
		orgCommercialId: 'Identyfikator handlowy',
		isCancelled: 'Anulowano',
		isLocationSameAsOrganizer: 'Lokalizacja taka sama jak organizator',
		navigationCounter: template`#${0} z ${1}`,
		viewPDF: 'Podgląd PDF',
		uploadEvidenceImages: 'Załaduj obrazy potwierdzające',
		viewDescription: 'Zobacz opis',
		editDescription: 'Edytuj opis',
		viewSetlists: 'Zobacz listy utworów',
	},

	genDetailView: {
		evTimestamp: 'Wydarzenie - Znacznik czasu',
		evDate: 'Wydarzenie - Data',
		evInvoiceNumber: 'Wydarzenie - Numer faktury',
		evItemNumber: 'Wydarzenie - Numer pozycji',
		evStatusName: 'Wydarzenie - Status',
		evStatusUsername: 'Wydarzenie - Kto ustawił status',
		evStatusLastChangedAt: 'Wydarzenie - Ostatnia zmiana statusu',
		evCustomerIds: 'Wydarzenie - Identyfikatory klientów',
		evAdmission: 'Wydarzenie - Wstęp',
		evArtists: 'Wydarzenie - Artyści',
		evLineup: 'Wydarzenie - Lista artystów',
		evAttendees: 'Wydarzenie - Osoby uczestniczące',
		evTags: 'Wydarzenie - Tagi',
		evUrl: 'Wydarzenie - URL',
		evCancelled: 'Wydarzenie - Anulowano',
		evLocationEqualsOrganizer: 'Wydarzenie - Lokalizacja taka sama jak organizator',

		locName: 'Lokalizacja - Nazwa',
		locTimestamp: 'Lokalizacja - Znacznik czasu',
		locStatusName: 'Lokalizacja - Status',
		locStatusUsername: 'Lokalizacja - Kto ustawił status',
		locStatusLastChangedAt: 'Lokalizacja - Ostatnia zmiana statusu',
		locCustomerIds: 'Lokalizacja - Identyfikatory klientów',
		locCommercialId: 'Lokalizacja - Identyfikator handlowy',
		locAddress: 'Lokalizacja - Adres',
		locCategory: 'Lokalizacja - Kategoria',
		locEmails: 'Lokalizacja - E-maile',
		locPhoneNumbers: 'Lokalizacja - Numery telefonów',
		locWebsites: 'Lokalizacja - Strony internetowe',

		orgName: 'Organizator - Nazwa',
		orgTimestamp: 'Organizator - Znacznik czasu',
		orgStatusName: 'Organizator - Status',
		orgStatusUsername: 'Organizator - Kto ustawił status',
		orgStatusLastChangedAt: 'Organizator - Ostatnia zmiana statusu',
		orgCustomerIds: 'Organizator - Identyfikatory klientów',
		orgCommercialId: 'Organizator - Identyfikator handlowy',
		orgAddress: 'Organizator - Adres',
		orgCategory: 'Organizator - Kategoria',
		orgEmails: 'Organizator - E-maile',
		orgPhoneNumbers: 'Organizator - Numery telefonów',
		orgWebsites: 'Organizator - Strony internetowe',
	},

	tableView: {
		info: 'Informacje', // for filter
		infoCollaboration: 'Informacje - Współpraca',
		infoIsLocked: 'Informacje - Wydarzenie zablokowane', // for csv export
		infoLockingUser: 'Informacje - Kto zablokował', // for csv export
		infoState: 'Informacje - Stan',
		infoExportCount: 'Informacje - Ile wyeksportowano',
		infoLeaflet: 'Informacje - Ulotka',
		infoLastChangingUser: 'Informacje - Ostatnio zmieniony użytkownik',
		infoLastChangingUserTip:
			'Ostatni użytkownik, który przeniósł to wydarzenie na ulotkę, wyeksportował, ukrył albo oznaczył jako "Wydarzenie pozamuzyczne".',
		infoLastAction: 'Informacje - Ostatnie działanie',
		infoLastActionTip:
			'Kiedy ostatnio wydarzenie przeniesiono na ulotkę, wyeksportowano, ukryto lub oznaczono jako "Wydarzenie pozamuzyczne".',

		evId: 'Wydarzenie - Identyfikator',
		evIdTip:
			'Wydarzenie mogło poprzednio nosić inne identyfikatory. Nadal możesz je odnaleźć, filtrując wg poprzednich identyfikatorów.\n\nFiltrowany poprzedni identyfikator to szary tekst obok aktualnego identyfikatora.\n\nAby zobaczyć pełną listę poprzednich identyfikatorów, najedź myszą na ikonę informacji w odpowiednich komórkach. ',
		evOldIds: 'To wydarzenie nosiło poprzednio następujące "identyfikatory":',

		locIdTip:
			'Lokalizacja mogła poprzednio nosić inne identyfikatory. Nadal możesz ją odnaleźć, filtrując wg poprzednich identyfikatorów.\n\nFiltrowany poprzedni identyfikator to szary tekst obok aktualnego identyfikatora.\n\nAby zobaczyć pełną listę poprzednich identyfikatorów, najedź myszą na ikonę informacji w odpowiednich komórkach. ',

		locOldIds: 'Ta lokalizacja nosiła poprzednio następujące "identyfikatory":',

		orgIdTip:
			'Organizator mógł poprzednio nosić inne identyfikatory. Nadal możesz je odnaleźć, filtrując wg poprzednich identyfikatorów.\n\nFiltrowany poprzedni identyfikator to szary tekst obok aktualnego identyfikatora.\n\nAby zobaczyć pełną listę poprzednich identyfikatorów, najedź myszą na ikonę informacji w odpowiednich komórkach. ',

		orgOldIds: 'Ten organizator nosił poprzednio następujące "identyfikatory":',
		evStatusName: 'Wydarzenie - Status',
		evStatusUsername: 'Wydarzenie - Kto ustawił status',
		evStatusLastChangedAt: 'Wydarzenie - Ostatnia zmiana statusu',
		evInvoiceNumber: 'Wydarzenie - Numer faktury',
		evItemNumber: 'Wydarzenie - Numer pozycji',

		evName: 'Wydarzenie - Nazwa',
		evStartDate: 'Wydarzenie - Data rozpoczęcia',
		evStartTime: 'Wydarzenie - Czas rozpoczęcia',
		evEndDate: 'Wydarzenie - Data zakończenia',
		evEndTime: 'Wydarzenie - Czas zakończenia',
		evDates: 'Wydarzenie - Terminy',
		evAdmission: 'Wydarzenie - Wstęp',
		evType: 'Wydarzenie - Tagi',
		evRelevance: 'Wydarzenie - Muzyka',
		evSetlists: 'Wydarzenie - Listy utworów',
		evSetlistsArtist: 'Wydarzenie - Listy utworów',
		evSetlistsSongTitle: 'Wydarzenie - Listy utworów - Tytuł piosenki',
		evSetlistsOriginalArtist: 'Wydarzenie - Listy utworów - Artysta oryginalny',
		evSetlistsPerformer: 'Wydarzenie - Listy utworów - Wykonawca',
		evArtist: 'Wydarzenie - Artyści',
		evArtistTip: 'Artyści wymienieni w opisie wydarzenia',
		evArtistName: 'Wydarzenie - Nazwa artysty',
		evArtistScore: 'Wydarzenie - Utwory artysty',
		evLineup: 'Wydarzenie - Lista artystów',
		evLineupTip: 'Wykonawcy automatycznie skopiowani z metadanych lub z opisu wydarzenia',
		evAttendees: 'Wydarzenie - Osoby uczestniczące',
		evAdvertisementIds: 'Wydarzenie - Identyfikatory reklam',
		evDescription: 'Wydarzenie - Opis',
		evURL: 'Wydarzenie - URL',
		evCustomerIds: 'Wydarzenie - Identyfikatory klientów',
		evCustomerIdsTip:
			'Identyfikator wydarzenia (status) używany przez klienta, powiązane pola wyszukiwania to "Wydarzenie - Status klienta" oraz "Wydarzenie - Identyfikatory klientów"',
		evCustomerIdsTipFilter: 'Identyfikator wydarzenia używany przez klienta',
		evCustomerIdsStatus: 'Wydarzenie - Identyfikatory klientów (Status klienta)', // used in csv export because no tooltip in csv export
		evTimestamp: 'Wydarzenie - Znacznik czasu',
		evTimestampTip:
			'Data ostatniej aktualizacji wydarzenia (w bazie danych MESLIS dla strefy czasowej UTC+1).',
		evProcessingStatus: 'Wydarzenie - Status klienta',
		evIsCancelled: 'Wydarzenie - Anulowano',
		evIsLocationSameAsOrganizer: 'Wydarzenie - Lokalizacja taka sama jak organizator',
		evAdCount: 'Wydarzenie - Liczba reklam',
		evOrgCount: 'Wydarzenie - Liczba organizatorów',
		evSelectedOrganizer: 'Wydarzenie - Wybrany organizator',

		locStatusName: 'Lokalizacja - Status',
		locStatusUsername: 'Lokalizacja - Kto ustawił status',
		locStatusLastChangedAt: 'Lokalizacja - Ostatnia zmiana statusu',
		locId: 'Lokalizacja - Identyfikator',
		locMatchedId: 'Lokalizacja - Identyfikator klienta',
		locMatchedIds: 'Lokalizacja - Identyfikatory klientów',
		locCommercialId: 'Lokalizacja - Identyfikator handlowy',
		locCustomerStatus: 'Lokalizacja - Status klienta',
		locName: 'Lokalizacja - Nazwa',
		locStreet: 'Lokalizacja - Ulica',
		locCity: 'Lokalizacja - Miasto',
		locRegion: 'Lokalizacja - Region',
		locCountry: 'Lokalizacja - Kraj',
		locZIP: 'Lokalizacja - Kod pocztowy',
		locPhone: 'Lokalizacja - Numery telefonów',
		locCategory: 'Lokalizacja - Kategoria',
		locURL: 'Lokalizacja - Strony internetowe',
		locTimestamp: 'Lokalizacja - Znacznik czasu',
		locMail: 'Lokalizacja - E-maile',
		locEventCountTotal: 'Lokalizacja - Całkowita liczba wydarzeń',
		locEventCountRelevant: 'Lokalizacja - Liczba znalezionych wydarzeń muzycznych',
		locEventCountIrrelevant: 'Lokalizacja - Liczba znalezionych wydarzeń pozamuzycznych',
		locStatisticEventCountPerYear: 'Lokalizacja - Roczna liczba wydarzeń MESLIS',
		locCustomerEventCountPerYear: 'Lokalizacja - Roczna liczba wydarzeń klientów',

		orgStatusName: 'Organizator - Status',
		orgStatusUsername: 'Organizator - Kto ustawił status',
		orgStatusLastChangedAt: 'Organizator - Ostatnia zmiana statusu',
		orgId: 'Organizator - Identyfikator',
		orgMatchedIds: 'Organizator - Identyfikatory klientów',
		orgCommercialId: 'Organizator - Identyfikator handlowy',
		orgCustomerStatus: 'Organizator - Status klienta',
		orgName: 'Organizator - Nazwa',
		orgStreet: 'Organizator - Ulica',
		orgCity: 'Organizator - Miasto',
		orgRegion: 'Organizator - Region',
		orgCountry: 'Organizator - Kraj',
		orgZIP: 'Organizator - Kod pocztowy',
		orgPhone: 'Organizator - Numery telefonów',
		orgCategory: 'Organizator - Kategoria',
		orgURL: 'Organizator - Strony internetowe',
		orgTimestamp: 'Organizator - Znacznik czasu',
		orgMail: 'Organizator - E-maile',
		orgEventCountTotal: 'Organizator - Całkowita liczba wydarzeń',
		orgEventCountRelevant: 'Organizator - Liczba znalezionych wydarzeń muzycznych',
		orgEventCountIrrelevant: 'Organizator - Liczba znalezionych wydarzeń pozamuzycznych',
		orgStatisticEventCountPerYear: 'Organizator - Roczna liczba wydarzeń MESLIS',
		orgCustomerEventCountPerYear: 'Organizator - Roczna liczba wydarzeń klientów',
	},

	genTableView: {
		statusName: 'Status',
		statusUsername: 'Kto ustawił status',
		statusLastChangedAt: 'Ostatnia zmiana statusu',
		collaboration: 'Współpraca',
		id: 'Identyfikator',
		customerId: 'Identyfikator klienta',
		customerStatus: 'Status klienta',
		phoneNumbers: 'Numery telefonów',
		emailAddresses: 'E-maile',
		url: 'Strony internetowe',
		timestamp: 'Znacznik czasu',
		region: 'Region',
		commercialId: 'Identyfikator handlowy',
		category: 'Kategoria',
		type: 'Rodzaj',
		cusEvPerYear: 'Roczna liczba wydarzeń klientów',
		cusEvPerYearTip1:
			'Roczna liczba wydarzeń klientów w porównaniu z danymi MESLIS w następującym formacie:',
		cusEvPerYearTip2: 'rok: wydarzenia klientów/ wydarzenia MESLIS (różnica)',

		evPerYear: 'Roczna liczba wydarzeń MESLIS',
		evPerYearTip1: 'Roczna liczba wydarzeń wg danych MESLIS w następującym formacie:',
		evPerYearTip2: 'rok: wydarzenia MESLIS',
		address: {
			street: 'Ulica',
			city: 'Miasto',
			postalCode: 'Kod pocztowy',
			country: 'Kraj',
		},
		statistic: {
			eventCountIrrelevant: 'Liczba znalezionych wydarzeń pozamuzycznych',
			eventCountRelevant: 'Liczba znalezionych wydarzeń muzycznych',
			eventCountTotal: 'Całkowita liczba wydarzeń',
			evCountTotalTip:
				'Podana liczba to łączna kwota za wszystkie lata, która może różnić się od ram czasowych określonych w umowie.',
		},
	},

	queryTableView: {
		type: 'Rodzaj',
		id: 'Identyfikator',
		name: 'Nazwa',
		createdAt: 'Utworzono',
		filters: 'Filtry',
		hits: 'Wyniki',
		createdBy: 'Autor',
		sharedWith: 'Udostępniono',
		actions: 'Działania',

		new: 'nowe',

		unshareRequired:
			'Jeśli chcesz usunąć zapisane wyszukiwanie, najpierw musisz przestać udostępniać je wszystkim użytkownikom.',

		delete: 'Usuń',

		searchButton: {
			event: 'Szukaj wydarzeń',
			location: 'Szukaj lokalizacji',
			organizer: 'Szukaj organizatorów',
		},

		deleteButton: {
			deleted: 'Usunięto',
			failed: 'Operacja nie powiodła się',
			delete: 'Usuń',
			error: 'Błąd',
			deleteQuery: 'Czy na pewno chcesz usunąć to wyszukiwanie?',
		},

		showButton: {
			show: 'Pokaż',
			hide: 'Ukryj',
			showUnhidden: 'Pokaż nieukryte wyszukiwania',
			showHidden: 'Pokaż ukryte wyszukiwania',
		},
	},

	selectionsTableView: {
		filename: 'Nazwa pliku',
		createdAt: 'Utworzony',
		description: 'Opis',
		downloadCount: 'Pobrania',
		actions: 'Działania',
		mySelections: 'Moje wybory',
	},

	leaflets: {
		create: 'Utwórz nową ulotkę',
		edit: 'Edytuj',
		update: 'Aktualizuj ulotkę',
		delete: 'Usuń ulotkę',
		view: 'Widok',
		shared: 'Udostępniona',
		conflictTip1:
			'Sprzeczna ulotka zawiera wydarzenia, w przypadku których MESLIS nie wie, na której ulotce je zachować.\n\nDzieje się tak w rzadkich przypadkach, kiedy MESLIS scala kilka wydarzeń pochodzących z różnych ulotek.\n\nDotyczy to wszystkich użytkowników w firmie. Nie można ręcznie przenieść wydarzeń na tę ulotkę.',
		conflictTip2:
			'Sprawdź wydarzenia na sprzecznej ulotce i przenieś je ręcznie na właściwą ulotkę.',
		header: {
			create: 'Utwórz nową ulotkę',
			edit: 'Edytuj ulotkę',
		},
		leafName: 'Nazwa',
		leafId: 'Identyfikator',
		leafDate: 'Data utworzenia',
		leafTag: 'Tag',
		leafDescription: 'Opis',
		leafCreator: 'Autor',
		leafOwner: 'Właściciel',
		leafNameExists: 'Ulotka o tej nazwie już istnieje.',
		leafNameSpaces: 'Nazwa tej ulotki składa się tylko ze spacji.',

		formEditor: 'Wybierz współpracownika',
		maxHeader: 'Osiągnięto maksymalną liczbę ulotek',
		maxLeaflets:
			'Osiągnięto maksymalną liczbę ulotek dla jednego użytkownika. Jeśli chcesz utworzyć nową ulotkę, usuń inną.',
		sharedTooltip: 'Udostępniono innym użytkownikom',
		new: 'nowe',

		items: 'pozycja/e',

		created: 'Utworzono',
		edited: 'Edytowano',
		removed: 'Usunięto',
		clear: 'Wyczyść',
		clearMsg: template`Wyczyść ulotkę '${0}'`,
		cancel: 'Anuluj',
		removeAllEventsMsg: template`Czy na pewno chcesz usunąć wszystkie wydarzenia z ulotki '${0}'?`,
	},

	actions: {
		actions: 'Działania',
		moveTo: template`Przesuń do "${0}"`,
		moveToLeaflet: 'Przesuń do ulotki',
		leaflet: 'Ulotka',
		discard: 'Ukryj',
		scrap: 'Wydarzenie pozamuzyczne',
		drop: 'Upuść',
		keep: 'Zachowaj w tej samej ulotce',
		merge: 'Scal',
		displayColumns: 'Wyświetl i ukryj kolumny',

		eventStatusBulkEdit: 'Ustaw status',

		csvExport: 'Eksport do pliku CSV',
		expandAll: 'Rozwiń wszystkie',
		collapseAll: 'Zwiń wszystkie',
		collapse: '...mniej',
		expand: '...więcej',

		cancel: 'Anuluj',
		reset: 'Resetuj',
		ok: 'Ok',
		download: 'Pobierz',
		stillProcessing: 'Wciąż trwa przetwarzanie',
		undo: 'Cofnij',
		detail: 'Szczegół',
		table: 'Tabela',
		showAll: 'Pokaż wszystkie',
		hideAll: 'Ukryj wszystkie',
		showOnlyCoveredSongs: 'Pokaż tylko coverowane piosenki',

		error: 'Błąd',
		done: 'Gotowe',
		fail: 'Nie powiodło się',
		saveSearch: 'Zapisz wyszukiwanie',

		selectAll: 'Zaznacz wszystkie',
		deselectAll: 'Odznacz wszystkie',

		deleteAll: 'Usuń wszystkie',
		noGrouping: 'Bez grupowania',
		groupingByField: 'Grupowanie wg pola',
		groupingByAction: 'Grupowanie wg działania',

		changeToEditMode: 'Zmień na tryb "edytuj wydarzenie"',
		leaveEditMode: 'Zostaw tryb "edytuj wydarzenie"',
		instructionsEditMode: 'Aby zmienić tryb edycji, najpierw zablokuj wydarzenie. ',
		warningEditMode: 'Uwaga: Nie możesz edytować wydarzeń zablokowanych przez innych użytkowników.',
		createNewEvent: 'Utwórz wydarzenie',
		resetCreateUpdateEvent: 'Odrzuć zmiany',
		dragMode: 'Przesuń lub skopiuj do innego wydarzenia',

		createNewLocation: 'Utwórz lokalizację',
		createNewOrganizer: 'Utwórz organizatora',
		selectExistingLocation: 'Wybierz istniejącą lokalizację',
		selectExistingOrganizer: 'Wybierz istniejącego organizatora',
		switchTo: template`Przełącz się na "${0}"`,

		searchLocationByCustomerId: 'Szukaj istniejącej lokalizacji wg identyfikatora klienta',
		searchLocationByCommercialId: 'Szukaj istniejącej lokalizacji wg identyfikatora handlowego',
		searchOrganizerByCustomerId: 'Szukaj istniejącego organizatora wg identyfikatora klienta',
		searchOrganizerByCommercialId: 'Szukaj istniejącego organizatora wg identyfikatora handlowego',

		lock: {
			event: 'Zablokuj wydarzenie',
			organizer: 'Zablokuj organizatora',
		},
		locked: {
			event: 'Wydarzenie zablokowane przez innego użytkownika',
			organizer: 'Organizator zablokowany przez innego użytkownika',
		},
		unlock: {
			event: 'Odblokuj wydarzenie',
			organizer: 'Odblokuj organizatora',
		},

		createLocation: 'Utwórz lokalizację',
		createOrganizer: 'Utwórz organizatora',
		requiredFields: 'Pola oznaczone * są wymagane.',
		updateLocation: 'Edytuj lokalizację',
		updateOrganizer: 'Edytuj organizatora',

		redirectToLocation: 'Otwórz krok "Wybierz" dla lokalizacji w nowej zakładce.',
		redirectToOrganizer: 'Otwórz krok "Wybierz" dla organizatora w nowej zakładce.',
	},

	filters: {
		filters: 'Filtry',
		add: 'Dodaj',
		confirm: 'Potwierdź',
		keyword: {
			contains: 'zawiera',
			doesntContain: 'nie zawiera',
			containsAll: 'zawiera wszystkie',
			containsOneOf: 'zawiera jeden z',
			containsOnly: 'zawiera tylko',
			exists: 'istnieje',
			doesntContainAll: 'nie zawiera wszystkich',
			doesntContainOneOf: 'nie zawiera jednego z',
			doesntContainOnly: 'nie zawiera tylko',
			doesntExist: 'nie istnieje',
		},
		decompose: 'Dzieli frazę na bloki zawierające tylko jedno słowo.',
		operators: {
			atLeast: 'co najmniej',
			equals: 'równa się',
			atMost: 'co najwyżej',
			between: 'między',
			between_and: 'oraz',
			underLicensedCustomer: 'mniej niż wydarzeń MESLIS do',
			underLicensedCustomerInfo:
				'Wydarzeń klientów rocznie jest mniej niż wydarzeń MESLIS rocznie, a różnica jest co najmniej określoną tolerancją.',
			overLicensedCustomer: 'więcej niż wydarzeń MESLIS do',
			overLicensedCustomerInfo:
				'Wydarzeń klientów rocznie jest więcej niż wydarzeń MESLIS rocznie, a różnica jest co najmniej określoną tolerancją.',
		},
		count: {
			tolerance: 'tolerancja',
			number: 'liczba',
		},
		tags: {
			liveMusic: 'muzyka na żywo',
			backgroundMusic: 'muzyka w tle',
			djMusic: 'muzyka dj',
		},
		status: {
			newLocation: 'nowa lokalizacja',
			newOrganizer: 'nowy organizator',
			changedOwner: 'zmieniony właściciel',
			known: 'znany',
		},
		boolean: {
			true: 'tak',
			false: 'nie',
		},
		year: {
			inYear: 'w roku',
			betweenYears: 'między latami',
			betweenYears_and: 'oraz',
			sinceYear: 'od roku',
			untilYear: 'do roku',
			forAllYears: 'dla wszystkich lat',
		},
		info: {
			lastChangingUser: 'użytkownik, który dokonał ostatniej zmiany',
			leaflet: 'ulotka',
			lastChange: 'ostatnia zmiana',
			between: 'między',
			between_and: 'oraz',
			from: 'od',
			to: 'do',
		},
		dateRange: {
			startDate: 'Data początkowa',
			endDate: 'Data końcowa',
			start: 'Początek',
			end: 'Koniec',
		},
		range: {
			from: 'od',
			to: 'do',
			year: 'rok',
			fromYear: 'od roku',
			toYear: 'do roku',
		},
		collaboration: 'Współpraca',
		chooseLocking: 'Wybierz blokowanie',
		chooseUsers: 'Wybierz jednego lub więcej użytkowników',
		collaborationOptions: {
			any: 'Dowolny',
			unlocked: 'Odblokowany',
			locked: 'Zablokowany',
			lockedByMe: 'Zablokowany przeze mnie',
			lockedByOtherUsers: 'Zablokowany przez innych użytkowników',
			lockedByFollowingUsers: 'Zablokowany przez następujących użytkowników...',
		},
	},

	genericEventFilters: {
		filters: 'Filtry',
		relevance: 'Muzyka',
		relevances: {
			any: 'Dowolna',
			relevant: "Ocenione jako 'Wydarzenie muzyczne'",
			irrelevant: "Ocenione jako 'Wydarzenie pozamuzyczne'",
		},
		year: 'Rok',
		any: 'Dowolny',
		status: 'Status',
		states: {
			NEW: 'Nowe',
			IN_PROGRESS: 'W trakcie',
			EXPORTED: 'Wyeksportowane',
			DISCARDED: 'Ukryte',
			SCRAPPED: "Oznaczone jako 'Wydarzenie pozamuzyczne'",
		},
	},

	genericPager: {
		location: 'Lokalizacja',
		organizer: 'Organizator',
		loading: 'Ładowanie...',
		newEvents: template`Nowych wydarzeń: ${0}`,
	},

	resetPassword: {
		modalHeader: 'Poproś o reset hasła',
		modalDesc: 'Aby poprosić o reset hasła, wprowadź nazwę użytkownika.',
		failMessage: 'Wprowadzona nazwa użytkownika nie istnieje. Sprawdź, czy jest prawidłowa.',
		failResetMessage:
			'Reset hasła nie powiódł się. Spróbuj ponownie lub poproś o nowy link do resetu hasła na app.meslis',
		failTokenHeader: 'Twój link do resetu zawiera błąd lub wygasł.',
		failTokenMessage:
			'Aby poprosić o nowy link do resetu, rozpocznij procedurę resetowania ze strony logowania MESLIS WebApp.',
		successMessage:
			'Prośba o reset hasła powiodła się. Potwierdzenie znajdziesz w skrzynce e-mail.',
		confirmButton: 'Poproś o reset',
		triggerButton: 'Poproś o reset hasła',
		reset: 'Resetuj hasło',
		change: 'Zmień hasło',
		oldPassword: 'Stare hasło',
		passwordChangeHeader: 'Tutaj zmień swoje hasło MESLIS Web App',
		passwordChangeMessage: template`Ta zmiana nie obejmuje hasła do Centrum Pomocy MESLIS. Jeśli chcesz zmienić także to hasło, przejdź do ustawień bezpieczeństwa w swoim profilu w ${0}.`,
		passwordResetHeader: 'Tutaj zmień swoje hasło MESLIS Web App',
		passwordResetMessage: template`Ta zmiana nie obejmuje hasła do Centrum Pomocy MESLIS. Jeśli chcesz zmienić także to hasło, przejdź do ustawień bezpieczeństwa w swoim profilu w ${0}.`,
		newPassword: 'Nowe hasło',
		validatePassword: 'Potwierdź nowe hasło',
		setNewPassword: 'Ustaw nowe hasło',
		strength:
			'Twoje hasło musi spełniać następujące wymagania:\n\t\u2022 co najmniej 12 znaków\n\t\u2022 co najmniej jedna cyfra [0-9]\n\t\u2022 co najmniej jedna duża litera [A-Z]\n\t\u2022 co najmniej jedna mała litera [a-z]\n\t\u2022 co najmniej jeden znak specjalny: &-_[]`~|@$%^?!:.{}',
	},

	userSettings: {
		changePassword: 'Zmień hasło',
		table: 'Widok tabeli',
		defaultFetchSize: 'Liczba dodanych pozycji przy przewijaniu do dołu',
		headerReset: 'Przywróć domyślny porządek kolumn',
		eventsReset: "Tabela 'Szukaj wydarzeń'",
		locationsReset: "Tabela 'Szukaj lokalizacji'",
		organizersReset: "Tabela 'Szukaj organizatorów'",
		reset: 'Resetuj',
	},

	drag: {
		moveEventAd: template`Przenieś reklamę wydarzenia ${0}`,
		moveEventAdDescription: template`z identyfikatora ${0} do innego wydarzenia.`,
		moveEvent: 'Przenieś wydarzenie',
		moveEventDescription: template`z identyfikatora ${0} do innego wydarzenia.`,
		copyEventAd: template`Kopiuj reklamę wydarzenia ${0}`,
		copyEventAdDescription: template`z identyfikatora ${0} do innego wydarzenia.`,
		copyEvent: 'Kopiuj wydarzenie',
		copyEventDescription: template`z identyfikatora ${0} do innego wydarzenia.`,
		allAdsMoved: 'Wszystkie reklamy tego wydarzenia zostały przeniesione do innego wydarzenia.',
		allAdsCopied: 'Wszystkie reklamy tego wydarzenia zostały skopiowane do innego wydarzenia.',
		dragThisBox: 'Przeciągnij to pole do widoku szczegółów w innej zakładce przeglądarki!',
		dropHere: 'Tutaj upuść reklamy lub wydarzenia.',
	},

	messages: {
		tooManyRequests: template`Wygląda na to, że próbowano ${0} zbyt wiele razy. Spróbuj później.`,
		searchResults: template`${2}łącznie: ${0} / wyświetlonych: ${1}`,
		selectSearchResults: template`${3}łącznie: ${0} / wyświetlonych: ${1} / wybranych: ${2}`,
		createEvent: 'Utwórz wydarzenie',
		updateEvent: 'Edytuj wydarzenie',
		searchLocations: 'Szukaj lokalizacji - ',
		searchOrganizers: 'Szukaj organizatorów - ',

		noLeafletsHeader: 'Brak ulotek',
		noLeafletsText: 'Aktualnie nie masz ulotek na swojej liście ulotek.',

		noDataHeader: 'Nie znaleziono danych',
		noData:
			'Brak danych odpowiadających aktualnym filtrom. Zmień ustawienia filtrów, aby uzyskać dostęp do większej ilości danych.',

		noSubscriptionsHeader: 'Brak zapisanych wyszukiwań',
		noSubscriptionsText:
			'Możesz zapisać listy filtrów stworzone dla wyszukiwania wydarzeń, wpisując nazwę i klikając "Zapisz wyszukiwanie". Wtedy wyświetlą się tutaj Twoje zapisane wyszukiwania.',

		noSelections:
			"Nie wybrano żadnych pozycji. Aby wybrać, przejdź do 'Moich ulotek' i wyeksportuj ulotkę albo wydarzenia.",

		noHistory:
			"Historia to tabelaryczny przegląd wszystkich ukrytych wydarzeń oraz 'Wydarzeń pozamuzycznych'. Wyświetlane są tylko wydarzenia przetwarzane przez zalogowanego użytkownika. Nie ukryto jeszcze żadnych wydarzeń ani nie oznaczono ich jako 'Wydarzenie pozamuzyczne'.",

		export: {
			header: template`Liczba eksportowanych wydarzeń: ${0}`,
			description: template`Liczba wydarzeń z ulotki ${1}, które zostaną za chwilę wyeksportowane to ${0}. Możesz utworzyć opis, aby zidentyfikować lub wyróżnić ten plik wśród innych eksportowanych.`,
			descriptionPlaceholder: 'Uwtórz opis...',

			action: template`Po wyeksportowaniu, możesz zatrzymać wydarzenia na tej samej ulotce albo przenieść je do dowolnej innej ulotki jako status "W trakcie" ${0}. Jeśli pozostawisz ustawienia domyślne, wydarzenia zostaną usunięte z aktualnej ulotki, a ich status zostanie ustawiony jako "Wyeksportowane" ${1}.`,
			actionPlaceholder: 'Pozostaw ustawienia fabryczne lub wybierz działanie...',
			button: {
				cancel: 'Anuluj',
				export: template`Eksportuj ${0}`,
				selected: 'wybrane',
				all: 'wszystkie',
				exportAnd: template` oraz ${0}`,
			},
			pageNotFound: 'Nie znaleziono strony',

			exportSuccessful: 'Wyeksportowano pomyślnie',
			messageExportBeingProcessed:
				'Polecenie jest w trakcie realizacji. Może to chwilę potrwać, więc zajrzyj niedługo do swojej skrzynki odbiorczej. Jeśli chcesz, w tym czasie możesz rozpocząć nową operację eksportowania.',
			startNewSearch: 'Rozpocznij nowe wyszukiwanie',
			useSavedSearch: 'Skorzystaj z zapisanego wyszukiwania',
			checkSelections: 'Sprawdź swoje wybory',
		},

		selectOrganizer: {
			headerUnselected: 'Wybierz właściwego organizatora',
			headerSelected: template`Wybierz organizatora "${0}"`,
			description:
				'To wydarzenie jest aktualnie przypisane do wielu organizatorów. Jeśli chcesz użyć go w obiegu organizatorów, musisz wybrać jednego właściwego organizatora. Blokowanie organizatorów ma zastosowanie tylko w przypadku wydarzeń przypisanych do jednego organizatora.',
			customID: 'specjalny identyfikator',
			oldIdReplacedByNewId: template`Poprzedni identyfikator ${0} zastąpi nowy identyfikator ${1}.`,
		},

		undoLastEventMove: 'Cofnij ostatnie przeniesienie wydarzenia',

		undoLastMoveAction: template`Czy na pewno chcesz cofnąć ${0}? Pamiętaj, że aktualnie brak jest funkcji "wykonaj ponownie".`,
		undoMoveAds: template`Twoje ostatnie działanie (przesuń ${0} reklam z wydarzenia ID=${1} z powrotem do wydarzenia ID=${2})`,
		undoMoveAdsOfEvents: template`Twoje ostatnie scalanie (przesuń ${0} reklam z wydarzenia ID=${1} z powrotem do pojedynczych wydarzeń)`,
		undoCopyAds: template`Twoje ostatnie kopiowanie (usuń ${0} reklam z wydarzenia ID=${1})`,
		undoCopyAdsToNewRemoveAds: template`Twoje ostatnie kopiowanie (usuń wszystkie reklamy z wydarzenia ID=${0})`,
		undoEventMove: template`Twoje ostatnie przeniesienie wydarzenia (liczba wydarzeń: ${0})`,

		noSetlistsDataHeader: 'Brak danych o listach utworów',
		noSetlistsData: 'Brak dostępnych list utworów dla tego wydarzenia.',
		passwordResetSuccessfulHeader: 'Reset hasła powiódł się',
		passwordResetSuccessful: template`Pomyślnie zresetowano hasło. Możesz się teraz zalogować w ${0}.`,

		urlsElseWhere: 'Wprowadź adresy URL pod obrazami potwierdzającymi.',
	},

	alerts: {
		information: 'Ojej...',
		eventsMoveFailed: template`Działanie nie powiodło się: niektóre wydarzenia nie mogły zostać przeniesione ${0}. Ten błąd pojawia się, kiedy niektóre z wydarzeń są modyfikowane lub blokowane przez Ciebie albo przez innego użytkownika. Załaduj widok ponownie i spróbuj jeszcze raz.`,
		eventsMoveFailedOfIds: template` (Identyfikatory: ${0})`,
		queryNameAlreadyExists: template`Wyszukiwanie o nazwie "${0}" już istnieje - wprowadź inną nazwę.`,
		selectedMaxAmountOfEvents: template`Wybrano tylko ${0} pierwszych wydarzeń. Zrealizuj lub zmień aktualny wybór, by wybrać dalsze wydarzenia. Maksymalna liczba wydarzeń, które można wybrać za jednym razem to ${0}.`,
		leafletUnshared: 'Cofnięto udostępnienie Ci przeglądanej przez Ciebie ulotki.',

		unshareColleague:
			'Czy na pewno chcesz przestać udostępniać zapisane wyszukiwanie tym użytkownikom?',
		leafletNotBeCleared: template`Coś poszło nie tak - nie można wyczyścić ulotki! \n ${0}`,
		downloadFailed: 'Pobieranie nie powiodło się - załaduj ponownie i spróbuj jeszcze raz!',
		mergeEvents: template`Czy na pewno chcesz scalić ${0} wybrane wydarzenia i przenieść je do wydarzenia ID=${1}?`,
		linkUnlink: {
			noMoveAds: template`Nie można przenieść reklam/y ${0} z wydarzenia ${1} do wydarzenia ${2}.`,
			noMoveAdsToNew: template`Nie można utworzyć wydarzenia lub przenieść reklam/y ${0} z wydarzenia ${1} do nowego wydarzenia.`,
			noCopyAds: template`Nie można skopiować reklam/y ${0} z wydarzenia ${1} do wydarzenia ${2}.`,
			noCopyAdsToNew: template`Nie można skopiować reklam/y ${0} z wydarzenia ${1} do wydarzenia.`,
			noDeleteEvent: template`Nie można usunąć następujących wydarzeń: ${0}.`,
			noUndo:
				'Nie wszystkie działania cofnięcia powiodły się. Niektóre wydarzenia mogły zostać w tym czasie przeniesione.',
		},
		otherUserLockedEvent:
			'Blokowanie wydarzenia nie powiodło się. Możliwe, że inny użytkownik podjął w tym samym czasie takie samo działanie.',
		otherUserLockedOrganizer:
			'Blokowanie organizatora nie powiodło się. Możliwe, że inny użytkownik podjął w tym samym czasie takie samo działanie.',
		errorAssignOrganizer: 'Nie można przypisać organizatora do wydarzenia.',
		lockedSelectionIgnored: template`Niektóre wybrane przez Ciebie wydarzenia zablokował w międzyczasie inny użytkownik. Zignorowane będą wydarzenia z następującymi identyfikatorami: ${0}.`,

		failMessageGenerator: template`Coś poszło nie tak - ${0} nie może być ${1}. \nSpokojnie, to nie Twoja wina. Pracujemy nad tym!`,
		item: {
			event: 'wydarzenie',
			eventStatusBulkEdit: 'grupowa edycja statusu wydarzeń',
			advertisement: 'reklama',
			advertisements: 'reklama/y',
			location: 'lokalizacja',
			organizer: 'organizator',
			search: 'wyszukiwanie',
			undo: 'cofnij',
		},
		what: {
			locked: 'zablokowane',
			unlocked: 'odblokowane',
			updated: 'zaktualizowane',
			created: 'utworzone',
			applied: 'zastosowane',
			copied: 'skopiowane',
			moved: 'przeniesione',
			deleted: 'usunięte',
		},
	},

	tooltips: {
		discardButtonHeader: 'Ukryj wydarzenia z widoku standardowego',
		discardButton:
			'Pamiętaj, że ta czynność ma wpływ na bieżący widok, ale także na przyszłe wyszukiwania wydarzeń dla innych użytkowników w organizacji. Jeśli chcesz uwzględnić ukryte wydarzenia w wyszukiwaniu, włącz przycisk statusu "Ukryte".',
		scrapButtonHeader:
			'Wprowadź wydarzenia jako negatywne dane treningowe w celu poprawy rezultatów muzycznych',
		scrapButton:
			'W wyszukiwaniu wydarzeń pojawi się mniej podobnych wydarzeń. Pamiętaj, że ta czynność usuwa wydarzenia z bieżącego widoku, ale także z przyszłych wyszukiwań wydarzeń dla innych użytkowników w organizacji. Jeśli chcesz uwzględnić te wydarzenia w wyszukiwaniu, włącz przycisk statusu "Oznaczone jako \'Wydarzenie pozamuzyczne\'".',
		dropButtonHeader: 'Usuń wydarzenia z bieżącej ulotki',
		dropButton:
			'Będą one dostępne poprzez wyszukiwanie wydarzeń o statusie "Nowe". Jeśli miały inny status przed przeniesieniem do ulotki, mogą również zostać zresetowane do statusu "Wyeksportowane", "Ukryte" lub "Oznaczone jako \'Wydarzenie pozamuzyczne\'".',
		deleteButton:
			'Aby usunąć ulotkę, należy usunąć z niej wszystkie wydarzenia i cofnąć jej udostępnienie wszystkim współpracownikom.',
		mergeButton:
			'Scal wybrane wydarzenia. Reklamy wszystkich wybranych wydarzeń zostaną przeniesione do wybranego wydarzenia na górze.',

		dragModeButton: 'Zamiast tego możesz wcisnąć klawisz "F2".',

		mergeButtonTooFew: 'Aby skorzystać z przycisku scalania, wybierz co najmniej dwa elementy.',
		mergeButtonTooMany: template`Aby skorzystać z przycisku scalania, wybierz maksymalnie ${0} wydarzeń. Nie można scalić większej liczby wydarzeń jednocześnie.`,
		lockButton: {
			event: {
				lock:
					'Zablokuj to wydarzenie, aby móc je przenieść lub edytować. Dopóki wydarzenie jest zablokowane, inni użytkownicy mogą je tylko odczytywać.',
				locked: 'Dopóki inny użytkownik blokuje wydarzenie, można je tylko odczytać.',
				unlock: 'Zezwól innym użytkownikom na pracę z tym wydarzeniem.',
				disabled:
					'Nie masz dostępu do ulotki wydarzenia. Możesz jedynie zapoznać się z wydarzeniem.',
			},
			organizer: {
				lock:
					'Zablokuj tego organizatora, aby móc przenosić lub edytować przypisane wydarzenia. Podczas blokady inni użytkownicy mogą tylko odczytywać wydarzenia."nHint": Blokada nie jest stosowana do wydarzeń, które są przypisane do innych organizatorów. Aby zablokować również te wydarzenia, należy przypisać je do jednego organizatora.',
				locked:
					'Dopóki inny użytkownik blokuje organizatora, można odczytywać tylko unikalnie przypisane wydarzenia.',
				unlock: 'Zezwól innym użytkownikom na pracę z przypisanymi wydarzeniami tego organizatora.',
			},
		},
		lockIcon: {
			event: {
				locked: template`To wydarzenie jest zablokowane przez ${0}. Możesz je tylko odczytać.`,
				lockedByMe:
					'Blokujesz to wydarzenie. Nie może być ono edytowane przez innych użytkowników.',
				viewed: template`Wydarzenie ${1} jest oglądane przez ${0} użytkownika/ów.`,
				unlocked: 'To wydarzenie nie jest zablokowane przez żadnego użytkownika.',
			},
			organizer: {
				locked: template`Ten organizator jest zablokowany przez ${0}. Możesz go tylko odczytać.`,
				lockedByMe:
					'Blokujesz tego organizatora. Nie może być on edytowany przez innych użytkowników.',
				viewed: template`Organizator ${1} jest oglądany przez ${0} użytkownika/ów.`,
				unlocked: 'Ten organizator nie jest zablokowany przez żadnego użytkownika.',
			},
		},
		submitButton: {
			submit: 'Prześlij',
			submitPermitted: 'Przesłanie jest dozwolone.',
			unchanged: 'Dane nie uległy zmianie.',
			missingFields: 'Brakujące pola obowiązkowe: ',
			invalidFields: 'Błędne wartości: ',
			fieldNames: {
				name: 'Nazwa',
				startDate: 'Data rozpoczęcia',
				endDate: 'Data zakończenia',
				Location: 'Lokalizacja',
				editedLocation: {
					name: 'Lokalizacja - Nazwa',
					matchedLocationIds: 'Lokalizacja - Identyfikatory klientów',
					emailAddresses: 'Lokalizacja - E-maile',
					phoneNumbers: 'Lokalizacja - Numery telefonów',
					urls: 'Lokalizacja - Strony internetowe',
					address: {
						postalCode: 'Lokalizacja - Kod pocztowy',
						street: 'Lokalizacja - Ulica',
						houseNumber: 'Lokalizacja - Numer domu',
						city: 'Lokalizacja - Miasto',
						region: 'Lokalizacja - Region',
						country: 'Lokalizacja - Kraj',
					},
				},
				organizer: 'Organizator',
				editedOrganizer: {
					name: 'Organizator - Nazwa',
					matchedOrganizerIds: 'Organizator - Identyfikatory klientów',
					emailAddresses: 'Organizator - E-maile',
					phoneNumbers: 'Organizator - Numery telefonów',
					urls: 'Organizator - Strony internetowe',
					address: {
						postalCode: 'Organizator - Kod pocztowy',
						street: 'Organizator - Ulica',
						houseNumber: 'Organizator - Numer domu',
						city: 'Organizator - Miasto',
						region: 'Organizator - Region',
						country: 'Organizator - Kraj',
					},
				},
				images: 'Adresy URL obrazów potwierdzających',
				noImage: 'Obraz potwierdzający',
				noImages: 'Obrazy potwierdzające (wymagany co najmniej jeden)',
				admission: 'Wstęp',
				visitorCount: 'Osoby uczestniczące',
				invoiceNumber: 'Numer faktury',
				itemNumber: 'Numer pozycji',
			},
		},
		csvExport: template`${0}: wyeksportuj wszystkie wyświetlone z aktualnie włączonymi kolumnami.`,
		csvExportLocations: 'Lokalizacje',
		csvExportOrganizers: 'Organizatorzy',
		csvExportEvents: 'Wydarzenia',
		expandAllButtonLocations:
			'Rozwija (lub zwija) wszystkie rzędy tabeli, umożliwiając wyświetlenie wszystkich lat dla kolumn "Wydarzenia klienta na rok" oraz "Wydarzenia MESLIS na rok".',
		expandAllButtonOrganizers:
			'Rozwija (lub zwija) wszystkie rzędy tabeli, umożliwiając wyświetlenie wszystkich lat dla kolumn "Wydarzenia klienta na rok" oraz "Wydarzenia MESLIS na rok".',
		expandAllButtonEvents:
			'Rozwija (lub zwija) wszystkie rzędy tabeli, umożliwiając wyświetlenie wszystkich organizatorów wydarzenia.',
		info: {
			header: {
				state: 'Sortuj wg statusu',
				leaflet: 'Sortuj wg aktualnej ulotki',
				user: 'Sortuj wg użytkownika, który wprowadził zmiany jako ostatni',
				timestamp: 'Sortuj wg czasu ostatniej zmiany',
			},
			cell: {
				infoIs: 'Stan wydarzenia',
				NEW: 'Nowe',
				IN_PROGRESS: 'W trakcie',
				EXPORTED: 'Wyeksportowane',
				DISCARDED: 'Ukryte',
				SCRAPPED: "Oznaczone jako 'Wydarzenie pozamuzyczne'",

				itWas_exported: template`Wydarzenie wyeksportowano ${0}.`,
				once: 'raz',
				twice: 'dwa razy',
				times: 'razy',

				currLeafletIs: 'Aktualna ulotka to',
				noLeaflet: 'Wydarzenia aktualnie nie ma na żadnej ulotce',
				lastChangeWas: 'Ostatnie działanie w celu zmiany statusu wydarzenia miało miejsce',
				timestampUnknown: 'Nieznany jest znacznik czasu ostatniej zmiany statusu wydarzenia',
				changingUserWas: 'Użytkownik, który jako ostatni dokonywał zmian, to',
				userUnknown: 'Żaden użytkownik nie zmienił statusu wydarzenia',
			},

			filter: {
				leafletsDisabled1: 'Wybrane ulotki są aktualnie wyłączone. Włącz status "W trakcie" ',
				leafletsDisabled2: ' lub kliknij to pole.',
				leafletsEnabled1: 'Po wybraniu ulotek w statusie "W trakcie" ',
				leafletsEnabled2: ' zostanie automatycznie włączone.',
			},
			entry: {
				leafletsDisabled1:
					'Wybrane ulotki są aktualnie wyłączone. Aby filtrować ulotki włącz status "W trakcie" ',
				leafletsDisabled2: ' lub kliknij na tę pozycję.',
			},
			quickfilter: {
				NEW: 'Nowe',
				IN_PROGRESS: 'W trakcie (przeniesione do ulotki)',
				EXPORTED: 'Wyeksportowane',
				DISCARDED: 'Ukryte',
				SCRAPPED: "Oznaczone jako 'Wydarzenie pozamuzyczne'",
			},
		},
		anyField:
			'Wyszukiwanie przybliżone w kilku polach pozwalające słowom pola różnić się od wyszukiwanego terminu o dwa znaki.',
		listNavigatorForbiddenForward:
			'Nie można pobrać więcej elementów, dopóki poprzednie nie zostaną przetworzone.',

		sortLeaflets: {
			name: 'Sortuj wg nazwy',
			creator: 'Sortuj wg autora',
			createdAt: 'Sortuj wg daty utworzenia',
			updatedAt: 'Sort wg daty ostatniej edycji',
			default: 'Porządek domyślny',
		},

		locationCreated: template`Utworzono lokalizację. Identyfikator to ${0}.`,
		locationUpdated: template`Zaktualizowano lokalizację ${0}`,
		organizerCreated: template`Utworzono organizatora. Identyfikator to ${0}.`,
		organizerUpdated: template`Zaktualizowano organizatora ${0}`,
		mergedEventsUpdateSuccess: template`Pomyślnie zaktualizowano wydarzenie (wydarzenia) o identyfikatorze: ${0}.`,
		mergedEventsUpdateDuplication: template`Nie powiodła się duplikacja wydarzenia (wydarzeń) o identyfikatorze: ${0}.`,
		mergedEventsUpdateFailure: template`Nie powiodła się aktualizacja dla następujących identyfikatorów: ${0}.`,
		mergedEventsUpdateMatching: template`Nie powiodło się dopasowanie wydarzenia (wydarzeń) o identyfikatorze: ${0}.`,

		sortIcon: {
			descSort: 'Sortowanie w porządku malejącym wg tej kolumny.',
			ascSort: 'Sortowanie w porządku rosnącym wg tej kolumny.',
			prioSort: template`Ta kolumna to priorytet dla: ${0}.`,
			clickToSort: 'Kliknij, aby sortować tę kolumnę.',
			clickToToggleSort: 'Kliknij, aby odwrócić sortowanie.',
			multipleSort:
				'Wkazówka: Kliknięcie i przytrzymanie klawisza Control powoduje utworzenie sortowania wielokrotnego. W ten sposób można dołączyć kolejne kolumny i nadać im priorytet, jeśli w sortowanej kolumnie znajdują się równe wartości.',
			notSortable: 'Nie można sortować tej kolumny.',
		},
	},

	titles: {
		logout: 'Wylogowanie z systemu',
	},

	placeholders: {
		leafletDescription: 'Kilka słów opisu Twojej ulotki...',
		pdfPlaceholder: 'Dokument pdf niedostępny.',
		loading: 'Ładowanie',
		loadingDots: 'ładowanie...',
		noItemsFound: 'Nie znaleziono żadnych pozycji.',
		moreDataHeader: 'Pobieranie danych',
		moreData: 'Poczekaj chwilę...',
		noMoreDataHeader: 'Nie ma więcej danych',
		noMoreData: 'Nie ma więcej danych do załadowania.',
		chooseAName: 'Wybierz nazwę',
		dragEventAdsOrInput:
			'Przeciągnij reklamy wydarzenia z innego okna przeglądarki i upuść je tutaj albo wypełnij pola, aby utworzyć nowe dane reklamy.',
		allAdsRemoved: 'Usunięto wszystkie reklamy dla tego wydarzenia.',
		dragAndDrop: 'Przeciągnij i upuść obrazy tutaj lub kliknij wybrane pliki.',
		releaseToDrop: 'Puść, aby upuścić pliki tutaj.',
	},

	error: {
		error: 'Błąd',
		login: 'Login nie powiódł się.',
		locked: 'Konto zostało zablokowane. Poproś o reset hasła.',
		dataFetch: 'Nie można pobrać danych',
		rulePassword:
			'hasło musi zawierać co najmniej 12 znaków, w tym co najmniej jedną dużą literę, jedną małą literę, jedną cyfrę i jeden znak specjalny',
		samePassword: 'Stare i nowe hasło nie może być takie samo',
		notSameNewPassword: 'Oba hasła nie są takie same. Wprowadź je ponownie.',
		notSamePassword: 'hasła nie są takie same',
		wrongPassword: 'błędne hasło',
		passwordNotValid: 'Błędne pierwotne hasło.',
	},
	success: { passwordChange: 'Otrzymasz e-mail z potwierdzeniem Twojego działania.' },

	navigation: {
		home: 'Strona główna',
		events: 'Szukaj wydarzeń',
		locations: 'Szukaj lokalizacji',
		organizers: 'Szukaj organizatorów',
		subscriptions: 'Moje wyszukiwania',
		subscriptionShared: 'Ostatnio udostępniono Ci wyszukiwanie.', // saved search
		subscriptionsShared: template`Liczba ostatnio udostępnionych Ci wyszukiwań: ${0}.`,
		leaflets: 'Moje ulotki',
		exports: 'Moje wybory',
		history: 'Moja historia',
		customLeaflet: 'Zarządzaj ulotkami',
		leafletShared: 'Ostatnio udostępniono Ci ulotkę.',
		leafletsShared: template`Liczba ostatnio udostępnionych Ci ulotek: ${0}.`,
		help: 'Centrum pomocy',
		ticketSystem: 'System biletów',
		close: 'Zamknij',
		userSettings: 'Ustawienia użytkownika',
		search: 'Szukaj',
		select: 'Wybierz',
		process: 'Przetwarzaj',
	},

	local: {
		localSettings: 'Ustawienia lokalne',
		language: 'Język',
		dateAndTime: 'Data i czas',
	},

	countries: {
		australia: 'Australia',
		austria: 'Austria',
		belgium: 'Belgia',
		denmark: 'Dania',
		germany: 'Niemcy',
		finland: 'Finlandia',
		greatBritain: 'Wielka Brytania',
		ireland: 'Irlandia',
		israel: 'Izrael',
		italy: 'Włochy',
		netherlands: 'Holandia',
		portugal: 'Portugalia',
		sweden: 'Szwecja',
		switzerland: 'Szwajcaria',
		usa: 'USA',
	},
};
