import React from 'react';

import FilterForm from './FilterForm';
import FilterList from './FilterList';
import SubscribeForm from './SubscribeForm';
import EventStateQuickFilter from './EventStateQuickFilter';
import filterUtils from './filterUtils';
import InfoPopup from 'components/Base/InfoPopup';

import {
	keywordOr,
	keywordOrExists,
	keywordOrAndExists,
	keywordOrAndStrictExists,
	keywordNoBlocks,
	dateRange,
	datesRange,
	numberRange,
	tagList,
	booleanSelect,
	ratingRange,
	eventStateInfo,
	collaboration,
	entityStatusSelect,
	userSelect,
} from 'formTypes';
import { ICONS } from 'config';
import { useTranslate } from 'customHooks';
const { EVENT_MUSIC, LOCATION, ORGANIZER, COLLABORATION, ANY_FIELD, STATE_INFO } = ICONS;

const EventsFilter = ({ filters, sorting, quickFilter, onFiltersUpdate, onQuickFilterUpdate }) => {
	const translate = useTranslate();
	const fields = eventFields(translate);

	const updateFilters = filters => onFiltersUpdate(filterUtils.removeDuplicates(filters));
	const handleAddFilter = filter =>
		updateFilters([...filters, { ...filter, ...{ enabled: true } }]);

	const handleChangeEventState = value => onQuickFilterUpdate({ fieldName: 'state', value });

	return (
		<div>
			<EventStateQuickFilter
				value={quickFilter && quickFilter.value}
				onChangeValue={handleChangeEventState}
			/>
			<FilterForm
				{...{ fields }}
				confirmButtonText={translate('filters.add')}
				type="event"
				onAddFilter={handleAddFilter}
			/>
			<hr />
			<SubscribeForm enabled={filters.length} {...{ filters, sorting, quickFilter }} />
			{!!filters.length ? (
				<FilterList {...{ fields, filters }} type="event" onChangeFilters={updateFilters} />
			) : null}
		</div>
	);
};

const setFilterField = (name, _label, formType, listIcon) => {
	const [label, content] =
		_label && typeof _label === 'object'
			? [_label.label, <InfoPopup header={_label.label} content={_label.tooltip} />]
			: [_label, null];

	return {
		name,
		label,
		content,
		formType,
		listIcon,
		defaultValue: formType.defaultValue,
	};
};

export const eventFields = translate => [
	setFilterField('all', translate('anyField'), keywordNoBlocks, ANY_FIELD),

	setFilterField('eventStateInfo', translate('tableView.info'), eventStateInfo, STATE_INFO),
	setFilterField(
		'collaboration',
		translate('tableView.infoCollaboration'),
		collaboration,
		COLLABORATION
	),
	setFilterField(
		'id',
		{ label: translate('tableView.evId'), tooltip: translate('tableView.evIdTip') },
		keywordOr,
		EVENT_MUSIC
	),
	setFilterField(
		'advertisements.id',
		translate('tableView.evAdvertisementIds'),
		keywordOr,
		EVENT_MUSIC
	),
	setFilterField(
		'customer.events.id',
		{
			label: translate('tableView.evCustomerIds'),
			tooltip: translate('tableView.evCustomerIdsTipFilter'),
		},
		keywordOrExists,
		EVENT_MUSIC
	),
	setFilterField(
		'customer.events.processingStatus',
		translate('tableView.evProcessingStatus'),
		keywordOrAndExists,
		EVENT_MUSIC
	),
	setFilterField('name', translate('tableView.evName'), keywordOrAndStrictExists, EVENT_MUSIC),
	setFilterField('dates', translate('tableView.evDates'), datesRange, EVENT_MUSIC),
	setFilterField('admission.price', translate('tableView.evAdmission'), numberRange, EVENT_MUSIC),
	setFilterField('types', translate('tableView.evType'), tagList, EVENT_MUSIC),
	setFilterField('rating', translate('tableView.evRelevance'), ratingRange, EVENT_MUSIC),
	setFilterField(
		'description',
		translate('tableView.evDescription'),
		keywordOrAndExists,
		EVENT_MUSIC
	),
	setFilterField(
		'setlists.performer',
		translate('tableView.evSetlistsPerformer'),
		keywordOrAndStrictExists,
		EVENT_MUSIC
	),
	setFilterField(
		'setlists.songs.title',
		translate('tableView.evSetlistsSongTitle'),
		keywordOrAndStrictExists,
		EVENT_MUSIC
	),
	setFilterField(
		'setlists.songs.originalArtist',
		translate('tableView.evSetlistsOriginalArtist'),
		keywordOrAndStrictExists,
		EVENT_MUSIC
	),
	setFilterField(
		'artists.name',
		{ label: translate('tableView.evArtistName'), tooltip: translate('tableView.evArtistTip') },
		keywordOrAndStrictExists,
		EVENT_MUSIC
	),
	setFilterField('artists.score', translate('tableView.evArtistScore'), numberRange, EVENT_MUSIC),
	setFilterField(
		'lineup',
		{ label: translate('tableView.evLineup'), tooltip: translate('tableView.evLineupTip') },
		keywordOrAndStrictExists,
		EVENT_MUSIC
	),
	setFilterField('visitorCount', translate('tableView.evAttendees'), numberRange, EVENT_MUSIC),
	setFilterField('url', translate('tableView.evURL'), keywordOrExists, EVENT_MUSIC),
	setFilterField(
		'timestamp',
		{ label: translate('tableView.evTimestamp'), tooltip: translate('tableView.evTimestampTip') },
		dateRange,
		EVENT_MUSIC
	),
	setFilterField('isCancelled', translate('tableView.evIsCancelled'), booleanSelect, EVENT_MUSIC),
	setFilterField(
		'isLocationSameAsOrganizer',
		translate('tableView.evIsLocationSameAsOrganizer'),
		booleanSelect,
		EVENT_MUSIC
	),
	setFilterField(
		'advertisements.totalCount',
		translate('tableView.evAdCount'),
		numberRange,
		EVENT_MUSIC
	),
	setFilterField('organizersCount', translate('tableView.evOrgCount'), numberRange, EVENT_MUSIC),
	setFilterField(
		'selectedOrganizer.id',
		translate('tableView.evSelectedOrganizer'),
		keywordOrExists,
		EVENT_MUSIC
	),
	setFilterField(
		'invoice.invoiceNumber',
		translate('tableView.evInvoiceNumber'),
		keywordOrAndExists,
		EVENT_MUSIC
	),
	setFilterField(
		'invoice.itemNumber',
		translate('tableView.evItemNumber'),
		keywordOrAndExists,
		EVENT_MUSIC
	),
	setFilterField(
		'status.name',
		translate('tableView.evStatusName'),
		entityStatusSelect,
		EVENT_MUSIC
	),
	setFilterField(
		'status.username',
		translate('tableView.evStatusUsername'),
		userSelect,
		EVENT_MUSIC
	),
	setFilterField(
		'status.lastChangedAt',
		translate('tableView.evStatusLastChangedAt'),
		dateRange,
		EVENT_MUSIC
	),

	setFilterField(
		'location.id',
		{ label: translate('tableView.locId'), tooltip: translate('tableView.locIdTip') },
		keywordOrExists,
		LOCATION
	),
	setFilterField(
		'location.commercialId',
		translate('tableView.locCommercialId'),
		keywordOrAndExists,
		LOCATION
	),
	setFilterField(
		'location.matchedLocationIds',
		translate('tableView.locMatchedIds'),
		keywordOrAndStrictExists,
		LOCATION
	),
	setFilterField(
		'location.name',
		translate('tableView.locName'),
		keywordOrAndStrictExists,
		LOCATION
	),
	setFilterField(
		'location.address.street', // will search for street and house number
		translate('tableView.locStreet'),
		keywordOrAndStrictExists,
		LOCATION
	),
	setFilterField(
		'location.address.postalCode',
		translate('tableView.locZIP'),
		keywordOrExists,
		LOCATION
	),
	setFilterField(
		'location.address.city',
		translate('tableView.locCity'),
		keywordOrAndStrictExists,
		LOCATION
	),
	setFilterField(
		'location.address.region',
		translate('tableView.locRegion'),
		keywordOrAndStrictExists,
		LOCATION
	),
	setFilterField(
		'location.address.country',
		translate('tableView.locCountry'),
		keywordOrExists,
		LOCATION
	),
	setFilterField(
		'location.category',
		translate('tableView.locCategory'),
		keywordOrAndExists,
		LOCATION
	),
	setFilterField(
		'location.emailAddresses',
		translate('tableView.locMail'),
		keywordOrAndExists,
		LOCATION
	),
	setFilterField(
		'location.phoneNumbers',
		translate('tableView.locPhone'),
		keywordOrAndExists,
		LOCATION
	),
	setFilterField('location.urls', translate('tableView.locURL'), keywordOrAndExists, LOCATION),
	setFilterField('location.timestamp', translate('tableView.locTimestamp'), dateRange, LOCATION),

	setFilterField(
		'organizer.id',
		{ label: translate('tableView.orgId'), tooltip: translate('tableView.orgIdTip') },
		keywordOrExists,
		ORGANIZER
	),
	setFilterField(
		'organizer.commercialId',
		translate('tableView.orgCommercialId'),
		keywordOrAndExists,
		ORGANIZER
	),
	setFilterField(
		'organizer.matchedOrganizerIds',
		translate('tableView.orgMatchedIds'),
		keywordOrAndStrictExists,
		ORGANIZER
	),
	setFilterField(
		'organizer.name',
		translate('tableView.orgName'),
		keywordOrAndStrictExists,
		ORGANIZER
	),
	setFilterField(
		'organizer.address.street', // will search for street and house number
		translate('tableView.orgStreet'),
		keywordOrAndStrictExists,
		ORGANIZER
	),
	setFilterField(
		'organizer.address.postalCode',
		translate('tableView.orgZIP'),
		keywordOrExists,
		ORGANIZER
	),
	setFilterField(
		'organizer.address.city',
		translate('tableView.orgCity'),
		keywordOrAndStrictExists,
		ORGANIZER
	),
	setFilterField(
		'organizer.address.region',
		translate('tableView.orgRegion'),
		keywordOrAndStrictExists,
		ORGANIZER
	),
	setFilterField(
		'organizer.address.country',
		translate('tableView.orgCountry'),
		keywordOrExists,
		ORGANIZER
	),
	setFilterField(
		'organizer.category',
		translate('tableView.orgCategory'),
		keywordOrAndExists,
		ORGANIZER
	),
	setFilterField(
		'organizer.emailAddresses',
		translate('tableView.orgMail'),
		keywordOrAndExists,
		ORGANIZER
	),
	setFilterField(
		'organizer.phoneNumbers',
		translate('tableView.orgPhone'),
		keywordOrAndExists,
		ORGANIZER
	),
	setFilterField('organizer.urls', translate('tableView.orgURL'), keywordOrAndExists, ORGANIZER),
	setFilterField('organizer.timestamp', translate('tableView.orgTimestamp'), dateRange, ORGANIZER),
];

export default EventsFilter;
