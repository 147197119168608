import React from 'react';
import { Icon } from 'semantic-ui-react';

// css could be similar to:
// .open,
// .close {
//   color: #3f72af;
//   cursor: pointer;
//   white-space: pre;
//   float: right;
//   margin-right: 20px;
//   font-size: larger;
// }

const ToggleIcon = ({ open, onToggle }) =>
	open ? (
		<div className="open" onClick={onToggle}>
			<Icon name="chevron circle up" />
		</div>
	) : (
		<div className="close" onClick={onToggle}>
			<Icon name="chevron circle down" />
		</div>
	);

export default ToggleIcon;
