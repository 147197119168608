import React, { useRef, useEffect, useState } from 'react';
import {
	value2Blocks,
	blocks2Value,
	noop,
	validateEmail,
	validateURL,
	validatePhone,
	validateCurrency,
} from 'utils';
import { useTranslate } from 'customHooks';

import { Icon, Popup } from 'semantic-ui-react';
import { dropRightWhile } from 'lodash';
import { DeleteIcon, GrowingInput } from '.';
import classNames from 'classnames';
import './BlocksInput.scss';

const BlocksInput = ({
	inputType = 'String',
	isArray = false,
	value,
	onChange = noop,
	error,
	min,
	max,
	onBlur,
	className,
	...props
}) => {
	const [getValue, getBlocks] = isArray
		? [removeLastEmpties, v => v && [...v]]
		: [blocks2Value, value2Blocks];

	const [blocks, setBlocks] = useState([]);
	const blocksWrapperRef = useRef();
	const syntaxInputRef = useRef();
	const translate = useTranslate();

	const validateBlocks = getBlocksValidator(inputType);
	const validate = getValidator(inputType);

	if (blocks[blocks.length - 1] !== '') blocks.push('');

	const updateBlocks = () => setBlocks(getBlocks(value));

	const handleBlur = e => {
		updateBlocks();
		!!onBlur &&
			e?.currentTarget?.parentNode?.parentNode !== e?.relatedTarget?.parentNode?.parentNode &&
			onBlur(e);
	};

	const handleInputBlock = (i, value) => {
		blocks[i] = value;
		setBlocks([...blocks]);
		onChange(getValue(blocks), validateBlocks(blocks));
	};

	const handleDecomposeBlock = i => {
		const addedBlocks = value2Blocks(blocks[i]);
		blocks.splice(i, 1, ...addedBlocks);
		setBlocks([...blocks]);
		onChange(getValue(blocks), validateBlocks(blocks));
	};
	const handleDeleteBlock = i => {
		blocks.splice(i, 1);
		setBlocks([...blocks]);
		onChange(getValue(blocks), validateBlocks(blocks));
	};

	const handleDeleteAllBlocks = () => {
		setBlocks([]);
		onChange(getValue([]), true);
	};

	const focusLast = () => {
		blocksWrapperRef.current && blocksWrapperRef.current.lastChild.firstChild.focus();
		syntaxInputRef.current && syntaxInputRef.current.focus();
	};

	const handleClickBlocksInput = ({ target }) =>
		((target && target.className) || '').includes('blocks-wrapper') && focusLast();

	useEffect(() => {
		value !== getValue(blocks) && setBlocks(getBlocks(value));
		// eslint-disable-next-line
	}, [value]); // only invoke useEffect-function when 'value' has changed

	// validating all blocks from growing inputs, in order to apply correct styling
	error |= validate && !blocks.every(block => block === '' || validate(block));

	return (
		<div
			className={classNames('BlocksInput', { error }, className)}
			onClick={handleClickBlocksInput}
			{...props}>
			<div className="blocks-wrapper" data-test="BlocksInput" ref={blocksWrapperRef}>
				{blocks.map((block, i) => (
					<GrowingInput
						className="block"
						data-test="block"
						key={i}
						value={block}
						onChange={(_, { value }) => handleInputBlock(i, value)}
						onBlur={handleBlur}
						icon
						type={{ Number: 'number', Currency: 'currency' }[inputType] || undefined}
						widthOffset={inputType === 'Number' ? WIDTH_OFFSET_NUMBER : WIDTH_OFFSET_STRING}
						maxWidth={MAX_WIDTH}
						isWidthEmpty
						error={block !== '' && validate && !validate(block)}
						{...{ min, max }}>
						<input />
						<Icon
							name="remove"
							data-test="delete"
							color="red"
							link
							onClick={() => handleDeleteBlock(i)}
						/>
						{block.trim().includes(' ') && inputType !== 'Phone' && (
							<Popup
								position="top right"
								mouseEnterDelay={200}
								trigger={
									<Icon
										name="magic"
										data-test="decompose"
										link
										onClick={() => handleDecomposeBlock(i)}
									/>
								}>
								{translate('filters.decompose')}
							</Popup>
						)}
					</GrowingInput>
				))}
			</div>
			{blocks.length > 1 && (
				<DeleteIcon className="deleteAll" onClick={handleDeleteAllBlocks} data-test="deleteAll" />
			)}
		</div>
	);
};

const removeLastEmpties = blocks => dropRightWhile(blocks, block => block === '');

const getValidator = type =>
	({
		Email: validateEmail,
		URL: validateURL,
		Phone: validatePhone,
		Currency: validateCurrency,
	}[type]);

const getBlocksValidator = type => {
	const validate = getValidator(type);
	if (!validate) return () => true;
	return blocks => blocks.every(block => block === '' || validate(block));
};

const MAX_WIDTH = 298;
const WIDTH_OFFSET_STRING = 12;
const WIDTH_OFFSET_NUMBER = 32; // additional width for increase / decrease buttons

export default BlocksInput;
