import gql from 'graphql-tag';

const adType = ({ hasFullAdProps }) => `{
	id
	url
	${
		hasFullAdProps
			? `admission {
				currency
				description
				price
			}
			artists {
				id
				name
				score
			}
			description
			endDate
			isCancelled
			isLocationSameAsOrganizer
			lineup
			name
			organizers {
				id
			}
			pdfId
			startDate
			timestamp
			types
			version
			visitorCount`
			: ''
	}
}`;

const eventType = ({ hasFullAdProps }) => `{
	timestamp
	admission {
		currency
		description
		price
	}
	lineup
	artists {
		id
		name
		score
	}
	endDate
	id
	isCancelled
	isLocationSameAsOrganizer
	isSelectable
	customLeaflet {
		id
		name
		timestamp
		username
	}
	customer {
		events {
			id
			processingStatus
		}
	}
	location {
		address {
			city
			country
			postalCode
			street
			houseNumber
			region
		}
		category
		emailAddresses
		id
		oldIds
		name
		commercialId
		matchedLocationIds
		phoneNumbers
		urls
		timestamp
		commercialId
		locationStatus {
			id
			name
			user {
				username
			}
			lastChangedAt
		}
	}
	name
	organizers {
		id
		oldIds
		address {
			city
			country
			postalCode
			street
			houseNumber
			region
		}
		emailAddresses
		name
		phoneNumbers
		category
		urls
		timestamp
		commercialId
		matchedOrganizerIds
		organizerStatus {
			id
			name
			user {
				username
			}
			lastChangedAt
		}
		userLock {
			isLocked
			timestamp
			user {
				username
			}
		}
	}
	selectedOrganizer {
			id
	}
	oldIds
	rating
	setlists {
		results {
			artist
			songs {
				originalArtist
				title
			}
			timestamp
			url
		}
		totalCount
	}
	startDate
	state
	countOfExported
	types
	version
	visitorCount
	advertisements {
		totalCount
		results ${adType({ hasFullAdProps })}
	}
	userLock {
		isLocked
		timestamp
		user {
			username
		}
	}
	organizersCount
	eventStatus{
		id
		user {
			username
		}
		name
		lastChangedAt
	}
	invoice {
		invoiceNumber
		itemNumber
	}
}`;

export const LOGIN = gql`
	query login($username: String!, $password: String!, $loginType: LoginTypeEnum) {
		login(username: $username, password: $password, loginType: $loginType) {
			id
			firstName
			lastName
			jwtToken {
				expires
				token
			}
			username
			company {
				availableHistory
				eventTags
				id
				name
				companyStatuses {
					statusId
					statusTypeName
					entityType
					statusName
				}
			}
		}
	}
`;

export const LOGOUT = gql`
	query logout {
		logout
	}
`;

export const EVENTS = gql`
    query searchEvents($max: Int, $offset: Int, $sortings: [Sorting], $searchQuery: SearchQueryInput!, $includeEventAds: Boolean = false) {
        searchEventsBySearchParams(max: $max, offset: $offset, sortings: $sortings, searchQuery: $searchQuery, includeEventAds: $includeEventAds) {
            totalCount
            results ${eventType({ hasFullAdProps: false })}
        }
    }
`;

export const EVENT = gql`
    query getEvent($id: ID!) {
        getEvent(id: $id) ${eventType({ hasFullAdProps: true })}
    }`;

export const LOCATIONS = gql`
	query searchLocations(
		$max: Int
		$offset: Int
		$sortings: [Sorting]
		$searchQuery: LocationSearchQueryInput!
	) {
		searchLocations(max: $max, offset: $offset, sortings: $sortings, searchQuery: $searchQuery) {
			totalCount
			results {
				address {
					city
					country
					postalCode
					street
					houseNumber
					region
				}
				category
				customerLocations {
					statisticPerYear {
						count
						differenceToMeslisEventCount
						year
					}
				}
				emailAddresses
				id
				oldIds
				matchedLocationIds
				name
				customerStatus
				phoneNumbers
				urls
				commercialId
				timestamp
				statistic {
					eventCountIrrelevant
					eventCountRelevant
					eventCountPerYear {
						year
						count
					}
					eventCountTotal
				}
				locationStatus {
					id
					user {
						username
					}
					name
					lastChangedAt
				}
			}
		}
	}
`;

export const LOCATIONS_COUNT = gql`
	query locationsCount($max: Int, $searchQuery: LocationSearchQueryInput!) {
		searchLocations(max: $max, searchQuery: $searchQuery) {
			totalCount
		}
	}
`;

export const ORGANIZERS = gql`
	query searchOrganizers(
		$max: Int
		$offset: Int
		$sortings: [Sorting]
		$searchQuery: OrganizerSearchQueryInput!
	) {
		searchOrganizers(max: $max, offset: $offset, sortings: $sortings, searchQuery: $searchQuery) {
			totalCount
			results {
				address {
					city
					country
					postalCode
					street
					houseNumber
					region
				}
				customerOrganizers {
					statisticPerYear {
						count
						differenceToMeslisEventCount
						year
					}
				}
				emailAddresses
				id
				oldIds
				matchedOrganizerIds
				name
				customerStatus
				phoneNumbers
				urls
				commercialId
				timestamp
				statistic {
					eventCountIrrelevant
					eventCountRelevant
					eventCountPerYear {
						year
						count
					}
					eventCountTotal
				}
				category
				version
				userLock {
					isLocked
					timestamp
					user {
						username
					}
				}
				organizerStatus {
					id
					user {
						username
					}
					name
					lastChangedAt
				}
			}
		}
	}
`;

export const ORGANIZERS_COUNT = gql`
	query organizersCount($max: Int, $searchQuery: OrganizerSearchQueryInput!) {
		searchOrganizers(max: $max, searchQuery: $searchQuery) {
			totalCount
		}
	}
`;

export const ME = gql`
	query me {
		me {
			id
			firstName
			lastName
			username
			jwtToken {
				expires
				token
			}
			company {
				availableHistory
				eventTags
				id
				name
				companyStatuses {
					statusId
					statusTypeName
					entityType
					statusName
				}
			}
		}
	}
`;

export const LEAFLET = gql`
	query getPreviewList(
		$offset: Int
		$max: Int
		$searchQuery: SearchQueryInput!
		$includeEventAds: Boolean = false
	) {
		searchEventsBySearchParams(
			max: $max
			offset: $offset
			sortings: [{ field: "customLeaflet.timestamp", orderBy: DESC }]
			searchQuery: $searchQuery
			includeEventAds: $includeEventAds
		) {
			totalCount
			results {
				name
				id
				isSelectable
				version
				customLeaflet {
					id
				}
			}
		}
	}
`;

export const EXPORTS = gql`
	query allExports {
		getAllExports {
			createdAt
			description
			downloadCount
			filename
			id
			isAvailable
		}
	}
`;

export const USERS_OF_COMPANY = gql`
	query allUsersForCompany {
		getAllUsersForCompany {
			id
			firstName
			lastName
			username
			company {
				id
				name
			}
		}
	}
`;

export const CUSTOMLEAFLETS = gql`
	query allCustomLeaflets {
		getAllLeaflets {
			id
			name
			createdAt
			eventTag
			description
			creator {
				id
				firstName
				lastName
				username
				company {
					id
					name
				}
			}
			editors {
				id
				firstName
				lastName
				username
				company {
					id
					name
				}
			}
			updatedAt
		}
	}
`;

export const LEAFLET_NAMES = gql`
	query leafletNames {
		getAllLeaflets {
			id
			name
			creator {
				id
				username
			}
		}
	}
`;

export const LEAFLETS_FOR_COMPANY = gql`
	query leafletsForCompany {
		getAllLeafletsForCompany {
			id
			name
			creator {
				id
				username
				firstName
				lastName
			}
		}
	}
`;

export const SUBSCRIPTIONS = gql`
	query allSavedQueries {
		getAllSavedQueries {
			id
			name
			type
			createdAt
			user {
				id
				username
			}
			sharedUsers {
				id
			}
			isHiddenForMe
			searchQueryAsText
		}
	}
`;

export const SUBSCRIPTIONS_NAMES = gql`
	query allSavedQueryNames {
		getAllSavedQueries {
			id
			name
			type
		}
	}
`;

export const EVENTS_COUNT = gql`
	query eventsCount($max: Int, $searchQuery: SearchQueryInput!, $includeEventAds: Boolean = false) {
		searchEventsBySearchParams(
			max: $max
			searchQuery: $searchQuery
			includeEventAds: $includeEventAds
		) {
			totalCount
		}
	}
`;
export const EVENTS_DETAIL = gql`
    query searchEventsDetail(
        $max: Int
        $offset: Int
        $sortings: [Sorting]
        $searchQuery: SearchQueryInput!
        $includeEventAds: Boolean = true
    ) {
        searchEventsBySearchParams(max: $max, offset: $offset, sortings: $sortings, searchQuery: $searchQuery, includeEventAds: $includeEventAds) {
            totalCount
            results ${eventType({ hasFullAdProps: true })}
        }
    }
`;

export const USER_SETTINGS = gql`
	query userSettings {
		getUserSettings {
			dateFormat
			defaultFetchSize
			languageCode
		}
	}
`;
