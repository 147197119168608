import React from 'react';
import { useTranslate } from 'customHooks';
import { Button, Checkbox } from 'semantic-ui-react';

import storage from 'storage';

import './SetListToolArea.scss';

const SetListToolArea = ({ isExpanded, setIsExpanded, setOnlyCovered, dataLength }) => {
	const translate = useTranslate();
	const updateIsExpanded = bool => {
		setIsExpanded([].concat(isExpanded).fill(bool));
		storage.setSetListExpanded(bool);
	};

	const showAll = () => updateIsExpanded(true);
	const hideAll = () => updateIsExpanded(false);

	// only check the entries in isExpanded for the number of currently shown setlists
	// showAll / hideAll is disabled if there are no false / true values
	const isDisabled = bool => !isExpanded.slice(0, dataLength).includes(bool);

	return (
		<div className="SetListToolArea">
			<Button.Group size="mini">
				<Button basic color="grey" onClick={showAll} disabled={isDisabled(false)}>
					{translate('actions.showAll')}
				</Button>

				<Button basic color="grey" onClick={hideAll} disabled={isDisabled(true)}>
					{translate('actions.hideAll')}
				</Button>
			</Button.Group>
			<div className="right">
				<div className="label">{translate('actions.showOnlyCoveredSongs')}</div>
				<Checkbox toggle onChange={(e, { checked }) => setOnlyCovered(checked)} />
			</div>
		</div>
	);
};

export default SetListToolArea;
