import template from 'utils/stringTemplates';

export default {
	username: 'Username',
	password: 'Password',

	login: 'Login',
	logout: 'Logout',

	statusPage: 'App Status',

	documentation: 'Documentation',
	anyField: 'Any field',

	detailView: {
		event: 'Event',
		ad: 'Ad',
		ads: 'Ads',
		adsSuffix: 's',
		id: 'ID',
		ids: 'IDs',
		eventType: 'Tags',
		location: 'Location',
		organizers: 'Organizers',
		organizer: 'Organizer',
		timestamp: 'Timestamp',
		date: 'Date',
		from: 'from',
		to: 'to',
		invoiceNumber: 'Invoice Number',
		invoiceNumberTip:
			'This field must be filled along with the "Item Number" field. After giving a initial value, it can be edited individually. When removing the value, the "Item Number" field will be removed along with it.',
		itemNumber: 'Item Number',
		itemNumberTip:
			'This field must be filled along with the "Invoice Number" field. After giving a initial value, it can be edited individually. When removing the value, the "Invoice Number" field will be removed along with it.',
		statusName: 'Status',
		statusUsername: 'Status assigned by',
		statusLastChangedAt: 'Status last changed at',
		admission: 'Admission',
		artist: 'Artist',
		artists: 'Artists',
		lineup: 'Lineup',
		attendees: 'Attendees',
		setlists: 'Setlists',
		name: 'Name',
		evCustomerIds: 'Customer IDs',
		evCustomerIdsTip:
			'Event ID (status) used by customer, corresponding search fields are "Event - Customer status" and "Event - Customer IDs"',

		address: 'Address',
		postalCode: 'ZIP',
		street: 'Street',
		houseNumber: 'House number',
		city: 'City',
		region: 'Region',
		country: 'Country',

		category: 'Category',
		freeInput: 'Free input',
		phone: 'Phone numbers',
		websites: 'Websites',
		url: 'URL',
		mail: 'Emails',
		commercialId: 'Commercial ID',
		locMatchedIds: 'Customer IDs',
		locCommercialId: 'Commercial ID',
		setlistTable: {
			songName: 'Song title',
			artist: 'Original artist',
		},
		orgMatchedIds: 'Customer IDs',
		orgCommercialId: 'Commercial ID',
		isCancelled: 'Cancelled',
		isLocationSameAsOrganizer: 'Location equals Organizer',
		navigationCounter: template`#${0} of ${1}`,
		viewPDF: 'View PDF',
		uploadEvidenceImages: 'Upload evidence images',
		viewDescription: 'View description',
		editDescription: 'Edit description',
		viewSetlists: 'View setlists',
	},

	genDetailView: {
		evTimestamp: 'Event - Timestamp',
		evDate: 'Event - Date',
		evInvoiceNumber: 'Event - Invoice number',
		evItemNumber: 'Event - Item number',
		evStatusName: 'Event - Status',
		evStatusUsername: 'Event - Status assigned by',
		evStatusLastChangedAt: 'Event - Status last changed at',
		evCustomerIds: 'Event - Customer IDs',
		evAdmission: 'Event - Admission',
		evArtists: 'Event - Artists',
		evLineup: 'Event - Lineup',
		evAttendees: 'Event - Attendees',
		evTags: 'Event - Tags',
		evUrl: 'Event - URL',
		evCancelled: 'Event - Cancelled',
		evLocationEqualsOrganizer: 'Event - Location equals Organizer',

		locName: 'Location - Name',
		locTimestamp: 'Location - Timestamp',
		locStatusName: 'Location - Status',
		locStatusUsername: 'Location - Status assigned by',
		locStatusLastChangedAt: 'Location - Status last changed at',
		locCustomerIds: 'Location - Customer IDs',
		locCommercialId: 'Location - Commercial ID',
		locAddress: 'Location - Address',
		locCategory: 'Location - Category',
		locEmails: 'Location - Emails',
		locPhoneNumbers: 'Location - Phone Numbers',
		locWebsites: 'Location - Websites',

		orgName: 'Organizer - Name',
		orgTimestamp: 'Organizer - Timestamp',
		orgStatusName: 'Organizer - Status',
		orgStatusUsername: 'Organizer - Status assigned by',
		orgStatusLastChangedAt: 'Organizer - Status last changed at',
		orgCustomerIds: 'Organizer - Customer IDs',
		orgCommercialId: 'Organizer - Commercial ID',
		orgAddress: 'Organizer - Address',
		orgCategory: 'Organizer - Category',
		orgEmails: 'Organizer - Emails',
		orgPhoneNumbers: 'Organizer - Phone Numbers',
		orgWebsites: 'Organizer - Websites',
	},

	tableView: {
		info: 'Info', // for filter
		infoCollaboration: 'Info - Collaboration',
		infoIsLocked: 'Info - Event locked', // for csv export
		infoLockingUser: 'Info - Locked by', // for csv export
		infoState: 'Info - State',
		infoExportCount: 'Info - Export count',
		infoLeaflet: 'Info - Leaflet',
		infoLastChangingUser: 'Info - Last changing user',
		infoLastChangingUserTip:
			'Last user moving that event to a leaflet, exporting, hiding or marking as "No Music Event".',
		infoLastAction: 'Info - Last action',
		infoLastActionTip:
			'Last time when the event was moved to a leaflet, exported, hidden or marked as "No Music Event".',

		evId: 'Event - ID',
		evIdTip:
			'An event might have previously been assigned to different IDs ' +
			'but it can still be found if you filter for one of these old IDs.\n\n' +
			'The filtered old ID is written ' +
			'as grayed out text behind the current ID.\n\n' +
			'To see a complete list of old IDs mouse over ' +
			'the info icon in the relevant cells. ',
		evOldIds: 'This event was previously available under the following IDs:',

		locIdTip:
			'A location might have previously been assigned to different IDs ' +
			'but it can still be found if you filter for one of these old IDs.\n\n' +
			'The filtered old ID is written ' +
			'as grayed out text behind the current ID.\n\n' +
			'To see a complete list of old IDs mouse over ' +
			'the info icon in the relevant cells. ',

		locOldIds: 'This location was previously available under the following IDs:',

		orgIdTip:
			'An organizer might have previously been assigned to different IDs ' +
			'but it can still be found if you filter for one of these old IDs.\n\n' +
			'The filtered old ID is written ' +
			'as grayed out text behind the current ID.\n\n' +
			'To see a complete list of old IDs mouse over ' +
			'the info icon in the relevant cells. ',

		orgOldIds: 'This organizer was previously available under the following IDs:',
		evStatusName: 'Event - Status',
		evStatusUsername: 'Event - Status assigned by',
		evStatusLastChangedAt: 'Event - Status last changed at',
		evInvoiceNumber: 'Event - Invoice number',
		evItemNumber: 'Event - Item number',

		evName: 'Event - Name',
		evStartDate: 'Event - Start date',
		evStartTime: 'Event - Start time',
		evEndDate: 'Event - End date',
		evEndTime: 'Event - End time',
		evDates: 'Event - Dates',
		evAdmission: 'Event - Admission',
		evType: 'Event - Tags',
		evRelevance: 'Event - Music Score',
		evSetlists: 'Event - Setlists',
		evSetlistsArtist: 'Event - Setlists',
		evSetlistsSongTitle: 'Event - Setlists - Song title',
		evSetlistsOriginalArtist: 'Event - Setlists - Original artist',
		evSetlistsPerformer: 'Event - Setlists - Performer',
		evArtist: 'Event - Artists',
		evArtistTip: 'Artists mentioned in the event description',
		evArtistName: 'Event - Artist name',
		evArtistScore: 'Event - Artists score',
		evLineup: 'Event - Lineup',
		evLineupTip: 'Performers automatically extracted from meta data or event description',
		evAttendees: 'Event - Attendees',
		evAdvertisementIds: 'Event - Advertisement IDs',
		evDescription: 'Event - Description',
		evURL: 'Event - URL',
		evCustomerIds: 'Event - Customer IDs',
		evCustomerIdsTip:
			'Event ID (status) used by customer, corresponding search fields are "Event - Customer status" and "Event - Customer IDs"',
		evCustomerIdsTipFilter: 'Event ID used by customer',
		evCustomerIdsStatus: 'Event - Customer IDs (Customer status)', // used in csv export because no tooltip in csv export
		evTimestamp: 'Event - Timestamp',
		evTimestampTip:
			'The date of creation or last update of the event (in MESLIS database for time zone UTC+1).',
		evProcessingStatus: 'Event - Customer status',
		evIsCancelled: 'Event - Cancelled',
		evIsLocationSameAsOrganizer: 'Event - Location equals Organizer',
		evAdCount: 'Event - Number of Advertisements',
		evOrgCount: 'Event - Number of Organizers',
		evSelectedOrganizer: 'Event - Selected Organizer',

		locStatusName: 'Location - Status',
		locStatusUsername: 'Location - Status assigned by',
		locStatusLastChangedAt: 'Location - Status last changed at',
		locId: 'Location - ID',
		locMatchedId: 'Location - Customer ID',
		locMatchedIds: 'Location - Customer IDs',
		locCommercialId: 'Location - Commercial ID',
		locCustomerStatus: 'Location - Customer status',
		locName: 'Location - Name',
		locStreet: 'Location - Street',
		locCity: 'Location - City',
		locRegion: 'Location - Region',
		locCountry: 'Location - Country',
		locZIP: 'Location - ZIP',
		locPhone: 'Location - Phone numbers',
		locCategory: 'Location - Category',
		locURL: 'Location - Websites',
		locTimestamp: 'Location - Timestamp',
		locMail: 'Location - Emails',
		locEventCountTotal: 'Location - Total number of events',
		locEventCountRelevant: 'Location - Number of music events found',
		locEventCountIrrelevant: 'Location - Number of no music events found',
		locStatisticEventCountPerYear: 'Location - MESLIS events per year',
		locCustomerEventCountPerYear: 'Location - Customer events per year',

		orgStatusName: 'Organizer - Status',
		orgStatusUsername: 'Organizer - Status assigned by',
		orgStatusLastChangedAt: 'Organizer - Status last changed at',
		orgId: 'Organizer - ID',
		orgMatchedIds: 'Organizer - Customer IDs',
		orgCommercialId: 'Organizer - Commercial ID',
		orgCustomerStatus: 'Organizer - Customer status',
		orgName: 'Organizer - Name',
		orgStreet: 'Organizer - Street',
		orgCity: 'Organizer - City',
		orgRegion: 'Organizer - Region',
		orgCountry: 'Organizer - Country',
		orgZIP: 'Organizer - ZIP',
		orgPhone: 'Organizer - Phone numbers',
		orgCategory: 'Organizer - Category',
		orgURL: 'Organizer - Websites',
		orgTimestamp: 'Organizer - Timestamp',
		orgMail: 'Organizer - Emails',
		orgEventCountTotal: 'Organizer - Total number of events',
		orgEventCountRelevant: 'Organizer - Number of music events found',
		orgEventCountIrrelevant: 'Organizer - Number of no music events found',
		orgStatisticEventCountPerYear: 'Organizer - MESLIS events per year',
		orgCustomerEventCountPerYear: 'Organizer - Customer events per year',
	},

	genTableView: {
		statusName: 'Status',
		statusUsername: 'Status assigned by',
		statusLastChangedAt: 'Status last changed at',
		collaboration: 'Collaboration',
		id: 'ID',
		customerId: 'Customer ID',
		customerStatus: 'Customer status',
		phoneNumbers: 'Phone numbers',
		emailAddresses: 'Emails',
		url: 'Websites',
		timestamp: 'Timestamp',
		region: 'Region',
		commercialId: 'Commercial ID',
		category: 'Category',
		type: 'Type',
		cusEvPerYear: 'Customer events per year',
		cusEvPerYearTip1:
			'Annual event count of customer compared to MESLIS data using the following format:',
		cusEvPerYearTip2: 'year: customer events/ MESLIS events (difference)',

		evPerYear: 'MESLIS events per year',
		evPerYearTip1: 'Annual event count from MESLIS data using the following format:',
		evPerYearTip2: 'year: MESLIS events',
		address: {
			street: 'Street',
			city: 'City',
			postalCode: 'ZIP',
			country: 'Country',
		},
		statistic: {
			eventCountIrrelevant: 'Number of no music events found',
			eventCountRelevant: 'Number of music events found',
			eventCountTotal: 'Total number of events',
			evCountTotalTip:
				'The number shown is the total amount over all years, which may differ from the contractually defined time frame',
		},
	},

	queryTableView: {
		type: 'Type',
		id: 'ID',
		name: 'Name',
		createdAt: 'Created at',
		filters: 'Filters',
		hits: 'Hits',
		createdBy: 'Created by',
		sharedWith: 'Shared with',
		actions: 'Actions',

		new: 'new',

		unshareRequired:
			'If you want to delete this saved search you first have to unshare it for all users.',

		delete: 'Delete',

		searchButton: {
			event: 'Search Events',
			location: 'Search Locations',
			organizer: 'Search Organizers',
		},

		deleteButton: {
			deleted: 'Deleted',
			failed: 'Failed',
			delete: 'Delete',
			error: 'Error',
			deleteQuery: 'Are you sure you want to delete this search?',
		},

		showButton: {
			show: 'Show',
			hide: 'Hide',
			showUnhidden: 'Show unhidden searches',
			showHidden: 'Show hidden searches',
		},
	},

	selectionsTableView: {
		filename: 'Filename',
		createdAt: 'Created at',
		description: 'Description at',
		downloadCount: 'Downloads',
		actions: 'Actions',
		mySelections: 'My Selections',
	},

	leaflets: {
		create: 'Create new Leaflet',
		edit: 'Edit',
		update: 'Update Leaflet',
		delete: 'Delete Leaflet',
		view: 'View',
		shared: 'Shared',
		conflictTip1:
			'The Conflict Leaflet contains events for which MESLIS cannot decide on which leaflet to store them.\n\n' +
			'This happens in rare cases when MESLIS merges several events originating from different leaflets.\n\n' +
			'It is shared by all users of the company. Manually moving events to this leaflet is not possible.',
		conflictTip2:
			'Please, review the events on the Conflict Leaflet and move them to the correct leaflet manually.',
		header: {
			create: 'Create new Leaflet',
			edit: 'Edit Leaflet',
		},
		leafName: 'Name',
		leafId: 'ID',
		leafDate: 'Creation Date',
		leafTag: 'Tag',
		leafDescription: 'Description',
		leafCreator: 'Creator',
		leafOwner: 'Owner',
		leafNameExists: 'A leaflet with this name already exists.',
		leafNameSpaces: 'This leaflet name consists only of spaces.',

		formEditor: 'Choose Collaborator',
		maxHeader: 'Maximum Leaflets Reached',
		maxLeaflets:
			'You have reached the maximum number of leaflets available to one user. If you want to create a new leaflet delete another leaflet.',
		sharedTooltip: 'Shared with other users',
		new: 'new',

		items: 'item(s)',

		created: 'Created',
		edited: 'Edited',
		removed: 'Removed',
		clear: 'Clear',
		clearMsg: template`Clear leaflet '${0}'`,
		cancel: 'Cancel',
		removeAllEventsMsg: template`Do you really want to remove all events from the leaflet '${0}'?`,
	},

	actions: {
		actions: 'Actions',
		moveTo: template`Move to "${0}"`,
		moveToLeaflet: 'Move to leaflet',
		leaflet: 'Leaflet',
		discard: 'Hide',
		scrap: 'No Music Event',
		drop: 'Drop',
		keep: 'Keep in same Leaflet',
		merge: 'Merge',
		displayColumns: 'Display and hide columns',

		eventStatusBulkEdit: 'Set status',

		csvExport: 'CSV-Export',
		expandAll: 'Expand all',
		collapseAll: 'Collapse all',
		collapse: '...less',
		expand: '...more',

		cancel: 'Cancel',
		reset: 'Reset',
		ok: 'Ok',
		download: 'Download',
		stillProcessing: 'Still processing',
		undo: 'Undo',
		detail: 'Detail',
		table: 'Table',
		showAll: 'Show All',
		hideAll: 'Hide All',
		showOnlyCoveredSongs: 'show only covered songs',

		error: 'Error',
		done: 'Done',
		fail: 'Fail',
		saveSearch: 'Save Search',

		selectAll: 'Select all',
		deselectAll: 'Deselect all',

		deleteAll: 'Delete all',
		noGrouping: 'No Grouping',
		groupingByField: 'Grouping by field',
		groupingByAction: 'Grouping by action',

		changeToEditMode: 'Change to mode "edit event"',
		leaveEditMode: 'Leave mode "edit event"',
		instructionsEditMode: 'In order to change to edit mode, you must first lock the event. ',
		warningEditMode: 'Note: You can not edit events locked by other users.',
		createNewEvent: 'Create event',
		resetCreateUpdateEvent: 'Discard changes',
		dragMode: 'Move or copy to another event',

		createNewLocation: 'Create location',
		createNewOrganizer: 'Create organizer',
		selectExistingLocation: 'Select existing location',
		selectExistingOrganizer: 'Select existing organizer',
		switchTo: template`Switch to "${0}"`,

		searchLocationByCustomerId: 'Search for existing Location by Customer Id',
		searchLocationByCommercialId: 'Search for existing Location by Commercial Id',
		searchOrganizerByCustomerId: 'Search for existing Organizer by Customer Id',
		searchOrganizerByCommercialId: 'Search for existing Organizer by Commercial Id',

		lock: {
			event: 'Lock event',
			organizer: 'Lock organizer',
		},
		locked: {
			event: 'Event locked by another user',
			organizer: 'Organizer locked by another user',
		},
		unlock: {
			event: 'Unlock event',
			organizer: 'Unlock organizer',
		},

		createLocation: 'Create location',
		createOrganizer: 'Create organizer',
		requiredFields: 'Fields marked with * are required.',
		updateLocation: 'Edit location',
		updateOrganizer: 'Edit organizer',

		redirectToLocation: 'Open step "Select" of location on new tab.',
		redirectToOrganizer: 'Open step "Select" of organizer on new tab.',
	},

	filters: {
		filters: 'Filters',
		add: 'Add',
		confirm: 'Confirm',
		keyword: {
			contains: 'contains',
			doesntContain: "doesn't contain",
			containsAll: 'contains all',
			containsOneOf: 'contains one of',
			containsOnly: 'contains only',
			exists: 'exists',
			doesntContainAll: "doesn't contain all",
			doesntContainOneOf: "doesn't contain one of",
			doesntContainOnly: "doesn't contain only",
			doesntExist: "doesn't exist",
		},
		decompose: 'Splits phrase into blocks containing only one word.',
		operators: {
			atLeast: 'at least',
			equals: 'equals',
			atMost: 'at most',
			between: 'between',
			between_and: 'and',
			underLicensedCustomer: 'less than MESLIS events by',
			underLicensedCustomerInfo:
				'Customer events per year are less than MESLIS events per year' +
				' and the difference is at least a defined tolerance.',
			overLicensedCustomer: 'more than MESLIS events by',
			overLicensedCustomerInfo:
				'Customer events per year are more than MESLIS events per year' +
				' and the difference is at least a defined tolerance.',
		},
		count: {
			tolerance: 'tolerance',
			number: 'number',
		},
		tags: {
			liveMusic: 'live music',
			backgroundMusic: 'background music',
			djMusic: 'dj music',
		},
		status: {
			newLocation: 'new location',
			newOrganizer: 'new organizer',
			changedOwner: 'changed owner',
			known: 'known',
		},
		boolean: {
			true: 'yes',
			false: 'no',
		},
		year: {
			inYear: 'in year',
			betweenYears: 'between years',
			betweenYears_and: 'and',
			sinceYear: 'since year',
			untilYear: 'until year',
			forAllYears: 'for all years',
		},
		info: {
			lastChangingUser: 'last changing user',
			leaflet: 'leaflet',
			lastChange: 'last change',
			between: 'between',
			between_and: 'and',
			from: 'from',
			to: 'to',
		},
		dateRange: {
			startDate: 'Start date',
			endDate: 'End date',
			start: 'Start',
			end: 'End',
		},
		range: {
			from: 'from',
			to: 'to',
			year: 'year',
			fromYear: 'from year',
			toYear: 'to year',
		},
		collaboration: 'Collaboration',
		chooseLocking: 'Choose a locking',
		chooseUsers: 'Choose one or more users',
		collaborationOptions: {
			any: 'Any',
			unlocked: 'Unlocked',
			locked: 'Locked',
			lockedByMe: 'Locked by me',
			lockedByOtherUsers: 'Locked by other users',
			lockedByFollowingUsers: 'Locked by following users ...',
		},
	},

	genericEventFilters: {
		filters: 'Filters',
		relevance: 'Music Score',
		relevances: {
			any: 'Any',
			relevant: "Evaluated as 'Music Event'",
			irrelevant: "Evaluated as 'No Music Event'",
		},
		year: 'Year',
		any: 'Any',
		status: 'Status',
		states: {
			NEW: 'New',
			IN_PROGRESS: 'In Process',
			EXPORTED: 'Exported',
			DISCARDED: 'Hidden',
			SCRAPPED: "Marked as 'No Music Event'",
		},
	},

	genericPager: {
		location: 'Location',
		organizer: 'Organizer',
		loading: 'Loading...',
		newEvents: template`${0} new events`,
	},

	resetPassword: {
		modalHeader: 'Request password reset',
		modalDesc: 'To request a password reset, please enter your username.',
		failMessage: 'The username you entered does not exist, please check your spelling.',
		failResetMessage:
			'The password reset failed. Please try again or request a new password request link on app.meslis',
		failTokenHeader: 'Your reset link has failed or expired.',
		failTokenMessage:
			'To request a new reset link start the reset process from the login page of the MESLIS WebApp.',
		successMessage:
			'You successfully requested a password reset, please check your email for confirmation.',
		confirmButton: 'Request reset',
		triggerButton: 'Request a password reset',
		reset: 'Reset Password',
		change: 'Change Password',
		oldPassword: 'old password',
		passwordChangeHeader: 'Change your MESLIS Web App password here',
		passwordChangeMessage: template`Please note the password for the MESLIS Help Center will not be affected by this change. If you want to change that password too go to your profile security settings in the ${0}.`,
		passwordResetHeader: 'Change your MESLIS Web App password here',
		passwordResetMessage: template`Please note the password for the MESLIS Help Center will not be affected by this change. If you want to change that password too go to your profile security settings in the ${0}.`,
		newPassword: 'new password',
		validatePassword: 'verify new password',
		setNewPassword: 'Set new password',
		strength:
			'Your password must meet the following requirements:\n' +
			'\t\u2022 minimum length of 12 characters\n' +
			'\t\u2022 at least one number [0-9]\n' +
			'\t\u2022 at least one upper case letter [A-Z]\n' +
			'\t\u2022 at least one lower case letter [a-z]\n' +
			'\t\u2022 at least one special character of &-_[]`~|@$%^?!:.{}',
	},

	userSettings: {
		changePassword: 'Change Password',
		table: 'Table View',
		defaultFetchSize: 'Number of added items when scrolling down',
		headerReset: 'Reset column order to default',
		eventsReset: "Table 'Search Events'",
		locationsReset: "Table 'Search Locations'",
		organizersReset: "Table 'Search Organizers'",
		reset: 'Reset',
	},

	drag: {
		moveEventAd: template`Move event ad ${0}`,
		moveEventAdDescription: template`of id ${0} to another event.`, // in English same text, but not in other languages
		moveEvent: 'Move event',
		moveEventDescription: template`of id ${0} to another event.`,
		copyEventAd: template`Copy event ad ${0}`,
		copyEventAdDescription: template`of id ${0} to another event.`,
		copyEvent: 'Copy event',
		copyEventDescription: template`of id ${0} to another event.`,
		allAdsMoved: 'All ads of this event are moved to the other event.',
		allAdsCopied: 'All ads of this event are copied to the other event.',
		dragThisBox: 'Drag this box to the detail view in another browser tab!',
		dropHere: 'Drop ads or events here.',
	},

	messages: {
		tooManyRequests: template`It looks like you tried to ${0} too many times. Please try again later.`,
		searchResults: template`${2}total: ${0} / displayed: ${1}`,
		selectSearchResults: template`${3}total: ${0} / displayed: ${1} / selected: ${2}`,
		createEvent: 'Create event',
		updateEvent: 'Edit event',
		searchLocations: 'Search locations - ',
		searchOrganizers: 'Search organizers - ',

		noLeafletsHeader: 'There are no Leaflets',
		noLeafletsText: 'You currently have no leaflets in your leaflet list.',

		noDataHeader: 'No data found',
		noData:
			'There is no data that matches the current filters. Please modify your filter setup to access more data.',

		noSubscriptionsHeader: 'There are no saved Searches',
		noSubscriptionsText:
			'You can save your filter lists that you created in events search by entering a name and clicking on "Save Search". Your saved searches will then be displayed here.',

		noSelections:
			"You don't have any selections yet. To make a selection, go to 'My Leaflets' and export a leaflet or some events.",

		noHistory:
			"The History is a tabular overview of all hidden events and 'No Music Events'. Only events processed by the logged in user are displayed. You haven't hidden or marked any events as 'No Music Event' yet.",

		export: {
			header: template`Exporting ${0} events`,
			description: template`You are about to export ${0} events from the leaflet 
			${1}. Feel free to write a description to identify and
			distinguish this file from the other exports.`,
			descriptionPlaceholder: 'Write a description...',

			action: template`After exporting, you can keep the events in the same leaflet 
			or move them to any other leaflet as state "In Process" ${0}.
			If you leave it as default the events will be removed from the current leaflet and their state will be set to "Exported" ${1}.`,
			actionPlaceholder: 'Leave as default or choose an action...',
			button: {
				cancel: 'Cancel',
				export: template`Export ${0}`,
				selected: 'selected',
				all: 'all',
				exportAnd: template` and ${0}`,
			},
			pageNotFound: 'This page could not could not be found',

			exportSuccessful: 'Export successful',
			messageExportBeingProcessed:
				'Your command is being processed. This might take a while, so it might not appear immediately inside your inbox. In the meantime, you might want to start a new export operation!',
			startNewSearch: 'Start a new search',
			useSavedSearch: 'Use a saved search',
			checkSelections: 'Check your selections',
		},

		selectOrganizer: {
			headerUnselected: 'Select the correct Organizer',
			headerSelected: template`Select Organizer "${0}"`,
			description:
				'Currently this event is assigned to multiple organizers. ' +
				'If you want to use it in organizer workflow you have to choose one correct organizer. ' +
				'The organizer locking is only applied to events mapped to a single organizer.',
			customID: 'custom ID',
			oldIdReplacedByNewId: template`Old ID ${0} replaced by new ID ${1}.`,
		},

		undoLastEventMove: 'Undo last event move action',

		undoLastMoveAction: template`Are you sure you want to undo ${0}? Be advised, there is currently no "redo" function.`,
		undoMoveAds: template`your last move action (move ${0} ad(s) from event ID=${1} back to event ID=${2})`,
		undoMoveAdsOfEvents: template`your last merge action (move ${0} ad(s) from event ID=${1} back to single events)`,
		undoCopyAds: template`your last copy action (remove ${0} ad(s) from event ID=${1})`,
		undoCopyAdsToNewRemoveAds: template`your last copy action (remove all ads from event ID=${0})`,
		undoEventMove: template`your last event move action (${0} events)`,

		noSetlistsDataHeader: 'No Setlists Data',
		noSetlistsData: 'For this event there are no setlists available.',
		passwordResetSuccessfulHeader: 'Password Reset Successful',
		passwordResetSuccessful: template`You successfully reset your password. You can now login on ${0}.`,

		urlsElseWhere: 'Please input the urls underneath the evidence images.',
	},

	alerts: {
		information: 'Ooops..',
		eventsMoveFailed: template`Operation failed: Some events could not be moved${0}. This error might occur when some of the events are modified or locked by you or by another user. Reload the view and try again.`,
		eventsMoveFailedOfIds: template` (IDs: ${0})`,
		queryNameAlreadyExists: template`A search with the name "${0}" already exists - please enter a different name.`,
		selectedMaxAmountOfEvents: template`Only the first ${0} events were selected. Please process or alter your current selection to select further events. ${0} events is the maximum amount that can be selected at once.`,
		leafletUnshared: 'The leaflet you were looking at has been un-shared with you.',

		unshareColleague: 'Are you sure you want to stop sharing the saved search with these user(s)?',
		leafletNotBeCleared: template`Something went wrong - the leaflet could not be cleared! \n ${0}`,
		downloadFailed: 'Download failed - please reload and try again!',
		mergeEvents: template`Are you sure you want to merge the ${0} selected events and move them to the event ID=${1}?`,
		linkUnlink: {
			noMoveAds: template`Could not move ad(s) ${0} from event(s) ${1} to event ${2}.`,
			noMoveAdsToNew: template`Could not create event or move ad(s) ${0} from event ${1} on new event.`,
			noCopyAds: template`Could not copy ad(s) ${0} from event ${1} on event ${2}.`,
			noCopyAdsToNew: template`Could not copy ad(s) ${0} from event ${1} on event.`,
			noDeleteEvent: template`Could not delete event(s) ${0}.`,
			noUndo:
				'Not all undo actions could be performed. Some of the relevant events might have been moved in the mean time.',
		},
		otherUserLockedEvent:
			'The event could not be locked. Another user might have triggered the same action simultaneously.',
		otherUserLockedOrganizer:
			'The organizer could not be locked. Another user might have triggered the same action simultaneously.',
		errorAssignOrganizer: 'The organizer could not be assigned to the event.',
		lockedSelectionIgnored: template`Some events of your selection have been locked by another user in the meantime. The events of the following ids will be ignored: ${0}.`,

		failMessageGenerator: template`Something went wrong, ${0} could not be ${1}. \nDon’t worry it’s not your fault and we’re working on it!`,
		item: {
			event: 'event',
			eventStatusBulkEdit: template`event status bulk edit`,
			advertisement: 'advertisement',
			advertisements: 'advertisement(s)',
			location: 'location',
			organizer: 'organizer',
			search: 'search',
			undo: 'undo',
		},
		what: {
			locked: 'locked',
			unlocked: 'unlocked',
			updated: 'updated',
			created: 'created',
			applied: 'applied',
			copied: 'copied',
			moved: 'moved',
			deleted: 'deleted',
		},
	},

	tooltips: {
		discardButtonHeader: 'Hide events from the standard view',
		discardButton:
			'Please consider this action affects the current view but also future event searches for other users of your organization. ' +
			'If you want to include the hidden events into your search enable the status button "Hidden".',
		scrapButtonHeader: 'Provide events as negative training data to improve the music score',
		scrapButton:
			'You will find less similar events in the event search. ' +
			'Please consider this action removes the events from the current view but also from future event searches for other users of your organization. ' +
			'If you want to include those events into your search enable the status button "Marked as \'No Music Event\'".',
		dropButtonHeader: 'Drop events from their current Leaflet',
		dropButton:
			'They will be accessible through the search for events having status "New". ' +
			'If they had another status before they were moved to the leaflet they might also be reset to status "Exported", "Hidden" or "Marked as \'No Music Event\'".',
		deleteButton:
			'To delete this leaflet remove any events on it and unshare the leaflet from all collaborators',
		mergeButton:
			'Merge the selected events. The ads of all selected events are moved to the selected event on top.',

		dragModeButton: 'You may press the "F2" key instead.',

		mergeButtonTooFew: 'To use the merge button you need to select at least two items.',
		mergeButtonTooMany: template`To use the merge button you need to select a maximum number of ${0} events. You cannot merge more events at a time.`,
		lockButton: {
			event: {
				lock:
					'Lock this event in order to move or edit it. ' +
					'As long as you are locking other uses can only read the event.',
				locked: 'As long as another user locks the event you can only read it.',
				unlock: 'Enable other users to work with this event.',
				disabled: "You do not have access to the event's leaflet. You may only read the event.",
			},
			organizer: {
				lock:
					'Lock this organizer in order to move or edit the assigned events. ' +
					'As long as you are locking other users can only read the events.\n' +
					'Hint: The locking is not applied on events which are assigned to other organizers in addition to this. ' +
					'In order to lock those events too you need to assign them to a single organizer.',
				locked:
					'As long as another user is locking the organizer you can only read the uniquely assigned events.',
				unlock: 'Enable other users to work with the assigned events of this organizer.',
			},
		},
		lockIcon: {
			event: {
				locked: template`This event is locked by ${0}. You may only read it.`,
				lockedByMe: 'You are locking this event. It cannot be edited by other users.',
				viewed: template`This event is viewed by ${0} user(s): ${1}.`,
				unlocked: 'This event is not locked by any user.',
			},
			organizer: {
				locked: template`This organizer is locked by ${0}. You may only read it.`,
				lockedByMe: 'You are locking this organizer. It cannot be edited by other users.',
				viewed: template`This organizer is viewed by ${0} user(s): ${1}.`,
				unlocked: 'This organizer is not locked by any user.',
			},
		},
		submitButton: {
			submit: 'Submit',
			submitPermitted: 'The submit is permitted.',
			unchanged: 'The data has not been changed.',
			missingFields: 'Missing mandatory fields: ',
			invalidFields: 'Invalid values at: ',
			fieldNames: {
				name: 'Name',
				startDate: 'Start Date',
				endDate: 'End Date',
				location: 'Location',
				editedLocation: {
					name: 'Location - Name',
					matchedLocationIds: 'Location - Customer IDs',
					emailAddresses: 'Location - Emails',
					phoneNumbers: 'Location - Phone numbers',
					urls: 'Location - Websites',
					address: {
						postalCode: 'Location - ZIP',
						street: 'Location - Street',
						houseNumber: 'Location - House number',
						city: 'Location - City',
						region: 'Location - Region',
						country: 'Location - Country',
					},
				},
				organizer: 'Organizer',
				editedOrganizer: {
					name: 'Organizer - Name',
					matchedOrganizerIds: 'Organizer - Customer IDs',
					emailAddresses: 'Organizer - Emails',
					phoneNumbers: 'Organizer - Phone numbers',
					urls: 'Organizer - Websites',
					address: {
						postalCode: 'Organizer - ZIP',
						street: 'Organizer - Street',
						houseNumber: 'Organizer - House number',
						city: 'Organizer - City',
						region: 'Organizer - Region',
						country: 'Organizer - Country',
					},
				},
				images: 'URLs of evidence images',
				noImage: 'Evidence image',
				noImages: 'Evidence images (at least one required)',
				admission: 'Admission',
				visitorCount: 'Attendees',
				invoiceNumber: 'Invoice number',
				itemNumber: 'Item number',
			},
		},
		csvExport: template`Export all displayed ${0} with the currently enabled columns.`,
		csvExportLocations: 'locations',
		csvExportOrganizers: 'organizers',
		csvExportEvents: 'events',
		expandAllButtonLocations:
			'Expands (or collapses) all table rows, enabling the display of all years for the "Customer events per year" and "MESLIS events per year" columns.',
		expandAllButtonOrganizers:
			'Expands (or collapses) all table rows, enabling the display of all years for the "Customer events per year" and "MESLIS events per year" columns.',
		expandAllButtonEvents:
			'Expands (or collapses) all table rows, enabling the display of all organizers of an event.',
		info: {
			header: {
				state: 'Sort for state',
				leaflet: 'Sort for current leaflet',
				user: 'Sort for last changing user',
				timestamp: 'Sort for last changing time',
			},
			cell: {
				infoIs: 'The Event state is',
				NEW: 'New',
				IN_PROGRESS: 'In Process',
				EXPORTED: 'Exported',
				DISCARDED: 'Hidden',
				SCRAPPED: "Marked as 'No Music Event'",

				itWas_exported: template`The event was exported ${0}.`,
				once: 'once',
				twice: 'twice',
				times: 'times',

				currLeafletIs: 'The current leaflet is',
				noLeaflet: 'The event is currently not in any leaflet',
				lastChangeWas: 'The last action to change the event state was',
				timestampUnknown: 'The timestamp of the last change to the event state is unknown',
				changingUserWas: 'The last changing user was',
				userUnknown: 'The event state has not been changed by any user',
			},

			filter: {
				leafletsDisabled1: 'The chosen leaflets are currently disabled. Enable state "In Process" ',
				leafletsDisabled2: ' or click on this field.',
				leafletsEnabled1: 'After choosing leaflets the state in "In Process" ',
				leafletsEnabled2: ' will be automatically enabled.',
			},
			entry: {
				leafletsDisabled1:
					'The chosen leaflets are currently disabled. In order to filter for leaflets enable state "In Process" ',
				leafletsDisabled2: ' or click on this entry.',
			},
			quickfilter: {
				NEW: 'New',
				IN_PROGRESS: 'In Process (Moved to Leaflet)',
				EXPORTED: 'Exported',
				DISCARDED: 'Hidden',
				SCRAPPED: "Marked as 'No Music Event'",
			},
		},
		anyField:
			'Fuzzy search on several fields allowing words ' +
			'of a field to differ from search term by two characters.',
		listNavigatorForbiddenForward:
			'No more items can be fetched, until the previous ones have not been processed.',

		sortLeaflets: {
			name: 'Sort by name',
			creator: 'Sort by creator',
			createdAt: 'Sort by creation date',
			updatedAt: 'Sort by last edit',
			default: 'Default order',
		},

		locationCreated: template`The location has been created. The id is ${0}.`,
		locationUpdated: template`The location has been updated. ${0}`,
		organizerCreated: template`The organizer has been created. The id is ${0}.`,
		organizerUpdated: template`The organizer has been updated. ${0}`,
		mergedEventsUpdateSuccess: template`Event(s) with the following ids were successfully updated: ${0}.`,
		mergedEventsUpdateDuplication: template`Duplication failed for event(s) with the following ids: ${0}.`,
		mergedEventsUpdateFailure: template`Update failed for event(s) with the following ids: ${0}.`,
		mergedEventsUpdateMatching: template`Matching failed for event(s) with the following ids: ${0}.`,

		sortIcon: {
			descSort: 'Sorted in descending order by this column.',
			ascSort: 'Sorted in ascending order by this column.',
			prioSort: template`This column as a priority of ${0}.`,
			clickToSort: 'Click to sort by this column.',
			clickToToggleSort: 'Click to inverse the sorting.',
			multipleSort:
				'Tip: Clicking and holding the control key creates a multiple sort. ' +
				'This way you can include further columns and prioritize them if there are equal values in a sorted column.',
			notSortable: 'This column cannot be sorted.',
		},
	},

	titles: {
		logout: 'Logout of system',
	},

	placeholders: {
		leafletDescription: 'A few descriptive words about your leaflet...',
		pdfPlaceholder: 'There is no pdf document available.',
		loading: 'Loading',
		loadingDots: 'loading...',
		noItemsFound: 'No items found.',
		moreDataHeader: 'Fetching data',
		moreData: 'Please wait a moment...',
		noMoreDataHeader: 'No more data',
		noMoreData: 'There is no more data to load.',
		chooseAName: 'Choose a name',
		dragEventAdsOrInput:
			'Drag event ads from another browser window and drop them here or use the input fields to create new ad data!',
		allAdsRemoved: 'All advertisements have been removed from this event.',
		dragAndDrop: 'Drag and drop images here or click to select files.',
		releaseToDrop: 'Release to drop files here.',
	},

	error: {
		error: 'Error',
		login: 'Login failed!',
		locked: 'Account is locked. Please request a password reset.',
		dataFetch: 'Data could not be fetched',
		rulePassword:
			'password needs at least 12 characters, including at least one upper case letter, one lower case letter, one number and a special case character',
		samePassword: 'old and new password need to be different',
		notSameNewPassword: 'The two passwords are not the same. Please enter again.',
		notSamePassword: 'passwords are not the same',
		wrongPassword: 'wrong password',
		passwordNotValid: 'Wrong original password.',
	},
	success: { passwordChange: 'You will receive an email confirming your action.' },

	navigation: {
		home: 'Home',
		events: 'Search Events',
		locations: 'Search Locations',
		organizers: 'Search Organizers',
		subscriptions: 'My Searches',
		subscriptionShared: 'A search query has recently been shared with you.', // saved search
		subscriptionsShared: template`${0} search queries have recently been shared with you.`,
		leaflets: 'My Leaflets',
		exports: 'My Selections',
		history: 'My History',
		customLeaflet: 'Manage Leaflets',
		leafletShared: 'A leaflet has recently been shared with you.',
		leafletsShared: template`${0} leaflets have recently been shared with you.`,
		help: 'Help Center',
		ticketSystem: 'Ticket System',
		close: 'Close',
		userSettings: 'User Settings',
		search: 'Search',
		select: 'Select',
		process: 'Process',
	},

	local: {
		localSettings: 'Local Settings',
		language: 'Language',
		dateAndTime: 'Date and Time',
	},

	countries: {
		australia: 'Australia',
		austria: 'Austria',
		belgium: 'Belgium',
		denmark: 'Denmark',
		germany: 'Germany',
		finland: 'Finland',
		greatBritain: 'Great Britain',
		ireland: 'Ireland',
		israel: 'Israel',
		italy: 'Italy',
		netherlands: 'Netherlands',
		portugal: 'Portugal',
		sweden: 'Sweden',
		switzerland: 'Switzerland',
		usa: 'USA',
	},
};
