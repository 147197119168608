import React, { useState, useContext, useEffect } from 'react';
import { Form, Icon, Button as Buttony, Input, Popup, Divider, Select } from 'semantic-ui-react';
import { useTranslate } from 'customHooks';
import { UserContext } from 'contexts/UserContext';
import Meslis from 'components/Meslis';
import Button from 'components/Button';
import ShareLeafletFormPart from './ShareLeafletFormPart';

const EditLeafletForm = ({
	onActionCompleted,
	leafletData,
	name,
	setName,
	tag,
	onChangeTag,
	setDescription,
	description,
	meslisEvents,
	users,
	errorMessage,
}) => {
	const { eventTags: fields = [] } = useContext(UserContext);
	const [shared, setShared] = useState(false);
	const [editors, setEditors] = useState(
		!!leafletData.editors
			? leafletData.editors
					.map(user => user.id)
					.filter(user => users.map(user => user.id).includes(user))
			: []
	);
	const [usersCurrent] = useState(users);

	const translate = useTranslate();

	useEffect(() => {
		setEditors(
			!!leafletData.editors
				? leafletData.editors
						.map(user => user.id)
						.filter(user => usersCurrent.map(user => user.id).includes(user))
				: []
		);
		setDescription(!!leafletData.description ? leafletData.description : '');
		setShared(!!leafletData.editors && leafletData.editors.length > 0);
	}, [leafletData, setDescription, usersCurrent]);

	const handleSharing = () => {
		setShared(!shared);
	};
	const handleChangeEditors = (e, target) => {
		setEditors(target.value);
	};

	return (
		<div>
			<div className="cancelButton" onClick={onActionCompleted}>
				<Icon name="cancel" />
			</div>
			<header>
				<h3>
					{translate('leaflets.header.edit')} '{leafletData.name}'
				</h3>
			</header>
			<div className="EditForm">
				<strong>
					{translate('leaflets.leafId')} {leafletData.id}
				</strong>
				<Divider fitted />
				{!!errorMessage && <div className="warning">{errorMessage}</div>}
				<Form onSubmit={onActionCompleted}>
					<Form.Field>
						<label>{translate('leaflets.leafName')}</label>
						<Input
							data-test="editLeafletName"
							placeholder="Name"
							value={name}
							onChange={e => setName(e.target.value)}
							maxLength="34"
							error={!!errorMessage}
						/>
					</Form.Field>
					<Form.Field>
						<label>{translate('leaflets.leafTag')}</label>
						<Select
							data-test="editLeafletTag"
							value={tag}
							clearable
							onChange={onChangeTag}
							placeholder={translate('leaflets.leafTag')}
							options={fields.map(item => ({
								value: item,
								text: item,
								key: item,
							}))}
						/>
					</Form.Field>
					<Form.Field>
						<Form.TextArea
							data-test="editLeafletDescription"
							label={translate('leaflets.leafDescription')}
							placeholder={translate('placeholders.leafletDescription')}
							value={description}
							onChange={e => setDescription(e.target.value)}
						/>
					</Form.Field>
					<ShareLeafletFormPart
						{...{ editors, shared, users }}
						onSharing={handleSharing}
						onChangeEditors={handleChangeEditors}
					/>
					<Meslis.UpdateCustomLeaflet
						leaflet={{
							id: leafletData.id,
							name,
							description,
							...(!!editors && { editorIds: editors }),
							eventTag: tag,
						}}>
						{f => (
							<Buttony
								data-test="editLeafletUpdateButton"
								fluid
								disabled={!name || !!errorMessage}
								primary
								onClick={f}>
								{translate('leaflets.update')}
							</Buttony>
						)}
					</Meslis.UpdateCustomLeaflet>
				</Form>
				<Popup
					basic
					position="bottom center"
					content={translate('tooltips.deleteButton')}
					disabled={!shared && meslisEvents === 0}
					trigger={
						<div>
							<Button.DeleteLeaflet
								fluid
								disabled={shared || meslisEvents !== 0}
								id={leafletData.id}
								leaflet={{
									id: leafletData.id,
									description: description,
									...(!!editors && { editorIds: editors }),
								}}
								events={null}
								onActionCompleted={onActionCompleted}
							/>
						</div>
					}
				/>
			</div>
		</div>
	);
};

export default EditLeafletForm;
